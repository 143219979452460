import React,{ useState, useEffect } from "react";
import { postRequest, getRequest } from "component/Axios";
import { RcsTemplateResultTable, RcsTemplateViewTable } from "component/Table";

export const RcsTemplateView = () => {
  const [content, setContent] = useState([]);
  const [brandIdList, setBrandIdList] = useState();
  const [data, setData] = useState();
  const [messageBaseId, setMessageBaseId] = useState();
  const [selectCustSn, setSelectCustSn] = useState([]);

  const [templateApprovalResult , setTemplateApprovalResult]  = useState();
  const [templateApprovalReason , setTemplateApprovalReason]  = useState();
  const [templateApprovalDt , setTemplateApprovalDt]  = useState();
  const [tempTemplateSeq, setTempTemplateSeq] = useState();
  useEffect(() => {
    handlePageChange();
  }, []);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url,data) {
    return postRequest.fetch(url,data);
}

  const handlePageChange = () => {
    const seq = sessionStorage.getItem("seq");
    setTempTemplateSeq(sessionStorage.getItem("seq"));
    const messagebaseIdChcek = sessionStorage.getItem("messagebaseId");
    setMessageBaseId(sessionStorage.getItem("messagebaseId"));
    const selectCustSn = sessionStorage.getItem("selectCustSn");
    setSelectCustSn(selectCustSn);
    const myCustId = sessionStorage.getItem("myCustId");
    const custType = sessionStorage.getItem("custType");

    sessionStorage.removeItem("messagebaseId");
    sessionStorage.removeItem("seq");
    sessionStorage.removeItem("selectCustSn");
    sessionStorage.removeItem("myCustId");
    sessionStorage.removeItem("custType");

    if(messagebaseIdChcek != '-') {
      const data = {
        search3 : messagebaseIdChcek,
        search4 : selectCustSn
      }
      getFetch("/rcs/brandId/getSelect")
      .then(result => {
        if( result.resultCode === "0" ) {
          setBrandIdList(JSON.parse(result.resultData));
  
          getPostFetch("/rcs/template", data)
          .then(result1 => {
            if( result1.resultCode === "0" ) {
              let data = JSON.parse(result1.resultData);
              setTemplateApprovalResult(data[0].templateApprovalResult);
              setTemplateApprovalReason(data[0].templateApprovalReason);
              setTemplateApprovalDt(data[0].templateApprovalDt);
              setData(JSON.parse(result1.resultData));
              setContent(<RcsTemplateViewTable custType={custType} myCustId={myCustId} brandIdList={JSON.parse(result.resultData)} data={JSON.parse(result1.resultData)}/>);
              }
          })
        }
      })
      .catch(error => console.log(error))
    } else {
      const data = {
        search3 : seq
      }
      getFetch("/rcs/brandId/getSelect")
      .then(result => {
        if( result.resultCode === "0" ) {
  
          getPostFetch("/rcs/tempTemplate", data)
          .then(result1 => {
            if( result1.resultCode === "0" ) {
              setData(JSON.parse(result1.resultData));
            }
          
            setContent(<RcsTemplateViewTable custType={custType} myCustId={myCustId} brandIdList={JSON.parse(result.resultData)} data={JSON.parse(result1.resultData)} tempTemplateSeq={seq}/>);
          })
        }
      })
      .catch(error => console.log(error))
    }

  


    
  }
 return (
    <div className="container sendboxview">
      
      {/* '저장'상태일 때만 미노출하고, 그 외의 상태일 떈 노출 */}
      {
        messageBaseId != '-' ? (
          <>
            <div className="page-title">
              템플릿 관리{" "}
              <span className="gray999 size14">
              승인 심사는 영업일 기준 48시간 이내이며 내부 사정상 지체될 수
              있습니다.
              </span>
          </div>
          <RcsTemplateResultTable templateApprovalResult={templateApprovalResult} templateApprovalReason={templateApprovalReason} templateApprovalDt={templateApprovalDt}/>
          </>
        ) : null
      }
      
      {/* '저장'상태일 때만 미노출하고, 그 외의 상태일 떈 노출 end*/}
      {content}
    </div>
  );
};
