import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Button } from "../../../Common";
import { SEVER_DOMAIN } from "../../../../utils/constant.js";
import { getPostFetch } from "../../../../component/Axios";
import { PopUpLayout } from "../../../../component/popUp/PopUpLayout";
import LoadExcelFile from "../../../../modal/sendMessage/receiveList/loadExcelFile/LoadExcelFile";
import LoadReceiveNumberList from "../../../../modal/sendMessage/receiveList/LoadReceiveNumberList";
import { PaginationDynamic } from "component/Common";

function Mass({
  viewTableRows,
  setviewTableRows,
  OpenAddressBook,
  setRegId,
  receiveCount,
  isLoadData,
  setIsLoadData,
  handleSearch,
  currentPage,
  leftPage,
  rightPage,
  endPage,
  fnlPage,
  pages,
  totalCount,
  fileRegIdList,
  setLoading
}) {
  const [deleteCnt, setDeleteCnt] = useState(0);
  const [popup, setPopup] = useState(false);
  const [snList, setSnList] = useState([]);
  const [uploadCnt, setReceiveCount] = useState(0);

  // 샘플파일 다운로드
  const downloadSample = (event) => {
    event.preventDefault();

    let fileName = "문자전송_Sample.xlsx";
    axios({
      method: "POST",
      url: SEVER_DOMAIN + "/file/download/",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
        "Content-Type": "application/json",
      },
      data: {
        sn: 3,
      },
    }).then((res) => {
      let blob = new Blob([res.data], { type: res.headers["content-type"] });

      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // not IE
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_self";
        if (fileName) link.download = fileName;
        link.click();
      }
    });
  };

  // 엑셀 파일 불러오기
  const OpenExcelFile = (event) => {
    event.preventDefault();
      setPopup(true);
      setIsLoadData("excel");
  };

  // 선택 목록 삭제
  const selectListDelete = (event) => {
    event.preventDefault();

    if (snList.length === 0) {
      alert("삭제할 항목이 선택되지 않았습니다.");
      return;
    }

    if (
      window.confirm(
        "선택항목을 삭제 하시겠습니까? [ 선택항목 수 : " + snList.length + " ]"
      )
    ) {
      const url = "/message/tempDelete";
      const sn = snList.map((list) => list.sn);
      const data = { search1: "uploadRegId", search2: "2", snList: sn };
      setLoading(true);
      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            setDeleteCnt(snList.length);
            setSnList([])
            handleSearch();
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  // 전체 목록 삭제
  const AllDelete = (event) => {
    event.preventDefault();

    if (window.confirm("업로드한 데이터를 전부 삭제 하시겠습니까?")) {
      const url = "/message/tempDelete";
      const data = { search1: "uploadRegId", search2: "1", strList: fileRegIdList };
      setLoading(true);
      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            setviewTableRows([]);
            setIsLoadData("");
            setDeleteCnt(uploadCnt);
            setReceiveCount(0);
            setSnList([])
            handleSearch();
            setRegId("");
            alert("삭제되었습니다.");
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="receive-number-wrap">
        <div>
          불러오기 가능한 파일 형식은 xls, xlsx 입니다. (최대 10만건)
          <br />
          셀서식의 표시형식은 텍스트로 이용해 주세요.
        </div>
        <TableTop>
          <div>
            <DeleteButton
              className="button-border-gray sbtn"
              onClick={selectListDelete}
            >
              선택 삭제
            </DeleteButton>
            <DeleteButton
              className="button-border-gray sbtn"
              onClick={AllDelete}
            >
              전체 삭제
            </DeleteButton>
          </div>
          <div className="write-number-down-info pt10">
            <span className="case" id="recipientCnt">
              총 <span className="red bold">{totalCount}</span> 건
            </span>
          </div>
        </TableTop>

        <div className="receive-number-list">
          <LoadReceiveNumberList
            data={viewTableRows}
            snList={snList}
            setSnList={setSnList}
          />
        </div>
        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handleSearch}
        />
        <div className="button-wrap">
          <Button
            buttonName="button-bgc-dark"
            buttonValue="주소록 불러오기"
            onChangePage={OpenAddressBook}
          />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="엑셀 파일 업로드"
            onChangePage={OpenExcelFile}
          />
          <Button
            buttonName="buttonDown mr10"
            buttonValue="업로드 양식 다운로드"
            onChangePage={downloadSample}
          />
        </div>
        {popup && (
          <PopUpLayout onClose={setPopup} poptitle="파일 업로드">
            <LoadExcelFile
              setRegId={setRegId}
              setPopupClose={() => setPopup(false)}
              setLoading={setLoading}
            />
          </PopUpLayout>
        )}
      </div>
    </div>
  );
}
export default React.memo(Mass);

const TableTop = styled.div`
  margin: 30px 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled.button`
  width: auto;
  height: 36px;
  margin-right: 10px;
`;
