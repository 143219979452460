import React, { useState, useEffect } from "react";
import { Button } from "component/Common";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

export const FindId2 = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(state);
    }, [state])

    const CustType = (type) => {
        switch (type) {
          case '1':
            return "대표계정"
          case '2':
            return "서브계정"
          case '3':
            return "미지정"
          case '4':
            return "승인요청"
          case '5':
            return "승인거부"
          default:
            return "-"
        }
    }
    return (
        <div className="account-wrap login">
            <div className="account-container">
                <p className="page-stitle">
                아이디 찾기
                </p>
                <div className="account-contents">
                    <p className="list-item-state center mb10"><span className="blue">입력하신 정보와 일치하는 아이디는 아래와 같습니다.</span></p>
                    <table className="table_style2">
            <thead>
                <tr>
                    <th>아이디</th>
                    <th>결제유형</th>
                    <th>계정구분</th>
                    <th>가입일</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr>
                        <td>{row.ID}</td>
                        <td>{row.PreRating === '1' ? "후불" : "선불"}</td>
                        <td>{CustType(row.CustType)}</td>
                        <td>{row.RegDd}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot></tfoot>
        </table>
                    
                    <p></p>
                    <Button
                        buttonName="button-bgc-blue bbtn mt30"
                        buttonValue="로그인"
                        onChangePage={function () {
                            navigate("/account/login");
                        }}
                    />
                    <Button
                        buttonName="button-border-gray bbtn mt10"
                        buttonValue="비밀번호 찾기"
                        onChangePage={function () {
                            navigate("/account/findpwd1");
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
