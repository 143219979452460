import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'component/Common';

export default function RefundComplete() {
  const location = useLocation();
  const navigate = useNavigate();
  const [refundSucInfo, setRefundSucInfo] = useState({
    custId: '',
    userNm: '',
    refundAmount: '',
    fnclCmpy: '',
    accountNo: '',
    owner: '',
    refundRemark: '',
  });

  useEffect(() => {
    if (location.state == null) {
      alert('잘못된 접근입니다.');
      navigate('/mypage/info/accountInfo');
    } else {
      setRefundSucInfo(location.state.refundSucInfo);
    }
  }, []);
  return (
    <div className='container signout_complete'>
      <h2>환불 신청완료</h2>
      <h4>
        안녕하세요. <span className='blue'>HiSMS</span>입니다.
      </h4>
      <p>
        환불 신청이 완료되었습니다.
        <br />
        요청하신 환불 신청은 현재 처리 중에 있으며, 최대한 빠른 시일이내에
        처리를 도와드리겠습니다.
        <br />
        고객님께서 신청하신 내역은 다음과 같습니다.
      </p>

      <table>
        <thead></thead>
        <tbody>
          <tr>
            <th>아이디</th>
            <td>{refundSucInfo.custId}</td>
          </tr>
          <tr>
            <th>신청 사용자</th>
            <td>{refundSucInfo.userNm}</td>
          </tr>
          <tr>
            <th>환불 요청 금액</th>
            <td>{refundSucInfo.refundAmount.toLocaleString('ko-KR')} 원</td>
          </tr>
          <tr>
            <th>환불 계좌 정보</th>
            <td>
              {refundSucInfo.fnclCmpy} {refundSucInfo.accountNo}{' '}
              {refundSucInfo.owner}
            </td>
          </tr>
          <tr>
            <th>환불 사유</th>
            <td>{refundSucInfo.refundRemark}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <b>HiSMS를 이용해 주셔서 감사합니다.</b><br></br>
      <NavLink to='/'>
        <Button buttonName='button-bgc-blue' style={{marginTop: "30px", height: "35px"}} buttonValue='홈으로' />
      </NavLink>
    </div>
  );
}
