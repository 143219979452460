import React, { useState, useEffect } from "react";
import Direct from "./direct/Direct";
import Mass from "./mass/Mass";
import { getPostFetch } from "../../../component/Axios";
import { PopUpLayout } from "../../../component/popUp/PopUpLayout";
import ImportAddress from "../../../modal/sendMessage/receiveList/loadAddressBook/ImportAddress";
import _ from "lodash";

function ReceiveList({
  phone,
  setPhone,
  phoneCount,
  setPhoneCount,
  contentPhone,
  setContentPhone,
  receiveCount,
  setReceiveCount,
  getCountTotal,
  setFileRegIdList,
  viewTableRows,
  setviewTableRows,
  messageType,
  fileRegIdList,
  setLoading,
  listUploadType,
  setListUploadType
}) {
  const [importAddress, popupImportAddress] = useState(false); // 수신번호 - 주소록 불러오기
  const [receiveNumberFile, popupReceiveNumberFile] = useState(false); // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업
  const [uploadType, setUploadType] = useState("1");
  const [regId, setRegId] = useState("");
  const [isLoadData, setIsLoadData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [rowCount, setRowCount] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    const regIdList = viewTableRows.map((row) => row.tsRegId);
    const Deduplication = [...new Set(regIdList)]; // regIdList의 중복제거
    setFileRegIdList(Deduplication);
  }, [viewTableRows]);

  useEffect(() => {
    if(contentPhone.length == 0) {
      setCurrentPage(1);
      setLeftPage(1);
      setEndPage(1);
      setFnlPage(1);
      setPages([1]);
      setTotalCount(0);
    }
  }, [contentPhone]);

  useEffect(() => {
    if (messageType === "alrimTalk") setListUploadType("mass");
  }, [messageType]);

  // 수신번호 입력 타입 선택 시 초기화
  const Reset = (type) => {

    if(receiveCount > 0) {
      if(!window.confirm("등록된 수신번호가 모두 초기화됩니다.\n수신번호 등록 유형을 정말로 변경하시겠습니까?")) {
        return false;
      }
    }

    setReceiveCount(0);
    setContentPhone([]);
    setRegId("");
    setTotalCount(0);
    setviewTableRows([]);
    setCurrentPage(1);
    setLeftPage(1);
    setRightPage(1);
    setEndPage(1);
    setFnlPage(1);
    setPages([1]);
    setListUploadType(type);
  };

  // 직접입력 - 선택한 리스트 삭제
  const handleDeleteNewUser = (item) => {
    let newList = contentPhone.filter((list) => list !== item);
    setContentPhone(newList);
    setReceiveCount(receiveCount - 1);
  };

  // 직접입력 - 수신번호 전체 삭제
  const RecipientAllDelete = (event) => {
    event.preventDefault();

    if (window.confirm("모든 수신번호를 삭제 하시겠습니까?")) {
      for (let i = 1; i <= phoneCount; i++) {
        const addPhone = "addPhone_" + i;
        const addFile = "filePhone_" + i;
        if (document.getElementById(addPhone))
          document.getElementById(addPhone).remove();

        if (document.getElementById(addFile))
          document.getElementById(addFile).remove();
      }
      setReceiveCount(0);
      setContentPhone([]);
    }
  };

  // 주소록 불러오기 모달 오픈
  const OpenAddressBook = (event) => {
    event.preventDefault();
      popupImportAddress(true);
      setIsLoadData("address");
  };

  // 주소록 내용 불러오기
  function uploadAddrPopClose(regId) {
    popupImportAddress(false);
    setRegId(regId);
    // handleSearch(); // 주소록 불러오기 중복 이슈로 주석 처리 2023.09.13
    setUploadType("2");
  }

  // 엑셀파일을 읽어올 때마다 regId가 업데이트 되므로 수신목록을 재 조회
  useEffect(() => {
    if (regId !== "") handleSearch();
  }, [regId]);

  // handleUpload에서 읽어온 regId로 수신목록 업데이트
  const handleSearch = (page) => {
    const url = "/message/tempList";
    const data = { search1: regId,       
                   rowCount: rowCount,
                   currentPage: page 
                 };
    setLoading(true);
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setviewTableRows(jsonArr);
          
          const pageItem = JSON.parse(result.resultPage);
          setTotalCount(pageItem.totalCount);
          setReceiveCount(pageItem.totalCount);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false);
      });
  };


  return (
    <div className="receive-area">
      <div className="write-tit-con clearfix">
        <div className="tit-left select-tit">
          <span className="required">수신번호</span>
        </div>
        {messageType !== "alrimTalk" && (
          <div className="tit-right">
            <div className="gs-custom-select buttonWith">
              <div className="Radiobox">
                <input
                  type="radio"
                  name="UploadType"
                  id="direct"
                  defaultChecked
                  checked={listUploadType === 'direct'}
                  onClick={() => {
                    Reset("direct");
                  }}
                />
                <label htmlFor="direct">직접입력</label>
                <input
                  type="radio"
                  name="UploadType"
                  id="mass"
                  checked={listUploadType === 'mass'}
                  onClick={() => {
                    Reset("mass");
                  }}
                />
                <label htmlFor="mass">대량등록</label>
              </div>
            </div>
          </div>
        )}
      </div>

      {listUploadType === "direct" && messageType !== "alrimTalk" && (
        <div className="write-number-down-info">
          <span className="case" id="recipientCnt">
            총 <span className="red bold">{receiveCount}</span> 건
          </span>
          <a href="/" onClick={RecipientAllDelete} className="ml10">
            전체삭제
          </a>
        </div>
      )}

      <div>
        {listUploadType === "direct" && messageType !== "alrimTalk" ? (
          <Direct
            phone={phone}
            setPhone={setPhone}
            phoneCount={phoneCount}
            setPhoneCount={setPhoneCount}
            contentPhone={contentPhone}
            setContentPhone={setContentPhone}
            receiveCount={receiveCount}
            setReceiveCount={setReceiveCount}
            handleDeleteNewUser={handleDeleteNewUser}
          />
        ) : (
          <Mass
            viewTableRows={viewTableRows}
            setviewTableRows={setviewTableRows}
            OpenAddressBook={OpenAddressBook}
            setRegId={setRegId}
            receiveCount={receiveCount}
            isLoadData={isLoadData}
            setIsLoadData={setIsLoadData}
            handleSearch={handleSearch}
            currentPage={currentPage}
            leftPage={leftPage}
            rightPage={rightPage}
            endPage={endPage}
            fnlPage={fnlPage}
            pages={pages}
            totalCount={totalCount}
            fileRegIdList={fileRegIdList}
            setLoading={setLoading}
          />
        )}

        {importAddress && (
          <PopUpLayout
            onClose={popupImportAddress}
            poptitle="주소록 불러오기"
            size="large"
            overflow="clip"
          >
            <ImportAddress uploadAddrPopClose={uploadAddrPopClose} />
          </PopUpLayout>
        )}
      </div>
      <p className="line"></p>
    </div>
  );
}

export default React.memo(ReceiveList);
