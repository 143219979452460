import React, { useState, useEffect } from "react";

export function RCSCallerList(props) {
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const jsonArr = props.chatbotInfo;
    const rows = [];
    if (jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="2">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        rows.push(
          <tr>
            <td>
              {row.chatbotId.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}
            </td>
            <td>{row.regDt}</td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.chatbotInfo]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>발신번호</th>
          <th>등록일시</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export default RCSCallerList;
