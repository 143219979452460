import React,{ useState, useEffect } from "react";
import { SendTemplateStatisticTable, SendRcsTemplateStatisticTable } from "component/Table";
import { Button,PaginationDynamic } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import axios from 'axios'; 
import { DateRangePicker } from 'rsuite';
import { SEVER_DOMAIN } from 'utils/constant.js';
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  datepicker: {
    margin: 0,
    marginTop: '-6px',
    padding:15
  },
  margin0: {
    margin:0
  }
}));


export const SendTemplateStatistic = () => {
  const classes = useStyles();
  const [value, onChange] = useState([new Date(), new Date()]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [plusIdList, setPlusIdList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [idUrl, setIdUrl] = useState("/stats/kakaoTemplateStatus/plusId/getSelect");
  const [templateUrl, setTemplateUrl] = useState("/stats/kakaoTemplateStatus/template/getSelect");
  const [url, setUrl] = useState("/stats/kakaoTemplateStats");
  const [serviceType, setServiceType] = useState(<SendTemplateStatisticTable />);
  const [select, setSelect] = useState("1");
  const [SelectId, setSelectId] = useState("");
  const [SelectTemplate, setSelectTemplate] = useState("");
  const [custType, setCustType] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();
  const [callCnt, setCallCnt] = useState(0);

  useEffect(() => {
    handlePageChange();
  }, [idUrl]);

  async function getPostFetch(url,data) {
    return postRequest.fetch(url,data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }


  const buttonOption = [
      { id :"1", value :"카카오 템플릿 통계", content : <SendTemplateStatisticTable />}
  ]

  const handleSelectId = (e) => {
    setSelectId(e.target.value);
  };

  const handleSelectTemplate = (e) => {
    setSelectTemplate(e.target.value);
  };

  const handleLoadingOn = () => {
    setCallCnt(0);
  }

  const handleLoadingClose = () => {
    setCallCnt(2);
  }

  const handlePageChange = page => {
      
    const myInfoUrl = "/user/myInfo";
    const subInfoUrl = "/user/subInfo";
    handleLoadingOn();
    getFetch(myInfoUrl)
    .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                setCustType(jsonArr.custType);

                if(jsonArr.custType === "1") {
                  getFetch(subInfoUrl)
                  .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        setCustIdList(jsonArr);
                    }
                  })
                  .catch(error => alert(error))
                }

                if ( value.length === 0 ) {
                  alert("발송일은 필수 선택사항 입니다.");
                  return;
                }
            
                let month0 = value[0].getMonth() + 1;
                if ( month0 < 10 )
                  month0 = "0" + month0;
            
                let day0 = value[0].getDate();
                if ( day0 < 10 )
                  day0 = "0" + day0;
            
                let month1 = value[1].getMonth() + 1;
                if ( month1 < 10 )
                  month1 = "0" + month1;
                
                let day1 = value[1].getDate();
                if ( day1 < 10 )
                  day1 = "0" + day1; 
            
                const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
                const endDay = value[1].getFullYear() + '' + month1 + '' + day1;
            
                const data = { rowCount : rowCount, currentPage: page, search2 : startDay, search3 : endDay, search4 : SelectId, search5 : SelectTemplate, search6 : jsonArr.custType, search7 : custId  }
                
                getPostFetch(url, data)
                .then(result => {
                    if( result.resultCode === "0" ) {
                      const jsonArr = JSON.parse(result.resultData);
                      const pageItem = JSON.parse(result.resultPage);
                      setLeftPage(pageItem.leftPage);
                      setRightPage(pageItem.rightPage);
                      setEndPage(pageItem.endPage);
                      setCurrentPage(pageItem.currentPage);
                      setFnlPage(pageItem.fnlPage);
                      const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                      setPages(pages);
                      setTableRows(jsonArr);
                    } else {
                        alert(result.resultMsg);
                    }
                    handleLoadingClose();
                  }
                )
                .catch(error => handleLoadingClose())
              }
            }
    )
    .catch(error => handleLoadingClose())

    getFetch(idUrl)
    .then(result => {
      if( result.resultCode === "0" ) {
          const jsonArr = JSON.parse(result.resultData);
          setIdList(jsonArr);
          getTemplate();
      }
    })
    .catch(error => alert(error))
  }

  const handleCustId = (e) => {
    setCustId(e.target.value);
  };

  
  useEffect(() => {
    getTemplate();
    setSelectTemplate("");
  }, [SelectId]);

  const getTemplate = () => { 

    const data = { search2 : SelectId };

    getPostFetch(templateUrl, data)
    .then(result => {
      if( result.resultCode === "0" ) {
        const jsonArr = JSON.parse(result.resultData);
        setTemplateList(jsonArr);
      }
    })
    .catch(error => {})
  }



  const getSelectedContent = (id) => {
   
    if (id === "1") {
      setIdUrl("/stats/kakaoTemplateStatus/plusId/getSelect");
      setTemplateUrl("/stats/kakaoTemplateStatus/template/getSelect");
      setUrl("/stats/kakaoTemplateStats");
      setIdList([]);
      setServiceType(<SendTemplateStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
    }
    else if (id === "2") {
      setIdUrl("/stats/rcsTemplateStatus/brandId/getSelect");
      setTemplateUrl("/stats/rcsTemplateStatus/template/getSelect");
      setUrl("/stats/rcsTemplateStats");
      setIdList([]);
      setServiceType(<SendRcsTemplateStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);

    }

    setSelect(id);
  }

  const handleSearch = (event) => {
    event.preventDefault();
  };
  const DownloadStatistics = (event) => {
    event.preventDefault();
    handleDownTemplate(1);
  };

  const handleDownTemplate = (type) => {
        
    let month0 = value[0].getMonth() + 1;
    if ( month0 < 10 )
      month0 = "0" + month0;

    let day0 = value[0].getDate();
    if ( day0 < 10 )
      day0 = "0" + day0;

    let month1 = value[1].getMonth() + 1;
    if ( month1 < 10 )
      month1 = "0" + month1;
    
    let day1 = value[1].getDate();
    if ( day1 < 10 )
      day1 = "0" + day1; 

    const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
    const endDay = value[1].getFullYear() + '' + month1 + '' + day1;
      let str = "템플릿별 통계를 다운로드 하시겠습니까?";
      handleLoadingOn();
      if( window.confirm(str) ) {
          if(tableRows.length === 2) {
            alert("다운로드할 데이터가 없습니다.");
            return;
          }
          axios({
              method: 'POST',
              url: SEVER_DOMAIN + url + '/downloadStats/',
              responseType: 'blob',
              headers: {
                  Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                  "Content-Type": "application/json"
              },   
              data: {
                search2 : startDay, search3 : endDay, search4 : SelectId, search5 : SelectTemplate, search6 : sessionStorage.getItem("custType"), search7 : custId     
              } 
          })
          .then(res =>{
              let blob = new Blob([res.data], { type: res.headers['content-type'] })
              let fileName = "템플릿별통계.xlsx"
              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                  window.navigator.msSaveOrOpenBlob(blob, fileName)
              } else { // not IE
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.target = '_self'
                  if (fileName) link.download = fileName
                  link.click()
              }
          })
          .catch(error => alert("파일 다운로드를 실패하였습니다."))
            handleLoadingClose();
          
      }  
  }

    return (
      <div className="container">
        {callCnt != 2 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <p className="page-title">템플릿별 통계</p>
        <div className="searchbox">
          <ul className="search-col3-group">
            <li>
              <select name="selectbox" id="" className={classes.rcsBtnSelect}  onChange={(e) => { getSelectedContent(e.target.value)}}>
                {buttonOption.map((b) => (
                    <option value={b.id}>{b.value}</option>
                ))}
              </select>
            </li>
            <li>
            <DateRangePicker  appearance="default" placeholder="발송일"
             onChange={onChange}
             value={value}
             dateFormat="yyyy-MM-dd"
             />
            </li>
            <li>
            <select onChange={handleSelectId} id="id" name="id" data-fakeform-index="0" value={SelectId}>
            {
              (function() {
                if (select === "1") return (<option value="" disabled selected hidden>채널명</option>);
                else return (<option value="" disabled selected>브랜드명</option>);
              })()
            }
            <option value="">전체</option>
            {
              (function() {   
                if (select === "1") return (idList.map((row) => (<option value={row.plusId}>{row.plusId}</option>)))
                else return (idList.map((row) => (<option value={row.brandName}>{row.brandName}</option>)))
              })()
            }
            </select>
            </li>        
          </ul>
          <ul className="search-col3-group">
   
            <li>
              <select onChange={handleSelectTemplate} name="template" id="template" value={SelectTemplate}> 
                <option value="" disabled selected hidden>템플릿</option>
                <option value="">전체</option>
                {
                  (function() {
                    if (select === "1") return (templateList.map((row) => (<option value={row.tpNm}>{row.plusId+"   ["}{row.tpNm+"]"}</option>)))
                    else return (templateList.map((row) => (<option value={row.templateName}>{row.templateName}</option>)))
                  })()
                }
               </select>
            </li>
            { custType === '1' ? 
              <li>
                <select onChange={handleCustId} name="userID" id="userID" value={custId}>
                  <option value="" selected>
                    계정 전체
                  </option>
                  {
                  custIdList.map((row) => (<option value={row.custId}>{row.custId}</option>))
                  }
                </select>
              </li>
            : null }
            <li><Button buttonName="button-bgc-dark" buttonValue="검색" onChangePage={ e => {handlePageChange(1)}}/></li>
            <li>
             
            </li>
          </ul>
        </div>
        {/*  <span className="Radiobox">
              <span>서비스유형</span>
              <input type="radio" name="radio2" id="id11" defaultValue="type1" defaultChecked onClick={function (e) { setServiceType(<SendTemplateStatisticTable />); setProfileType(<KakaoProfileName/>);}}/>
              <label htmlFor="id11">카카오</label>
              <input type="radio" name="radio2" id="id22" defaultValue="type2" onClick={function (e) { setServiceType(<SendRcsTemplateStatisticTable/>); setProfileType(<RCSBrandName/>);}}/>
              <label htmlFor="id22">RCS</label>
              
           </span>
            <br/>
            {profileType}
             */}
        <div className="button-wrap">
          <Button buttonName="buttonDown" buttonValue="엑셀 다운로드" onChangePage={DownloadStatistics}/> 
        </div>

        {
          (function() {
            if (select === "1") return (<SendTemplateStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
            else return (<SendRcsTemplateStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
          })()
        }
      </div>
  );
}

export default SendTemplateStatistic;


export const RCSBrandName = () => {
  return(
    <>
    <select name="" id="">
      <option value="" disabled selected>브랜드명</option>
      <option value="">전체</option>
      <option value="">목록</option>
      <option value="">목록</option>
    </select>
    </>
  );
}