import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "../src/assets/css/reset.css";
import "../src/assets/css/form.css";
import "../src/assets/css/layout.css";
import "../src/assets/css/font.css";

import "rsuite/dist/styles/rsuite-default.css"; // or 'rsuite/lib/styles/index.less'
import { IntlProvider } from "rsuite";
import koKR from "rsuite/lib/IntlProvider/locales/ko_KR";
import { RecoilRoot } from "recoil";

import ReactGA from 'react-ga';


if(process.env.REACT_APP_ENV ==  "live") {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID)
}

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <IntlProvider locale={koKR}>
        <App />
      </IntlProvider>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);
