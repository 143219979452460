import React, { useState } from 'react';
import { Button } from 'component/Common';
import { Privacy } from 'views/terms/Privacy';
import { PopUpLayout } from 'component/popUp/PopUpLayout';
import { LayerPopFileUpload } from 'component/popUp/PopUp';
import { postRequest } from 'component/Axios';
import { useNavigate } from 'react-router-dom';
import Loader from "../../../component/Loader";
export const ContactUS = () => {
  const [contractAllSelected, setContractAllSelected] = useState(false); // 개인정보처리방침 체크여부
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [contents, setContents] = useState('');
  const [popup, setPopup] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(1);

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  } 
  
  const setFile = uploadFile => {
    setUploadFile(uploadFile);
  };
  const setPopupClose = () => {
    setPopup(false);
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleChange = e => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  const handleXss = e => {
    let str = e.target.value;
    str = str.replaceAll('<', '&lt;');
    str = str.replaceAll('>', '&gt;');
    let name = e.target.name;
    if (name === 'titleQna') {
      setTitle(str);
    } else if (name === 'contents') {
      setContents(str);
    } else if (name === 'emailQna') {
      setEmail(str);
    }
  };
  const handleSendMail = event => {
    event.preventDefault();
    //console.log('김영훈 과장님 메일로 전달, (참조 : 김풍희 차장님)');
    const regexEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const regexMobile = /^\d{2,3}\d{3,4}\d{4}$/;
    if (!title) {
      alert('이름(회사명)은 필수입력사항 입니다.');
      return;
    }
    if (!email) {
      alert('이메일은 필수입력사항 입니다.');
      return;
    } else if (!regexEmail.test(email)) {
      alert('잘못된 형식의 이메일 입니다.');
      return;
    }
    if (!phone) {
      alert('연락처는 필수입력사항 입니다.');
      return;
    } else if (!regexMobile.test(phone)) {
      alert('잘못된 형식의 연락처 입니다.');
      return;
    }
    if (!contents) {
      alert('문의내용은 필수입력사항 입니다.');
      return;
    }
    if (!contractAllSelected) {
      alert('개인정보처리방침에 동의해 주셔야 합니다.');
      return;
    }
    if (window.confirm('관리자 메일로 접수 하시겠습니까?')) {
      sendMail();
    }
  };
  function sendMail() {
    let url;
    let data;
    if (uploadFile) {
      url = '/board/saveQnaFile';
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('email', email);
      formData.append('title', title);
      formData.append('phone', phone);
      formData.append('contents', contents);
      formData.append('auth', contractAllSelected);
      data = formData;
    } else {
      url = '/board/saveQna';
      data = {
        email: email,
        title: title,
        phone: phone,
        contents: contents,
        auth: contractAllSelected,
      };
    }
    handleLoadingOn();
    getPostFetch(url, data)
      .then(result => {
        alert(result.resultMsg);
        handleLoadingClose();
        if (result.resultCode === '0') navigate('/');
      })
      .catch(error => handleLoadingClose());
  }
  const handleChecked = event => {
    contractAllSelected
      ? setContractAllSelected(false)
      : setContractAllSelected(true);
  };
  const handlePopup = event => {
    event.preventDefault();
    setPopup(true);
  };
  return (
    <div className='container ContactUS'>
      {loading !=  1 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <p className='page-title'>상담문의</p>
      <div className='account-contents'>
        <div className='check-list-item'>
          <div className='input-inner clearfix'>
            <div className='title-con'>
              <span className='required'>이름(회사명)</span>
            </div>
            <div className='input-con'>
              <input
                type='text'
                name='titleQna'
                id='titleQna'
                maxlength='50'
                title='이름(회사명)'
                className='gs-custom-input fc-event'
                placeholder='이름(회사명)'
                onChange={e => {
                  handleXss(e);
                }}
              />
            </div>
            <p className='clear'></p>
          </div>
        </div>
        <div className='check-list-item'>
          <div className='input-inner clearfix'>
            <div className='title-con'>
              <span className='required'>이메일</span>
            </div>
            <div className='input-con'>
              <input
                type='text'
                name='emailQna'
                id='emailQna'
                maxlength='50'
                title='이메일'
                className='gs-custom-input fc-event'
                placeholder='이메일'
                onChange={e => {
                  handleXss(e);
                }}
              />
            </div>
            <p className='clear'></p>
          </div>
        </div>
        <div className='check-list-item'>
          <div className='input-inner clearfix'>
            <div className='title-con'>
              <span className='required'>연락처</span>
            </div>
            <div className='input-con'>
              <input
                type='text'
                name='phoneQna'
                id='phoneQna'
                maxlength='12'
                title='연락처'
                className='gs-custom-input fc-event'
                placeholder='연락처'
                onChange={handleChange}
                value={phone}
              />
            </div>
            <p className='clear'></p>
          </div>
        </div>
        <div className='check-list-item'>
          <div className='input-inner clearfix' id='cropNumArea'>
            <div className='title-con'>
              <span>첨부파일</span>
            </div>
            <div className='input-con'>
              <fieldset className='address-fieldset'>
                <div className='address-wrapper'>
                  <input
                    type='text'
                    className='gs-custom-input'
                    name='uploadFile'
                    id='uploadFile'
                    readonly=''
                    value={fileName}
                  />
                  <Button
                    buttonName='cm-highlight-btn he30 fc-event'
                    buttonValue='파일등록'
                    onChangePage={handlePopup}
                  />
                </div>
              </fieldset>
            </div>
            <p className='clear'></p>
          </div>
        </div>
        <div className='check-list-item'>
          <div className='input-inner textarea-inner clearfix' id=''>
            <div className='title-con'>
              <span className='required'>문의내용</span>
            </div>
            <div className='input-con'>
              <textarea
                name='contents'
                placeholder='상세 문의내용을 입력해주세요.'
                onChange={e => {
                  handleXss(e);
                }}
              ></textarea>
            </div>
            <p className='clear'></p>
          </div>
        </div>
      </div>
      <article className='sub-join02-check-list total-add-css'>
        <div className='check-list-item'>
          <dl className='check-inner'>
            <dt className='title-con'>
              <div className=''>
                <span className='arrow'></span>
                <strong className='check-title'>
                  <fieldset className='gs-custom-checkbox'>
                    <span className='checkbox-item'>
                      <input
                        type='checkbox'
                        id='advertising'
                        name='advertising'
                        onClick={handleChecked}
                      />
                      <label
                        for='advertising'
                        className={contractAllSelected ? 'checked' : null}
                      >
                        개인정보처리방침에 동의합니다.
                      </label>
                    </span>
                  </fieldset>
                </strong>
              </div>
            </dt>
            {contractAllSelected ? (
              <>
                <dd className='borderbox mt10'>
                  <div className='custom-scrollbar-wrapper'>
                    <div className='scroll-object-box mCustomScrollbar _mCS_2 mCS_no_scrollbar'>
                      <div
                        id='mCSB_2'
                        className='mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside'
                        tabindex='0'
                      >
                        <div
                          id='mCSB_2_container'
                          className='mCSB_container mCS_y_hidden mCS_no_scrollbar_y'
                          dir='ltr'
                        >
                          <div className='service-txt-scroll'>
                            <Privacy />
                          </div>
                        </div>
                        <div
                          id='mCSB_2_scrollbar_vertical'
                          className='mCSB_scrollTools mCSB_2_scrollbar mCS-dark mCSB_scrollTools_vertical'
                        >
                          <div className='mCSB_draggerContainer'>
                            <div
                              id='mCSB_2_dragger_vertical'
                              className='mCSB_dragger'
                            >
                              <div className='mCSB_dragger_bar'></div>
                            </div>
                            <div className='mCSB_draggerRail'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
              </>
            ) : null}
          </dl>
          {/* 여기 팝업을 밖으로  */}
          {popup && (
            <PopUpLayout onClose={setPopup} poptitle='파일 업로드'>
              <LayerPopFileUpload
                setFile={setFile}
                setPopupClose={setPopupClose}
                setFileName={setFileName}
              />
            </PopUpLayout>
          )}
        </div>
      </article>
      <p className='center gray999 mt10 size14'>
        문의하신 상담내용은 사이트 관리자의 메일로 전송되며, 연락은 기재하신
        연락처 또는 이메일로 회신드리오니 정확한 정보를 입력해주세요.
      </p>
      <div className='button-wrap center'>
        <Button
          buttonName='button-bgc-dark'
          buttonValue='관리자 메일로 접수'
          onChangePage={handleSendMail}
        />
      </div>
    </div>
  );
};