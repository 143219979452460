import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'component/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { postRequest, getRequest } from 'component/Axios';
import { useNavigate } from 'react-router-dom';

const PasswordCheck = props => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');
  const [tableRows, setTableRows] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [errMsgId, setErrMsgId] = useState('');
  const idInput = useRef();
  const pwdInput = useRef();
  const [checked, setChecked] = useState(false);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleGetCustType = () => {
    const url = '/user/custInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const json = JSON.parse(result.resultData);
          if (json.userType !== '1') {
            alert('잘못된 접근입니다.');
            navigate('/mypage/info/accountInfo');
          }
        }
      })
      .catch(error => alert(error));
  };
  const handleConfirm = e => {
    e.preventDefault();
    // 계정의 ID PW가 맞는지 검증하는 코드 추가해주세요.

    if (id == '' || id.length == 0) {
      setErrMsgId('아이디를 입력해 주세요.');
      idInput.current.focus();
      return;
    }

    if (pwd == '' || pwd.length == 0) {
      setErrMsg('비밀번호를 입력해 주세요.');
      pwdInput.current.focus();
      return;
    }
    const url = '/user/checkCustInfo';
    const data = { username: id, password: pwd };

    // 아이디 찾기
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          window.location.href = '/mypage/signOutMember';
        } else {
          setErrMsg(result.resultMsg);
        }
      })
      .catch(error => alert(error));
  };

  useEffect(() => {
    handleGetCustType();
  }, []);

  return (
    // git 테스트(사용자 재등록)
    <div className='account-wrap'>
      <div className='account-container'>
        <p className='page-stitle'>환불/탈퇴 신청</p>
        <div className='signOut'>
          <div className='signOutInfo'>
            <p className='center'>
              <FontAwesomeIcon icon={faInfoCircle} className='gray999 mr5' />
              회원님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 확인합니다
            </p>
          </div>
          <div className='account-container'>
            <p className='clear'></p>
            <div className='account-contents'>
              <div className='check-list-item'>
                <div className='input-inner clearfix' id='cropNumArea'>
                  <div className='title-con'>아이디</div>
                  <div className='input-con'>
                    <input
                      type='text'
                      name='username'
                      id='username'
                      maxlength='20'
                      title='ID'
                      className='gs-custom-input fc-event'
                      placeholder='ID'
                      onChange={e => {
                        setId(e.target.value);
                        if (e.target.value != '') setErrMsgId('');
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') handleConfirm(e);
                      }}
                      ref={idInput}
                      value={id}
                    />
                  </div>
                  <p className='clear'></p>
                </div>
                <p className='list-item-state'>
                  <span className='red'>{errMsgId}</span>
                </p>
              </div>
              <div className='check-list-item'>
                <div className='input-inner clearfix' id='cropNumArea'>
                  <div className='title-con'>비밀번호</div>
                  <div className='input-con'>
                    <input
                      type='password'
                      name='password'
                      id='password'
                      maxlength='100'
                      title='PW'
                      className='gs-custom-input fc-event'
                      placeholder='PW'
                      onChange={e => {
                        setPwd(e.target.value);
                        if (e.target.value != '') setErrMsg('');
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') handleConfirm(e);
                      }}
                      ref={pwdInput}
                    />
                  </div>
                  <p className='clear'></p>
                </div>
                <p className='list-item-state'>
                  <span className='red'>{errMsg}</span>
                </p>
              </div>
              <div class='sub-login-bottom-con'>
                <div className='button-wrap center'>
                  <NavLink to='/mypage/info/accountInfo'>
                    <Button
                      buttonName='button-border-gray'
                      buttonValue='뒤로가기'
                    />
                  </NavLink>
                  <Button
                    buttonName='button-bgc-dark ml10'
                    buttonValue='확인'
                    onChangePage={handleConfirm}
                  />
                </div>
              </div>
              <p className='clear'></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordCheck;
