import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = () => {
    
    const location = useLocation();
    const [initialize, setInitialize] = useState(false);

    useEffect(() => {
        if(process.env.REACT_APP_ENV ==  "live") {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
            setInitialize(true);
        }
    }, [])

    useEffect(() => {
        if(initialize) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialize, location])
}

export default RouteChangeTracker;