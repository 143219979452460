import React, { useState, useEffect }  from "react";
import { SendStatisticTable } from "component/Table";
import { Button,PaginationDynamic } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'rsuite';
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import axios from 'axios'; 
import { SEVER_DOMAIN } from 'utils/constant.js';
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  datepicker: {
    margin: 0,
    marginTop: '-6px',
    padding:15
  },
  margin0: {
    margin:0
  }
}));

export const SendStatistic = () => {
  const classes = useStyles();
  const [value, onChange] = useState([new Date(), new Date()]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search1, setSearch1] = useState('');
  const [custType, setCustType] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();
  const [callCnt, setCallCnt] = useState(0);

  const handleSearch = (event) => {
    event.preventDefault();
  };
  const DownloadStatistics = (event) => {
    event.preventDefault();
    handleDownTemplate(1);
  };

  useEffect(() => {
    handlePageChange(1);
  }, []);

  async function getPostFetch(url,data) {
    return postRequest.fetch(url,data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleLoadingOn = () => {
    setCallCnt(0);
  }

  const handleLoadingClose = () => {
    setCallCnt(2);
  }

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handlePageChange(1);
    }
  }


  const handlePageChange = page => {
      if ( value.length === 0 ) {
          alert("전송일자는 필수선택사항 입니다.");
          return;
      }

      let month0 = value[0].getMonth() + 1;
      if ( month0 < 10 )
        month0 = "0" + month0;

      let day0 = value[0].getDate();
      if ( day0 < 10 )
        day0 = "0" + day0;

      let month1 = value[1].getMonth() + 1;
      if ( month1 < 10 )
        month1 = "0" + month1;
      
      let day1 = value[1].getDate();
      if ( day1 < 10 )
        day1 = "0" + day1; 

      const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
      const endDay = value[1].getFullYear() + '' + month1 + '' + day1;

      const myInfoUrl = "/user/myInfo";
      const subInfoUrl = "/user/subInfo";
      const url = "/stats/sendStats";
      
      handleLoadingOn();
      getFetch(myInfoUrl)
      .then(result => {
              if( result.resultCode === "0" ) {
                  const jsonArr = JSON.parse(result.resultData);
                  setCustType(jsonArr.custType);

                  if(jsonArr.custType === "1") {
                    getFetch(subInfoUrl)
                    .then(result => {
                      if( result.resultCode === "0" ) {
                          const jsonArr = JSON.parse(result.resultData);
                          setCustIdList(jsonArr);
                      }
                    })
                    .catch(error => handleLoadingClose())
                  }
                  
                  const data = { rowCount : rowCount, currentPage: page, search1 : search1 , search2 : startDay, search3 : endDay, search4 : jsonArr.custType, search5 : custId}
                  
                  getPostFetch(url, data)
                  .then(result => {
                          if( result.resultCode === "0" ) {
                              const jsonArr = JSON.parse(result.resultData);
                              const pageItem = JSON.parse(result.resultPage);
                              setLeftPage(pageItem.leftPage);
                              setRightPage(pageItem.rightPage);
                              setEndPage(pageItem.endPage);
                              setCurrentPage(pageItem.currentPage);
                              setFnlPage(pageItem.fnlPage);
                              const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                              setPages(pages);
                              setTableRows(jsonArr);
                          } else {
                              alert(result.resultMsg);
                          }
                          handleLoadingClose();
                      }
                  )
                  .catch(error => handleLoadingClose())
              }
      })
      .catch(error => handleLoadingClose())
  }

  
  const handleDownTemplate = (type) => {
        
    let month0 = value[0].getMonth() + 1;
      if ( month0 < 10 )
        month0 = "0" + month0;

      let day0 = value[0].getDate();
      if ( day0 < 10 )
        day0 = "0" + day0;

      let month1 = value[1].getMonth() + 1;
      if ( month1 < 10 )
        month1 = "0" + month1;
      
      let day1 = value[1].getDate();
      if ( day1 < 10 )
        day1 = "0" + day1; 
        
    const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
    const endDay = value[1].getFullYear() + '' + month1 + '' + day1;
      let str = "전송내역을 다운로드 하시겠습니까?";
      handleLoadingOn();
      if( window.confirm(str) ) {

          axios({
              method: 'POST',
              url: SEVER_DOMAIN + '/stats/downloadStats/',
              responseType: 'blob',
              headers: {
                  Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                  "Content-Type": "application/json"
              },   
              data: {
                dayList : value, search1 : search1 , search2 : startDay, search3 : endDay , search4 : type , search4 : custType, search5 : custId   
              } 
          })
          .then(res =>{
              let blob = new Blob([res.data], { type: res.headers['content-type'] })
              let fileName = "전송통계.xlsx"
              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                  window.navigator.msSaveOrOpenBlob(blob, fileName)
              } else { // not IE
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.target = '_self'
                  if (fileName) link.download = fileName
                  link.click()
              }
              handleLoadingClose();
          })      
          .catch((error) => {
            handleLoadingClose();
          });
          
      }  
  }


  // 날짜 비교시 년 월 일까지만 비교하게끔 
  const createDate = (date) => { 
    return new Date(new Date(date.getFullYear() , date.getMonth() , date.getDate() , 0 , 0 , 0)); 
  }

  const handleCustId = (e) => {
    setCustId(e.target.value);
  };

    return (
      <div className="container">
        {callCnt != 2 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <div className="page-title">전송통계</div>
        <div className="searchbox">
          <ul className="search-col4-group">
            <li>
             <DateRangePicker  appearance="default" placeholder="전송일자"
             onChange={onChange}
             value={value}
             dateFormat="yyyy-MM-dd"
             />
            </li>
            <li>
              <select name="channel_id" id="sendType" value={search1} onChange={ e => setSearch1(e.target.value) }  onKeyPress={handleEnterPress}>
                <option hidden value="">서비스 유형</option>
                <option value="">전체</option>
                <option value="1">SMS</option>
                <option value="2">LMS</option>
                <option value="3">MMS</option>
                <option value="4">카카오 알림톡</option>
                <option value="5">카카오 친구톡 텍스트</option>
                <option value="6">카카오 친구톡 이미지</option>
                <option value="7">카카오 친구톡 이미지 와이드</option>
                <option value="8">RCS 템플릿</option>
                <option value="9">RCS SMS</option>
                <option value="10">RCS LMS</option>
                <option value="11">RCS MMS</option>
              </select>
            </li>
            { custType === '1' ? 
              <li>
                <select onChange={handleCustId} name="userID" id="userID" value={custId} onKeyPress={handleEnterPress}>
                <option hidden value="">사용자 계정</option>
                  <option value="">전체</option>
                  {
                  custIdList.map((row) => (<option value={row.custId}>{row.custId}</option>))
                  }
                </select>
              </li>
            : null }
            <li>
              <Button buttonName="button-bgc-dark" buttonValue="검색" onChangePage={ e => {handlePageChange(1)}}/>
            </li>
          </ul> 
        </div>
        {/* <div className="searchbox">
            <span className="Radiobox">
              <input type="radio" name="radio" id="id1" defaultValue="type1" defaultChecked/>
              <label htmlFor="id1">일별</label>
              <input type="radio" name="radio" id="id2" defaultValue="type2"/>
              <label htmlFor="id2">월별</label>
              <span className={classes.margin0}>
               일별 선택시
                <DateRangePicker className={classes.datepicker}/>
                 월별 선택시
                <DateRangePicker hoverRange="month" ranges={[]} className={classes.datepicker}/>
          
              </span>
            </span>
            <span>서비스유형</span>
            <select name="channel_id" id="">
                <option value="">전체</option>
                <option value="">목록</option>
                <option value="">목록</option>
            </select>
            <Button buttonName="button-bgc-dark bbtn" buttonValue="검색" onChangePage={handleSearch} />
        </div> */}

        <div className="button-wrap">
          <Button buttonName="buttonDown" buttonValue="엑셀 다운로드" onChangePage={DownloadStatistics} /> 
        </div>

        <SendStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>
        <PaginationDynamic  
                    pages={pages}
                    currentPage={currentPage}
                    leftPage={leftPage}
                    rightPage={rightPage}
                    endPage={endPage}
                    fnlPage={fnlPage}
                    onPageChange={handlePageChange}
                />
      </div>
  );
}

export default SendStatistic;