import React, { Component, useState, useEffect } from 'react';
import { Button } from 'component/Common';
import { ChargeTable } from 'component/Table';
import { makeStyles } from '@material-ui/core/styles';
import { PaginationDynamic } from 'component/Common';
import { DateRangePicker } from 'rsuite';
import { PopUpLayout } from 'component/popUp/PopUpLayout';
import { postRequest, getRequest } from 'component/Axios';
import _ from 'lodash';
import {
  LayerPopDevideCharge,
  LayerPopCollectCharge,
} from 'component/popUp/PopUp';
import Loader from "../../../component/Loader";

export function ChargeHistory(props) {
  const custType = props.custType;

  const userType = props.userType;
  const balance = props.balance;
  const custGrade = props.custGrade;

  const handleGetBalance = props.handleGetBalance;
  const [devideCharge, popupDevideCharge] = useState(false);
  const [collectCharge, popupCollectCharge] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search1, setSearch1] = useState('1');
  const [search2, setSearch2] = useState('');
  const [search3, setSearch3] = useState('');
  const [search4, setSearch4] = useState('');
  const [dateValue, setDateValue] = useState([]);

  const [codeRows, setCodeRows] = useState([]);
  const [custRows, setCustRows] = useState([]);

  const [custBalanceInfo, setCustBalanceInfo] = useState([]);
  const [loading, setLoading] = useState("");

  const useStyles = makeStyles(theme => ({
    datepicker: {
      margin: 0,
      marginTop: '0px',
      padding: 15,
      width: '100%',
    },
  }));

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleCommonCode = () => {
    const url = '/getCommonCode';
    const snList = [1019];
    const data = { snList: snList };

    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch(error => alert(error));
  };

  const handleSubList = () => {
    const url = '/user/subInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setCustRows(jsonArr);
        }
      })
      .catch(error => alert(error));
  };

  const handlePageChange = page => {
    let startDay = '';
    let endDay = '';
    if (dateValue.length !== 0) {
      let month0 = dateValue[0].getMonth() + 1;
      if (month0 < 10) month0 = '0' + month0;

      let day0 = dateValue[0].getDate();
      if (day0 < 10) day0 = '0' + day0;

      let month1 = dateValue[1].getMonth() + 1;
      if (month1 < 10) month1 = '0' + month1;

      let day1 = dateValue[1].getDate();
      if (day1 < 10) day1 = '0' + day1;

      startDay = dateValue[0].getFullYear() + '-' + month0 + '-' + day0;
      endDay = dateValue[1].getFullYear() + '-' + month1 + '-' + day1;
    }
    const url = '/user/balanceHistory';
    const data = {
      rowCount: rowCount,
      currentPage: page,
      search1: startDay,
      search2: endDay,
      search3: search3,
      search4: search4,
      userType: userType,
    };
    setLoading(0);
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);
          handleCustBalanceInfo();
        } else {
          alert(result.resultMsg);
        }
        setLoading(2);
      })
      .catch(error => setLoading(2));
      
  };

  const handleCustBalanceInfo = () => {
    const url = '/user/findSubCustList';
    const data = {};

    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          let jsonArr = JSON.parse(result.resultData);
          jsonArr.map(info => (info.amount = 0));
          setCustBalanceInfo(jsonArr);
        }
      })
      .catch(error => alert(error));
  };

  const handleDistribution = event => {
    event.preventDefault();
    handleCustBalanceInfo();
    popupDevideCharge(true);
  };

  const setDistributionClosePop = () => {
    popupDevideCharge(false);
  };

  const handleCollect = event => {
    event.preventDefault();
    handleCustBalanceInfo();
    popupCollectCharge(true);
  };

  const handleSearch = event => {
    handlePageChange(1);
  };

  useEffect(() => {
    handlePageChange(currentPage);
    handleCommonCode();
    handleSubList();
  }, []);
  const [isShown, setIsShown] = useState(false);
  const getAlertShown = () => {
    setIsShown(true);
  };
  const setCollectClosePop = () => {
    popupCollectCharge(false);
  };

  const getAlertclose = () => {
    setIsShown(false);
  };

  return (
    <>
      {loading !=  2 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className='charge'>
        {/* <div className="searchbox mt20">
            <span className="Radiobox">
              <input type="radio" name="radio" id="id1" defaultChecked />
              <label htmlFor="id1">최근 3개월</label>
              <input type="radio" name="radio" id="id2"/>
              <label htmlFor="id2">직접 기간 입력</label>
            </span>

            <span className="ml10">
              <DateRangePicker className={useStyles.datepicker}/>
            </span>
            <span> 구분</span>
            <select name="channel_id" id="">
              <option value="">전체</option>
              <option value="">충전</option>
              <option value="">사용</option>
              <option value="">배분</option>
            </select>
            <Button buttonName="button-bgc-dark bbtn" buttonValue="검색" onChangePage={handleSearch}/>
            <br />
          </div>  */}
        <div className='searchbox'>
          <ul className='search-col4-group'>
            <li>
              <DateRangePicker
                className={useStyles.datepicker}
                value={dateValue}
                onChange={setDateValue}
              />
            </li>
            {custType === '1' && custGrade === '2' && userType === '1' ? (
              <li>
                <select
                  name=''
                  id=''
                  onChange={e => setSearch3(e.target.value)}
                  value={search3}
                >
                  <option hidden value="">사용자 계정</option>
                  <option value=''>
                    전체
                  </option>

                  {custRows.map(row => (
                    <option value={row.custSn}>{row.custId}</option>
                  ))}
                </select>
              </li>
            ) : null}
            <li>
              <select
                name='channel_id'
                id=''
                onChange={e => setSearch4(e.target.value)}
                value={search4}
              >
                <option hidden value="">구분</option>
                <option defaultValue value=''>
                  전체
                </option>
                {codeRows.map(row =>
                  row.cgSn === 1019 ? (
                    <option value={row.cdId}>{row.cdNm}</option>
                  ) : null
                )}
              </select>
            </li>
            <li>
              <Button
                buttonName='button-bgc-dark'
                buttonValue='검색'
                onChangePage={handleSearch}
              />
            </li>
            <li></li>
          </ul>
        </div>
        <div>
          {custGrade === '2' ? (
            <div className='right button-wrap'>
              <Button
                buttonValue='충전금 배분'
                buttonName='button-square-blue'
                onChangePage={handleDistribution}
              />
              <Button
                buttonValue='충전금 회수'
                buttonName='button-square-red'
                onChangePage={handleCollect}
              />
            </div>
          ) : null}

          <div>
            <ChargeTable custType={custType} tableRows={tableRows} />
          </div>
        </div>
      </div>
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
      {devideCharge && (
        <PopUpLayout onClose={popupDevideCharge} poptitle='충전금 배분하기'>
          <LayerPopDevideCharge
            balance={balance}
            custBalanceInfo={custBalanceInfo}
            handlePageChange={handlePageChange}
            setDistributionClosePop={setDistributionClosePop}
            getAlertShown={getAlertShown}
            handleGetBalance={handleGetBalance}
          />
        </PopUpLayout>
      )}
      {collectCharge && (
        <PopUpLayout onClose={popupCollectCharge} poptitle='충전금 회수하기'>
          <LayerPopCollectCharge
            custBalanceInfo={custBalanceInfo}
            handlePageChange={handlePageChange}
            setCollectClosePop={setCollectClosePop}
            getAlertShown={getAlertShown}
            handleGetBalance={handleGetBalance}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export default ChargeHistory;
