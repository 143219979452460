import React from "react";
import $ from 'jquery';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;


/* export class Popup extends React.Component{
  render(){
    return (
      $('.popup-open').click(function(){
            var $href = $(this).attr('href');
            layer_popup($href);
        })
        function layer_popup(el){

            var $el = $(el);//레이어의 id를 $el 변수에 저장
            $el.fadeIn();

            var $elWidth = ~~($el.outerWidth()),
                $elHeight = ~~($el.outerHeight()),
                docWidth = $(document).width(),
                docHeight = $(document).height();

            $el.find('.popup-close').click(function(){
                document.all['popup1'].style.display = "none";
                return false;
            });

            $('.layer .dimBg').click(function(){
                document.all['popup1'].style.display = "none";
                return false;
            });
        }   
    );
  }
} */

export class He30 extends React.Component{
  render(){
    return (
      <p className="he30"></p>
    );
  }
}


export class Radiobox extends React.Component{
  render(){
    return (
      <span className="Radiobox">
          <input type="radio" name="radio" id="id1" checked />
          <label for="id1">라디오</label>
          <input type="radio" name="radio" id="id2" />
          <label for="id2">라디오</label>
          <input type="radio" name="radio" id="id3" disabled />
          <label for="id3">라디오 disabled</label>
      </span>
    );
  }
}

export class Checkbox extends React.Component{
  render(){
    return (
      <span className="Checkbox">
          <input type="checkbox" name="check" id="id4" />
          <label for="id4">체크박스</label>
      </span>
    );
  }
}

export class Textbox extends React.Component{
  render(){
    return (
      <div className="Textbox">
          <label for="ex_input">아이디</label> 
          <input type="text" id="ex_input" placeholder="아이디" /> 
      </div>
    );
  }
}

export class Selectbox extends React.Component{
  render(){
    return (
      <select className="Selectbox" name="selectbox" id="">
          <option value="">전체</option>
          <option value="">옵션1</option>
          <option value="">옵션2</option>
      </select>
    );
  }
}

export class Button extends React.Component{
  render(){
    return (
      <button className={this.props.buttonName} onClick={this.props.onChangePage} style={this.props.style}>{this.props.buttonValue}</button>
    );
  }
}



// export class ButtonDown extends React.Component{
//   render(){
//     return (
//       <button className="buttonDown">엑셀 다운로드</button>
//     );
//   }
// }

// export class ButtonDownDark extends React.Component{
//   render(){
//     return (
//       <button className="buttonDown_dark">엑셀 샘플파일</button>
//     );
//   }
// }

// export class ButtonBorderDark extends React.Component{
//   render(){
//     return (
//       <button className="button-border-dark">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBgcDark extends React.Component{
//   render(){
//     return (
//       <button className="button-bgc-dark">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBorderGray extends React.Component{
//   render(){
//     return (
//       <button className="button-border-gray">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBgcGray extends React.Component{
//   render(){
//     return (
//       <button className="button-bgc-gray">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBorderBlue extends React.Component{
//   render(){
//     return (
//       <button className="button-border-blue">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBgcBlue extends React.Component{
//   render(){
//     return (
//       <button className="button-bgc-blue">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonBgcLightBlue extends React.Component{
//   render(){
//     return (
//       <button className="button-bgc-lightblue">발신번호 등록</button>
//     );
//   }
// }

// export class ButtonSearch extends React.Component{
//   render(){
//     return (
//       <button className="button-search"></button>
//     );
//   }
// }

// export class ButtonSquareBlue extends React.Component{
//   render(){
//     return (
//       <button className="button-square-blue">충전금 배분</button>
//     );
//   }
// }

// export class ButtonSquareRed extends React.Component{
//   render(){
//     return (
//       <button className="button-square-red">충전금 배분</button>
//     );
//   }
// }

export class InfoType1 extends React.Component{
  render(){
    return (
      <p className="info-type1">[필독] 광고문자 전송 가이드 <a href="/" className="ssbtn">자세히</a></p>
    );
  }
}

export class InfoType2 extends React.Component{
  render(){
    return (
      <p className="info-type2">[필독] 광고문자 전송 가이드 <a href="/" className="ssbtn">자세히</a></p>
    );
  }
}

export class InfoType3 extends React.Component{
  render(){
    return (
      <p className="info-type3"><a href="/">[필독] 광고문자 전송 가이드</a></p>
    );
  }
}

export class Pagination extends React.Component{
  render(){
    return (
      <div className="page">
          <ul className="pagination modal">
              <li><a href="/" className="first pagemove"></a></li>
              <li><a href="/" className="left pagemove"></a></li>
              <li><a href="/" className="active num">1</a></li>
              <li><a href="/" className="num">2</a></li>
              <li><a href="/" className="num">3</a></li>
              <li><a href="/" className="num">4</a></li>
              <li><a href="/" className="num">5</a></li>
              <li><a href="/" className="num">6</a></li>
              <li><a href="/" className="num">7</a></li>
              <li><a href="/" className="num">8</a></li>
              <li><a href="/" className="num">9</a></li>
              <li><a href="/" className="right pagemove"></a></li>
              <li><a href="/" className="last pagemove"></a></li>
              <p className="clear"></p>
          </ul>
      </div>
    );
  }
}

export class PaginationDynamic extends React.Component{
  render(){
    return (
      <div className="page">
          <ul className="pagination modal">
              <li><a onClick={() => this.props.onPageChange(1) } className="first pagemove"></a></li>
              <li><a onClick={() => this.props.onPageChange(this.props.leftPage) } className="left pagemove"></a></li>
              {this.props.pages.map(page => (
                <li> 
                  <a  
                    key={page} 
                    className={page === this.props.currentPage ? "active nume" : "num" } 
                    onClick={() => this.props.onPageChange(page)}
                  >{page}</a> 
                </li>
              ))}
              <li><a onClick={() => this.props.onPageChange(this.props.rightPage) } className="right pagemove"></a></li>
              <li><a onClick={() => this.props.onPageChange(this.props.endPage) } className="last pagemove"></a></li>
          </ul>
      </div>
    );
  }
}



export class TableStyle1 extends React.Component{
  render(){
    return (
      <table className="table_style1">
          <tr>
              <th>아이디</th>
              <td>Hisms01</td>
              <th>가입일</th>
              <td>2021-03-29</td>
          </tr>
          <tr>
              <th>고객유형</th>
              <td>개인</td>
              <th>고객(업체)명</th>
              <td>유니컨버스</td>
          </tr>
          <tr>
              <th>휴대폰번호</th>
              <td>010-1234-5678</td>
              <th>주소지</th>
              <td>서울시 강서구 공항대로453</td>
          </tr>
          <tr>
              <th>이메일</th>
              <td>admin@hist.co.kr</td>
              <th>대표 계정</th>
              <td>연동된 계정이 없습니다 <button className="button-border-gray ssbtn">발신번호 등록</button></td>
          </tr>
      </table>
    );
  }
}

export class TableStyle2 extends React.Component{
  render(){
    return (
      <table className="table_style2">
          <tr>
              <th>권한</th>
              <th>사용자 이름</th>
              <th>인증상태</th>
              <th>연락처</th>
              <th>이메일</th>
              <th>수정</th>
              <th>삭제</th>
          </tr>
          <tr>
              <td>대표 관리자</td>
              <td>김유니</td>
              <td>인증완료</td>
              <td>010-1234-5678</td>
              <td>uni@hist.co.kr</td>
              <td><a href="/" className="underline">수정</a></td>
              <td><a href="/" className="underline">삭제</a></td>
          </tr>
          <tr className="disabled">
              <td>사용자</td>
              <td>이유니</td>
              <td className="red">미인증</td>
              <td>010-1234-5678 <button className="button-border-gray ssbtn">본인인증요청</button></td>
              <td>uni@hist.co.kr</td>
              <td><a href="/" className="underline">수정</a></td>
              <td><a href="/" className="underline">삭제</a></td>
          </tr>
          <tr>
              <td>대표 관리자</td>
              <td>김유니</td>
              <td>인증완료</td>
              <td>010-1234-5678</td>
              <td>uni@hist.co.kr</td>
              <td><a href="/" className="underline">수정</a></td>
              <td><a href="/" className="underline">삭제</a></td>
          </tr>
          <tr>
              <td>대표 관리자</td>
              <td>김유니</td>
              <td>인증완료</td>
              <td>010-1234-5678</td>
              <td>uni@hist.co.kr</td>
              <td><a href="/" className="underline">수정</a></td>
              <td><a href="/" className="underline">삭제</a></td>
          </tr>
      </table>
    );
  }
}

export class LayerPopup extends React.Component{
  render(){
    return (
      <div className="dim-layer" id="popup1">
            <div className="dimBg"></div>
            <div className="pop-layer">
                <div className="pop-container">

                    <div className="pop-header">
                        <span className="title">팝업타이틀</span>
                        <a href="javascript:closeWin();" className="popup-close"> <i className="fas fa-times"></i></a>
                    </div>

                    <div className="pop-contns-box">
                        <div className="box-inner">
                            <div className="pop-box">
                                <p className="pop-stitle">팝업 소타이틀</p>
                                <div></div>
                                
                                <div className="button-area right">
                                    <button className="button-bgc-blue">발신번호 등록</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
  }
}