import React, { useState, useEffect } from "react";
import { SEVER_DOMAIN } from "../../../../utils/constant.js";
import { Button } from "../../../../component/Common";
import { PopUpLayout } from "../../../../component/popUp/PopUpLayout";
import { LayerPopImgSub } from "../../../../component/popUp/PopUp";

export function RcsImgList(props) {
  const {} = props;
  const custType = props.custType;
  const custGrade = props.custGrade;
  const [popup, handlePopup] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const handlePopBtn = (url) => {
    setImgUrl(url);
    handlePopup(true);
  };
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
    setDelList(snList);
  }, [tableRows]);
  const setDelList = props.setDelList;
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
    setDelList(sn);
  };
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="6">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td>
              <img src={SEVER_DOMAIN + "/rcs_image/" + row.fileLocation} />
            </td>
            <td>{row.fileName}</td>
            <td> {row.regDate} </td>
            <td>
              <Button
                buttonName="button-border-gray ssbtn center"
                buttonValue="보기"
                onChangePage={(e) => {
                  handlePopBtn(SEVER_DOMAIN + "/rcs_image/" + row.fileLocation);
                }}
              />
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data, custType, custGrade]);
  return (
    <>
      <table className="table_style2 KakaoFriendList">
        <thead>
          <tr>
            {custType === "1" && custGrade === "2" ? <th>계정ID</th> : null}
            <th>미리보기</th>
            <th>파일명</th>
            <th>등록일시</th>
            <th>확대보기</th>
          </tr>
          {tableRows}
        </thead>
      </table>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="이미지 확대보기"
          size="small"
        >
          <LayerPopImgSub imgUrl={imgUrl} />
        </PopUpLayout>
      )}
    </>
  );
}

export default RcsImgList;
