import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "component/Common";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import {
  LayerPopReceiveNumber, // 수신번호 - 엑셀 대량전송
  LayerPopReceiveNumberError, // 수신번호 - 엑셀 대량전송 - 오류검사 - 오류건수
  LayerPopAccountCreation, // 전용계좌 생성하기 (선불만 해당)
  LayerPopSendTestMsg, // 테스트전송
  LayerPopSendMsg, // 전송하기
  LayerPopLoadMsg, // 문구 불러오기
  LayerPopImportAddress, // 수신번호 - 주소록 불러오기
  LayerPopTestCardPay,
  LayerPopRechargeInfo,
} from "component/popUp/PopUp";
import { postRequest, getRequest } from "component/Axios";
import ReceiveList from "../../../component/sendMessage/receiveList/ReceiveList";
import { template } from "@babel/core";
import { doExpression } from "@babel/types";
import { set } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  textField: {
    width: 353,
  },
}));

export const SendKakaoAlrimTalk = (props) => {
  const [importAddress, popupImportAddress] = useState(false); // 수신번호 - 주소록 불러오기
  const [receiveNumberFile, popupReceiveNumberFile] = useState(false); // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

  const [contractAllSelected, setContractAllSelected] = useState(false); // 알림톡 발신 실패 시 대체문자 전송
  const [alternativeCharacter, setAlternativeCharacter] = useState(null); // 대체문자 입력란
  const [alternativeTotal, setAlternativeTotal] = useState(null); // 대체문자 입력 시 최하단 결과란 표시

  const [chekedId, setChekedId] = React.useState("id3_1"); // 전송시간
  const [activeContent, setActiveContent] = React.useState(null); // 전송시간- 예약 datetype

  const [sendTestMsg, popupSendTestMsg] = useState(false); // 테스트 전송
  const [sendMsgConfirm, popupSendMsgConfirm] = useState(false); // 전송하기

  const [charge, setCharge] = useState(false); //전송예상결과에서 충전버튼 클릭 여부 (선불계정 only)

  /*********************************** 수신번호 관련 ***********************************/
  //발송제한금액
  const [daySend, setDaySend] = useState(0);
  const [monthSend, setMonthSend] = useState(0);

  const navigate = useNavigate();
  const [receiveCount, setReceiveCount] = useState(0);
  const [viewTableRows, setviewTableRows] = useState([]); // 수신 목록
  const [fileRegIdList, setFileRegIdList] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [callbackList, setCallbackList] = useState([]);
  const [channelRows, setChannelRows] = useState([]);
  const [templateRows, setTemplateRows] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [contentPhone, setContentPhone] = useState([]);
  const [phoneCount, setPhoneCount] = useState(0);
  const [selectRegId, setSelectRegId] = useState("");
  const [phone, setPhone] = useState("");
  const [templateCd, setTemplateCd] = useState(0);
  const [unitPrce, setUnitPrce] = useState(0);
  const [balance, setBalance] = useState(0);
  const [alimPrce, setAlimPrce] = useState(0);
  const [replaceUse, setReplaceUse] = useState(false);
  const [alternativeCheck, setAlternativeCheck] = useState(false);
  const [btnInfo, setBtnInfo] = useState("");
  const [tpCode, setTpCode] = useState("");
  const [selectIdx, setSelectIdx] = useState(0);
  const [rating, setRating] = useState("2");
  const [uploadType, setUploadType] = useState("1");
  const [smsUnit, setSmsUnit] = useState(0);
  const [lmsUnit, setLmsUnit] = useState(0);
  const [mmsUnit, setMmsUnit] = useState(0);
  const [rechargeInfo, popupRechargeInfo] = useState(false);
  const [sendType, setSendType] = useState(null);

  const templateRef = useRef(null);

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [listUploadType, setListUploadType] = useState("direct");
  
  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    if (alternativeTotal) {
      document.getElementById("replaceType").textContent = "SMS";
      document.getElementById("replacePrce").textContent = smsUnit;
    }
  }, [alternativeTotal]);

  const handleAlternative = (val) => {
    setAlternativeCheck(val);
  };

  function handleBarenceInfo() {
    const url = "/user/userInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          sessionStorage.setItem("pay", jsonArr[0].balance);
        }
      })
      .catch((error) => console.log(error));
  }
  function handleUserInfo() {
    const url = "/user/userInfo";
    setLoading(true);
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);

          let prce = 0;
          if (jsonArr[0].alimPrce) prce = jsonArr[0].alimPrce;

          setAlimPrce(prce);
          setUnitPrce(prce);
          setRating(jsonArr[0].preRating);
          sessionStorage.setItem("pay", jsonArr[0].balance);

          let smsPrce = 0;
          if (jsonArr[0].smsPrce) smsPrce = jsonArr[0].smsPrce;
          setSmsUnit(smsPrce);

          let lmsPrce = 0;
          if (jsonArr[0].lmsPrce) lmsPrce = jsonArr[0].lmsPrce;
          setLmsUnit(lmsPrce);

          let mmsPrce = 0;
          if (jsonArr[0].mmsPrce) mmsPrce = jsonArr[0].mmsPrce;
          setMmsUnit(mmsPrce);

          if (jsonArr[0].daySend) setDaySend(jsonArr[0].daySend);

          if (jsonArr[0].monthSend) setMonthSend(jsonArr[0].monthSend);

          handleChannel();
          handleCallback();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    const payUrl = "/user/payInfo";

    getFetch(payUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    const tpCd = document.getElementById("templateCode").value;
    defaultSetText();
    console.log(tpCd + "템플릿 코드");
    if (tpCd) handleTemplateDetil(tpCd);
  }, [channelId, receiveCount]);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  const handleCallback = () => {
    const url = "/callback/getSelect/";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCallbackList(jsonArr);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleChannel = () => {
    const url = "/kko/getChannel";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChannelId(jsonArr[0].senderKey);
          setChannelRows(jsonArr);
          handleTemplate(jsonArr[0].senderKey);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleTemplate = (senderKey) => {
    const url = "/kko/templateCodeList";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTemplateRows(jsonArr);
          let cnt = 0;
          jsonArr.map((info) => {
            if (senderKey === info.senderKey) {
              if (cnt === 0) {
                templateRef.current.selectedIndex =
                  templateRef.current.options[0];
                handleTemplateDetil(info.tpSn);
                cnt++;
              }
              return;
            }
          });
        }
      })
      .catch((error) => console.log(error));
  };

  function handelDefaultSet() {
    for (let i = 1; i <= phoneCount; i++) {
      const addPhone = "addPhone_" + i;
      const addFile = "filePhone_" + i;
      if (document.getElementById(addPhone))
        document.getElementById(addPhone).remove();

      if (document.getElementById(addFile))
        document.getElementById(addFile).remove();
    }
    getCountTotal();
    handleUserInfo();
    //handleContents('');

    // 대체문자 여부
    // 광고 여부
    document.getElementById("advertising").checked = false;
    setAlternativeCharacter(null);
    setAlternativeTotal(null);
    setReplaceUse(false);

    /* 발송시간 */
    setChekedId("id3_1");
    setActiveContent(null);

    /* 수신번호 목록 */
    setContentPhone([]);
    setFileRegIdList([]);
    setviewTableRows([]);
    setPhone("");
  }

  const [mergeTextField, setmMrgeTextField] = useState({
    input0: "",
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });

  const handleTextValueChange = (e) => {
    const { name, value } = e.target;
    setmMrgeTextField((mergeTextField) => {
      return { ...mergeTextField, [name]: value };
    });
  };

  const [mergeTextArr, setMergeTextArr] = useState([]);
  const handleTemplateDetil = (sn) => {
    defaultSetText();
    const url = "/kko/templateDetail/" + sn;
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTemplateCd(sn);
          setTemplateText(jsonArr[0].tpText);
          setTpCode(jsonArr[0].tpCode);
          document.getElementById("previewHead").textContent = jsonArr[0].tpNm;
          document.getElementById("msgContentsPreview").value =
            jsonArr[0].tpText;

          if (jsonArr[0].countBtn > 0) {
            let btn_info = "";
            for (let i = 0; i < jsonArr.length; i++) {
              let num = i + 1;
              document.getElementById("previewBtn" + num).style.display =
                "block";
              document.getElementById("previewBtn" + num).textContent =
                jsonArr[i].btnNm;
              if (btn_info) {
                btn_info =
                  btn_info +
                  "," +
                  jsonArr[i].btnType +
                  "|" +
                  jsonArr[i].btnNm +
                  "|" +
                  jsonArr[i].btnLink1 +
                  "|" +
                  jsonArr[i].btnLink2;
              } else {
                btn_info =
                  jsonArr[i].btnType +
                  "|" +
                  jsonArr[i].btnNm +
                  "|" +
                  jsonArr[i].btnLink1 +
                  "|" +
                  jsonArr[i].btnLink2;
              }
              setBtnInfo(btn_info);
            }
          }

          const text = jsonArr[0].tpText;
          const regText = /#\{.*?\}/g;

          let matchText = text.match(regText);

          //console.log(matchText);
          const set = new Set(matchText);

          const uniqueArr = [...set];

          const idMerge = [
            "merge_0",
            "merge_1",
            "merge_2",
            "merge_3",
            "merge_4",
          ];
          for (let i = 0; i < 5; i++) {
            if (document.getElementById(idMerge[i])) {
              // document.getElementById(idMerge[i]).remove();
            }
          }

          const idName = [
            "mergeValue_0",
            "mergeValue_1",
            "mergeValue_2",
            "mergeValue_3",
            "mergeValue_4",
          ];
          const idChoice = [
            "mergeChoice_0",
            "mergeChoice_1",
            "mergeChoice_2",
            "mergeChoice_3",
            "mergeChoice_4",
          ];
          const newMergeText = [];

          if (receiveCount === 0) {
            setMergeTextArr([]);
          } else {
            setMergeTextArr(
              newMergeText.concat(
                <div id="mergeSelect">
                  {uniqueArr.map((row, index) => (
                    <div id={idMerge[index]}>
                      <input type="hidden" id={idName[index]} value={row} />
                      <div className="tit-left select-tit">
                        <span className="required">{row}</span>
                      </div>
                      <div className="tit-right">
                        <select id={idChoice[index]} className="wid100 mb10">
                          <option value="" disabled selected>
                            머지항목
                          </option>
                          <option value="NAME">#이름</option>
                          <option value="ETC1">#1</option>
                          <option value="ETC2">#2</option>
                          <option value="ETC3">#3</option>
                          <option value="ETC4">#4</option>
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
              )
            );
          }
        }
      })
      .catch((error) => console.log(error));
  };

  function defaultSetText() {
    setTemplateText("");
    setBtnInfo("");
    setTpCode("");
    document.getElementById("previewHead").textContent = "";
    document.getElementById("msgContentsPreview").value = "";
    for (let i = 1; i < 6; i++) {
      document.getElementById("previewBtn" + i).style.display = "none";
    }
    const idMerge = ["merge_0", "merge_1", "merge_2", "merge_3", "merge_4"];
    for (let i = 0; i < 5; i++) {
      if (document.getElementById(idMerge[i])) {
        //document.getElementById(idMerge[i]).remove();
      }
    }
  }

  const uploadPopClose = (id, cnt, type) => {
    popupReceiveNumberFile(false);

    // 셋팅
    handleUploadFile(id, cnt, type);
  };

  function uploadAddrPopClose(id, cnt) {
    popupImportAddress(false);
    handleUploadFile(id, cnt, "2");
  }

  function modifyAddrPopClose(id, cnt, delCnt) {
    popupImportAddress(false);
    if (delCnt > 0) {
      const delNum = document.getElementById(id).value;
      handleDeleteNewFile(delNum);
      if (cnt > 0) handleUploadFile(id, cnt, "1");
    }
    getCountTotal();
  }

  function handleUploadFile(id, cnt, type) {
    let count = phoneCount + 1;

    const row = contentPhone;
    const addText = "filePhone_" + count;
    const fileVal = "fileVal_" + count;
    const fileCnt = "fileCnt_" + count;

    let tot = receiveCount + cnt;
    setContentPhone(
      contentPhone.concat(
        <li id={addText}>
          <input type="hidden" id={fileVal} value={id} />
          <input type="hidden" name={fileCnt} id={fileCnt} value={cnt} />
          <input type="hidden" id={id} value={count} />
          <a
            onClick={(e) => {
              OpenReceiveNumberFile(id, count, type);
            }}
            className="blue"
          >
            {type === "2" ? "주소록" : null} 파일 불러오기 ({cnt}건)
          </a>
          <a
            onClick={(e) => {
              handleDeleteNewFile(count);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </li>
      )
    );

    setPhoneCount(count);
    setReceiveCount(tot);
  }

  const handleDeleteNewFile = (cnt) => {
    // 수신번호 카드의 X버튼
    //console.log('해당 발송자정보 삭제');
    const addText = "filePhone_" + cnt;
    document.getElementById(addText).remove();

    getCountTotal();
  };

  function getCountTotal() {
    let num = 0;
    for (let i = 1; i <= Number(phoneCount) + 10; i++) {
      const addFile = "filePhone_" + i;
      const addPhone = "addPhone_" + i;
      const fileCnt = "fileCnt_" + i;
      if (document.getElementById(addPhone)) {
        num = Number(num) + 1;
      }

      if (document.getElementById(addFile)) {
        const cnt = document.getElementById(fileCnt).value;
        num = Number(num) + Number(cnt);
      }
    }
    setReceiveCount(num);
  }

  function modifyPopClose(id, cnt, delCnt, type) {
    popupReceiveNumberFile(false);
    if (delCnt > 0) {
      const delNum = document.getElementById(id).value;
      handleDeleteNewFile(delNum);
      if (cnt > 0) handleUploadFile(id, Number(cnt) - Number(delCnt), type);
    }
    getCountTotal();
  }

  const AddReceiveNumber = (event) => {
    //수신번호 1만건 기준 이벤트 적용

    event.preventDefault();
    //console.log('수신번호 추가');

    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;
    if (!phone) {
      alert("수신번호를 입력하세요.");
      return;
    } else if (!regexMobile.test(phone)) {
      alert("잘못된 형식의 수신번호 입니다.");
      return;
    }

    let count = phoneCount + 1;
    let tot = receiveCount + 1;
    const row = contentPhone;
    const addText = "addPhone_" + count;
    const phoneVal = "addVal_" + count;

    setContentPhone(
      contentPhone.concat(
        <li id={addText}>
          <input type="hidden" id={phoneVal} value={phone} />
          {phone}
          <a
            onClick={(e) => {
              handleDeleteNewUser(count);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </li>
      )
    );

    setPhoneCount(count);
    setPhone("");
    setReceiveCount(tot);
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const OpenAddressBook = (event) => {
    // 주소록 불러오기
    //console.log(infoData);

    event.preventDefault();
    popupImportAddress(true);
  };

  const OpenReceiveNumberFile = (id, count, type) => {
    // 엑셀 대량전송
    setSelectIdx(count);
    setSelectRegId(id);
    setUploadType(type);
    popupReceiveNumberFile(true);
  };

  const handleDeleteNewUser = (cnt) => {
    // 수신번호 카드의 X버튼
    const addText = "addPhone_" + cnt;
    document.getElementById(addText).remove();
    getCountTotal();
  };

  const RecipientAllDelete = (event) => {
    // 수신번호 전체삭제

    event.preventDefault();
    //console.log('수신번호 전체삭제');

    if (window.confirm("모든 수신번호를 삭제 하시겠습니까?")) {
      for (let i = 1; i <= phoneCount; i++) {
        const addPhone = "addPhone_" + i;
        const addFile = "filePhone_" + i;
        if (document.getElementById(addPhone))
          document.getElementById(addPhone).remove();

        if (document.getElementById(addFile))
          document.getElementById(addFile).remove();
      }
      setReceiveCount(0);
    }
  };

  /*********************************** 수신번호 관련 end***********************************/
  /*********************************** 대체문자 발송 ***********************************/
  function getCheckboxValue(event) {
    // 알림톡 발신 실패 시 대체문자 전송

    if (event.target.checked) {
      setAlternativeCharacter(
        <AlternativeCharacter setAlternativeCheck={setAlternativeCheck} />
      );

      setAlternativeTotal(
        <AlternativeTotal alternativeType={"SMS"} smsUnit={smsUnit} />
      );
      setReplaceUse(true);
    } else {
      setAlternativeCharacter(null);
      setAlternativeTotal(null);
      setReplaceUse(false);
    }
  }
  /*********************************** 대체문자 발송 end ***********************************/
  /*********************************** 전송시간 ***********************************/
  const sendTime = [
    { id: "id3_1", value: "즉시", content: null },
    { id: "id3_2", value: "예약", content: <Reservation /> },
  ];
  /*********************************** 전송시간 end ***********************************/
  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 (선불only) ***********************************/
  const handleRefresh = (e) => {
    e.preventDefault();
    const userInfoUrl = "/user/userInfo";
    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setBalance(jsonArr[0].balance);
          setRating(jsonArr[0].preRating);
          sessionStorage.setItem("pay", jsonArr[0].balance);
        }
      })
      .catch((error) => console.log(error));
  };
  const showChargeAccount = () => {
    if (charge) {
      setCharge(false);
    } else {
      setCharge(true);
    }
  };
  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 end (선불only) ***********************************/
  /*********************************** 테스트전송 컨펌창 ***********************************/
  function getTestSendMsg(event) {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("T");
    if (balance < unitPrce * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendTestMsg(true);
    }
  }
  /*********************************** 테스트전송 컨펌창 end ***********************************/
  /*********************************** 전송하기 컨펌창 ***********************************/
  const SendMsgConfirm = (event) => {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("R");
    if (balance < unitPrce * receiveCount * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendMsgConfirm(true);
    }
  };
  /*********************************** 전송하기 컨펌창 end ***********************************/
  const sendMsg = () => {
    sendMessage("N", "");
  };

  const sendMsgTest = (numPhone) => {
    if (!numPhone) {
      alert("메시지를 발송할 휴대폰 번호가 존재하지 않습니다.");
      return;
    }
    sendMessage("Y", numPhone);
  };

  const sendMessage = (testYn, numPhone) => {
    let phoneList = [];
    let fileList = fileRegIdList;
    let cntNum = 0;

    if (testYn === "Y") {
      phoneList.push(numPhone);
      cntNum = 1;
      fileList = [] ; // jy 2023.01.17
    } else {
      cntNum = receiveCount;
    }

    if (cntNum === 0) {
      alert("메시지를 발송할 수신번호가 존재하지 않습니다.");
      return;
    }

    const id = document.getElementById("channelId");
    const plusID = id.options[id.selectedIndex].text;
    const sender = document.getElementById("channelId").value;

    if (!sender) {
      alert("채널명을 선택해 주세요.");
      return;
    }

    const tpCd = document.getElementById("templateCode").value;

    if (!tpCd) {
      alert("템플릿을 선택해 주세요.");
      return;
    }

    let mergeCheck = "N";
    let sendCnt = 1;
    let mergeText = "";

    // 머지확인
    const idMerge = ["merge_0", "merge_1", "merge_2", "merge_3", "merge_4"];
    const idName = [
      "mergeValue_0",
      "mergeValue_1",
      "mergeValue_2",
      "mergeValue_3",
      "mergeValue_4",
    ];
    const idChoice = [
      "mergeChoice_0",
      "mergeChoice_1",
      "mergeChoice_2",
      "mergeChoice_3",
      "mergeChoice_4",
    ];

    if (testYn === "N") {
      for (let i = 0; i < 5; i++) {
        if (document.getElementById(idMerge[i])) {
          const textNm = document.getElementById(idName[i]).value;
          const mergeVal = document.getElementById(idChoice[i]).value;
          if (!mergeVal) {
            alert("템플릿 에 있는 변수에 머지필드가 선택되어 있지 않습니다.");
            return;
          }
          if (!mergeText) mergeText = textNm + "|" + mergeVal;
          else mergeText = mergeText + "," + textNm + "|" + mergeVal;

          mergeCheck = "Y";
        }
      }
    }

    const callbackNum = document.getElementById("callbackList").value;

    if (callbackNum == 0) {
      alert("발신번호를 등록하세요.");
      return;
    }

    if (!callbackNum) {
      alert("발신번호를 선택해 주세요.");
      return;
    }

    let advertYn = "N";
    let adNumber = "";
    let replaceText = "";
    let headerText = "";
    let footerText = "";
    let mainText = "";
    // 대체문자 발송여부
    let failover = "0";
    if (replaceUse) {
      failover = "2";
      if (rating === "2" && receiveCount * smsUnit * 1.1 > balance) {
        alert(
          "대체발송을 위한 충전금이 부족합니다.\n 대체문자 발송여부를 해제해주세요."
        );
        return;
      }

      mainText = document.getElementById("replaceText").value;
      if (alternativeCheck) {
        advertYn = "Y";
        //080번호 체크

        adNumber = document.getElementById("adNumber").value;
        const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
        if (!adNumber) {
          alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
          return;
        } else if (!regexMobile.test(adNumber)) {
          alert("잘못된 형식의 080 번호 입니다. 입니다.");
          return;
        }
        headerText = "(광고)" + document.getElementById("adTitle").value + "\n";
        footerText = "\n무료거부 " + adNumber;
      }
      replaceText = headerText + mainText + footerText;
      replaceText = headerText + mainText + footerText;
      const regText = /#\{.*?\}/g;

      let matchText = replaceText.match(regText);

      //console.log(matchText);
      const set = new Set(matchText);

      const uniqueArr = [...set];
      if (uniqueArr.length > 0 && phoneList.length > 0) {
        alert("머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다.");
        return;
      }
    }

    const text = document.getElementById("msgContents").value;

    let sendType = "1";
    let sendDayTime;
    if (testYn === "N") {
      sendCnt = receiveCount;
      if (chekedId === "id3_2") {
        sendDayTime = document.getElementById("datetime-local").value;
        if (!sendDayTime) {
          alert("예약시간을 선택해 주세요.");
          return;
        }
        sendType = "2";
      }
    }

    let url = "/message/messageReady";
    let data = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: "",
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: "4",
      advertYn: advertYn,
      totCnt: sendCnt,
      kakaoMethod: "p",
      templateCode: tpCode,
      replaceText: replaceText,
      senderKey: sender,
      failover: failover,
      plusId: plusID,
      mergeText: mergeText,
      btnInfo: btnInfo,
    };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "1") {
          let msg = result.resultMsg;
          if (window.confirm(msg)) {
            const jsonArr = JSON.parse(result.resultData);
            const sn = JSON.parse(result.resultData).tmSn;
            const customRegIdList = jsonArr.regIdList;
            fnlSendMsg(
              phoneList,
              fileList,
              callbackNum,
              text,
              sendType,
              sendDayTime,
              mergeCheck,
              mergeText,
              advertYn,
              replaceText,
              sender,
              failover,
              plusID,
              sn,
              testYn,
              "Y",
              sendCnt,
              daySend,
              monthSend,
              customRegIdList
            );
          }
        } else if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          const sn = JSON.parse(result.resultData).tmSn;
          const customRegIdList = jsonArr.regIdList;
          fnlSendMsg(
            phoneList,
            fileList,
            callbackNum,
            text,
            sendType,
            sendDayTime,
            mergeCheck,
            mergeText,
            advertYn,
            replaceText,
            sender,
            failover,
            plusID,
            sn,
            testYn,
            "N",
            sendCnt,
            daySend,
            monthSend,
            customRegIdList
          );
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  };

  const fnlSendMsg = (
    phoneList,
    fileList,
    callbackNum,
    text,
    sendType,
    sendDayTime,
    mergeCheck,
    mergeText,
    advertYn,
    replaceText,
    sender,
    failover,
    plusID,
    sn,
    testYn,
    duplicateYn,
    sendCnt,
    daySend,
    monthSend,
    customRegIdList
  ) => {
    let url = "/message/messageSendAlim";
    let data = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: "",
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: "4",
      advertYn: advertYn,
      totCnt: sendCnt,
      kakaoMethod: "p",
      templateCode: tpCode,
      replaceText: replaceText,
      senderKey: sender,
      failover: failover,
      plusId: plusID,
      mergeText: mergeText,
      btnInfo: btnInfo,
      tmSn: sn,
      daySend: daySend,
      monthSend: monthSend,
      customRegIdList: customRegIdList,
    };

    console.log("문자보내기 컨펌", data);
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          if (testYn === "Y") {
            popupSendTestMsg(false);
          } else {
            popupSendMsgConfirm(false);
            handelDefaultSet();
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  function setComma(num) {
    if (num > 999) {
      const cn = num
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return cn;
    } else {
      return num.toFixed(2);
    }
  }
  const closeSendMsgPop = () => {
    popupSendMsgConfirm(false);
  };

  const closeSendMsgTestPop = () => {
    popupSendTestMsg(false);
  };
  const closeInsufficientBalancePop = () => {
    popupRechargeInfo(false);
  };

  const handleCertification = (event) => {
    event.preventDefault();
    console.log("발신번호 인증");
  };

  const handlePopClose = () => {
    popupReceiveNumberFile(false);
  };

  return (
    <>
      <div>
        {
          loading == true && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />          
          )
        }
        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">채널명</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="channelId"
                  name="channelId"
                  data-fakeform-index="0"
                  className="wid100"
                  onChange={(e) => {
                    setChannelId(e.target.value);
                  }}
                >
                  {channelRows.map((row) => (
                    <option value={row.senderKey}>{row.plusId}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <p className="line"></p>
        </div>

        {/*  수신번호 */}
        <ReceiveList
          phone={phone}
          setPhone={setPhone}
          phoneCount={phoneCount}
          setPhoneCount={setPhoneCount}
          contentPhone={contentPhone}
          setContentPhone={setContentPhone}
          receiveCount={receiveCount}
          setReceiveCount={setReceiveCount}
          getCountTotal={getCountTotal}
          setFileRegIdList={setFileRegIdList}
          viewTableRows={viewTableRows}
          setviewTableRows={setviewTableRows}
          messageType="alrimTalk"
          setLoading={setLoading}
          listUploadType={listUploadType}
          setListUploadType={setListUploadType}
        />

        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">템플릿 선택</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="templateCode"
                  name="templateCode"
                  data-fakeform-index="0"
                  ref={templateRef}
                  className="wid100"
                  onChange={(e) => {
                    handleTemplateDetil(e.target.value);
                  }}
                >
                  {templateRows.map((row) =>
                    row.senderKey === channelId ? (
                      <option value={row.tpSn}>{row.tpNm}</option>
                    ) : null
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="write-left-inner receive-area">
            <div id="msgArea" className="write-input-con change-effect-bg">
              <div className="write-input-down-con">
                <textarea
                  name="msgContents"
                  id="msgContents"
                  className="gs-custom-textarea change-effect"
                  maxlength="2000"
                  placeholder=""
                  value={templateText}
                ></textarea>
                <div className="write-input-down-info">
                  <span className="msgType">알림톡</span>
                </div>
              </div>
            </div>

            <p className="red mt10">
              * 변수설정 영역만 수정이 가능합니다. 메시지 발송 전 내용을
              확인해주세요
            </p>
          </div>
          <p className="line"></p>
          <div className="write-tit-con clearfix" id="mergeChoice">
            {mergeTextArr}
          </div>
          {receiveCount !== 0 && <p className="line"></p>}
        </div>

        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">발신번호</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="callbackList"
                  name="phone"
                  data-fakeform-index="0"
                  className={receiveCount >= 10000 ? "" : "wid100"}
                >
                  {callbackList.length == 0 && <option value={"0"} selected hidden>발신번호를 등록하세요.</option>}
                  {callbackList.map((row) => (
                    <option value={row.callback}>
                      {row.callback}
                      {row.cbType === "1" ? "(기본발신)" : null}
                    </option>
                  ))}
                </select>
                {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                  <Button
                    buttonName="button-bgc-dark"
                    buttonValue="발신번호 인증"
                    onChangePage={handleCertification}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
            <p className="list-item-state red mt5">
              ※ 1만건 이상 발송 시 발신번호 인증 후 메시지 발송이 가능합니다.
            </p>
          ) : null}
          <p className="line"></p>
        </div>

        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">대체문자 발송</span>
            </div>
            <div className="tit-right">
              <fieldset className="gs-custom-checkbox">
                <span className="checkbox-item">
                  <input
                    type="checkbox"
                    id="advertising"
                    name=""
                    onClick={getCheckboxValue}
                  />
                  <label
                    for="advertising"
                    className={contractAllSelected ? "checked" : null}
                  >
                    알림톡 발송 실패 시 대체문자 전송하기
                  </label>
                </span>
              </fieldset>
            </div>
          </div>
          {alternativeCharacter}
          <p className="line"></p>
        </div>

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">전송시간</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {sendTime.map((value) => (
                    <>
                      <input
                        type="radio"
                        name=""
                        id={value.id}
                        checked={chekedId === value.id}
                        onClick={function (e) {
                          setActiveContent(value.content);
                          setChekedId(value.id);
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </>
                  ))}
                </div>
                {activeContent}
              </div>
            </div>
          </div>
          <p className="line"></p>
        </div>

        <div className="write-total-con clearfix" id="">
          <div className="write-total-left-con">
            <div className="write-total-top">
              <div className="inner clearfix">
                <div className="left">전송 금액</div>
                <div className="right">
                  <p className="price sendPrice">
                    {setComma(unitPrce * receiveCount * 1.1)} 원
                  </p>
                  <span className="txt">(부가세 포함)</span>
                </div>
              </div>
            </div>
            <div className="write-total-bottom-wrap">
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      메시지 타입
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">카카오 알림톡</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      건당 요금
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{alimPrce}</span>원
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {alternativeTotal} {/* 대체문자 전송 시 표시 */}
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{receiveCount}</span>건
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 선불계정일 때만 표시 */}
              {rating === "2" ? (
                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        내 충전금
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input">
                        <div className="input-con">
                          <a href="/" onClick={handleRefresh}>
                            <FontAwesomeIcon
                              icon={faRedoAlt}
                              className="gray999 mr10 cursor size8"
                            />
                          </a>
                          <span className="mr10">
                            {setComma(parseInt(sessionStorage.getItem("pay")))}
                          </span>
                          원
                          {
                            payInfo == 2 ? <Button
                                              buttonName="cm-highlight-btn ml10"
                                              buttonValue="카드결제"
                                              onChangePage={handleTestCardPay}
                                            />
                                          : <Button
                                          buttonName="cm-highlight-btn ml10"
                                          buttonValue="충전"
                                          onChangePage={() => showChargeAccount()}
                                        />
                          }
                          <div id="payRender" name="payRender"></div>
                        </div>
                        {charge && (
                          <div className="input-con">
                            <MyAccountInfo setBalance={setBalance} infoData={infoData} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* 선불계정일 때만 표시 */}
            </div>
          </div>
        </div>

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="button-wrap rfloat">
              <Button
                buttonName="button-border-blue"
                buttonValue="테스트전송"
                onChangePage={getTestSendMsg}
              />
              <Button
                buttonName="button-bgc-blue"
                buttonValue="전송하기"
                onChangePage={SendMsgConfirm}
              />
              {rechargeInfo && (
                <PopUpLayout
                  onClose={popupRechargeInfo}
                  poptitle="잔액 부족 안내"
                  size="small"
                >
                  <LayerPopRechargeInfo
                    payInfo={payInfo}
                    accountNo={accountNo}
                    sendType={sendType}
                    popupSendTestMsg={popupSendTestMsg}
                    popupSendMsgConfirm={popupSendMsgConfirm}
                    closeInsufficientBalancePop={closeInsufficientBalancePop}
                    balance={balance}
                    setBalance={setBalance}
                    nowBalance={unitPrce}
                    receiveCount={receiveCount}
                  />
                </PopUpLayout>
              )}
            </div>
          </div>
        </div>
        <p className="clear"></p>
      </div>
      {sendMsgConfirm && (
        <PopUpLayout onClose={popupSendMsgConfirm} poptitle="메시지 발송">
          <LayerPopSendMsg
            sendMsg={sendMsg}
            closeSendMsgPop={closeSendMsgPop}
            receiveCount={receiveCount}
            unitPrce={unitPrce}
            msgType={"알림톡"}
            chekedId={chekedId}
          />
        </PopUpLayout>
      )}
      {sendTestMsg && (
        <PopUpLayout onClose={popupSendTestMsg} poptitle="테스트전송">
          <LayerPopSendTestMsg
            sendMsgTest={sendMsgTest}
            closeSendMsgTestPop={closeSendMsgTestPop}
            unitPrce={unitPrce}
            msgType={"알림톡"}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout 
          onClose={setTestCardPay}
          poptitle="카드결제"
          size="small"
        >
          <LayerPopTestCardPay
             onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
    </>
  );
};

export const ErrorCheckResults = () => {
  // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

  const [errorList, popupErrorList] = useState(false);
  const OpenErrorList = (event) => {
    event.preventDefault();
    popupErrorList(true);
  };
  return (
    <>
      <div className="write-total-con mt10 clearfix" id="">
        <div className="write-total-left-con">
          <div className="write-total-bottom-wrap">
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">295</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">정상 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">293</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">오류 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <a href="/" className="red" onClick={OpenErrorList}>
                        {" "}
                        <span className="mr10 redline">2</span>건
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorList && (
        <PopUpLayout onClose={popupErrorList} poptitle="오류상세" size="medium">
          <LayerPopReceiveNumberError />
        </PopUpLayout>
      )}
    </>
  );
};

export function AlternativeCharacter(props) {
  // 대체문자 (발신번호 및 메시지 내용)

  /*********************************** 대체문자 메시지내용 ***********************************/
  const setAlternativeCheck = props.setAlternativeCheck;
  const [contractAllSelectedAlternative, setContractAllSelectedAlternative] =
    useState(false); // 대체문자 광고성 정보 여부 - defaultchecked

  const [adNameAlternative, setAdNameAlternative] = useState(null); // 대체문자 광고성 정보체크 - 업체명 또는 서비스명
  const [advertisementAlternative, setAdvertisementAlternative] =
    useState(null); // 대체문자 광고성 정보체크 - 무료거부번호
  const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
  const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창

  const [alternativechekedImgDefault, setAlternativeChekedImgDefault] =
    useState("alternativeImg1"); // 대체메시지 - 이미지 첨부 - 라디오 defaultchecked
  const [alternativeactiveUploadImage, setAlternativeActiveUploadImage] =
    useState(null); // 대체메시지 - 이미지 첨부
  const [selectType, setSelectType] = useState("1");

  const [contents, setContents] = useState("");
  const [contentsHead, setContentsHead] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");

  const [msgByte, setMsgByte] = useState(0);

  const alternativeAd = useRef(null); // 광고 체크박스
  const alternativeTextArea = useRef(null); // 대체메시지 내용

  const [msgType, setMsgType] = useState("SMS");

  const [alternativeFocus, setAlternativeFocus] = useState(0);
  /*********************************** 대체문자 메시지내용 ***********************************/

  useEffect(() => {
    // 대체메시지 광고문구 재 랜더링
    if (contractAllSelectedAlternative) {
      setAdvertisementAlternative(
        <AdvertisementAlternative
          contentsFooter={contentsFooter}
          handleContentsFooter={handleContentsFooter}
        />
      );
      setAdNameAlternative(
        <AdNameAlternative
          contentsHead={contentsHead}
          handleContentsHeader={handleContentsHeader}
        />
      );

      handleContentsFooter(contentsFooter);
      handleContentsHeader(contentsHead);
    }
  }, [contentsHead, contentsFooter]);

  useEffect(() => {
    if (alternativeFocus !== 0) {
      textAreaPosition();
    }
  }, [alternativeFocus]);

  useEffect(() => {
    handleContents(contents);
  }, [contractAllSelectedAlternative]);

  function getAdvertisementAlternative(event) {
    //광고여부 체크

    if (event.target.checked) {
      setAdvertisementAlternative(
        <AdvertisementAlternative
          contentsFooter={contentsFooter}
          handleContentsFooter={handleContentsFooter}
        />
      );
      setAdNameAlternative(
        <AdNameAlternative
          contentsHead={contentsHead}
          handleContentsHeader={handleContentsHeader}
        />
      );
      setContractAllSelectedAlternative(true);
      setAlternativeCheck(true);
    } else {
      setAdvertisementAlternative(null);
      setAdNameAlternative(null);
      setContentsHead("");
      setContentsFooter("");
      setContractAllSelectedAlternative(false);
      setAlternativeCheck(false);
    }
  }

  const LoadMsgAlternative = (event) => {
    // 문구 불러오기

    event.preventDefault();
    popupLoadMsg(true);
  };

  const handleRetainMsgSet = (hText, mText, fText, rMsgType, rAdvertYn) => {
    if (rAdvertYn === "Y") {
      setContentsHead(hText);
      handleContentsFooter(fText);
      if (!contractAllSelectedAlternative) {
        alternativeAd.current.click();
      }
    } else {
      if (contractAllSelectedAlternative) {
        alternativeAd.current.click();
      }
    }
    handleContents(mText);
    popupLoadMsg(false);
  };

  function getByte(str) {
    return str
      .split("")
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  }
  const handleContents = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    if (contractAllSelectedAlternative) {
      const add_len = contentsHead.length + contentsFooter.length;
      text_len = text_len + add_len;
    }

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    if (contractAllSelectedAlternative) {
      totalByte = getByte(text + contentsHead + contentsFooter) + 21;
    } else {
      totalByte = getByte(text);
    }

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContents(text_val);
      setMsgByte(totalByte);
    }
  };
  const handleContentsHeader = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    const add_len = contents.length + contentsFooter.length;
    text_len = text_len + add_len;

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    totalByte = getByte(text + contents + contentsFooter) + 21;

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContentsHead(text_val);
      setMsgByte(totalByte);
    }
  };

  const handleContentsFooter = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    const add_len = contents.length + contentsHead.length;

    text_len = text_len + add_len;

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    totalByte = getByte(contentsHead + contents + text) + 21;

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContentsFooter(text_val);
      setMsgByte(totalByte);
    }
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const SaveMsgAlternative = (event) => {
    // 문구 저장하기
    event.preventDefault();
    let advertYn = "N";
    let footerText = "";
    if (contractAllSelectedAlternative) {
      advertYn = "Y";
      //080번호 체크

      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!contentsFooter) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(contentsFooter)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }

    if (!contents) {
      alert("저장할 대체 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 대체 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: contentsHead,
        mainText: contents,
        footerText: contentsFooter,
        subject: "",
        msgType: "REP_" + msgType,
        advertYn: advertYn,
      };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => console.log(error));
    }
  };

  const showMergeSettingsAlternative = () => {
    // 머지설정

    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };

  const handleContentsName = (text) => {
    const beforeTxt = contents.substring(
      0,
      alternativeTextArea.current.selectionStart
    );
    const afterTxt = contents.substring(
      alternativeTextArea.current.selectionEnd,
      contents.length
    );
    let selectPos = alternativeTextArea.current.selectionStart;

    console.log(alternativeTextArea);
    setAlternativeFocus(selectPos + text.length);
    handleContents(beforeTxt + text + afterTxt);
  };

  const textAreaPosition = () => {
    alternativeTextArea.current.setSelectionRange(
      alternativeFocus,
      alternativeFocus
    );
    alternativeTextArea.current.focus();
  };
  /*********************************** 이미지 첨부***********************************/

  /*********************************** 이미지 첨부 end ***********************************/

  let receiveCount = "19998"; //수신번호 총 건수

  const handleCertification = (event) => {
    event.preventDefault();
    console.log("발신번호 인증");
  };

  return (
    <>
      <p className="line"></p>

      <div className="write-left-inner" id="sendNumber">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">대체메시지</span>
          </div>
          <div className="tit-right">
            <fieldset className="gs-custom-checkbox">
              <span className="checkbox-item">
                <input
                  type="checkbox"
                  id="Alternative"
                  name=""
                  onClick={getAdvertisementAlternative}
                />
                <label
                  for="Alternative"
                  ref={alternativeAd}
                  className={contractAllSelectedAlternative ? "checked" : null}
                >
                  광고성 정보가 포함되어 있습니다
                </label>
              </span>
            </fieldset>
          </div>
        </div>
      </div>
      <div id="msgArea" className="write-input-con change-effect-bg">
        {/* 광고성 정보 체크 시에만 노출 */}
        {adNameAlternative}
        {/* 광고성 정보 체크 시에만 노출 */}
        <div className="write-input-down-con">
          <textarea
            name=""
            id="replaceText"
            className="gs-custom-textarea change-effect"
            placeholder="내용 입력
            - 90 byte 이내"
            maxlength="2000"
            value={contents}
            ref={alternativeTextArea}
            onChange={(e) => {
              handleContents(e.target.value);
            }}
          ></textarea>
          <div className="write-input-down-info">
            <span className="msgType">SMS</span>
            <span className="byte" id="msgByte">
              {msgByte} / <em className="red">90 Byte</em>
            </span>
          </div>
        </div>
        {/* 광고성 정보 체크 시에만 노출 */}
        {advertisementAlternative}
      </div>
      <div className="write-number-down-btn mt20">
        <ul className="clearfix button-col3">
          <li>
            <Button
              buttonName="button-border-gray"
              buttonValue="문구 불러오기"
              onChangePage={LoadMsgAlternative}
            />
          </li>
          <li>
            <Button
              buttonName="button-border-gray"
              buttonValue="문구 저장하기"
              onChangePage={SaveMsgAlternative}
            />
          </li>
          <li>
            <Button
              buttonName={
                mergeVariableState ? "button-bgc-blue" : "button-border-gray"
              }
              buttonValue="머지설정"
              onChangePage={() => showMergeSettingsAlternative()}
            />
            {mergeVariableState ? (
              <MergeSettings handleContentsName={handleContentsName} />
            ) : null}
          </li>
        </ul>
      </div>

      {loadMsg && (
        <PopUpLayout
          onClose={popupLoadMsg}
          poptitle="문구 불러오기"
          size="medium"
        >
          <LayerPopLoadMsg
            selectType={selectType}
            handleRetainMsgSet={handleRetainMsgSet}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function AdNameAlternative(props) {
  const contentsHead = props.contentsHead;
  const handleContentsHeader = props.handleContentsHeader;
  // 대체메시지 내용 - 광고성 정보 체크 시 - 업체명 또는 서비스명 입력란
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02">
        <input
          type="text"
          id="adTitle"
          name=""
          title="제목"
          placeholder="업체명 또는 서비스명 입력"
          value={contentsHead}
          onChange={(e) => handleContentsHeader(e.target.value)}
        />
      </div>
    </div>
  );
}

export function AdvertisementAlternative(props) {
  // 대체메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
  const contentsFooter = props.contentsFooter;
  const handleContentsFooter = props.handleContentsFooter;
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id="adNumber"
        name=""
        title="무료거부번호"
        placeholder="080 무료거부번호 입력"
        value={contentsFooter}
        maxLength={11}
        onChange={(e) => handleContentsFooter(e.target.value)}
      />
    </div>
  );
}

export function MergeSettings(props) {
  const handleContentsName = props.handleContentsName;
  // 메시지 내용 - 머지설정
  const SetVariableName = (event) => {
    event.preventDefault();
    handleContentsName("#{이름}");
  };
  const SetVariable1 = (event) => {
    event.preventDefault();
    handleContentsName("#{1}");
  };
  const SetVariable2 = (event) => {
    event.preventDefault();
    handleContentsName("#{2}");
  };
  const SetVariable3 = (event) => {
    event.preventDefault();
    handleContentsName("#{3}");
  };
  const SetVariable4 = (event) => {
    event.preventDefault();
    handleContentsName("#{4}");
  };
  return (
    <div className="variable-info-con" id="">
      <div>
        <table className="variable-info-tb" id="">
          <tbody>
            <tr>
              <td byte="10">
                <Button
                  buttonName=""
                  buttonValue="#{이름}"
                  onChangePage={SetVariableName}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{1}"
                  onChangePage={SetVariable1}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{2}"
                  onChangePage={SetVariable2}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{3}"
                  onChangePage={SetVariable3}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{4}"
                  onChangePage={SetVariable4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function ImageUpload() {
  // 대체이미지

  const LoadingImg = (event) => {
    event.preventDefault();
    //console.log('이미지 불러오기');
  };
  return (
    <>
      <div className="mt20 mb20">
        가로기준 최대 640px입니다.
        <br />
        640보다 큰 이미지는 640으로 자동 조정되어 발송됩니다.
        <br />
        <br />
        * 크기조정시 가로X세로 비율이 약 1:2 이상인 경우 용량초과로
        <br />
        첨부되지 않을 수 있으니 확인 후 등록 바랍니다.
      </div>
      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id=""
            name=""
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="파일등록"
            onChangePage={LoadingImg}
          />
        </div>
      </div>
    </>
  );
}

export function Reservation() {
  // 전송시간 - 예약

  const classes = useStyles();
  return (
    <span>
      <TextField
        id="datetime-local"
        type="datetime-local"
        defaultValue={new Date()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </span>
  );
}

// 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)
export function MyAccountInfo(props) {
  // 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)
  const [accountCreation, popupAccountCreation] = useState(false);
  const [testCardPay, setTestCardPay] = useState(false);
  const setBalance = props.setBalance;
  const [dataCheck, setDataCheck] = useState(null);
  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const infoData = props.infoData;
  const handleAccountNumber = (event) => {
    event.preventDefault();
    popupAccountCreation(true);
  };
  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };
  const handleTestVbankPay = (event) => {
    event.preventDefault();
    alert("준비중 입니다.");
  };
  useEffect(() => {
    const url = "/user/payInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
          console.log("jsonArr" + jsonArr.payType);
        }
      })
      .catch((error) => console.log(error));
  }, [accountNo]);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);
  return (
    <div className="my-account-info">
      {/* 전용 계좌 생성 전 */}
      {payInfo === "1" && accountNo === undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              생성한 전용계좌가 없습니다.
            </fieldset>
          </div>
          <div className="right">
            <a href="/" onClick={handleAccountNumber} className="underline">
              내 전용계좌 생성하기
            </a>
          </div>
        </div>
      ) : null}
      {payInfo === "1" && accountNo != undefined  && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">하나은행</fieldset>
          </div>
          <div className="right">{accountNo}</div>
        </div>
      ) : null}
      {payInfo === "2" ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">
            <Button
              buttonName="cm-highlight-btn ml10"
              buttonValue="카드결제"
              onChangePage={handleTestCardPay}
            ></Button>
          </div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {payInfo === undefined || payInfo === null && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">결제 타입 미지정</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {infoData.custType != 1 && infoData.custType != 2 ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right red">계정 승인 후 충전 가능합니다.</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}

      {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
          <LayerPopAccountCreation
            popupAccountCreation={popupAccountCreation}
            setAccountNo={setAccountNo}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}>
          <LayerPopTestCardPay
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
      {/* 전용 계좌 생성 후 */}
      {/* <div className="clearfix center">
                국민은행 1301-1111-1234
            </div> */}
    </div>
  );
}

export function AlternativeTotal() {
  // 대체문자 선택 시 최하단 결과란에 반영

  return (
    <>
      <div className="write-total-bottom">
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">대체문자 타입</fieldset>
          </div>
          <div className="right">
            <div className="gs-custom-input-btn-group">
              <div className="input-con" id="replaceType"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="write-total-bottom">
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              대체문자 건당 요금
            </fieldset>
          </div>
          <div className="right">
            <div className="gs-custom-input-btn-group">
              <div className="input-con">
                <span className="mr10" id="replacePrce"></span>원
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function PreviewAlrimTalk() {
  // 알림톡 미리보기 이미지

  const style = {
    head: {
      position: "absolute",
      top: "70px",
      left: "7%",
      width: "86%",
      height: "40px",
      lineHeight: "40px",
      backgroundColor: "#fee800",
      color: "#222222",
      fontWeight: "bold",
      fontSize: "14px",
      textAlign: "center",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    area: {
      position: "absolute",
      left: "7%",
      top: "110px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "86%",
      minHeight: "200px",
      maxHeight: "390px",
      padding: "15px",
      color: "#4a4a4a",
      lineHeight: "22px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      fontSize: "15px",
      overflow: "hidden",
    },
    buttonWrap: {
      width: "100%",
      marginTop: "10px",
    },
    button: {
      width: "100%",
      padding: "8px 0",
      textAlign: "center",
      backgroundColor: "#f5f4f6",
      color: "#222",
      marginBottom: "10px",
      borderRadius: "3px",
      fontSize: "14px",
    },
  };
  return (
    <>
      <div style={style.head} id="previewHead"></div>

      <div style={style.area}>
        <textarea
          name="msgContentsPreview"
          id="msgContentsPreview"
          className="gs-custom-textarea change-effect"
          readOnly
        ></textarea>
        <ul style={style.buttonWrap} id="previewBtnList" name="previewBtnList">
          <div style={style.button} id="previewBtn1" name="previewBtn"></div>
          <div style={style.button} id="previewBtn2" name="previewBtn"></div>
          <div style={style.button} id="previewBtn3" name="previewBtn"></div>
          <div style={style.button} id="previewBtn4" name="previewBtn"></div>
          <div style={style.button} id="previewBtn5" name="previewBtn"></div>
        </ul>
      </div>
    </>
  );
}

const ReceiveListSettingInfo = styled.div`
  padding: 14px;
  text-align: center;
  line-height: 1.3 !important;
  background-color: #fff0f0;
  border: 1px solid #ff3535;
  box-sizing: border-box;
  border-radius: 5px;
  color: #565656;
`;
