import React, { useState, useEffect, useRef } from "react";
import { Button } from "component/Common";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopJoin1, LayerPopJoin2, LayerPopPostCode } from "component/popUp/PopUp";
import { makeStyles } from '@material-ui/core/styles';
import { postRequest, getRequest } from "component/Axios";
import { SEVER_DOMAIN } from 'utils/constant.js'; 
import { useNavigate } from "react-router-dom";
import Loader from "../../../component/Loader";

const useStyles = makeStyles((theme) => ({
    password: {
        width: '100px'
    }
}));

export const JoinStep2 = () => {
    const classes = useStyles();
    const [popupJoin1, setPopupJoin1] = useState(false);
    const [popupJoin2, setPopupJoin2] = useState(false);
    const [addsubAccount, popupAddsubAccount] = useState(false);
    const [certificationNumber, setCertificationNumber] = useState(false);
    const [certificationEmail, setCertificationEmail] = useState(false);
    const [popupPostCode, setPopupPostCode] = useState(false);
    const [address, setAddress] = useState("");
    const [tableRows, setTableRows] = useState('');
    const [checkBizno, setCheckBizno] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [custIdCheck, setCustIdCheck] = useState(false);
    const [repCustSn, setRepCustSn] = useState(0);
    const [biznoData, setBiznoData] = useState('');
    const [useCust, setUseCust] = useState(false);
    const [zip, setZip] = useState('');
    const [addr, setAddr] = useState('');
    const [dtlAddr, setDtlAddr] = useState('');
    const [corpNm, setCorpNm] = useState('');
    const [ceoNm, setCeoNm] = useState('');
    const [bizCndtnNm, setBizCndtnNm] = useState('');
    const [bizIndstNm, setBizIndstNm] = useState('');
    const [bizMsg, setBizMsg] = useState('');
    const [custStatus, setCustStatus] = useState('3');
    const [emailMsg, setEmailMsg] = useState('');
    //const [certMsg, setCertMsg] = useState('');
    const [confirmMsg, setConfirmMsg] = useState('');
    const [custId, setCustId] = useState('');
    const [check, setCheck] = useState(false);
    const [mailCheck, setMailCheck] = useState(false);

    const intialValues = { email: "", password: "",  bizno1: "", bizno2: "", bizno3: "", userNm: "", mobile1: "", mobile2: "", mobile3: "", confirmPassword: "", custId: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sEmail, setSEmail] = useState('');
    const [biznoErr, setBiznoErr] = useState('');
    const [bizMsgErr, setBizMsgErr] = useState('');
    const [confirmErr, setConfirmErr] = useState('');

    const [authCheck , setAuthCheck] = useState('N');
    const [emailAuthCheck , setEmailAuthCheck] = useState(false);
    const [bizNoDisabled , setBizNoDisabled] = useState(false);

    const [loading, setLoading] = useState(1);

    const style={
        green : {
          color: '#00cc00',
          textAlign: 'right',
          fontSize:'12px'
        }
      }

    
    // useEffect(() => {
    //     if (Object.keys(formErrors).length === 0 && isSubmitting && checkBizno) {
    //         submitForm();
    //     }
    // }, [formErrors]);
    

    const navigate = useNavigate();

    const handleLoadingOn = () => {
        setLoading(0)
      }
    
      const handleLoadingClose = () => {
        setLoading(1)
      } 

    useEffect(() => {
        const setp = sessionStorage.getItem("step1");
        if ( !setp ) {
            alert("필수 약관 동의가 필요합니다.");
            navigate("/account/joinStep1");
        }
    },[])

    const setPhoneCheck = val => {
        setCheck(val);
    }

    const closePostCode = () => {
        setPopupPostCode(false)
    }

    const closeBizno = () => {
        setPopupJoin1(false);
    }

    const closeCustInfo = () => {
        setPopupJoin2(false);
    }

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    async function getFetch(url) {
        return getRequest.fetch(url);
    }
    
    const submitForm = (event) => {
        const submit = true;
        
        if(bizNoDisabled == false) {
            handleSearch(event);
            return;
        }
        
        setIsSubmitting(true);
        if (Object.keys(formErrors).length === 0 && submit ) {
            
            if ( !checkBizno ) {
                alert("사업자등록번호를 확인하세요.");
                return;
            }
            if(bizFileName == '') {
                alert("사업자등록증을 첨부하세요.");
                return;
            }
            if ( authCheck != 'Y' ) {
                alert("휴대폰 본인인증을 하세요.");
                return;
            }

            if(!emailAuthCheck) {
                alert("이메일 본인인증을 하세요.");
                return;
            }
            
            if( window.confirm("가입신청을 하시겠습니까?") ){
                
                const url = "/signup";
                
                const custDto = { custId : formValues.custId, custPwd: formValues.password,userNm : formValues.userNm,mobile : formValues.mobile1 + formValues.mobile2 + formValues.mobile3,
                    email : formValues.email, bizNo : formValues.bizno1 + formValues.bizno2 + formValues.bizno3, corpNm : corpNm, ceoNm : ceoNm,
                    bizCndtnNm : bizCndtnNm, bizIndstNm : bizIndstNm, zip : zip, addr : addr, dtlAddr : dtlAddr, repCustSn : repCustSn, custStatus : custStatus 
                }

                const formData = new FormData();
                formData.append("custDto", JSON.stringify(custDto));
                formData.append("file", bizFile);

                handleLoadingOn();
                getPostFetch(url, formData)
                .then(result => {
                        if( result.resultCode === "0" ) {
                            alert("회원가입이 완료되었습니다.");
                            navigate("/");
                            handleLoadingClose();
                        } else {
                            alert(result.resultMsg)
                            handleLoadingClose();
                        }
                    }
                )
                .catch(error => {
                        handleLoadingClose();
                        alert(error);
                    }
                )
            }
        }
    };

    useEffect(()=>{ 
        if( useCust && biznoData[1] != undefined){
            setBizNoDisabled(true);
            setCheckBizno(true);
            setBizMsg("대표계정 : " + biznoData[1] + "");
            setCorpNm(biznoData[2]);
            setCeoNm(biznoData[3]);
            setBizCndtnNm(biznoData[4]);
            setBizIndstNm(biznoData[5]);
            setZip(biznoData[6]);
            setAddr(biznoData[7]);
            setDtlAddr(biznoData[8]);
            setRepCustSn(biznoData[0]);
            formErrors.bizno = "";
        } else {
            setBizNoDisabled(false);
            setBizMsg('');
        }
    }, [useCust]);

    useEffect(()=>{ 
        setZip(address[0]);
        setAddr(address[1]);
        setDtlAddr("");
    }, [address]);
    
    useEffect(()=>{ 
        setFormErrors(validate(formValues));
    }, [formValues,isSubmitting,custIdCheck,biznoErr]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        if ( name === "custId" )
            setCustIdCheck(false);

        setFormValues({ ...formValues, [name]: value });
        setFormErrors(validate(formValues));
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    };
    
    const validate = (values) => {
        let errors = {};
        
        const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/
        // 영문,특수문자,숫자 조합으로 6자리 이상
        let regexPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/
        
        const sumBizno = values.bizno1 + "" + values.bizno2 + "" + values.bizno3;
        
        if ( biznoErr ){
            errors.bizno = biznoErr;
        }else if( !sumBizno && isSubmitting ) {
            errors.bizno = "사업자등록번호는 필수입력사항 입니다.";
            setBizMsg('');
            setBizMsgErr("사업자등록번호는 필수입력사항 입니다.");
        } else if ( sumBizno && sumBizno.length < 10 ) {
            errors.bizno = "잘못된 사업자등록번호 입니다.";
            setCheckBizno(false);
            setBizMsg('');
            setUseCust(false);
            setRepCustSn(0);
            setBizMsgErr("잘못된 사업자등록번호 입니다.")
        } else if ( sumBizno && !checkBizno && sumBizno.length === 10 ) {
            errors.bizno = "사업자확인이 필요합니다.";
            setBizMsg('');
            setBizMsgErr("사업자확인이 필요합니다.");
        }
        
        if (!values.userNm && isSubmitting) { 
            errors.userNm = "대표담당자명은 필수입력사항 입니다.";
        }
        
        const sumMobile = values.mobile1 + values.mobile2 + values.mobile3;
        if (!sumMobile && isSubmitting) {
            errors.mobile = "대표담당자 연락처는 필수입력사항 입니다.";
        } else if (sumMobile && !regexMobile.test(sumMobile)) {
            errors.mobile = "잘못된 형식의 연락처 입니다.";
        }
        
        if (!values.email && isSubmitting) { 
            errors.email = "대표담당자 이메일은 필수입력사항 입니다.";
        } else if (values.email && !regexEmail.test(values.email)) {
            errors.email = "잘못된 형식의 이메일 입니다.";
        }
        
        if (!values.custId && isSubmitting) { 
            errors.custId = "아이디는 필수입력사항 입니다.";
        }
        
        if (values.custId && !custIdCheck) {
            errors.custId = "아이디 중복확인이 필요합니다.";
        }

        if (!values.password && isSubmitting) { 
            errors.password = "비밀번호는 필수입력사항 입니다.";
            setPasswordErrMsg();
        } else if (values.password && !regexPwd.test(values.password)) { 
            errors.password = "비밀번호는 6자리이상, 영문, 숫자, 특수문자를 조합하여야 합니다.";
            setPasswordErrMsg();
        } else if ( values.password ) {
            setPasswordErrMsg("안전한 비밀번호입니다");
        }

        if (!values.confirmPassword && isSubmitting) { 
            errors.confirmPassword = "비밀번호 확인은 필수입력사항 입니다.";
        } else if (values.password !== values.confirmPassword ) { 
            errors.confirmPassword = "비밀번호가 일치하지 않습니다.";
        } else if ( !values.password && isSubmitting && values.password === values.confirmPassword ) {
            setConfirmMsg("비밀번호가 일치합니다.");
        }
        return errors;
    };
    
    const handleSearch = (event) => {
        event.preventDefault();
        
        const sumBizno = formValues.bizno1 + formValues.bizno2 + formValues.bizno3;

        if( !sumBizno ) {
            alert("사업자등록번호를 입력하세요.");
            return;
        } else if ( sumBizno && sumBizno.length < 10 ) {
            alert("잘못된 사업자 번호 입니다.");
            return;
        }

        const url = '/bizno/'+sumBizno;
        setBizMsgErr("");
        setBizMsg("");
        setUseCust();

        handleLoadingOn();

        getFetch(url)
        .then(result => {
                if( result.resultCode === "0" ){
                    setCheckBizno(true);
                    setBizNoDisabled(true);
                    if( result.resultData != null ){
                        setBizNoDisabled(false);
                        let data;
                        const jsonArr = JSON.parse(result.resultData);
                        const rows = [];
                        for (const row of jsonArr){
                            const stat = row.status;

                            if(row.custGrade == "2") {
                                rows.push(
                                    <tr>
                                        <td>
                                            <input type="radio" name="RCSButton" id={row.id} onClick={function (e) {setBiznoData([row.sn,row.id, row.corpNm,row.ceoNm,row.bizCndtnNm,row.bizIndstNm,row.zip,row.addr,row.dtlAddr])}} />
                                            <label htmlFor={row.id}>{row.value}</label>
                                        </td>
                                        <td> {row.id} </td>
                                        <td> {row.nm} </td>   
                                        <td> {row.mobile} </td>
                                    </tr>
                                )
                            }
                        }

                        if(rows.length > 0) {
                            setTableRows(rows); 
                            setPopupJoin1(true);
                        } else {
                            setBizMsgErr("해당 사업자등록번호로 이미 등록된 계정이 존재합니다.");
                        }
                    } 
                    setBizMsg("정상 사업자등록번호입니다.");
                    handleLoadingClose();
                } else {
                    handleLoadingClose();
                    setCheckBizno(false);
                   // alert(result.resultMsg);
                   setBizMsgErr(result.resultMsg);
                }
            }
        )
        .catch(error => {
            handleLoadingClose();
            alert(error);
        }
    )
    };

    const handleAddress = (event) => {
        event.preventDefault();
        setPopupPostCode(true);
    };

    const [min2, setMin2] = useState(3);
    const [sec2, setSec2] = useState(0);
    const time2 = useRef(180);
    const timerId2 = useRef(null);
    const [timeCheck2, setTimeCheck2] = useState('');
    const [num2, setNum2] = useState('');
    const [msg2 , setMsg2] = useState('');


    useEffect(() => {
        // 만약 타임 아웃이 발생했을 경우
        if (time2.current <= 0) {
            alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
            clearInterval(timerId2.current);
            setTimeCheck2(false);
            time2.current = 180;
        }
    }, [sec2]);

    const handleNumberPhone = (sumMobile) => {
        clearInterval(timerId2.current);
        setTimeCheck2(false);
        time2.current = 180;
        setMsg2("");


        selfAuthPopup = window.open(SEVER_DOMAIN+'/auth/'+sumMobile+'/req', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        selfAuthPopupCheckTimer = setInterval(checkSelfAuthPopupClose, 500);
        
        // const url  = "/auth/receiveNum";
        // const data = { mobile : sumMobile , type : "1" }
    
        // getPostFetch(url, data)
        // .then(result => {
        //         if( result.resultCode === "0" ) {
        //             setTimeCheck2(true);
        //             setMin(3);
        //             setSec(0);
        //             timerId2.current = setInterval(() => {
        //                 time2.current -= 1;
        //                 setMin2(parseInt(time2.current / 60));
        //                 setSec2(time2.current % 60);
        //             }, 1000);
        //             alert("인증번호가 발송되었습니다.");
        //             document.getElementById("authCheck").value = false;
        //         } else {
        //             alert(result.resultMsg)
        //         }
        //     }
        // )
        // .catch(error => alert(error))
  
    };

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    var selfAuthPopup;
    var selfAuthPopupCheckTimer;

    function checkSelfAuthPopupClose(){
    if(selfAuthPopup.closed){
        clearInterval(selfAuthPopupCheckTimer);
        let mobile = document.getElementById("mobile1").value + document.getElementById("mobile2").value + document.getElementById("mobile3").value;
        const data = {
            search3 : mobile,
            search4 : document.getElementById("userNm").value
        }
        getPostFetch("/auth/registerSelfAuthResult2/check",data)
        .then(result => {
            if(result.resultCode === '0') {
                setAuthCheck('Y');
            } else if(result.resultCode === '1') {
            }}
        )
        .catch(error => alert(error))

        }
    }
    const handleConfirmPhone = (event) => {
        event.preventDefault();
        
        const m1 = document.getElementById("mobile1").value;
        const m2 = document.getElementById("mobile2").value;
        const m3 = document.getElementById("mobile3").value;

        const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/

        const sumMobile = m1 + m2 + m3;

        if (!sumMobile ) {
            alert("인증을 위해 연락처를 입력하세요.");
            return;
        } else if (sumMobile && !regexMobile.test(sumMobile)) {
            alert("잘못된 형식의 연락처 입니다.");
            return;
        }
    
        if ( !num2 ) {
            alert("인증번호를 입력하세요.");
            return;
        }
        
        if ( !timeCheck2 ) {
            alert("인증번호를 요청하세요.");
            return;
        }

        const url  = "/auth/checkNum";
        const data = { num : num2 , mobile : sumMobile , type : "1" }
    
        getPostFetch(url, data)
        .then(result => {
                alert(result.resultMsg)
                if( result.resultCode === "0" ) {
                        //setPhoneCheck(true);
                        document.getElementById("authCheck").value = true;
                        setMsg2("본인인증이 완료되었습니다.");
                        clearInterval(timerId2.current);
                        setTimeCheck2(false);
                        time2.current = 180;
                } 
            }
        )
        .catch(error => alert(error))
      
    };

    const handleCertification = (event) => {
        event.preventDefault();
        setCertificationNumber(false);

        const m1 = document.getElementById("mobile1").value;
        const m2 = document.getElementById("mobile2").value;
        const m3 = document.getElementById("mobile3").value;

        const nm = document.getElementById("userNm").value;

        const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/

        const sumMobile = m1 + m2 + m3;

        if (!sumMobile ) {
            alert("인증을 위해 연락처를 입력하세요.");
            return;
        } else if (sumMobile && !regexMobile.test(sumMobile)) {
            alert("잘못된 형식의 연락처 입니다.");
            return;
        }

        if(nm === '') {
            alert("인증을 위해 이름을 입력하세요.");
            return;
        }

        handleNumberPhone(sumMobile);
        //setCertificationNumber(true);
    };

    const handleEmail = (event) => {
        event.preventDefault();
        setCertificationEmail(false);
        const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        const authMail = document.getElementById("email").value;
        
        if (!authMail ) { 
            alert("인증을 위해 이메일을 입력하세요.");
            return;
        } else if (authMail && !regexEmail.test(authMail)) {
            alert("잘못된 형식의 이메일 입니다.");
            return;
        }
        setCertificationEmail(true);
        handleNumber();
        //setSEmail(authMail);
    };

    const handleAccountID = (event) => {
        event.preventDefault();
        
        if( !formValues.custId ){
            alert("아이디를 입력하세요.");
            return;
        }

        const url = '/idCheck/'+formValues.custId;
        handleLoadingOn()
        getFetch(url)
        .then(result => {
                if( result.resultCode === "0" ){
                    setCustIdCheck(true);
                    setCustId(formValues.custId);
                } else {
                    setCustIdCheck(false);
                }
                alert(result.resultMsg);
                handleLoadingClose()
            }
        )
        .catch(error =>  handleLoadingClose())
    };
    
    const ConnectAccount = (event) => {
        event.preventDefault();
        setPopupJoin2(true);
    };
    const handleReset = (event) => {
        event.preventDefault();
    };
    const handleDeleteUser = (event) => {
        event.preventDefault();
    };
    const handleAddUser = (event) => {
        event.preventDefault();
    };
    
    const handleJoin = (event) => {
        event.preventDefault();
    };

    const handleAddr = val => {
        setDtlAddr(val);
    }

    const [min, setMin] = useState(3);
    const [sec, setSec] = useState(0);
    const time = useRef(180);
    const timerId = useRef(null);
    const [timeCheck, setTimeCheck] = useState('');
    const [sn, setSn] = useState(''); 
    const [num, setNum] = useState('');
    const [msg , setMsg] = useState('');
    const [bizFile, setBizFile] = useState('');
    const [bizFileName, setBizFileName] = useState('');


    useEffect(() => {
        // 만약 타임 아웃이 발생했을 경우
        if (time.current <= 0) {
            alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
            clearInterval(timerId.current);
            setTimeCheck(false);
            time.current = 180;
        }
    }, [sec]);

    const handleNumber = () => {
      
      clearInterval(timerId.current);
      setTimeCheck(false);
      time.current = 180;
      setMsg("");
  
      const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      const authMail = document.getElementById("email").value;
      if (!authMail ) {
          alert("이메일을 입력하세요.")
          return;
      } else if (!regexEmail.test(authMail)) {
          alert("잘못된 형식의 이메일 입니다.");
          return;
      }
  
      const url  = "/auth/receiveNum";
      const data = { email : authMail , type : "2" }
      getPostFetch(url, data)
      .then(result => {
            if( result.resultCode === "0" ) {
                
            } else {
                alert(result.resultMsg)
            }
          }
      )
      .catch(error =>  alert(error))

      setTimeCheck(true);
                setMin(3);
                setSec(0);
                timerId.current = setInterval(() => {
                    time.current -= 1;
                    setMin(parseInt(time.current / 60));
                    setSec(time.current % 60);
                }, 1000);
                alert("인증번호가 발송되었습니다.");
  
    };
    const handleConfirm = (event) => {
      event.preventDefault();
      
      const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      const authMail = document.getElementById("email").value;
      if (!authMail ) {
            alert("이메일을 입력하세요.")
            return;
        } else if (!regexEmail.test(authMail)) {
            alert("잘못된 형식의 이메일 입니다.");
            return;
        }
  
      if ( !num ) {
          alert("인증번호를 입력하세요.");
          return;
      }

      if ( !timeCheck ) {
          alert("인증번호를 요청하세요.");
          return;
      }
  
      const url  = "/auth/checkNum";
      const data = { num : num , email : authMail , type : "2" }
      handleLoadingOn();
      getPostFetch(url, data)
      .then(result => {
              alert(result.resultMsg)
              if( result.resultCode === "0" ) {
                    //setPhoneCheck(true);
                    document.getElementById("emailCheck").value = true;
                    setMsg("메일주소가 인증되었습니다.");
                    clearInterval(timerId.current);
                    setTimeCheck(false);
                    setEmailAuthCheck(true);
                    setCertificationEmail(true);
                    time.current = 180;
              } 
              handleLoadingClose();
          }
      )
      .catch(error =>  handleLoadingClose())
      
    };

    const handleBizFile = (e) => {

        if(e.target.files[0] != undefined) {
            
            let fileName = e.target.files[0].name;
            let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
            
            if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
                alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
                return;
            } else {
                setBizFile(e.target.files[0]);
                setBizFileName(fileName);
            }
        }
    };
    


    return (
        <> 
            <p className="page-stitle">
                회원가입을 위해 <br/><b>필요한 정보를 입력</b>해주세요.
            </p>
            {loading !=  1 && (
                <Loader type="spin" color="black" message="요청을 처리중입니다" />
            )}
            <form onSubmit={handleSubmit} noValidate >
            <div className="account-container join-container">
                <p className="stitle">
                    <div>
                        기업정보
                        <span className="rfloat required">필수 입력사항</span>
                    </div>
                </p>
                <p className="clear"></p>

                <div className="account-contents">
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con" style={{marginTop: "5px"}}><span className="required">사업자등록번호</span></div>
                            <div className="input-con clearfix">
                                <ul className="input-col3-group clearfix" style={{marginTop: "5px"}}>
                                    <li><input type="text" name="bizno1" id="bizno1" maxlength="3" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                            value={formValues.bizno1} onChange={handleChange} disabled={bizNoDisabled}/>
                                    </li>
                                    <li><input type="text" name="bizno2" id="bizno2" maxlength="2" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                            value={formValues.bizno2} onChange={handleChange} disabled={bizNoDisabled}/>
                                    </li>
                                    <li><input type="text" name="bizno3" id="bizno3" maxlength="5" title="" className="gs-custom-input w-auto fc-event" placeholder=""
                                            value={formValues.bizno3} onChange={handleChange} disabled={bizNoDisabled}/>
                                    </li>
                                    <p className="clear"></p>
                                </ul>
                                {
                                    bizNoDisabled == false ? <button className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleSearch} style={{marginTop: "7px"}}>사업자확인</button> 
                                                           : <button className="success-highlight-btn he30 fc-event" id="corpNumCheckBtn" style={{marginTop: "7px", cursor: "none"}} disabled>정상</button>
                                }
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state" style={{marginTop: "12px"}}>{bizMsgErr ? <span className="red">{bizMsgErr}</span> : <span className="blue">{bizMsg}</span>}</p>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>업체명</span></div>
                            <div className="input-con">
                                <input type="text" name="corpNm" id="corpNm" maxlength="100" title="업체명" className="gs-custom-input fc-event" placeholder="업체명" value={corpNm} 
                                onChange={e => {setCorpNm(e.target.value)}} />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>대표자명</span></div>
                            <div className="input-con">
                                <input type="text" name="ceoNm" id="ceoNm" maxlength="10" title="대표자명" className="gs-custom-input fc-event" placeholder="대표자명" value={ceoNm}
                                onChange={e => {setCeoNm(e.target.value)}}  />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>업태</span></div>
                            <div className="input-con">
                                <input type="text" name="bizCndtnNm" id="bizCndtnNm" maxlength="100" title="업태" className="gs-custom-input fc-event" placeholder="업태" value={bizCndtnNm} 
                                onChange={e => {setBizCndtnNm(e.target.value)}}/>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>종목</span></div>
                            <div className="input-con">
                                <input type="text" name="bizIndstNm" id="bizIndstNm" maxlength="100" title="종목" className="gs-custom-input fc-event" placeholder="종목" value={bizIndstNm} 
                                onChange={e => {setBizIndstNm(e.target.value)}}/>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">사업자등록증</span></div>
                            <div className="filebox">
                                <input className="upload-name" style={{color: "black"}} value={bizFileName} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
                                <label for="file" className="cm-highlight-btn he30" style={{cursor: "pointer"}}>파일찾기</label> 
                                <input type="file" id="file" onChange={handleBizFile} hidden/>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>우편번호</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" className="gs-custom-input" name="zip" id="zip" readonly="" value={zip} />
                                        <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleAddress} >주소 검색</button>
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item mt10">
                        <div className="input-inner clearfix mb10" id="cropNumArea">
                            <div className="title-con"><span>주소</span></div>
                            <div className="input-con">
                            <input type="text" className="gs-custom-input" name="addr" id="addr" readonly="" value={addr} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <div className="input-inner clearfix">
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" className="gs-custom-input" name="dtlAddr" placeholder="상세주소" maxlength="100" id="dtlAddr" value={dtlAddr} onChange={ e => {handleAddr(e.target.value)}}/>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="clear"></p>
            </div>
            <div className="account-container join-container">
                <p className="stitle">
                    <div>
                        회원정보
                        <span className="rfloat required">필수 입력사항</span>
                    </div>
                </p>
                <p className="clear"></p>

                <div className="account-contents">
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">대표담당자명</span></div>
                            <div className="input-con">
                                <input type="text" name="userNm" id="userNm" maxlength="20" title="대표담당자명" className="gs-custom-input fc-event" placeholder="대표담당자명" 
                                    value={formValues.userNm} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.userNm && (<span className="red">{formErrors.userNm}</span>)}</p>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"  style={{marginTop: "5px"}}><span className="required">대표담당자 연락처</span></div>
                            <div className="input-con clearfix">
                                <ul className="input-col3-group clearfix" style={{marginTop: "5px"}}>
                                    <li><input type="text" name="mobile1" id="mobile1" maxlength="3" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                        value={formValues.mobile1} onChange={handleChange} disabled={authCheck == "Y" ? true : false}/>
                                    </li>
                                    <li><input type="text" name="mobile2" id="mobile2" maxlength="4" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                        value={formValues.mobile2} onChange={handleChange} disabled={authCheck == "Y" ? true : false}/>
                                    </li>
                                    <li><input type="text" name="mobile3" id="mobile3" maxlength="4" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                        value={formValues.mobile3} onChange={handleChange} disabled={authCheck == "Y" ? true : false}/>
                                    </li>
                                    <p className="clear"></p>
                                </ul>
                                {
                                    authCheck === "N" ? <button  style={{marginTop: "5px"}} className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleCertification}>본인인증</button>
                                                      : <button className="success-highlight-btn he30 fc-event" id="corpNumCheckBtn" style={{marginTop: "7px", cursor: "none"}} disabled>인증완료</button>
                                }
                            </div>
                            <p className="clear"></p>
                        </div>
                        {/* <p className="list-item-state">{formErrors.mobile && (<span className="red">{formErrors.mobile}</span>)}</p> */}
                        {authCheck === "Y" ? <p className="list-item-state"  style={{marginTop: "12px"}}><span className="blue"></span></p> : formErrors.mobile && <p className="list-item-state" style={{marginTop: "12px"}}><span className="red">{formErrors.mobile}</span></p>}
                    </div>
                    
                    {/* {
                        (function() {
                        if (authCheck === "Y") return (<div style={style.green}>
                            인증되었습니다.
                            </div>);
                    })()
                    } */}
                    {certificationNumber &&
                     <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">연락처 인증번호</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" name="authNumber" id="authNumber" maxlength="20" title="인증번호" className="gs-custom-input fc-event Certification" placeholder="인증번호" 
                                        onChange={e => {setNum2(e.target.value)}} />
                                        <span className="validtime red">{min2}:{sec2 < 10 ? `0${sec2}` : sec2}</span>
                                        <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleConfirmPhone}>확인</button>
                                        <input type="hidden" name="authCheck"  id="authCheck" />
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state"><span className="blue">{msg2}</span></p>
                    </div>    
                    }
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">대표담당자 이메일</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="email" name="email" id="email" maxlength="50" title="이메일" className="gs-custom-input fc-event input-error" placeholder="이메일" 
                                            value={formValues.email} onChange={handleChange} disabled={emailAuthCheck}/>
                                            {
                                                emailAuthCheck ? <button className="success-highlight-btn he30 fc-event" id="corpNumCheckBtn" style={{marginTop: "7px", cursor: "none"}} disabled>인증완료</button>  
                                                               : <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleEmail} >본인인증</button>
                                            }
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                        {formErrors.email ? <p className="list-item-state"><span className="red">{formErrors.email}</span></p> : <p className="list-item-state"><span className="blue">{emailMsg}</span></p>}
                    </div>
                    {certificationEmail && !emailAuthCheck &&
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">이메일 인증번호</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" name="authEmailNum" id="authEmailNum" maxlength="20" title="인증번호" className="gs-custom-input fc-event Certification" placeholder="인증번호" 
                                        onChange={e => {setNum(e.target.value)}} />
                                        <span className="validtime red">{min}:{sec < 10 ? `0${sec}` : sec}</span>
                                        <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleConfirm}>확인</button>
                                        <input type="hidden" name="emailCheck"  id="emailCheck" />
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state"><span className="blue">{msg}</span></p>
                    </div>
                   }
                    
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">아이디</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" name="custId" id="custId" maxlength="20" title="아이디" className="gs-custom-input fc-event" placeholder="아이디" 
                                            value={formValues.custId} onChange={handleChange} />
                                        {
                                            custIdCheck ? <button className="success-highlight-btn he30 fc-event" id="corpNumCheckBtn" style={{marginTop: "0px", cursor: "none"}} disabled>인증완료</button>
                                                              : <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleAccountID}>중복확인</button>
                                        }
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                        {formErrors.custId && <p className="list-item-state"><span className="red">{formErrors.custId}</span></p>}
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">비밀번호</span></div>
                            <div className="input-con">
                                <input type="password" name="password" id="password" maxlength="20" title="비밀번호" className="gs-custom-input fc-event" placeholder="비밀번호 6자리이상, 영문, 숫자, 특수문자 조합" 
                                    value={formValues.password} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        {formErrors.password ? <p className="list-item-state" style={{marginTop: "-5px"}}><span className="red">{formErrors.password}</span></p> : <p className="list-item-state" style={{marginTop: "-5px"}}><span className="blue">{passwordErrMsg}</span></p>}
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">비밀번호 확인</span></div>
                            <div className="input-con">
                                <input type="password" name="confirmPassword" id="confirmPassword" maxlength="20" title="비밀번호 확인" className="gs-custom-input fc-event" placeholder="비밀번호 확인" 
                                    value={formValues.confirmPassword} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        {formErrors.confirmPassword ? <p className="list-item-state" style={{marginTop: "-5px"}}><span className="red">{formErrors.confirmPassword}</span></p> : <p className="list-item-state" style={{marginTop: "-5px"}}><span className="blue">{confirmMsg}</span></p>}
                    </div>
                </div>
                <p className="clear"></p>
                
                <p className="clear"></p>
            </div>
            <div className="button-wrap center">
                <p className="size15 gray333">신청하신 정보는 담당자가 확인 후 서비스를 이용하실 수 있습니다.</p>
                <Button buttonName="button-bgc-blue mt20 bbtn" buttonValue="가입 신청" onChangePage={submitForm} />
            </div>
            </form>
        {popupJoin1 && (
            <PopUpLayout onClose={closeBizno} poptitle="동일한 사업자등록번호로 가입진행">
                <LayerPopJoin1 data={tableRows} useCust={useCust} setUseCust={setUseCust} onClose={closeBizno} />
            </PopUpLayout>
        )}
        {popupJoin2 && (
            <PopUpLayout onClose={setPopupJoin2} poptitle="대표계정 연동" >
                <LayerPopJoin2 onClose={closeCustInfo}/>
            </PopUpLayout>
        )}
        {popupPostCode && (
            <PopUpLayout onClose={setPopupPostCode} poptitle="우편번호 조회">
                <LayerPopPostCode address={address} setAddress={setAddress} onClose={closePostCode} />
            </PopUpLayout>
        )}
        </>
    );
};


export const CertificationNumber = () => {
    

    return(
        <div className="check-list-item">
            <div className="input-inner clearfix" id="cropNumArea">
                <div className="title-con"><span className="required">연락처 인증번호</span></div>
                <div className="input-con">
                    <fieldset className="address-fieldset">
                        <div className="address-wrapper">
                            <input type="text" name="authNumber" id="authNumber" maxlength="20" title="인증번호" className="gs-custom-input fc-event Certification" placeholder="인증번호" 
                             />
                            <span className="validtime red"></span>
                            <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" >확인</button>
                            <input type="hidden" name="authCheck"  id="authCheck" />
                        </div>
                    </fieldset>
                </div>
                <p className="clear"></p>
            </div>
            <p className="list-item-state"><span className="blue"></span></p>
        </div>
    );
}

export const CertificationEmail = () => {
    

    return(
    <div className="check-list-item">
        <div className="input-inner clearfix" id="cropNumArea">
            <div className="title-con"><span className="required">이메일 인증번호</span></div>
            <div className="input-con">
                <fieldset className="address-fieldset">
                    <div className="address-wrapper">
                        <input type="text" name="authEmailNum" id="authEmailNum" maxlength="20" title="인증번호" className="gs-custom-input fc-event Certification" placeholder="인증번호" 
                        />
                        <span className="validtime red"></span>
                        <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" >확인</button>
                        <input type="hidden" name="emailCheck"  id="emailCheck" />
                    </div>
                </fieldset>
            </div>
            <p className="clear"></p>
        </div>
        <p className="list-item-state"><span className="blue"></span></p>
    </div>
    );
}