import React from "react";
import { Navigate } from "react-router-dom";
import { ROOT_URL } from "./utils/constant";

import { DesktopLayout, AccountLayout } from "./layouts/desktopLayout/Index";
import { AboutService } from "./views/aboutService/AboutService";
import { Agreement } from "./views/terms/Agreement";
import { Privacy } from "./views/terms/Privacy";
import { AntiSpam } from "./views/terms/AntiSpam";
import { FindId1 } from "./views/account/find/FindId1";
import { FindId2 } from "./views/account/find/FindId2";
import { FindPwd1 } from "./views/account/find/FindPwd1";
import { FindPwd2 } from "./views/account/find/FindPwd2";
import { JoinStep1 } from "./views/account/join/JoinStep1";
import { JoinStep2 } from "./views/account/join/JoinStep2";
import Login from "./views/account/login/Login";
import { ConnectApi } from "./views/customerService/connectApi/ConnectApi";
import NotFound from "./views/errors/NotFound";
import Main from "./views/main/Main";

// 고객센터
import Faq from "./views/customerService/faq/Faq";
import { BoardView } from "./views/customerService/notice/View";
import List from "./views/customerService/notice/List";
import { ContactUS } from "./views/customerService/contactUS/ContactUS";

// 카카오
//import Home from "./views/kakao/Home";
import KakaoProfile from "./views/kakao/kakaoProfile/KakaoProfile";
import KakaoFriends from "./views/kakao/KakaoFriends";
import KakaoTopImg from "./views/kakao/KakaoTopImg";
import KakaoItemList from "./views/kakao/KakaoItemList";
import KakaoTemplate from "./views/kakao/kakaoTemplate/KakaoTemplate";
import View from "./views/kakao/kakaoTemplate/View";

// RCS
import { RcsTemplate } from "./views/rcs/template/RcsTemplate";
import { RcsBrandList } from "./views/rcs/brand/RcsBrand";
import { RcsTemplateRegister } from "./views/rcs/template/RcsTemplateRegister";
import { RcsTemplateView } from "./views/rcs/template/RcsTemplateView";
import RcsImg from "./views/rcs/Img/RcsImg";

// 마이페이지
import { EditMyInfo } from "./views/myPage/info/EditMyInfo";
import Payment from "./views/myPage/payment/Payment";
import SignOut from "./views/myPage/info/signOut/SignOut";
import PasswordCheck from "./views/myPage/info/signOut/PasswordCheck";
import SignOutCompleted from "./views/myPage/info/signOut/SignOutCompleted";
import RefundComplete from "./views/myPage/info/signOut/RefundComplete";
import Reference from "./views/myPage/reference/Reference";
import { ReferenceView } from "./views/myPage/reference/ReferenceView";

// 주메뉴
import { Address } from "./views/mainService/address/Address";
import { PersonalAddress } from "./views/mainService/address/PersonalAddress";
import { SendMessage } from "./views/mainService/sendMessage/SendMessage";
import { SendNumber } from "./views/mainService/sendUser/SendNumber";
import { ReservationBoxView } from "./views/mainService/sendResult/ReservationBoxView";
import SendResult from "./views/mainService/sendResult/SendResult";
import MessageBox from "./views/mainService/sendResult/MessageBox";
import { SendBoxView } from "./views/mainService/sendResult/SendBoxView";

import CompanyInfo from "./views/myPage/info/CompanyInfo";
import ContractInfo from "./views/myPage/info/ContractInfo";
import { SendRCS } from "./views/mainService/sendMessage/SendRCS";
import { SendKakaoTalk } from "./views/mainService/sendMessage/SendKakaoTalk";
const routes = [
  {
    //  메인
    path: ROOT_URL,
    element: <DesktopLayout />,
    children: [
      { path: "/", element: <Main /> },
      { path: "service", element: <AboutService /> },
      { path: "agreement", element: <Agreement /> },
      { path: "privacy", element: <Privacy /> },
      { path: "antispam", element: <AntiSpam /> },
      { path: "message", element: <SendMessage /> },
      { path: "kakao", element: "" },
      { path: "rcs", element: <RcsTemplate /> },
      { path: "spampolicy", elemat: "" },
      { path: "customer", element: "" },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    // 계정
    path: `${ROOT_URL}/account`,
    element: <AccountLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "joinStep1", element: <JoinStep1 /> },
      { path: "joinStep2", element: <JoinStep2 /> },
      { path: "findid1", element: <FindId1 /> },
      { path: "findid2", element: <FindId2 /> },
      { path: "findpwd1", element: <FindPwd1 /> },
      { path: "findpwd2", element: <FindPwd2 /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    // 카카오
    path: `${ROOT_URL}/kakao`,
    element: <DesktopLayout />,
    children: [
      { path: "profile", element: <KakaoProfile /> },
      { path: "friends", element: <KakaoFriends /> },
      { path: "template", element: <KakaoTemplate /> },
      { path: "template/view", element: <View /> },
      { path: "topimg", element: <KakaoTopImg /> }, // 알림톡 상단 이미지 관리
      { path: "itemlist", element: <KakaoItemList /> }, //알림톡 아이템 이미지 관리
    ],
  },
  {
    // RCS
    path: `${ROOT_URL}/rcs`,
    element: <DesktopLayout />,
    children: [
      { path: "rcsTemplate", element: <RcsTemplate /> },
      { path: "rcsTemplate/upload", element: <RcsTemplateRegister /> },
      { path: "rcsTemplate/view", element: <RcsTemplateView /> },
      { path: "rcsBrand", element: <RcsBrandList /> },
      { path: "rcsImg", element: <RcsImg /> },
    ],
  },
  {
    // 문자보내기
    path: `${ROOT_URL}/sendMessage`,
    element: <DesktopLayout />,
    children: [
      { path: "message", element: <SendMessage /> }, //문자전송
      { path: "kakao", element: <SendKakaoTalk /> }, //카카오톡전송
      { path: "rcs", element: <SendRCS /> }, //RCS전송
    ],
  },
  {
    //마이페이지
    path: `${ROOT_URL}/mypage`,
    element: <DesktopLayout />,
    children: [
      { path: "info/accountInfo", element: <CompanyInfo /> }, // 업체 및 계정정보
      { path: "info/contractInfo", element: <ContractInfo /> }, // 계약정보
      { path: "reference", element: <Reference /> }, // 자료실
      { path: "reference/view", element: <ReferenceView /> }, // 자료실
      { path: "payment", element: <Payment /> }, // 요금조회 및 납부
      { path: "passwordCheck", element: <PasswordCheck /> }, // 탈퇴전 계정확인
      { path: "signOutMember", element: <SignOut /> }, // 탈퇴진행
      { path: "signOutCompleted", element: <SignOutCompleted /> }, // 탈퇴 신청 완료
      { path: "refundComplete", element: <RefundComplete /> }, // 환불 신청 완료
    ],
  },
  {
    // 계정
    path: `${ROOT_URL}/mypage`,
    element: <AccountLayout />,
    children: [
      { path: "info/editInfo", element: <EditMyInfo /> }, // 내정보수정,
    ],
  },
  {
    //고객센터
    path: `${ROOT_URL}/customer`,
    element: <DesktopLayout />,
    children: [
      { path: "contactUS", element: <ContactUS /> }, // 상담문의
      { path: "faq", element: <Faq /> }, // FAQ
      { path: "notice", element: <List /> }, //공지사항 리스트
      { path: "notice/view", element: <BoardView /> }, //공지사항 뷰페이지
      { path: "api", element: <ConnectApi /> },
    ],
  },
  {
    //주메뉴
    path: `${ROOT_URL}/main`,
    element: <DesktopLayout />,
    children: [
      { path: "sendnumber", element: <SendNumber /> }, //발신번호 관리
      { path: "sendResult", element: <SendResult /> }, //발신자 정보
      { path: "messagebox", element: <MessageBox /> }, // 메시지함
      { path: "messagebox/sendboxview", element: <SendBoxView /> }, //보낸메세지함상세
      {
        path: "messagebox/reservationboxview",
        element: <ReservationBoxView />,
      },
      { path: "address", element: <Address /> }, //공유주소록
      { path: "personalAddress", element: <PersonalAddress /> }, //개인주소록
    ],
  },
  {
    path: "/",
    element: <DesktopLayout />,
    children: [{ path: "/", element: <Navigate to="" /> }],
  },
];
export default routes;
