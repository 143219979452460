import React, { Component, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RefundRequestTable } from 'component/Table';
import { Button } from 'component/Common';
import { postRequest, getRequest } from 'component/Axios';

import { loginState } from '../../../../utils/state';
import { useRecoilState } from 'recoil';

export default function RefundRequest(props) {
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const navigate = useNavigate();
  const signOutInfo = props.signOutInfo;
  const [signOutData, setSignOutData] = useState({ ...signOutInfo });
  const [codeRows, setCodeRows] = useState([]);
  
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  const logout = () => {
    sessionStorage.clear();
    // 로그아웃 이력 저장
    const url = '/auth/logout';

    getFetch(url)
      .then(result => {
      })
      .catch(error => alert(error));
  };
  const handleRefund = event => {
    event.preventDefault();
    if (signOutData.refundAmount === 0) {
      alert('환불 가능금액이 없습니다.');
      return;
    }
    if (signOutData.owner === '') {
      alert('예금주를 입력해주세요');
      return;
    }
    if (signOutData.fnclCmpy === '' || signOutData.fnclCmpy === '은행을 선택하세요.') {
      alert('은행을 선택해주세요.');
      return;
    }
    if (signOutData.accountNo === '') {
      alert('계좌번호를 입력해주세요');
      return;
    }
    if (signOutData.refundRemark === '') {
      alert('환불사유를 입력해주세요.');
      return;
    }
    const url = '/user/requestRefund';
    const data = signOutData;
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          // logout();
          // setIsLogin(false);
          navigate('/mypage/refundComplete', {
            state: { refundSucInfo: jsonArr },
          });
        } else if (result.resultCode === '-1') {
          alert(result.resultMsg);
        }
      })
      .catch(error => alert(error));
    // 환불하기' 버튼 클릭 시 로그인 유지 & 환불 신청접수완료 안내 페이지로 이동4
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleCommonCode = () => {
    const url = '/getCommonCode';
    const snList = [1034];
    const data = { snList: snList };

    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch(error => alert(error));
  };
  const handleSetSignOutData = e => {
    const { name, value } = e.target;
    setSignOutData({ ...signOutData, [name]: value });
  };
  useEffect(() => {
    handleCommonCode();
  }, []);
  return (
    <div className='container'>
      <div className='Info-gray mt30'>
        <p className='title'>환불안내</p>

        <ul className='Info-wrap'>
          <li>
            * HiSMS는 충전일로부터 5년간 사용 가능합니다. 기한 내에 남은 잔액은
            전액 환불만 가능하며, 부분 환불은 불가능합니다. <br />
            <span className='pl10'></span>다만, 배분금을 제외한 최종 잔액의
            10%의 환불수수료가 발생합니다.
          </li>
          <li>
            * 이벤트가 적용된 충전금은 1년간 사용가능하며 환불이 불가합니다.
          </li>
          <li>* 환불에 대한 세부 규정은 HiSMS 이용약관 제 15조에 따릅니다</li>
        </ul>
      </div>

      <RefundRequestTable
        signOutData={signOutData}
        codeRows={codeRows}
        handleSetSignOutData={handleSetSignOutData}
      />
      <div className='button-wrap center'>
        <NavLink to='/mypage/info/accountInfo'>
          <Button buttonName='button-border-gray' buttonValue='뒤로가기' />
        </NavLink>
        <Button
          buttonName='button-bgc-dark ml10'
          buttonValue='환불신청'
          onChangePage={handleRefund}
        />
      </div>
    </div>
  );
}