import React, { useState, useEffect } from "react";
import { Button } from "component/Common";
import { postRequest } from "component/Axios";
import { useNavigate } from "react-router-dom";

export const FindPwd2 = () => {
    const intialValues = { password: "",  confirmPassword: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [confirmMsg, setConfirmMsg] = useState('');
    const navigate = useNavigate();

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    const validate = (values) => {
        let errors = {};
        
        // 영문,특수문자,숫자 조합으로 6자리 이상
        let regexPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/

        setPasswordErrMsg('');
        setConfirmMsg('');
        
        if (!values.password && isSubmitting) { 
            errors.password = "비밀번호는 필수입력사항 입니다.";
        } else if (values.password && !regexPwd.test(values.password)) { 
            errors.password = "비밀번호는 6자리이상, 영문, 숫자, 특수문자를 조합하여야 합니다.";
        } else if ( values.password ) {
            setPasswordErrMsg("안전한 비밀번호입니다");
        }

        if (values.password !== values.confirmPassword ) { 
            errors.confirmPassword = "비밀번호가 일치하지 않습니다.";
        } else if ( values.confirmPassword ) {
            setConfirmMsg("비밀번호가 일치합니다.");
        }

        if ( values.password && errors )
            setIsSubmitting(true);

        return errors;
    };
    
    useEffect(()=>{ 
        setFormErrors(validate(formValues));
    }, [formValues,isSubmitting]);

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors(validate(formValues));
    };
    
    const onPassword = () => {
        if ( !formValues.password )
            return;
        
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            
            if( window.confirm("비밀번호를 변경 하시겠습니까?") ){
                const url = "/changePwd";
                const data = { custId : sessionStorage.getItem("find_id"), custPwd : formValues.password }
                
                getPostFetch(url, data)
                .then(result => {
                        if( result.resultCode === "0" ) {
                            alert("비밀번호가 변경되었습니다.");
                            navigate("/account/login");
                        } else {
                            alert(result.resultMsg)
                        }
                    }
                )
                .catch(error => alert(error))
            }
        }
        setIsSubmitting(false);
    };

    const handleEnterPress = e => {
        if(e.key === 'Enter') {
            onPassword();
        }
    }

    return (
        <div className="account-wrap login">
            <div className="account-container">
                <p className="page-stitle">
                비밀번호 재설정
                </p>
                <div className="account-contents">
                    <div className="find_result">{ sessionStorage.getItem("find_id") }</div>
                    <div className="check-list-item disable">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">비밀번호</span></div>
                            <div className="input-con">
                                <input type="password" name="password" id="password" maxlength="20" title="비밀번호" className="gs-custom-input fc-event" placeholder="비밀번호 6자리이상, 영문, 숫자, 특수문자 조합" 
                                value={formValues.password} onChange={handleChange} onKeyPress={handleEnterPress} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.password && (<span className="red">{formErrors.password}</span>)}<span className="blue">{passwordErrMsg}</span></p>
                    </div>
                    <div className="check-list-item disable">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">비밀번호 확인</span></div>
                            <div className="input-con">
                                <input type="password" name="confirmPassword" id="confirmPassword" maxlength="20" title="비밀번호 확인" className="gs-custom-input fc-event" placeholder="비밀번호 확인" 
                                value={formValues.confirmPassword} onChange={handleChange} 
                                onKeyPress={handleEnterPress}/>
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.confirmPassword && (<span className="red">{formErrors.confirmPassword}</span>)}<span className="blue">{confirmMsg}</span></p>
                    </div>
                    <p className="clear mt50"></p>

                    <div className="button-wrap button-col2">
                        <Button buttonName="button-bgc-blue sbtn" buttonValue="확인" onChangePage={onPassword} />
                        <Button 
                            buttonName="button-border-gray sbtn" 
                            buttonValue="취소" 
                            onChangePage={function () {
                                navigate("/account/login");
                        }} />
                    </div>
                </div>
            </div>
        </div>
    );
};