import React, { useState, useEffect } from "react";
import { PaginationDynamic, Button } from "component/Common";
import { KakaoTemplateList } from "component/Table";
import { postRequest, getRequest } from "component/Axios";
import _ from "lodash";
import axios from "axios";
import { SEVER_DOMAIN } from "utils/constant.js";

export const List = () => {
  const [useTemplate, setuseTemplate] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState("");
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [search5, setSearch5] = useState("");
  const [search6, setSearch6] = useState("");
  const [snList, setSnList] = useState([]);
  const [codeRows, setCodeRows] = useState([]);
  const [custType, setCustType] = useState("");
  const [custGrade, setCustGrade] = useState("");
  const [accountList, setAccountList] = useState([]);

  const [page1, setPage1] = useState(1);
  let reqPage = 1;
  useEffect(() => {
    handlePageChange(1);
  }, []);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const stopUseTemplte = (num) => {
    let str = "선택된 템플릿을 중지 하시겠습니까?";
    if (num === 2) {
      str = "선택된 템플릿을 중지해제 하시겠습니까?";
    }

    if (snList.length === 0) {
      alert("선택된 템플릿이 없습니다.");
      return;
    }

    if (window.confirm(str)) stopChoiceTemplate(num);
  };

  const handleReq = (num) => {
    let str = "선택된 템플릿을 검수요청 하시겠습니까?)";
    if (num === 1) {
      str = "일괄 검수요청 하시겠습니까?";
    } else if (num === 2) {
      if (snList.length === 0) {
        alert("선택된 템플릿이 없습니다.");
        return;
      }
    }

    if (window.confirm(str)) checkTemplate(num);
  };

  function checkTemplate(num) {
    const url = "/kko/checkTemplate";
    const data = { snList: snList, type: num };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          alert(result.resultMsg);
        } else {
          alert(result.resultMsg);
        }
        handlePageChange(1);
      })
      .catch((error) => alert(error));
  }

  function stopChoiceTemplate(num) {
    const url = "/kko/stopChoiceTemplate";
    const data = { snList: snList, type: num };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          alert(result.resultMsg);
        } else {
          alert(result.resultMsg);
        }
        handlePageChange(1);
      })
      .catch((error) => alert(error));
  }

  function handleDel() {
    let str = "선택된 템플릿을 삭제 하시겠습니까?)";

    if (snList.length === 0) {
      alert("선택된 템플릿이 없습니다.");
      return;
    }

    if (window.confirm(str)) {
      const url = "/kko/delTemplate";
      const data = { snList: snList };

      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            alert(result.resultMsg);
          } else {
            alert(result.resultMsg);
          }
          handlePageChange(1);
        })
        .catch((error) => alert(error));
    }
  }

  const handleDownTemplate = () => {
    let str = "선택된 템플릿을 다운로드 하시겠습니까?)";

    if (snList.length === 0) {
      alert("선택된 템플릿이 없습니다.");
      return;
    }

    if (window.confirm(str)) {
      const url = "/kko/downloadTemplate";
      const data = { snList: snList };

      axios({
        method: "POST",
        url: SEVER_DOMAIN + "/kko/downloadTemplate/",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
          "Content-Type": "application/json",
        },
        data: {
          snList: snList,
        },
      }).then((res) => {
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        //let fileName = getFileName(res.headers['content-disposition'])
        let fileName = "템플릿.xlsx";
        //fileName = decodeURI(fileName)
        if (window.navigator.msSaveOrOpenBlob) {
          // IE 10+
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // not IE
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.target = "_self";
          if (fileName) link.download = fileName;
          link.click();
        }
      });
    }
  };

  function getFileName(contentDisposition) {
    let fileName = contentDisposition
      .split(";")
      .filter((ele) => {
        return ele.indexOf("fileName") > -1;
      })
      .map((ele) => {
        return ele.replace(/"/g, "").split("=")[1];
      });
    return fileName[0] ? fileName[0] : null;
  }

  const handlePageChange = (page) => {
    let reqSearch2 = "";
    let reqSearch3 = "";
    let reqSearch4 = "";
    let reqSearch5 = "";
    let reqSearch6 = "";

    if (sessionStorage.getItem("backCheck") === "K") {
      sessionStorage.removeItem("backCheck");

      reqPage = sessionStorage.getItem("pages");

      if (
        sessionStorage.getItem("search2") === "undefined" ||
        sessionStorage.getItem("search2") === null ||
        sessionStorage.getItem("search2") === ""
      ) {
        reqSearch2 = search2;
      } else {
        document.getElementById("search2").value =
          sessionStorage.getItem("search2");
        reqSearch2 = sessionStorage.getItem("search2");
        setSearch2(sessionStorage.getItem("search2"));
      }
      if (
        sessionStorage.getItem("search3") === "undefined" ||
        sessionStorage.getItem("search3") === null ||
        sessionStorage.getItem("search3") === ""
      ) {
        reqSearch3 = search3;
      } else {
        document.getElementById("search3").value =
          sessionStorage.getItem("search3");
        reqSearch3 = sessionStorage.getItem("search3");
        setSearch3(sessionStorage.getItem("search3"));
      }

      if (
        sessionStorage.getItem("search4") === "undefined" ||
        sessionStorage.getItem("search4") === null ||
        sessionStorage.getItem("search4") === ""
      ) {
        reqSearch4 = search4;
      } else {
        document.getElementById("search4").value =
          sessionStorage.getItem("search4");
        reqSearch4 = sessionStorage.getItem("search4");
        setSearch4(sessionStorage.getItem("search4"));
      }

      if (
        sessionStorage.getItem("search5") === "undefined" ||
        sessionStorage.getItem("search5") === null ||
        sessionStorage.getItem("search5") === ""
      ) {
        reqSearch5 = search5;
      } else {
        document.getElementById("search5").value =
          sessionStorage.getItem("search5");
        reqSearch5 = sessionStorage.getItem("search5");
        setSearch5(sessionStorage.getItem("search5"));
      }
      if (
        sessionStorage.getItem("search6") === "undefined" ||
        sessionStorage.getItem("search6") === null ||
        sessionStorage.getItem("search6") === ""
      ) {
        reqSearch6 = search6;
      } else {
        reqSearch6 = sessionStorage.getItem("search6");
        setSearch6(sessionStorage.getItem("search6"));
      }
    } else {
      reqPage = page;
      reqSearch2 = search2;
      reqSearch3 = search3;
      reqSearch4 = search4;
      reqSearch5 = search5;
      reqSearch6 = search6;
    }

    const commonUrl = "/getCommonCode";
    const snList = [1007, 1022];
    const commonData = { snList: snList };

    getPostFetch(commonUrl, commonData)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => {});

    const subInfoUrl = "/user/subInfo";
    getRequest
      .fetch(subInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setAccountList(jsonArr);
        }
      })
      .catch((error) => {});

    const custInfoUrl = "/user/custInfo";
    getRequest
      .fetch(custInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const json = JSON.parse(result.resultData);
          setCustType(json.custType);
          setCustGrade(json.custGrade);
        }
      })
      .catch((error) => {});

    const url = "/kko/templateList";
    const data = {
      rowCount: rowCount,
      currentPage: reqPage,
      search2: reqSearch2,
      search3: reqSearch3,
      search4: reqSearch4,
      search5: reqSearch5,
      search6: reqSearch6,
    };
    setPage1(data.currentPage);

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);

          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {});

    sessionStorage.removeItem("backCheck");
    sessionStorage.removeItem("search2");
    sessionStorage.removeItem("search3");
    sessionStorage.removeItem("search4");
    sessionStorage.removeItem("search5");
    sessionStorage.removeItem("search6");
    sessionStorage.removeItem("pages");
  };

  return (
    <>
      <div className="kakao-contents">
        <div className="searchbox">
          <ul className="search-col3-group">
            {/*
                        <li>
                            <select name="search1" id="search1" onChange={ e => { setSearch1(e.target.value) } } >
                                <option value="" disabled selected>메세지 유형</option>
                                <option value="">전체</option>
                                <option value="1">기본형</option>
                                <option value="2">부가 정보형</option>
                                <option value="3">광고 추가형</option>
                                <option value="4">복합형</option>
                            </select>
                        </li>
                        */}
            <li>
              <select
                name="search2"
                id="search2"
                value={search2}
                onChange={(e) => {
                  setSearch2(e.target.value);
                }}
              >
                <option defaultValue="" disabled selected>
                  검수상태
                </option>
                <option value="">전체</option>
                {codeRows.map((row) =>
                  row.cgSn === 1022 ? (
                    <option value={row.cdId}>{row.cdNm}</option>
                  ) : null
                )}
              </select>
            </li>
            <li>
              <select
                name="search3"
                id="search3"
                value={search3}
                onChange={(e) => {
                  setSearch3(e.target.value);
                }}
              >
                <option defaultValue="" disabled selected>
                  템플릿상태
                </option>
                <option value="">전체</option>
                {codeRows.map((row) =>
                  row.cgSn === 1007 ? (
                    <option value={row.cdId}>{row.cdNm}</option>
                  ) : null
                )}
              </select>
            </li>
            {custType === "1" && custGrade === "2" ? (
              <li>
                <select
                  name="userID"
                  id="userID"
                  value={search6}
                  onChange={(e) => {
                    setSearch6(e.target.value);
                  }}
                >
                  <option defaultValue="" selected>
                    계정 전체
                  </option>
                  {accountList.map((info) => (
                    <option value={info.custSn}>{info.custId}</option>
                  ))}
                </select>
              </li>
            ) : null}
          </ul>
          <ul className="search-col3-group">
            <li>
              <select
                name="search4"
                id="search4"
                value={search4}
                onChange={(e) => {
                  setSearch4(e.target.value);
                }}
              >
                <option defaultValue="" disabled selected>
                  검색 항목
                </option>
                <option value="">전체</option>
                <option value="1">채널</option>
                <option value="2">템플릿코드</option>
                <option value="3">템플릿명</option>
                <option value="4">메세지 내용</option>
              </select>
            </li>

            <li>
              <input
                type="text"
                name="search5"
                id="search5"
                value={search5}
                placeholder="검색항목 내용을 입력해주세요."
                onChange={(e) => {
                  setSearch5(e.target.value);
                }}
              />
            </li>
            <li>
              <Button
                buttonName="button-bgc-dark"
                buttonValue="검색"
                onChangePage={(e) => {
                  handlePageChange(1);
                }}
              />
            </li>
            <li></li>
          </ul>
        </div>

        <div className="button-wrap">
          <Button
            buttonName="button-bgc-dark"
            buttonValue="일괄 검수요청"
            onChangePage={(e) => {
              handleReq(1);
            }}
          />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="선택 템플릿 검수요청"
            onChangePage={(e) => {
              handleReq(2);
            }}
          />
          <Button
            buttonName="button-border-gray"
            buttonValue="선택 템플릿 삭제"
            onChangePage={(e) => {
              handleDel();
            }}
          />
          <Button
            buttonName="buttonDown"
            buttonValue="선택 템플릿 다운로드"
            onChangePage={(e) => {
              handleDownTemplate();
            }}
          />

          <span className="rfloat">
            <Button
              buttonName="button-border-gray"
              buttonValue="템플릿 중지"
              onChangePage={(e) => stopUseTemplte(1)}
            />
            <Button
              buttonName="button-border-gray"
              buttonValue="템플릿 중지해제"
              onChangePage={(e) => stopUseTemplte(2)}
            />
          </span>
        </div>
        <KakaoTemplateList
          data={tableRows}
          setSnList={setSnList}
          custGrade={custGrade}
          custType={custType}
          search2={search2}
          search3={search3}
          search4={search4}
          search5={search5}
          search6={search6}
          pages={page1}
        />
        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default List;
