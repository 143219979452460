import React, { useState, useEffect }  from "react";
import { SendProfileStatisticTable,SendRcsProfileStatisticTable } from "component/Table";
import { Button,PaginationDynamic } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'rsuite';
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import axios from 'axios'; 
import { SEVER_DOMAIN } from 'utils/constant.js';
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  datepicker: {
    margin: 0,
    marginTop: '-6px',
    padding:15
  },
  margin0: {
    margin:0
  }
}));

export const SendProfileStatistic = () => {
  const classes = useStyles();
  const [value, onChange] = useState([new Date(), new Date()]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [serviceType, setServiceType] = useState();
  const [url, setUrl] = useState("/stats/kakaoStats");
  const [select, setSelect] = useState("1");
  const [custType, setCustType] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();
  const [callCnt, setCallCnt] = useState(0);

  const handleSearch = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handlePageChange();
  }, [url]);
  async function getPostFetch(url,data) {
    return postRequest.fetch(url,data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleLoadingOn = () => {
    setCallCnt(0);
  }

  const handleLoadingClose = () => {
    setCallCnt(2);
  }

  const handlePageChange = page => {

    if ( value.length === 0 ) {
      alert("발송일은 필수 선택사항 입니다.");
      return;
    }

    let month0 = value[0].getMonth() + 1;
    if ( month0 < 10 )
      month0 = "0" + month0;

    let day0 = value[0].getDate();
    if ( day0 < 10 )
      day0 = "0" + day0;

    let month1 = value[1].getMonth() + 1;
    if ( month1 < 10 )
      month1 = "0" + month1;
    
    let day1 = value[1].getDate();
    if ( day1 < 10 )
      day1 = "0" + day1; 

    const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
    const endDay = value[1].getFullYear() + '' + month1 + '' + day1;

    const myInfoUrl = "/user/myInfo";
    const subInfoUrl = "/user/subInfo";
    handleLoadingOn();
    getFetch(myInfoUrl)
    .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                setCustType(jsonArr.custType);

                if(jsonArr.custType === "1") {
                  getFetch(subInfoUrl)
                  .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        setCustIdList(jsonArr);
                    }
                  })
                  .catch(error =>handleLoadingClose())
                }

                const data = { rowCount : rowCount, currentPage: page, search2 : startDay, search3 : endDay, search4 : jsonArr.custType, search5 : custId }

                getPostFetch(url, data)
                .then(result => {
                    if( result.resultCode === "0" ) {
                      const jsonArr = JSON.parse(result.resultData);
                      const pageItem = JSON.parse(result.resultPage);
                      setLeftPage(pageItem.leftPage);
                      setRightPage(pageItem.rightPage);
                      setEndPage(pageItem.endPage);
                      setCurrentPage(pageItem.currentPage);
                      setFnlPage(pageItem.fnlPage);
                      const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                      setPages(pages);
                      setTableRows(jsonArr);
                    } else {
                        alert(result.resultMsg);
                    }
                    handleLoadingClose();
                  }
                )
                .catch(error => handleLoadingClose())
            }
          }
    )
    .catch(error => handleLoadingClose())

    
  }
  
  const buttonOption = [
      { id :"1", value :"카카오 채널 통계"}
  ]

  const getSelectedContent = (id) => {
    
    if (id === "1") {
      setUrl("/stats/kakaoStats");
      setServiceType(<SendProfileStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
    }
    else if (id === "2") {
      setUrl("/stats/rcsStats");
      setServiceType(<SendRcsProfileStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
    }

    setSelect(id);
  }

  const DownloadStatistics = (event) => {
    event.preventDefault();
    handleDownTemplate(1);
  };

  const handleCustId = (e) => {
    setCustId(e.target.value);
  };


  const handleDownTemplate = (type) => {
        
    let month0 = value[0].getMonth() + 1;
    if ( month0 < 10 )
      month0 = "0" + month0;

    let day0 = value[0].getDate();
    if ( day0 < 10 )
      day0 = "0" + day0;

    let month1 = value[1].getMonth() + 1;
    if ( month1 < 10 )
      month1 = "0" + month1;
    
    let day1 = value[1].getDate();
    if ( day1 < 10 )
      day1 = "0" + day1; 

    const startDay = value[0].getFullYear() + '' + month0 + '' + day0;
    const endDay = value[1].getFullYear() + '' + month1 + '' + day1;
      let str = "채널별 통계를 다운로드 하시겠습니까?";

      if( window.confirm(str) ) {
          if(tableRows.length === 2) {
            alert("다운로드할 데이터가 없습니다.");
            return;
          }
          handleLoadingOn();
          axios({
              method: 'POST',
              url: SEVER_DOMAIN + url + '/downloadStats/',
              responseType: 'blob',
              headers: {
                  Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                  "Content-Type": "application/json"
              },   
              data: {
                search2 : startDay, search3 : endDay,  search4 : custType, search5 : custId
              } 
          })
          .then(res =>{
              let blob = new Blob([res.data], { type: res.headers['content-type'] })
              let fileName = "채널별통계.xlsx"
              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                  window.navigator.msSaveOrOpenBlob(blob, fileName)
              } else { // not IE
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.target = '_self'
                  if (fileName) link.download = fileName
                  link.click()
              }
          })
          .catch(error => alert("파일 다운로드를 실패하였습니다."))
          handleLoadingClose();
      }  
  }

    return (
      <div className="container">
        {callCnt != 2 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <p className="page-title">채널별 통계</p>
        <div className="searchbox">
          <ul className="search-col4-group">
            <li>
              <select name="selectbox" id="chanel" className={classes.rcsBtnSelect} onChange={(e) => { getSelectedContent(e.target.value)}}>
                  {buttonOption.map((b) => (
                      <option value={b.id}>{b.value}</option>
                  ))}
              </select>
            </li>
            <li>
             <DateRangePicker  appearance="default" placeholder="발송일"
             onChange={onChange}
             value={value}
             dateFormat="yyyy-MM-dd"
             />
            </li>
            { custType === '1' ? 
              <li>
                <select onChange={handleCustId} name="userID" id="userID" value={custId}>
                  <option value="" selected >
                    계정 전체
                  </option>
                  {
                  custIdList.map((row) => (<option value={row.custId}>{row.custId}</option>))
                  }
                </select>
              </li>
            : null }
            <li>
              <Button buttonName="button-bgc-dark" buttonValue="검색"  onChangePage={ e => {handlePageChange(1)}}/>
            </li>
          </ul> 
        </div>
        
        <div className="button-wrap">
          <Button buttonName="buttonDown" buttonValue="엑셀 다운로드" onChangePage={DownloadStatistics} /> 
        </div>

        {
          (function() {
            if (select === "1") return (<SendProfileStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
            else return (<SendRcsProfileStatisticTable data={tableRows} currentPage={currentPage} rowCount={rowCount} custType={custType}/>);
          })()
        } 
      </div>
  );
}

export default SendProfileStatistic;