import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import List from "../views/kakao/kakaoTemplate/List";
import { Button } from "component/Common";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import profileImg from "assets/images/banner1.png";
import deleteBtn from "assets/images/delete_btn.png";
import { postRequest, getRequest } from "component/Axios";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import Loader from "component/Loader";
import {
  LayerPopImgZoom,
  LayerPopMasterAccount,
  LayerPopEditManager,
  LayerPopSubAccountContract,
  LayerPopRCSTemplatePreview,
  LayerPopRequestForPayLater,
  LayerPopImgSub,
  LayerPopFileUpload,
  LayerPopEditAddressInfo,
  LayerPopPaymentTotal,
  LayerPopTryAuthSendNumber,
} from "component/popUp/PopUp";
import axios from "axios";
import _, { add } from "lodash";
import { SEVER_DOMAIN } from "utils/constant.js";
import { useNavigate } from "react-router-dom";
import { constSelector } from "recoil";
import { withStyles } from "@material-ui/styles";
import setComma, { setCommaToFixed } from "./common/SetComma";
import Tooltip, { TootipText, TooltipHover } from "./common/Tooltip";

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

export function KakaoProfileUpload(props) {
  const style = {
    select: {
      width: "calc(32% - 5px)",
      // marginRight: '0 !important',
      // width: 'calc(50% - 5px)',
    },
    input: {
      width: "calc(100% - 126px)",
    },
  };

  const [mobile, setMobile] = useState("");
  const mobileInput = useRef();
  const plusIdInput = useRef();
  const [category, setCategory] = useState([]);
  const [cate1, setCate1] = useState("");
  const [cate2, setCate2] = useState("");
  const [cate3, setCate3] = useState("");
  const [channel, setChannel] = useState("");

  const setPlusId = props.setPlusId;
  const setNum = props.setNum;
  const setCheck = props.setCheck;
  const setCategory1 = props.setCategory1;
  const setCategory2 = props.setCategory2;
  const setCategory3 = props.setCategory3;
  const setPhone = props.setPhone;

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleCategory();
  }, []);
  const handleCategory = () => {
    const url = "/kko/getCategory/1";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCategory(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handlePhone = (event) => {
    let phone = event.target.value;
    phone = phone.replace(/[^0-9.]/g, "");
    setMobile(phone);
    setPhone(phone);
  };

  const handlePlusId = (event) => {
    setPlusId(event.target.value);
    setChannel(event.target.value);
  };

  const handleCategory1 = (val) => {
    setCategory1(val);
    setCate1(val);
  };

  const handleCategory2 = (val) => {
    setCategory2(val);
    setCate2(val);
  };

  const handleCategory3 = (val) => {
    setCategory3(val);
    setCate3(val);
  };

  const handleNum = (e) => {
    setNum(e.target.value);
  };

  const sendNumberBtn = (event) => {
    event.preventDefault();

    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;

    if (!channel) {
      alert("카카오톡 채널은 필수입력사항 입니다.");
      plusIdInput.current.focus();
      return;
    }

    if (!mobile) {
      alert("휴대폰번호는 필수입력사항 입니다.");
      mobileInput.current.focus();
      return;
    } else if (!regexMobile.test(mobile)) {
      alert("잘못된 형식의 휴대폰번호 입니다.");
      mobileInput.current.focus();
      return;
    }

    const url = "/kko/reqNum";
    const data = {
      phone: mobile,
      plusId: channel,
    };
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") setCheck(true);
      })
      .catch((error) => alert(error));
  };

  return (
    <table className="table_style1">
      <thead></thead>
      <tbody>
        <tr>
          <th>카카오톡 채널</th>
          <td>
            {/* <input
              type="text"
              name=""
              defaultValue=""
              placeholder="@검색용 아이디"
            /> */}
            <div class="gs-custom-input-btn-group">
              <input
                type="text"
                id="plusId"
                name="plusId"
                title=""
                placeholder=""
                ref={plusIdInput}
                onChange={handlePlusId}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>카테고리</th>
          <td>
            <select
              className="ml10"
              name="selectbox"
              id="cate1"
              style={style.select}
              onChange={(e) => {
                handleCategory1(e.target.value);
              }}
            >
              <option defaultValue="">카테고리 대분류 선택</option>
              {category.map((row) =>
                row.ctType === "1" ? (
                  <option value={row.ctCd}>{row.ctNm}</option>
                ) : null
              )}
            </select>
            <span></span>
            <select
              className="ml10"
              name="selectbox"
              id="cate2"
              style={style.select}
              onChange={(e) => {
                handleCategory2(e.target.value);
              }}
            >
              <option defaultValue="">카테고리 중분류 선택</option>
              {category.map((row) =>
                row.ctType === "2" && row.ctParentCd === cate1 ? (
                  <option value={row.ctCd}>{row.ctNm}</option>
                ) : null
              )}
            </select>
            <span></span>
            <select
              className="ml10"
              name="selectbox"
              id="cate3"
              style={style.select}
              onChange={(e) => {
                handleCategory3(e.target.value);
              }}
            >
              <option defaultValue="">카테고리 소분류 선택</option>
              {category.map((row) =>
                row.ctType === "3" && row.ctParentCd === cate1 + cate2 ? (
                  <option value={row.ctCd}>{row.ctNm}</option>
                ) : null
              )}
            </select>
          </td>
        </tr>
        <tr>
          <th>관리자 휴대폰번호</th>
          <td>
            {/* <input
              type="text"
              name=""
              defaultValue=""
              placeholder="휴대폰번호를 입력해주세요."
            /> */}

            <div class="gs-custom-input-btn-group">
              <input
                style={style.input}
                type="text"
                id="mobile"
                name="mobile"
                title=""
                maxlength="12"
                placeholder="휴대폰번호를 입력해주세요"
                onChange={handlePhone}
                value={mobile}
                ref={mobileInput}
              />
              <Button
                buttonName="button-bgc-dark"
                buttonValue="인증"
                onChangePage={sendNumberBtn}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>인증번호</th>
          <td>
            {/* <input
              type="text"
              name=""
              defaultValue=""
              placeholder="인증번호를 입력해주세요."
            /> */}
            <div class="gs-custom-input-btn-group">
              <input
                type="text"
                id="phoneNumChk"
                name="phoneNumChk"
                title=""
                placeholder="인증번호를 입력해주세요"
                onChange={handleNum}
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function RcsBrandRegisterTable() {
  const [mobile, setMobile] = useState("");
  const [authNum, setAuthNum] = useState("");
  const [brand, setBrand] = useState("");

  const style = {
    select: {
      width: "48%",
    },
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handlePhone = (event) => {
    let phone = event.target.value;
    phone = phone.replace(/[^0-9.]/g, "");
    setMobile(phone);
  };

  const handleAuthNum = (event) => {
    let authNum = event.target.value;
    setAuthNum(authNum);
  };

  const handleBrand = (event) => {
    let brand = event.target.value;
    setBrand(brand);
  };

  const sendNumberBtn = (event) => {
    if (brand === "") {
      alert("브랜드 ID를 입력하세요.");
      return;
    }

    if (mobile === "") {
      alert("관리자 휴대폰 번호를 입력하세요.");
      return;
    }

    event.preventDefault();
    const url = "/rcs/authNum/" + mobile;
    getFetch(url)
      .then((result) => {
        alert("인증번호가 전송되었습니다.");
      })
      .catch((error) => alert(error));
  };

  const regBtn = (event) => {
    if (brand === "") {
      alert("브랜드 ID를 입력하세요.");
      return;
    }

    if (mobile === "") {
      alert("관리자 휴대폰 번호를 입력하세요.");
      return;
    }

    if (authNum === "") {
      alert("인증번호를 입력하세요.");
      return;
    }

    const authUrl = "/auth/checkNum";
    const authData = { num: authNum, mobile: mobile, type: "1" };
    const regUrl = "/rcs/brandSave";
    const regData = { search3: brand };

    getPostFetch(authUrl, authData)
      .then((result) => {
        if (result.resultMsg != "인증 되었습니다.") {
          alert(result.resultMsg);
        } else {
          getPostFetch(regUrl, regData)
            .then((result) => {
              alert(result.resultMsg);
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <div>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>브랜드 ID</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="text"
                  id=""
                  name=""
                  title=""
                  placeholder="브랜드 ID 명(예. BR.RR3FVoeLs) "
                  onChange={handleBrand}
                  value={brand}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>관리자 휴대폰번호</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  style={style.input}
                  type="text"
                  id="mobile"
                  name="mobile"
                  title=""
                  placeholder="휴대폰번호를 입력해주세요"
                  onChange={handlePhone}
                  value={mobile}
                />
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="인증"
                  onChangePage={sendNumberBtn}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>인증번호</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="text"
                  id="authNum"
                  name="authNum"
                  title=""
                  onChange={handleAuthNum}
                  value={authNum}
                  placeholder="인증번호를 입력해주세요. "
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <p className="button-wrap">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="RCS 브랜드 추가"
          onChangePage={regBtn}
        />
      </p>
    </div>
  );
}

export function KakaoProfileList(props) {
  const custGrade = props.custGrade;
  const custType = props.custType;
  const releaseStopBtn = (event) => {
    event.preventDefault();
  };
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="6">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.pfSn}
                value={row.pfSn}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td>
              {row.corpNm}({row.plusId})
            </td>
            <td> {row.senderKey} </td>
            <td> {row.regDt} </td>
            <td> {row.pfBusiProNm} </td>
            <td>
              {stat != "A" ? (
                <p className="red">{row.pfStatusNm}</p>
              ) : (
                row.pfStatusNm
              )}
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
  }, [props.data, props.custType, props.custGrade]);
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
  };
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
  };
  return (
    <table className="table_style2 checktype">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              name="checkAllDel"
              defaultValue=""
              onChange={(e) => {
                handleAllCheck(e);
              }}
            />
          </th>
          {custType === "1" && custGrade === "2" ? <th>계정 ID</th> : null}
          <th>채널아이디</th>
          <th>채널키</th>
          <th>등록일시</th>
          <th>비즈니스 인증</th>
          <th>채널 상태</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function KakaoTemplate(props) {
  const setActiveContent = props.setActiveContent;
  const [selectedInput, setSelectedInput] = useState(1);
  const [selectedContent, setSelectedContent] = useState(
    <KakaoTemplateUpload1 />
  );
  const [btnCount, setBtnCount] = useState(0);
  const [uploadFile, setUploadFile] = useState(null);
  const inputTypes = [
    { id: 1, value: "직접입력", content: <KakaoTemplateUpload1 /> },
    {
      id: 2,
      value: "엑셀대량입력",
      content: <KakaoTemplateUpload2 setUploadFile={setUploadFile} />,
    },
  ];
  // const [defaultValue, setdefaultValue] = React.useState("type1");
  // const handleChange = (event) => {
  //   event.preventDefault();
  //   setdefaultValue(event.target.defaultValue);
  // };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const [inputList, setInputList] = useState([]);
  const Input = () => {
    return <KakaoTemplateUpload1 />;
  };
  const onAddBtnClick = (event) => {
    event.preventDefault();
    setInputList(
      inputList.concat(<KakaoTemplateUpload1 key={inputList.length} />)
    );
  };
  const onDeleteBtnClick = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const handleTemplateSave = () => {
    let type = document.getElementById("1").checked;
    if (type) {
      // 직접입력
      saveTemplate();
    } else {
      // 엑셀대량입력
      excelTemplate();
    }
  };
  function excelTemplate() {
    if (!uploadFile) {
      alert("업로드 할 엑셀 파일을 선택해주세요.");
      return;
    }
    const formData = new FormData();
    formData.append("file", uploadFile);
    const url = "/kko/templateFileUpload";
    const data = formData;
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        setUploadFile(null);
        document.getElementById("fileName").value = "";
      })
      .catch((error) => alert(error));
  }
  function saveTemplate() {
    const channel = document.getElementById("plusId").value;
    if (!channel) {
      alert("채널은 필수선택사항 입니다.");
      return;
    }
    const templateCode = document.getElementById("tpCode").value;
    if (!templateCode) {
      alert("템플릿 코드는 필수입력사항 입니다.");
      document.getElementById("tpCode").focus();
      return;
    }
    const templateName = document.getElementById("tpNm").value;
    if (!templateName) {
      alert("템플릿 명은 필수입력사항 입니다.");
      document.getElementById("tpNm").focus();
      return;
    }
    const cate1 = document.getElementById("cate1").value;
    if (!cate1) {
      alert("카테고리 그룹은 필수선택사항 입니다.");
      return;
    }
    const cate2 = document.getElementById("cate2").value;
    if (!cate2) {
      alert("카테고리는 필수선택사항 입니다.");
      return;
    }
    const secCheck = document.getElementsByName("securityFlag")[0].checked;
    let flag = "2";
    if (secCheck) flag = "1";
    const tpText = document.getElementById("tpText").value;
    if (!tpText) {
      alert("템플릿 내용은 필수입력사항 입니다.");
      document.getElementById("tpText").focus();
      return;
    }
    const cnt = document.getElementsByName("hiddenVal").length;
    const btnList = [];
    for (let i = 0; i < cnt; i++) {
      const num = document.getElementsByName("hiddenVal")[i].value;
      const btnTypeNm = "btnType_" + num; // 버튼타입
      const textNm = "btnText_" + num; // 버튼명
      const linkNm1 = "btnLink1_" + num; // 버튼링크 1
      const linkNm2 = "btnLink2_" + num; // 버튼링크 2
      const btnType = document.getElementById(btnTypeNm).value;
      let btnNm = "";
      let link1 = "";
      let link2 = "";
      let pluginId = "";
      if (btnType) {
        btnNm = document.getElementById(textNm).value;
        if (!btnNm) {
          alert("버튼명은 필수입력사항 입니다.");
          document.getElementById(textNm).focus();
          return;
        }
        if (btnType === "WL") {
          link1 = document.getElementById(linkNm1).value;
          if (!link1) {
            alert("버튼타입 웹링크의 Mobile 버튼링크는 필수입력사항 입니다.");
            document.getElementById(linkNm1).focus();
            return;
          }
          link2 = document.getElementById(linkNm2).value;
        } else if (btnType === "AL") {
          link1 = document.getElementById(linkNm1).value;
          if (!link1) {
            alert("버튼타입 앱링크의 Android 버튼링크는 필수입력사항 입니다.");
            document.getElementById(linkNm1).focus();
            return;
          }
          link2 = document.getElementById(linkNm2).value;
          if (!link2) {
            alert("버튼타입 앱링크의 IOS 버튼링크는 필수입력사항 입니다.");
            document.getElementById(linkNm2).focus();
            return;
          }
        } else if (btnType === "P1" || btnType === "P2" || btnType === "P3") {
          pluginId = document.getElementById(linkNm1).value;
          if (!pluginId) {
            alert("플러그인 버튼의 플러그인ID 버튼링크는 필수입력사항 입니다.");
            document.getElementById(linkNm1).focus();
            return;
          }
        }
        btnList.push({
          btnKind: "1",
          btnType: btnType,
          btnNm: btnNm,
          btnLink1: link1,
          btnLink2: link2,
          btnOrder: i + 1,
          btnPluginId: pluginId,
        });
      }
    }
    // if(document.getElementById("").checked) {
    //   alert("체크");
    //   return;
    // }
    if (window.confirm("템플릿을 등록하시겠습니까?")) {
      const url = "/kko/templateSave";
      const data = {
        tpType: "1",
        plusId: channel,
        tpCode: templateCode,
        tpNm: templateName,
        tpMsgType: "1",
        tpSecurityFlag: flag,
        tpText: tpText,
        tpCtCode: cate1 + cate2,
        btnList: btnList,
      };
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") setActiveContent(<List />);
        })
        .catch((error) => alert(error));
    }
  }
  const changeHandler = (checked, id) => {

    if (checked) {
    } else {
    }
  };
  return (
    <>
      <table className="table_style1">
        <colgroup>
          <col width="15%" />
          <col width="" />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <th>입력 방법</th>
            <td>
              <span className="Radiobox">
                {inputTypes.map((value) => (
                  <div className="lfloat">
                    <input
                      type="radio"
                      name={value.id}
                      id={value.id}
                      checked={selectedInput === value.id}
                      onClick={function (e) {
                        setSelectedContent(value.content);
                        setSelectedInput(value.id);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </div>
                ))}
                {/* <input type="radio" name="radio" id="id1" defaultValue="type1"
                  onChange={handleChange}
                  checked={defaultValue === "type1"}
                />
                <label htmlFor="id1">직접입력</label>
                <input
                  type="radio"
                  name="radio"
                  id="id2"
                  defaultValue="type2"
                  onChange={handleChange}
                  checked={defaultValue === "type2"}
                />
                <label htmlFor="id2">엑셀대량입력</label> */}
              </span>
            </td>
          </tr>
          {selectedContent}
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap">
        {/*selectedInput === 1 ? (
          <>
            <Button buttonName="button-border-gray" buttonValue="+ 입력란 추가" onChangePage={onAddBtnClick}/>
            <Button buttonName="button-border-gray" buttonValue="- 입력란 삭제" onChangePage={onDeleteBtnClick}/>
          </>
        ) : null*/}
        <Button
          buttonName="button-bgc-blue"
          buttonValue="템플릿 등록"
          onChangePage={handleTemplateSave}
        />
      </div>
    </>
  );
}

export function KakaoTemplateUpload1() {
  const [buttonTypeList, setButtonTypeList] = useState([]);
  const [channelRows, setChannelRows] = useState([]);
  const [codeRows, setCodeRows] = useState([]);
  const [textLen, setTextLen] = useState(0);
  const [saveType, setSaveType] = useState(0);
  const [btnNum, setBtnNum] = useState(1);
  const [btnNumQ, setBtnNumQ] = useState(2);
  const [category, setCategory] = useState([]);
  const [cate, setCate] = useState("");
  const [cate1, setCate1] = useState("");
  const [cate2, setCate2] = useState("");

  useEffect(() => {
    // 카카오톡 채널 조회
    handleChannel();
    handleCommonCode();
    handleCategory();
  }, []);

  useEffect(() => {
    setBtnCount();
  }, [buttonTypeList]);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleChannel = () => {
    const url = "/kko/getChannel";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChannelRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCategory = () => {
    const url = "/kko/getCategory/2";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCategory(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCommonCode = () => {
    const url = "/getCommonCode";
    const snList = [1024, 1025];
    const data = { snList: snList };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCateChange = (code) => {
    setCate(code);
  };

  const handleChangeText = (text) => {
    const len = text.length;
    setTextLen(len);
    if (len > 1000) alert("템플릿 내용 1000자를 초과하지 못합니다.");
  };

  function handelChangeBtnType1(num, val) {
    const linkNm1 = "btnLink1_" + num;
    const linkNm2 = "btnLink2_" + num;

    if (val === "WL") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "Mobile", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement(
          "input",
          { type: "text", placeholder: "PC", id: linkNm2 },
          null
        );
    } else if (val === "AL") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "Android", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement(
          "input",
          { type: "text", placeholder: "IOS", id: linkNm2 },
          null
        );
    } else if (val === "P1" || val === "P2" || val === "P3") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "플러그인ID", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement("li", { id: "li3" }, null);
    } else {
      var rElmLi1 = React.createElement("li", { id: "li3" }, null),
        rElmLi2 = React.createElement("li", { id: "li3" }, null);
    }

    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;

    var reactElementUl = React.createElement(
      "div",
      { id: linkText2, name: linkText2 },
      rElmLi1,
      rElmLi2
    );
    ReactDOM.render(reactElementUl, document.getElementsByName(linkText)[0]);
  }

  function setBtnCount() {
    let count = 1;
    for (let i = 1; i < btnNum + 10; i++) {
      const btnRow = "row_" + i;
      const btnCnt = "btnCnt_" + i;
      if (document.getElementById(btnRow)) {
        document.getElementsByName(btnCnt)[0].innerText = count;
        count = count + 1;
      }
    }
  }

  const handleDelete = (num) => {
    const btnRow = "row_" + num;
    document.getElementById(btnRow).remove();
    setBtnCount();
  };

  //버튼타입 '추가'버튼 클릭
  const AddBtnType = (event) => {
    event.preventDefault();

    const cnt = document.getElementsByName("btnTypeGrp").length;
    if (cnt > 4) {
      alert("버튼은 최대 5개까지 가능합니다.");
      return;
    }

    const num = btnNum;
    const btnRow = "row_" + num;
    const btnTypeNm = "btnType_" + num;
    const textNm = "btnText_" + num;
    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;
    const btnCnt = "btnCnt_" + num;

    const count = btnNum + 1;
    setBtnNum(count);

    setButtonTypeList(
      buttonTypeList.concat(
        <tr name="btnTypeGrp" id={btnRow}>
          <input type="hidden" name="hiddenVal" value={num} />
          <td name={btnCnt} id={btnCnt}></td>
          <td>
            <select
              name={btnTypeNm}
              id={btnTypeNm}
              onChange={(e) => handelChangeBtnType1(num, e.target.value)}
            >
              <option value="">선택</option>
              {codeRows.map((row) =>
                row.cgSn === 1024 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
          </td>
          <td>
            <input type="text" name={textNm} id={textNm} defaultValue="" />
          </td>
          <td name={linkText} id={linkText}>
            <div name={linkText2} id={linkText2}></div>
          </td>
          <td>
            <button
              className="delete"
              onClick={(e) => {
                handleDelete(num);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="dark" />
            </button>
          </td>
        </tr>
      )
    );
  };

  const [linkList, setLinkList] = useState([]);

  //바로연결 '추가'버튼 클릭
  const AddLinkBtn = (event) => {
    event.preventDefault();
    setLinkList(
      linkList.concat(
        <tr>
          <td></td>
          <td>
            <select name="btnType2" id="">
              <option value="">선택</option>
              {codeRows.map((row) =>
                row.cgSn === 1025 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
          </td>
          <td>
            <input type="text" name="" defaultValue="" />
          </td>
          <td>
            <div>
              <input
                type="text"
                name=""
                defaultValue=""
                placeholder="플러그인ID"
              />
            </div>
          </td>
          <td>
            <button className="delete">
              <FontAwesomeIcon icon={faTrashAlt} className="dark" />
            </button>
          </td>
        </tr>
      )
    );
  };

  const text1 = (
    <div>
      <p>
        버튼명은 변수 사용이 불가하며, 버튼링크는 변수 사용이 가능합니다.
        <br />
        ex) https://www.yourAddress.kr/
        <span className="gray666_bg">#&#123;url&#125;</span>
      </p>
      <br />
      <p className="btn_guide">
        - 배송조회 : 메시지 내용에서 택배사명과 송장번호를 파싱하여 각 택배사
        배송조회 페이지로 이동하는 배송조회 버튼 링크를 자동으로 생성합니다.
      </p>
      <p>- 웹링크 : 버튼 클릭시 인앱 브라우저에서 웹페이지가 실행됩니다.</p>
      <p>- 앱링크 : 버튼 클릭시 앱 커스텀스킴이 실행됩니다.</p>
      <p className="btn_guide">
        - 봇키워드 : 버튼 클릭시 유저로부터 "버튼명"이 텍스트로 들어간 챗버블이
        발송되며 봇 또는 상담원에게 유저의 액션을 전달할 때 이용합니다.
      </p>
      <p className="btn_guide2">
        - 메시지전달 : 버튼 클릭시 유저로부터 "버튼명+메시지 본문"이 텍스트로
        들어간 챗버블이 발송되며, 봇 또는 상담원에게 유저가 수신한 알림톡과 함께
        유저의 액션을 전달할 경우 이용합니다.
      </p>
      <p>- 상담톡전환 : 버튼 클릭시 상담톡으로 전환됩니다.</p>
      <p>- 봇전환 : 버튼 클릭시 봇으로 전환됩니다.</p>
      <p>- 배송 조회 : 버튼 클릭시 배송조회 자동연결</p>
      <p className="btn_guide2">
        - 채널 추가 : 버튼 클릭시 채널추가 단, 광고형, 복합형인 경우에만
        사용가능
      </p>
    </div>
  );

  const text2 = (
    <div>
      <p>
        바로가기명은 변수 사용이 불가하며, 바로가기 링크는 변수 사용이
        가능합니다.
        <br />
        ex) https://www.yourAddress.kr/
        <span className="gray666_bg">#&#123;url&#125;</span>
      </p>
      <br />
      <p>- 웹링크 : 버튼 클릭시 인앱 브라우저에서 웹페이지가 실행됩니다.</p>
      <p>- 앱링크 : 버튼 클릭시 앱 커스텀스킴이 실행됩니다.</p>
      <p className="btn_guide">
        - 봇키워드 : 버튼 클릭시 유저로부터 "버튼명"이 텍스트로 들어간 챗버블이
        발송되며 봇 또는 상담원에게 유저의 액션을 전달할 때 이용합니다.
      </p>
      <p className="btn_guide2">
        - 메시지전달 : 버튼 클릭시 유저로부터 "버튼명+메시지 본문"이 텍스트로
        들어간 챗버블이 발송되며, 봇 또는 상담원에게 유저가 수신한 알림톡과 함께
        유저의 액션을 전달할 경우 이용합니다.
      </p>
      <p>- 상담톡전환 : 버튼 클릭시 상담톡으로 전환됩니다.</p>
      <p>- 봇전환 : 버튼 클릭시 봇으로 전환됩니다.</p>
    </div>
  );

  const [selectedContent, setSelectedContent] = useState(null);
  const [contentsCount, setContentsCount] = useState(1);

  const buttonOption = [
    { id: "1", value: "선택안함", content: null },
    { id: "2", value: "강조표기형", content: <MsgButtonType1 /> },
    { id: "3", value: "이미지형", content: <MsgButtonType2 /> },
    { id: "4", value: "아이템리스트형", content: <MsgButtonType3 /> },
  ];

  const getSelectedContent = (id) => {
    if (id === "1") {
      setSelectedContent(<></>);
    } else if (id === "2") {
      setSelectedContent(<MsgButtonType1 />);
    } else if (id === "3") {
      setSelectedContent(<MsgButtonType2 />);
    } else if (id === "4") {
      setSelectedContent(<MsgButtonType3 />);
    }
  };

  const getContents = () => {
    const array = [];
    for (let i = 1; i <= contentsCount; i++) {
      array.push(
        <>
          <tr>
            <th>템플릿 강조 유형</th>
            <td>
              <select
                name="selectbox"
                id={i}
                onChange={(e) => {
                  getSelectedContent(e.target.value);
                }}
              >
                {buttonOption.map((b) => (
                  <option value={b.id}>{b.value}</option>
                ))}
              </select>
              {/*선택된 옵셥에 따른 컨텐츠 보여주기 */}
            </td>
          </tr>
          {selectedContent}
        </>
      );
    }
    return array;
  };

  return (
    <>
      <tr>
        <th>채널</th>
        <td>
          <select id="plusId" name="plusId">
            {channelRows.map((row) => (
              <option value={row.plusId}>{row.plusId}</option>
            ))}
          </select>
        </td>
      </tr>
      <tr>
        <th>템플릿 코드</th>
        <td>
          <input type="text" name="tpCode" id="tpCode" />
        </td>
      </tr>
      <tr>
        <th>템플릿 명</th>
        <td>
          <input type="text" name="tpNm" id="tpNm" />
        </td>
      </tr>
      <tr>
        <th>카테고리</th>
        <td>
          <select
            name="cate1"
            id="cate1"
            className="halfSelect"
            onChange={(e) => {
              setCate1(e.target.value);
            }}
          >
            <option value="">카테고리 그룹</option>
            {category.map((row) =>
              row.ctType === "1" ? (
                <option value={row.ctCd}>{row.ctNm}</option>
              ) : null
            )}
          </select>
          <span></span>
          <select
            name="cate2"
            id="cate2"
            className="halfSelect"
            onChange={(e) => {
              setCate2(e.target.value);
            }}
          >
            <option value="">카테고리</option>
            {category.map((row) =>
              row.ctType === "2" && row.ctParentCd === cate1 ? (
                <option value={row.ctCd}>{row.ctNm}</option>
              ) : null
            )}
          </select>
        </td>
      </tr>
      {saveType === 1 ? (
        <tr>
          <th>템플릿 메시지 유형</th>
          <td>
            <select name="tpMagType" id="tpMagType">
              <option value="1">기본형</option>
              <option value="2">부가 정보형</option>
              <option value="3">광고 추가형</option>
              <option value="4">복합형</option>
            </select>
          </td>
        </tr>
      ) : null}
      <tr>
        <th>
          보안 템플릿 설정 <i className=""></i>
        </th>
        <td>
          <input
            type="checkbox"
            name="securityFlag"
            id="id4"
            className="check"
          />
          <label htmlFor="id4">
            보안 템플릿 체크 시, 메인 디바이스 모바일 외 모든 서브
            디바이스에서는 메시지 내용이 노출되지 않습니다.
          </label>
        </td>
      </tr>

      {saveType === 1 ? getContents() : null}

      <tr>
        <th>템플릿 내용</th>
        <td className="table_position">
          <textarea
            name="tpText"
            id="tpText"
            onChange={(e) => {
              handleChangeText(e.target.value);
            }}
          ></textarea>
          <p className="gray999 size14">
            {textLen} /1000 <br />
          </p>
        </td>
      </tr>
      <tr>
        <th>
          <TootipText>버튼 타입</TootipText>
          <Tooltip
            horiz="right"
            vertical="middle"
            text={text1}
            maxWidth={550}
          />
          <button className="button_add" onClick={AddBtnType}>
            + 추가
          </button>
        </th>
        <td>
          <table className="table_style2 inner_table">
            <tbody>
              <tr>
                <th>No</th>
                <th>버튼타입</th>
                <th>버튼명</th>
                <th>버튼링크</th>
                <th>삭제</th>
              </tr>
              {/*<tr>
                
                <td>1</td>
                <td>
                  <select name="btnType1" id="btnTyp1_1" onChange={e => {handelChangeBtnType1(1, e.target.value)}}>
                    <option defaultValue="">선택</option>
                    {codeRows.map((row) => (
                      row.cgSn === 1024 ? <option value={row.cdId}>{row.cdNm}</option> : null
                    ))}
                  </select>
                </td>
                <td>
                  <input type="text" name="" defaultValue="" />
                </td>
                
                <td>
                  <div>
                    {selectedBtnText1}
                  </div>
                </td>
                <td></td>
              </tr>
                    */}
              {buttonTypeList}
            </tbody>
          </table>
        </td>
      </tr>

      {saveType === 1 ? (
        <tr>
          <th>
            <TootipText>바로 연결</TootipText>
            <Tooltip horiz="right" vertical="middle" text={text2} />
            <br />
            <button className="button_add" onClick={AddLinkBtn}>
              + 추가
            </button>
          </th>
          <td>
            <table className="table_style2 inner_table">
              <tbody>
                <tr>
                  <th>No</th>
                  <th>바로연결타입</th>
                  <th>바로연결명</th>
                  <th>바로연결링크</th>
                  <th>삭제</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <select name="btnType2" id="btnType2">
                      <option defaultValue="">선택</option>
                      {codeRows.map((row) =>
                        row.cgSn === 1025 ? (
                          <option value={row.cdId}>{row.cdNm}</option>
                        ) : null
                      )}
                    </select>
                  </td>
                  <td>
                    <input type="text" name="" defaultValue="" />
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        name=""
                        defaultValue=""
                        placeholder="플러그인ID"
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
                {linkList}
              </tbody>
            </table>
          </td>
        </tr>
      ) : null}
    </>
  );
}

export function MsgButtonType1() {
  return (
    <>
      <tr>
        <th>강조표기</th>
        <td>
          <input type="text" name="" defaultValue="" />
        </td>
      </tr>
      <tr>
        <th>강조표기 보조문구</th>
        <td>
          <input type="text" name="" defaultValue="" />
        </td>
      </tr>
    </>
  );
}

export function MsgButtonType2() {
  const handleSelect = (event) => {
    event.preventDefault();
  };

  const handleDelete = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <tr>
        <th>상단이미지</th>
        <td>
          <div className="buttongroup mb0">
            <Button
              buttonName="button-bgc-gray mr10"
              buttonValue="이미지 선택"
              onChangePage={handleSelect}
            />
            <Button
              buttonName="button-bgc-gray"
              buttonValue="이미지 삭제"
              onChangePage={handleDelete}
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export function MsgButtonType3() {
  const handleSelect = (event) => {
    event.preventDefault();
  };

  const handleDelete = (event) => {
    event.preventDefault();
  };

  const [linkList, setLinkList] = useState([]);
  //아이템 리스트 '추가'버튼 클릭
  const AddList = (event) => {
    event.preventDefault();
    setLinkList(
      linkList.concat(
        <tr>
          <td>no</td>
          <td>
            <input type="text" name="" defaultValue="" />
          </td>
          <td>
            <div>
              <input type="text" name="" defaultValue="" placeholder="" />
            </div>
          </td>
          <td>
            <button className="delete">
              <FontAwesomeIcon icon={faTrashAlt} className="dark" />
            </button>
          </td>
        </tr>
      )
    );
  };
  return (
    <>
      <tr>
        <th>상단이미지</th>
        <td>
          <div className="buttongroup mb0">
            <Button
              buttonName="button-bgc-gray mr10"
              buttonValue="이미지 선택"
              onChangePage={handleSelect}
            />
            <Button
              buttonName="button-bgc-gray"
              buttonValue="이미지 삭제"
              onChangePage={handleDelete}
            />
          </div>
        </td>
      </tr>
      <tr>
        <th>템플릿 헤더</th>
        <td>
          <input type="text" name="" defaultValue="" />
        </td>
      </tr>
      <tr>
        <th>아이템 하이라이트</th>
        <td>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <th>타이틀 </th>
                <td>
                  <input type="text" name="" defaultValue="" />
                </td>
              </tr>
              <tr>
                <th>디스크립션 </th>
                <td>
                  <input type="text" name="" defaultValue="" />
                </td>
              </tr>
              <tr>
                <th>썸네일 (선택) </th>
                <td>
                  <div className="buttongroup mb0">
                    <Button
                      buttonName="button-bgc-gray mr10"
                      buttonValue="이미지 선택"
                      onChangePage={handleSelect}
                    />
                    <Button
                      buttonName="button-bgc-gray"
                      buttonValue="이미지 삭제"
                      onChangePage={handleDelete}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </td>
      </tr>
      <tr>
        <th>
          아이템 리스트
          <br />
          <button className="button_add" onClick={AddList}>
            + 추가
          </button>
        </th>
        <td>
          <table>
            <thead>
              <tr>
                <th>no</th>
                <th>아이템명</th>
                <th>아이템 내용</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>no</td>
                <td>
                  <input type="text" name="" defaultValue="" />
                </td>
                <td>
                  <div>
                    <input type="text" name="" defaultValue="" placeholder="" />
                  </div>
                </td>
                <td>
                  <button className="delete">
                    <FontAwesomeIcon icon={faTrashAlt} className="dark" />
                  </button>
                </td>
              </tr>
              {linkList}
            </tbody>
            <tfoot></tfoot>
          </table>
        </td>
      </tr>
      <tr>
        <th>아이템 요약 정보</th>
        <td>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td>-</td>
                <td>
                  <input type="text" name="" defaultValue="" />
                </td>
                <td>
                  <div>
                    <input type="text" name="" defaultValue="" placeholder="" />
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </td>
      </tr>
    </>
  );
}

export function KakaoTemplateUpload2(props) {
  const [popup, setPopup] = useState(false);
  //const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const setUploadFile = props.setUploadFile;
  const downloadUploadSample = (event) => {
    event.preventDefault();
  };
  const downloadCartegoryList = (event) => {
    event.preventDefault();
  };
  const handlePopup = (event) => {
    event.preventDefault();
    setPopup(true);
  };
  const setFile = (uploadFile) => {
    setUploadFile(uploadFile);
  };
  const setPopupClose = () => {
    setPopup(false);
  };

  const downloadFile = (num) => {
    const url = "/file/download";
    const data = { sn: num };

    let fileName = "템플릿 업로드 양식.xlsx";
    if (num === 2) fileName = "템플릿 카테고리 목록.xlsx";

    axios({
      method: "POST",
      url: SEVER_DOMAIN + "/file/download/",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
        "Content-Type": "application/json",
      },
      data: {
        sn: num,
      },
    }).then((res) => {
      let blob = new Blob([res.data], { type: res.headers["content-type"] });

      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // not IE
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_self";
        if (fileName) link.download = fileName;
        link.click();
      }
    });
  };

  return (
    <>
      <tr>
        <th>템플릿 업로드 등록</th>
        <td>
          <div className="buttongroup">
            <Button
              buttonName="buttonDown mr10"
              buttonValue="업로드 양식 다운로드"
              onChangePage={(e) => {
                downloadFile(1);
              }}
            />
            <Button
              buttonName="buttonDown"
              buttonValue="템플릿 카테고리 목록 다운로드"
              onChangePage={(e) => {
                downloadFile(2);
              }}
            />
          </div>
          <div className="write-left-inner receive-area">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">파일첨부</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-input-btn-group">
                  <input
                    type="text"
                    name="fileName"
                    id="fileName"
                    value={fileName}
                    placeholder=""
                  />
                  <Button
                    buttonName="button-bgc-gray"
                    buttonValue="파일등록"
                    onChangePage={handlePopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      {/* 여기 팝업을 밖으로  */}
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="파일 업로드">
          <LayerPopFileUpload
            setFile={setFile}
            setPopupClose={setPopupClose}
            setFileName={setFileName}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function KakaoTemplateList(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;
  const custGrade = props.custGrade;
  const setSnList = props.setSnList;

  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllTp")[0].checked = true;
    else document.getElementsByName("checkAllTp")[0].checked = false;
    setSnList(sn);
  };
  const navigate = useNavigate();
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setSnList(snList);
  };
  const handleView = (sn) => {
    sessionStorage.setItem("find_temp", sn);

    sessionStorage.setItem("backCheck", "K");
    sessionStorage.setItem("search2", props.search2);
    sessionStorage.setItem("search3", props.search3);
    sessionStorage.setItem("search4", props.search4);
    sessionStorage.setItem("search5", props.search5);
    sessionStorage.setItem("search6", props.search6);
    sessionStorage.setItem("pages", props.pages);

    navigate("/kakao/template/view");
  };
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="11">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                name="checkTp"
                defaultValue=""
                id={row.tpSn}
                value={row.tpSn}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td>
              {row.corpNm}({row.plusId})
            </td>
            <td>
              <a
                onClick={(e) => {
                  handleView(row.tpSn);
                }}
              >
                {row.tpCode}
              </a>
            </td>
            <td className="max200">
              <a
                onClick={(e) => {
                  handleView(row.tpSn);
                }}
              >
                {row.tpNm}
              </a>
            </td>
            <td className="left max300">
              <div className="ellipsis2 max300">
                <a
                  onClick={(e) => {
                    handleView(row.tpSn);
                  }}
                >
                  {row.tpText}
                </a>
              </div>
            </td>
            <td>{row.countBtn > 0 ? "button" : null}</td>
            <td>
              <span
                className={
                  row.tpInspStatusNm === "승인"
                    ? "stateB"
                    : row.tpInspStatusNm === "반려"
                    ? "stateR"
                    : row.tpInspStatusNm === "등록"
                    ? "stateG"
                    : "stateY"
                }
              >
                {row.tpInspStatusNm}
              </span>
            </td>
            <td>
              <span
                className={
                  row.tpStatusNm === "정상"
                    ? "stateB"
                    : row.tpStatusNm === "대기(발송전)"
                    ? "stateG"
                    : row.tpStatusNm === "휴면삭제" || row.tpStatusNm === "중지"
                    ? "stateR"
                    : "stateY"
                }
              >
                {row.tpStatusNm}
              </span>
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
    const snNodeList = document.getElementsByName("checkTp");
    snNodeList.forEach((node) => {
      node.checked = false;
    });
    document.getElementsByName("checkAllTp")[0].checked = false;
  }, [props.data]);
  return (
    <div className="table_wrapX">
      <table className="table_style2 KakaoTemplateList checktype">
        <colgroup>
          <col width="70px;" />
          <col width="" />
          {custType === "1" && custGrade === "2" ? <col width="" /> : null}
          <col width="" />
          <col width="" />
          <col width="300px" />
          <col width="" />
          <col width="100px" />
          <col width="100px" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllTp"
                defaultValue=""
                onChange={(e) => {
                  handleAllCheck(e);
                }}
              />
            </th>
            {custType === "1" && custGrade === "2" ? <th>계정 ID</th> : null}
            <th>채널</th>
            <th>템플릿 코드</th>
            <th className="max200">템플릿 명</th>
            <th className="max300">템플릿 내용</th>
            <th>버튼정보</th>
            <th>검수상태</th>
            <th>템플릿상태</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
}

export function KakaoTemplateView() {
  return (
    <table className="table_style1 KakaoTemplateUpload1">
      <thead></thead>
      <tbody>
        <tr>
          <th>채널</th>
          <td></td>
        </tr>
        <tr>
          <th>템플릿 코드</th>
          <td></td>
        </tr>
        <tr>
          <th>템플릿 명</th>
          <td></td>
        </tr>
        <tr>
          <th>카테고리</th>
          <td>
            <select name="Category_1" id="" className="halfSelect" disabled>
              <option defaultValue="">카테고리 그룹</option>
              <option defaultValue="">목록</option>
            </select>
            <span></span>
            <select name="Category_2" id="" className="halfSelect" disabled>
              <option defaultValue="">카테고리</option>
              <option defaultValue="">목록</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>템플릿 메시지 유형</th>
          <td></td>
        </tr>
        <tr>
          <th>템플릿 강조 유형</th>
          <td></td>
        </tr>
        <tr>
          <th>등록일</th>
          <td></td>
        </tr>
        <tr>
          <th>검수 요청일</th>
          <td></td>
        </tr>
        <tr>
          <th>검수일</th>
          <td></td>
        </tr>
        <tr>
          <th>검수상태/템플릿상태</th>
          <td>
            <span className="stateY">검수요청</span>
            <span className="stateY">발송전</span>
          </td>
        </tr>
        <tr>
          <th>문의상태</th>
          <td>
            <span className="stateB">정상</span>
          </td>
        </tr>
        <tr>
          <th>보안템플릿 설정</th>
          <td></td>
        </tr>
        <tr>
          <th>템플릿 내용</th>
          <td></td>
        </tr>
        <tr>
          <th>버튼 타입</th>
          <td>
            <table className="table_style2 inner_table">
              <tr>
                <th>No</th>
                <th>버튼타입</th>
                <th>버튼명</th>
                <th>버튼링크</th>
                <th>삭제</th>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
export function KakaoTemplateResult() {
  const [commentList, setCommentList] = useState([]);
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  function handleComment() {
    const url = "/kko/templateCommentList";
    const data = { search1: sessionStorage.getItem("find_temp"), search2: "2" };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCommentList(jsonArr);
        }
      })
      .catch((error) => alert(error));
  }
  useEffect(() => {
    handleComment();
  }, []);
  return (
    <table className="table_style2">
      <thead>
        <tr>
          <th>작성자</th>
          <th>등록시간</th>
          {/* <th>상태</th> */}
          <th>결과 및 요청사항</th>
          <th>첨부파일</th>
        </tr>
      </thead>
      <tbody>
        {commentList.map((info) => (
          <tr>
            <td>{info.regId}</td>
            <td>{info.regDt}</td>
            {/* <td>{info.cmType}</td> */}
            <td className="left">{info.cmText}</td>
            <td>
              <a target="_blank" href={info.cmFilePath}>
                {info.cmFileName}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function FileUpload() {
  const [name, setName] = useState("");
  const [selectedFiles, setselectedFiles] = useState(null);

  const fileChangedHandler = (event) => {
    event.preventDefault();
    const files = event.target.files;
    setselectedFiles(files[0]);
    setName(files[0].name);
  };

  return (
    <table className="table_style1 margin0">
      <thead></thead>
      <tbody>
        <tr>
          <th>파일선택</th>
          <td>
            <input
              type="text"
              className="ellipsis"
              defaultValue={name}
              onChange={setName}
            />
            <span className="box-file-input">
              <label>
                <input type="file" name="" onChange={fileChangedHandler} />
              </label>
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
//카카오친구톡 (views/kakao/KakaoFriends.js)
export function KakaoFriendList(props) {
  const {} = props;
  const custType = props.custType;
  const custGrade = props.custGrade;
  const [popup, handlePopup] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const handlePopBtn = (url) => {
    setImgUrl(url);
    handlePopup(true);
  };
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
    setDelList(snList);
  }, [tableRows]);
  const setDelList = props.setDelList;
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
    setDelList(sn);
  };
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };
  const CopyTooltip = <div>URL 복사하기</div>;
  const friendImgUrl = useRef();
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="6">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.imgSn}
                value={row.imgSn}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td>
              <img src={row.imgUrl} alt="" />
            </td>
            <td> {row.imgType} </td>
            <td>
              {row.corpNm}({row.plusId})
            </td>
            <td>
              <TooltipHover text={CopyTooltip}>
                <a
                  onClick={() =>
                    copyToClipboard(row.imgUrl)
                      .then(() => alert("복사되었습니다."))
                      .catch(() => alert("오류가 발생하였습니다."))
                  }
                  ref={friendImgUrl}
                >
                  {row.subImgUrl}...
                </a>
              </TooltipHover>
            </td>
            <td> {row.regDt} </td>
            <td>
              <Button
                buttonName="button-border-gray ssbtn center"
                buttonValue="보기"
                onChangePage={(e) => {
                  handlePopBtn(row.imgUrl);
                }}
              />
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data, custType, custGrade]);
  return (
    <>
      <table className="table_style2 KakaoFriendList">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllDel"
                defaultValue=""
                onChange={(e) => {
                  handleAllCheck(e);
                }}
              />
            </th>
            {custType === "1" && custGrade === "2" ? <th>계정ID</th> : null}
            <th>미리보기</th>
            <th>이미지 종류</th>
            <th>채널아이디</th>
            <th>이미지 URL</th>
            <th>등록일시</th>
            <th>확대보기</th>
          </tr>
          {tableRows}
        </thead>
      </table>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="이미지 확대보기"
          size="small"
        >
          <LayerPopImgSub imgUrl={imgUrl} />
        </PopUpLayout>
      )}
    </>
  );
}

//알림톡 상단 이미지 등록 가이드 (views/kakao/KakaoTopImg.js)
export function KakaoAlrimTopImg(props) {
  const custType = props.custType;
  const custGrade = props.custGrade;
  const {} = props;
  const [imgUrl, setImgUrl] = useState("");
  const [popup, handlePopup] = useState(false);
  const setDelList = props.setDelList;
  const handlePopBtn = (url) => {
    setImgUrl(url);
    handlePopup(true);
  };
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
    setDelList(sn);
  };
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };
  const [tableRows, setTableRows] = useState("");
  const CopyTooltip = <div>URL 복사하기</div>;
  const topImgUrl = useRef();
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    /*
    <tr>
      <td>
        <input type="checkbox" name="" defaultValue="" />
      </td>
      <td>
        이미지명
      </td>
      <td>한진정보통신 (@uni_1)</td>
      <td><a href="/">https://Hisms.co.kr/kakao/images/img123eadgded...</a></td>
      <td>2021.03.24 16:34:11</td>
      <td>
        <Button buttonName="button-border-gray ssbtn center" buttonValue="보기" onChangePage={handlePopBtn}/>
      </td>
    </tr>
    */
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="5">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.imgSn}
                value={row.imgSn}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td> {row.imgTitle} </td>
            <td>
              {row.corpNm}({row.plusId})
            </td>
            <td>
              <TooltipHover text={CopyTooltip}>
                <a
                  onClick={() =>
                    copyToClipboard(row.imgUrl)
                      .then(() => alert("text copied !"))
                      .catch(() => alert("error"))
                  }
                  ref={topImgUrl}
                >
                  {row.subImgUrl}...
                </a>
              </TooltipHover>
            </td>
            <td> {row.regDt} </td>
            <td>
              <Button
                buttonName="button-border-gray ssbtn center"
                buttonValue="보기"
                onChangePage={(e) => {
                  handlePopBtn(row.imgUrl);
                }}
              />
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data, custType, custGrade]);
  return (
    <>
      <table className="table_style2 KakaoFriendList">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllDel"
                defaultValue=""
                onChange={(e) => {
                  handleAllCheck(e);
                }}
              />
            </th>
            {custType === "1" && custGrade === "2" ? <th>계정ID</th> : null}
            <th>이미지명</th>
            <th>채널아이디</th>
            <th>이미지 URL</th>
            <th>등록일시</th>
            <th>확대보기</th>
          </tr>
          {tableRows}
        </thead>
      </table>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="이미지 확대보기"
          size="small"
        >
          <LayerPopImgSub imgUrl={imgUrl} />
        </PopUpLayout>
      )}
    </>
  );
}

//알림톡 아이템 이미지 등록 가이드 (views/kakao/KakaoItemList.js)
export function KakaoAlrimItemList(props) {
  const custType = props.custType;
  const custGrade = props.custGrade;
  const {} = props;
  const [imgUrl, setImgUrl] = useState("");
  const [popup, handlePopup] = useState(false);
  const setDelList = props.setDelList;
  const handlePopBtn = (url) => {
    setImgUrl(url);
    handlePopup(true);
  };
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });
    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
    setDelList(sn);
  };
  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };

  const CopyTooltip = <div>URL 복사하기</div>;
  const itemImgUrl = useRef();
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="5">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.imgSn}
                value={row.imgSn}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" && custGrade === "2" ? (
              <td>{row.custId}</td>
            ) : null}
            <td> {row.imgTitle} </td>
            <td>
              {row.corpNm}({row.plusId})
            </td>
            <td>
              <TooltipHover text={CopyTooltip}>
                <a
                  onClick={() =>
                    copyToClipboard(row.imgUrl)
                      .then(() => alert("text copied !"))
                      .catch(() => alert("error"))
                  }
                  ref={itemImgUrl}
                >
                  {row.subImgUrl}...
                </a>
              </TooltipHover>
            </td>
            <td> {row.regDt} </td>
            <td>
              <Button
                buttonName="button-border-gray ssbtn center"
                buttonValue="보기"
                onChangePage={(e) => {
                  handlePopBtn(row.imgUrl);
                }}
              />
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data, custType, custGrade]);
  return (
    <>
      <table className="table_style2 KakaoFriendList">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllDel"
                defaultValue=""
                onChange={(e) => {
                  handleAllCheck(e);
                }}
              />
            </th>
            {custType === "1" && custGrade === "2" ? <th>계정ID</th> : null}
            <th>이미지명</th>
            <th>채널아이디</th>
            <th>이미지 URL</th>
            <th>등록일시</th>
            <th>확대보기</th>
          </tr>
          {tableRows}
        </thead>
      </table>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="이미지 확대보기"
          size="small"
        >
          <LayerPopImgSub imgUrl={imgUrl} />
        </PopUpLayout>
      )}
    </>
  );
}

// 내정보 > 업체및계정정보
export function Myinfo(props) {
  const [connectAccount, popupConnectAccount] = useState(false);
  const [cust, setCust] = useState("");
  const handleAccountInfo = props.handleAccountInfo;
  const popupClose = () => {
    popupConnectAccount(false);
  };
  const data = props.data;
  useEffect(() => {
    const custGrade = data.custGrade;
    const repCustSn = data.repCustSn;
    const custType = data.custType;
    const rows = [];
    if (custType === "1") {
      //대표계정
      rows.push(<div>대표ID</div>);
    } else if (custType === "2") {
      // 서브아이디
      rows.push(<div>{data.repCustId}</div>);
    } else if (custType === "3") {
      // 미지정
      rows.push(
        <div style={{color: "blue"}}>
          <label style={{fontWeight: "bold"}}>연동된 계정이 없습니다.</label>
          <button
            style={{marginLeft: "10px"}}
            className="ssbtn button-border-gray"
            onClick={() => {
              popupConnectAccount(true);
            }}
          >
            연동신청
          </button>
        </div>
      );
    } else if (custType === "4") {
      // 미승인
      rows.push(<div style={{color: "blue"}}>
                  <label style={{fontWeight: "bold"}}>승인대기중</label> [ {data.repCustId} ]
                </div>);
    } else if (custType === "5") {
      // 승인거부
      rows.push(
        <div style={{color: "red"}}>
          <label style={{fontWeight: "bold"}}>승인거부</label>[ {data.repCustId} ]
          <button
            style={{marginLeft: "10px"}}
            className="ssbtn button-border-gray"
            onClick={() => {
              popupConnectAccount(true);
            }}
          >
            연동신청
          </button>
        </div>
      );
    }
    setCust(rows);
  }, [data]);
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>아이디</th>
            <td>{data.custId}</td>
            <th>가입일</th>
            <td>{data.regDd}</td>
          </tr>
          <tr>
            <th>고객유형</th>
            <td>{data.custGradeNm}</td>
            <th>고객(업체)명</th>
            <td>{data.corpNm}</td>
          </tr>
          <tr>
            <th>주소지</th>
            <td>
              {data.addr} {/*data.dtlAddr*/}
            </td>
            <th>대표 계정</th>
            <td style={{width: "35%"}}>{cust}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      {connectAccount && (
        <PopUpLayout
          onClose={popupConnectAccount}
          poptitle="대표계정 연동신청"
          size="small"
        >
          <LayerPopMasterAccount
            popupClose={popupClose}
            handleAccountInfo={handleAccountInfo}
          />
        </PopUpLayout>
      )}
    </>
  );
}

// 내정보 > 업체및계정정보 > 사용자 정보
export function Userinfo(props) {
  const [editManager, popupEditManager] = useState(false);
  const data = props.data;
  const [sn, setSn] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [userNm, setUserNm] = useState("");
  const handleAccountInfo = props.handleAccountInfo;
  const setAuthCheck = props.setAuthCheck;
  const userType = props.userType;
  const dataType = props.dataType;

  const popupClose = () => {
    popupEditManager(false);
  };

  function handlerSelfAuth(userNm, custSn, userSn, mobile) {
    if (window.confirm(userNm + "님에게 인증요청을 하시겠습니까?\n이 기능은 SMS 1건을 발송하므로 과금 또는 잔액이 차감됩니다.")) {
      const url = "/user/authSend/personal";
      const data = { search3: userSn };
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => alert(error));
    }
  }

  function handleEditBtn(sn, mobile, email, userNm) {
    setSn(sn);
    setMobile(mobile);
    setEmail(email);
    setUserNm(userNm);

    popupEditManager(true);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  function handleDeleteBtn(sn, userNm) {
    
    let ans = dataType == "normal" ? "일반사용자 [ " + userNm + " ] 님을 탈퇴 처리하시겠습니까?" 
                                   : "정산담당자 [ " + userNm + " ] 님을 탈퇴 처리하시겠습니까?" 
    if (
      window.confirm(ans)
    ) {
      const url = "/user/userDelete";
      const data = { userSn: sn, userNm: userNm };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            // 재조회
            handleAccountInfo();
          }
        })
        .catch((error) => alert(error));
    } 
  }

  return (
    <>
      <table className="table_style2">
        <thead></thead>
        <tbody>
          <tr>
            <th>권한</th>
            <th>사용자 이름</th>
            <th>이메일</th>
            <th>휴대폰번호</th>
            {dataType == "normal" && <th>인증상태</th>}
            {userType === "1" && <th>관리</th>}
          </tr>
          {
            data.length == 0 && 
              <tr>
                <td colSpan={"6"}>조회된 정보가 없습니다.</td>
              </tr>
          }
          {
            data.map((row) => (  
            (
              row.userType != '3' ?
              <tr>
              <td>{row.userTypeNm}</td>
              <td>{row.userNm}</td>
              <td>{row.email}</td>
              <td>
                {row.mobile}
                {/*row.userStatus === "3" ? <Button buttonName="button-border-gray ssbtn" buttonValue="본인인증요청" 
              onChangePage={e => {requestCertification(row.userSn)}}/> : null*/}
              </td>
              {dataType == "normal" && 
                (row.userStatus === "3" ? (
                  <td>
                    <Button
                      buttonName="button-border-gray ssbtn"
                      buttonValue="인증요청"
                      defaultValue={row.userSn}
                      onChangePage={(e) => {
                        handlerSelfAuth(
                          row.userNm,
                          row.custSn,
                          row.userSn,
                          row.mobile
                        );
                      }}
                    />
                  </td>
                ) : (
                  <td>인증</td>
                ))
              }
              
              {userType === "1" && (
                <td>
                  <div className="button-group-col2">
                    <Button
                      buttonName="button-border-gray ssbtn mr10"
                      buttonValue="수정"
                      onChangePage={(e) => {
                        handleEditBtn(row.userSn, row.mobile, row.email, row.userNm);
                      }}>
                    </Button>
                    {
                     row.userTypeNm != "대표사용자" 
                     ? 
                       <Button
                        buttonName="button-border-gray ssbtn"
                        buttonValue="탈퇴"
                        onChangePage={(e) => {
                          handleDeleteBtn(row.userSn, row.userNm);
                        }}>
                       </Button>
                     :                       
                       <Button
                        buttonName=" ssbtn"
                        buttonValue="탈퇴"
                        style={{backgroundColor:"#eeeeee"}}>
                       </Button>
                    }
                </div>
                </td>
              )}
              </tr>
              :
              <></>
            )
            
          ))}
        </tbody>
      </table> 
        {editManager && (
        <PopUpLayout
          onClose={popupEditManager}
          poptitle={dataType == "normal" ? "사용자 정보 수정" : "정산담당자 정보 수정"}
          size="small"
        >
          <LayerPopEditManager
            sn={sn}
            mobile={mobile}
            email={email}
            userNm={userNm}
            popupClose={popupClose}
            handleAccountInfo={handleAccountInfo}
            dataType={dataType}
          />
        </PopUpLayout>
        )} 
    </>
  );
}

// 내정보 > 업체및계정정보 > 서브계정 연동(선불)
export function SubAccountPrepaid(props) {
  const [addsubAccount, popupAddsubAccount] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [selectCust, setSelectCust] = useState("");
  const handlePageChange = props.handlePageChange;
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;

  const mainData = props.main;
  const popupClose = () => {
    popupAddsubAccount(false);
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  function handleAdmissionBtn(sn, id) {
    if (
      window.confirm(
        "[ ID : " + id + " ] 의 서브계정 요청을 승인 하시겠습니까?"
      )
    ) {
      handleLoadingOn();
      const url = "/user/subIdAprv";
      const data = { custSn: sn };
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handlePageChange(1);
          }
          handleLoadingClose();
        })
        .catch((error) => alert(error));
    }
  }
  function handleRefuseBtn(sn, id) {
    //event.preventDefault();
    if (
      window.confirm(
        "[ ID : " + id + " ] 의 서브계정 요청을 거절 하시겠습니까?"
      )
    ) {
      const url = "/user/subIdRefuse";
      const data = { custSn: sn };
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handlePageChange(1);
          }
          handleLoadingClose();
        })
        .catch((error) => alert(error));
    }
  }
  const handleEditBtn = (sn) => {
    //event.preventDefault();
    setSelectCust(sn);
    popupAddsubAccount(true);
  };
  function handleDeleteBtn(sn, id) {
    //event.preventDefault();
    //삭제권한은 정책을 따름
    if (
      window.confirm(
        "[ ID : " +
          id +
          " ] 의 계정을 삭제 하시겠습니까?\n(해당 계정의 사용자도 모두 삭제 됩니다.)"
      )
    ) {
      const url = "/user/subIdDelete";
      const data = { custSn: sn };
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handlePageChange(1);
          }
          handleLoadingClose();
        })
        .catch((error) => alert(error));
    }
  }
  useEffect(() => {
    const style = {
      maxWidth: {
        maxWidth: "300px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textAlign: "left",
      },
    };
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="7">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const text = row.text;
        let textSub;
        if (text && text.length > 20) textSub = row.text.substr(1, 20) + "...";
        else textSub = text;
        rows.push(
          <tr>
            <td> {row.custId} </td>
            <td> {row.corpNm} </td>
            <td> {row.userNm} </td>
            <td style={style.maxWidth}> {row.addr} </td>
            <td> {row.regDd} </td>
            {row.custType == "4" || row.custType == "5" ? (
              <td className="red">{row.custTypeNm}</td>
            ) : (
              <td>{row.custStatusNm}</td>
            )}
            <td>
              {row.custStatus == "1" && row.custType == "2" ? (
                <div className="button-group-col2">
                  <Button
                    buttonName="button-border-gray ssbtn mr10"
                    buttonValue="관리"
                    onChangePage={(e) => {
                      handleEditBtn(row.custSn);
                    }}
                  />
                  <Button
                    buttonName="button-border-gray ssbtn"
                    buttonValue="탈퇴"
                    onChangePage={(e) => {
                      handleDeleteBtn(row.custSn, row.custId);
                    }}
                  />
                </div>
              ) : row.custType == "4" ? (
                <div className="button-group-col2">
                  <Button
                    buttonName="button-border-gray ssbtn mr10"
                    buttonValue="승인"
                    onChangePage={(e) => {
                      handleAdmissionBtn(row.custSn, row.custId);
                    }}
                  />
                  <Button
                    buttonName="button-border-gray ssbtn"
                    buttonValue="거절"
                    onChangePage={(e) => {
                      handleRefuseBtn(row.custSn, row.custId);
                    }}
                  />
                </div>
              ) : null}
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data]);
  return (
    <>
      <table className="table_style2">
        <thead></thead>
        <tbody>
          <tr>
            <th>서브계정 ID</th>
            <th>업체명</th>
            <th>담당자</th>
            <th className="width400">주소</th>
            <th>등록일</th>
            <th>계정상태</th>
            <th>관리</th>
          </tr>
          {tableRows}
        </tbody>
        <tfoot></tfoot>
      </table>
      {addsubAccount && (
        <PopUpLayout
          onClose={popupAddsubAccount}
          poptitle="계약단가 편집"
          size="medium"
        >
          <LayerPopSubAccountContract
            data={selectCust}
            popupClose={popupClose}
            main={mainData}
            handleLoadingOn={handleLoadingOn}
            handleLoadingClose={handleLoadingClose}
          />
        </PopUpLayout>
      )}
    </>
  );
}

// 내정보 > 업체및계정정보 > 서브계정 연동(후불)
export function SubAccountPostpaid(props) {
  const [addsubAccount, popupAddsubAccount] = useState(false);

  const handleAdmissionBtn = (event) => {
    event.preventDefault();
  };
  const handleRefuseBtn = (event) => {
    event.preventDefault();
  };
  const handleEditBtn = (event) => {
    event.preventDefault();
    popupAddsubAccount(true);
  };
  const handleDeleteBtn = (event) => {
    event.preventDefault();
    //삭제권한은 정책을 따름
  };
  return (
    <>
      <table className="table_style2">
        <thead></thead>
        <tbody>
          <tr>
            {/*<th>서비스형태</th>*/}
            <th>서브계정 ID</th>
            <th>업체명</th>
            <th>담당자</th>
            <th>등록일</th>
            <th>계정상태</th>
            <th>관리</th>
          </tr>

          <tr>
            <td>WEB</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="red">승인요청</td>
            <td>
              <div className="button-group-col2">
                <Button
                  buttonName="button-border-gray ssbtn mr10"
                  buttonValue="승인"
                  onChangePage={handleAdmissionBtn}
                />
                <Button
                  buttonName="button-border-gray ssbtn"
                  buttonValue="거절"
                  onChangePage={handleRefuseBtn}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>API</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>정상</td>
            <td>
              <div className="button-group-col2">
                <Button
                  buttonName="button-border-gray ssbtn mr10"
                  buttonValue="관리"
                  onChangePage={handleEditBtn}
                />
                <Button
                  buttonName="button-border-gray ssbtn"
                  buttonValue="삭제"
                  onChangePage={handleDeleteBtn}
                />
              </div>
            </td>
          </tr>
          <tr className="disabled">
            <td>WEB</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>탈퇴</td>
            <td>
              <div className="button-group-col2">
                <Button
                  buttonName="button-border-gray ssbtn mr10"
                  buttonValue="관리"
                  onChangePage={handleEditBtn}
                />
                <Button
                  buttonName="button-border-gray ssbtn"
                  buttonValue="삭제"
                  onChangePage={handleDeleteBtn}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Agent</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>정상</td>
            <td>
              <div className="button-group-col2">
                <Button
                  buttonName="button-border-gray ssbtn mr10"
                  buttonValue="관리"
                  onChangePage={handleEditBtn}
                />
                <Button
                  buttonName="button-border-gray ssbtn"
                  buttonValue="삭제"
                  onChangePage={handleDeleteBtn}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      {addsubAccount && (
        <PopUpLayout
          onClose={popupAddsubAccount}
          poptitle="계약단가 편집"
          size="medium"
        >
          <LayerPopSubAccountContract />
        </PopUpLayout>
      )}
    </>
  );
}

// 내정보 > 탈퇴신청 > 현재상태 확인
export function AccountCharge(props) {
  const signOutInfo = props.signOutInfo;
  const handleSetSignOutData = props.handleSetSignOutData;
  const codeRows = props.codeRows;
  const [userWrite, setUserWrite] = useState(null);
  const buttonOption = [
    { id: "0", value: "선택" },
    { id: "1", value: "미사용" },
    { id: "2", value: "서비스 이용 불편" },
    { id: "3", value: "타 서비스 이용" },
    {
      id: "4",
      value: "기타(직접입력)",
    },
  ];
  const getSelectedContent = (e) => {
    if (e.target.value === "1") {
      setUserWrite(null);
    } else if (e.target.value === "4") {
      setUserWrite(
        <input
          type="text"
          className="wid100 mt10"
          name="remark"
          defaultValue=""
          onChange={(e) => handleSetSignOutData(e)}
        ></input>
      );
    } else {
      setUserWrite(null);
    }
    handleSetSignOutData(e);
  };
  return (
    <table className="table_style1">
      <colgroup>
        <col width="25%" />
        <col width="" />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <th>문자발송잔액</th>
          <td>{signOutInfo.amount.toLocaleString("ko-KR")}원</td>
        </tr>
        <tr>
          <th>환불수수료</th>
          <td>
            {signOutInfo.comm.toLocaleString("ko-KR")}원
            <span className="size14 gray999">
              (배분금 제외한 최종 잔액의 10%)
            </span>
          </td>
        </tr>
        <tr>
          <th>환불 가능금액</th>
          <td>{signOutInfo.refundAmount.toLocaleString("ko-KR")}원</td>
        </tr>
        <tr>
          <th>탈퇴사유</th>
          <td>
            <select
              name="reasonType"
              className="wid100"
              onChange={(e) => {
                getSelectedContent(e);
              }}
            >
              <option value="">선택</option>
              {codeRows.map((row) =>
                row.cgSn === 1031 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
            {userWrite}
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 내정보 > 탈퇴신청 > 탈퇴하신 정보
export function SignOutCharge() {
  return (
    <table className="table_style1">
      <thead></thead>
      <tbody>
        <tr>
          <th>충전금 잔액</th>
          <td>
            <p className="Radiobox">
              <input type="radio" name="refund" id="type1" checked />
              <label for="type1">잔액포기</label>
              <input type="radio" name="refund" id="type2" />
              <label for="type2">환불신청</label>
            </p>
            <p className="mt20">
              <label>
                <input
                  type="checkbox"
                  name=""
                  defaultValue=""
                  className="signoutCheck"
                />
                별도의 환불절차 없이 충전금 전액을 포기하고 탈퇴 신청
                하겠습니다.
              </label>
            </p>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 내정보 > 계약정보
export function Price(props) {
  const data = props.data;
  return (
    <table className="table_style3">
      <thead></thead>
      <tbody>
        <tr>
          <th>구분</th>
          <th>메시징 타입</th>
          <th>발송 권한</th>
          <th>단가</th>
          {/* <th>비고</th> */}
        </tr>
        <tr>
          <td rowSpan="3">기업메시징</td>
          <td>SMS (단문메시지)</td>
          <td>{data.atSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atSms == 'Y' ? data.smsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>LMS (장문메시지)</td>
          <td>{data.atlms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atlms == 'Y' ? data.lmsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>MMS (그림메시지)</td>
          <td>{data.atMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atMms == 'Y' ? data.mmsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td rowSpan="4">카카오 비즈메시지</td>
          <td>카카오 알림톡</td>
          <td>{data.atAlim == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atAlim == 'Y' ? data.alimPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>카카오 친구톡 텍스트</td>
          <td>{data.atFrd == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atFrd == 'Y' ? data.frdPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>카카오 친구톡 이미지</td>
          <td>{data.atFrdImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atFrdImg == 'Y' ? data.frdImgPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>카카오 친구톡 와이드 이미지</td>
          <td>{data.atFrdWideImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atFrdWideImg == 'Y' ? data.frdWideImgPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td rowSpan="4">RCS 차세대 메시징</td>
          <td>RCS-SMS (단문메시지)</td>
          <td>{data.atRcsSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atRcsSms == 'Y' ? data.rcsSmsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>RCS-LMS (장문메시지)</td>
          <td>{data.atRcsLms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atRcsLms == 'Y' ? data.rcsLmsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>RCS-MMS (그림메시지)</td>
          <td>{data.atRcsMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atRcsMms == 'Y' ? data.rcsMmsPrce + '원' : '-'}</td>
        </tr>
        <tr>
          <td>RCS-템플릿</td>
          <td>{data.atRcsTmpl == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
          <td>{data.atRcsTmpl == 'Y' ? data.rcsTmplPrce + '원' : '-'}</td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 내정보 > 계정정보 > 서브계정 > 관리
export function SubAccountPrice(props) {
  const main = props.main;
  const info = props.data;
  const [reseller, setReseller] = useState(false);
  
  useEffect(() => {
    if (main.custGrade === "2") setReseller(true);
    else setReseller(false);
  }, [props.data]);
  return (
    <>
      <table className="table_style1 SubAccountPrice">
        <thead>
          <tr>
            <th style={{width: "130px", textAlign: "center"}}>서비스명</th>
            <th style={{width: "120px", textAlign: "center"}}>대표 계정 발송 권한</th>
            <th style={{width: "120px", textAlign: "center"}}>대표 계정 단가</th>
            <th style={{width: "120px", textAlign: "center"}}>서브 계정 발송 권한</th>
            <th style={{width: "120px", textAlign: "center"}}>서브 계정 단가</th>
            {/*<th>비고</th>*/}
          </tr>
        </thead>
        <tbody>
          <tr style={{textAlign: "center"}}>
            <td style={{textAlign: "center"}}>SMS (단문메시지)</td>
            <td style={{textAlign: "center"}}>{main.atSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atSms == 'Y' ? main.smsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atSms == 'Y'
                ? <input type="text" id="smsPrce" style={{width: "120px"}} defaultValue={info.smsPrce}/>
                : <input type="text" id="smsPrce" style={{width: "120px"}} defaultValue={info.smsPrce} disabled/>
              ) : (
                info.smsPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>LMS (장문메시지)</td>
            <td style={{textAlign: "center"}}>{main.atlms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atlms == 'Y' ? main.lmsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atlms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atlms == 'Y' 
                ? <input type="text" id="lmsPrce" style={{width: "120px"}} defaultValue={info.lmsPrce} />
                : <input type="text" id="lmsPrce" style={{width: "120px"}} defaultValue={info.lmsPrce} disabled/>
              ) : (
                info.lmsPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>MMS (그림메시지)</td>
            <td style={{textAlign: "center"}}>{main.atMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atMms == 'Y' ? main.mmsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atMms == 'Y' 
                ? <input type="text" id="mmsPrce" style={{width: "120px"}} defaultValue={info.mmsPrce} />
                : <input type="text" id="mmsPrce" style={{width: "120px"}} defaultValue={info.mmsPrce} disabled/>
              ) : (
                info.mmsPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>카카오 알림톡</td>
            <td style={{textAlign: "center"}}>{main.atAlim == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atAlim == 'Y' ? main.alimPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atAlim == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atAlim == 'Y' 
                ? <input type="text" id="alimPrce" style={{width: "120px"}} defaultValue={info.alimPrce} />
                : <input type="text" id="alimPrce" style={{width: "120px"}} defaultValue={info.alimPrce} disabled/>
              ) : (
                info.alimPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>카카오 친구톡 텍스트</td>
            <td style={{textAlign: "center"}}>{main.atFrd == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atFrd == 'Y' ? main.frdPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atFrd == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atFrd == 'Y' 
                ? <input type="text" id="frdPrce" style={{width: "120px"}} defaultValue={info.frdPrce} />
                : <input type="text" id="frdPrce" style={{width: "120px"}} defaultValue={info.frdPrce} disabled/>
              ) : (
                info.frdPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>카카오 친구톡 이미지</td>
            <td style={{textAlign: "center"}}>{main.atFrdImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atFrdImg == 'Y' ? main.frdImgPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atFrdImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atFrdImg == 'Y' 
                ? <input type="text" id="frdImgPrce" style={{width: "120px"}} defaultValue={info.frdImgPrce} />
                : <input type="text" id="frdImgPrce" style={{width: "120px"}} defaultValue={info.frdImgPrce} disabled />
              ) : (
                info.frdImgPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>카카오 친구톡 와이드 이미지</td>
            <td style={{textAlign: "center"}}>{main.atFrdWideImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atFrdWideImg == 'Y' ? main.frdWideImgPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atFrdWideImg == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atFrdWideImg == 'Y' 
                ? <input type="text" id="frdWideImgPrce" style={{width: "120px"}} defaultValue={info.frdWideImgPrce} />
                : <input type="text" id="frdWideImgPrce" style={{width: "120px"}} defaultValue={info.frdWideImgPrce} disabled />
              ) : (
                info.frdWideImgPrce
              )}
            </td>
            {/*<td><input type="text" name="" defaultValue="" /></td>*/}
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>RCS-SMS (단문메시지)</td>
            <td style={{textAlign: "center"}}>{main.atRcsSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atRcsSms == 'Y' ? main.rcsSmsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atRcsSms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atRcsSms == 'Y' 
                ? <input type="text" id="rcsSmsPrce" style={{width: "120px"}} defaultValue={info.rcsSmsPrce} />
                : <input type="text" id="rcsSmsPrce" style={{width: "120px"}} defaultValue={info.rcsSmsPrce} disabled/>
              ) : (
                info.frdWideImgPrce
              )}
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>RCS-LMS (장문메시지)</td>
            <td style={{textAlign: "center"}}>{main.atRcsLms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atRcsLms == 'Y' ? main.rcsLmsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atRcsLms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atRcsLms == 'Y' 
                ? <input type="text" id="rcsLmsPrce" style={{width: "120px"}} defaultValue={info.rcsLmsPrce} />
                : <input type="text" id="rcsLmsPrce" style={{width: "120px"}} defaultValue={info.rcsLmsPrce} disabled/>
              ) : (
                info.rcsLmsPrce
              )}
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>RCS-MMS (그림메시지)</td>
            <td style={{textAlign: "center"}}>{main.atRcsMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atRcsMms == 'Y' ? main.rcsMmsPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atRcsMms == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atRcsMms == 'Y' 
                ? <input type="text" id="rcsMmsPrce" style={{width: "120px"}} defaultValue={info.rcsMmsPrce} />
                : <input type="text" id="rcsMmsPrce" style={{width: "120px"}} defaultValue={info.rcsMmsPrce} disabled/>
              ) : (
                info.rcsMmsPrce
              )}
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}>RCS-템플릿</td>
            <td style={{textAlign: "center"}}>{main.atRcsTmpl == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>{main.atRcsTmpl == 'Y' ? main.rcsTmplPrce + "원" : '-'}</td>
            <td style={{textAlign: "center"}}>{info.atRcsTmpl == 'Y' ? <input type="checkbox" checked/> : <input type="checkbox" disabled/>}</td>
            <td style={{textAlign: "center"}}>
              {reseller ? (
                info.atRcsTmpl == 'Y' 
                ? <input type="text" id="rcsTmplPrce" style={{width: "120px"}} defaultValue={info.rcsTmplPrce} />
                : <input type="text" id="rcsTmplPrce" style={{width: "120px"}} defaultValue={info.rcsTmplPrce} disabled/>
              ) : (
                info.rcsTmplPrce
              )}
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}

export function PrepayedContract(props) {
  const data = props.data;
  const reqData = props.reqData;
  const isRequest = props.isRequest;
  const [popup, handlePopup] = useState(false);
  const handlePopBtn = (event) => {
    event.preventDefault();
    handlePopup(true);
  };
  return (
    <div>
      <table className="table_style1">
        <colgroup>
          <col width="20%" />
          <col width="30%" />
          <col width="20%" />
          <col width="30%" />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <th>계약일</th>
            <td>
              <span>{data.regDd}</span>
            </td>
            <th>지급 기준일</th>
            <td>
              <span>-</span>
            </td>
          </tr>
          <tr>
            <th>결제조건</th>
            <td>
              <span className="mr5">선불</span>
              {reqData === undefined ? (
                <Button
                  buttonName="button-border-gray ssbtn center"
                  buttonValue="후불계정요청"
                  onChangePage={handlePopBtn}
                />
              ) : (
                <Button
                  buttonName="button-bgc-gray ssbtn center"
                  buttonValue="후불 승인대기"
                  disabled
                />
              )}
            </td>
            {
              (data.custType == 1 || data.custType == 2) && (
                <>
                  <th>결제방식</th>
                  <td>
                    <span>{data.payType}</span>
                  </td>
                </>
              )
            }
            {
              (data.custType == 3 || data.custType == 4) && (
                <>
                  <th>결제방식</th>
                  <td>
                    <span className="red">승인 후 이용가능합니다.</span>
                  </td>
                </>
              )
            }
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="후불계정 생성요청"
          size="small"
        >
          <LayerPopRequestForPayLater
            onClose={handlePopup}
            isRequest={isRequest}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export function PayLaterContract(props) {
  const data = props.data;
  return (
    <table className="table_style1">
      <colgroup>
        <col width="20%" />
        <col width="30%" />
        <col width="20%" />
        <col width="30%" />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <th>계약일</th>
          <td>
            <span>{data.regDd}</span>
          </td>
          <th>지급 기준일</th>
          <td>
            <span>매월 10일</span>
          </td>
        </tr>
        <tr>
          <th>결제조건</th>
          <td>
            <span>후불</span>
          </td>
          <th>결제방식</th>
          <td>
            <span>{data.payType}</span>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function AddManager(props) {
  const [mobile1, setMobile1] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [mobile3, setMobile3] = useState("");
  const mobile = props.mobile;
  const [authNum, setAuthNum] = useState("");

  const setChangeMobile = props.setChangeMobile;
  const setChangeEmail = props.setChangeEmail;
  const setChangeNm = props.setChangeNm;
  const setCheck = props.setCheck;
  const type = props.type;
  const handleEnterPress = props.handleEnterPress;

  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  const time = useRef(180);
  const timerId = useRef(null);
  const [timeCheck, setTimeCheck] = useState("");
  const [sn, setSn] = useState("");
  const [num, setNum] = useState("");
  const [mobileNumber, setMobileNumber] = useState(props.mobile);

  const style = {
    inputType1: {
      width: "100%",
    },
    inputType2: {
      width: "calc(100% - 135px)",
    },
    button: {
      marginLeft: "10px",
      width: "125px",
      marginRight: 0,
      top: "0px",
    },
    red: {
      color: "#ff5757",
      top: "12%",
    },
  };

  useEffect(() => {
    if (time.current <= 0) {
      alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
      clearInterval(timerId.current);
      setTimeCheck(false);
      time.current = 180;
    }
  }, [sec]);

  const handleDelete = (event) => {
    event.preventDefault();
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleNumber = (event) => {
    event.preventDefault();

    clearInterval(timerId.current);
    setTimeCheck(false);
    time.current = 180;

    const sumMobile = mobile1 + mobile2 + mobile3;

    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;

    if (!sumMobile) {
      alert("휴대폰 번호를 입력해주세요.");
      return;
    } else if (!regexMobile.test(sumMobile)) {
      alert("잘못된 형식의 휴대폰 번호 입니다.");
      return;
    }

    const url = "/auth/receiveNum";
    const data = { mobile: sumMobile, type: "1" };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          setTimeCheck(true);
          setMin(3);
          setSec(0);
          timerId.current = setInterval(() => {
            time.current -= 1;
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
          }, 1000);
        }
        alert(result.resultMsg);
      })
      .catch((error) => alert(error));
  };
  const handleConfirm = (event) => {
    event.preventDefault();

    const sumMobile = mobile1 + mobile2 + mobile3;

    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;

    if (!sumMobile) {
      alert("휴대폰 번호를 입력해주세요.");
      return;
    } else if (!regexMobile.test(sumMobile)) {
      alert("잘못된 형식의 휴대폰 번호 입니다.");
      return;
    }

    if (!timeCheck) {
      alert("인증번호를 요청하세요.");
      return;
    }

    if (!authNum) {
      alert("인증번호를 입력하세요.");
      return;
    }

    const url = "/auth/checkNum";
    const data = { num: authNum, mobile: sumMobile, type: "1" };

    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          setCheck(true);
          setChangeMobile(sumMobile);
        }
      })
      .catch((error) => alert(error));
  };

  const  handleChange = (e) => {

    const onlyNum = /[^0-9]/gi;
    const result = e.target.value.replace(onlyNum, "");

    setMobileNumber(result);
    setChangeMobile(result);
  }

  return (
    <>
      <div className="pop-stitle">
        {/*<a className="rfloat bold" href="/" onClick={handleDelete}>- 삭제</a>*/}
      </div>
      <div className="borderbox mb20">
        <div className="check-list-item">
          <div className="input-inner clearfix" id="cropNumArea">
            <div className="title-con">
              <span>사용자 이름</span>
            </div>
            <div className="input-con">
              <input
                type="text"
                maxlength="50"
                title="사용자 이름"
                id="userName"
                className="gs-custom-input fc-event"
                placeholder="사용자 이름"
                defaultValue={props.userNm}
                onChange={(e) => {
                  setChangeNm(e.target.value);
                }}
                onKeyPress={handleEnterPress}
              />
            </div>
            <p className="clear"></p>
          </div>
        </div>
        <div className="check-list-item">
          <div className="input-inner clearfix" id="cropNumArea">
            <div className="title-con">
              <span className="required">이메일</span>
            </div>
            <div className="input-con">
              <fieldset className="address-fieldset">
                <div className="address-wrapper">
                  <input
                    type="text"
                    defaultValue={props.email}
                    maxlength="50"
                    id="userEmail"
                    title="이메일"
                    className="gs-custom-input fc-event"
                    placeholder="이메일"
                    onChange={(e) => {
                      setChangeEmail(e.target.value);
                    }}
                    onKeyPress={handleEnterPress}
                  />
                </div>
              </fieldset>
            </div>
            <p className="clear"></p>
          </div>
        </div>
        <div className="check-list-item">
          <div className="input-inner clearfix" id="">
            <div className="title-con">
              <span className="required">휴대폰번호</span>
            </div>
            <div className="input-con clearfix">
                  <input
                    type="text"
                    name="mobile1"
                    id="mobile1"
                    maxlength="11"
                    title=""
                    className="gs-custom-input w-auto fc-event"
                    placeholder="휴대폰번호"
                    onChange={handleChange}
                    value={mobileNumber}
                    onKeyPress={handleEnterPress}
                  />
                <p className="clear"></p>
            </div>
            <p className="clear"></p>
          </div>
        </div>
      </div>
    </>
  );
}

export function MasterAccountList(props) {
  const setSnList = props.setSnList;
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="5">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      /*
        <td>
            <input type="checkbox" />
          </td>
          <td>Hisms</td>
          <td>한진정보통신</td>
          <td>김유*</td>
          <td>123-45-678</td>
        </tr>
        */

      for (const row of jsonArr) {
        const stat = row.pfStatus;
        const custId = "cust_" + row.sn;
        rows.push(
          <tr>
            <input type="hidden" id={custId} value={row.id} />
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkTp"
                value={row.sn}
                onChange={handleCheked}
              />
            </td>
            <td> {row.id} </td>
            <td> {row.corpNm} </td>
            <td> {row.nm} </td>
            <td> {row.bizno} </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data]);

  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    setSnList(sn);
  };

  return (
    <table className="table_style2">
      <thead>
        <tr>
          <th>선택 </th>
          <th>계정 ID</th>
          <th>업체명</th>
          <th>대표자명</th>
          <th>사업자등록번호</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function NewAccountService(props) {
  const [serviceQuantity1, setServiceQuantity1] = useState(null);
  const [serviceQuantity2, setServiceQuantity2] = useState(null);
  const [serviceQuantity3, setServiceQuantity3] = useState(null);
  const [serviceNote1, setServiceNote1] = useState(null);
  const [serviceNote2, setServiceNote2] = useState(null);
  const [serviceNote3, setServiceNote3] = useState(null);
  const style = {
    name: {
      position: "relative",
      top: -3,
      marginLeft: "5px",
    },
    textAlign: {
      textAlign: "center",
      width: "200px",
    },
  };
  const dataChange = props.handelRequestInfoChange;
  const handleRequestInit = props.handleRequestInit;
  function getServiceInfo1(event) {
    //신규계정 요청 WEB
    if (event.target.checked) {
      setServiceQuantity1(
        <NewAccountQuantity dataChange={dataChange} index={0} />
      );
      setServiceNote1(<NewAccountNote dataChange={dataChange} index={0} />);
    } else {
      setServiceQuantity1(null);
      setServiceNote1(null);
    }
  }
  function getServiceInfo2(event) {
    //신규계정 요청 API
    if (event.target.checked) {
      setServiceQuantity2(
        <NewAccountQuantity dataChange={dataChange} index={1} />
      );
      setServiceNote2(<NewAccountNote dataChange={dataChange} index={1} />);
    } else {
      setServiceQuantity2(null);
      setServiceNote2(null);
    }
  }
  function getServiceInfo3(event) {
    //신규계정 요청 Agent
    if (event.target.checked) {
      setServiceQuantity3(
        <NewAccountQuantity dataChange={dataChange} index={2} />
      );
      setServiceNote3(<NewAccountNote dataChange={dataChange} index={2} />);
    } else {
      setServiceQuantity3(null);
      setServiceNote3(null);
    }
  }

  return (
    <table className="table_style2 NewAccountTable">
      <thead>
        <tr>
          <th></th>
          <th>서비스형태</th>
          <th>생성 요청 수량</th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{width:"10px"}}>
            <label>
              <input
                type="checkbox"
                name="web"
                onClick={getServiceInfo1}
                onChange={(e) => {
                  if (e.target.checked) {
                    dataChange(e, 0);
                  } else {
                    handleRequestInit(0);
                  }
                }}
              />
            </label>
          </td>
          <td style={style.textAlign}>
            <span style={style.name}>WEB</span>
          </td>
          <td style={{width:"250px", height: "47px"}}>{serviceQuantity1}</td>
          <td style={{width:"250px", height: "47px"}}>{serviceNote1}</td>
        </tr>
        <tr>
          <td>
          <label>
              <input
                type="checkbox"
                name="api"
                onClick={getServiceInfo2}
                onChange={(e) => {
                  if (e.target.checked) {
                    dataChange(e, 1);
                  } else {
                    handleRequestInit(1);
                  }
                }}
              />
            </label>
          </td>
          <td style={style.textAlign}>
            <span style={style.name}>API</span>
          </td>
          <td style={{width:"250px", height: "47px"}}>{serviceQuantity2}</td>
          <td style={{width:"250px", height: "47px"}}>{serviceNote2}</td>
        </tr>
        <tr>
          <td>
          <label>
              <input
                type="checkbox"
                name="agent"
                onClick={getServiceInfo3}
                onChange={(e) => {
                  if (e.target.checked) {
                    dataChange(e, 2);
                  } else {
                    handleRequestInit(2);
                  }
                }}
              />
            </label>
          </td>
          <td style={style.textAlign}>
            <span style={style.name}>Agent</span>
          </td>
          <td style={{width:"250px", height: "47px"}}>{serviceQuantity3}</td>
          <td style={{width:"250px", height: "47px"}}>{serviceNote3}</td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function NewAccountQuantity(props) {
  return (
    <input
      type="text"
      name="arCnt"
      defaultValue=""
      maxLength="11"
      placeholder="숫자만 입력하세요"
      style={{width:"200px", height: "30px"}}
      onChange={(e) => {
        props.dataChange(e, props.index);
      }}
    />
  );
}

export function NewAccountNote(props) {
  return (
    <input
      type="text"
      name="arRemark"
      defaultValue=""
      maxLength="80"
      placeholder=""
      style={{width:"200px", height: "30px"}}
      onChange={(e) => {
        props.dataChange(e, props.index);
      }}
    />
  );
}

export function ContractTable(props) {
  const infoData = props.infoData;
  const smsPrce = infoData.smsPrce;
  const lmsPrce = infoData.lmsPrce;
  const mmsPrce = infoData.mmsPrce;
  const alimPrce = infoData.alimPrce;
  const frdImgPrce = infoData.frdImgPrce;
  const frdPrce = infoData.frdPrce;
  const frdWideImgPrce = infoData.frdWideImgPrce;
  const rcsSmsPrce = infoData.rcsSmsPrce;
  const rcsLmsPrce = infoData.rcsLmsPrce;
  const rcsMmsPrce = infoData.rcsMmsPrce;
  const rcsTmplPrce = infoData.rcsTmplPrce;
  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>SMS (단문메세지)</th>
          <th>LMS(장문메세지)</th>
          <th>MMS(그림메세지)</th>
        </tr>
        <tr>
          <td>{infoData.atSms == 'Y' ? smsPrce + "원" : '-'}</td>
          <td>{infoData.atlms == 'Y' ? lmsPrce + "원" : '-'}</td>
          <td>{infoData.atMms == 'Y' ? mmsPrce + "원" : '-'}</td>
        </tr>
        <tr>
          <th>카카오알림톡</th>
          <th>카카오친구톡텍스트</th>
          <th>카카오 친구톡 이미지</th>
        </tr>
        <tr>
          <td>{infoData.atAlim == 'Y' ? alimPrce + "원" : '-'}</td>
          <td>{infoData.atFrd == 'Y' ? frdPrce + "원" : '-'}</td>
          <td>{infoData.atFrdImg == 'Y' ? frdImgPrce + "원" : '-'}</td>
        </tr>
        <tr>
          <th>친구톡 와이드 이미지</th>
          <th>RCS-SMS(단문메세지)</th>
          <th>RCS-LMS(장문메세지)</th>
        </tr>
        <tr>
          <td>{infoData.atFrdWideImg == 'Y' ? frdWideImgPrce + "원" : '-'}</td>
          <td>{infoData.atRcsSms == 'Y' ? rcsSmsPrce + "원" : '-'}</td>
          <td>{infoData.atRcsLms == 'Y' ? rcsLmsPrce + "원" : '-'}</td>
        </tr>
        <tr>
          <th>RCS-MMS(그림메세지)</th>
          <th>RCS-템플릿</th>
          <th></th>
        </tr>
        <tr>
          <td>{infoData.atRcsMms == 'Y' ? rcsMmsPrce + "원" : '-'}</td>
          <td>{infoData.atRcsTmpl == 'Y' ? rcsTmplPrce + "원" : '-'}</td>
          <td></td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
// export function MasterAccountList() {
//     return (
//         <table className="table_style2">
//             <thead>
//                 <tr>
//                     <th><input type="checkbox" /></th>
//                     <th>계정 ID</th>
//                     <th>업체명</th>
//                     <th>대표자명</th>
//                     <th>사업자등록번호</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 <tr>
//                     <td><input type="checkbox" /></td>
//                     <td>Hisms</td>
//                     <td>한진정보통신</td>
//                     <td>김유*</td>
//                     <td>123-45-678</td>
//                 </tr>
//             </tbody>
//             <tfoot></tfoot>
//         </table>
//     )
// }

export function AddressList(props) {
  const custGrade = props.custGrade;
  const userType = props.userType;
  const handleCurrent = props.handleCurrent;
  const addrType = props.addrType;
  const [addressInfo, popupAddressInfo] = useState(false);
  const [tableRows, setTableRows] = useState("");
  const [snList, setSnList] = useState([]);

  const popupClose = () => {
    popupAddressInfo(false);
  };
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;

    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllTp")[0].checked = true;
    else document.getElementsByName("checkAllTp")[0].checked = false;

    setSnList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setSnList(snList);
  };

  const style = {
    tableWrap: {
      width: "100%",
      overflow: "hidden",
    },
  };

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td style={{textAlign: "center"}} colSpan="9">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        rows.push(
          <tr>
            <td style={{width: "30px"}}>
              <input
                type="checkbox"
                defaultValue=""
                name="checkTp"
                id={row.abSn}
                value={row.abSn}
                onChange={handleCheked}
              />
            </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.agNm} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abPhone} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abNm} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abEtc1} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abEtc2} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abEtc3} </td>
            <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}> {row.abEtc4} </td>
            {custGrade === "1" && userType === "1" ? (
              // 일반 등급 && 대표사용자
              <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}>
                <a
                  className="btn"
                  onClick={(e) => {
                    handleEdit(
                      row.agNm,
                      row.abSn,
                      row.abNm,
                      row.abPhone,
                      row.abEtc1,
                      row.abEtc2,
                      row.abEtc3,
                      row.abEtc4
                    );
                  }}
                >
                  수정
                </a>
              </td>
            ) : null}
            {addrType === "P" ? (
              <td style={{width: "100px", maxWidth: "100px", minWidth: "100px", textAlign: "center"}}>
                <a
                  className="btn"
                  onClick={(e) => {
                    handleEdit(
                      row.agNm,
                      row.abSn,
                      row.abNm,
                      row.abPhone,
                      row.abEtc1,
                      row.abEtc2,
                      row.abEtc3,
                      row.abEtc4
                    );
                  }}
                >
                  수정
                </a>
              </td>
            ) : null}
          </tr>
        );
      }
    }
    setTableRows(rows);

    document.getElementsByName("checkAllTp")[0].checked = false;
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
  }, [props.data]);
  const [rowData, setRowData] = useState([]);
  // row.agSn,row.agNm,row.abPhone,row.abEtc1,row.abEtc2,row.abEtc3,row.abEtc4
  const handleEdit = (agNm, sn, nm, phone, etc1, etc2, etc3, etc4) => {
    //그룹추가(등록)
    //event.preventDefault();
    setRowData({
      ag: agNm,
      sn: sn,
      nm: nm,
      phone: phone,
      etc1: etc1,
      etc2: etc2,
      etc3: etc3,
      etc4: etc4,
    });
    popupAddressInfo(true);
  };

  return (
    <div className="table_wrapX">
      <table className="table_style1 overflowTable table_wrap">
        <thead>
          <tr style={{textAlign: "center"}}>
            <th>
              <input
                type="checkbox"
                name="checkAllTp"
                onChange={handleAllCheck}
              />
            </th>
            <th style={{textAlign: "center"}}>그룹명</th>
            <th style={{textAlign: "center"}}>수신번호</th>
            <th style={{textAlign: "center"}}>이름</th>
            <th style={{textAlign: "center"}}>기타1</th>
            <th style={{textAlign: "center"}}>기타2</th>
            <th style={{textAlign: "center"}}>기타3</th>
            <th style={{textAlign: "center"}}>기타4</th>
            {custGrade === "1" && userType === "1" ? <th style={{textAlign: "center"}}>관리</th> : null}
            {addrType === "P" ? <th style={{textAlign: "center"}}>관리</th> : null}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
        <tfoot></tfoot>
      </table>
      {addressInfo && (
        <PopUpLayout
          onClose={popupAddressInfo}
          poptitle="주소록 상세정보 수정"
          size="medium"
        >
          <LayerPopEditAddressInfo
            rowData={rowData}
            popupClose={popupClose}
            handleCurrent={handleCurrent}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export function ImportAddressList(props) {
  const [tableRows, setTableRows] = useState([]);
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="3">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const rowNm = "nm_" + row.abSn;
        const rowPhone = "phone_" + row.abSn;
        rows.push(
          <tr>
            <input type="hidden" id={rowNm} value={row.abNm} />
            <input type="hidden" id={rowPhone} value={row.abPhone} />
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkTp"
                id={row.abSn}
                value={row.abSn}
                onChange={handleCheked}
              />
            </td>
            <td> {row.abNm} </td>
            <td> {row.abPhone} </td>
          </tr>
        );
      }
    }
    setTableRows(rows);

    document.getElementsByName("checkAllTp")[0].checked = false;
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
  }, [props.data]);

  const setSnList = props.setSnList;
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;

    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllTp")[0].checked = true;
    else document.getElementsByName("checkAllTp")[0].checked = false;

    setSnList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setSnList(snList);
  };
  return (
    <table className="list-table">
      <colgroup>
        <col width="50px" />
        <col width="" />
        <col width="" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              name="checkAllTp"
              onChange={handleAllCheck}
            />
          </th>
          <th>이름</th>
          <th>휴대폰번호</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function ReceiveAddressList(props) {
  const data = props.data;
  const setSelectCnt = props.setSelectCnt;
  function handleDeleteNewUser(event) {
    event.preventDefault();
  }

  const [tableRows, setTableRows] = useState([]);
  useEffect(() => {
    setTableRows(tableRows.concat(data));
    document.getElementsByName("checkAllDel")[0].checked = false;
    const nodeList = document.getElementsByName("checkDel");
    setSelectCnt(nodeList.length + data.length);
  }, [props.data]);

  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;

    snNodeList.forEach((node) => {
      if (node.checked) {
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
  };

  return (
    <table className="list-table receive">
      <colgroup>
        <col width="50px" />
        <col width="" />
        <col width="" />
        <col width="80px" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              name="checkAllDel"
              onChange={handleAllCheck}
            />
          </th>
          <th>이름</th>
          <th>휴대폰번호</th>
          <th>삭제</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function UsageTable(props) {
  
  const tableRows = props.tableRows;
  const usageSum = props.usageSum;
  return (
    <div className="table_wrapX">
      <table className="table_style2 overflowTable table_wrap">
        <thead></thead>
        <tbody>
          <tr>
            <th>사용자 계정</th>
            <th>사용월</th>
            <th>결제방식</th>
            <th>서비스유형</th>
            <th>발송 단가</th>
            <th>WEB 건수</th>
            <th>AGENT 건수</th>
            <th>API 건수</th>
            <th>공급가액</th>
            <th>부가세</th>
            <th>총 금액</th>
          </tr>
          { tableRows == undefined ? (
          <tr>
            <td colSpan="11">조회된 정보가 없습니다.</td>
          </tr>
          ) : (
          tableRows.map((info) => (
            <tr>
              <td>{info.custId}</td>
              <td>
                {info.requestDt.substr(0, 4)}년 {info.requestDt.substr(5, 2)}월
              </td>
              <td>{info.preRating}</td>
              <td>{info.msgType}</td>
              <td>{setComma(info.prce)}</td>
              <td>{setComma(info.webTot)}</td>
              <td>{setComma(info.agentTot)}</td>
              <td>{setComma(info.apiTot)}</td>
              <td>{setComma(info.amount)}</td>
              <td>{setComma(info.vat)}</td>
              <td className="right">{setComma(info.totAmount)}</td>
            </tr>
          )))}
          <tr>
            <th colSpan="5">
              <b>합계</b>
            </th>
            <th>
              <b>{setComma(usageSum.webCntSum)}</b>
            </th>
            <th>
              <b>{setComma(usageSum.agentCntSum)}</b>
            </th>
            <th>
              <b>{setComma(usageSum.apiCntSum)}</b>
            </th>
            <th>
              <b>{setComma(usageSum.amountSum)}</b>
            </th>
            <th>
              <b>{setComma(usageSum.vatSum)}</b>
            </th>
            <th className="red right">
              <b>{usageSum.resultAmountSum}</b>
            </th>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
}

export function ChargeTable(props) {
  const custType = props.custType;
  const tableRows = props.tableRows;
  const handlepositiveCheck = (num) => {
    if (Math.sign(num) === +1) {
      const numChange = setComma(num);
      return <td className="blue">{numChange}</td>;
    } else {
      return <td className="">-</td>;
    }
  };
  const handleNegaitiveCheck = (num) => {
    if (Math.sign(num) === -1) {
      const numChange = setComma(Math.abs(num));
      return <td className="red">{numChange}</td>;
    } else {
      return <td className="">-</td>;
    }
  };
  const handleconmma = (num) => {
    if (num === undefined) {
      return "";
    } else {
      return setComma(num);
    }
  };
  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          {/* 리셀러계정일 때만 노출 , td항목도 마찬가지로 수정해주세요 */}
          {custType === "1" ? <th>사용자 계정</th> : null}
          {/* 리셀러계정일 때만 노출 end */}
          <th>구분</th>
          <th>일자</th>
          <th>입금액</th>
          <th>출금액</th>
          <th>잔액</th>
          <th>비고</th>
        </tr>
        {tableRows.length == 0 ? <tr><td colSpan={custType === "1" ? "7" : "6"} style={{textAlign: "center"}}>조회된 충전내역이 없습니다.</td></tr> : <></>}
        {tableRows.map((info) => (
          <tr>
            {/* 리셀러계정일 때만 노출 , td항목도 마찬가지로 수정해주세요 */}
            {custType === "1" ? <td>{info.custId}</td> : null}
            {/* 리셀러계정일 때만 노출 end */}
            <td>{info.payType}</td>
            <td>{info.regDt}</td>
            {handlepositiveCheck(info.amount)}
            {handleNegaitiveCheck(info.amount)}
            <td>{handleconmma(info.balance)}</td>
            <td>{info.bhRemark}</td>
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function TaxbillTable() {
  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>
            <input type="checkbox" />
          </th>
          <th>발행번호</th>
          <th>종류</th>
          <th>세금등급</th>
          <th>공급가액</th>
          <th>세엑</th>
          <th>합계</th>
          <th>신청일</th>
          <th>발행일</th>
          <th>상태</th>
        </tr>
        <tr>
          <td>
            <input type="checkbox" />
          </td>
          <td>202101112-00001</td>
          <td>전자</td>
          <td>과세</td>
          <td>50,000</td>
          <td>5,000</td>
          <td>55,000</td>
          <td>2021.03.11</td>
          <td>2021.03.11</td>
          <td>신고완료</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" />
          </td>
          <td>202101112-00001</td>
          <td>전자</td>
          <td>과세</td>
          <td>50,000</td>
          <td>5,000</td>
          <td>55,000</td>
          <td>2021.03.11</td>
          <td>2021.03.11</td>
          <td>신고완료</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" />
          </td>
          <td>202101112-00001</td>
          <td>전자</td>
          <td>과세</td>
          <td>50,000</td>
          <td>5,000</td>
          <td>55,000</td>
          <td>2021.03.11</td>
          <td>2021.03.11</td>
          <td>신고완료</td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
export function PaymentTable({ tableRows, unpaid }) {
  const [paymentTotal, popupPaymentTotal] = useState(false);
  const [cmYear, setCmYear] = useState("");
  const [cmMonth, setCmMonth] = useState("");
  const [cmCustId, setCustId] = useState("");
  const [cmCustSn, setCmCustSn] = useState("");
  const [loading, setLoading] = useState(2);

  const closeLoading = () => {
    setLoading(2);
  }

  const onLoading = () => {
    setLoading(0);
  }
  const handlePay = (event, year, month, custId, cmCustSn) => {
    event.preventDefault();
    setCmYear(year);
    setCmMonth(month);
    setCustId(custId);
    setCmCustSn(cmCustSn);
    popupPaymentTotal(true);
  };

  return (
    
    <div className="table_wrapX">
        {loading !=  2 && (
    <Loader type="spin" color="black" message="요청을 처리중입니다" />
  )}
      <table className="table_style2 overflowTable table_wrap">
        <thead>
          <tr>
            <th>계정 ID</th>
            <th>사용월</th>
            <th>공급가</th>
            <th>부가세</th>
            <th>납부 방법</th>
            <th>총 청구 금액</th>
            <th>납부 금액</th>
            <th>미납 금액</th>
            <th>납부 상태</th>
            <th>납부 시각</th>
            <th>청구내역서</th>
          </tr>
        </thead>
        <tbody>
          {
            tableRows.length == 0 
            ? <tr>
                <td colSpan={"11"}>조회된 정보가 없습니다.</td>
              </tr>
            : (tableRows.map((info) => (
              <tr key={info.cmSn}>
                <td>{info.custId}</td>
                <td>
                  {info.cmYear}년 {info.cmMonth}월
                </td>
                <td className="right">{setComma(info.cmAmount)}원</td>
                <td className="right">{setComma(info.cmVat)}원</td>
                <td>{info.cmPayUse}</td>
                <td className="right">{setComma(info.cmTotAmount)}원</td>
                <td className="right">{setComma(info.cmPayAmount)}원</td>
                <td className="right">{setComma(
                      (
                        Number(info.cmTotAmount) - Number(info.cmPayAmount)
                      ).toFixed(1)
                    )}
                    원 </td>
                {info.cmStatus === "1" || info.cmStatus === "3" ? (
                  <td className="red">
                    미납
                  </td>
                ) : (
                  <td className="blue">
                    납부완료
                  </td>
                )}
                <td>{info.cmPayDt}</td>
                <td>
                  <Button
                    buttonName="button-border-gray ssbtn"
                    buttonValue="확인"
                    onChangePage={(e) =>
                      handlePay(
                        e,
                        info.cmYear,
                        info.cmMonth,
                        info.custId,
                        info.cmCustSn
                      )
                    }
                  />
                </td>
              </tr>)))
          }
          <tr>
            <th colSpan="6" className="bold">
              미납총액
            </th>
            <th colSpan="2" className="red right bold">
              {setComma(unpaid.toFixed(1))}
            </th>
            <th colSpan="3" className="red left bold">
            (VAT포함)
            </th>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      {paymentTotal && (
        <PopUpLayout onClose={popupPaymentTotal} poptitle={"청구 내역서"}>
          <LayerPopPaymentTotal
            cmYear={cmYear}
            cmMonth={cmMonth}
            cmCustSn={cmCustSn}
            cmCustId={cmCustId}
            closeLoading={closeLoading}
            onLoading={onLoading}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export function SendBoxTable(props) {
  const navigate = useNavigate();

  const custType = props.custType;
  const sendViewPage = (val) => {
    sessionStorage.setItem("backCheck", "T");
    sessionStorage.setItem("backCheck1", "T");
    sessionStorage.setItem("saveCustId", props.saveCustId);
    sessionStorage.setItem("saveMsgType", props.saveMsgType);
    sessionStorage.setItem("saveCallBack", props.saveCallBack);
    sessionStorage.setItem("saveResultType", props.saveResultType);
    sessionStorage.setItem("saveText", props.saveText);
    sessionStorage.setItem("saveDstaddr", props.saveDstaddr);

    if (props.dateStart != undefined) {
      sessionStorage.setItem("dateStart", props.dateStart);
    }

    if (props.dateEnd != undefined) {
      sessionStorage.setItem("dateEnd", props.dateEnd);
    }

    sessionStorage.setItem("pages", props.pages);

    sessionStorage.setItem("sendView", val);
    navigate("/main/messagebox/sendboxview");
  };

  const [tableRows, setTableRows] = useState("");
  const setSnList = props.setSnList;
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;

    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllTp")[0].checked = true;
    else document.getElementsByName("checkAllTp")[0].checked = false;

    setSnList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setSnList(snList);
  };

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan= {custType === "1" ? 10 :9}>조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      /*
        
        <tr>
          <td>
            <input type="checkbox" />
          </td>
          <td>2021.07.28 16:42</td>
          <td>SMS</td>
          <td>02-1234-5678</td>
          <td>010-8888-9999</td>
          <td>안녕하세요</td>
          <td>
            <span className="stateR">실패</span>
            <span className="stateB">성공</span>
            <span className="stateY">응답대기</span>
          </td>
          <td>
            <a 
              href="/main/messagebox/sendboxview" 
              className="underline" 
              onClick={sendViewPage}
            >
              상세
            </a>
          </td>
        </tr>
        */
      for (const row of jsonArr) {
        let text = row.text;
        if (row.suc === "대체성공" || row.suc === "대체실패") {
          text = row.replaceText;
        }

        let textSub;

        if (text === undefined) {
          text = "-";
        }

        if (text.length > 20) textSub = text.substr(0, 20) + "...";
        else textSub = text;

        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkTp"
                value={row.tabSeq}
                onChange={handleCheked}
              />
            </td>
            {custType === "1" ? <td> {row.custId} </td> : null}
            <td> {row.requestDt} </td>
            <td> {row.typeNm} </td>
            <td> {row.callback} </td>
            <td> {row.dstaddr} </td>
            <td> {textSub} </td>
            <td>
              <span
                className={
                  row.suc == "실패" || row.suc == "대체실패"
                    ? "stateR"
                    : row.suc == "응답대기"
                    ? "stateY"
                    : row.suc == "성공" || row.suc == "대체성공"
                    ? "stateB"
                    : null
                }
              >
                {row.suc}
              </span>
            </td>
            <td> 
              {
                (row.typeNm == "SMS" || row.typeNm == "LMS" || row.typeNm == "MMS") && row.result != ""
                ? row.result
                :(row.typeNm == "SMS" || row.typeNm == "LMS" || row.typeNm == "MMS") && row.result == ""
                ? row.resultCd

                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "성공") && row.result != ""
                ? row.result
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "성공") && row.result == ""
                ? row.resultCd

                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "실패") && row.kakaoResult != ""
                ? row.kakaoResult
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "실패") && row.kakaoResult == ""
                ? row.kakaoResultCd
                
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "대체성공") && row.kakaoResult != ""
                ? row.kakaoResult
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "대체성공") && row.kakaoResult == ""
                ? row.kakaoResultCd
                
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "대체실패") && row.result != ""
                ? row.result
                : (row.typeNm != "SMS" && row.typeNm != "LMS" && row.typeNm != "MMS" && row.suc == "대체실패") && row.result == ""
                ? row.resultCd

                : row.suc == "응답대기"
                ? "-"
                
                : null
              } </td>
            <td>
              <a
                className="underline"
                onClick={(e) => {
                  sendViewPage(row.tabSeq);
                }}
              >
                상세
              </a>
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);

    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>
            <input
              type="checkbox"
              name="checkAllTp"
              id="checkAllTp"
              onChange={(e) => {
                handleAllCheck(e);
              }}
            />
          </th>
          {custType === "1" ? <th>계정 ID</th> : null}
          <th>발송일시</th>
          <th>서비스유형</th>
          <th>발신번호</th>
          <th>수신번호</th>
          <th>메시지 내용</th>
          <th>결과</th>
          <th>결과 사유</th>
          <th>관리</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function ReservationBoxTable(props) {
  const custType = props.custType;
  const navigate = useNavigate();
  const sendViewPage = (val) => {
    const kindId = "kind_" + val;
    const sdKind = document.getElementById(kindId).value;
    const msgId = "msgType_" + val;
    const msgType = document.getElementById(msgId).value;
    const typeId = "typeNm_" + val;
    const typeNm = document.getElementById(typeId).value;

    sessionStorage.setItem("reservView", val);
    sessionStorage.setItem("reservKind", sdKind);
    sessionStorage.setItem("reservType", msgType);
    sessionStorage.setItem("reservTypeNm", typeNm);

    sessionStorage.setItem("saveCustId", props.saveCustId);
    sessionStorage.setItem("saveMsgType", props.saveMsgType);
    sessionStorage.setItem("saveCallBack", props.saveCallBack);
    sessionStorage.setItem("saveText", props.saveText);
    sessionStorage.setItem("saveDstaddr", props.saveDstaddr);
    sessionStorage.setItem("backCheck", "R");
    sessionStorage.setItem("backCheck1", "R");

    if (props.dateStart != undefined) {
      sessionStorage.setItem("dateStart", props.dateStart);
    }

    if (props.dateEnd != undefined) {
      sessionStorage.setItem("dateEnd", props.dateEnd);
    }

    sessionStorage.setItem("pages", props.pages);

    sessionStorage.setItem("sendView", val);

    navigate("/main/messagebox/reservationboxview");
  };

  const [tableRows, setTableRows] = useState("");
  const setSnList = props.setSnList;
  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkTp");
    const fnlNum = snNodeList.length;

    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllTp")[0].checked = true;
    else document.getElementsByName("checkAllTp")[0].checked = false;

    setSnList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setSnList(snList);
  };

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan= {custType === "1" ? 8 : 7}>조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      /*
        <tr>
          <td>
            <input type="checkbox" />
          </td>
          <td>2021.07.28 12:12</td>
          <td>010-1234-5678</td>
          <td>SMS</td>
          <td>안녕하세요</td>
          <td>10,000</td>
          <td>
            <a 
              href="/main/messagebox/reservationboxview" 
              className="underline" 
              onClick={sendViewPage}
            >
              상세
            </a>
          </td>
        </tr>
        */
      for (const row of jsonArr) {
        const myCustId = props.myCustId;
        const text = row.text;
        let textSub;
        if (text && text.length > 20) textSub = row.text.substr(1, 20) + "...";
        else textSub = text;

        const kindId = "kind_" + row.sdSn;
        const msgId = "msgType_" + row.sdSn;
        const typeId = "typeNm_" + row.sdSn;
        rows.push(
          <tr>
            <input type="hidden" id={kindId} value={row.sdKind} />
            <input type="hidden" id={msgId} value={row.msgType} />
            <input type="hidden" id={typeId} value={row.msgTypeNm} />
            {myCustId === row.custId ? (
              <td>
                <input
                  type="checkbox"
                  defaultValue=""
                  name="checkTp"
                  value={row.sdSn}
                  onChange={handleCheked}
                />
              </td>
            ) : (
              <td></td>
            )}

            {custType === "1" ? <td> {row.custId} </td> : null}
            <td> {row.requestDt} </td>
            <td> {row.callback} </td>
            <td> {row.msgTypeNm} </td>
            <td> {textSub} </td>
            <td> {row.totCnt} </td>
            <td>
              <a
                className="underline"
                onClick={(e) => {
                  sendViewPage(row.sdSn);
                }}
              >
                상세
              </a>
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);

    document.getElementsByName("checkAllTp")[0].checked = false;
    const snNodeList = document.getElementsByName("checkTp");
    let snList = [];
    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>
            <input
              type="checkbox"
              name="checkAllTp"
              id="checkAllTp"
              onChange={(e) => {
                handleAllCheck(e);
              }}
            />
          </th>
          {custType === "1" ? <th>고객 ID</th> : null}
          <th>예약일시</th>
          <th>발신번호</th>
          <th>서비스유형</th>
          <th>메시지내용</th>
          <th>총건수</th>
          <th>관리</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function SendStatisticTable(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || (jsonArr.length === 1 && custType === "1")) {
      rows.push(
        <tr>
          <td colSpan="13">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else if (!jsonArr || (jsonArr.length === 1 && custType === "2")) {
      rows.push(
        <tr>
          <td colSpan="11">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      /*
        <tr>
          <td>4</td>
          <td>2020.08.21</td>
          <td>LMS</td>
          <td>37</td>
          <td>7</td>
          <td>30</td>
          <td>0</td>
          <td>30</td>
          <td>0</td>
          <td>5.28%</td>
        </tr>
        <tr>
          <th colSpan="3" className="blue">Total</th>
          <th className="blue">113</th>
          <th className="blue">63</th>
          <th className="blue">30</th>
          <th className="blue">20</th>
          <th className="blue">30</th>
          <th className="blue">20</th>
          <th className="blue">51.32%</th>
        </tr>
        */
      let cnt = 1;
      const totCnt = jsonArr.length;
      for (const row of jsonArr) {
        const avg = (row.suc / row.tot) * 100;
        const fnlAvg = avg.toFixed(2);
        if (totCnt === cnt) {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="5" className="blue">
                  Total
                </th>
              ) : (
                <th colSpan="4" className="blue">
                  Total
                </th>
              )}
              <th className="blue">{setComma(row.tot)}</th>
              <th className="blue">{setComma(row.suc)}</th>
              <th className="blue">{setComma(row.err1)}</th>
              <th className="blue">{setComma(row.err2)}</th>
              <th className="blue">{setComma(row.etcErr)}</th>
              <th className="blue">{setComma(row.stay)}</th>
              <th className="blue">{fnlAvg}%</th>
            </tr>
          );
        } else {
          rows.push(
            <tr>
              <td>{row.no}</td>
              {custType === "1" ? <td>{row.custId}</td> : null}
              <td>{row.reqDd}</td>
              <td>{row.msgTypeNm}</td>
              <td>{row.sdStatus}</td>
              <td>{setComma(row.tot)}</td>
              <td>{setComma(row.suc)}</td>
              <td>{setComma(row.err1)}</td>
              <td>{setComma(row.err2)}</td>
              <td>{setComma(row.etcErr)}</td>
              <td>{setComma(row.stay)}</td>
              <td>{fnlAvg}%</td>
            </tr>
          );
        }
        cnt = cnt + 1;
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>NO</th>
          {custType === "1" ? <th>계정 ID</th> : null}
          <th>전송일자</th>
          <th>서비스유형</th>
          <th>발송 승인 여부</th>
          <th>총건수</th>
          <th>성공</th>
          <th>결번</th>
          <th>스팸</th>
          <th>기타오류</th>
          <th>전송중</th>
          <th>성공률</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function ReceivingNumberList() {
  return (
    <table className="table_style1">
      <thead>
        <tr>
          <th>이름</th>
          <th>수신번호</th>
          <th>기타1</th>
          <th>기타2</th>
          <th>기타3</th>
          <th>기타4</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>김유니</td>
          <td>0101234578</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function SendProfileStatisticTable(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (jsonArr.length === 2 && custType === "1") {
      rows.push(
        <tr>
          <td colSpan="16">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else if (jsonArr.length === 2 && custType === "2") {
      rows.push(
        <tr>
          <td colSpan="12">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        if (row.rowType === "normal") {
          rows.push(
            <tr>
              {custType === "1" ? <td>{row.custId}</td> : null}
              <td>{row.plusId}</td>
              <td>{row.reqDd}</td>
              <td>{setComma(row.alimTot)}</td>
              <td>{setComma(row.alimSuc)}</td>
              <td>{setComma(row.friTot)}</td>
              <td>{setComma(row.friSuc)}</td>
              <td>{setComma(row.friImgTot)}</td>
              <td>{setComma(row.friImgSuc)}</td>
              <td>{setComma(row.friWideImgTot)}</td>
              <td>{setComma(row.friWideImgSuc)}</td>
              <td>{setComma(row.rowTot)}</td>
              <td>{setComma(row.rowSuc)}</td>
            </tr>
          );
        } else if (row.rowType === "subTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="3" className="blue">
                  소계
                </th>
              ) : (
                <th colSpan="2" className="blue">
                  소계
                </th>
              )}
              <th className="blue">{setComma(row.alimTot)}</th>
              <th className="blue">{setComma(row.alimSuc)}</th>
              <th className="blue">{setComma(row.friTot)}</th>
              <th className="blue">{setComma(row.friSuc)}</th>
              <th className="blue">{setComma(row.friImgTot)}</th>
              <th className="blue">{setComma(row.friImgSuc)}</th>
              <th className="blue">{setComma(row.friWideImgTot)}</th>
              <th className="blue">{setComma(row.friWideImgSuc)}</th>
              <th className="blue">{setComma(row.rowTot)}</th>
              <th className="blue">{setComma(row.rowSuc)}</th>
            </tr>
          );
        } else if (row.rowType === "mainTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="3" className="blue">
                  <b>총계</b>
                </th>
              ) : (
                <th colSpan="2" className="blue">
                  <b>총계</b>
                </th>
              )}
              <th className="blue">
                <b>{setComma(row.alimTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.alimSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friImgTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friImgSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friWideImgTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.friWideImgSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rowTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rowSuc)}</b>
              </th>
            </tr>
          );
        }
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          {custType === "1" ? <th rowSpan="2">계정 ID</th> : null}
          <th rowSpan="2">카카오 채널</th>
          <th rowSpan="2">발송일</th>
          <th colSpan="2">알림톡</th>
          <th colSpan="2">친구톡 텍스트</th>
          <th colSpan="2">친구톡 이미지</th>
          <th colSpan="2">친구톡 와이드</th>
          <th colSpan="2">총계</th>
        </tr>
        <tr>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function SendTemplateStatisticTable(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (jsonArr.length === 2) {
      rows.push(
        <tr>
          <td colSpan="10">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        if (row.rowType === "normal") {
          rows.push(
            <tr>
              {custType === "1" ? <td>{row.custId}</td> : null}
              <td>{row.plusId}</td>
              <td>{row.tpCode}</td>
              <td>{row.reqDd}</td>
              <td>{setComma(row.alimTot)}</td>
              <td>{setComma(row.alimSuc)}</td>
            </tr>
          );
        } else if (row.rowType === "subTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="4" className="blue">
                  소계
                </th>
              ) : (
                <th colSpan="3" className="blue">
                  소계
                </th>
              )}
              <th className="blue">{setComma(row.alimTot)}</th>
              <th className="blue">{setComma(row.alimSuc)}</th>
            </tr>
          );
        } else if (row.rowType === "mainTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="4" className="blue">
                  <b>총계</b>
                </th>
              ) : (
                <th colSpan="3" className="blue">
                  <b>총계</b>
                </th>
              )}
              <th className="blue">
                <b>{setComma(row.alimTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.alimSuc)}</b>
              </th>
            </tr>
          );
        }
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          {custType === "1" ? <th rowSpan="2">계정 ID</th> : null}
          <th rowSpan="2">채널아이디</th>
          <th rowSpan="2">카카오템플릿코드(템플릿명)</th>
          <th rowSpan="2">발송일</th>
          <th colSpan="2">알림톡</th>
        </tr>
        <tr>
          <th>발송</th>
          <th>성공</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
export function RefundRequestTable(props) {
  const signOutData = props.signOutData;
  const codeRows = props.codeRows;
  const handleSetSignOutData = props.handleSetSignOutData;
  const style = {
    select: {
      width: "50%",
      position: "relative",
      top: "2px",
      marginLeft: "70px",
      marginTop: "20px",
    },
    accountNumber: {
      width: "50%",
      background: "lightgrey",
      marginLeft: "40px",
    },
    left: {
      width: "100px",
      display: "inline-block",
    },
  };
  return (
    <table className="table_style1">
      <thead></thead>
      <tbody>
        <tr>
          <th>충전금잔액</th>
          <td>{signOutData.amount.toLocaleString("ko-kr")}원</td>
        </tr>
        <tr>
          <th>환불수수료</th>
          <td>{signOutData.comm.toLocaleString("ko-kr")}원</td>
        </tr>
        <tr>
          <th>환불 가능금액</th>
          <td>{signOutData.refundAmount.toLocaleString("ko-kr")}원</td>
        </tr>
        <tr>
          <th>환불계좌</th>
          <td>
            <div className="check-list-item">
              <div className="input-inner clearfix" id="cropNumArea">
                <div className="title-con">예금주</div>
                <div className="input-con">
                  <input
                    type="text"
                    name="owner"
                    id=""
                    maxlength="20"
                    title="예금주"
                    className="gs-custom-input fc-event"
                    onChange={(e) => handleSetSignOutData(e)}
                    placeholder="예금주명을 입력해주세요."
                  />
                </div>
                <p className="clear"></p>
              </div>
            </div>
            <div className="check-list-item">
              <div className="input-inner selectWith clearfix" id="cropNumArea">
                <div className="title-con">계좌번호</div>
                <div className="input-con">
                  <select
                    id="callbackList"
                    name="fnclCmpy"
                    data-fakeform-index="0"
                    onChange={(e) => handleSetSignOutData(e)}
                  >
                    <option defaultValue="">은행을 선택하세요.</option>
                    {codeRows.map((row) =>
                      row.cgSn === 1034 ? (
                        <option value={row.cdId}>{row.cdNm}</option>
                      ) : null
                    )}
                  </select>
                  <input
                    type="text"
                    name="accountNo"
                    id=""
                    maxlength="20"
                    title="계좌번호"
                    className="gs-custom-input fc-event"
                    placeholder="계좌번호를 입력해주세요."
                    onChange={(e) => handleSetSignOutData(e)}
                  />
                </div>
                <p className="clear"></p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>환불 사유</th>
          <td>
            <div className="check-list-item">
              <div className="input-inner clearfix" id="cropNumArea">
                <div className="input-con">
                  <input
                    type="text"
                    name="refundRemark"
                    className="gs-custom-input fc-event"
                    placeholder="환불 사유를 입력해주세요."
                    onChange={(e) => handleSetSignOutData(e)}
                  />
                </div>
                <p className="clear"></p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function SendUserInfo() {
  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          <th>상태</th>
          <th>등록일</th>
          <th>발송자정보</th>
          <th>수신동의</th>
          <th>관리</th>
          <th>삭제</th>
        </tr>
        <tr>
          <td>인증대기중</td>
          <td>2021-07-29 13:49:24</td>
          <td className="left">
            <p className="bold">발송자정보</p>
            <a href="/" className="filebutton">
              파일이름.jpg
            </a>
          </td>
          <td>
            <a href="/" className="filebutton">
              파일이름.jpg
            </a>
          </td>
          <td>-</td>
          <td>
            <a href="/" className="underline">
              삭제
            </a>
          </td>
        </tr>
        <tr>
          <td>인증대기중</td>
          <td>2021-07-29 13:49:24</td>
          <td className="left">
            <p className="bold">발송자정보</p>
            <a href="/" className="filebutton">
              파일이름.jpg
            </a>
          </td>
          <td>
            <a href="/" className="filebutton">
              파일이름.jpg
            </a>
          </td>
          <td>-</td>
          <td>
            <a href="/" className="underline">
              삭제
            </a>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

//발신번호 관리 - 사용중
export function UseSendNumberInfo(props) {
  const [tableRows, setTableRows] = useState([]);

  const handlePageChoice = props.handlePageChoice;

  const ViewUploadFile = (event) => {
    event.preventDefault();
  };
  const handleCancle = (event) => {
    event.preventDefault();
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  function handleCancelCallback(sn, num) {
    if (
      window.confirm(
        "[ 발신번호 : " + num + " ] 을 해지상태로 전환 하시겠습니까?"
      )
    ) {
      const url = "/callback/cancel";
      const data = { cbSn: sn };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handlePageChoice(0);
          }
        })
        .catch((error) => alert(error));
    }
  }

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    // defaultChecked
    let cnt = 0;
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="8">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        let newId = "new_" + cnt;
        let oldId = "old_" + cnt;
        let snId = "sn_" + cnt;
        let checkId = "id" + cnt;
        let snTypeId = "snType_" + cnt;

        rows.push(
          <tr>
            <input type="hidden" name="snId" id={snId} value={row.cbSn} />
            <input
              type="hidden"
              name="snType"
              id={snTypeId}
              value={row.cbType}
            />
            <td>
              {row.cbType == 1 ? (
                <input
                  type="radio"
                  name="defaultSendNumber"
                  id={checkId}
                  defaultChecked 
                  key={row.callback}

                />
              ) : (
                  <input type="radio"
                    name="defaultSendNumber"
                    id={checkId}
                    key={row.callback}
                  />
              )}
              <label htmlFor={checkId}></label>
            </td>
            <td>{row.callback}</td>
            <td>
              <input
                type="text"
                name="cbNm"
                id={newId}
                defaultValue={row.cbNm}
                placeholder="이름"
                key={row.cbNm}
              />
              <input type="hidden" name="cbNmOld" id={oldId} value={row.cbNm}  />
            </td>
            <td>{row.cbStatusNm}</td>
            <td>{row.regDt}</td>
            <td>{row.cbDt}</td>
            {/* <td>
                  <Button
                    buttonName="button-border-gray ssbtn center"
                    buttonValue="확인"
                    onChangePage={ViewUploadFile}
                  />
            </td>*/}
            <td>
              <Button
                buttonName="button-border-gray ssbtn center"
                buttonValue="해지"
                onChangePage={(e) => {
                  handleCancelCallback(row.cbSn, row.callback);
                }}
              />
            </td>
          </tr>
        );
        cnt = cnt + 1;
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2 SendNumberInfo">
      <thead></thead>
      <tbody>
        <tr>
          <th>기본발신</th>
          <th>발신번호</th>
          <th>이름</th>
          <th>상태</th>
          <th>등록일자</th>
          <th>유효일자</th>
          {/*  <th>첨부문서</th>*/}
          <th>관리</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

//발신번호 관리 - 미사용중
export function UnUsedSendNumberInfo(props) {
  const [tableRows, setTableRows] = useState([]);
  const [popup, setPopup] = useState(false);
  const [number, setNumber] = useState(false);
  const [cbKind, setCbKind] = useState(false);
  const handlePageChoice = props.handlePageChoice;
  const handleAddNumber = () => {
    setPopup(true);
  };

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="4">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        const stat = row.cbType;
        rows.push(
          <tr>
            <td>{row.callback}</td>
            <td>
              <span
                className={
                  stat === "3"
                    ? "red"
                    : stat === "4"
                    ? "gray999"
                    : stat === 5
                    ? "red"
                    : null
                }
              >
                {" "}
                {row.cbStatusNm}
              </span>
            </td>
            <td>{row.modDt}</td>
            <td>
              {row.cbStatusNm === "해지" ||
              row.cbStatusNm === "반려" ||
              row.cbStatusNm === "유효기간 지남" ? (
                <Button
                  buttonName="ssbtn button-bgc-dark"
                  buttonValue="재인증"
                  onChangePage={(e) => {
                    handleAddNumber();
                    setNumber(row.callback);
                    setCbKind(row.cbKind);
                  }}
                />
              ) : null}
            </td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data]);

  const popupClose = () => {
    setPopup(false);
  };

  return (
    <div>
      <table className="table_style2 SendNumberInfo">
        <thead></thead>
        <tbody>
          <tr>
            <th>발신번호</th>
            <th>상태</th>
            <th>상태 변경일시</th>
            <th>관리</th>
          </tr>
          {tableRows}
        </tbody>
        <tfoot></tfoot>
      </table>
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="발신번호 재인증">
          <LayerPopTryAuthSendNumber
            handlePageChoice={handlePageChoice}
            popupClose={popupClose}
            number={number}
            cbKind={cbKind}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

// 공지사항 리스트
export function NoticeTable(props) {
  const [tableRows, setTableRows] = useState("");
  const navigate = useNavigate();

  const handleView = (sn) => {
    sessionStorage.setItem("find_noti", sn);
    sessionStorage.setItem("saveSearch1", props.saveSearch1);
    sessionStorage.setItem("saveSearch2", props.saveSearch2);
    sessionStorage.setItem("backCheck", "NOTICE");
    sessionStorage.setItem("pages", props.pages);
    navigate("/customer/notice/view");
  };

  useEffect(() => {
    const jsonArr = props.data;
    const currentPage = props.currentPage;
    const rowCount = props.rowCount;
    const rows = [];
    /*
    <tr>
      <td>1</td>
      <td className="left">
      <NavLink to="/customer/notice/view">
          업데이트 일정 안내(~3/30)
        <span className="notice_title ml10">
          <span className="new">NEW</span>
        </span>
      </NavLink>
      </td>
      <td>2020.11.21 17:42</td>
    </tr>
    <tr>
      <td>2</td>
      <td className="left"><NavLink to="/customer/notice/view">업데이트 일정 안내(~3/30)</NavLink></td>
      <td>2020.11.21 17:42</td>
    </tr>rowCount
    */
    let count = (currentPage - 1) * rowCount;

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="3">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        count = count + 1;
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>{count}</td>
            <td className="left">
              <a
                onClick={(e) => {
                  handleView(row.bnSn);
                }}
              >
                {row.bnTitle}
              </a>
            </td>
            <td>{row.regDt}</td>
          </tr>
        );
      }
    }

    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <colgroup>
        <col width="10%" />
        <col width="*" />
        <col width="20%" />
      </colgroup>
      <thead>
        <tr>
          <th>번호</th>
          <th>제목</th>
          <th>작성일자</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

// SW자료실 리스트
export function ReferenceTable(props) {
  const [tableRows, setTableRows] = useState("");
  const navigate = useNavigate();
  let now = new Date();
  let todayDate = now.getDate();
  const handleView = (sn) => {
    sessionStorage.setItem("find_sw", sn);

    sessionStorage.setItem("saveSearch1", props.saveSearch1);
    sessionStorage.setItem("saveSearch2", props.saveSearch2);
    sessionStorage.setItem("backCheck", "S/W");
    sessionStorage.setItem("pages", props.pages);
    navigate("/mypage/reference/view");
  };
  useEffect(() => {
    const jsonArr = props.data;
    const currentPage = props.currentPage;
    const rowCount = props.rowCount;
    const rows = [];
    let count = (currentPage - 1) * rowCount;
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="3">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        count = count + 1;
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>{count}</td>
            <td>{row.bsAuth}</td>
            <td className="left">
              <a
                onClick={(e) => {
                  handleView(row.bsSn);
                }}
              >
                {row.bsTitle}
                {todayDate === new Date(row.regDt).getDate() ? (
                  <span className="notice_title ml10">
                    <span className="new">NEW</span>
                  </span>
                ) : null}
              </a>
            </td>
            <td>{row.regDt}</td>
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props.data]);
  return (
    <table className="table_style2">
      <colgroup>
        <col width="10%" />
        <col width="10%" />
        <col width="*" />
        <col width="20%" />
      </colgroup>
      <thead>
        <tr>
          <th>번호</th>
          <th>서비스</th>
          <th>제목</th>
          <th>작성일자</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 로그인 사용자 인증
export function AccountUserList(props) {
  const requestCertification = (event) => {
    event.preventDefault();
  };
  return (
    <table className="table_style2 LoginStep">
      <thead>
        <tr>
          <th>선택</th>
          <th>사용자 이름</th>
          <th>휴대폰 번호</th>
          <th>이메일</th>
          <th>인증 상태</th>
          <th>인증</th>
        </tr>
      </thead>
      <tbody>{props.data}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 회원가입 시 동일한 사업자등록번호 내역
export function ConnectMaster() {
  return (
    <table className="table_style2 LoginStep">
      <thead>
        <tr>
          <th>대표계정 ID</th>
          <th>업체명</th>
          <th>대표자명</th>
          <th>사업자등록번호</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Hisms</td>
          <td>한진정보통신</td>
          <td>김유*</td>
          <td>123-45-67891</td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 회원가입 시 동일한 사업자등록번호 내역
export function JoinAccountList(props) {
  return (
    <table className="table_style2 LoginStep">
      <thead>
        <tr>
          <th></th>
          <th>사용 ID</th>
          <th>대표 담당자</th>
          <th>대표 담당자 연락처</th>
          <th>가입일</th>
        </tr>
      </thead>
      <tbody>{props.data}</tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 충전금 배분하기
export function DivideCharge(props) {
  const subBalanceInfo = props.subBalanceInfo;
  const handleSetSubAmount = props.handleSetSubAmount;
  return (
    <table className="table_style2 charge_table">
      <thead>
        <tr>
          <th>계정 ID</th>
          <th>업체명</th>
          <th>계정 잔액</th>
          <th>배분할 금액</th>
        </tr>
      </thead>
      <tbody>
        {subBalanceInfo.map((info, index) => (
          <tr>
            <td>{info.custId}</td>
            <td>{info.corpNm}</td>
            <td>{info.balance}</td>
            <td>
              <input
                type="text"
                name="amount"
                value={info.amount}
                placeholder="숫자만 입력해주세요."
                onChange={(e) => handleSetSubAmount(e, index)}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 충전금 회수하기
export function CollectCharge(props) {
  const subBalanceInfo = props.subBalanceInfo;
  const handleSetSubAmount = props.handleSetSubAmount;
  return (
    <table className="table_style2 charge_table">
      <thead>
        <tr>
          <th>계정 ID</th>
          <th>업체명</th>
          <th>회수가능 잔액</th>
          <th>회수할 금액</th>
        </tr>
      </thead>
      <tbody>
        {subBalanceInfo.map((info, index) => (
          <tr>
            <td>{info.custId}</td>
            <td>{info.corpNm}</td>
            <td>{info.balance}</td>
            <td>
              <input
                type="text"
                name="amount"
                value={info.amount}
                placeholder="숫자만 입력해주세요."
                onChange={(e) => handleSetSubAmount(e, index)}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 요금결제 및 납부(후불) - 결제하기
export function PaymentTotal() {
  return (
    <table className="table_style2">
      <thead>
        <tr>
          <th>구분</th>
          <th>사용 건수</th>
          <th>공급가 (VAT 별도)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>SMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>LMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>MMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>알림톡</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>친구톡 텍스트</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>친구톡 이미지</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>친구톡 와이드 이미지</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>RCS-SMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>RCS-LMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>RCS-MMS</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
        <tr>
          <td>RCS-템플릿</td>
          <td>130</td>
          <td>2,600</td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 요금결제 및 납부(선불) - 충전금액 계산기
export function ChargeCalculator(props) {
  const handleEnterPress = props.handleEnterPress;
  const handleMsgCnt = props.handleMsgCnt;
  const msgsCnt = props.msgsCnt;
  return (
    <table className="table_style3 table_calculator">
      <thead></thead>
      <tbody>
        <tr>
          <th>SMS</th>
          <td>
            <input
              type="text"
              name="sms"
              defaultValue=""
              value={msgsCnt.sms}
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th>LMS</th>
          <td>
            <input
              type="text"
              name="lms"
              value={msgsCnt.lms}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
        </tr>
        <tr>
          <th>MMS</th>
          <td>
            <input
              type="text"
              name="mms"
              value={msgsCnt.mms}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th>알림톡</th>
          <td>
            <input
              type="text"
              name="alim"
              value={msgsCnt.alim}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
        </tr>
        <tr>
          <th>친구톡 텍스트</th>
          <td>
            <input
              type="text"
              name="frd"
              value={msgsCnt.frd}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th>친구톡 이미지</th>
          <td>
            <input
              type="text"
              name="frdImg"
              value={msgsCnt.frdImg}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
        </tr>
        <tr>
          <th>친구톡 와이드 이미지</th>
          <td>
            <input
              type="text"
              name="frdWideImg"
              value={msgsCnt.frdWideImg}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th>RCS-SMS</th>
          <td>
            <input
              type="text"
              name="rcsSms"
              value={msgsCnt.rcsSms}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
        </tr>
        <tr>
          <th>RCS-LMS</th>
          <td>
            <input
              type="text"
              name="rcsLms"
              value={msgsCnt.rcsLms}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th>RCS-MMS</th>
          <td>
            <input
              type="text"
              name="rcsMms"
              value={msgsCnt.rcsMms}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
        </tr>
        <tr>
          <th>RCS-템플릿</th>
          <td>
            <input
              type="text"
              name="rcsTp"
              value={msgsCnt.rcsTp}
              defaultValue=""
              onChange={(e) => handleMsgCnt(e)}
              onKeyPress={handleEnterPress}
            />
          </td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

//RCS 템플릿 등록
export function RcsTemplateRegisterTable(props) {
  const [popup, handlePopup] = useState(false);
  const navigate = useNavigate();
  const [selectedInput, setSelectedInput] = useState(1);
  const [selectedContent, setSelectedContent] = useState(
    <RcsTemplateUploadType1 brandIdList={props.brandIdList} data={props.data} />
  );
  const [previewBtn, setPreviewBtn] = useState(true);
  const [templateBody, setTemplateBody] = useState("");
  const [templateBtn, setTemplateBtn] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [check, setCheck] = useState("N");

  const inputTypes = [
    {
      id: 1,
      value: "직접입력",
      state: true,
      content: (
        <RcsTemplateUploadType1
          brandIdList={props.brandIdList}
          data={props.data}
        />
      ),
    },
    {
      id: 2,
      value: "엑셀대량입력",
      state: false,
      content: (
        <RcsTemplateUploadType2
          brandIdList={props.brandIdList}
          setUploadFile={setUploadFile}
          data={props.data}
        />
      ),
    },
  ];

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  /*  const handleTemplateSave = () => {
    let type = document.getElementById("1").checked;
    if (type) {
      // 직접입력
      handleUpload();
    } else {
      // 엑셀대량입력
      excelTemplate();
    }
  }; */

  function excelTemplate() {

    let brandId = document.getElementById("brandNm1").value;

    if (!uploadFile) {
      alert("업로드 할 엑셀 파일을 선택해주세요.");
      return;
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (check === "N") {
      alert("동의 후 등록 가능합니다.");
      return;
    }

    var checkedRcsCardType = document.querySelector(
      "input[name='RCSCardType']:checked"
    );

    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("brand", brandId);
    formData.append("cardType", checkedRcsCardType.value);

    const url = "/rcs/templateFileUpload";
    const data = formData;
    if (window.confirm("템플릿을 등록하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          setUploadFile(null);
          document.getElementById("fileName").value = "";
        })
        .catch((error) => alert(error));
    }
  }

  /* function handleUpload() {

    async function getFetch(url) {
      return getRequest.fetch(url);
    }

    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }

    let brandId = document.getElementById("brandNm").value;
    let cardType = "";
    let cardKind = document.getElementById("cardKind").value;
    let templateName = document.getElementById("templateName").value;

    const body = [];
    const btn = [];

    if (document.getElementsByName("cardType")[0].checked) {
      cardType = "Description";
    } else {
      cardType = "Cell";
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (templateName === "") {
      alert("템플릿 명을 입력하세요");
      return;
    } else if (templateName.length > 30) {
      alert("템플릿명은 90자를 초과하지 못합니다.");
    }

    if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value === ""
    ) {
      alert("내용을 입력하세요.");
      return;
    } else if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value.length > 90
    ) {
      alert("내용은 90자를 초과하지 못합니다.");
    }

    if (cardKind === "인증" && document.getElementById("btnName1")) {
      alert(cardType + "의 인증 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Cell" &&
      cardKind === "회원가입" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 회원가입 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Description" &&
      cardKind === "예약" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 예약 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 왼쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementById("bodyText2_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 오른쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
            text2: document.getElementById("bodyText2_" + i).value,
          };
          body.push(obj);
        } else {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
          };
          body.push(obj);
        }

        if (document.getElementsByName("line_" + i)[0]) {
          if (document.getElementsByName("line_" + i)[0].checked === true) {
            const obj = {};
            body.push(obj);
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        if (document.getElementById("btnName" + i).value === "") {
          alert(i + 1 + "번째 버튼명을 입력하세요.");
          return;
        } else if (document.getElementById("btnName" + i).value.length > 17) {
          alert(i + 1 + "번째 버튼명은 17자를 초과하지 못합니다.");
        }

        let info1 = "";
        let info2 = "";
        let info3 = "";
        let info4 = "";

        if (document.getElementById("btnInfo" + 1 + "_" + i)) {
          if (document.getElementById("btnInfo" + 1 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info1 = document.getElementById("btnInfo" + 1 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 2 + "_" + i)) {
          if (document.getElementById("btnInfo" + 2 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info2 = document.getElementById("btnInfo" + 2 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 3 + "_" + i)) {
          if (document.getElementById("btnInfo" + 3 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info3 = document.getElementById("btnInfo" + 3 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 4 + "_" + i)) {
          if (document.getElementById("btnInfo" + 4 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info4 = document.getElementById("btnInfo" + 4 + "_" + i).value;
        }

        const obj = {
          btnType: document.getElementById("select" + i).value,
          btn: document.getElementById("btnName" + i).value,
          info1: info1,
          info2: info2,
          info3: info3,
          info4: info4,
        };
        btn.push(obj);
      }
    }

    const url = "/rcs/templateSave";
    const data = {
      brandId: brandId,
      cardType: cardType,
      cardKind: cardKind,
      templateName: templateName,
      templateBody: body,
      templateBtn: btn,
    };

    if (check === "N") {
      alert("동의 후 등록 가능합니다.");
      return;
    }

    if (window.confirm("템플릿을 승인 요청 하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            window.location.replace("/rcs/rcsTemplate");
          }
        })
        .catch((error) => alert(error));
    }
  }
 */
  const handlePreview = (event) => {
    const body = [];
    const btn = [];

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          body.push(
            "<div style='float:left'>" +
              document.getElementById("bodyText_" + i).value +
              "</div>"
          );
          body.push(
            "<div style='float:right'>" +
              document.getElementById("bodyText2_" + i).value +
              "</div><br></br>"
          );
        } else {
          body.push(
            document.getElementById("bodyText_" + i).value + "<br></br>"
          );
        }

        if (document.getElementsByName("line_" + i)[0]) {
          if (document.getElementsByName("line_" + i)[0].checked === true) {
            body.push("<hr style='width:100%; margin-top:-15px;'></hr>");
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        const obj = { btnName: document.getElementById("btnName" + i).value };
        const json = JSON.stringify(obj);
        btn.push(obj);
      }
    }
    setTemplateBody(body);
    setTemplateBtn(btn);

    event.preventDefault();
    handlePopup(true);
  };

  const handleSave = () => {
    // 템플릿 등록

    if (check === "N") {
      alert("동의 후 등록 가능합니다.");
      return;
    }

    let type = document.getElementById("1").checked;
    if (type) {
      // 직접입력
      handleTemplateSave();
    } else {
      // 엑셀대량입력
      handleExcelTemplateSave();
    }
  };

  const handleTemplateSave = (event) => {
    // RCS 템플릿 직접입력 저장
    //event.preventDefault();

    let brandId = "";
    let cardType = "";
    let cardKind = "";
    let templateNm = "";

    const body = [];
    const btn = [];

    if (document.getElementById("brandNm")) {
      brandId = document.getElementById("brandNm").value;
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (document.getElementById("cardKind")) {
      cardKind = document.getElementById("cardKind").value;
    }

    if (document.getElementsByName("cardType")[0].checked) {
      cardType = "1";
    } else {
      cardType = "2";
    }

    if (document.getElementById("templateName")) {
      templateNm = document.getElementById("templateName").value;
    }

    if (templateNm === "") {
      alert("템플릿 명을 입력하세요.");
      return;
    }

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 왼쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementById("bodyText2_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 오른쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementsByName("line_" + i)[0]) {
            if (document.getElementsByName("line_" + i)[0].checked === true) {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                text2: document.getElementById("bodyText2_" + i).value,
                line: true,
              };
              body.push(obj);
            } else {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                text2: document.getElementById("bodyText2_" + i).value,
                line: false,
              };
              body.push(obj);
            }
          } else {
            const obj = {
              text1: document.getElementById("bodyText_" + i).value,
              text2: document.getElementById("bodyText2_" + i).value,
              line: false,
            };
            body.push(obj);
          }
        } else {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementsByName("line_" + i)[0]) {
            if (document.getElementsByName("line_" + i)[0].checked === true) {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                line: true,
              };
              body.push(obj);
            } else {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                line: false,
              };
              body.push(obj);
            }
          } else {
            const obj = {
              text1: document.getElementById("bodyText_" + i).value,
              line: false,
            };
            body.push(obj);
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        if (document.getElementById("btnName" + i).value === "") {
          alert(i + 1 + "번째 버튼명을 입력하세요.");
          return;
        } else if (document.getElementById("btnName" + i).value.length > 17) {
          alert(i + 1 + "번째 버튼명은 17자를 초과하지 못합니다.");
        }

        let info1 = "";
        let info2 = "";
        let info3 = "";
        let info4 = "";

        if (document.getElementById("btnInfo" + 1 + "_" + i)) {
          if (document.getElementById("btnInfo" + 1 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info1 = document.getElementById("btnInfo" + 1 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 2 + "_" + i)) {
          if (document.getElementById("btnInfo" + 2 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info2 = document.getElementById("btnInfo" + 2 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 3 + "_" + i)) {
          if (document.getElementById("btnInfo" + 3 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info3 = document.getElementById("btnInfo" + 3 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 4 + "_" + i)) {
          if (document.getElementById("btnInfo" + 4 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info4 = document.getElementById("btnInfo" + 4 + "_" + i).value;
        }

        const obj = {
          btnType: document.getElementById("select" + i).value,
          btn: document.getElementById("btnName" + i).value,
          info1: info1,
          info2: info2,
          info3: info3,
          info4: info4,
        };
        btn.push(obj);
      }
    }

    const url = "/rcs/templateSave/temp";
    const data = {
      brandId: brandId,
      cardType: cardType,
      cardKind: cardKind,
      templateName: templateNm,
      templateBody: body,
      templateBtn: btn,
    };
    if (window.confirm("템플릿을 등록하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            window.location.replace("/rcs/rcsTemplate");
          }
        })
        .catch((error) => alert(error));
    }
  };

  const handleExcelTemplateSave = () => {
    // 엑셀로 RCS 템플릿 대량 등록

    let brandId = document.getElementById("brandNm1").value;

    if (!uploadFile) {
      alert("업로드 할 엑셀 파일을 선택해주세요.");
      return;
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (check === "N") {
      alert("동의 후 등록 가능합니다.");
      return;
    }

    var checkedRcsCardType = document.querySelector(
      "input[name='RCSCardType']:checked"
    );

    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("brand", brandId);
    formData.append("cardType", checkedRcsCardType.value);

    const url = "/rcs/templateFileUpload";
    const data = formData;
    if (window.confirm("템플릿을 등록 하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);

          setUploadFile(null);
          document.getElementById("fileName").value = "";

          if (result.resultCode === "0") {
            window.location.replace("/rcs/rcsTemplate");
          }
        })
        .catch((error) => alert(error));
    }
  };

  const handleGoList = (event) => {
    event.preventDefault();
    window.location.replace("/rcs/rcsTemplate");
  };

  const changeHandler = (checked, id) => {
    if (checked) {
      setCheck("Y");
    } else {
      setCheck("N");
    }
  };

  const [handleAgree, setHandleAgree] = useState(false);

  const style = {
    lfloat: {
      width: "75%",
      float: "left",
    },
    rfloat: {
      width: "25%",
      float: "right",
    },
    select: {
      width: "100%",
    },
    input100: {
      width: "100%",
    },
    check: {
      position: "relative",
      top: "4px",
      marginRight: "5px",
    },
    desc: {
      color: "#999",
      lineHeight: "1.5",
      marginBottom: "10px",
    },
    rcsBtnOption: {
      margin: "10px 0",
    },
  };
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>입력 방법</th>
            <td>
              <span className="Radiobox">
                {inputTypes.map((value) => (
                  <span key={value.id}>
                    <input
                      type="radio"
                      name="RCSTemplateUpload"
                      id={value.id}
                      checked={selectedInput === value.id}
                      onClick={function (e) {
                        setSelectedContent(value.content);
                        setSelectedInput(value.id);
                        setPreviewBtn(value.state);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </span>
                ))}
              </span>
            </td>
          </tr>
          {selectedContent}
        </tbody>
        <tfoot></tfoot>
      </table>
      <div>
        <input
          type="checkbox"
          id="rcsTempleteAgree"
          name="rcsTempleteAgree"
          className="check"
          onChange={(e) => {
            changeHandler(e.currentTarget.checked, "rcsTempleteAgree");
          }}
        />
        <label
          for="rcsTempleteAgree"
          className={handleAgree ? "checked" : null}
        >
          정보성 메시지만 보낼 수 있으며, 광고 등 정책에 위배되는 메시지 발송 시
          템플릿 사용이 중지될 수 있음을 동의합니다.
        </label>
      </div>
      <div className="button-wrap">
        <Button
          buttonName="button-border-gray"
          buttonValue="목록"
          onChangePage={handleGoList}
        />

        {previewBtn && (
          <Button
            buttonName="button-bgc-gray"
            buttonValue="미리보기"
            onChangePage={handlePreview}
          />
        )}

        <Button
          buttonName="button-border-blue"
          buttonValue="등록"
          onChangePage={handleSave}
        />
        {/* <Button
          buttonName="button-bgc-blue"
          buttonValue="승인 요청"
          onChangePage={handleTemplateSave}
        /> */}
      </div>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="템플릿 미리보기"
          size="small"
        >
          <LayerPopRCSTemplatePreview
            previewType={"R"}
            body={templateBody}
            btn={templateBtn}
          />
        </PopUpLayout>
      )}
    </>
  );
}

//RCS 템플릿 등록 직접입력
export function RcsTemplateUploadType1(props) {
  const jsonArr = props.data;
  const btnArr = jsonArr[0].btnData;
  const bodyArr = jsonArr[0].templateDescription;
  var btnJsonArr = JSON.parse(btnArr);
  var bodyJsonArr = JSON.parse(bodyArr);

  // const [defaultValue, setdefaultValue] = React.useState("type1");

  const [chekedId, setChekedId] = React.useState(jsonArr[0].templateCardType);
  const [activeContent, setActiveContent] = React.useState(
    jsonArr[0].templateCardType
  );

  const cardList = [
    {
      id: "RCSCardType1",
      value: "Description",
      content: <RCSTemplateUpload1 />,
    },
    { id: "RCSCardType2", value: "Cell", content: <RCSTemplateUpload2 /> },
  ];

  const [chekeButtondId, setChekedButtonId] = React.useState(
    btnJsonArr.length * 11
  );
  const [activeButtonType, setActiveButtonType] = React.useState(null);

  const buttonList = [
    { id: 0, value: "버튼 미사용", content: null },
    { id: 11, value: "1개", content: <RCSButtonType btnCnt={0} /> },
    {
      id: 22,
      value: "2개",
      content: (
        <>
          <RCSButtonType btnCnt={0} />
          <RCSButtonType btnCnt={1} />
        </>
      ),
    },
  ];
  const style = {
    lfloat: {
      width: "75%",
      float: "left",
    },
    rfloat: {
      width: "25%",
      float: "right",
    },
    select: {
      width: "100%",
    },
    input100: {
      width: "100%",
    },
    check: {
      position: "relative",
      top: "4px",
      marginRight: "5px",
    },
    desc: {
      color: "#999",
      lineHeight: "1.5",
      marginBottom: "10px",
    },
    rcsBtnOption: {
      margin: "10px 0",
    },
  };

  const rows = [];
  for (const row of jsonArr) {

    return (
      <>
        <tr>
          <th>발신브랜드</th>
          <td>
            <select
              className=""
              name="brandNm"
              id="brandNm"
              defaultValue={row.brandId}
              style={style.select}
            >
              <option defaultValue="">발신브랜드 선택</option>
              {props.brandIdList.map((row) => (
                <option value={row.brandId}>{row.brandId}</option>
              ))}
            </select>
          </td>
        </tr>
        {row.messagebaseId != undefined ? (
          <tr>
            <th>템플릿 ID</th>
            <td>
              <input
                type="text"
                name="templateId"
                id="templateId"
                style={style.input100}
                defaultValue={row.messagebaseId}
                readOnly
              />
            </td>
          </tr>
        ) : null}

        <tr>
          <th>카드종류</th>
          <td>
            <span className="Radiobox">
              {cardList.map((value) => (
                <>
                  <input
                    type="radio"
                    name="cardType"
                    id={value.id}
                    checked={chekedId === value.id}
                    onClick={function (e) {
                      setChekedId(value.id);
                      setActiveContent(value.id);
                    }}
                  />
                  <label htmlFor={value.id}>{value.value}</label>
                </>
              ))}
            </span>
          </td>
        </tr>
        <tr>
          <th>유형</th>
          <td>
            <select
              defaultValue={row.formService}
              className=""
              name="cardKind"
              title="템플릿 유형"
              id="cardKind"
              style={style.select}
            >
              <option defaultValue="승인">승인</option>
              <option defaultValue="입금">입금</option>
              <option defaultValue="출금">출금</option>
              <option defaultValue="출고">출고</option>
              <option defaultValue="주문">주문</option>
              <option defaultValue="배송">배송</option>
              <option defaultValue="예약">예약</option>
              <option defaultValue="회원가입">회원가입</option>
              <option defaultValue="인증">인증</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>템플릿 명</th>
          <td>
            <input
              type="text"
              name="templateName"
              id="templateName"
              defaultValue={row.templateName}
              style={style.input100}
              placeholder="템플릿 명 (최대30자)"
            />
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td>
            <p style={style.desc}>
              변수로 설정하고 하는 내용을 &#123;&#123;&#125;&#125;표시로
              작성해주세요. (변수명은 영문, 숫자 가능)
              <br />
              예) 이름과 출금일을 변수설정 : &#123;&#123;name&#125;&#125;,
              &#123;&#123;date&#125;&#125;
            </p>
            {(function () {
              if (activeContent === "RCSCardType1")
                return (function () {
                  if (jsonArr[0].templateCardType === "RCSCardType1")
                    return <RCSTemplateUpload1 body={bodyJsonArr[0]} />;
                  else return <RCSTemplateUpload1 body={['[{"text1":""}]']} />;
                })();
              else if (activeContent === "RCSCardType2")
                return (function () {
                  if (jsonArr[0].templateCardType === "RCSCardType2")
                    return <RCSTemplateUpload2 body={bodyJsonArr} />;
                  else return <RCSTemplateUpload2 body={['[{"text1":""}]']} />;
                })();
            })()}
          </td>
        </tr>
        <tr>
          <th>버튼 노출</th>
          <td>
            <span className="Radiobox">
              {buttonList.map((value) => (
                <>
                  <input
                    type="radio"
                    name="RCSButton"
                    id={value.id}
                    checked={chekeButtondId === value.id}
                    onClick={function (e) {
                      setChekedButtonId(value.id);
                      setActiveButtonType(value.content);
                    }}
                  />
                  <label htmlFor={value.id}>{value.value}</label>
                </>
              ))}
            </span>
          </td>
        </tr>
        {(function () {
          if (chekeButtondId === 11)
            return <RCSButtonType btnCnt={0} data={btnJsonArr[0]} />;
          else if (chekeButtondId === 22)
            return (
              <>
                <RCSButtonType btnCnt={0} data={btnJsonArr[0]} />
                <RCSButtonType btnCnt={1} data={btnJsonArr[1]} />
              </>
            );
        })()}
      </>
    );
  }
}
//RCS 템플릿 등록 엑셀대량
export function RcsTemplateUploadType2(props) {
  const [chekedId, setChekedId] = React.useState("RCSCardType1");
  const [popup, setPopup] = useState(false);

  const [fileName, setFileName] = useState("");
  const setUploadFile = props.setUploadFile;

  const cardList = [
    { id: "RCSCardType1", value: "Description" },
    { id: "RCSCardType2", value: "Cell" },
  ];

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handlePopup = (event) => {
    event.preventDefault();
    setPopup(true);
  };

  const setPopupClose = () => {
    setPopup(false);
  };

  const setFile = (uploadFile) => {
    setUploadFile(uploadFile);
  };

  const handleDesc = (event) => {
    event.preventDefault();
  };
  const handleCell = (event) => {
    event.preventDefault();
  };
  const downloadUploadSample = (event) => {
    var checkedRcsCardType = document.querySelector(
      "input[name='RCSCardType']:checked"
    ).value;
    let fileName = "";
    let sn = 0;
    if (checkedRcsCardType === "Description") {
      fileName = "RCS Description 템플릿엑셀 업로드_양식.xlsx";
      sn = 4;
    } else if (checkedRcsCardType === "Cell") {
      fileName = "RCS Cell 템플릿엑셀 업로드_양식.xlsx";
      sn = 5;
    }

    axios({
      method: "POST",
      url: SEVER_DOMAIN + "/file/download/excelExample",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
        "Content-Type": "application/json",
      },
      data: {
        fiSn: sn,
      },
    }).then((res) => {
      let blob = new Blob([res.data], { type: res.headers["content-type"] });

      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // not IE
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_self";
        if (fileName) link.download = fileName;
        link.click();
      }
    });
  };
  // 카드종류 변경
  const setChangeRadio = (id) => {
    setChekedId(id);
    setFileName("");
  };
  const style = {
    select: {
      width: "100%",
    },
  };

  return (
    <>
      <tr>
        <th>발신브랜드</th>
        <td>
          <select
            className=""
            name="brandNm1"
            id="brandNm1"
            style={style.select}
          >
            <option defaultValue="">발신브랜드 선택</option>
            {props.brandIdList.map((row) => (
              <option value={row.brandId} key={row.brandId}>
                {row.brandId}
              </option>
            ))}
          </select>
        </td>
      </tr>
      <tr>
        <th>카드종류</th>
        <td>
          <span className="Radiobox">
            {/* <input type="radio" name="RCSUploadExcelCardType" id="desc" defaultChecked onClick={handleDesc} />
            <label htmlFor="desc">Description</label>
            <input type="radio" name="RCSUploadExcelCardType" id="cell" onClick={handleCell} />
            <label htmlFor="cell">Cell</label> */}
            {cardList.map((value) => (
              <>
                <input
                  type="radio"
                  name="RCSCardType"
                  id={value.id}
                  value={value.value}
                  checked={chekedId === value.id}
                  /* onClick={function (e) {
                    //setChekedId(value.id);
                  }} */
                  onClick={() => setChangeRadio(value.id)}
                />
                <label htmlFor={value.id}>{value.value}</label>
              </>
            ))}
          </span>
        </td>
      </tr>
      <tr>
        <th>템플릿 업로드 등록</th>
        <td>
          <div className="buttongroup">
            <Button
              buttonName="buttonDown"
              buttonValue="샘플파일 다운로드"
              onChangePage={downloadUploadSample}
            />
          </div>
          <div className="write-left-inner receive-area">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">파일첨부</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-input-btn-group">
                  <input
                    type="text"
                    name="fileName"
                    id="fileName"
                    value={fileName}
                    placeholder=""
                  />
                  <Button
                    buttonName="button-bgc-gray"
                    buttonValue="파일등록"
                    onChangePage={handlePopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      {/* 여기 팝업을 밖으로  */}
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="파일 업로드">
          <LayerPopFileUpload
            setFile={setFile}
            setPopupClose={setPopupClose}
            setFileName={setFileName}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function RCSTemplateUpload1(props) {
  const [defaultValue, setdefaultValue] = React.useState("type1");
  const [templateBody, setTemplateBody] = useState(props.body.text1);
  const [btnNum, setBtnNum] = useState(1);
  const [textLen, setTextLen] = useState(0);
  const style = {
    select: {
      width: "100%",
    },
    input100: {
      width: "100%",
    },
    textarea: {
      padding: "20px",
      margin: "10px 0",
    },
  };

  const handleBody = (body) => {
    setTemplateBody(body);

    const len = body.length;
    setTextLen(len);
    if (len > 90) alert("내용 90자를 초과하지 못합니다.");
  };

  return (
    <>
      <textarea
        name="bodyText_0"
        id="bodyText_0"
        placeholder="내용입력 (최대 90자)"
        style={style.textarea}
        onChange={(e) => handleBody(e.target.value)}
        value={templateBody}
      ></textarea>
      <div className="rflaot">
        <p style={style.desc}>({textLen}/90자)</p>
      </div>
    </>
  );
}

export function RCSTemplateUpload2(props) {
  let firstTextCnt = "";

  if (props.body[0].text2 === undefined) {
    firstTextCnt = "1칸";
  } else {
    firstTextCnt = "2칸";
  }

  const [contentPhone, setContentPhone] = useState([]);
  const [btnCount, setBtnCount] = useState(0);
  const [btnNum, setBtnNum] = useState(1);

  const style = {
    select: {
      width: "100%",
    },
    smallSelect: {
      width: "100px",
    },
    input100: {
      width: "100%",
    },
    input50: {
      width: "calc(50% - 5px)",
    },
    input50_margin10px: {
      width: "calc(50% - 5px)",
      marginLeft: "10px",
    },
    desc: {
      color: "#999",
      lineHeight: "1.5",
    },
    check: {
      position: "relative",
      top: "4px",
      marginRight: "5px",
    },
    td1: {
      padding: "0 10px",
    },
  };

  function handelChangeBodyType1(num, val) {
    const linkNm1 = "bodyText_" + num;
    const linkNm2 = "bodyText2_" + num;

    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;
    if (val === "1칸") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "100%" },
          type: "text",
          placeholder: "최대 90자",
          id: linkNm1,
        },
        null
      );

      ReactDOM.render(rElmLi1, document.getElementsByName(linkText)[0]);
    } else if (val === "2칸") {
      var rElmLi1 = React.createElement(
          "input",
          {
            style: { width: "calc(50% - 5px)" },
            type: "text",
            placeholder: "최대 90자",
            id: linkNm1,
          },
          null
        ),
        rElmLi2 = React.createElement(
          "input",
          {
            style: { width: "calc(50% - 5px)", marginLeft: "10px" },
            type: "text",
            placeholder: "최대 90자",
            id: linkNm2,
          },
          null
        );

      var reactElementUl = React.createElement(
        "div",
        { id: linkText2, name: linkText2 },
        rElmLi1,
        rElmLi2
      );
      ReactDOM.render(reactElementUl, document.getElementsByName(linkText)[0]);
    }
  }

  const handleDelete = (num) => {
    

    const btnRow = "row_" + num;
    document.getElementById(btnRow).remove();
    setBtnCount();
  };

  const AddBtnType = (event) => {
    event.preventDefault();

    const cnt = document.getElementsByName("bodyTypeGrp").length;
    if (cnt > 8) {
      alert("텍스트는 최대 10개까지 가능합니다.");
      return;
    }

    const num = document.getElementsByName("bodyTypeGrp").length + 1;
    const bodyRow = "row_" + num;
    const bodyTypeNm = "bodyType_" + num;
    const textNm = "bodyText_" + num;
    const lineNm = "line_" + num;
    const addLine = "addLine_" + num;
    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;
    const bodyCnt = "bodyCnt_" + num;

    const count = btnNum + 1;
    setBtnNum(count);

    setContentPhone(
      contentPhone.concat(
        <tr name="bodyTypeGrp" id={bodyRow}>
          <input type="hidden" name="hiddenVal" value={num} />
          <td name={bodyCnt} id={bodyCnt} style={style.td1}>
            <select
              name={bodyTypeNm}
              id={bodyTypeNm}
              onChange={(e) => handelChangeBodyType1(num, e.target.value)}
              style={style.smallSelect}
            >
              <option defaultValue="">1칸</option>
              <option defaultValue="">2칸</option>
            </select>
          </td>
          <td id={linkText} name={linkText}>
            <input
              type="text"
              name={textNm}
              id={textNm}
              style={style.input100}
              defaultValue=""
              placeholder="최대 90자"
            />
          </td>
          <td style={style.td1}>
            <div>
              <input
                type="checkbox"
                id={addLine}
                name={lineNm}
                className="check"
              />
              <label
                for={addLine}
                className={handleLinechecked ? "checked" : null}
              >
                라인
              </label>
              <a
                onClick={(e) => {
                  handleDelete(num);
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="gray999 ml10"
                />
              </a>
            </div>
          </td>
        </tr>
      )
    );
  };
  const [checked1, setChecked1] = React.useState(true);
  const [checked, setChecked] = React.useState(true);
  function defaultText() {
    let array = [];
    for (let i = 1; i < props.body.length; i++) {
      let textCnt = "";

      if (props.body[i].text2 === undefined) {
        textCnt = "1칸";
      } else {
        textCnt = "2칸";
      }

      array.push(
        <tr name="bodyTypeGrp" id={"row_" + i}>
          <td style={style.td1}>
            <select
              name={"bodyType_" + i}
              id={"bodyType_" + i}
              defaultValue={textCnt}
              onChange={(e) => handelChangeBodyType1(i, e.target.value)}
              style={style.smallSelect}
            >
              <option defaultValue="1칸">1칸</option>
              <option defaultValue="2칸">2칸</option>
            </select>
          </td>
          <td id={"linkText_" + i} name={"linkText_" + i}>
            {(function () {
              if (props.body[i].text2 === undefined)
                return (
                  <input
                    type="text"
                    name={"bodyText_" + i}
                    id={"bodyText_" + i}
                    style={style.input100}
                    defaultValue={props.body[i].text1}
                    placeholder="최대 90자"
                  />
                );
              else
                return (
                  <div>
                    <input
                      type="text"
                      name={"bodyText_" + i}
                      id={"bodyText_" + i}
                      style={style.input50}
                      defaultValue={props.body[i].text1}
                      placeholder="최대 90자"
                    />
                    <input
                      type="text"
                      name={"bodyText2_" + i}
                      id={"bodyText2_" + i}
                      style={style.input50_margin10px}
                      defaultValue={props.body[i].text2}
                      placeholder="최대 90자"
                    />
                  </div>
                );
            })()}
          </td>
          <td style={style.td1}>
            <div>
              {(function () {
                if (props.body[i].line === "true")
                  return (
                    <input
                      type="checkbox"
                      id={"addLine_" + i}
                      name={"line_" + i}
                      className="check"
                      defaultChecked={checked1}
                      onChange={() => setChecked1(!checked1)}
                    />
                  );
                else
                  return (
                    <input
                      type="checkbox"
                      id={"addLine_" + i}
                      name={"line_" + i}
                      className="check"
                    />
                  );
              })()}
              <label
                for={"addLine_" + i}
                className={handleLinechecked ? "checked" : null}
              >
                라인
              </label>
              <a
                onClick={(e) => {
                  handleDelete(i);
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="gray999 ml10"
                />
              </a>
            </div>
          </td>
        </tr>
      );
    }
    return array;
  }

  const [handleLinechecked, setHandleLineChecked] = useState(false);

  return (
    <>
      <table className="table_style4">
        <colgroup>
          <col width="5%" />
          <col width="60%" />
          <col width="15%" />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <td style={style.td1}>
              <select
                name="bodyType_0"
                id="bodyType_0"
                defaultValue={firstTextCnt}
                onChange={(e) => handelChangeBodyType1(0, e.target.value)}
                style={style.smallSelect}
              >
                <option defaultValue="1칸">1칸</option>
                <option defaultValue="2칸">2칸</option>
              </select>
            </td>
            <td id="linkText_0" name="linkText_0">
              {(function () {
                if (props.body[0].text2 === undefined)
                  return (
                    <input
                      type="text"
                      name={"bodyText_" + 0}
                      id={"bodyText_" + 0}
                      style={style.input100}
                      defaultValue={props.body[0].text1}
                      placeholder="최대 90자"
                    />
                  );
                else
                  return (
                    <div>
                      <input
                        type="text"
                        name={"bodyText_" + 0}
                        id={"bodyText_" + 0}
                        style={style.input50}
                        defaultValue={props.body[0].text1}
                        placeholder="최대 90자"
                      />
                      <input
                        type="text"
                        name={"bodyText2_" + 0}
                        id={"bodyText2_" + 0}
                        style={style.input50_margin10px}
                        defaultValue={props.body[0].text2}
                        placeholder="최대 90자"
                      />
                    </div>
                  );
              })()}
            </td>
            <td style={style.td1}>
              <div>
                {(function () {
                  if (props.body[0].line === "true")
                    return (
                      <input
                        type="checkbox"
                        id="addLine_0"
                        name="line_0"
                        className="check"
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    );
                  else
                    return (
                      <input
                        type="checkbox"
                        id="addLine_0"
                        name="line_0"
                        className="check"
                      />
                    );
                })()}
                <label
                  for="addLine_0"
                  className={handleLinechecked ? "checked" : null}
                >
                  라인
                </label>
              </div>
            </td>
          </tr>
          {defaultText()}
          {contentPhone}
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="center mt10 mb10">
        <button className="button_add" onClick={AddBtnType}>
          + 추가
        </button>
      </div>
    </>
  );
}

export function RCSButtonType(props) {
  const style = {
    input100: {
      width: "100%",
    },
    input100_marginTop10: {
      width: "100%",
      marginTop: "10px",
    },
    rcsBtnSelect: {
      width: 180,
      marginRight: "10px",
    },
    BtnName: {
      width: "calc(100% - 190px)",
    },
  };

  let btnName = "btnName" + props.btnCnt;

  let btnInfo1 = "btnInfo1_" + props.btnCnt;
  let btnInfo2 = "btnInfo2_" + props.btnCnt;
  let btnInfo3 = "btnInfo3_" + props.btnCnt;
  let btnInfo4 = "btnInfo4_" + props.btnCnt;

  let btnList = "btnList" + props.btnCnt;
  let setBtnList = "setBtnList" + props.btnCnt;
  let selectList = "select" + props.btnCnt;

  function handelChangeBtnType(val) {
    var select = React.createElement(
      "select",
      {
        style: { width: 180, marginRight: "10px" },
        defaultValue: val,
        id: selectList,
        onChange: (e) => handelChangeBtnType(e.target.value),
      },
      React.createElement("option", { value: "URL연결" }, "URL연결"),
      React.createElement("option", { value: "전화걸기" }, "전화걸기"),
      React.createElement("option", { value: "복사하기" }, "복사하기"),
      React.createElement(
        "option",
        { value: "지도보여주기(좌표)" },
        "지도보여주기(좌표)"
      ),
      React.createElement(
        "option",
        { value: "지도보여주기(쿼리)" },
        "지도보여주기(쿼리)"
      ),
      React.createElement("option", { value: "현재위치공유" }, "현재위치공유"),
      React.createElement("option", { value: "캘린더등록" }, "캘린더등록"),
      React.createElement("option", { value: "메시지전송" }, "메시지전송")
    );

    if (val === "전화걸기") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "휴대폰 번호",
          id: btnInfo1,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "복사하기") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "복사할 메시지",
          id: btnInfo1,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "메시지전송") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "전송할 메시지",
          id: btnInfo1,
        },
        null
      );
      var rElmLi3 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "휴대폰 번호",
          id: btnInfo2,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2,
        rElmLi3
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "지도보여주기(좌표)") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "위도",
          id: btnInfo1,
        },
        null
      );
      var rElmLi3 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "경도",
          id: btnInfo2,
        },
        null
      );
      var rElmLi4 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "위치 이름",
          id: btnInfo3,
        },
        null
      );
      var rElmLi5 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder:
            "실패시 호출 링크 https://xxxxxxxxx.xxx 형식으로 입력해 주세요.",
          id: btnInfo4,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2,
        rElmLi3,
        rElmLi4,
        rElmLi5
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "지도보여주기(쿼리)") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "검색 키워드",
          id: btnInfo1,
        },
        null
      );
      var rElmLi3 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder:
            "위치를 조회할 사이트 https://xxxxxxxxx.xxx 형식으로 입력해 주세요.",
          id: btnInfo2,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2,
        rElmLi3
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "현재위치공유") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1
      );
    } else if (val === "캘린더등록") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "시작일",
          id: btnInfo1,
        },
        null
      );
      var rElmLi3 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "종료일",
          id: btnInfo2,
        },
        null
      );
      var rElmLi4 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "제목",
          id: btnInfo3,
        },
        null
      );
      var rElmLi5 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder: "내용",
          id: btnInfo4,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2,
        rElmLi3,
        rElmLi4,
        rElmLi5
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    } else if (val === "URL연결") {
      var rElmLi1 = React.createElement(
        "input",
        {
          style: { width: "calc(100% - 190px)" },
          type: "text",
          placeholder: "버튼명(최대 17자)",
          type: "text",
          placeholder: "버튼명(최대 17자)",
          id: btnName,
        },
        null
      );
      var rElmLi2 = React.createElement(
        "input",
        {
          style: {
            width: "calc(100% - 10px)",
            marginLeft: "10px",
            marginTop: "10px",
          },
          type: "text",
          placeholder:
            "예) URL 버튼 : http://www.bizrcs.com 형식으로 입력해 주세요.(최대 영문 100자, 한글 50자)",
          id: btnInfo1,
        },
        null
      );

      var div1 = React.createElement(
        "div",
        { id: "div1", name: "div1" },
        select,
        rElmLi1
      );
      var div2 = React.createElement(
        "div",
        { style: { marginLeft: "-10px" }, id: "div2", name: "div2" },
        rElmLi2
      );
      var reactElementUl = React.createElement(
        "div",
        { id: setBtnList, name: setBtnList },
        div1,
        div2
      );
    }
    ReactDOM.render(reactElementUl, document.getElementsByName(btnList)[0]);
  }

  if (props.data === undefined) {
    return (
      <tr>
        <th>버튼</th>
        <td>
          <div name={btnList} id={btnList}>
            <div name={setBtnList} id={setBtnList}>
              <div id="div1" name="div1">
                <select
                  name="Selectbox"
                  id={selectList}
                  style={style.rcsBtnSelect}
                  onChange={(e) => handelChangeBtnType(e.target.value)}
                >
                  <option defaultValue="URL연결">URL연결</option>
                  <option defaultValue="전화걸기">전화걸기</option>
                  <option defaultValue="복사하기">복사하기</option>
                  <option defaultValue="지도보여주기(좌표)">
                    지도보여주기(좌표)
                  </option>
                  <option defaultValue="지도보여주기(쿼리)">
                    지도보여주기(쿼리)
                  </option>
                  <option defaultValue="현재위치공유">현재위치공유</option>
                  <option defaultValue="캘린더등록">캘린더등록</option>
                  <option defaultValue="메시지전송">메시지전송</option>
                </select>
                <input
                  type="text"
                  id={btnName}
                  name={btnName}
                  style={style.BtnName}
                  placeholder="버튼명(최대 17자)"
                />
              </div>
              <div id="div2" name="div2" className="mt10">
                <input
                  type="text"
                  name=""
                  defaultValue=""
                  style={style.input100}
                  placeholder="예) URL 버튼 : http://www.bizrcs.com 형식으로 입력해 주세요.(최대 영문 100자, 한글 50자)"
                  id={btnInfo1}
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <th>버튼</th>
        <td>
          <div name={btnList} id={btnList}>
            <div name={setBtnList} id={setBtnList}>
              <div id="div1" name="div1">
                <select
                  name="Selectbox"
                  id={selectList}
                  style={style.rcsBtnSelect}
                  defaultValue={props.data.btnType}
                  onChange={(e) => handelChangeBtnType(e.target.value)}
                >
                  <option defaultValue="URL연결">URL연결</option>
                  <option defaultValue="전화걸기">전화걸기</option>
                  <option defaultValue="복사하기">복사하기</option>
                  <option defaultValue="지도보여주기(좌표)">
                    지도보여주기(좌표)
                  </option>
                  <option defaultValue="지도보여주기(쿼리)">
                    지도보여주기(쿼리)
                  </option>
                  <option defaultValue="현재위치공유">현재위치공유</option>
                  <option defaultValue="캘린더등록">캘린더등록</option>
                  <option defaultValue="메시지전송">메시지전송</option>
                </select>
                <input
                  type="text"
                  id={btnName}
                  name={btnName}
                  style={style.BtnName}
                  defaultValue={props.data.btn}
                  placeholder="버튼명(최대 17자)"
                />
              </div>
              <div id="div2" name="div2" className="mt10">
                {(function () {
                  if (props.data.btnType === "URL연결")
                    return (
                      <input
                        type="text"
                        name=""
                        defaultValue={props.data.info1}
                        style={style.input100}
                        placeholder="예) URL 버튼 : http://www.bizrcs.com 형식으로 입력해 주세요.(최대 영문 100자, 한글 50자)"
                        id={btnInfo1}
                      />
                    );
                  else if (props.data.btnType === "전화걸기")
                    return (
                      <input
                        type="text"
                        name=""
                        defaultValue={props.data.info1}
                        style={style.input100}
                        placeholder="휴대폰 번호"
                        id={btnInfo1}
                      />
                    );
                  else if (props.data.btnType === "복사하기")
                    return (
                      <input
                        type="text"
                        name=""
                        defaultValue={props.data.info1}
                        style={style.input100}
                        placeholder="복사할 메시지"
                        id={btnInfo1}
                      />
                    );
                  else if (props.data.btnType === "지도보여주기(좌표)")
                    return (
                      <div>
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info1}
                          style={style.input100}
                          placeholder="위도"
                          id={btnInfo1}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info2}
                          style={style.input100_marginTop10}
                          placeholder="경도"
                          id={btnInfo2}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info3}
                          style={style.input100_marginTop10}
                          placeholder="위치 이름"
                          id={btnInfo3}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info4}
                          style={style.input100_marginTop10}
                          placeholder="실패시 호출 링크 https://xxxxxxxxx.xxx 형식으로 입력해 주세요."
                          id={btnInfo4}
                        />
                      </div>
                    );
                  else if (props.data.btnType === "지도보여주기(쿼리)")
                    return (
                      <div>
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info1}
                          style={style.input100}
                          placeholder="검색 키워드"
                          id={btnInfo1}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info2}
                          style={style.input100_marginTop10}
                          placeholder="위치를 조회할 사이트 https://xxxxxxxxx.xxx 형식으로 입력해 주세요."
                          id={btnInfo2}
                        />
                      </div>
                    );
                  else if (props.data.btnType === "현재위치공유")
                    return <div></div>;
                  else if (props.data.btnType === "캘린더등록")
                    return (
                      <div>
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info1}
                          style={style.input100}
                          placeholder="시작일"
                          id={btnInfo1}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info2}
                          style={style.input100_marginTop10}
                          placeholder="종료일"
                          id={btnInfo2}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info3}
                          style={style.input100_marginTop10}
                          placeholder="제목"
                          id={btnInfo3}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info4}
                          style={style.input100_marginTop10}
                          placeholder="내용"
                          id={btnInfo4}
                        />
                      </div>
                    );
                  else if (props.data.btnType === "메시지전송")
                    return (
                      <div>
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info1}
                          style={style.input100}
                          placeholder="전송할 메시지"
                          id={btnInfo1}
                        />
                        <input
                          type="text"
                          name=""
                          defaultValue={props.data.info2}
                          style={style.input100_marginTop10}
                          placeholder="휴대폰 번호"
                          id={btnInfo2}
                        />
                      </div>
                    );
                })()}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export function SendRcsProfileStatisticTable(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (jsonArr.length === 2 && custType === "1") {
      rows.push(
        <tr>
          <td colSpan="10">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else if (jsonArr.length === 2 && custType === "2") {
      rows.push(
        <tr>
          <td colSpan="12">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        if (row.rowType === "normal") {
          rows.push(
            <tr>
              {custType === "1" ? <td>{row.custId}</td> : null}
              <td>{row.brandName}</td>
              <td>{row.reqDd}</td>
              <td>{setComma(row.rcsSmsTot)}</td>
              <td>{setComma(row.rcsSmsSuc)}</td>
              <td>{setComma(row.rcsTemplateTot)}</td>
              <td>{setComma(row.rcsTemplateSuc)}</td>
              <td>{setComma(row.rcsLmsTot)}</td>
              <td>{setComma(row.rcsLmsSuc)}</td>
              <td>{setComma(row.rcsMmsTot)}</td>
              <td>{setComma(row.rcsMmsSuc)}</td>
              <td>{setComma(row.rowTot)}</td>
              <td>{setComma(row.rowSuc)}</td>
            </tr>
          );
        } else if (row.rowType === "subTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="3" className="blue">
                  소계
                </th>
              ) : (
                <th colSpan="2" className="blue">
                  소계
                </th>
              )}
              <th className="blue">{setComma(row.rcsSmsTot)}</th>
              <th className="blue">{setComma(row.rcsSmsSuc)}</th>
              <th className="blue">{setComma(row.rcsTemplateTot)}</th>
              <th className="blue">{setComma(row.rcsTemplateSuc)}</th>
              <th className="blue">{setComma(row.rcsLmsTot)}</th>
              <th className="blue">{setComma(row.rcsLmsSuc)}</th>
              <th className="blue">{setComma(row.rcsMmsTot)}</th>
              <th className="blue">{setComma(row.rcsMmsSuc)}</th>
              <th className="blue">{setComma(row.rowTot)}</th>
              <th className="blue">{setComma(row.rowSuc)}</th>
            </tr>
          );
        } else if (row.rowType === "mainTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="3" className="blue">
                  <b>총계</b>
                </th>
              ) : (
                <th colSpan="2" className="blue">
                  <b>총계</b>
                </th>
              )}
              <th className="blue">
                <b>{setComma(row.rcsSmsTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsSmsSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsTemplateTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsTemplateSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsLmsTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsLmsSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsMmsTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsMmsSuc)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rowTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rowSuc)}</b>
              </th>
            </tr>
          );
        }
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>
      <tbody>
        <tr>
          {custType === "1" ? <th rowSpan="2">계정 ID</th> : null}
          <th rowSpan="2">RSC 채널</th>
          <th rowSpan="2">발송일</th>
          <th colSpan="2">RCS-SMS</th>
          <th colSpan="2">RCS-SMS 템플릿</th>
          <th colSpan="2">RCS-LMS</th>
          <th colSpan="2">RCS-MMS</th>
          <th colSpan="2">총계</th>
        </tr>
        <tr>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
          <th>발송</th>
          <th>성공</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
export function SendRcsTemplateStatisticTable(props) {
  const [tableRows, setTableRows] = useState("");
  const custType = props.custType;

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (jsonArr.length === 2) {
      rows.push(
        <tr>
          <td colSpan="10">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        if (row.rowType === "normal") {
          rows.push(
            <tr>
              {custType === "1" ? <td>{row.custId}</td> : null}
              <td>{setComma(row.brandName)}</td>
              <td>{setComma(row.tpCode)}</td>
              <td>{row.reqDd}</td>
              <td>{setComma(row.rcsTemplateTot)}</td>
              <td>{setComma(row.rcsTemplateSuc)}</td>
            </tr>
          );
        } else if (row.rowType === "subTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="4" className="blue">
                  소계
                </th>
              ) : (
                <th colSpan="3" className="blue">
                  소계
                </th>
              )}
              <th className="blue">{setComma(row.rcsTemplateTot)}</th>
              <th className="blue">{setComma(row.rcsTemplateSuc)}</th>
            </tr>
          );
        } else if (row.rowType === "mainTot") {
          rows.push(
            <tr>
              {custType === "1" ? (
                <th colSpan="4" className="blue">
                  <b>총계</b>
                </th>
              ) : (
                <th colSpan="3" className="blue">
                  <b>총계</b>
                </th>
              )}
              <th className="blue">
                <b>{setComma(row.rcsTemplateTot)}</b>
              </th>
              <th className="blue">
                <b>{setComma(row.rcsTemplateSuc)}</b>
              </th>
            </tr>
          );
        }
      }
    }
    setTableRows(rows);
  }, [props.data]);

  return (
    <table className="table_style2">
      <thead></thead>

      <tbody>
        <tr>
          {custType === "1" ? <th rowSpan="2">계정 ID</th> : null}
          <th rowSpan="2">브랜드명(브랜드 ID)</th>
          <th rowSpan="2">RCS템플릿코드(템플릿명)</th>
          <th rowSpan="2">발송일</th>
          <th colSpan="2">SMS 템플릿</th>
        </tr>
        <tr>
          <th>발송</th>
          <th>성공</th>
        </tr>
        {tableRows}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

export function RequestForPayLaterTable(props) {
  const [codeRows, setCodeRows] = useState([]);
  const [search2, setSearch2] = useState("");
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const setArsdKind = () => {
    if (codeRows.length > 0) {
      props.setAccountData({
        ...props.accountData,
        arSdKind: codeRows[0].cdId,
      });
    }
  };
  const handleCommonCode = () => {
    const url = "/getCommonCode";
    const snList = [1017];
    const data = { snList: snList };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  useEffect(() => {
    handleCommonCode();
  }, []);
  useEffect(() => {
    setArsdKind();
  }, [codeRows]);

  return (
    <table className="table_style1">
      <thead></thead>
      <tbody>
        <tr>
          <th>담당자명</th>
          <td>
            <input
              type="text"
              name="arNm"
              defaultValue=""
              maxLength="20"
              onChange={(e) => props.handleContentChange(e)}
            />
          </td>
        </tr>
        <tr>
          <th>연락처</th>
          <td>
            <input
              type="text"
              name="arPhone"
              defaultValue=""
              maxLength="11"
              onChange={(e) => props.handleContentChange(e)}
            />
          </td>
        </tr>
        <tr>
          <th>이메일</th>
          <td>
            <input
              type="text"
              name="arEmail"
              defaultValue=""
              maxLength="30"
              onChange={(e) => props.handleContentChange(e)}
            />
          </td>
        </tr>
        <tr>
          <th>요청 대상 서비스</th>
          <td>
            <select
              name="arSdKind"
              id="arSdKind"
              onChange={(e) => props.handleContentChange(e)}
            >
              {codeRows.map((row) =>
                row.cgSn === 1017 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
          </td>
        </tr>
        <tr>
          <th>연락 가능 시간대</th>
          <td>
            <input
              type="text"
              name="arRemark"
              defaultValue=""
              maxLength="80"
              onChange={(e) => props.handleContentChange(e)}
            />
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

//일반관리 - 발신번호 관리 - 발신번호등록
export function AddSendNumber(props) {
  const [selectedInput, setSelectedInput] = useState(3);
  const [selectedContent, setSelectedContent] = useState(
    <AddSendNumberType1 />
  );
  const inputTypes = [
    {
      id: 1,
      value: "휴대폰번호",
      state: true,
      content: <AddSendNumberType1 />,
    },
    { id: 2, value: "일반번호", state: false, content: <AddSendNumberType2 /> },
    { id: 3, value: "대표번호", state: false, content: <AddSendNumberType3 /> },
  ];
  const popupClose = props.popupClose;
  const handlePageChoice = props.handlePageChoice;
  const [file, setFile] = useState(null);  // 통신가입증명원
  const [file2, setFile2] = useState(null); // 계약서
  const [file3, setFile3] = useState(null); // 재직증명서
  const [callback, setCallback] = useState("");
  const [authCheck, setAuthCheck] = useState("N");
  const authType = props.authType;
  var cbKind = "";
  const Register = () => {
    //event.preventDefault();
    

    let regexMobile;

    if (selectedInput === 2) {
      regexMobile = /^\d{2,3}\d{3,4}\d{4}$/;
    } else if (selectedInput === 3) {
      regexMobile = /^\d{7,11}$/;
    }

    if (selectedInput === 2 || selectedInput === 3) {
      if (!callback) {
        alert("전화번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(callback)) {
        alert("잘못된 형식의 전화번호 입니다.");
        return;
      }
    }

    if (selectedInput === 1) {
      if (authCheck === "N") {
        alert("휴대폰 본인인증이 완료되지 않았습니다.");
        return;
      }

      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }

      if (!file3) {
        alert("재직증명서 파일을 첨부하여 주세요.");
        return;
      }
      cbKind = "1";
    } else if (selectedInput === 2) {
      /* if (authCheck === "N") {
        alert("ARS 인증이 완료되지 않았습니다.");
        return;
      } */

      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }
      cbKind = "2";
    } else if (selectedInput === 3) {
      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }
      cbKind = "3";
    }

    if (
      window.confirm(
        "[ 발신번호 : " + callback + " ] 을 등록요청 하시겠습니까?"
      )
    ) {
      let url;
      if (selectedInput === 1 || selectedInput === 3) {
        url = "/callback/saveFile";
      } else if (selectedInput === 2) {
        url = "/callback/saveArsFile";
      }
      let data;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file2", file2); // 계약서
      formData.append("file3", file3); // 재직증명서
      formData.append("type", selectedInput);
      formData.append("callback", callback);
      formData.append("cbKind", cbKind);
      data = formData;

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handlePageChoice(1);
            popupClose();
          }
        })
        .catch((error) => alert(error));
    }
  };

  const style = {
    submit: {
      width: "100%",
      padding: "20px 0",
      backgroundColor: "#625af6",
      color: "#fff",
      marginBottom: "10px",
      border: "none",
      cursor: "pointer",
    },
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>번호 유형</th>
            <td>
              <span className="Radiobox">
                {inputTypes.map((value) => (
                  <span>
                    <input
                      type="radio"
                      name="AddNewNumberType"
                      id={value.id}
                      checked={selectedInput === value.id}
                      onClick={function (e) {
                        setSelectedContent(value.content);
                        setSelectedInput(value.id);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </span>
                ))}
              </span>
            </td>
          </tr>
          {selectedInput === 1 ? (
            <AddSendNumberType1
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setFile3={setFile3}
              setCallback={setCallback}
              setAuthCheck={setAuthCheck}
            />
          ) : selectedInput === 2 ? (
            <AddSendNumberType2
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setCallback={setCallback}
              setAuthCheck={setAuthCheck}
            />
          ) : (
            <AddSendNumberType3
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setCallback={setCallback}
            />
          )}
        </tbody>
        <tfoot></tfoot>
      </table>
      <span style={{fontWeight: "bold", fontSize: "16px", color: "#625af6"}}>발신번호 서류 제출 안내</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>통신가입증명원 서류는 번호 유형 구분 없이 필수 서류이며,</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>타사 발신번호 등록 시 당사와 타사의 관계를 증명할 수 있는 서류를 추가로 제출하여야 합니다. (계약서 등)</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>휴대폰번호일 경우 명의자의 재직증명서를 추가로 제출하여야 합니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>지원 파일 형식은 pdf, jpg, jpeg, png 입니다.</span>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>필수</th>
            <td style={{color: "#625af6"}}>통신가입증명원</td>
          </tr>
          <tr>
            <th>타사</th>
            <td style={{color: "#625af6"}}>당사와 타사의 관계를 증명할 수 있는 서류 추가 제출 (계약서 등)</td>
          </tr>
          <tr>
            <th>휴대폰번호</th>
            <td style={{color: "#625af6"}}>명의자의 재직증명서 추가 제출</td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
      <span style={{fontSize: "12px"}}>① 당사 휴대폰번호 : 통신가입증명원, 재직증명서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>② 당사 일반번호 : 통신가입증명원</span>
      <br></br>
      <span style={{fontSize: "12px"}}>③ 당사 대표번호 : 통신가입증명원</span>
      <br></br>
      <span style={{fontSize: "12px"}}>④ 타사 휴대폰번호 : 통신가입증명원, 재직증명서, 계약서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>⑤ 타사 일반번호 : 통신가입증명원, 계약서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>⑥ 타사 대표번호 : 통신가입증명원, 계약서</span>
      <div className="button-wrap right">
        <Button
          buttonName="button-bgc-blue sbtn"
          buttonValue="등록"
          onChangePage={(e) => {
            Register();
          }}
        />
      </div>
    </>
  );
}

//일반관리 - 발신번호 관리 - 재인증
export function TryAuthSendNumber(props) {
  const number = props.number;
  const authType = props.authType;
  var cbKind = props.cbKind;
  const [selectedInput, setSelectedInput] = useState(props.cbKind);

  const [selectedContent, setSelectedContent] = useState(
    <AddSendNumberType1 />
  );
  const inputTypes = [
    {
      id: "1",
      value: "휴대폰번호",
      state: true,
      content: <AddSendNumberType1 />,
    },
    {
      id: "2",
      value: "일반번호",
      state: false,
      content: <AddSendNumberType2 />,
    },
    {
      id: "3",
      value: "대표번호",
      state: false,
      content: <AddSendNumberType3 />,
    },
  ];
  const popupClose = props.popupClose;
  const handlePageChoice = props.handlePageChoice;
  const [file, setFile] = useState(null);  // 통신가입증명원
  const [file2, setFile2] = useState(null); // 계약서
  const [file3, setFile3] = useState(null); // 재직증명서
  const [callback, setCallback] = useState("");
  const [authCheck, setAuthCheck] = useState("N");

  const Register = () => {
    //event.preventDefault();

    let regexMobile;

    if (selectedInput === "2") {
      regexMobile = /^\d{2,3}\d{3,4}\d{4}$/;
    } else if (selectedInput === "3") {
      regexMobile = /^\d{7,11}$/;
    }

    if (selectedInput === "2" || selectedInput === "3") {
      if (!callback) {
        alert("전화번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(callback)) {
        alert("잘못된 형식의 전화번호 입니다.");
        return;
      }
    }

    if (selectedInput === "1") {
      if (authCheck === "N") {
        alert("휴대폰 본인인증이 완료되지 않았습니다.");
        return;
      }

      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }
      
      cbKind = "1";
    } /* else if (selectedInput === "2") {
      if (authCheck === "N") {
        alert("ARS 인증이 완료되지 않았습니다.");
        return;
      }

      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }
      cbKind = "2";
    } */ else if (selectedInput === "3") {
      if (!file) {
        alert("통신가입증명원 파일을 첨부하여 주세요.");
        return;
      }
      cbKind = "3";
    }

    if (authType === "T") {
      if (
        window.confirm(
          "[ 발신번호 : " + callback + " ] 을 재인증 하시겠습니까?"
        )
      ) {
        let url;
        if (selectedInput === "1" || selectedInput === "3") {
          url = "/callback/trySaveFile";
        } else if (selectedInput === "2") {
          url = "/callback/trySaveArsFile";
        }
        let data;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file2", file2); // 계약서
        formData.append("file3", file3); // 재직증명서
        formData.append("type", selectedInput);
        formData.append("callback", callback);
        formData.append("cbKind", cbKind);
        data = formData;

        getPostFetch(url, data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              handlePageChoice(0);
              popupClose();
            }
          })
          .catch((error) => alert(error));
      }
    } else if (authType === "R") {
      if (
        window.confirm(
          "[ 발신번호 : " + callback + " ] 을 등록요청 하시겠습니까?"
        )
      ) {
        let url;
        if (selectedInput === "1" || selectedInput === "3") {
          url = "/callback/saveFile";
        } else if (selectedInput === "2") {
          url = "/callback/saveArsFile";
        }
        let data;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", selectedInput);
        formData.append("callback", callback);
        formData.append("cbKind", cbKind);
        data = formData;

        getPostFetch(url, data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              handlePageChoice(1);
              popupClose();
            }
          })
          .catch((error) => alert(error));
      }
    }
  };

  const style = {
    submit: {
      width: "100%",
      padding: "20px 0",
      backgroundColor: "#625af6",
      color: "#fff",
      marginBottom: "10px",
      border: "none",
      cursor: "pointer",
    },
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>번호 유형</th>
            <td>
              <span className="Radiobox">
                {inputTypes.map((value) => (
                  <span>
                    <input
                      type="radio"
                      name="AddNewNumberType"
                      id={value.id}
                      checked={selectedInput === value.id}
                      disabled={selectedInput === value.id ? false : true}
                      onClick={function (e) {
                        setSelectedContent(value.content);
                        setSelectedInput(value.id);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </span>
                ))}
              </span>
            </td>
          </tr>

          {selectedInput === "1" ? (
            <AddSendNumberType1
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setFile3={setFile3}
              setCallback={setCallback}
              setAuthCheck={setAuthCheck}
              number={number}
            />
          ) : selectedInput === "2" ? (
            <AddSendNumberType2
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setCallback={setCallback}
              setAuthCheck={setAuthCheck}
              number={number}
            />
          ) : (
            <AddSendNumberType3
              authType={authType}
              setFile={setFile}
              setFile2={setFile2}
              setCallback={setCallback}
              number={number}
            />
          )}
        </tbody>
        <tfoot></tfoot>
      </table>
      <span style={{fontWeight: "bold", fontSize: "16px", color: "#625af6"}}>발신번호 서류 제출 안내</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>통신가입증명원 서류는 번호 유형 구분 없이 필수 서류이며,</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>타사 발신번호 등록 시 당사와 타사의 관계를 증명할 수 있는 서류를 추가로 제출하여야 합니다. (계약서 등)</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>휴대폰번호일 경우 명의자의 재직증명서를 추가로 제출하여야 합니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "12px"}}>지원 파일 형식은 pdf, jpg, jpeg, png 입니다.</span>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>필수</th>
            <td>통신가입증명원</td>
          </tr>
          <tr>
            <th>타사</th>
            <td>당사와 타사의 관계를 증명할 수 있는 서류 추가 제출 (계약서 등)</td>
          </tr>
          <tr>
            <th>휴대폰번호</th>
            <td>명의자의 재직증명서 추가 제출</td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
      <span style={{fontSize: "12px"}}>① 당사 휴대폰번호 : 통신가입증명원, 재직증명서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>② 당사 일반번호 : 통신가입증명원</span>
      <br></br>
      <span style={{fontSize: "12px"}}>③ 당사 대표번호 : 통신가입증명원</span>
      <br></br>
      <span style={{fontSize: "12px"}}>④ 타사 휴대폰번호 : 통신가입증명원, 재직증명서, 계약서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>⑤ 타사 일반번호 : 통신가입증명원, 계약서</span>
      <br></br>
      <span style={{fontSize: "12px"}}>⑥ 타사 대표번호 : 통신가입증명원, 계약서</span>
      <div className="button-wrap right">
        <Button
          buttonName="button-bgc-blue sbtn"
          buttonValue="등록"
          onChangePage={(e) => {
            Register();
          }}
        />
      </div>
    </>
  );
}

//일반관리 - 발신번호 관리 - 발신번호등록(휴대폰번호)
export function AddSendNumberType1(props) {
  const propsAuthCheck = props.setAuthCheck;
  const number = props.number;
  const authType = props.authType;
  const [custSn, setCustSn] = useState(1);
  const [authCheck, setAuthCheck] = useState("N");
  const setFile = props.setFile;
  const setFile2 = props.setFile2;
  const setFile3 = props.setFile3;
  const [fileNm1, setFileNm1] = useState('');
  const [fileNm2, setFileNm2] = useState('');
  const [fileNm3, setFileNm3] = useState('');
  const setCallback = props.setCallback;
  const [phone, setPhone] = useState("");

  useEffect(() => {
    handlerUserSet();
  }, []);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handlerUserSet = () => {
    const url = "/user/info";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCustSn(jsonArr[0].custSn);
          setAuthCheck("N");
          propsAuthCheck("N");
        }
      })
      .catch((error) => alert(error));
  };

  let selfAuthPopup;
  let selfAuthPopupCheckTimer;
  function handlerSelfAuth() {
    if (authType === "R") {
      let mobile = sessionStorage.getItem("mobile");
      selfAuthPopup = window.open(
        SEVER_DOMAIN +
          "/callback/register/selfAuth/" +
          mobile +
          "/" +
          custSn +
          "/req",
        "popupChk",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );
      selfAuthPopupCheckTimer = setInterval(checkSelfAuthPopupClose, 500);
    } else if (authType === "T") {
      selfAuthPopup = window.open(
        SEVER_DOMAIN +
          "/callback/register/selfAuth/" +
          number +
          "/" +
          custSn +
          "/req",
        "popupChk",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );
      selfAuthPopupCheckTimer = setInterval(checkSelfAuthPopupClose, 500);
    }
  }

  function checkSelfAuthPopupClose() {
    if (selfAuthPopup.closed) {
      let mobile = sessionStorage.getItem("mobile");
      let name = sessionStorage.getItem("nm");

      if (authType === "R") {
        const data = {
          search3: mobile,
          search4: name,
          search5: custSn,
          search6: "R",
        };

        getPostFetch("/callback/registerSelfAuthResult/check", data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              setAuthCheck("Y");
              propsAuthCheck("Y");
              setCallback(result.resultData);
            } else if (result.resultCode === "1") {
            }
          })
          .catch((error) => alert(error));
      } else if (authType === "T") {
        const data = {
          search3: number,
          search4: name,
          search5: custSn,
          search6: "T",
        };

        getPostFetch("/callback/registerSelfAuthResult/check", data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              setAuthCheck("Y");
              propsAuthCheck("Y");
              setCallback(result.resultData);
            } else if (result.resultCode === "1") {
            }
          })
          .catch((error) => alert(error));
      }

      clearInterval(selfAuthPopupCheckTimer);
    }
  }

  const handleChange = (e) => {

    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile(e.target.files[0]);
        setFileNm1(fileName);
      }
    }
  };

  const handleChange2 = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile2(e.target.files[0]);
        setFileNm2(fileName);
      }
    }
  };

  const handleChange3 = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile3(e.target.files[0]);
        setFileNm3(fileName);
      }
    }
  };

  const handleCallbackChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
      setCallback(e.target.value);
    }
  };

  const style = {
    button: {
      padding: "20px 25px",
      backgroundColor: "#313131",
      border: "none",
      color: "#fff",
      marginBottom: "10px",
      borderRadius: "3px",
      cursor: "pointer",
    },
    green: {
      color: "#00cc00",
    },
  };
  return (
    <>
      <tr>
        <th>본인인증</th>
        <td>
          <input type="hidden" id="authCheck" value="asdasd" />

          {/* <input type="button" name="" defaultValue="안심 본인인증하기" onChangePage={selfAuthentication} style={style.button}/> */}
          <>
            {authCheck !== "Y" && (
              <Button
                buttonName="button-bgc-dark wid100 pd10"
                buttonValue="안심 본인인증하기"
                onChangePage={(e) => {
                  handlerSelfAuth();
                }}
              />
            )}
            {authCheck === "Y" ? (
              <p className="blue" style={{marginTop:"0px"}}>본인인증이 완료되었습니다.</p>
            ) : (
              <p className="red center" style={{marginTop:"0px"}} >
              </p>
            )}
          </>
        </td>
      </tr>
      <tr>
        <th>통신가입증명원 <span style={{color: "#ff0000", fontSize: "10px"}}>*</span></th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm1} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file" onChange={handleChange} hidden/>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>재직증명서 <span style={{color: "#ff0000", fontSize: "10px"}}>*</span></th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm3} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file3" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file3" onChange={handleChange3} hidden/>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>계약서</th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm2} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file2" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file2" onChange={handleChange2} hidden/>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

//일반관리 - 발신번호 관리 - 발신번호등록(일반번호)
export function AddSendNumberType2(props) {
  const style = {
    select: {
      width: "120px",
      height: "40px",
    },
    text1: {
      width: "200px",
      marginRight: "5px",
      marginLeft: "5px",
      height: "47px",
    },
    text2: {
      width: "calc(100% - 168px)",
      marginRight: "5px",
    },
    button: {
      float: "right",
      width: "calc(100% - 128px)",
    },
    input: {
      width: "330px",
    },
    red: {
      color: "#ff5757",
      position: "absolute",
      right: "15px",
      top: "12%",
    },
    countWrap: {
      position: "relative",
    },
    green: {
      color: "#00cc00",
      textAlign: "right",
      fontSize: "12px",
    },
    green2: {
      color: "#00cc00",
      position: "absolute",
      right: "15px",
      top: "12%",
      fontSize: "12px",
    },
  };

  const [areaCode, setAreaCode] = useState("02");
  const [number, setNumber] = useState(props.number);
  const [authNumber, setAuthNumber] = useState("");
  const [reqCheck, setReqCheck] = useState("N");
  const [authCheck, setAuthCheck] = useState("N");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const propsCallBack = props.setCallback;
  const propsAuthCheck = props.setAuthCheck;
  const setFile = props.setFile;
  const setFile2 = props.setFile2;
  const [fileNm1, setFileNm1] = useState('');
  const [fileNm2, setFileNm2] = useState('');
  const authType = props.authType;

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleFile = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile(e.target.files[0]);
        setFileNm1(fileName);
      }
    }
  };

  const handleFile2 = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile2(e.target.files[0]);
        setFileNm2(fileName);
      }
    }
  };

  useEffect(() => {
    if (authType === "R") {
      if (number != undefined) {
        var phone = areaCode + number;
        propsCallBack(phone);
      }
    } else {
      if (number != undefined) {
        setNumber(number);
        propsCallBack(number);
      }
    }
  }, [number]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const handleArsReq = (e) => {
    if (number === undefined) {
      alert("전화번호를 입력하세요.");
      return;
    }
    if (authType === "R") {
      if (
        window.confirm(
          "해당 전화번호로 인증요청 하시겠습니까?\n전화번호 : " +
            areaCode +
            number
        )
      ) {
        getFetch("/callback/register/ars/" + areaCode + number)
          .then((result) => {
            if (result.resultCode === "0") {
              setReqCheck("Y");
              setAuthCheck("N");
              document.getElementById("authNum").readOnly = false;
              setMinutes(5);
              setSeconds(0);
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    } else if (authType === "T") {
      if (
        window.confirm(
          "해당 전화번호로 인증요청 하시겠습니까?\n전화번호 : " + number
        )
      ) {
        getFetch("/callback/register/ars/" + number)
          .then((result) => {
            if (result.resultCode === "0") {
              setReqCheck("Y");
              setAuthCheck("N");
              document.getElementById("authNum").readOnly = false;
              setMinutes(5);
              setSeconds(0);
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    }
  };

  const handleArsCheck = (e) => {
    if (reqCheck === "N") {
      alert("인증요청을 하십시오.");
      return;
    }

    if (document.getElementById("authNum").value === "") {
      alert("인증번호를 입력하세요.");
      return;
    }

    if (document.getElementById("authNum").value.length != 6) {
      alert("인증번호 6자리를 입력하세요.");
      return;
    }

    if (document.getElementById("authNum").readOnly === true) {
      alert("이미 인증되었습니다.");
      return;
    }

    if (authType === "R") {
      getFetch(
        "/callback/register/ars/check/" + areaCode + number + "/" + authNumber
      )
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            document.getElementById("authNum").readOnly = true;
            document.getElementById("phone").readOnly = true;
            document.getElementById("tel").disabled = true;
            setAuthCheck("Y");
            propsAuthCheck("Y");
          }
        })
        .catch(function (error) {
          alert(error);
        });
    } else if (authType === "T") {
      getFetch("/callback/register/ars/check/" + number + "/" + authNumber)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            document.getElementById("authNum").readOnly = true;
            document.getElementById("phone").readOnly = true;
            setAuthCheck("Y");
            propsAuthCheck("Y");
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  const handleArsNumChange = (e) => {
    setAuthNumber(e);
  };

  const handleChange = (e) => {
    const phone = document.getElementById("phone").value;
    const tel = document.getElementById("tel").value;
    setNumber(e);
    propsCallBack(tel + phone);
  };

  return (
    <>
      <tr>
        <th>전화번호</th>
        <td>
          {authType === "T" ? (
            <div>
              <div class="gs-custom-input-btn-group">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={number}
                  title="전화번호"
                  placeholder="전화번호"
                  readOnly
                />
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="인증요청"
                  onChangePage={handleArsReq}
                />
              </div>
            </div>
          ) : null}
          {authType === "R" && (
            <div>
              <select
                id="tel"
                name="tel"
                style={style.select}
                onChange={(e) => setAreaCode(e.target.value)}
              >
                <option value="02">02</option>
                <option value="031">031</option>
                <option value="032">032</option>
                <option value="033">033</option>
                <option value="041">041</option>
                <option value="042">042</option>
                <option value="043">043</option>
                <option value="044">044</option>
                <option value="051">051</option>
                <option value="052">052</option>
                <option value="053">053</option>
                <option value="054">054</option>
                <option value="055">055</option>
                <option value="061">061</option>
                <option value="062">062</option>
                <option value="063">063</option>
                <option value="064">064</option>
                <option value="">없음</option>
              </select>
              <div class="gs-custom-input-btn-group" style={style.button}>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={(e) => handleChange(e.target.value)}
                  value={number}
                  title="전화번호"
                  placeholder="전화번호"
                />
                {/* <Button
                  buttonName="button-bgc-dark"
                  buttonValue="인증요청"
                  onChangePage={handleArsReq}
                />*/}
              </div>
              {/*
              {reqCheck === "Y" && (
                <div className="blue center">인증번호가 전송되었습니다.</div>
              )} */}
            </div>
          )}
        </td>
      </tr>
      {/*<tr>
        <th>인증번호</th>
        <td>
          <div class="gs-custom-input-btn-group">
            <span style={style.countWrap}>
              <input
                type="text"
                name="authNum"
                id="authNum"
                defaultValue=""
                placeholder="인증번호 6자리"
                onChange={(e) => handleArsNumChange(e.target.value)}
                readOnly
              />
              {(function () {
                if (reqCheck === "Y" && authCheck === "N")
                  return (
                    <p style={style.red}>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  );
                else if (reqCheck === "Y" && authCheck === "Y")
                  return <p style={style.green2}>인증되었습니다.</p>;
              })()}
            </span>

            <Button
              buttonName="button-bgc-dark"
              buttonValue="인증확인"
              onChangePage={handleArsCheck}
            />
          </div>
        </td>
      </tr> */}
      <tr>
        <th>통신가입증명원 <span style={{color: "#ff0000", fontSize: "10px"}}>*</span></th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm1} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file" onChange={handleFile} hidden/>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>계약서</th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm2} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file2" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file2" onChange={handleFile2} hidden/>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

//일반관리 - 발신번호 관리 - 발신번호등록(대표번호)
export function AddSendNumberType3(props) {
  const style = {
    text1: {
      width: "100%",
      marginRight: "5px",
    },
  };
  const number = props.number;
  const setFile = props.setFile;
  const setFile2 = props.setFile2;
  const [fileNm1, setFileNm1] = useState('');
  const [fileNm2, setFileNm2] = useState('');
  const setCallback = props.setCallback;
  const [phone, setPhone] = useState("");
  const authType = props.authType;

  const handleChange = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile(e.target.files[0]);
        setFileNm1(fileName);
      }
    }
  };

  const handleChange2 = (e) => {
    if(e.target.files[0] != undefined) {
            
      let fileName = e.target.files[0].name;
      let extension = fileName.slice(e.target.files[0].name.indexOf(".") + 1).toLowerCase();
      
      if(extension != "pdf" && extension != "jpg" && extension != "jpeg" && extension != "png") {
          alert("[pdf jpg jpeg png] 파일을 첨부하세요.");
          return;
      } else {
        setFile2(e.target.files[0]);
        setFileNm2(fileName);
      }
    }
  };

  useEffect(() => {
    if (number != undefined) {
      setPhone(number);
      setCallback(number);
    }
  }, [number]);

  const handleCallbackChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
      setCallback(e.target.value);
    }
  };

  return (
    <>
      <tr>
        <th>전화번호</th>
        <td>
          {number === undefined ? (
            <input
              type="text"
              name="callbackNum"
              id="callbackNum"
              defaultValue=""
              placeholder="전화번호"
              onChange={(e) => {
                handleCallbackChange(e);
              }}
              value={phone}
            />
          ) : (
            <input
              type="text"
              name="callbackNum"
              id="callbackNum"
              defaultValue=""
              placeholder="전화번호"
              value={number}
              readOnly
            />
          )}
        </td>
      </tr>
      <tr>
        <th>통신가입증명원 <span style={{color: "#ff0000", fontSize: "10px"}}>*</span></th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm1} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file" onChange={handleChange} hidden/>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>계약서</th>
        <td>
          <div class="gs-custom-input-btn-group">
            <div className="filebox">
              <input className="bolder_none" style={{background: "transparent", borderWidth: "1px", color: "black"}} value={fileNm2} placeholder="pdf, jpg, jpeg, png만 첨부 가능합니다." disabled/>
              <label for="file2" className="cm-highlight-btn he30" style={{marginLeft: "10px", cursor: "pointer"}}>파일찾기</label> 
              <input type="file" id="file2" onChange={handleChange2} hidden/>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
//문자보내기 - 수신번호 - 엑셀 대량전송
export function LoadReceiveNumberList(props) {
  const setDelList = props.setDelList;
  const [tableRows, setTableRows] = useState("");
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="9">불러 올 파일정보가 없습니다.</td>
        </tr>
      );
    } else {
      /*
            <td><input type="checkbox" name="" defaultValue="" /></td>
            <td>1</td>
            <td>010-0000-0000</td>
            <td>홍길동</td>
            <td>변수</td>
            <td>변수2</td>
            <td></td>
            <td></td>
            <td>정상</td> 
      */
      let count = 1;
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.tsSn}
                value={row.tsSn}
                onChange={handleCheked}
              />
            </td>
            <td> {count} </td>
            <td> {row.tsPhone} </td>
            <td> {row.tsNm} </td>
            <td> {row.tsEtc1} </td>
            <td> {row.tsEtc2} </td>
            <td> {row.tsEtc3} </td>
            <td> {row.tsEtc4} </td>
            <td> 정상 </td>
          </tr>
        );
        count = count + 1;
      }
      document.getElementsByName("checkAllDel")[0].checked = false;
      const snNodeList = document.getElementsByName("checkDel");
      snNodeList.forEach((node) => {
        if (node.checked) node.checked = false;
      });
    }
    setTableRows(rows);
  }, [props.data]);

  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;

    setDelList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };

  return (
    <>
      <table className="list-table">
        <colgroup>
          <col width="31px" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllDel"
                defaultValue=""
                onChange={handleAllCheck}
              />
            </th>
            <th>NO</th>
            <th>수신번호</th>
            <th>#&#123;이름&#125;</th>
            <th>#&#123;1&#125;</th>
            <th>#&#123;2&#125;</th>
            <th>#&#123;3&#125;</th>
            <th>#&#123;4&#125;</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </>
  );
}

export function LoadReceiveNumberErrorList() {
  return (
    <>
      <table className="list-table">
        <colgroup>
          <col width="31px" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input type="checkbox" name="" defaultValue="" />
            </th>
            <th>NO</th>
            <th>수신번호</th>
            <th>#&#123;이름&#125;</th>
            <th>#&#123;1&#125;</th>
            <th>#&#123;2&#125;</th>
            <th>#&#123;3&#125;</th>
            <th>#&#123;4&#125;</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          <tr className="error">
            <td>
              <input type="checkbox" name="" defaultValue="" />
            </td>
            <td>1</td>
            <td>010-0000-0000</td>
            <td>홍길동</td>
            <td>변수</td>
            <td>변수2</td>
            <td></td>
            <td></td>
            <td>번호중복</td>
          </tr>
          <tr className="error">
            <td>
              <input type="checkbox" name="" defaultValue="" />
            </td>
            <td>1</td>
            <td>010-0000-0000</td>
            <td>홍길동</td>
            <td>변수</td>
            <td>변수2</td>
            <td></td>
            <td></td>
            <td>번호중복</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

//그룹추가(등록) 팝업
export function AddGroup(props) {
  const groupSn = props.groupSn;
  const handleGroupCheck = props.handleGroupCheck;
  const agType = props.agType;
  const [newGroupNm, setNewGroupNm] = useState("");

  useEffect(() => {
    handleGroupCheck(false);
  }, [newGroupNm]);

  const handleDuplicate = (event) => {
    event.preventDefault();

    if (!newGroupNm) {
      alert("그룹명을 입력하세요.");
      return;
    }
    const url = "/addr/overlapCheck";
    const data = { agSn: groupSn, agType: agType, agNm: newGroupNm };

    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          handleGroupCheck(true);
        }
      })
      .catch((error) => alert(error));
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>그룹 위치</th>
            <td>{props.groupNm}</td>
          </tr>
          <tr>
            <th>그룹명</th>
            <td>
              <div className="gs-custom-input-btn-group">
                <input
                  type="text"
                  id="addGroupName"
                  name="addGroupName"
                  title="그룹명"
                  placeholder="그룹명"
                  onChange={(e) => {
                    setNewGroupNm(e.target.value);
                  }}
                />
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="중복검사"
                  onChangePage={handleDuplicate}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

//그룹수정 팝업
export function EditGroup(props) {
  const groupSn = props.groupSn;
  const groupUpSn = props.groupUpSn;
  const handleGroupCheck = props.handleGroupCheck;
  const [editGroupNm, setEditGroupNm] = useState("");
  const groupNm = props.groupNm;

  useEffect(() => {
    setEditGroupNm(props.groupNm);
  }, []);

  useEffect(() => {
    handleGroupCheck(false);
  }, [editGroupNm]);

  const handleDuplicate = (event) => {
    event.preventDefault();

    if (!editGroupNm) {
      alert("그룹명을 입력하세요.");
      return;
    }

    if (editGroupNm === groupNm) {
      alert("동일한 그룹명 입니다.");
      return;
    }

    const url = "/addr/editOverlapCheck";
    const data = {
      agSn: groupSn,
      agType: "1",
      agNm: editGroupNm,
      upperAgSn: groupUpSn,
    };

    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          handleGroupCheck(true);
        }
      })
      .catch((error) => alert(error));
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>그룹명</th>
            <td>
              <div className="gs-custom-input-btn-group">
                <input
                  type="text"
                  id="editGroupName"
                  name="editGroupName"
                  title="그룹명"
                  placeholder="그룹명"
                  onChange={(e) => {
                    setEditGroupNm(e.target.value);
                  }}
                  value={editGroupNm}
                />
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="중복검사"
                  onChangePage={handleDuplicate}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

//그룹 주소록 이동 팝업
export function MoveGroup(props) {
  const groupList = props.groupList;
  const groupDepth = props.groupDepth;
  const groupPathNm = props.groupPathNm;
  const groupCnt = props.groupCnt;
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>원본그룹 주소록 위치</th>
            <td>전체/{groupPathNm}</td>
          </tr>
          <tr>
            <th>원본그룹 주소록 수</th>
            <td>{setComma(groupCnt)}</td>
          </tr>
          <tr>
            <th>이동 할 그룹 위치</th>
            <td>
              <select name="Button_type" id="groupListMove" className="wid100">
                {groupDepth === 2 ? <option value="0">전체</option> : null}
                {groupList.map((row) => (
                  <option value={row.agSn}>전체/{row.groupNm}</option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

//그룹 주소록 복사 팝업
export function CopyGroup(props) {
  const groupList = props.groupList;
  const groupDepth = props.groupDepth;
  const groupPathNm = props.groupPathNm;
  const groupCnt = props.groupCnt;
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>원본그룹 주소록 위치</th>
            <td>전체/{groupPathNm}</td>
          </tr>
          <tr>
            <th>원본그룹 주소록 수</th>
            <td>{setComma(groupCnt)}</td>
          </tr>
          <tr>
            <th>복사 할 그룹 위치</th>
            <td>
              <select name="Button_type" id="groupListCopy" className="wid100">
                {groupDepth === 2 ? <option value="0">전체</option> : null}
                {groupList.map((row) => (
                  <option value={row.agSn}>전체/{row.groupNm}</option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

//주소록 상세정보 수정
export function EditAddressInfo(props) {
  const row = props.rowData;
  const [nm, setNm] = useState("");
  const [phone, setPhone] = useState("");
  const [etc1, setEtc1] = useState("");
  const [etc2, setEtc2] = useState("");
  const [etc3, setEtc3] = useState("");
  const [etc4, setEtc4] = useState("");

  useEffect(() => {
    setNm(props.rowData.nm);
    setPhone(props.rowData.phone);
    setEtc1(props.rowData.etc1);
    setEtc2(props.rowData.etc2);
    setEtc3(props.rowData.etc3);
    setEtc4(props.rowData.etc4);
  }, [props.rowData]);

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  return (
    <table className="table_style1 addressInfo">
      <thead></thead>
      <tbody>
        <tr>
          <th>그룹</th>
          <td>{row.ag}</td>
        </tr>
        <tr>
          <th>수신번호</th>
          <td>
            <input
              type="text"
              name="manualPhone"
              id="manualPhone"
              maxlength="11"
              value={phone}
              onChange={handleChange}
            />
          </td>
        </tr>
        <tr>
          <th>이름</th>
          <td>
            <input
              type="text"
              name="manualNm"
              id="manualNm"
              value={nm}
              onChange={(e) => {
                setNm(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>기타1</th>
          <td>
            <input
              type="text"
              name="manualEtc1"
              id="manualEtc1"
              value={etc1}
              onChange={(e) => {
                setEtc1(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>기타2</th>
          <td>
            <input
              type="text"
              name="manualEtc2"
              id="manualEtc2"
              value={etc2}
              onChange={(e) => {
                setEtc2(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>기타3</th>
          <td>
            <input
              type="text"
              name="manualEtc3"
              id="manualEtc3"
              value={etc3}
              onChange={(e) => {
                setEtc3(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>기타4</th>
          <td>
            <input
              type="text"
              name="manualEtc4"
              id="manualEtc4"
              value={etc4}
              onChange={(e) => {
                setEtc4(e.target.value);
              }}
            />
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}

// 주소록 중복번호/이름 검색
export function DuplicateSearch(props) {
  const setDelList = props.setDelList;
  const handleDuplicateSearch = props.handleDuplicateSearch;
  const handleReload = props.handleReload;
  const custGrade = props.custGrade;
  const userType = props.userType;
  const addrType = props.addrType;
  const totalCount = props.totalCount;

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleDelete = (sn, nm, phone) => {
    const snList = [];
    if (
      window.confirm(
        "해당 연락처 [ 이름 : " +
          nm +
          " / 휴대폰번호 : " +
          phone +
          " ]를 삭제 하시겠습니까?"
      )
    ) {
      snList.push(sn);
      const url = "/addr/addrDel";
      const data = { snList: snList };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleDuplicateSearch();
            handleReload(-1);
          }
        })
        .catch((error) => alert(error));
    }
  };
  const [tableRows, setTableRows] = useState([]);
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];

    if (!jsonArr || jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="5">중복된 연락처가 없습니다.</td>
        </tr>
      );
    } else {
      /*
            <tr>
            <td><input type="checkbox" name="" defaultValue="" /></td>
            <td>test1</td>
            <td>김유니</td>
            <td>010-4567-8977</td>
            <td><button class="button-border-gray ssbtn center">삭제</button></td>
          </tr>
      */
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(
          <tr>
            <td>
              <input
                type="checkbox"
                defaultValue=""
                name="checkDel"
                id={row.abSn}
                value={row.abSn}
                onChange={handleCheked}
              />
            </td>
            <td> {row.agNm} </td>
            <td> {row.abNm} </td>
            <td> {row.abPhone} </td>
            {custGrade === "1" && userType === "1" ? (
              <td>
                {" "}
                <button
                  class="button-border-gray ssbtn center"
                  onClick={(e) => {
                    handleDelete(row.abSn, row.abNm, row.abPhone);
                  }}
                >
                  삭제
                </button>{" "}
              </td>
            ) : null}
            {addrType === "P" ? (
              <td>
                {" "}
                <button
                  class="button-border-gray ssbtn center"
                  onClick={(e) => {
                    handleDelete(row.abSn, row.abNm, row.abPhone);
                  }}
                >
                  삭제
                </button>{" "}
              </td>
            ) : null}
          </tr>
        );
      }
      document.getElementsByName("checkAllDel")[0].checked = false;
      const snNodeList = document.getElementsByName("checkDel");
      snNodeList.forEach((node) => {
        if (node.checked) node.checked = false;
      });
    }
    setTableRows(rows);
  }, [props.data]);

  const handleCheked = () => {
    let num = 0;
    let sn = [];
    const snNodeList = document.getElementsByName("checkDel");
    const fnlNum = snNodeList.length;
    snNodeList.forEach((node) => {
      if (node.checked) {
        sn.push(node.value);
        num = num + 1;
      }
    });

    if (num === fnlNum)
      document.getElementsByName("checkAllDel")[0].checked = true;
    else document.getElementsByName("checkAllDel")[0].checked = false;

    setDelList(sn);
  };

  const handleAllCheck = (event) => {
    const snNodeList = document.getElementsByName("checkDel");
    let snList = [];
    snNodeList.forEach((node) => {
      if (event.target.checked) {
        snList.push(node.value);
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
    setDelList(snList);
  };

  return (
    <>
      <p>
        전체 <span className="red">{totalCount}</span>건
      </p>
      <table className="table_style2">
        <thead></thead>
        <tbody>
          <tr>
            <th>
              <input
                type="checkbox"
                name="checkAllDel"
                id="checkAllDel"
                defaultValue=""
                onChange={handleAllCheck}
              />
            </th>
            <th>그룹명</th>
            <th>성명</th>
            <th>연락처</th>
            {custGrade === "1" && userType === "1" ? <th>관리</th> : null}
            {addrType === "P" ? <th>관리</th> : null}
          </tr>
          {tableRows}
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}
export function LoadFriendsTalkImg(props) {
  const tableRows = props.tableRows;
  const setSelectedImgData = props.setSelectedImgData;
  const style = {
    eclipse: {
      maxWidth: "250px",
    },
  };
  const [defaultValue, setdefaultValue] = useState(1);
  const popupClose = props.popupClose;
  const handleSelect = (e, data) => {
    e.preventDefault();
    // setdefaultValue(e.target.defaultValue); // 선택한 라디오의 이미지 URL값 넘기기
    let type = 0;
    if (data.imgType === "친구톡") {
      type = 2;
    } else {
      type = 3;
    }
    setSelectedImgData({
      id: data.imgSn,
      type: type,
      url: data.imgUrl,
    });
    popupClose(); // 라디오 선택 시 자동으로 팝업 닫기
  };
  return (
    <table className="table_style2 KakaoFriendList nowrap LoginStep">
      <thead>
        <tr>
          <th></th>
          <th>미리보기</th>
          <th>이미지 종류</th>
          <th>채널아이디</th>
          <th style={style.eclipse}>이미지 URL</th>
        </tr>
        {tableRows.map((data, index) => (
          <tr>
            <td>
              <input
                type="radio"
                id={"LoadFriendImg1" + index}
                name="LoadFriendImg"
                value="LoadFriendImg1"
                onClick={(e) => handleSelect(e, data)}
              />
              <label for={"LoadFriendImg1" + index}></label>
            </td>
            <td>
              <img src={data.imgUrl} alt="" />
            </td>
            <td>{data.imgType}</td>
            <td>
              {data.corpNm} ({data.plusId})
            </td>
            <td style={style.eclipse}>{data.imgUrl}</td>
          </tr>
        ))}
      </thead>
    </table>
  );
}

export function LoadRcsImg(props) {
  const tableRows = props.tableRows;
  const setSelectedImgData = props.setSelectedImgData;

  const style = {
    eclipse: {
      maxWidth: "250px",
    },
  };
  const [defaultValue, setdefaultValue] = useState(1);
  const popupClose = props.popupClose;
  const handleSelect = (e, data) => {
    e.preventDefault();
    // setdefaultValue(e.target.defaultValue); // 선택한 라디오의 이미지 URL값 넘기기
    let type = 0;

    setSelectedImgData({
      id: data.fileId,
      url: SEVER_DOMAIN + "/rcs_image/" + data.fileLocation,
    });
    popupClose(); // 라디오 선택 시 자동으로 팝업 닫기
  };
  return (
    <table className="table_style2 KakaoFriendList nowrap LoginStep">
      <thead>
        <tr>
          <th></th>
          <th>미리보기</th>
          <th style={style.eclipse}>이미지 ID</th>
        </tr>
        {tableRows.map((data, index) => (
          <tr>
            <td>
              <input
                type="radio"
                id={data.fileId}
                name="LoadFriendImg"
                value="LoadFriendImg1"
                onClick={(e) => handleSelect(e, data)}
              />
              <label for={data.fileId}></label>
            </td>
            <td>
              <img src={SEVER_DOMAIN + "/rcs_image/" + data.fileLocation} />
            </td>
            <td>{data.fileId}</td>
          </tr>
        ))}
      </thead>
    </table>
  );
}

//RCS 템플릿 상세(view) 승인결과
export function RcsTemplateResultTable(props) {
  const [popup, handlePopup] = useState(false);
  const templateApprovalResult = props.templateApprovalResult;
  const templateApprovalReason = props.templateApprovalReason;
  const templateApprovalDt = props.templateApprovalDt;

  return (
    <>
      <div className="page-stitle">승인결과</div>
      <form>
        <table className="table_style1">
          <thead></thead>
          <tbody>
            <tr>
              <th>{templateApprovalResult}</th>
              {/* 반려 / 신규승인대기 / 승인 등의 상태값 표기 */}
              <td>
                <p className="size14 mb10">{templateApprovalDt}</p>
                {/* 상태변경 일시 */}
                <p>{templateApprovalReason}</p>
                {/* RBC의 답변 내용 */}
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </form>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="템플릿 미리보기1"
          size="small"
        >
          <LayerPopRCSTemplatePreview />
        </PopUpLayout>
      )}
    </>
  );
}

//RCS 템플릿 상세(view)
export function RcsTemplateViewTable(props) {
  const myCustId = props.myCustId;
  const brandIdList = props.brandIdList;
  const data = props.data;
  const custType = props.custType;
  const templateId = props.data[0].messagebaseId;
  const brandId = props.data[0].brandId;
  const templateStatus = props.data[0].templateApprovalResult;
  const tempTemplateSeq = props.tempTemplateSeq;
  const [templateBody, setTemplateBody] = useState(false);
  const [templateBtn, setTemplateBtn] = useState(false);
  const [check, setCheck] = useState("N");
  const [handleAgree, setHandleAgree] = useState(false);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const [popup, handlePopup] = useState(false);
  const navigate = useNavigate();
  const handleUpload = (event) => {
    event.preventDefault();
    async function getFetch(url) {
      return getRequest.fetch(url);
    }

    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }

    let brandId = document.getElementById("brandNm").value;
    let cardType = "";
    let cardKind = document.getElementById("cardKind").value;
    let templateName = document.getElementById("templateName").value;

    const body = [];
    const btn = [];

    if (document.getElementsByName("cardType")[0].checked) {
      cardType = "Description";
    } else {
      cardType = "Cell";
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (templateName === "") {
      alert("템플릿 명을 입력하세요");
      return;
    } else if (templateName.length > 30) {
      alert("템플릿명은 90자를 초과하지 못합니다.");
    }

    if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value === ""
    ) {
      alert("내용을 입력하세요.");
      return;
    } else if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value.length > 90
    ) {
      alert("내용은 90자를 초과하지 못합니다.");
    }

    if (cardKind === "인증" && document.getElementById("btnName1")) {
      alert(cardType + "의 인증 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Cell" &&
      cardKind === "회원가입" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 회원가입 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Description" &&
      cardKind === "예약" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 예약 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 왼쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementById("bodyText2_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 오른쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
            text2: document.getElementById("bodyText2_" + i).value,
          };
          body.push(obj);
        } else {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
          };
          body.push(obj);
        }

        if (document.getElementsByName("line_" + i)[0]) {
          if (document.getElementsByName("line_" + i)[0].checked === true) {
            const obj = {};
            body.push(obj);
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        if (document.getElementById("btnName" + i).value === "") {
          alert(i + 1 + "번째 버튼명을 입력하세요.");
          return;
        } else if (document.getElementById("btnName" + i).value.length > 17) {
          alert(i + 1 + "번째 버튼명은 17자를 초과하지 못합니다.");
        }

        let info1 = "";
        let info2 = "";
        let info3 = "";
        let info4 = "";

        if (document.getElementById("btnInfo" + 1 + "_" + i)) {
          if (document.getElementById("btnInfo" + 1 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info1 = document.getElementById("btnInfo" + 1 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 2 + "_" + i)) {
          if (document.getElementById("btnInfo" + 2 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info2 = document.getElementById("btnInfo" + 2 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 3 + "_" + i)) {
          if (document.getElementById("btnInfo" + 3 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info3 = document.getElementById("btnInfo" + 3 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 4 + "_" + i)) {
          if (document.getElementById("btnInfo" + 4 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info4 = document.getElementById("btnInfo" + 4 + "_" + i).value;
        }

        const obj = {
          btnType: document.getElementById("select" + i).value,
          btn: document.getElementById("btnName" + i).value,
          info1: info1,
          info2: info2,
          info3: info3,
          info4: info4,
        };
        btn.push(obj);
      }
    }

    const url = "/rcs/templateSave";
    const data = {
      brandId: brandId,
      cardType: cardType,
      cardKind: cardKind,
      templateName: templateName,
      templateBody: body,
      templateBtn: btn,
      tempTemplateSeq: tempTemplateSeq,
    };

    if (check === "N") {
      alert("동의 후 등록 가능합니다.");
      return;
    }

    if (window.confirm("템플릿을 등록하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          navigate("/rcs/rcsTemplate");
        })
        .catch((error) => alert(error));
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    async function getFetch(url) {
      return getRequest.fetch(url);
    }

    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }
    let messageBaseId = document.getElementById("templateId").value;
    let brandId = document.getElementById("brandNm").value;
    let cardType = "";
    let cardKind = document.getElementById("cardKind").value;
    let templateName = document.getElementById("templateName").value;

    const body = [];
    const btn = [];

    if (document.getElementsByName("cardType")[0].checked) {
      cardType = "Description";
    } else {
      cardType = "Cell";
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (templateName === "") {
      alert("템플릿 명을 입력하세요");
      return;
    } else if (templateName.length > 30) {
      alert("템플릿명은 90자를 초과하지 못합니다.");
    }

    if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value === ""
    ) {
      alert("내용을 입력하세요.");
      return;
    } else if (
      cardType === "Description" &&
      document.getElementById("bodyText_0").value.length > 90
    ) {
      alert("내용은 90자를 초과하지 못합니다.");
    }

    if (cardKind === "인증" && document.getElementById("btnName1")) {
      alert(cardType + "의 인증 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Cell" &&
      cardKind === "회원가입" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 회원가입 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    if (
      cardType === "Description" &&
      cardKind === "예약" &&
      document.getElementById("btnName1")
    ) {
      alert(cardType + "의 예약 유형은 최대 버튼 1개까지만 허용합니다.");
      return;
    }

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 왼쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementById("bodyText2_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 오른쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
            text2: document.getElementById("bodyText2_" + i).value,
          };
          body.push(obj);
        } else {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용은 90자를 초과하지 못합니다.");
            return;
          }
          const obj = {
            text1: document.getElementById("bodyText_" + i).value,
          };
          body.push(obj);
        }

        if (document.getElementsByName("line_" + i)[0]) {
          if (document.getElementsByName("line_" + i)[0].checked === true) {
            const obj = {};
            body.push(obj);
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        if (document.getElementById("btnName" + i).value === "") {
          alert(i + 1 + "번째 버튼명을 입력하세요.");
          return;
        } else if (document.getElementById("btnName" + i).value.length > 17) {
          alert(i + 1 + "번째 버튼명은 17자를 초과하지 못합니다.");
        }

        let info1 = "";
        let info2 = "";
        let info3 = "";
        let info4 = "";

        if (document.getElementById("btnInfo" + 1 + "_" + i)) {
          if (document.getElementById("btnInfo" + 1 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info1 = document.getElementById("btnInfo" + 1 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 2 + "_" + i)) {
          if (document.getElementById("btnInfo" + 2 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info2 = document.getElementById("btnInfo" + 2 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 3 + "_" + i)) {
          if (document.getElementById("btnInfo" + 3 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info3 = document.getElementById("btnInfo" + 3 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 4 + "_" + i)) {
          if (document.getElementById("btnInfo" + 4 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info4 = document.getElementById("btnInfo" + 4 + "_" + i).value;
        }

        const obj = {
          btnType: document.getElementById("select" + i).value,
          btn: document.getElementById("btnName" + i).value,
          info1: info1,
          info2: info2,
          info3: info3,
          info4: info4,
        };
        btn.push(obj);
      }
    }

    const url = "/rcs/templateUpdate";
    const data = {
      messageBaseId: messageBaseId,
      brandId: brandId,
      cardType: cardType,
      cardKind: cardKind,
      templateName: templateName,
      templateBody: body,
      templateBtn: btn,
      tempTemplateSeq: tempTemplateSeq,
    };

    if (check === "N") {
      alert("동의 후 수정(승인요청) 가능합니다.");
      return;
    }

    if (window.confirm("템플릿을 수정(승인요청)하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          navigate("/rcs/rcsTemplate");
        })
        .catch((error) => alert(error));
    }
  };

  const handlePreview = (event) => {
    let body = [];
    let btn = [];

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          body.push(
            "<div style='float:left'>" +
              document.getElementById("bodyText_" + i).value +
              "</div>"
          );
          body.push(
            "<div style='float:right'>" +
              document.getElementById("bodyText2_" + i).value +
              "</div><br></br>"
          );
        } else {
          body.push(
            document.getElementById("bodyText_" + i).value + "<br></br>"
          );
        }

        if (document.getElementsByName("line_" + i)[0]) {
          if (document.getElementsByName("line_" + i)[0].checked === true) {
            body.push("<hr style='width:100%; margin-top:-15px;'></hr>");
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        const obj = { btnName: document.getElementById("btnName" + i).value };
        const json = JSON.stringify(obj);
        btn.push(obj);
      }
    }
    setTemplateBody(body);
    setTemplateBtn(btn);

    event.preventDefault();
    handlePopup(true);
  };
  const handleCancle = (e) => {
    e.preventDefault();
    const data = {
      search3: templateId,
      search4: brandId,
    };

    if (
      window.confirm("승인 요청을 취소하시겠습니까?\n템플릿 ID : " + templateId)
    ) {
      getPostFetch("/rcs/templateApproveReqCancle", data)
        .then((result) => {
          if (result.resultCode === "0") {
            alert("승인 요청이 취소되었습니다.");
            navigate("/rcs/rcsTemplate");
          } else {
            alert("");
          }
        })
        .catch((error) => alert(error));
    }
  };
  const handleGoList = (event) => {
    event.preventDefault();
    navigate("/rcs/rcsTemplate");
  };

  const handleSave = (event) => {
    event.preventDefault();

    let brandId = "";
    let cardType = "";
    let cardKind = "";
    let templateNm = "";

    const body = [];
    const btn = [];

    if (document.getElementById("brandNm")) {
      brandId = document.getElementById("brandNm").value;
    }

    if (brandId === "" || brandId === "발신브랜드 선택") {
      alert("브랜드 ID를 선택하세요");
      return;
    }

    if (document.getElementById("cardKind")) {
      cardKind = document.getElementById("cardKind").value;
    }

    if (document.getElementsByName("cardType")[0].checked) {
      cardType = "1";
    } else {
      cardType = "2";
    }

    if (document.getElementById("templateName")) {
      templateNm = document.getElementById("templateName").value;
    }

    if (templateNm === "") {
      alert("템플릿 명을 입력하세요.");
      return;
    }

    for (var i = 0; i < 999; i++) {
      if (document.getElementById("bodyText_" + i)) {
        if (document.getElementById("bodyText2_" + i)) {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 왼쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementById("bodyText2_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용 오른쪽 칸은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementsByName("line_" + i)[0]) {
            if (document.getElementsByName("line_" + i)[0].checked === true) {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                text2: document.getElementById("bodyText2_" + i).value,
                line: true,
              };
              body.push(obj);
            } else {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                text2: document.getElementById("bodyText2_" + i).value,
                line: false,
              };
              body.push(obj);
            }
          } else {
            const obj = {
              text1: document.getElementById("bodyText_" + i).value,
              text2: document.getElementById("bodyText2_" + i).value,
              line: false,
            };
            body.push(obj);
          }
        } else {
          if (document.getElementById("bodyText_" + i).value.length > 90) {
            alert(i + 1 + "번째 내용은 90자를 초과하지 못합니다.");
            return;
          }

          if (document.getElementsByName("line_" + i)[0]) {
            if (document.getElementsByName("line_" + i)[0].checked === true) {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                line: true,
              };
              body.push(obj);
            } else {
              const obj = {
                text1: document.getElementById("bodyText_" + i).value,
                line: false,
              };
              body.push(obj);
            }
          } else {
            const obj = {
              text1: document.getElementById("bodyText_" + i).value,
              line: false,
            };
            body.push(obj);
          }
        }
      }
    }

    for (var i = 0; i < 2; i++) {
      if (document.getElementById("btnName" + i)) {
        if (document.getElementById("btnName" + i).value === "") {
          alert(i + 1 + "번째 버튼명을 입력하세요.");
          return;
        } else if (document.getElementById("btnName" + i).value.length > 17) {
          alert(i + 1 + "번째 버튼명은 17자를 초과하지 못합니다.");
        }

        let info1 = "";
        let info2 = "";
        let info3 = "";
        let info4 = "";

        if (document.getElementById("btnInfo" + 1 + "_" + i)) {
          if (document.getElementById("btnInfo" + 1 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info1 = document.getElementById("btnInfo" + 1 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 2 + "_" + i)) {
          if (document.getElementById("btnInfo" + 2 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info2 = document.getElementById("btnInfo" + 2 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 3 + "_" + i)) {
          if (document.getElementById("btnInfo" + 3 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info3 = document.getElementById("btnInfo" + 3 + "_" + i).value;
        }
        if (document.getElementById("btnInfo" + 4 + "_" + i)) {
          if (document.getElementById("btnInfo" + 4 + "_" + i).value === "") {
            alert(i + 1 + "번째 버튼 내용을 입력하세요.");
            return;
          }
          info4 = document.getElementById("btnInfo" + 4 + "_" + i).value;
        }

        const obj = {
          btnType: document.getElementById("select" + i).value,
          btn: document.getElementById("btnName" + i).value,
          info1: info1,
          info2: info2,
          info3: info3,
          info4: info4,
        };
        btn.push(obj);
      }
    }

    const url = "/rcs/templateUpdate/temp";
    const data = {
      brandId: brandId,
      cardType: cardType,
      cardKind: cardKind,
      templateName: templateNm,
      templateBody: body,
      templateBtn: btn,
      templateId: tempTemplateSeq,
    };
    if (window.confirm("템플릿을 임시 저장하시겠습니까?")) {
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);

          /* if (result.resultCode === "0") {
            navigate("/rcs/rcsTemplate");
          } */
        })
        .catch((error) => alert(error));
    }
  };

  const handleDelete = (e) => {
    const data = {
      search3: templateId,
      search4: brandId,
      search5: tempTemplateSeq,
    };

    let msg = "";
    if (templateId == undefined) {
      msg = "템플릿을 삭제하시겠습니까? (임시 저장 템플릿)";
    } else {
      msg = "템플릿을 삭제하시겠습니까?\n템플릿 ID : " + templateId;
    }

    if (window.confirm(msg)) {
      getPostFetch("/rcs/templateDelete", data)
        .then((result) => {
          alert(result.resultMsg);
          navigate("/rcs/rcsTemplate");
        })
        .catch((error) => alert(error));
    } else {
    }
    e.preventDefault();
  };

  const changeHandler = (checked, id) => {
    if (checked) {
      setCheck("Y");
    } else {
      setCheck("N");
    }
  };

  return (
    <>
      <div className="page-stitle">템플릿 내용</div>
      <form>
        <table className="table_style1">
          <thead></thead>
          <tbody>
            {/* 대표계정일 때만 노출 */}
            {custType === "1" ? (
              <tr>
                <th>계정 ID</th>
                <td>{data[0].custId}</td>
              </tr>
            ) : null}
            {/* 대표계정일 때만 노출 end */}
            <RcsTemplateUploadType1 brandIdList={brandIdList} data={data} />
            {/* 등록 시는 숨김처리, 저장 또는 승인요청 이후 목록에서 클릭하여 상세페이지 전환시에만 노출 */}
            <tr>
              <th>등록일</th>
              <td>
                {data[0].regDt} / {data[0].userNm}
              </td>
            </tr>
            {templateId != undefined ? (
              <tr>
                <th>승인요청일</th>
                <td>
                  {data[0].regDt} / {data[0].userNm}
                </td>
              </tr>
            ) : null}
            {/* 등록 시는 숨김처리, 저장 또는 승인요청 이후 목록에서 클릭하여 상세페이지 전환시에만 노출 end */}
          </tbody>
          <tfoot></tfoot>
        </table>
        {/* 템플릿 목록에서 '저장'상태인 템플릿의 상세보기 클릭 시만 노출 */}
        {templateStatus == "저장" ||
        templateStatus == "반려" ||
        templateStatus == "승인" ||
        (templateId == undefined && myCustId === data[0].custId) ? (
          <div>
            <input
              type="checkbox"
              id="rcsTempleteAgree"
              name="rcsTempleteAgree"
              className="check"
              onChange={(e) => {
                changeHandler(e.currentTarget.checked, "rcsTempleteAgree");
              }}
            />
            <label
              for="rcsTempleteAgree"
              className={handleAgree ? "checked" : null}
            >
              정보성 메시징만 보낼 수 있으며, 광고 등 정책에 위배되는 메시징
              발송 시 템플릿 사용이 중지될 수 있음을 동의합니다.
              {/* 승인 요청 전 필수 체크 */}
            </label>
          </div>
        ) : null}
        {/* 템플릿 목록에서 '저장'상태인 템플릿의 상세보기 클릭 시만 노출 end */}
        <div className="button-wrap lfloat">
          <Button
            buttonName="button-border-gray"
            buttonValue="목록"
            onChangePage={handleGoList}
            /* 템플릿 목록으로 돌아갑니다 
              모든 상태일 때 노출됩니다.(항상)
            */
          />

          <Button
            buttonName="button-border-gray"
            buttonValue="미리보기"
            onChangePage={handlePreview}
            /* 모든 상태일 때 노출됩니다.(항상)
             */
          />
          {(templateStatus == undefined || templateStatus == "반려") &&
          myCustId === data[0].custId ? (
            <Button
              buttonName="button-border-blue"
              buttonValue="임시저장"
              onChangePage={handleSave}
            />
          ) : null}
          {(templateStatus == undefined ||
            templateStatus == "반려" ||
            templateStatus == "저장") &&
          myCustId === data[0].custId ? (
            <Button
              buttonName="button-border-gray"
              buttonValue="삭제"
              onChangePage={handleDelete}
              /* 상태가 '반려', '저장' 일 때만 노출됩니다. alert 확인 후 목록으로 돌아갑니다.
               */
            />
          ) : null}
          {templateStatus === "승인대기" && myCustId === data[0].custId ? (
            <Button
              buttonName="button-bgc-blue"
              buttonValue="승인요청 취소"
              onChangePage={handleCancle}
              /* 상태가 '승인대기' 일 때만 노출됩니다. 클릭 후 목록으로 돌아갑니다.
               */
            />
          ) : null}
          {templateStatus == undefined && myCustId === data[0].custId ? (
            <Button
              buttonName="button-bgc-blue"
              buttonValue="승인 요청"
              onChangePage={
                handleUpload
              } /* 상태가 '반려','저장'일 때만 노출됩니다. 클릭 후 목록으로 돌아갑니다. */
            />
          ) : null}
          {templateStatus == "승인" && myCustId === data[0].custId ? (
            <Button
              buttonName="button-bgc-blue"
              buttonValue="수정"
              onChangePage={handleUpdate}
            />
          ) : null}
          {(templateStatus == "반려" || templateStatus == "저장") &&
          myCustId === data[0].custId ? (
            <Button
              buttonName="button-bgc-blue"
              buttonValue="승인 요청"
              onChangePage={handleUpdate}
            />
          ) : null}
        </div>
      </form>
      {popup && (
        <PopUpLayout
          onClose={handlePopup}
          poptitle="템플릿 미리보기2"
          size="small"
        >
          <LayerPopRCSTemplatePreview
            previewType={"R"}
            body={templateBody}
            btn={templateBtn}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function RequestAccountTable(props) {
  const tableRows = props.tableRows;
  const style = {
    eclipse: {
      maxWidth: "250px",
    },
  };
  const [defaultValue, setdefaultValue] = useState(1);
  const popupClose = props.popupClose;
  const handleSelect = (e, data) => {
    e.preventDefault();
    // setdefaultValue(e.target.defaultValue); // 선택한 라디오의 이미지 URL값 넘기기
    let type = 0;
    if (data.imgType === "친구톡") {
      type = 2;
    } else {
      type = 3;
    }
    popupClose(); // 라디오 선택 시 자동으로 팝업 닫기
  };
  return (
    <table className="table_style2 KakaoFriendList nowrap LoginStep">
      <thead>
        <tr>
          <th>서비스구분</th>
          <th>요청 수량</th>
          <th>요청상태</th>
          <th style={style.eclipse}>등록일</th>
        </tr>
        { tableRows.length === 0
          ? <tr>
              <td colSpan={4}>계정요청 정보가 존재하지 않습니다.</td>
            </tr>
          : null
        }
        {tableRows.map((data, index) => (
          <tr>
            <td>{data.arSdKind}</td>
            <td>{data.arCnt}</td>
            <td>{data.arStatus}</td>
            <td>{data.regDt}</td>
          </tr>
        ))}
      </thead>
    </table>
  );
}
