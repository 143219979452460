import React, { Component } from "react";
import { Button } from "component/Common";
import { TaxbillTable } from "component/Table";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "rsuite";

class TaxBillHistory extends Component {
  render() {
    const useStyles = makeStyles((theme) => ({
      datepicker: {
        margin: 0,
        marginTop: "-6px",
        padding: 15,
      },
    }));
    const handleSearch = (event) => {
      event.preventDefault();
    };

    return (
      <div className="charge">
        <div className="searchbox mt20">
          <label htmlFor="id1">조회기간</label>
          <span className="ml10">
            <DatePicker className={useStyles.datepicker} />
          </span>

          
          <span>분기</span>
          <select name="channel_id" id="">
            <option value="">전체</option>
            <option value="">성공</option>
            <option value="">실패</option>
            <option value="">잔여</option>
          </select>
          <span>발행상태</span>
          <select name="channel_id" id="">
            <option value="">전체</option>
            <option value="">SMS</option>
            <option value="">LMS</option>
            <option value="">MMS</option>
          </select>
          <span>신고상태</span>
          <select name="channel_id" id="">
            <option value="">전체</option>
            <option value="">SMS</option>
            <option value="">LMS</option>
            <option value="">MMS</option>
          </select>
          <Button buttonName="button-bgc-dark bbtn" buttonValue="검색" onChangePage={handleSearch}/>
          
        </div>
        <div>
          <div>
            <TaxbillTable />
          </div>
        </div>
      </div>
    );
  }
}
export default TaxBillHistory;
