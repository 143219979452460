import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

function Loader({ type, color, message }) {
  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ContentWrap>
      <Message>{message}</Message>
      <LoadingIcon>
        <ReactLoading
          type={type}
          color={color}
          height={"30px"}
          width={"30px"}
        />
      </LoadingIcon>
    </ContentWrap>
  );
}
export default Loader;

const ContentWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);

  & > h2 {
    text-align: center;
    display: inline-block;
  }
`;

const Message = styled.h2`
  line-height: 1.2;
`;

const LoadingIcon = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
`;
