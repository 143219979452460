import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { RCSText } from "./SendRCSText";
import { RCSStandAlone } from "./SendRCSStandAlone";
import { RCSCarousel } from "./SendRCSCarousel";
import { RCSTemplate } from "./SendRCSTemplate";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { Button } from "component/Common";
import { postRequest, getRequest } from "component/Axios";
import ReactDOM from "react-dom";
import noimg from "assets/images/noimg.jpg";
import Slider from "react-slick";
import getSliceByByte from "../../../hooks/getSliceByByte";
import getByte from "../../../hooks/getByte";
import {
  LayerPopSendTestMsg, // 테스트전송
  LayerPopSendMsg, // 전송하기
  LayerPopAdvertisingGuide, // 광고문자 전송가이드
  LayerPopLoadMsg, // 문구 불러오기
  LayerPopAccountCreation, // 전용계좌 생성하기 (선불만 해당)
  LayerPopReceiveNumber, // 수신번호 - 엑셀 대량전송
  LayerPopReceiveNumberError, // 수신번호 - 엑셀 대량전송 - 오류검사 - 오류건수
  LayerPopImportAddress, // 수신번호 - 주소록 불러오기
  LayerPopMessageImgUpload,
  LayerPopTestCardPay,
  LayerPopRechargeInfo, // 전송 시 잔액부족 안내
} from "component/popUp/PopUp";
import ReceiveList from "../../../component/sendMessage/receiveList/ReceiveList";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 353,
  },
}));

export const SendRCS = (props) => {
  const [chekedRcsTypeValue, setChekedRcsTypeValue] = useState("RCS 텍스트");
  const [nowBalance, setNowBalance] = useState(0);
  const [brandId, setBrandtId] = useState(""); // RCS 브랜드 ID SELECT
  const [mergeText, setMergeText] = useState([]);
  const [cardCount, setCardCount] = useState("");
  const previewType = props.previewType;

  // RCS 상품 //
  const RCSType = [
    {
      id: "RCS_type1",
      value: "텍스트",
      content: (
        <RCSText
          setChekedRcsTypeValue={setChekedRcsTypeValue}
          setNowBalance={setNowBalance}
        />
      ),
      preview: <PreviewRCS />,
    },
    {
      id: "RCS_type2",
      value: "StandAlone",
      content: (
        <RCSStandAlone
          setChekedRcsTypeValue={setChekedRcsTypeValue}
          setNowBalance={setNowBalance}
        />
      ),
      preview: <PreviewRCS />,
    },
    {
      id: "RCS_type3",
      value: "Carousel",
      content: (
        <RCSCarousel
          cardCount={setCardCount}
          setChekedRcsTypeValue={setChekedRcsTypeValue}
          setNowBalance={setNowBalance}
        />
      ),
      preview: <PreviewRCS />,
    },
    {
      id: "RCS_type4",
      value: "템플릿",
      content: <RCSTemplate />,
      preview: <PreviewRCS />,
    },
  ];

  // 대체문자 관련 //
  const [alternativeCharacter, setAlternativeCharacter] = useState(null);
  const [replaceUse, setReplaceUse] = useState(false);
  const [contractAllSelected, setContractAllSelected] = useState(false);
  const [callbackList, setCallbackList] = useState([]);
  const [alternativeCheck, setAlternativeCheck] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  // 단가 관련 //
  const [replacePrceUnit, setReplacePrceUnit] = useState(0);
  const [replaceKindUnit, setReplaceKindUnit] = useState(0);
  const [rcsTemplateUnit, setRcsTemplateUnit] = useState(0);
  const [rcsSmsUnit, setRcsSmsUnit] = useState(0);
  const [rcsLmsUnit, setRcsLmsUnit] = useState(0);
  const [rcsMmsUnit, setRcsMmsUnit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [rating, setRating] = useState("2");

  // 수신번호 관련 //
  const [receiveCount, setReceiveCount] = useState(0);
  const [viewTableRows, setviewTableRows] = useState([]); // 수신 목록
  const [phoneCount, setPhoneCount] = useState(0);
  const [contentPhone, setContentPhone] = useState([]);
  const [fileRegIdList, setFileRegIdList] = useState([]);

  //주소록 관련
  const [importAddress, popupImportAddress] = useState(false);
  const [selectIdx, setSelectIdx] = useState(0);

  // 엑셀 관련
  const [uploadType, setUploadType] = useState("1");
  const [receiveNumberFile, popupReceiveNumberFile] = useState(false);

  // RCS 상품 Default //
  const [chekedRcsTypeId, setChekedRcsTypeId] = useState("RCS_type1");
  const [activeRcsType, setActiveRcsType] = useState(
    <RCSText
      setChekedRcsTypeValue={setChekedRcsTypeValue}
      setNowBalance={setNowBalance}
    />
  );

  // DATA SET //
  const [infoData, setInfoData] = useState([]);
  const [phone, setPhone] = useState("");
  const [brandIdList, setBrandIdList] = useState([]); // RCS 브랜드 ID
  const [chatbotIdList, setChatbotIdList] = useState([]); // RCS 챗봇 ID
  const [sendTestMsg, popupSendTestMsg] = useState(false); // 테스트 전송
  const [sendMsgConfirm, popupSendMsgConfirm] = useState(false); // 전송하기
  const [chekedId, setChekedId] = React.useState("id3_1");
  const [sendType, setSendType] = useState(null);
  const [contents, setContents] = useState();
  const [templateType, setTemplateType] = useState();
  const [smsPrce, setSmsPrce] = useState();
  const [lmsPrce, setLmsPrce] = useState();
  const [mmsPrce, setMmsPrce] = useState();

  // ETC
  const [selectRegId, setSelectRegId] = useState("");
  const [activeContent, setActiveContent] = React.useState(null); // 전송시간- 예약 datetype

  // 전송시간 관련
  const sendTime = [
    { id: "id3_1", value: "즉시", content: null },
    { id: "id3_2", value: "예약", content: <Reservation /> },
  ];

  // 충전 버튼 관련
  const [charge, setCharge] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState();

  // 잔액 부족 관련
  const [rechargeInfo, popupRechargeInfo] = useState(false); // 전송하기 - 충전금 잔액 부족 안내
  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);

  useEffect(() => {
    handleTemplatePreview();
    handleDefaultSet();
  }, [activeRcsType]);

  useEffect(() => {
    ReactDOM.render(
      <div id="template"></div>,
      document.getElementById("templatePreview")
    );
    document.getElementById("previewSubject").innerHTML = "";
    document.getElementById("previewHeader").innerHTML = "";
    document.getElementById("previewBody").innerHTML = "";
    document.getElementById("previewFooter").innerHTML = "";
    document.getElementById("alternative").checked = false;
    for (let i = 0; i < 3; i++) {
      if (document.getElementById("rcsTextButtonNm" + i)) {
        document.getElementById("rcsTextButtonNm" + i).remove();
      }
    }
    setReplaceKindUnit(0);
    console.log(chekedRcsTypeId);
    if (chekedRcsTypeId === "RCS_type3") {
      previewType("S");
      ReactDOM.render(
        <PreviewRCSSlide cardCount={cardCount} />,
        document.getElementById("previewTest")
      );
    } else {
      previewType("N");
      ReactDOM.render(<></>, document.getElementById("previewTest"));
    }
  }, [chekedRcsTypeId, cardCount]);

  useEffect(() => {
    handleChatbotSet();
    if (chekedRcsTypeId === "RCS_type4") {
      handleTemplatePreview();
    }
  }, [brandId]);

  useEffect(() => {
    handleTemplatePreview();
  }, [template]);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  function getCountTotal() {
    let num = 0;
    for (let i = 1; i <= Number(phoneCount) + 10; i++) {
      const addFile = "filePhone_" + i;
      const addPhone = "addPhone_" + i;
      const fileCnt = "fileCnt_" + i;
      if (document.getElementById(addPhone)) {
        num = Number(num) + 1;
      }

      if (document.getElementById(addFile)) {
        const cnt = document.getElementById(fileCnt).value;
        num = Number(num) + Number(cnt);
      }
    }
    setReceiveCount(num);
  }

  // 브랜드 ID, USER 정보 SET
  const handleDefaultSet = () => {
    const brandIdUrl = "/rcs/brandId/getSelect";
    const userInfoUrl = "/user/userInfo";

    getFetch(brandIdUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          console.log("brandId getSelect", jsonArr);
          setBrandIdList(jsonArr);
          setBrandtId(jsonArr[0].brandId);
        }
      })
      .catch((error) => console.log(error));

    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setDefaultUnit(jsonArr[0]);
          setBalance(jsonArr[0].balance);
          setRating(jsonArr[0].preRating);
          sessionStorage.setItem("pay", jsonArr[0].balance);
        }
      })
      .catch((error) => console.log(error));

    const payUrl = "/user/payInfo";

    getFetch(payUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => console.log(error));

    if (document.getElementById("advertising")) {
      document.getElementById("advertising").checked = false;
    }

    setAlternativeCharacter(null);
    setReplaceUse(false);
    handleChatbotSet();
    setUploadFile(null);
  };

  const handleTemplate = (e) => {
    setTemplate(e.target.value);
  };

  // CHATBOT ID SET
  const handleChatbotSet = () => {
    const data = { search3: brandId };
    getPostFetch("/rcs/chatbotId/getSelect", data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChatbotIdList(jsonArr);
        }
      })
      .catch((error) => console.log(error));

    getPostFetch("/rcs/template/getSelect", data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTemplateList(jsonArr);
          setTemplate(jsonArr[0].messagebaseId);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleContents = (text) => {
    const maxByte = 1300;
    const text_val = text;

    let totalByte = 0;
    totalByte += text_val.length;
    let test = contents + text;

    if (totalByte > maxByte) {
      alert("최대 1300자까지만 입력가능합니다.");
    } else {
      setContents(text);
      document.getElementById("previewBody").innerHTML = text;
    }
  };

  const handleTemplatePreview = () => {
    //document.getElementById("previewBody").innerHTML = "";

    setContents();
    if (chekedRcsTypeId === "RCS_type4") {
      const data = { search3: template };
      getPostFetch("/rcs/template/getInfo", data).then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);

          if (jsonArr[0].templateDescription === "free") {
            setTemplateType("free");
            ReactDOM.render(
              <div id="templateBtn"></div>,
              document.getElementById("previewBtnList")
            );
            ReactDOM.render(
              <div id="template"></div>,
              document.getElementById("templatePreview")
            );

            ReactDOM.render(
              <div>
                <div id="msgArea" className="write-input-con change-effect-bg">
                  <textarea
                    name=""
                    id="msgContents"
                    className="gs-custom-textarea change-effect"
                    placeholder="RCS Free 템플릿 입니다."
                    maxlength="2000"
                    onChange={(e) => {
                      handleContents(e.target.value);
                    }}
                    value={contents}
                  ></textarea>
                  <div className="write-input-down-con">
                    <div id="stringTemplate"></div>
                    <div className="write-input-down-info">
                      <span className="msgType">RCS 템플릿</span>
                    </div>
                  </div>
                </div>
              </div>,
              document.getElementById("templateType")
            );

            ReactDOM.render(
              <div id="mergeSelect"></div>,
              document.getElementById("asdasd")
            );
          } else {
            setTemplateType("another");
            ReactDOM.render(
              <div>
                <div id="msgArea" className="write-input-con change-effect-bg">
                  <div className="write-input-down-con">
                    <div id="stringTemplate"></div>
                    <div className="write-input-down-info">
                      <span className="msgType">RCS 템플릿</span>
                    </div>
                  </div>
                </div>
                <p className="red mt10">
                  * 변수설정 영역만 수정이 가능합니다. 메시지 발송 전 내용을
                  확인해주세요
                </p>
              </div>,
              document.getElementById("templateType")
            );

            let body = "";

            for (let i = 0; i < jsonArr[0].templateDescription.length; i++) {
              body += jsonArr[0].templateDescription[i];
            }

            var btnNameRows = [];

            for (let i = 0; i < jsonArr[0].templateBtn.length; i++) {
              btnNameRows.push(jsonArr[0].templateBtn[i].btnName);
            }
            ReactDOM.render(
              <div>
                {btnNameRows.map((row, index) => (
                  <div
                    id={"templateBtn" + index}
                    style={{
                      width: "100%",
                      padding: "7px 0",
                      textAlign: "center",
                      backgroundColor: "#f5f4f6",
                      color: "#222",
                      marginBottom: "7px",
                      borderRadius: "3px",
                      fontSize: "14px",
                    }}
                    dangerouslySetInnerHTML={{ __html: row }}
                    key={index}
                  ></div>
                ))}
              </div>,
              document.getElementById("previewBtnList")
            );

            const text = body;
            const regText = /\{.*?\}}/g;

            let matchText = text.match(regText);

            //console.log(matchText);
            const set = new Set(matchText);

            const uniqueArr = [...set];

            const idMerge = [
              "merge_0",
              "merge_1",
              "merge_2",
              "merge_3",
              "merge_4",
            ];

            const idName = [
              "mergeValue_0",
              "mergeValue_1",
              "mergeValue_2",
              "mergeValue_3",
              "mergeValue_4",
            ];
            const idChoice = [
              "mergeChoice_0",
              "mergeChoice_1",
              "mergeChoice_2",
              "mergeChoice_3",
              "mergeChoice_4",
            ];
            ReactDOM.render(
              <div id="mergeSelect">
                <br></br>
                {uniqueArr.map((row, index) => (
                  <div name="mergeCnt" key={index}>
                    <input
                      type="hidden"
                      id={"mergeValue_" + index}
                      value={row}
                    />
                    <div className="tit-left select-tit">
                      <span style={{ fontSize: "15px" }} className="required">
                        {row}
                      </span>
                    </div>
                    <div className="tit-right mb10">
                      <select className="wid100" id={"mergeChoice_" + index}>
                        <option value="" disabled selected>
                          머지항목
                        </option>
                        <option value="NAME">#이름</option>
                        <option value="ETC1">#1</option>
                        <option value="ETC2">#2</option>
                        <option value="ETC3">#3</option>
                        <option value="ETC4">#4</option>
                      </select>
                    </div>
                  </div>
                ))}
              </div>,
              document.getElementById("asdasd")
            );
            document.getElementById("stringTemplate").innerHTML = body;

            ReactDOM.render(
              <div
                id="template"
                dangerouslySetInnerHTML={{ __html: body }}
              ></div>,
              document.getElementById("templatePreview")
            );
          }
        }
      });
    } else {
      ReactDOM.render(
        <div id="templateBtn"></div>,
        document.getElementById("previewBtnList")
      );
      ReactDOM.render(
        <div id="template"></div>,
        document.getElementById("templatePreview")
      );
    }
  };

  // 단가 SET
  const setDefaultUnit = (userData) => {
    const monthLimit = userData.monthLimit;
    const monthSend = userData.monthSend;
    const dayLimit = userData.dayLimit;
    const daySend = userData.daySend;
    const preRating = userData.preRating;
    const balance = userData.balance;

    if (userData.smsPrce === undefined) {
      setSmsPrce(0);
    } else {
      setSmsPrce(userData.smsPrce);
    }

    if (userData.lmsPrce === undefined) {
      setLmsPrce(0);
    } else {
      setLmsPrce(userData.lmsPrce);
    }

    if (userData.mmsPrce === undefined) {
      setMmsPrce(0);
    } else {
      setMmsPrce(userData.mmsPrce);
    }

    let replaceBalnce = 0;
    let rcsTemplateCnt = 0;
    let rcsSmsCnt = 0;
    let rcsLmsCnt = 0;
    let rcsMmsCnt = 0;

    let rcsTmplPrce = userData.rcsTmplPrce;
    let rcsSmsPrce = userData.rcsSmsPrce;
    let rcsLmsPrce = userData.rcsLmsPrce;
    let rcsMmsPrce = userData.rcsMmsPrce;

    if (preRating === "1") {
      if (monthLimit === 0 && dayLimit === 0) {
        rcsTemplateCnt = "∞";
        rcsSmsCnt = "∞";
        rcsLmsCnt = "∞";
        rcsMmsCnt = "∞";
      } else if (monthLimit != 0 && dayLimit === 0) {
        rcsTemplateCnt = Math.floor(
          (monthLimit - monthSend) / (rcsTmplPrce * 1.1)
        );
        rcsSmsCnt = Math.floor((monthLimit - monthSend) / (rcsSmsPrce * 1.1));
        rcsLmsCnt = Math.floor((monthLimit - monthSend) / (rcsLmsPrce * 1.1));
        rcsMmsCnt = Math.floor((monthLimit - monthSend) / (rcsMmsPrce * 1.1));
      } else if (monthLimit === 0 && dayLimit != 0) {
        rcsTemplateCnt = Math.floor((dayLimit - daySend) / (rcsTmplPrce * 1.1));
        rcsSmsCnt = Math.floor((dayLimit - daySend) / (rcsSmsPrce * 1.1));
        rcsLmsCnt = Math.floor((dayLimit - daySend) / (rcsLmsPrce * 1.1));
        rcsMmsCnt = Math.floor((dayLimit - daySend) / (rcsMmsPrce * 1.1));
      } else {
        if (monthLimit - monthSend >= dayLimit - daySend) {
          rcsTemplateCnt = Math.floor(
            (dayLimit - daySend) / (rcsTmplPrce * 1.1)
          );
          rcsSmsCnt = Math.floor((dayLimit - daySend) / (rcsSmsPrce * 1.1));
          rcsLmsCnt = Math.floor((dayLimit - daySend) / (rcsLmsPrce * 1.1));
          rcsMmsCnt = Math.floor((dayLimit - daySend) / (rcsMmsPrce * 1.1));
        } else {
          rcsTemplateCnt = Math.floor(
            (monthLimit - monthSend) / (rcsTmplPrce * 1.1)
          );
          rcsSmsCnt = Math.floor((monthLimit - monthSend) / (rcsSmsPrce * 1.1));
          rcsLmsCnt = Math.floor((monthLimit - monthSend) / (rcsLmsPrce * 1.1));
          rcsMmsCnt = Math.floor((monthLimit - monthSend) / (rcsMmsPrce * 1.1));
        }
      }
    }

    if (preRating === "2") {
      if (monthLimit != 0 || dayLimit != 0) {
        if (monthLimit === 0 && dayLimit != 0) {
          if (balance <= dayLimit) {
            replaceBalnce = balance;
          } else {
            replaceBalnce = dayLimit;
          }
        } else if (monthLimit != 0 && dayLimit === 0) {
          if (balance <= monthLimit) {
            replaceBalnce = balance;
          } else {
            replaceBalnce = monthLimit;
          }
        } else {
          if (balance <= monthLimit && balance <= dayLimit) {
            replaceBalnce = balance;
          } else {
            if (monthLimit <= dayLimit) {
              replaceBalnce = monthLimit;
            } else {
              replaceBalnce = dayLimit;
            }
          }
        }
      }
      if (monthLimit === 0 && dayLimit === 0) {
        rcsTemplateCnt = Math.floor(balance / (rcsTmplPrce * 1.1));
        rcsSmsCnt = Math.floor(balance / (rcsSmsPrce * 1.1));
        rcsLmsCnt = Math.floor(balance / (rcsLmsPrce * 1.1));
        rcsMmsCnt = Math.floor(balance / (rcsMmsPrce * 1.1));
      } else if (monthLimit != 0 && dayLimit === 0) {
        rcsTemplateCnt = Math.floor(
          (replaceBalnce - monthSend) / (rcsTmplPrce * 1.1)
        );
        rcsSmsCnt = Math.floor(
          (replaceBalnce - monthSend) / (rcsSmsPrce * 1.1)
        );
        rcsLmsCnt = Math.floor(
          (replaceBalnce - monthSend) / (rcsLmsPrce * 1.1)
        );
        rcsMmsCnt = Math.floor(
          (replaceBalnce - monthSend) / (rcsMmsPrce * 1.1)
        );
      } else if (monthLimit === 0 && dayLimit != 0) {
        rcsTemplateCnt = Math.floor((balance - daySend) / (rcsTmplPrce * 1.1));
        rcsSmsCnt = Math.floor((balance - daySend) / (rcsSmsPrce * 1.1));
        rcsLmsCnt = Math.floor((balance - daySend) / (rcsLmsPrce * 1.1));
        rcsMmsCnt = Math.floor((balance - daySend) / (rcsMmsPrce * 1.1));
      } else {
        if (monthLimit - monthSend >= dayLimit - daySend) {
          if (balance <= monthLimit - monthSend) {
            rcsTemplateCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (rcsTmplPrce * 1.1)
            );
            rcsSmsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (rcsSmsPrce * 1.1)
            );
            rcsLmsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (rcsLmsPrce * 1.1)
            );
            rcsMmsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (rcsMmsPrce * 1.1)
            );
          } else {
            rcsTemplateCnt = Math.floor(
              (dayLimit - daySend) / (rcsTmplPrce * 1.1)
            );
            rcsSmsCnt = Math.floor((dayLimit - daySend) / (rcsSmsPrce * 1.1));
            rcsLmsCnt = Math.floor((dayLimit - daySend) / (rcsLmsPrce * 1.1));
            rcsMmsCnt = Math.floor((dayLimit - daySend) / (rcsMmsPrce * 1.1));
          }
        } else {
          if (balance <= monthLimit - monthSend) {
            rcsTemplateCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (rcsTmplPrce * 1.1)
            );
            rcsSmsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (rcsSmsPrce * 1.1)
            );
            rcsLmsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (rcsLmsPrce * 1.1)
            );
            rcsMmsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (rcsMmsPrce * 1.1)
            );
          } else {
            rcsTemplateCnt = Math.floor(
              (monthLimit - monthSend) / (rcsTmplPrce * 1.1)
            );
            rcsSmsCnt = Math.floor(
              (monthLimit - monthSend) / (rcsSmsPrce * 1.1)
            );
            rcsLmsCnt = Math.floor(
              (monthLimit - monthSend) / (rcsLmsPrce * 1.1)
            );
            rcsMmsCnt = Math.floor(
              (monthLimit - monthSend) / (rcsMmsPrce * 1.1)
            );
          }
        }
      }
    }

    if (rcsTemplateCnt < 1) {
      rcsTemplateCnt = 0;
    }
    if (rcsSmsCnt < 1) {
      rcsSmsCnt = 0;
    }
    if (rcsLmsCnt < 1) {
      rcsLmsCnt = 0;
    }
    if (rcsMmsCnt < 1) {
      rcsMmsCnt = 0;
    }

    if (rcsTemplateCnt === Infinity) {
      rcsTemplateCnt = "∞";
    }
    if (rcsSmsCnt === Infinity) {
      rcsSmsCnt = "∞";
    }
    if (rcsLmsCnt === Infinity) {
      rcsLmsCnt = "∞";
    }
    if (rcsMmsCnt === Infinity) {
      rcsMmsCnt = "∞";
    }

    if (rcsTemplateCnt != "∞" && isNaN(rcsTemplateCnt)) {
      rcsTemplateCnt = 0;
    }

    if (rcsSmsCnt != "∞" && isNaN(rcsSmsCnt)) {
      rcsSmsCnt = 0;
    }

    if (rcsLmsCnt != "∞" && isNaN(rcsLmsCnt)) {
      rcsLmsCnt = 0;
    }

    if (rcsMmsCnt != "∞" && isNaN(rcsMmsCnt)) {
      rcsMmsCnt = 0;
    }

    rcsTemplateCnt = userData.rcsTmplPrce === undefined ? 0 : rcsTemplateCnt;
    rcsSmsCnt = userData.rcsSmsPrce === undefined ? 0 : rcsSmsCnt;
    rcsLmsCnt = userData.rcsLmsPrce === undefined ? 0 : rcsLmsCnt;
    rcsMmsCnt = userData.rcsMmsPrce === undefined ? 0 : rcsMmsCnt;

    setRcsTemplateUnit(rcsTemplateCnt);
    setRcsSmsUnit(rcsSmsCnt);
    setRcsLmsUnit(rcsLmsCnt);
    setRcsMmsUnit(rcsMmsCnt);

    if (chekedRcsTypeId === "RCS_type1") {
      setChekedRcsTypeValue("RCS SMS");
      if (userData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(userData.rcsSmsPrce);
      }
      ReactDOM.render(
        <div id="previewImg"></div>,
        document.getElementById("previewImg")
      );
    } else if (chekedRcsTypeId === "RCS_type2") {
      setChekedRcsTypeValue("RCS MMS");
      if (userData.rcsMmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(userData.rcsMmsPrce);
      }
    } else if (chekedRcsTypeId === "RCS_type3") {
      setChekedRcsTypeValue("RCS MMS");
      if (userData.rcsMmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(userData.rcsMmsPrce);
      }
      ReactDOM.render(
        <div id="previewImg"></div>,
        document.getElementById("previewImg")
      );
    } else if (chekedRcsTypeId === "RCS_type4") {
      setChekedRcsTypeValue("RCS 템플릿");
      if (userData.rcsTmplPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(userData.rcsTmplPrce);
      }
      ReactDOM.render(
        <div id="previewImg"></div>,
        document.getElementById("previewImg")
      );
    }
  };

  // BRAND ID SELECT
  const handleBrandId = (e) => {
    setBrandtId(e.target.value);
  };

  function getTestSendMsg(event) {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("T");
    if (balance < nowBalance * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendTestMsg(true);
    }
  }
  const SendMsgConfirm = (event) => {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("R");
    if (balance < nowBalance * receiveCount * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendMsgConfirm(true);
    }
  };

  // 수신번호가 1만건 이상일 때 발신번호 인증
  const handleCertification = (event) => {
    event.preventDefault();
    console.log("발신번호 인증");
  };

  function getCheckboxValue(event) {
    if (event.target.checked) {
      setAlternativeCharacter(
        <AlternativeCharacter
          setReplacePrceUnit={setReplacePrceUnit}
          setReplaceKindUnit={setReplaceKindUnit}
          callbackList={callbackList}
          handleAlternative={handleAlternative}
          setUploadFile={setUploadFile}
        />
      );
      setReplaceUse(true);
    } else {
      setReplaceKindUnit(null);
      setAlternativeCharacter(null);
      setReplaceUse(false);
      setUploadFile(null);
    }
  }

  const handleAlternative = (val) => {
    setAlternativeCheck(val);
  };

  // 콤마
  function setComma(num) {
    if (num > 999) {
      const cn = num
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return cn;
    } else {
      return num.toFixed(2);
    }
  }

  // 충전버튼
  const showChargeAccount = () => {
    if (charge) {
      setCharge(false);
    } else {
      setCharge(true);
    }
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    const userInfoUrl = "/user/userInfo";
    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setDefaultUnit(jsonArr[0]);
          setBalance(jsonArr[0].balance);
          setRating(jsonArr[0].preRating);
          sessionStorage.setItem("pay", jsonArr[0].balance);
        }
      })
      .catch((error) => console.log(error));
  };

  const closeSendMsgPop = () => {
    popupSendMsgConfirm(false);
  };

  const closeSendMsgTestPop = () => {
    popupSendTestMsg(false);
  };

  const closeInsufficientBalancePop = () => {
    popupRechargeInfo(false);
  };

  function handelDefaultSet() {
    for (let i = 1; i <= phoneCount; i++) {
      const addPhone = "addPhone_" + i;
      const addFile = "filePhone_" + i;
      if (document.getElementById(addPhone))
        document.getElementById(addPhone).remove();

      if (document.getElementById(addFile))
        document.getElementById(addFile).remove();
    }

    if (document.getElementById("file")) {
      document.getElementById("file").value = "";
    }

    if (document.getElementById("rcsMmsImg")) {
      document.getElementById("rcsMmsImg").src = noimg;
    }

    if (document.getElementById("subject")) {
      document.getElementById("subject").value = "";
    }
    if (document.getElementById("msgContents")) {
      document.getElementById("msgContents").innerHTML = "";
    }

    if (document.getElementById("adTitle")) {
      document.getElementById("adTitle").value = "";
    }
    if (document.getElementById("adNumber")) {
      document.getElementById("adNumber").value = "";
    }

    document.getElementById("previewSubject").innerHTML = "";
    document.getElementById("previewHeader").innerHTML = "";
    document.getElementById("previewBody").innerHTML = "";
    document.getElementById("previewFooter").innerHTML = "";
    for (let i = 0; i <= 6; i++) {
      if (document.getElementById("button" + i)) {
        document.getElementById("button" + i).remove();
        document.getElementById("rcsTextButtonNm" + i).remove();
      }
    }

    handleDefaultSet();
    setChekedRcsTypeId("RCS_type1");
    setActiveRcsType(
      <RCSStandAlone
        setChekedRcsTypeValue={setChekedRcsTypeValue}
        setNowBalance={setNowBalance}
      />
    );
    setActiveRcsType(
      <RCSText
        setChekedRcsTypeValue={setChekedRcsTypeValue}
        setNowBalance={setNowBalance}
      />
    );
    /* 이미지 */
    setUploadFile(null);
    if (document.getElementById("imagePreviewSrc"))
      document.getElementById("imagePreviewSrc").src = "";

    if (document.getElementById("imgFileName"))
      document.getElementById("imgFileName").value = "";

    handleContents("");

    /* 광고 */
    setContractAllSelected(false);

    /* 발송시간 */
    setChekedId("id3_1");
    setActiveContent(null);

    //getCountTotal();
    handleContents("");

    // 수신목록 초기화
    setContentPhone([]);
    setFileRegIdList([]);
    setviewTableRows([]);
    setPhone("");
  }

  const sendMsg = (testYn, numPhone) => {
    let phoneList = [];
    let fileList = [];

    if (testYn === "Y") {
      phoneList.push(numPhone);
    } else if (testYn === "N") {
      phoneList = contentPhone;
      fileList = fileRegIdList;
    }

    if (testYn === "N" && receiveCount === 0) {
      alert("메시지를 발송할 수신번호가 존재하지 않습니다.");
      return;
    }

    const callbackNum = document.getElementById("callbackList").value;

    if (callbackNum == 0) {
      alert("발신번호를 등록하세요.");
      return;
    }

    if (!callbackNum) {
      alert("발신번호를 선택해 주세요.");
      return;
    }

    let advertYn = "N";
    let adNumber = "";

    // 카드 한장
    if (document.getElementById("advertising")) {
      const adCheck = document.getElementById("advertising").checked;

      if (adCheck) {
        advertYn = "Y";
        const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
        adNumber = document.getElementById("adNumber").value;
        if (!adNumber) {
          alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
          return;
        } else if (!regexMobile.test(adNumber)) {
          alert("잘못된 형식의 080 번호 입니다. 입니다.");
          return;
        }
      }
    } else {
      for (let i = 1; i <= 6; i++) {
        if (document.getElementById("advertising" + i)) {
          const adCheck = document.getElementById("advertising" + i).checked;
          if (adCheck) {
            advertYn = "Y";
            const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
            adNumber = document.getElementById("adNumber" + i).value;
            if (!adNumber) {
              alert(
                i +
                  "번째 카드 광고 메시지의 080 무료거부번호는 필수입력사항 입니다."
              );
              return;
            } else if (!regexMobile.test(adNumber)) {
              alert(i + "번째 카드 잘못된 형식의 080 번호 입니다. 입니다.");
              return;
            }
          }

          if (document.getElementById("adNumber" + i)) {
            break;
          }
        }
      }
    }

    let previewHeader = "";
    let previewBody = "";
    let previewFooter = "";

    let adTitle = "";

    let textLength = "";
    let text = "";
    let subject = "";
    let msgType = "";
    let replaceText = "";
    let mergeCheck = "N";
    previewHeader = document.getElementById("previewHeader").innerText.length;
    previewBody = document.getElementById("previewBody").innerText.length;
    previewFooter = document.getElementById("previewFooter").innerText.length;
    textLength = previewHeader + previewBody + previewFooter;

    if (document.getElementById("subject")) {
      if (document.getElementById("subject").value != "") {
        subject = document.getElementById("subject").value;
      } else {
        subject = null;
      }
    }

    if (document.getElementById("adTitle")) {
      text += document.getElementById("adTitle").value + "\n";
    }
    text += document.getElementById("previewBody").innerText;

    if (document.getElementById("replaceAdReTitle")) {
      replaceText +=
        "(광고) " + document.getElementById("replaceAdReTitle").value + "\n";
    }

    if (document.getElementById("replaceText")) {
      replaceText += document.getElementById("replaceText").value;
    }

    if (document.getElementById("replaceAdNumber")) {
      replaceText +=
        "\n" + "무료거부 " + document.getElementById("replaceAdNumber").value;
    }

    let btnList = [];
    let btnList2 = [];
    if (document.getElementsByName("btnType")) {
      for (let i = 0; i < document.getElementsByName("btnType").length; i++) {
        if (document.getElementsByName("btnNm")[i].value === "") {
          alert(
            document.getElementsByName("btnType")[i].innerHTML +
              " 버튼의 버튼명을 입력하세요."
          );
          return;
        }

        if (document.getElementsByName("btnType")[i].innerHTML === "URL연결") {
          if (
            document
              .getElementsByName("btnInfo1")
              [i].value.indexOf("https://") === -1
          ) {
            alert(
              "URL연결 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
            );
            return;
          }
        }

        if (document.getElementsByName("btnType")[i].innerHTML === "전화걸기") {
          if (document.getElementsByName("btnInfo1")[i].value === "") {
            alert("전화걸기 버튼의 전화번호를 입력하세요.");
            return;
          }
        }

        if (document.getElementsByName("btnType")[i].innerHTML === "복사하기") {
          if (document.getElementsByName("btnInfo1")[i].value === "") {
            alert("복사하기 버튼의 내용을 입력하세요.");
            return;
          }
        }

        if (
          document.getElementsByName("btnType")[i].innerHTML ===
          "지도보여주기(좌표)"
        ) {
          if (document.getElementsByName("btnInfo1")[i].value === "") {
            alert("지도보여주기(좌표) 버튼의 위도를 입력하세요.");
            return;
          }
          if (document.getElementsByName("btnInfo2")[i].value === "") {
            alert("지도보여주기(좌표) 버튼의 경도를 입력하세요.");
            return;
          }
          if (document.getElementsByName("btnInfo3")[i].value === "") {
            alert("지도보여주기(좌표) 버튼의 위치이름을 입력하세요.");
            return;
          }

          if (
            document
              .getElementsByName("btnInfo4")
              [i].value.indexOf("https://") === -1
          ) {
            alert(
              "지도보여주기(좌표) 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
            );
            return;
          }
        }

        if (
          document.getElementsByName("btnType")[i].innerHTML ===
          "지도보여주기(쿼리)"
        ) {
          if (document.getElementsByName("btnInfo1")[i].value === "") {
            alert("지도보여주기(쿼리) 버튼의 위치이름을 입력하세요.");
            return;
          }

          if (
            document
              .getElementsByName("btnInfo2")
              [i].value.indexOf("https://") === -1
          ) {
            alert(
              "지도보여주기(쿼리) 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
            );
            return;
          }
        }

        if (
          document.getElementsByName("btnType")[i].innerHTML === "캘린더등록"
        ) {
          var pattern =
            /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/;

          if (
            pattern.test(document.getElementsByName("btnInfo1")[i].value) ===
            false
          ) {
            alert(
              "캘린더등록 버튼의 시작일 일자 형식이 잘못되었습니다.\nex) 2022-03-30 00:00:00"
            );
            return;
          }
          if (
            pattern.test(document.getElementsByName("btnInfo2")[i].value) ===
            false
          ) {
            alert(
              "캘린더등록 버튼의 종료일 일자 형식이 잘못되었습니다.\nex) 2022-03-30 00:00:00"
            );
            return;
          }

          if (document.getElementsByName("btnInfo3")[i].value === "") {
            alert("캘린더등록 버튼의 제목을 입력하세요.");
            return;
          }

          if (document.getElementsByName("btnInfo4")[i].value === "") {
            alert("캘린더등록 버튼의 내용을 입력하세요.");
            return;
          }
        }

        if (
          document.getElementsByName("btnType")[i].innerHTML === "메시지전송"
        ) {
          if (document.getElementsByName("btnInfo1")[i].value === "") {
            alert("메시지전송 버튼의 전화번호를 입력하세요.");
            return;
          }
          if (document.getElementsByName("btnInfo2")[i].value === "") {
            alert("메시지전송 버튼의 메시지를 입력하세요.");
            return;
          }
        }

        const btnInfo = {
          btn_type: document.getElementsByName("btnType")[i].innerHTML,
          btn_name: document.getElementsByName("btnNm")[i].value,
          btn_info1: document.getElementsByName("btnInfo1")[i].value,
          btn_info2: document.getElementsByName("btnInfo2")[i].value,
          btn_info3: document.getElementsByName("btnInfo3")[i].value,
          btn_info4: document.getElementsByName("btnInfo4")[i].value,
        };
        btnList.push(btnInfo);
      }
    }

    let cardList = [];
    let mergeList = [];
    let standAloneType = "";
    let carouselType = "";
    let file = "";
    if (chekedRcsTypeId === "RCS_type1") {
      if (testYn === "N") {
        let flag = text.includes("#{이름}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{1}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{2}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{3}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{4}");
        if (flag) mergeCheck = "Y";
      }

      if (mergeCheck === "Y") {
        if (phoneList.length > 0) {
          alert(
            "머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다."
          );
          return;
        }
      }

      const cardInfo = {
        card_text: text,
        card_subject: subject,
        btn_list: btnList,
      };
      cardList.push(cardInfo);
    } else if (chekedRcsTypeId === "RCS_type2") {
      if (testYn === "N") {
        let flag = text.includes("#{이름}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{1}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{2}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{3}");
        if (flag) mergeCheck = "Y";

        flag = text.includes("#{4}");
        if (flag) mergeCheck = "Y";
      }

      if (mergeCheck === "Y") {
        if (phoneList.length > 0) {
          alert(
            "머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다."
          );
          return;
        }
      }

      let file = "";
      if (document.getElementById("file")) {
        if (document.getElementById("file").value === "") {
          alert("RCS StandAlone 타입은 사진 첨부가 필수입니다.");
          return;
        }
        file = document.getElementById("file").value;
      }
      const cardInfo = {
        card_text: text,
        card_subject: subject,
        card_media: file,
        btn_list: btnList,
      };
      cardList.push(cardInfo);
      if (document.getElementById("mediaSize_1")) {
        if (document.getElementById("mediaSize_1").checked) {
          standAloneType = "MEDIUM";
        }
      }
      if (document.getElementById("mediaSize_2")) {
        if (document.getElementById("mediaSize_2").checked) {
          standAloneType = "TALL";
        }
      }
    } else if (chekedRcsTypeId === "RCS_type3") {
      let cardCnt = 0;

      if (document.getElementById("110")) {
        if (document.getElementById("110").checked) {
          cardCnt = 3;
        }
      }
      if (document.getElementById("111")) {
        if (document.getElementById("111").checked) {
          cardCnt = 4;
        }
      }
      if (document.getElementById("112")) {
        if (document.getElementById("112").checked) {
          cardCnt = 5;
        }
      }
      if (document.getElementById("113")) {
        if (document.getElementById("113").checked) {
          cardCnt = 6;
        }
      }

      if (document.getElementById("mediaSize_1")) {
        if (document.getElementById("mediaSize_1").checked) {
          carouselType = "SMALL";
        }
      }
      if (document.getElementById("mediaSize_2")) {
        if (document.getElementById("mediaSize_2").checked) {
          carouselType = "MEDIUM";
        }
      }

      for (let i = 1; i <= cardCnt; i++) {
        text = "";
        btnList = [];
        file = "";
        if (document.getElementById("file" + i)) {
          if (document.getElementById("file" + i).value != "") {
            file = document.getElementById("file" + i).value;
          } else {
            if (carouselType === "SMALL") {
              alert(i + "번째 카드의 사진첨부는 필수입니다.");
              return;
            } else if (carouselType === "MEDIUM") {
              if (document.getElementById("subject" + i).value === "") {
                if (document.getElementById("adTitle" + i)) {
                  if (document.getElementById("adTitle" + i).value === "") {
                    if (
                      document.getElementById("msgContents" + i).value === ""
                    ) {
                      alert(
                        i + "번쨰 카드 사진 또는 제목 또는 내용을 입력하세요."
                      );
                      return;
                    }
                  }
                } else {
                  if (document.getElementById("msgContents" + i).value === "") {
                    alert(
                      i + "번쨰 카드 사진 또는 제목 또는 내용을 입력하세요."
                    );
                    return;
                  }
                }
              }
            }
          }
        }

        if (document.getElementById("subject" + i)) {
          if (document.getElementById("subject" + i).value != "") {
            subject = document.getElementById("subject" + i).value;
          } else {
            subject = null;
          }
        }

        if (document.getElementById("adTitle" + i)) {
          text += document.getElementById("adTitle" + i).value + "\n";
        }
        text += document.getElementById("msgContents" + i).value;

        if (testYn === "N") {
          let flag = text.includes("#{이름}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{1}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{2}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{3}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{4}");
          if (flag) mergeCheck = "Y";
        }

        if (mergeCheck === "Y") {
          if (phoneList.length > 0) {
            alert(
              "머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다."
            );
            return;
          }
        }

        if (document.getElementsByName("btnType" + i)) {
          for (
            let z = 0;
            z < document.getElementsByName("btnType" + i).length;
            z++
          ) {
            let cardCnt = i;
            if (document.getElementsByName("btnNm" + i)[z].value === "") {
              alert(
                cardCnt +
                  "번째 카드 " +
                  document.getElementsByName("btnType" + i)[z].innerHTML +
                  " 버튼의 버튼명을 입력하세요."
              );
              return;
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "URL연결"
            ) {
              if (
                document
                  .getElementsByName("btnInfo1" + i)
                  [z].value.indexOf("https://") === -1
              ) {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "URL연결 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "전화걸기"
            ) {
              if (document.getElementsByName("btnInfo1" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "전화걸기 버튼의 전화번호를 입력하세요."
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "복사하기"
            ) {
              if (document.getElementsByName("btnInfo1" + i)[z].value === "") {
                alert(
                  cardCnt + "번째 카드 " + "복사하기 버튼의 내용을 입력하세요."
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "지도보여주기(좌표)"
            ) {
              if (document.getElementsByName("btnInfo1" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(좌표) 버튼의 위도를 입력하세요."
                );
                return;
              }

              if (document.getElementsByName("btnInfo2" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(좌표) 버튼의 경도를 입력하세요."
                );
                return;
              }

              if (document.getElementsByName("btnInfo3" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(좌표) 버튼의 위치이름을 입력하세요."
                );
                return;
              }

              if (
                document
                  .getElementsByName("btnInfo4" + i)
                  [z].value.indexOf("https://") === -1
              ) {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(좌표) 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "지도보여주기(쿼리)"
            ) {
              if (document.getElementsByName("btnInfo1" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(쿼리) 버튼의 위치이름을 입력하세요."
                );
                return;
              }

              if (
                document
                  .getElementsByName("btnInfo2" + i)
                  [z].value.indexOf("https://") === -1
              ) {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "지도보여주기(쿼리) 버튼의 URL 형식이 잘못되었습니다.\nex) https://xxxxxx.xxx"
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "캘린더등록"
            ) {
              var pattern =
                /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/;

              if (
                pattern.test(
                  document.getElementsByName("btnInfo1" + i)[z].value
                ) === false
              ) {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "캘린더등록 버튼의 시작일 일자 형식이 잘못되었습니다.\nex) 2022-03-30 00:00:00"
                );
                return;
              }
              if (
                pattern.test(
                  document.getElementsByName("btnInfo2" + i)[z].value
                ) === false
              ) {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "캘린더등록 버튼의 종료일 일자 형식이 잘못되었습니다.\nex) 2022-03-30 00:00:00"
                );
                return;
              }

              if (document.getElementsByName("btnInfo3" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "캘린더등록 버튼의 제목을 입력하세요."
                );
                return;
              }

              if (document.getElementsByName("btnInfo4" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "캘린더등록 버튼의 내용을 입력하세요."
                );
                return;
              }
            }

            if (
              document.getElementsByName("btnType" + i)[z].innerHTML ===
              "메시지전송"
            ) {
              if (document.getElementsByName("btnInfo1" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "메시지전송 버튼의 전화번호를 입력하세요."
                );
                return;
              }
              if (document.getElementsByName("btnInfo2" + i)[z].value === "") {
                alert(
                  cardCnt +
                    "번째 카드 " +
                    "메시지전송 버튼의 내용을 입력하세요."
                );
                return;
              }
            }

            let btnInfo = {
              btn_type: document.getElementsByName("btnType" + i)[z].innerHTML,
              btn_name: document.getElementsByName("btnNm" + i)[z].value,
              btn_info1: document.getElementsByName("btnInfo1" + i)[z].value,
              btn_info2: document.getElementsByName("btnInfo2" + i)[z].value,
              btn_info3: document.getElementsByName("btnInfo3" + i)[z].value,
              btn_info4: document.getElementsByName("btnInfo4" + i)[z].value,
            };
            btnList.push(btnInfo);
          }
        }

        let cardInfo = {
          card_text: text,
          card_subject: subject,
          card_media: file,
          btn_list: btnList,
        };
        cardList.push(cardInfo);
      }
    } else if (chekedRcsTypeId === "RCS_type4") {
      if (templateType === "free") {
        if (testYn === "N") {
          let flag = text.includes("#{이름}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{1}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{2}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{3}");
          if (flag) mergeCheck = "Y";

          flag = text.includes("#{4}");
          if (flag) mergeCheck = "Y";
        }

        if (mergeCheck === "Y") {
          if (phoneList.length > 0) {
            alert(
              "머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다."
            );
            return;
          }
        }

        text = document.getElementById("msgContents").value;
      } else {
        if (testYn === "N") {
          if (phoneList.length > 0) {
            alert(
              "머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다."
            );
            return;
          }
        }

        for (
          let i = 0;
          i < document.getElementsByName("mergeCnt").length;
          i++
        ) {
          const mergeInfo = {
            merge_value: document.getElementById("mergeValue_" + i).value,
            merge_choice: document.getElementById("mergeChoice_" + i).value,
          };
          mergeList.push(mergeInfo);
        }
      }
    }

    if (chekedRcsTypeValue === "RCS SMS") {
      if (textLength === 0) {
        alert("RCS SMS 타입은 메시지 내용이 필수입니다.");
        return;
      }
      if (
        replaceKindUnit != undefined &&
        replaceKindUnit != 0 &&
        replaceKindUnit != "SMS"
      ) {
        alert("RCS SMS 타입의 대체 메시지는 SMS만 가능합니다.");
        return;
      }
    } else if (chekedRcsTypeValue === "RCS LMS") {
      if (textLength === 0) {
        alert("RCS LMS 타입은 메시지 내용이 필수입니다.");
        return;
      }
      if (
        replaceKindUnit != undefined &&
        replaceKindUnit != 0 &&
        replaceKindUnit != "LMS"
      ) {
        alert("RCS LMS 타입의 대체 메시지는 LMS만 가능합니다.");
        return;
      }
    } else if (chekedRcsTypeValue === "RCS MMS") {
      if (
        replaceKindUnit != undefined &&
        replaceKindUnit != 0 &&
        replaceKindUnit != "MMS"
      ) {
        alert("RCS MMS 타입의 대체 메시지는 MMS만 가능합니다.");
        return;
      }
      if (document.getElementById("imgFileName")) {
        if (document.getElementById("imgFileName").value === "") {
          alert("대체발송 이미지를 첨부하세요.");
          return;
        }
      }
    } else if (chekedRcsTypeValue === "RCS 템플릿") {
      if (
        replaceKindUnit != undefined &&
        replaceKindUnit != 0 &&
        replaceKindUnit != "SMS"
      ) {
        alert("RCS 템플릿 타입의 대체 메시지는 SMS만 가능합니다.");
        return;
      }
    }

    let sendType = "1";
    let sendDayTime;
    let sendCnt = 1;

    sendCnt = receiveCount;

    let tfText = "";
    let tfMergeCheck = "N";
    if (document.getElementById("replaceText")) {
      tfText = document.getElementById("replaceText").value;

      if (tfText === "") {
        alert("대체 메시지 내용을 입력하세요.");
        return;
      }
    }

    if (testYn === "N") {
      let flag = tfText.includes("#{이름}");
      if (flag) tfMergeCheck = "Y";

      flag = tfText.includes("#{1}");
      if (flag) tfMergeCheck = "Y";

      flag = tfText.includes("#{2}");
      if (flag) tfMergeCheck = "Y";

      flag = tfText.includes("#{3}");
      if (flag) tfMergeCheck = "Y";

      flag = tfText.includes("#{4}");
      if (flag) tfMergeCheck = "Y";
    }

    if (tfMergeCheck === "Y") {
      if (phoneList.length > 0) {
        alert("머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다.");
        return;
      }
    }

    if (chekedId === "id3_2") {
      sendDayTime =
        document.getElementById("datetime-local").value.replace("T", " ") +
        ":00";
      if (document.getElementById("datetime-local").value === "") {
        alert("예약시간을 선택해 주세요.");
        return;
      }
      sendType = "2";
    }

    const formData = new FormData();
    formData.append("file", uploadFile);

    let sendCheckurl = "/message/rcsMessageCheck";
    let sendUrl = "/message/rcsMessageSend";
    let testUrl = "/message/rcsMessageSendTest";

    let checkData = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      text: text,
      cardList: cardList,
      sendType: sendType,
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: chekedRcsTypeValue,
      replaceText: replaceText,
      advertYn: advertYn,
      totCnt: sendCnt,
      brandId: brandId,
      template: template,
      formData: formData,
    };

    let sendData = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      text: text,
      cardList: cardList,
      mergeList: mergeList,
      replaceText: replaceText,
      sendType: sendType,
      subject: subject,
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: chekedRcsTypeValue,
      standAloneType: standAloneType,
      carouselType: carouselType,
      advertYn: advertYn,
      totCnt: sendCnt,
      brandId: brandId,
      templateCode: template,
      adNumber: adNumber,
      testYn: testYn,
    };

    formData.append("rcsSendDto", JSON.stringify(sendData));
    console.log("checkData ", checkData);
    console.log("sendData ", sendData);

    console.log("messageReady checkData", checkData);
    getPostFetch(sendCheckurl, checkData)
      .then((result) => {
        let msg = result.resultMsg;
        if (result.resultCode === "0") {
          if (window.confirm(msg)) {
            getPostFetch(sendUrl, formData)
              .then((result) => {
                alert(result.resultMsg);

                const userInfoUrl = "/user/userInfo";
                getFetch(userInfoUrl)
                  .then((result) => {
                    if (result.resultCode === "0") {
                      const jsonArr = JSON.parse(result.resultData);
                      setInfoData(jsonArr[0]);
                      setDefaultUnit(jsonArr[0]);
                      setBalance(jsonArr[0].balance);
                      setRating(jsonArr[0].preRating);
                      sessionStorage.setItem("pay", jsonArr[0].balance);
                    }
                  })
                  .catch((error) => console.log(error));

                if (testYn === "Y") {
                  popupSendTestMsg(false);
                } else {
                  popupSendMsgConfirm(false);
                  handelDefaultSet();
                  document.getElementById("alternative").checked = false;
                }
              })
              .catch((error) => console.log(error));
          }
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  };

  const sendMsgCase1 = (numPhone) => {
    if (!numPhone) {
      alert("메시지를 발송할 휴대폰 번호가 존재하지 않습니다.");
      return;
    }
    sendMsg("Y", numPhone);
  };

  const sendMsgCase2 = () => {
    sendMsg("N", "");
  };

  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <>
      <div>
        <div>
          <div className="write-left-inner receive-area">
            <div className="write-tit-con clearfix">
              <div className="bluebox mt20 mb30 clearfix">
                <div className="text-left size14 mb10">
                  현재 발송 가능한 건수
                </div>
                <div className="text-right">
                  RCS-SMS {rcsSmsUnit}건 / RCS-LMS {rcsLmsUnit}건 <br />{" "}
                  RCS-StandAlone {rcsMmsUnit}건 / RCS-Carousel {rcsMmsUnit}건 /
                  RCS-템플릿 {rcsTemplateUnit}건{" "}
                </div>
              </div>
              <p className="line"></p>
            </div>
          </div>
          <div className="write-left-inner">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">RCS 상품</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-select buttonWith">
                  <div className="Radiobox">
                    {RCSType.map((value) => (
                      <span key={value.id}>
                        <input
                          type="radio"
                          name=""
                          id={value.id}
                          checked={chekedRcsTypeId === value.id}
                          onClick={function (e) {
                            setActiveRcsType(value.content);
                            setChekedRcsTypeId(value.id);
                          }}
                        />
                        <label htmlFor={value.id}>{value.value}</label>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <p className="line"></p>
          </div>
          <div className="write-left-inner" id="sendNumber">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">브랜드</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-select h40-select gray-select buttonWith">
                  <select
                    onChange={handleBrandId}
                    id="id"
                    name="id"
                    data-fakeform-index="0"
                    className="wid100"
                    value={brandId}
                  >
                    {brandIdList.map((row) => (
                      <option value={row.brandId} key={row.brandId}>
                        {row.brandId}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <p className="line"></p>
          </div>
          {/*  수신번호 */}
          <ReceiveList
            phone={phone}
            setPhone={setPhone}
            phoneCount={phoneCount}
            setPhoneCount={setPhoneCount}
            contentPhone={contentPhone}
            setContentPhone={setContentPhone}
            receiveCount={receiveCount}
            setReceiveCount={setReceiveCount}
            getCountTotal={getCountTotal}
            setFileRegIdList={setFileRegIdList}
            viewTableRows={viewTableRows}
            setviewTableRows={setviewTableRows}
          />
          <div className="write-left-inner" id="sendNumber">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">발신번호</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-select h40-select gray-select buttonWith">
                  <select
                    id="callbackList"
                    name="phone"
                    data-fakeform-index="0"
                    className={receiveCount >= 10000 ? "" : "wid100"}
                  >
                    {callbackList.length == 0 && <option value={"0"} selected hidden>발신번호를 등록하세요.</option>}
                    {chatbotIdList.map((row) => (
                      <option value={row.chatbotId} key={row.chatbotId}>
                        {row.chatbotId}
                      </option>
                    ))}
                  </select>
                  {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                    <Button
                      buttonName="button-bgc-dark"
                      buttonValue="발신번호 인증"
                      onChangePage={handleCertification}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
              <p className="list-item-state red mt5">
                ※ 1만건 이상 발송 시 발신번호 인증 후 메시지 발송이 가능합니다.
              </p>
            ) : null}
            <p className="line"></p>
          </div>
          {chekedRcsTypeId === "RCS_type4" ? ( //수신번호 1만건 이상인지 확인
            <div className="write-left-inner" id="sendNumber">
              <div className="write-tit-con clearfix">
                <div className="tit-left select-tit">
                  <span className="required">템플릿 선택</span>
                </div>
                <div className="tit-right">
                  <div className="gs-custom-select h40-select gray-select buttonWith">
                    <select
                      onChange={handleTemplate}
                      id=""
                      name=""
                      value={template}
                      data-fakeform-index="0"
                      className="wid100"
                    >
                      {templateList.map((row) => (
                        <option
                          value={row.messagebaseId}
                          key={row.messagebaseId}
                        >
                          {row.templateName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {activeRcsType}
          <div className="write-left-inner receive-area">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">대체문자 발송</span>
              </div>
              <div className="tit-right">
                <fieldset className="gs-custom-checkbox">
                  <span className="checkbox-item">
                    <input
                      type="checkbox"
                      id="alternative"
                      name=""
                      onClick={getCheckboxValue}
                    />
                    <label
                      htmlFor="alternative"
                      className={contractAllSelected ? "checked" : null}
                    >
                      RCS 발송 실패 시 대체문자 전송하기
                    </label>
                  </span>
                </fieldset>
              </div>
            </div>
            {alternativeCharacter}
            <p className="line"></p>
          </div>
          <div className="write-left-inner">
            <div className="write-tit-con clearfix">
              <div className="tit-left select-tit">
                <span className="required">전송시간</span>
              </div>
              <div className="tit-right">
                <div className="gs-custom-select buttonWith">
                  <div className="Radiobox">
                    {sendTime.map((value) => (
                      <span key={value.id}>
                        <input
                          type="radio"
                          name=""
                          id={value.id}
                          checked={chekedId === value.id}
                          onClick={function (e) {
                            setActiveContent(value.content);
                            setChekedId(value.id);
                          }}
                        />
                        <label htmlFor={value.id}>{value.value}</label>
                      </span>
                    ))}
                  </div>
                  {activeContent}
                </div>
              </div>
            </div>
            <p className="line"></p>
          </div>
          <div className="write-total-con clearfix" id="">
            <div className="write-total-left-con">
              <div className="write-total-top">
                <div className="inner clearfix">
                  <div className="left">전송 금액</div>
                  <div className="right">
                    <p className="price sendPrice">
                      {setComma(nowBalance * receiveCount * 1.1)} 원
                    </p>
                    <span className="txt">(부가세 포함)</span>
                  </div>
                </div>
              </div>
              <div className="write-total-bottom-wrap">
                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        메시지 타입
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input-btn-group">
                        <div className="input-con">{chekedRcsTypeValue}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        건당 요금
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input-btn-group">
                        <div className="input-con">
                          <span className="mr10">{nowBalance}</span>원
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {replaceUse === true ? (
                  <div>
                    <div className="write-total-bottom">
                      <div className="clearfix">
                        <div className="left">
                          <fieldset className="gs-custom-checkbox">
                            대체문자 타입
                          </fieldset>
                        </div>
                        <div className="right">
                          <div className="gs-custom-input-btn-group">
                            <div className="input-con">{replaceKindUnit}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="write-total-bottom">
                      <div className="clearfix">
                        <div className="left">
                          <fieldset className="gs-custom-checkbox">
                            대체문자 건당 요금
                          </fieldset>
                        </div>
                        <div className="right">
                          <div className="gs-custom-input-btn-group">
                            <div className="input-con">
                              <span className="mr10">{replacePrceUnit}</span>원
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        총 건수
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input-btn-group">
                        <div className="input-con">
                          <span className="mr10">{receiveCount}</span>건
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 선불계정일 때만 표시 */}
                {rating === "2" ? (
                  <div className="write-total-bottom">
                    <div className="clearfix">
                      <div className="left">
                        <fieldset className="gs-custom-checkbox">
                          내 충전금
                        </fieldset>
                      </div>
                      <div className="right">
                        <div className="gs-custom-input">
                          <div className="input-con">
                            <a href="/" onClick={handleRefresh}>
                              <FontAwesomeIcon
                                icon={faRedoAlt}
                                className="gray999 mr10 cursor size8"
                              />
                            </a>
                            <span className="mr10">{setComma(balance)}</span>원
                            {
                              payInfo == 2 ? <Button
                                              buttonName="cm-highlight-btn ml10"
                                              buttonValue="카드결제"
                                              onChangePage={handleTestCardPay}
                                            />
                                          : <Button
                                          buttonName="cm-highlight-btn ml10"
                                          buttonValue="충전"
                                          onChangePage={() => showChargeAccount()}
                                        />
                            }
                          </div>
                          {charge && (
                            <div className="input-con">
                              <MyAccountInfo setBalance={setBalance} infoData={infoData} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* 선불계정일 때만 표시 */}
              </div>
            </div>
          </div>
          <div className="write-left-inner">
            <div className="write-tit-con clearfix">
              <div className="button-wrap rfloat">
                <Button
                  buttonName="button-border-blue"
                  buttonValue="테스트전송"
                  onChangePage={getTestSendMsg}
                />
                <Button
                  buttonName="button-bgc-blue"
                  buttonValue="전송하기"
                  onChangePage={SendMsgConfirm}
                />
              </div>
            </div>
          </div>
          <p className="clear"></p>
        </div>
        {sendTestMsg && (
          <PopUpLayout onClose={popupSendTestMsg} poptitle="테스트전송">
            <LayerPopSendTestMsg
              sendMsgTest={sendMsgCase1}
              closeSendMsgTestPop={closeSendMsgTestPop}
              unitPrce={nowBalance}
              msgType={chekedRcsTypeValue}
            />
          </PopUpLayout>
        )}
        {sendMsgConfirm && (
          <PopUpLayout onClose={popupSendMsgConfirm} poptitle="메시지 발송">
            <LayerPopSendMsg
              sendMsg={sendMsgCase2}
              closeSendMsgPop={closeSendMsgPop}
              receiveCount={receiveCount}
              unitPrce={nowBalance}
              msgType={chekedRcsTypeValue}
              chekedId={chekedId}
            />
          </PopUpLayout>
        )}
        {rechargeInfo && (
          <PopUpLayout
            onClose={popupRechargeInfo}
            poptitle="잔액 부족 안내"
            size="small"
          >
            <LayerPopRechargeInfo
              payInfo={payInfo}
              accountNo={accountNo}
              sendType={sendType}
              popupSendTestMsg={popupSendTestMsg}
              popupSendMsgConfirm={popupSendMsgConfirm}
              closeInsufficientBalancePop={closeInsufficientBalancePop}
              balance={balance}
              setBalance={setBalance}
              nowBalance={nowBalance}
              receiveCount={receiveCount}
            />
          </PopUpLayout>
        )}
        {testCardPay && (
        <PopUpLayout 
          onClose={setTestCardPay}
          poptitle="카드결제"
          size="small"
        >
          <LayerPopTestCardPay
             onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
      </div>
      <p className="clear"></p>
    </>
  );
};

export function AlternativeCharacter(props) {
  const [importAddress, popupImportAddress] = useState(false); // 수신번호 - 주소록 불러오기
  // 대체문자 (발신번호 및 메시지 내용)
  /*********************************** 대체문자 메시지내용 ***********************************/
  const [contractAllSelectedAlternative, setContractAllSelectedAlternative] =
    useState(false); // 대체문자 광고성 정보 여부 - defaultchecked
  const [adNameAlternative, setAdNameAlternative] = useState(null); // 대체문자 광고성 정보체크 - 업체명 또는 서비스명
  const [advertisementAlternative, setAdvertisementAlternative] =
    useState(null); // 대체문자 광고성 정보체크 - 무료거부번호
  const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
  const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창
  const [receiveNumberFile, popupReceiveNumberFile] = useState(false); // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업
  const [alternativechekedImgDefault, setAlternativeChekedImgDefault] =
    useState("alternativeImg1"); // 대체메시지 - 이미지 첨부 - 라디오 defaultchecked
  const [alternativeactiveUploadImage, setAlternativeActiveUploadImage] =
    useState(null); // 대체메시지 - 이미지 첨부
  const [contents, setContents] = useState("");
  const handleAlternative = props.handleAlternative;
  const [adCheck, setAdCheck] = useState(false);
  const [contentsHead, setContentsHead] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");
  const [contractAllSelected, setContractAllSelected] = useState(false);
  const [msgByte, setMsgByte] = useState(0);
  const [msgType, setMsgType] = useState("SMS");
  const [limitByte, setLimitByte] = useState(90);
  const [selectType, setSelectType] = useState("9");
  const [phone, setPhone] = useState("");
  const [phoneCount, setPhoneCount] = useState(0);
  const [contentPhone, setContentPhone] = useState([]);
  const [receiveCount, setReceiveCount] = useState(0);
  const [selectRegId, setSelectRegId] = useState("");
  const [selectIdx, setSelectIdx] = useState(0);
  const [rating, setRating] = useState("2");
  const [uploadType, setUploadType] = useState("1");

  const setUploadFile = props.setUploadFile;
  const [popupImgUpload, setPopupImgUpload] = useState(false);
  const [chekedImgDefault, setChekedImgDefault] =
    React.useState("alternativeImg1"); // 이미지 첨부 - 라디오 defaultchecked
  const [url, setUrl] = useState(null);
  /*********************************** 대체문자 메시지내용 ***********************************/
  /*********************************** 이미지 첨부***********************************/
  const alternativeuploadImage = [
    { id: "alternativeImg1", value: "없음", content: null },
    {
      id: "alternativeImg2",
      value: "이미지",
      content: <AlternativeImageUpload />,
    },
  ];
  /*********************************** 이미지 첨부 end ***********************************/

  const [infoData, setInfoData] = useState([]);

  // 현재 메시지 타입
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const [setNowBalance, nowBalance] = useState(0);
  const [unitPrce, setUnitPrce] = useState(0);

  const setReplacePrceUnit = props.setReplacePrceUnit;
  const setReplaceKindUnit = props.setReplaceKindUnit;
  const repAd = useRef(null); // 광고 체크박스
  const [retainCheck, setRetainCheck] = useState(false);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  useEffect(() => {
    setRetainCheck(false);
    handleContents(contents);
  }, [adCheck, retainCheck]);

  useEffect(() => {
    handleDefaultSet();
  }, []);

  useEffect(() => {
    console.log(msgType);
    if (msgType === "RCS SMS") {
      setSelectType(9);
    } else if (msgType === "RCS LMS") {
      setSelectType(10);
    }
  }, [msgType]);

  useEffect(() => {
    setDetilInfo(msgByte);
  }, [alternativeuploadImage]);

  const openImagePop = () => {
    setPopupImgUpload(true);
  };

  const closeImagePop = () => {
    setPopupImgUpload(false);
  };

  const setImgFile = (file) => {
    setUploadFile(file);
  };

  const setImageUrl = (url) => {
    setUrl(url);
  };

  const setImageFileName = (name) => {
    document.getElementById("imgFileName").value = name;
  };

  // USER 정보 SET
  const handleDefaultSet = () => {
    const userInfoUrl = "/user/userInfo";

    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setReplacePrceUnit(jsonArr[0].smsPrce);
        }
      })
      .catch((error) => console.log(error));
  };

  function getAdvertisementAlternative(event) {
    //광고여부 체크

    if (event.target.checked) {
      setAdvertisementAlternative(
        <AdvertisementAlternative handleContentsFooter={handleContentsFooter} />
      );
      setAdNameAlternative(
        <AdNameAlternative handleContentsHeader={handleContentsHeader} />
      );
      setAdCheck(true);
    } else {
      setAdvertisementAlternative(null);
      setAdNameAlternative(null);
      setAdCheck(false);

      setContentsHead("");
      setContentsFooter("");
    }
  }

  function getCountTotal() {
    let num = 0;
    for (let i = 1; i <= Number(phoneCount) + 10; i++) {
      const addFile = "filePhone_" + i;
      const addPhone = "addPhone_" + i;
      const fileCnt = "fileCnt_" + i;
      if (document.getElementById(addPhone)) {
        num = Number(num) + 1;
      }

      if (document.getElementById(addFile)) {
        const cnt = document.getElementById(fileCnt).value;
        num = Number(num) + Number(cnt);
      }
    }
    setReceiveCount(num);
  }

  const handleContents = (text) => {
    // const maxByte = 2000; // Agent 지원 X
    const maxByte = 90;
    const text_val = text + contentsHead + contentsFooter;

    let totalByte = 0;
    totalByte += getByte(text_val);

    if (adCheck) {
      totalByte = totalByte + 21; // (광고) byte 수
      if (getByte(text) > 69) {
        text = getSliceByByte(text, 68);
        totalByte =
          getByte(text) + getByte(contentsHead) + getByte(contentsFooter) + 21;
      }
    }
    if (totalByte > maxByte) {
      alert(`최대 ${maxByte}Byte까지만 입력가능합니다.`);
    } else {
      setContents(text);
      setMsgByte(totalByte);
      setDetilInfo(totalByte);
      if (adCheck) {
        const msg =
          "(광고)" +
          contentsHead +
          "\n" +
          text +
          "\n" +
          "무료거부 " +
          contentsFooter;
      }
    }
  };

  function handleContentsHeader(text) {
    //const maxByte = 2000;
    const maxByte = 90;
    const text_val = text + contents + contentsFooter;

    let totalByte = 0;
    totalByte += getByte(text_val);

    totalByte = totalByte + 21;

    if (totalByte > maxByte) {
      alert(`최대 ${maxByte}Byte까지만 입력가능합니다.`);
      document.getElementById("replaceAdReTitle").value = contentsHead;
    } else {
      setContentsHead(text);
      setMsgByte(totalByte);
      setDetilInfo(totalByte);
      const msg =
        "(광고)" + text + "\n" + contents + "\n" + "무료거부 " + contentsFooter;
      // document.getElementById("msgContentsPreview").value = msg;
      if (document.getElementById("replaceAdReTitle")) {
        document.getElementById("replaceAdReTitle").value = text;
      }
    }
  }

  function handleContentsFooter(text) {
    //const maxByte = 2000;
    const maxByte = 90;
    const text_val = text + contents + contentsHead;

    let totalByte = 0;
    totalByte += getByte(text_val);
    totalByte = totalByte + 21;

    if (totalByte > maxByte) {
      alert(`최대 ${maxByte}Byte까지만 입력가능합니다.`);
      document.getElementById("replaceAdNumber").value = contentsFooter;
    } else {
      setContentsFooter(text);
      setMsgByte(totalByte);
      setDetilInfo(totalByte);

      const msg =
        "(광고)" + contentsHead + "\n" + contents + "\n" + "무료거부 " + text;
      // document.getElementById("msgContentsPreview").value = msg;
      if (document.getElementById("replaceAdNumber")) {
        document.getElementById("replaceAdNumber").value = text;
      }
    }
  }

  const LoadMsgAlternative = (event) => {
    // 문구 불러오기

    event.preventDefault();
    popupLoadMsg(true);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const SaveMsgAlternative = (event) => {
    // 문구 저장하기
    event.preventDefault();
    let advertYn = "N";
    let footerText = "";
    if (adCheck) {
      advertYn = "Y";
      //080번호 체크
      footerText = document.getElementById("replaceAdNumber").value;
      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!footerText) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(footerText)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }

    if (!contents) {
      alert("저장할 대체 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 대체 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: contentsHead,
        mainText: contents,
        footerText: footerText,
        subject: "",
        msgType: "REP_" + msgType,
        advertYn: advertYn,
      };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => console.log(error));
    }
  };

  const showMergeSettingsAlternative = () => {
    // 머지설정

    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };

  const handleRetainMsgSet = (
    hText,
    subject,
    mText,
    fText,
    rMsgType,
    rAdvertYn
  ) => {
    if (rAdvertYn === "Y") {
      if (!adCheck) {
        repAd.current.click();
      }
      setAdCheck(true);
      document.getElementById("Alternative").checked = true;

      setContentsHead(hText);
      setContents(mText);
      setContentsFooter(fText);

      handleContentsHeader(hText);
      handleContents(mText);
      handleContentsFooter(fText);
    } else {
      if (adCheck) {
        repAd.current.click();
      }
      setAdCheck(false);
      document.getElementById("Alternative").checked = false;
      setContractAllSelected(false);

      setContentsHead("");
      setContentsFooter("");
      setContents(mText);

      handleContents(mText);
    }
    setRetainCheck(true);
    popupLoadMsg(false);
  };

  // 본문 길이에 따른 단가 조정
  function setDetilInfo(totalByte) {
    const btnCnt = document.getElementsByName("btnGrp").length;
    if (document.getElementById("alternativeImg2")) {
      let type = document.getElementById("alternativeImg2").checked;
      if (type) {
        console.log(true);
        setMsgType("MMS");
        setLimitByte("2000");
        setReplacePrceUnit(infoData.mmsPrce);
        setReplaceKindUnit("MMS");

        return;
      }
    }

    if (totalByte > 90) {
      setMsgType("LMS");
      setLimitByte("2000");
      setReplacePrceUnit(infoData.lmsPrce);
      setReplaceKindUnit("LMS");
    } else if (totalByte <= 90) {
      setMsgType("SMS");
      setLimitByte("90");
      setReplacePrceUnit(infoData.smsPrce);
      setReplaceKindUnit("SMS");
    }
  }

  const handleContentsName = (text) => {
    const txtArea = document.getElementById("replaceText");
    const txtValue = txtArea.value;
    let selectPos = txtArea.selectionStart;
    const beforeTxt = txtValue.substring(0, selectPos);
    const afterTxt = txtValue.substring(txtArea.selectionEnd, txtValue.length);
    const addTxt = text;
    handleContents(beforeTxt + addTxt + afterTxt);

    selectPos = selectPos + addTxt.length;
    txtArea.selectionStart = selectPos; // 커서 시작점을 추가 삽입된 텍스트 이후로 지정
    txtArea.selectionEnd = selectPos; // 커서 끝지점을 추가 삽입된 텍스트 이후로 지정
    txtArea.focus();
  };

  return (
    <>
      <p className="line"></p>

      <div className="write-left-inner receive-area">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">대체메시지</span>
          </div>
          <div className="tit-right">
            <fieldset className="gs-custom-checkbox">
              <span className="checkbox-item">
                <input
                  type="checkbox"
                  id="Alternative"
                  name=""
                  onClick={getAdvertisementAlternative}
                />
                <label
                  for="Alternative"
                  ref={repAd}
                  className={contractAllSelectedAlternative && "checked"}
                >
                  광고성 정보가 포함되어 있습니다
                </label>
              </span>
            </fieldset>
          </div>
        </div>
        <div id="msgArea" className="write-input-con change-effect-bg">
          {/* 광고성 정보 체크 시에만 노출 */}
          {adNameAlternative && (
            <AdNameAlternative
              handleContentsHeader={handleContentsHeader}
              contentsHead={contentsHead}
            />
          )}
          <div className="write-input-down-con">
            <textarea
              name="replaceText"
              id="replaceText"
              className="gs-custom-textarea change-effect"
              placeholder="내용 입력
                  - 90 byte 이내"
              maxlength="2000"
              onChange={(e) => {
                handleContents(e.target.value);
              }}
              value={contents}
            ></textarea>
            <div className="write-input-down-info">
              <span className="msgType">{msgType}</span>
              <span className="byte" id="msgByte">
                {msgByte} / <em className="red">{limitByte} Byte</em>
              </span>
            </div>
          </div>
          {/* 광고성 정보 체크 시에만 노출 */}
          {adNameAlternative ? (
            <AdvertisementAlternative
              handleContentsFooter={handleContentsFooter}
              contentsFooter={contentsFooter}
            />
          ) : null}
        </div>
        <div className="write-number-down-btn mt20">
          <ul className="button-col3">
            <li>
              <Button
                buttonName="button-border-gray"
                buttonValue="문구 불러오기"
                onChangePage={LoadMsgAlternative}
              />
            </li>
            <li>
              <Button
                buttonName="button-border-gray"
                buttonValue="문구 저장하기"
                onChangePage={SaveMsgAlternative}
              />
            </li>
            <li>
              <Button
                buttonName={
                  mergeVariableState ? "button-bgc-blue" : "button-border-gray"
                }
                buttonValue="머지설정"
                onChangePage={() => showMergeSettingsAlternative()}
              />
              {mergeVariableState ? (
                <MergeSettings handleContentsName={handleContentsName} />
              ) : null}
            </li>
          </ul>
        </div>
      </div>

      {loadMsg && (
        <PopUpLayout
          onClose={popupLoadMsg}
          poptitle="문구 불러오기"
          size="medium"
        >
          <LayerPopLoadMsg
            selectType={"2"}
            handleRetainMsgSet={handleRetainMsgSet}
          />
        </PopUpLayout>
      )}
      {popupImgUpload && (
        <PopUpLayout onClose={setPopupImgUpload} poptitle="이미지 업로드">
          <LayerPopMessageImgUpload
            setImgFile={setImgFile}
            setImageUrl={setImageUrl}
            setImageFileName={setImageFileName}
            closeImagePop={closeImagePop}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function AlternativeImageUpload(props) {
  // 대체메시지 - 이미지첨부
  const closeImagePop = props.closeImagePop;
  const openImagePop = props.openImagePop;

  const LoadingImg = (event) => {
    event.preventDefault();
    console.log("이미지 불러오기");
    openImagePop();
  };
  return (
    <>
      <div className="mt20 mb20">
        가로기준 최대 640px입니다.
        <br />
        640보다 큰 이미지는 640으로 자동 조정되어 발송됩니다.
        <br />
        <br />
        * 크기조정시 가로X세로 비율이 약 1:2 이상인 경우 용량초과로
        <br />
        첨부되지 않을 수 있으니 확인 후 등록 바랍니다.
      </div>
      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id="imgFileName"
            name="imgFileName"
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="파일등록"
            onChangePage={LoadingImg}
          />
        </div>
      </div>
    </>
  );
}

export function MergeSettings(props) {
  const handleContentsName = props.handleContentsName;
  // 메시지 내용 - 머지설정
  const SetVariableName = (event) => {
    event.preventDefault();
    handleContentsName("#{이름}");
  };
  const SetVariable1 = (event) => {
    event.preventDefault();
    handleContentsName("#{1}");
  };
  const SetVariable2 = (event) => {
    event.preventDefault();
    handleContentsName("#{2}");
  };
  const SetVariable3 = (event) => {
    event.preventDefault();
    handleContentsName("#{3}");
  };
  const SetVariable4 = (event) => {
    event.preventDefault();
    handleContentsName("#{4}");
  };
  return (
    <div className="variable-info-con" id="">
      <div>
        <table className="variable-info-tb" id="">
          <tbody>
            <tr>
              <td byte="10">
                <Button
                  buttonName=""
                  buttonValue="#{이름}"
                  onChangePage={SetVariableName}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{1}"
                  onChangePage={SetVariable1}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{2}"
                  onChangePage={SetVariable2}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{3}"
                  onChangePage={SetVariable3}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{4}"
                  onChangePage={SetVariable4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function AdNameAlternative(props) {
  const handleContentsHeader = props.handleContentsHeader;
  const contentsHead = props.contentsHead;
  // 메시지 내용 - 광고성 정보 체크 시 - 채널명
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02">
        <input
          type="text"
          id="replaceAdReTitle"
          name="replaceAdReTitle"
          title="제목"
          className="gs-custom-input"
          placeholder="업체명 또는 서비스명 입력"
          onChange={(e) => {
            handleContentsHeader(e.target.value);
          }}
          defaultValue={contentsHead}
        />
      </div>
    </div>
  );
}

export function AdvertisementAlternative(props) {
  const handleContentsFooter = props.handleContentsFooter;
  const contentsFooter = props.contentsFooter;

  // 메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id="replaceAdNumber"
        name="replaceAdNumber"
        maxlength="11"
        title="무료거부번호"
        placeholder="080 무료거부번호 입력"
        onChange={(e) => {
          handleContentsFooter(e.target.value);
        }}
        defaultValue={contentsFooter}
      />
    </div>
  );
}

export function Reservation() {
  // 전송시간 - 예약

  const classes = useStyles();
  return (
    <span>
      <TextField
        id="datetime-local"
        type="datetime-local"
        defaultValue={new Date()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </span>
  );
}

// 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)
export function MyAccountInfo(props) {
  // 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)

  const [accountCreation, popupAccountCreation] = useState(false);
  const [testCardPay, setTestCardPay] = useState(false);

  const setBalance = props.setBalance;
  const [dataCheck, setDataCheck] = useState(null);

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const infoData = props.infoData;
  const handleAccountNumber = (event) => {
    event.preventDefault();
    popupAccountCreation(true);
  };

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  const handleTestVbankPay = (event) => {
    event.preventDefault();
    alert("준비중 입니다.");
  };
  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  useEffect(() => {
    const url = "/user/payInfo";

    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
          console.log("jsonArr" + jsonArr.payType);
        }
      })
      .catch((error) => console.log(error));
  }, [accountNo]);

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <div className="my-account-info">
      {/* 전용 계좌 생성 전 */}
      {payInfo === "1" && accountNo === undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              생성한 전용계좌가 없습니다.
            </fieldset>
          </div>
          <div className="right">
            <a href="/" onClick={handleAccountNumber} className="underline">
              내 전용계좌 생성하기
            </a>
          </div>
        </div>
      ) : null}
      {payInfo === "1" && accountNo != undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">하나은행</fieldset>
          </div>
          <div className="right">{accountNo}</div>
        </div>
      ) : null}
      {payInfo === "2" ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">
            <Button
              buttonName="cm-highlight-btn ml10"
              buttonValue="카드결제"
              onChangePage={handleTestCardPay}
            ></Button>
          </div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {payInfo === undefined || payInfo === null && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">결제 타입 미지정</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {infoData.custType != 1 && infoData.custType != 2 ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right red">계정 승인 후 충전 가능합니다.</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
          <LayerPopAccountCreation
            popupAccountCreation={popupAccountCreation}
            setAccountNo={setAccountNo}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}>
          <LayerPopTestCardPay
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

/**************************** RCS 미리보기 *************************** */
export function PreviewRCS(props) {
  console.log("rows => " + props.rows);
  const style = {
    img: {
      width: "100%",
      height: "100%",
    },
    area: {
      position: "absolute",
      left: "7%",
      backgroundColor: "white",
      borderRadius: "10px",
      width: "86%",
      minHeight: "50px",
      maxHeight: "420px",
      padding: "15px",
      color: "#4a4a4a",
      overflowX: "hidden",
      overflowY: "auto",
      fontSize: "15px",
      lineHeight: "22px",
    },
    buttonWrap: {
      width: "100%",
      marginTop: "10px",
      textAlign: "center",
    },
    button: {
      width: "100%",
      padding: "7px 0",
      textAlign: "center",
      backgroundColor: "#f5f4f6",
      color: "#222",
      marginBottom: "7px",
      borderRadius: "3px",
      fontSize: "14px",
    },
    freeRefusal: {
      textDecoration: "underline",
      color: "#625af6",
      marginLeft: "5px",
    },
    textAreaBack: {
      backgroundColor: "white",
    },
    textAreaBack2: {
      backgroundColor: "#ffffff",
    },
    title: {
      fontWeight: 500,
      color: "#222",
      marginBottom: "7px",
    },
  };
  return (
    <>
      <div style={style.area} className="table_wrapY">
        <div id="previewImg"></div>
        <div id="previewTest"></div>
        <p id="previewSubject" style={style.title}></p>
        <pre id="previewHeader"></pre>
        <pre id="previewBody"></pre>
        <div style={style.textAreaBack2} id="templatePreview"></div>
        <div id="previewHeaderTitle">
          <span id="previewHeaderNum" style={style.content}></span>
          <ul
            style={style.buttonWrap}
            id="previewBtnList"
            name="previewBtnList"
          ></ul>
        </div>
        <pre id="previewFooter"></pre>
      </div>
    </>
  );
}

export function PreviewRCSSlide(props) {
  const cardCount = props.cardCount;
  console.log(cardCount);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerPadding: "0px",
    slidesToShow: 1,
    marginLeft: "-100px",
  };

  const style1 = {
    button: {
      marginLeft: "-20px",
    },
  };

  return (
    <>
      {cardCount === "3" ? (
        <Slider {...settings} style={style1.button}>
          <RCSCard cardId={"1"} />
          <RCSCard cardId={"2"} />
          <RCSCard cardId={"3"} lastCard={"T"} cardCount={"3"} />
        </Slider>
      ) : null}
      {cardCount === "4" ? (
        <Slider {...settings} style={style1.button}>
          <RCSCard cardId={"1"} />
          <RCSCard cardId={"2"} />
          <RCSCard cardId={"3"} />
          <RCSCard cardId={"4"} lastCard={"T"} cardCount={"4"} />
        </Slider>
      ) : null}
      {cardCount === "5" ? (
        <div>
          <Slider {...settings} style={style1.button}>
            <RCSCard cardId={"1"} />
            <RCSCard cardId={"2"} />
            <RCSCard cardId={"3"} />
            <RCSCard cardId={"4"} />
            <RCSCard cardId={"5"} lastCard={"T"} cardCount={"5"} />
          </Slider>
        </div>
      ) : null}
      {cardCount === "6" ? (
        <div>
          <Slider {...settings} style={style1.button}>
            <RCSCard cardId={"1"} />
            <RCSCard cardId={"2"} />
            <RCSCard cardId={"3"} />
            <RCSCard cardId={"4"} />
            <RCSCard cardId={"5"} />
            <RCSCard cardId={"6"} lastCard={"T"} cardCount={"6"} />
          </Slider>
        </div>
      ) : null}
    </>
  );
}

export function RCSCard(props) {
  const cardId = props.cardId;
  const lastCard = props.lastCard;
  const cardCount = props.cardCount;
  const style = {
    MediumArea: {
      left: "7%",
      top: "70px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "78%",
      minHeight: "50px",
      maxHeight: "450px",
      padding: "15px",
      color: "#4a4a4a",
      overflow: "hidden",
      fontSize: "15px",
      lineHeight: "22px",
      "& imgwrap": {
        width: "100%",
        height: "130px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        overflow: "hidden",
      },
    },
    img: {
      width: "100%",
      height: "100%",
    },
    area: {
      backgroundColor: "white",
      borderRadius: "10px",
      width: "calc(100% - 20px)",
      color: "#4a4a4a",
      overflow: "hidden",
      fontSize: "15px",
      lineHeight: "22px",
      marginLeft: "20px",
    },
    buttonWrap: {
      width: "100%",
      marginTop: "10px",
      textAlign: "center",
    },
    button: {
      width: "100%",
      padding: "7px 0",
      textAlign: "center",
      backgroundColor: "#f5f4f6",
      color: "#222",
      marginBottom: "7px",
      borderRadius: "3px",
      fontSize: "14px",
    },
    freeRefusal: {
      textDecoration: "underline",
      color: "#625af6",
      marginLeft: "5px",
    },
    textAreaBack: {
      backgroundColor: "white",
    },
    textAreaBack2: {
      backgroundColor: "#ffffff",
    },
    title: {
      fontWeight: 500,
      color: "#222",
      marginBottom: "7px",
    },
    testBody3: {
      transform: "translate3d(686px, 0px,0px)",
    },
    testBody4: {
      transform: "translate3d(908px, 0px,0px)",
    },
    testBody5: {
      transform: "translate3d(1130px, 0px,0px)",
    },
    testBody6: {
      transform: "translate3d(1355px, 0px,0px)",
    },
    testButtonWrap: {
      width: "100%",
      marginTop: "10px",
      textAlign: "center",
      position: "absolute",
      transform: "translate3d(606px, 0px,0px)",
    },
    testArea: {
      backgroundColor: "white",
      borderRadius: "10px",
      width: "100%",
      minHeight: "200px",
      color: "#4a4a4a",
      overflow: "hidden",
      fontSize: "15px",
      lineHeight: "22px",
      transform: "translate3d(606px, 0px,0px)",
    },
  };

  return (
    <>
      {lastCard === "T" ? (
        <div
          style={
            cardCount === "3"
              ? style.testBody3
              : cardCount === "4"
              ? style.testBody4
              : cardCount === "5"
              ? style.testBody5
              : cardCount === "6"
              ? style.testBody6
              : null
          }
        >
          {/* 이미지 or 동영상 추가할 경우 노출 */}
          {/* if 미디어 크기 === medium */}
          <div id="previewImg" style={style.imgwrap_Medium}>
            <img
              src={noimg}
              alt=""
              style={style.img}
              id={"rcsMmsImg" + cardId}
            />
          </div>

          {/* if 미디어 크기 === tall */}
          {/* <div className="imgArea tall">
                      <img src={noimg} alt=""/>
                  </div> */}
          {/* 이미지 or 동영상 추가할 경우 노출 end */}

          <p id={"previewSubject" + cardId} style={style.title}></p>
          <pre id={"previewHeader" + cardId}></pre>
          <pre id={"previewBody" + cardId}></pre>
          <pre id="previewHeaderTitle">
            <span id="previewHeaderNum" style={style.content}></span>
            <ul
              style={style.buttonWrap}
              id={"previewBtnList" + cardId}
              name={"previewBtnList" + cardId}
            ></ul>
          </pre>
          <pre id={"previewFooter" + cardId}></pre>
        </div>
      ) : (
        <div style={style.area}>
          {/* 이미지 or 동영상 추가할 경우 노출 */}
          {/* if 미디어 크기 === medium */}
          <div id="previewImg" style={style.imgwrap_Medium}>
            <img
              src={noimg}
              alt=""
              style={style.img}
              id={"rcsMmsImg" + cardId}
            />
          </div>

          {/* if 미디어 크기 === tall */}
          {/* <div className="imgArea tall">
                      <img src={noimg} alt=""/>
                  </div> */}
          {/* 이미지 or 동영상 추가할 경우 노출 end */}

          <p id={"previewSubject" + cardId} style={style.title}></p>
          <pre id={"previewHeader" + cardId}></pre>
          <pre id={"previewBody" + cardId}></pre>
          <pre id="previewHeaderTitle">
            <span id="previewHeaderNum" style={style.content}></span>
            <ul
              style={style.buttonWrap}
              id={"previewBtnList" + cardId}
              name={"previewBtnList" + cardId}
            ></ul>
          </pre>
          <pre id={"previewFooter" + cardId}></pre>
        </div>
      )}
    </>
  );
}
