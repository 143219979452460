import React from "react";
import styled from "styled-components";
import Summary1 from "../../../assets/images/API_summary1.jpg";
import Summary2 from "../../../assets/images/API_summary2.jpg";

export const ConnectApi = () => {

  const api_swagger_url = (event) => {
    let cur_link = document.location.href;
    let api_swagger_link = "http://hisms.co.kr:8686/api/swagger-ui/";   
  
   if ( cur_link.includes('dev.hisms.co.kr') ||  cur_link.includes('localhost') ){
      api_swagger_link = "http://dev.hisms.co.kr:8686/api/swagger-ui/" ; // dev
    }
   else{
    api_swagger_link = "http://hisms.co.kr:8686/api/swagger-ui/";   
   }

   return api_swagger_link  ; 
    
  };


  return (
    <Section>
      <SpaceBetween>
        <Title>REST API 메시지 발송 개요</Title>
        <div class="button-wrap center">
          <button
            className="button-bgc-dark bbtn"
            onClick={() =>
              //window.open("http://hisms.co.kr:8686/api/swagger-ui/", "_blank")
              window.open(api_swagger_url(), "_blank")
            }
          >
            API 문서 확인하기
          </button>
        </div>
      </SpaceBetween>
      <SubTitle>1.1.1 메시지 발송</SubTitle>
      <img src={Summary1} alt="메시지 발송 프로세스" />
      <DescriptionList>
        <li>
          1. 인증: Client ID, Client Secret, Username, Password으로 REST API
          Server(이하 Server)에 인증 요청.
        </li>
        <li>
          2. 인증 성공: Server에서 Client ID, Client Secret, Username,
          Password으로 Client와 사용자 인증.
        </li>
        <li>
          3. 토큰: 인증 성공 후 Server에서 Client에 토큰(Access Token, Refresh
          Token)발급.
        </li>
        <li>
          4. 메시지 발송 요청: 발급 받은 access token과 함께 발송 요청할 메시지
          정보를 담아 Server 호출.
        </li>
        <li>
          5. 토큰 확인 성공: Server에서 Client의 메시지 발송 요청의 토큰(Access
          Token)의 유효한 토큰임을 확인.
        </li>
        <li>
          6. 메시지 유효성 확인 성공: 메시지 정보 및 포맷이 규격에 맞는 지 확인.
        </li>
        <li>
          7. 요청 성공 응답: Client의 메시지 발송 요청이 성공적으로 처리되면
          Server에서 Client로 성공 여부 반환.
        </li>
      </DescriptionList>
      <br />
      <br />
      <SubTitle>1.1.2 발송 결과 push</SubTitle>
      <img src={Summary2} alt="발송 결과 프로세스" />
      <DescriptionList>
        <li>
          1. 메시지 발송: 토큰 확인 및 메시지 유효성 확인 성공 후 요청 메시지
          발송.
        </li>
        <li>2. 결과 푸시: 메시지 발송 결과를 Client Webhook서버에 푸시.</li>
        <li>
          3. 수신 성공 응답: 메시지 발송 결과를 수신 받으면 수신에 대한 결과를
          Server에 응답.
        </li>
      </DescriptionList>
    </Section>
  );
};

const Section = styled.div`
  width: 1200px;
  margin: 20px auto 40px auto;

  & img {
    display: block;
    margin: 0 auto;
    width: 600px;
  }
`;

const Title = styled.h3`
  margin-bottom: 24px;
  font-size: 20px;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTitle = styled.div`
  margin-left: 8px;
  margin-bottom: 8px;
  color: #464646;
  font-size: 18px;
  font-weight: 500;
`;

const DescriptionList = styled.div`
  background-color: #f7f7f7;
  padding: 24px;
  line-height: 1.6;
  font-weight: 300;
  font-size: 15px;
`;
