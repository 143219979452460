import React from "react";
import { LOGO_NAME } from "../../utils/constant";
import footLogo from "assets/images/footLogo.png";
import { style } from "./Footer.style";

export const Footer = () => {
  return (
    <FooterWrap>
      <CompanyInfo>
        <FootLogo>
          <img src={footLogo} alt={LOGO_NAME} />
        </FootLogo>
        <Biz>
          한진정보통신(주) | 사업자등록번호 : 201-81-46253 | 대표자명 : 변봉섭
          <br />
          통신판매업 신고번호 : 2022-서울강서-0714 | 고객센터 :
          ✉ histmsg@hist.co.kr , ☎ 02-2166-7153 | 서울시 강서구 공항대로 453 (등촌동) 5층
        </Biz>
      </CompanyInfo>

      <ServiceInfo>
        <Policy>
          <a href="https://www.hist.co.kr/" target="_blank">
            회사소개
          </a>
          <ServiceLink to={`/agreement`}>이용 약관</ServiceLink>
          <ServiceLink to={`/privacy`}>개인정보처리방침</ServiceLink>
          <ServiceLink to={`/antispam`}>스팸방지정책</ServiceLink>
        </Policy>
        <Copyright>
          Copyright © Hanjin Information Systems &amp; Telecommunication Corp.
          All rights reserved.
        </Copyright>
      </ServiceInfo>
    </FooterWrap>
  );
};
const {
  FooterWrap,
  CompanyInfo,
  FootLogo,
  Biz,
  ServiceInfo,
  Policy,
  ServiceLink,
  Copyright,
} = style;
