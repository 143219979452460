import React, { useCallback } from "react";

function ImportAddressList({ data, snList, setSnList, showGroupRef }) {
  // 전체 checked
  const onCheckedAll = useCallback(
    (checked) => {
      if (checked) {
        const newArr = [];
        data.map((item) =>
          newArr.push({
            abSn: JSON.stringify(item.abSn),
            abNm: item.abNm,
            abPhone: item.abPhone
          })
        );
        setSnList(newArr);
      } else {
        setSnList([]);
      }
    },
    [data]
  );

  // 개별 check
  const changeHandler = useCallback(
    (checked, sn, name, phone) => {
      let snum = JSON.stringify(sn);
      if (checked) {
        setSnList([...snList, { abSn: snum, abNm: name, abPhone: phone }]);
      } else {
        // 체크 해제
        setSnList(snList.filter((el) => el.abSn !== snum));
      }
    },
    [snList]
  );

  return (
    <table className="list-table">
      <colgroup>
        <col width="10px" />
        <col width="" />
        <col width="" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={(e) => onCheckedAll(e.target.checked)}
              checked={
                snList.length === 0
                  ? false
                  : snList.length === data.length
                  ? true
                  : false
              }
            />
          </th>
          <th>수신번호</th>
          <th>#&#123;이름&#125;</th>
        </tr>
      </thead>
      {!data || data === undefined || data === [] || data.length === 0 || showGroupRef.current === false ? (
        <tbody>
          <tr>
            <td colSpan="3">조회된 정보가 없습니다.</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data.map((row) => (
            <tr key={row.abSn}>
              <td>
                <input
                  type="checkbox"
                  id={row.abSn}
                  value={row.abSn}
                  onChange={(e) => {
                    changeHandler(
                      e.currentTarget.checked,
                      row.abSn,
                      row.abNm,
                      row.abPhone
                    );
                  }}
                  checked={
                    snList.some(
                      (item) => item.abSn === JSON.stringify(row.abSn)
                    )
                      ? true
                      : false
                  }
                />
              </td>
              <td> {row.abPhone} </td>
              <td> {row.abNm} </td>
            </tr>
          ))}
        </tbody>
      )}
      <tfoot></tfoot>
    </table>
  );
}

export default React.memo(ImportAddressList);
