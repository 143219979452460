import React, { useState, useEffect, useCallback } from "react";
import { postRequest } from "../../../../component/Axios";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../component/Common";

export function ListTable(props) {
  const navigate = useNavigate();
  const jsonArr = props.data;
  const myCustId = props.myCustId;
  const custType = props.custType;
  const checkedInputs = props.checkedInputs;
  const setCheckedInputs = props.setCheckedInputs;

  // 네비게이트 (상세페이지로)
  const sendViewPage = (myCustId, custSn, seq, messagebaseId) => {
    sessionStorage.setItem("messagebaseId", messagebaseId);
    sessionStorage.setItem("seq", seq);
    sessionStorage.setItem("selectCustSn", custSn);
    sessionStorage.setItem("myCustId", myCustId);
    sessionStorage.setItem("custType", custType);

    sessionStorage.setItem("backCheck", "R");
    sessionStorage.setItem("saveSearchMode", props.saveSearchMode);
    sessionStorage.setItem("saveCustId", props.setCustId);
    sessionStorage.setItem("saveTemplateInfo", props.setTemplateInfo);
    sessionStorage.setItem("saveCardType", props.setCardType);
    sessionStorage.setItem("saveStatus", props.setStatus);
    sessionStorage.setItem("saveFormService", props.setFormService);
    sessionStorage.setItem("pages", props.pages);
    navigate("/rcs/rcsTemplate/view");
  };

  useEffect(() => {
  }, [checkedInputs]);

  // 전체 checked
  const onCheckedAll = useCallback(
    (checked) => {
      if (checked) {
        const newArr = [];
        jsonArr.map((item) =>
          newArr.push(
            JSON.parse(
              // 2.  JSON 문자열을 인자로 받아 결과값으로 JavaScript 객체를 반환
              JSON.stringify(item, ["seq", "messagebaseId", "templateStatus"]) // 1. 해당 key값만 JSON문자열로 변환 후
            )
          )
        );
        setCheckedInputs(newArr);
      } else {
        setCheckedInputs([]);
      }
    },
    [jsonArr]
  );

  // 개별 check
  const changeHandler = useCallback(
    (checked, id, templateId, status) => {
      if (checked) {
        setCheckedInputs([
          ...checkedInputs,
          { seq: id, messagebaseId: templateId, templateStatus: status },
        ]);
      } else {
        // 체크 해제
        setCheckedInputs(checkedInputs.filter((el) => el.seq !== id));
      }
    },
    [checkedInputs]
  );

  return (
    <table className="table_style2 RcsTemplateListTable">
      <tbody>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={(e) => onCheckedAll(e.target.checked)}
              checked={
                checkedInputs.length === 0
                  ? false
                  : checkedInputs.length === jsonArr.length
                  ? true
                  : false
              }
            />
          </th>
          <th>번호</th>
          {custType === "1" ? <th>계정ID</th> : null}
          <th>템플릿ID</th>
          <th>템플릿명</th>
          <th>속성</th>
          <th>유형</th>
          <th>채널</th>
          <th>등록자</th>
          <th>등록일</th>
          <th>상태</th>
          <th>승인일</th>
          <th>사용</th>
        </tr>
        {jsonArr.length === 0 ? (
          <tr>
            <td colSpan="12">조회된 정보가 없습니다.</td>
          </tr>
        ) : (
          jsonArr.map((row) => (
            <tr key={row.seq}>
              <td>
                <input
                  type="checkbox"
                  name="checkTp"
                  onChange={(e) => {
                    changeHandler(
                      e.currentTarget.checked,
                      row.seq,
                      row.messagebaseId,
                      row.templateStatus
                    );
                  }}
                  checked={
                    checkedInputs.some((item) => item.seq === row.seq)
                      ? true
                      : false
                  }
                />
              </td>
              <td>{row.no}</td>
              {custType === "1" && <td>{row.custId}</td>}
              <td>
                <a
                  onClick={(e) =>
                    sendViewPage(
                      myCustId,
                      row.custSn,
                      row.seq,
                      row.messagebaseId
                    )
                  }
                >
                  {row.messagebaseId}
                </a>
              </td>
              <td>
                <a
                  onClick={(e) =>
                    sendViewPage(
                      myCustId,
                      row.custSn,
                      row.seq,
                      row.messagebaseId
                    )
                  }
                >
                  {row.templateName}
                </a>
              </td>
              <td>{row.templateCardType}</td>
              <td>{row.formService}</td>
              <td>{row.brandId}</td>
              <td>{row.userNm}</td>
              <td>{row.templateRegDt}</td>
              <td>{row.templateStatus}</td>
              <td>{row.templateApprovalDt}</td>
              <td>{row.templateStatus1}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default ListTable;
