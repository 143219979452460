import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { PaginationDynamic } from "component/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import Loader from "../../../component/Loader";


const useStyles = makeStyles((theme) => ({
  boardcont: {
      margin: '50px 0 25px 0',
      lineHeight: 1.3,
      color: '#616161',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
  }
}));

export default function FAQ() {
  const [activeId, setActiveId] = useState('');
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [isOn, setIsOn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search1, setSearch1] = useState('1');
  const [search2, setSearch2] = useState('');
  const [loading, setLoading] = useState(0);
  
  const classes = useStyles();

  useEffect(() => {
    
    handleTab();
  }, []);

  useEffect(() => {
    handlePageChange(1);
  }, [activeId]);
  
  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url,data) {
    return postRequest.fetch(url,data);
  }

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  } 

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handlePageChange(1);
    }
  }


  const [tab, setTab] = useState([]);
  const handleTab = () => {
    const url = "/board/faqTab"
    getFetch(url)
    .then(result => {
      if( result.resultCode === "0" ) {
          const jsonArr = JSON.parse(result.resultData);
          setTab(jsonArr);
          handlePageChange(1);
      }
    })
    .catch(error => handleLoadingClose())
  }

  const handlePageChange = page => {
    const url = "/board/faqList";
    const data = { rowCount : rowCount, currentPage: page, search1 : search1, search2 : search2, search3 : activeId }
    handleLoadingOn();
    getPostFetch(url, data)
    .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                const pageItem = JSON.parse(result.resultPage);
                setLeftPage(pageItem.leftPage);
                setRightPage(pageItem.rightPage);
                setEndPage(pageItem.endPage);
                setCurrentPage(pageItem.currentPage);
                setFnlPage(pageItem.fnlPage);
                const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                setPages(pages);
                setTableRows(jsonArr);
            } else {
                alert(result.resultMsg);
            }
            handleLoadingClose();
        }
    )
    .catch(error => handleLoadingClose())
}

  const handleToggle = () => {
    isOn === true ? setIsOn(false) : setIsOn(true)
  };
  const handleSearch = (event) => {
    event.preventDefault();
    handlePageChange(1);
  };
  return (
    <div className="container FAQ notice">
      {loading !=  1 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="page-title">
        FAQ
        <div className="rfloat boardsearch">
          <select name="search_type" id="search1" className="select_type2" onChange={ e => { setSearch1(e.target.value) } }>
            <option value="1">제목</option>
            <option value="2">내용</option>
          </select>
          <input type="text" id="search2" placeholder="검색어를 입력하세요" className="bolder_none" onChange={ e => { setSearch2(e.target.value) } } onKeyPress={handleEnterPress}
          />
          <a href="/" onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </a>
        </div>
      </div>
      <ul className="tabmenu">
        {tab.map((val) => (
          <li className={activeId ===val.id ? "active" : null} key={val.id}>
            <a href="/" onClick={function (e) {
              e.preventDefault();
              setActiveId(val.id);
             }}>{val.text}</a>
           </li>
      ))}
      </ul>
      <ul className="faq-list">
        { tableRows.length === 0 && loading != 0 
          ? <div style={{textAlign:"center"}}>등록된 내용이 없습니다.</div>
          : null
        }

        {tableRows.map((val) => (
          <li className={
            activeQuestion === val.bfSn
            ?  isOn === true
              ? 'active'
              : null
            : null
            } 
            key={val.bfSn} 
            onClick={handleToggle}
          >
            <dl>
              <dt>
                <a href="/" onClick={function (e) {
                    e.preventDefault();
                    setActiveQuestion(val.bfSn);
                  }}>
                  <em>Q</em>
                  <span>
                  {val.bfTitle}
                  </span>
                </a>
              </dt>
              <dd>
          <div
            className={classes.boardcont}
            dangerouslySetInnerHTML={{ __html: val.bfContents }}
          ></div>

              </dd>
            </dl>
          </li>
        ))}
      </ul>
      <p className="clear"></p>
      <PaginationDynamic  
                    pages={pages}
                    currentPage={currentPage}
                    leftPage={leftPage}
                    rightPage={rightPage}
                    endPage={endPage}
                    fnlPage={fnlPage}
                    onPageChange={handlePageChange}
                />
    </div>
  );
}
