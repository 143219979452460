import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare, faFolder, faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import { Button , PaginationDynamic } from "component/Common";
import dotted from "assets/images/base.png";
import {AddressList} from "component/Table";
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import { PopUpLayout } from "component/popUp/PopUpLayout";
import Loader from "../../../component/Loader";

import { 
    LayerPopExcelUpload,
    LayerPopAddGroup, // 그룹추가(등록)
    LayerPopEditGroup, // 그룹수정
    LayerPopMoveGroup, // 그룹 주소록 이동
    LayerPopCopyGroup, // 그룹 주소록 복사
    LayerPopDuplicateSearch, // 중복번호/이름 검색
} from "component/popUp/PopUp";
import axios from 'axios' 
import { tsMethodSignature } from '@babel/types';
import { SEVER_DOMAIN } from 'utils/constant.js';

export class PersonalAddress extends React.Component{

    state = { tableRows : '' , pages : [1], endPage : 1 , leftPage : 1, rightPage : 1, currentPage: 1 , rowCount: 10, fnlPage: 1,  
                addrSn : 1, addrNm : '', addrPhone : '', addrEtc1 : '', addrEtc2 : '', addrEtc3 : '', addrEtc4 : '' , search1 : '2', search2 : '2',
                search3 : '', group : '전체' , agSn : 0 , agCnt : 0 , groupList : [], totCnt : 0, popup : false, addGroup : false, editGroup : false, 
                moveGroup : false , copyGroup :false , duplicateSearch : false , upSn : -1, groupNm : '전체', groupDepth : 1 , groupCnt : 0 , resultData : '' , downCnt : 0, callCnt : 0, custGrade: ''}
    
    componentDidMount (){

        getRequest
        .fetch("/user/myInfo")
        .then((myInfo) => {
          if (myInfo.resultCode === "0") {
            const myInfoArr = JSON.parse(myInfo.resultData);
            this.setState({ custGrade: myInfoArr.custType });

            // 그룹 조회 및 최초 데이터 조회 ( 전체 ) 
            let nRet = "";
            let url = "/addr/addrGroupList";
            let data = { search1 : "2" }
            postRequest.fetch(url,data).then(result => {
                    nRet = result.resultCode;
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        this.setState({groupList : jsonArr})
                        this.setState({ totCnt: jsonArr.length == 0 ? 0 : jsonArr[0].totCnt });
                    this.setState({ agCnt: jsonArr.length == 0 ? 0 : jsonArr[0].totCnt });
                        this.setState({resultData : result.resultData})
                        this.setState({callCnt : this.state.callCnt + 1})
                    } else {
                        alert(result.resultMsg);
                    }
                }
            )
            .catch(error => this.setState({callCnt : this.state.callCnt + 1}))

            url = "/addr/addrList";
            data = { search1 : this.state.search1, search2 : this.state.search2, search3 : this.state.search3, 
                    search4 : this.state.agSn, rowCount : this.state.rowCount, currentPage: this.state.currentPage }
            postRequest.fetch(url,data).then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        this.setState({tableRows : jsonArr})
                        const pageItem = JSON.parse(result.resultPage);
                        this.setState({leftPage : pageItem.leftPage})
                        this.setState({rightPage : pageItem.rightPage})
                        this.setState({endPage : pageItem.endPage})
                        this.setState({currentPage : pageItem.currentPage})
                        this.setState({fnlPage: pageItem.fnlPage})
                        const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                        this.setState({pages : pages})
                        this.setState({callCnt : this.state.callCnt + 1})
                    } else {
                        alert(result.resultMsg);
                    }
                }
            )
            .catch(error => this.setState({callCnt : this.state.callCnt + 1}))
            }
            })
        .catch((error) => this.setState({callCnt : this.state.callCnt + 1}));
    }

    render(){

        async function getPostFetch(url,data) {
            return postRequest.fetch(url,data);
        }

        const handleReload = num => {
            let cnt = parseInt(this.state.agCnt);
            cnt = Number(cnt) + Number(num);
            this.setState({agCnt : cnt});
            
            //handelDefault();
            handlePageChange(1);
            handleGroupSearch();
        }

        const handlePopReload = num => {
            handleGroupSearch();
            handlePageChange(1);
        }
        
        const setPopup = val => {
            this.setState({popup : val});
        }
        const setPopupClose = () => {
            this.setState({popup : false});
        }

        const popupAddGroup = val => {
            this.setState({addGroup : val});
        }

        const popupEditGroup = val => {
            this.setState({editGroup : val});
        }

        const handleEditGroupNm = name => {
            this.setState({group : name});
        }

        const popupMoveGroup = val => {
            this.setState({moveGroup : val});
        }

        const popupCopyGroup = val => {
            this.setState({copyGroup : val});
        }

        const popupPopDuplicate = val => {
            this.setState({duplicateSearch : val});
        }
        

        const handleGroupSearch = e => {
            const url = "/addr/addrGroupList";
            const data = { search1 : "2" }
            postRequest.fetch(url,data).then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        this.setState({totCnt: jsonArr.length == 0 ? 0 : jsonArr[0].totCnt })
                        this.setState({groupList : jsonArr})
                    } else {
                        alert(result.resultMsg);
                    }
                }
            )
            .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
        }

        const handleSaveEnterPress = e => {
            if(e.key === 'Enter') {
              handleAddAddress(e);
            }
          }
      
          const handleSearchEnterPress = e => {
            if(e.key === 'Enter') {
              handlePageChange(1);
            }
          }

        const handleAddAddress = (event) => {
            event.preventDefault();
            const sn = this.state.agSn;
            const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;

            if ( !sn || sn === "0" ) {
                alert("선택된 그룹이 존재하지 않습니다.\n('전체' 에는 연락처를 등록할 수 없습니다.)");
                return;
            }

            if ( !this.state.addrNm ) {
                alert("이름은 필수입력사항 입니다.");
                return;
            }

            if ( !this.state.addrPhone ) {
                alert("휴대폰번호는 필수입력사항 입니다.");
                return;
            } else if ( !regexMobile.test(this.state.addrPhone)) {
                alert("잘못된 형식의 휴대폰번호 입니다.");
                return;
            }

            const groupNm = this.state.group;
            if( window.confirm("입력된 정보를 주소그룹 [ "+groupNm+" ] 에 저장하시겠습니까?") ) {
                const url = "/addr/addrSave";
                const data = { agSn : this.state.agSn, abNm : this.state.addrNm, abPhone : this.state.addrPhone, 
                    abEtc1 : this.state.addrEtc1 , abEtc2 : this.state.addrEtc2 , abEtc3 : this.state.addrEtc3 , abEtc4 : this.state.addrEtc4 };
                
                getPostFetch(url, data)
                .then(result => {
                        alert(result.resultMsg);
                        if( result.resultCode === "0" ) {
                            handleReload(1);
                            handleDefaultVal();
                        } 
                    }
                )
                .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
            }
        };

        const handleDefaultVal = () => {
            this.setState({addrNm : ""});
            this.setState({addrPhone : ""});
            this.setState({addrEtc1 : ""});
            this.setState({addrEtc2 : ""});
            this.setState({addrEtc3 : ""});
            this.setState({addrEtc4 : ""});
        }

        const handleCurrent = () => {
            const page = this.state.page;
            handlePageChange(page);
        }

        const handlePageChange = page => {
            this.setState({currentPage : page})
            this.setState({callCnt : 0})
            
            const url = "/addr/addrList";
            const data = { search1 : this.state.search1, search2 : this.state.search2, search3 : this.state.search3, 
                search4 : this.state.agSn, search5 : sessionStorage.getItem("custType"), rowCount : this.state.rowCount, currentPage: page }
            
            getPostFetch(url, data)
            .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        this.setState({tableRows : jsonArr})
                        
                        const pageItem = JSON.parse(result.resultPage);
                        this.setState({leftPage : pageItem.leftPage})
                        this.setState({rightPage : pageItem.rightPage})
                        this.setState({endPage : pageItem.endPage})
                        this.setState({currentPage : pageItem.currentPage})
                        this.setState({fnlPage: pageItem.fnlPage})

                        const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                        
                        this.setState({pages : pages})
                        this.setState({callCnt : 2})
                    } else {
                        alert(result.resultMsg);
                    }
                }
            )
            .catch(error => this.setState({callCnt : 2}))
        }

        const handleAddGroup = (event) => {
            event.preventDefault();
            if ( this.state.groupDepth === 2 ) {
                alert("선택그룹에는 더이상 추가할 수 없습니다.");
                return;
            }
            this.setState({addGroup : true});
        };

        const handleDeleteGroup = (event) => {
            event.preventDefault();
            
            const sn = this.state.agSn;

            if ( !sn || sn === "0" ) {
                alert("선택된 그룹이 존재하지 않습니다.");
                return;
            }

            const groupNm = this.state.group;

            if( window.confirm("주소그룹 [ "+groupNm+" ] 의 주소록을 삭제 하시겠습니까? \n(하위그룹의 모든 주소록도 함께 삭제 됩니다.") ) {
                const url = "/addr/addrGroupDel";
                const data = { agSn : this.state.agSn };
                this.setState({callCnt : 0})
                getPostFetch(url, data)
                .then(result => {
                        alert(result.resultMsg);
                        if( result.resultCode === "0" ) {
                            // 선택 그룹을 전체로 변경
                            handleReload(1);
                            handelDefault();
                        } 
                    }
                )
                .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
            }

        };
        const handleEditGroup = (event) => {
            event.preventDefault();
            if ( this.state.agSn === "0" ) {
                alert("그룹 전체는 수정할 수 없습니다.");
                return;
            }

            popupEditGroup(true);
        };
        const handleDeleteAddress = (event) => {
            event.preventDefault();

            let sn = [];
            let num = 0;
            const snNodeList = document.getElementsByName("checkTp");
            snNodeList.forEach((node) => {
                if(node.checked)  {
                    num = num + 1;
                    sn.push(node.value);
                }
            }) 

            if ( num === 0 ) {
                alert("선택된 연락처가 없습니다.");
                return;
            }

            if( window.confirm("선택된 연락처를 삭제 하시겠습니까?\n 삭제 건수 : "+num) ){
                const url = "/addr/addrDel";
                const data = { snList : sn }
                
                getPostFetch(url, data)
                .then(result => {
                        if( result.resultCode === "0" ) {
                            alert(result.resultMsg);
                            handleReload(-num);
                        } else {
                            alert(result.resultMsg);
                        }
                    }
                )
                .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
            }
        };
        const handleMove = type => {

            let sn = [];
            let num = 0;
            const snNodeList = document.getElementsByName("checkTp");
            snNodeList.forEach((node) => {
                if(node.checked)  {
                    num = num + 1;
                    sn.push(node.value);
                }
            }) 

            let str = "이동";
            let count = num * -1;
            if ( type == "2" ) {
                str = "복사";
                count = 0;
            }

            if ( num === 0 ) {
                alert("선택된 연락처가 없습니다.");
                return;
            }

            const groupCd = document.getElementById("groupNm").value;

            if ( !groupCd ) {
                alert("선택된 그룹이 없습니다.");
                return;
            }

            if ( groupCd === this.state.agSn ) {
                alert("동일 그룹으로는 "+str+"할 수 없습니다.");
                return;
            }

            let msg = "선택된 연락처를 "+str+" 하시겠습니까?\n "+str+" 건수 : "+num;

            if( window.confirm(msg) ){
                const url = "/addr/addrMove";
                const data = { snList : sn, search1 : groupCd, search2 : type }
                
                getPostFetch(url, data)
                .then(result => {
                        if( result.resultCode === "0" ) {
                            alert(result.resultMsg);
                            handleReload(count);
                        } else {
                            alert(result.resultMsg);
                        }
                    }
                )
               .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
            }

        };
        const handleCopy = (event) => {
            event.preventDefault();
        };
        const handleOverlapSearch = (event) => {
            event.preventDefault();
            popupPopDuplicate(true);
        };
        const handleExcel = (event) => {
            event.preventDefault();
            const sn = this.state.agSn;

            if ( !sn || sn === "0" ) {
                alert("선택된 그룹이 존재하지 않습니다.\n('전체' 에는 연락처를 업로드 할 수 없습니다.)");
                return;
            }

            this.setState({popup : true});
        };

        const handleDownload = type => {
            
            const depth = this.state.groupDepth;
            const sn = this.state.agSn;
            const cnt = this.state.agCnt;
            const groupNm = this.state.group;
            const downCnt = this.state.downCnt;
            const downTotal = cnt + downCnt;

            let extn = ".xlsx";
            let str = "( 엑셀 파일 )";
            if ( type == "2" ) {
                str = "( csv 파일 )";
                extn = ".csv";
            }
            let fileName = "주소록_" + groupNm + extn;
            let msg = "선택된 그룹 ["+groupNm+"]의 주소록 ( 하위그룹 포함 ) 을 내려받으시겠습니까?\n[ 현재 선택건수 : " + setComma(downTotal) + " ] " + str;

            if( window.confirm(msg) ) {
                // search1 : 공유/개인 , search2 : 그룹sn , search3 : 다운로드구분 엑셀/csv , search4 : 그룹명
                handleLoadingOn();
                axios({
                    method: 'POST',
                    url: SEVER_DOMAIN + '/addr/addrDownload/',
                    responseType: 'blob',
                    headers: {
                        Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                        "Content-Type": "application/json"
                    },   
                    data: { search1 : this.state.search1 , search2 : sn , search3 : type , search4 : groupNm }
                })
                .then(res =>{
                    let blob = new Blob([res.data], { type: res.headers['content-type'] })
                    //let fileName = getFileName(res.headers['content-disposition'])
                    //fileName = decodeURI(fileName)
                    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                        window.navigator.msSaveOrOpenBlob(blob, fileName)
                    } else { // not IE
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.target = '_self'
                        if (fileName) link.download = fileName
                        link.click()
                    }
                    handleLoadingClose();
                })
                .catch((error) => {
                    handleLoadingClose();
                  });
            }

        }

        const handleDownloadCSV = (event) => {
            event.preventDefault();
        };
        const handleDownloadExcel = (event) => {
            event.preventDefault();
        };
        
        const handleGroup = (sn,nm,tot,upSn,grNm,depth,cnt, downCnt) => {
            this.setState({agSn : sn});
            this.setState({group : nm});
            this.setState({upSn : upSn});
            this.setState({groupNm : grNm});
            this.setState({groupDepth : depth});
            this.setState({groupCnt : cnt});
            this.setState({downCnt : downCnt});
            if ( sn === "0" )
                this.setState({agCnt : this.state.totCnt});
            else
                this.setState({agCnt : tot});
            handleChoiceView(sn);
        }
        
        const handelDefault = () => {
            this.setState({agSn : 0});
            this.setState({group : '전체'});
            this.setState({upSn : -1});
            this.setState({groupNm : '전체'});
            this.setState({groupDepth : 1});
            this.setState({groupCnt : 0}); 
            this.setState({agCnt : this.state.totCnt});
        }

        const handleChoiceView = sn => {
            this.setState({currentPage : 1})
            this.setState({callCnt : 0})

            const url = "/addr/addrList";
            const data = { search1 : this.state.search1, search2 : this.state.search2, 
                search3 : this.state.search3, search4 : sn, rowCount : this.state.rowCount, currentPage: 1 }
            
            getPostFetch(url, data)
            .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        this.setState({tableRows : jsonArr})
                        
                        const pageItem = JSON.parse(result.resultPage);
                        this.setState({leftPage : pageItem.leftPage})
                        this.setState({rightPage : pageItem.rightPage})
                        this.setState({endPage : pageItem.endPage})
                        this.setState({currentPage : pageItem.currentPage})
                        this.setState({fnlPage: pageItem.fnlPage})

                        const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                        
                        this.setState({pages : pages})
                        this.setState({callCnt : 2})
                    } else {
                        alert(result.resultMsg);
                    }
                }
            )
            .catch(error => this.setState({callCnt : 2}))
        }

        const handleGroupMove = () => {

            const sn = this.state.agSn;

            if ( !sn || sn === "0" ) {
                alert("'전체' 그룹은 이동 할 수 없습니다.");
                return;
            }

            const groupCnt = this.state.groupCnt;

            if ( groupCnt > 0 ) {
                alert("하위그룹이 존재하여 이동 할 수 없습니다.");
                return;
            }

            popupMoveGroup(true);
        }

        const handleGroupCopy = () => {
            const sn = this.state.agSn;

            if ( !sn || sn === "0" ) {
                alert("'전체' 그룹은 복사 할 수 없습니다.)");
                return;
            }

            const groupCnt = this.state.groupCnt;

            if ( groupCnt > 0 ) {
                alert("하위그룹이 존재하여 복사 할 수 없습니다.");
                return;
            }

            popupCopyGroup(true);
        }

        const handleChange = num => {
            const regex = /^[0-9\b -]{0,13}$/;
            if (regex.test(num)) {
                this.setState({addrPhone : num});
            }
        }

        const handleRowCntChange = val => {
            this.setState({rowCount : val});
            //handlePageChange(1);
        }

        const handleLoadingOn = () => {
            this.setState({ callCnt : 0 })
          }
      
          const handleLoadingClose = () => {
            this.setState({ callCnt : 2 })
          }
      

        function setComma(num) {
            if (num > 999) {
              const cn = num
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              return cn;
            } else {
              return num;
            }
          }

        // 샘플파일 다운로드
        const downloadSample = (event) => {
            event.preventDefault();

            let fileName = "주소록_Sample.xlsx";
            axios({
                method: "POST",
                url: SEVER_DOMAIN + "/file/download/",
                responseType: "blob",
                headers: {
                Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
                "Content-Type": "application/json",
                },
                data: {
                sn: 3,
                },
            }).then((res) => {
                let blob = new Blob([res.data], { type: res.headers["content-type"] });

                if (window.navigator.msSaveOrOpenBlob) {
                // IE 10+
                window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                // not IE
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.target = "_self";
                if (fileName) link.download = fileName;
                link.click();
                }
            });
        };

        return (
            <div className="container address">
                
                {this.state.callCnt != 2 && (
                    <Loader type="spin" color="black" message="요청을 처리중입니다" />
                )}

                <p className="page-title">개인주소록</p>
                <div className="searchbox boarderbox">
                    <ul className="search-col4-group ">
                        <li>
                            <input type="text" title="수신번호"  id="address_no" name="address_no" maxLength="11" placeholder="수신번호" 
                            onChange={e => handleChange(e.target.value)} value={this.state.addrPhone} onKeyPress={handleSaveEnterPress}/>
                        </li>
                        <li>
                            <input type="text" title="이름" id="name" name="name" placeholder="이름" 
                            onChange={e => this.setState({addrNm : e.target.value})} value={this.state.addrNm} onKeyPress={handleSaveEnterPress}
                            />
                        </li>
                        <li>
                            <input type="text" title="기타1"  id="etc1" name="etc1" placeholder="기타1"
                            onChange={e => this.setState({addrEtc1 : e.target.value})} value={this.state.addrEtc1} onKeyPress={handleSaveEnterPress}/>
                        </li>
                        <li>
                            선택대상그룹 : <span className="blue" style={{fontWeight: "bold"}}>{this.state.group}</span><br/>
						    등록 연락처 : <span className="blue">{setComma(this.state.agCnt)}</span>
                        </li>
                    </ul>
                    <ul className="search-col4-group">
                        <li>
                            <input type="text" title="기타2" id="etc2" name="etc2" placeholder="기타2"
                            onChange={e => this.setState({addrEtc2 : e.target.value})} value={this.state.addrEtc2} onKeyPress={handleSaveEnterPress}/>
                        </li>
                        <li>
                            <input type="text" title="기타3" id="etc3" name="etc3" placeholder="기타3"
                            onChange={e => this.setState({addrEtc3 : e.target.value})} value={this.state.addrEtc3} onKeyPress={handleSaveEnterPress}/>
                        </li>
                        <li>
                           <input type="text" title="기타4" id="etc4" name="etc4" placeholder="기타4"
                           onChange={e => this.setState({addrEtc4 : e.target.value})} value={this.state.addrEtc4} onKeyPress={handleSaveEnterPress}/>
                        </li>
                        <li>
                            <Button buttonName="button-bgc-blue" buttonValue="해당 그룹에 연락처 등록" onChangePage={handleAddAddress}/>
                        </li>
                    </ul>
                </div>
                
                <div className="address_area">
                    <div className="lfloat">
                        <Button buttonName="button-bgc-blue" buttonValue="그룹 추가 (등록)" onChangePage={handleAddGroup}/>
                        <div className="wrap_tree borderbox">
							<div className="dtree">
                                {/*
								<div className="plusbtn">
									<a href="/" title="plus" onClick={handleAddGroup}>
                                        <FontAwesomeIcon
                                            icon={faPlusSquare}
                                            className="cursor"
                                        />
                                    </a>
									<a href="/" title="minus" onClick={handleDeleteGroup}>
                                        <FontAwesomeIcon
                                            icon={faMinusSquare}
                                            className="cursor"
                                        />
                                    </a>
								</div> 
                                */}
								<div className="treeBody">
                                    <div className="tree_area">
                                        <div className="dTreeNode">
                                            <FontAwesomeIcon
                                                icon={faGlobeAsia}
                                                className="cursor"
                                            />
                                            <a id="sd0" className="node" style={this.state.agSn == 0 ? {fontWeight: "bold"} : {} } onClick={e => {handleGroup('0','전체','','-1','전체',1,0,0)}}>전체({setComma(this.state.totCnt)})</a>
                                        </div>
                                        {this.state.groupList.map((row) => (
                                            <div className={"clip depth" + row.depth}>
                                                <div className="clip">
                                                    <div className="dTreeNode">
                                                        <img src={dotted} alt="점선" />
                                                        <FontAwesomeIcon
                                                            icon={faFolder}
                                                            className="cursor"
                                                        />
                                                        <a id={row.agNm} className="node" 
                                                            style={this.state.agSn == row.agSn ? {fontWeight: "bold"} : {} }
                                                            onClick={e => {handleGroup(row.agSn,row.agNm,row.agTotCnt,row.upperAgSn,row.groupNm,row.depth,row.groupCnt,row.downCnt)}}>
                                                            {row.agNm}({setComma(row.agTotCnt)})
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
							</div>
						</div>
                        <div className="button-wrap">
                            <div className="lfloat"><Button buttonName="button-border-gray" buttonValue="그룹 수정"  onChangePage={handleEditGroup}/></div>
                            <div className="rfloat"><Button buttonName="button-border-gray" buttonValue="그룹 삭제"  onChangePage={handleDeleteGroup}/></div>
                            <div className="lfloat"><Button buttonName="button-border-gray" buttonValue="그룹 주소록 이동"  onChangePage={handleGroupMove}/></div>
                            <div className="rfloat"><Button buttonName="button-border-gray" buttonValue="그룹 주소록 복사"  onChangePage={handleGroupCopy}/></div>
                            <div><Button buttonName="button-border-gray" buttonValue="선택 주소록 내려받기(csv)"  onChangePage={ e => {handleDownload(2)}}/></div>
                            <div><Button buttonName="button-border-gray" buttonValue="선택 주소록 내려받기(엑셀)"  onChangePage={ e => {handleDownload(1)}}/></div>
                            <p className="clear"></p>
                        </div>
                        <p className="clear"></p>
                    </div>
                    <div className="rfloat">
                        <div className="searchbox">
                            <ul className="search-col4-group">
                                <li>
                                    <select className="Selectbox" name="selectbox" id="rowCnt" onChange={e => handleRowCntChange(e.target.value)}>
                                        <option value="" hidden disabled selected>개수</option>
                                        <option value="10">10개</option>
                                        <option value="20">20개</option>
                                        <option value="30">30개</option>
                                    </select>
                                </li>
                                <li>
                                    <select className="Selectbox" name="selectbox" id="search1" onChange={e => this.setState({search2 : e.target.value})} >
                                        <option value="1">이름</option>
                                        <option value="2" selected>수신번호</option>
                                        <option value="3">기타1</option>
                                        <option value="4">기타2</option>
                                        <option value="5">기타3</option>
                                        <option value="6">기타4</option>
                                    </select>
                                </li>
                                <li>
                                    <input type="text" onChange={e => this.setState({search3 : e.target.value})} onKeyPress={handleSearchEnterPress}/>
                                </li>
                                <li>
                                    <Button buttonName="button-bgc-gray" buttonValue="검색" onChangePage={ e => {handlePageChange(1)}}/>
                                </li>
                            </ul> 
                        </div>
                        <div className="searchbox boarderNone">
                            <ul className="search-col3-group">
                                <li>
                                    <select className="Selectbox" id="groupNm" name="groupNm">
                                        {this.state.groupList.map((row) => (
                                            <option value={row.agSn}>{row.groupNm}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="선택이동"  onChangePage={ e => {handleMove(1)}}/>
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="선택복사"  onChangePage={ e => {handleMove(2)}}/>
                                </li>
                            </ul>
                             <ul className="search-col3-group">
                                <li>
                                    <Button buttonName="button-bgc-gray " buttonValue="선택삭제" onChangePage={handleDeleteAddress} />
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="중복번호/이름 검색"  onChangePage={handleOverlapSearch}/>
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" 
                                            style={{width: "50%"}}
                                            buttonValue="엑셀 업로드"  
                                            onChangePage={handleExcel}/>
                                    <Button style={{width: "50%"}}
                                            buttonName="button-border-gray"
                                            buttonValue="양식 다운로드"
                                            onChangePage={downloadSample}/>
                                </li>
                             </ul>
                        </div>
                                           
                        <AddressList data={this.state.tableRows} handleCurrent={handleCurrent} addrType={"P"}/>
                        <PaginationDynamic 
                            pages={this.state.pages}
                            currentPage={this.state.currentPage}
                            leftPage={this.state.leftPage}
                            rightPage={this.state.rightPage}
                            endPage={this.state.endPage}
                            fnlPage={this.state.fnlPage}
                            onPageChange={handlePageChange}
                        />

                    </div>
                </div>
                {/* 여기 팝업을 밖으로  */}
                {this.state.popup && (
                    <PopUpLayout onClose={setPopup} poptitle="파일 업로드">
                        <LayerPopExcelUpload    setPopupClose={setPopupClose} 
                                                sn={this.state.agSn} 
                                                nm={this.state.group}
                                                handleReload={handleReload} 
                                                handleLoadingOn={handleLoadingOn}
                                                handleLoadingClose={handleLoadingClose}/>
                    </PopUpLayout>
                )}
                {this.state.addGroup && (
                    <PopUpLayout onClose={popupAddGroup} poptitle="그룹추가(등록)">
                        <LayerPopAddGroup   popupAddGroup={popupAddGroup}
                                            handleReload={handleReload}
                                            groupNm={this.state.group}
                                            groupSn={this.state.agSn}
                                            agType={"2"}/>
                    </PopUpLayout>
                )}
                {this.state.editGroup && (
                    <PopUpLayout onClose={popupEditGroup} poptitle="그룹수정">
                        <LayerPopEditGroup  popupEditGroup={popupEditGroup} 
                                            handleReload={handleReload} 
                                            groupNm={this.state.group} 
                                            groupSn={this.state.agSn} 
                                            groupUpSn={this.state.upSn} 
                                            handleEditGroupNm={handleEditGroupNm} />
                    </PopUpLayout>
                )}
                {this.state.moveGroup && (
                    <PopUpLayout onClose={popupMoveGroup} poptitle="그룹 주소록 이동">
                        <LayerPopMoveGroup  popupMoveGroup={popupMoveGroup}
                                            handleReload={handleReload}
                                            groupNm={this.state.group}
                                            groupSn={this.state.agSn} 
                                            groupUpSn={this.state.upSn}
                                            groupPathNm={this.state.groupNm} 
                                            groupDepth={this.state.groupDepth} 
                                            groupCnt={this.state.agCnt} 
                                            addrType={"2"}
                                            handleLoadingOn={handleLoadingOn}
                                            handleLoadingClose={handleLoadingClose} />
                    </PopUpLayout>
                )}
                {this.state.copyGroup && (
                    <PopUpLayout  onClose={popupCopyGroup} poptitle="그룹 주소록 복사">
                        <LayerPopCopyGroup  popupCopyGroup={popupCopyGroup}
                                            handleReload={handlePopReload}
                                            groupNm={this.state.group}
                                            groupSn={this.state.agSn} 
                                            groupUpSn={this.state.upSn}
                                            groupPathNm={this.state.groupNm}
                                            groupDepth={this.state.groupDepth} 
                                            groupCnt={this.state.agCnt} 
                                            agType={2}
                                            handleLoadingOn={handleLoadingOn}
                                            handleLoadingClose={handleLoadingClose} />
                    </PopUpLayout>
                )}
                {this.state.duplicateSearch && (
                    <PopUpLayout onClose={popupPopDuplicate} poptitle="중복번호/이름 검색">
                        <LayerPopDuplicateSearch    popupPopDuplicate={popupPopDuplicate}
                                                    handleReload={handlePopReload}
                                                    groupSn={this.state.agSn} addrType={"P"}
                                                    agType={"2"}
                                                    custGrade={this.state.custGrade} 
                                                    handleLoadingOn={handleLoadingOn}
                                                    handleLoadingClose={handleLoadingClose}/>
                    </PopUpLayout>
                )}
            </div>   
        );
    }
}
