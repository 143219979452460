import React, { useState, useEffect } from "react";
import { Button } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import { postRequest } from "component/Axios";
import axios from 'axios' 
import { SEVER_DOMAIN } from 'utils/constant.js';
import { useNavigate } from "react-router-dom";
import Loader from "../../../component/Loader";

const useStyles = makeStyles((theme) => ({
    boardview: {
        width: '100%'
    },
    boardtitle: {
        borderBottom: '2px solid #222222',
        padding: '15px 0',
    },
    boardcont: {
        margin: '50px 0 25px 0',
        lineHeight: 1.3,
        color: '#616161',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    }
}));
  
 
export const BoardView = () => {
    const classes = useStyles();
    const [title, setTitle] = useState('');
    const [contents, setContents] = useState('');
    const [views, setViews] = useState(1);
    const [regDt, setRegDt] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileSn, setFileSn] = useState('');
    const [loading, setLoading] = useState(0);

    const navigate = useNavigate();

    const handleLoadingOn = () => {
        setLoading(0)
    }
    
    const handleLoadingClose = () => {
        setLoading(1)
    }

    const FileDownload = (event) => {
        event.preventDefault();
        if ( fileSn ) {
            const url = "/file/download";
            const data = { sn : fileSn }
            handleLoadingOn();
            axios({
                method: 'POST',
                url: SEVER_DOMAIN + '/file/download/',
                responseType: 'blob',
                headers: {
                    Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                    "Content-Type": "application/json"
                },   
                data: {
                    sn: fileSn    
                } 
            })
            .then(res =>{
                let blob = new Blob([res.data], { type: res.headers['content-type'] })
                
                if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                    window.navigator.msSaveOrOpenBlob(blob, fileName)
                } else { // not IE
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.target = '_self'
                    if (fileName) link.download = fileName
                    link.click()
                }
                
            })
            .catch(error => alert("파일 다운로드를 실패하였습니다."))
            handleLoadingClose();
        }
    };

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    useEffect(() => {
        handleNotice();
    }, []);

    function handleNotice() {
        const url = "/board/noticeDetailview";
        const data = { search1 : sessionStorage.getItem("find_noti")  }
        const contents = [];
        handleLoadingOn();
        getPostFetch(url,data).then(result => {
                  if( result.resultCode === "0" ) {
                      const jsonArr = JSON.parse(result.resultData);
                      
                      setTitle(jsonArr[0].bnTitle);
                      contents.push(jsonArr[0].bnContents);
                      setContents(contents);
                      setViews(jsonArr[0].bnViews);
                      setRegDt(jsonArr[0].regDt);
                      setFileName(jsonArr[0].fileName);
                      setFileSn(jsonArr[0].beSn);
                  } else {
                      alert(result.resultMsg);
                  }
                  handleLoadingClose();
              }
          )
          .catch(error => alert(error))
      }

    return (
    <div className="container notice_view">
        {loading !=  1 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <p className="page-title">공지사항</p>
        <div className={classes.boardview}>
            <div className={classes.boardtitle}>
                <div className="lfloat size18">{title}</div>
                <div className="rfloat gray999">
                    <span>등록일</span>
                    <span>{regDt}</span>
                    <span>조회수</span>
                    <span>{views}</span>
                </div>
                <p className="clear"></p>
            </div>
            <div>
                <div className={classes.boardcont} dangerouslySetInnerHTML={ {__html: contents} }>
                </div>
                {fileSn ?
                <div className="graybox">
                    <span className="bold zise18 mr20">첨부파일</span>
                    <span className="gray999"><a onClick={FileDownload}>{fileName}</a></span>
                </div>
                : null }   
            </div>
        </div>
        <div className="button-wrap left">
            <Button 
                buttonName="button-border-gray" 
                buttonValue="목록으로" 
                onChangePage={function () {
                    navigate("/customer/notice");
                }}
            />
        </div>
    </div>
    );
}
