import React from "react";
import Manual from "./Manual";
import { KakaoTemplate } from "component/Table";
import { NavLink } from "react-router-dom";

class Upload extends React.Component {
  render() {
    return (
      <>
        <div className="kakao-contents">
          <Manual />
          <KakaoTemplate setActiveContent={this.props.setActiveContent} />
        </div>
      </>
    );
  }
}

export default Upload;
