import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loginState } from "../../../utils/state";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopLogin } from "component/popUp/PopUp";
import { Button } from "component/Common";
import { postRequest } from "component/Axios";
import { useCookies } from "react-cookie";
import { SEVER_DOMAIN } from "utils/constant.js";
import Loader from "../../../component/Loader";

const Login = (props) => {
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const [popup, handlePopup] = useState(false);
  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [tableRows, setTableRows] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errMsgId, setErrMsgId] = useState("");
  const idInput = useRef();
  const pwdInput = useRef();
  const [checked, setChecked] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["hisms"]);
  const [loading, setLoading] = useState(1);

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    if (cookies.saveId !== undefined) {
      const saveId = cookies.saveId;
      if (saveId) {
        setId(saveId);
        setChecked(true);
      }
    }
  }, []);

  const handleCheck = (checked) => {
    setChecked(checked);
  };

  const handleAuth = (realNm, realMobile) => {
    let userNm = realNm;
    let custId = id;
    selfAuthPopup = window.open(
      SEVER_DOMAIN +
        "/auth/" +
        userNm +
        "/" +
        custId +
        "/" +
        realMobile +
        "/req",
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );
    selfAuthPopupCheckTimer = setInterval(checkSelfAuthPopupClose, 500);
  };

  var selfAuthPopup;
  var selfAuthPopupCheckTimer;

  function checkSelfAuthPopupClose() {
    if (selfAuthPopup.closed) {
      clearInterval(selfAuthPopupCheckTimer);

      const url = "/auth/user";
      const data = { username: id, password: pwd };

      // 아이디 찾기
      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            const jsonArr = JSON.parse(result.resultData);
            const rows = [];
            for (const row of jsonArr) {
              const stat = row.status;
              rows.push(
                <tr>
                  <td>
                    <span>
                      {stat == 1 ? (
                        <input
                          type="radio"
                          id={row.sn}
                          name="userCertification"
                          value={row.sn}
                        />
                      ) : null}
                      <label for={row.sn}></label>
                    </span>
                  </td>
                  <td> {row.nm} </td>
                  <td> {row.mobile} </td>
                  <td> {row.email} </td>
                  <td>
                    {" "}
                    {stat != 1 ? (
                      <p className="red mb5">{row.statusNm}</p>
                    ) : (
                      row.statusNm
                    )}
                  </td>
                  <td>
                    {stat != 1 ? (
                      <Button
                        buttonName="button-bgc-dark"
                        buttonValue="인증"
                        onChangePage={(e) =>
                          handleAuth(row.realNm, row.realMobile)
                        }
                      ></Button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            }
            setTableRows(rows);

            // <LayerPopLogin data={tableRows} />
            handlePopup(false);
            handlePopup(true);
            handlePopBtn();
            if (checked) {
              setCookie("saveId", id, { maxAge: 2000 });
            } else {
              setCookie("saveId", "", -1);
            }
          } else {
            setErrMsg(result.resultMsg);
          }
        })
        .catch((error) => alert(error));

    }
  }

  const handlePopBtn = (event) => {
    //handlePopup(true);
    if (id == "" || id.length == 0) {
      setErrMsgId("아이디를 입력해 주세요.");
      idInput.current.focus();
      return;
    }

    if (pwd == "" || pwd.length == 0) {
      setErrMsg("비밀번호를 입력해 주세요.");
      pwdInput.current.focus();
      return;
    }

    const url = "/auth/user";
    const data = { username: id, password: pwd };

    // 아이디 찾기
    handleLoadingOn();
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          const rows = [];
          for (const row of jsonArr) {
            const stat = row.status;
            rows.push(
              <tr>
                <td>
                  <span>
                    {stat == 1 ? (
                      <input
                        type="radio"
                        id={row.sn}
                        name="userCertification"
                        value={row.sn}
                      />
                    ) : null}
                    <label for={row.sn}></label>
                  </span>
                </td>
                <td> {row.nm} </td>
                <td> {row.mobile} </td>
                <td> {row.email} </td>
                <td>
                  {" "}
                  {stat != 1 ? (
                    <p className="red mb5">{row.statusNm}</p>
                  ) : (
                    row.statusNm
                  )}
                </td>
                <td>
                  {stat != 1 ? (
                    <Button
                      buttonName="button-bgc-dark"
                      buttonValue="인증"
                      onChangePage={(e) =>
                        handleAuth(row.realNm, row.realMobile)
                      }
                    ></Button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          }
          setTableRows(rows);
          handlePopup(true);

          if (checked) {
            setCookie("saveId", id, { maxAge: 2000 });
          } else {
            setCookie("saveId", "", -1);
          }
        } else {
          if (result.resultCode == "-1") {
            setErrMsg("해당 계정을 찾을 수 없습니다.");
          } else {
            setErrMsg(result.resultMsg);
          }
        }
        handleLoadingClose();
      })
      .catch((error) => {
        handleLoadingClose();
      });
  };

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handlePopBtn();
    }
  }

  return (
    <div className="account-wrap login">
      {loading !=  1 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="account-container">
        <p className="page-stitle">
          HiSMS에 오신 것을 환영합니다. <br />
          <b>로그인</b>해주세요.
        </p>

        <p className="clear"></p>
        <div className="account-contents">
          <div className="check-list-item">
            <div className="input-inner clearfix" id="cropNumArea">
              <div className="title-con">아이디</div>
              <div className="input-con">
                <input
                  type="text"
                  name="username"
                  id="username"
                  maxlength="20"
                  title="ID"
                  className="gs-custom-input fc-event"
                  placeholder="ID"
                  onChange={(e) => {
                    setId(e.target.value);
                    if (e.target.value != "") setErrMsgId("");
                  }}
                  ref={idInput}
                  value={id}
                  onKeyPress={handleEnterPress}
                />
              </div>
              <p className="clear"></p>
            </div>
            <p className="list-item-state">
              <span className="red">{errMsgId}</span>
            </p>
          </div>
          <div className="check-list-item">
            <div className="input-inner clearfix" id="cropNumArea">
              <div className="title-con">비밀번호</div>
              <div className="input-con">
                <input
                  type="password"
                  name="password"
                  id="password"
                  maxlength="100"
                  title="PW"
                  className="gs-custom-input fc-event"
                  placeholder="PW"
                  onChange={(e) => {
                    setPwd(e.target.value);
                    if (e.target.value != "") setErrMsg("");
                  }}
                  ref={pwdInput}
                  onKeyPress={handleEnterPress}
                />
              </div>
              <p className="clear"></p>
            </div>
            <p className="list-item-state">
              <span className="red">{errMsg}</span>
            </p>
          </div>
          <div class="sub-login-bottom-con">
            <div class="id-save-btn">
              <fieldset class="gs-custom-checkbox">
                <span class="checkbox-item">
                  <input
                    type="checkbox"
                    id="saveId"
                    name="saveId"
                    onChange={(e) => {
                      handleCheck(e.target.checked);
                    }}
                    checked={checked ? "checked" : null}
                  />
                  <label for="saveId"> 아이디 저장 </label>
                </span>
              </fieldset>
            </div>
            <ul class="id-search-btn">
              <li>
                <a href="/account/joinStep1">회원가입</a>
                {/* <a
                  href="#"
                  onClick={function (e) {
                    e.preventDefault();
                    alert(
                      "가입 문의는 고객센터 메뉴에서 상담문의를 통해 남겨주세요"
                    );
                  }}
                >
                  회원가입
                </a> */}
              </li>
              <li>
                <a href="/account/findid1">아이디 찾기</a>
              </li>
              <li>
                <a href="/account/findpwd1">비밀번호 찾기</a>
              </li>
              <p className="clear"></p>
            </ul>
            <p className="clear"></p>
          </div>
          <Button
            buttonName="button-bgc-blue mt50 wid100 bbtn"
            buttonValue="로그인"
            onChangePage={handlePopBtn}
          />
          <p className="clear"></p>
        </div>
      </div>

      {popup && (
        <PopUpLayout onClose={handlePopup} poptitle="2단계 인증">
          <LayerPopLogin data={tableRows} />
        </PopUpLayout>
      )}
    </div>
  );
};

export default Login;
