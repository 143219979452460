import React, { useState, useEffect } from "react";
import { KakaoAlrimItemList } from "component/Table";
import { Button, PaginationDynamic } from "component/Common";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopImgUpload2 } from "component/popUp/PopUp";
import { postRequest, getRequest } from "component/Axios";
import _, { set } from "lodash";

export function KakaoItemList(props) {
  const [popup, setPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState("");
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [channel, setChannel] = useState("");
  const [type, setType] = useState([5]);
  const [delList, setDelList] = useState([]);

  const [custType, setCustType] = useState("");
  const [custGrade, setCustGrade] = useState("");
  const [accountList, setAccountList] = useState([]);

  const [search1, setSearch1] = useState("");

  useEffect(() => {
    handlePageChange(1);
    // 카카오톡 채널 조회
    handleChannel();
    handleGetCustType();
    handleSubList();
  }, [search1]);

  const handlePopBtn = (event) => {
    event.preventDefault();
    setPopup(true);
  };

  const handleReSearch = () => {
    setPopup(false);
    handlePageChange(1);
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const guideDownload = (event) => {
  };

  const handleChannel = () => {
    const url = "/kko/getChannel";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChannel(jsonArr);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {});
  };

  const handleGetCustType = () => {
    const url = "/user/custInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const json = JSON.parse(result.resultData);
          setCustType(json.custType);
          setCustGrade(json.custGrade);
        }
      })
      .catch((error) => {});
  };

  const handleSubList = () => {
    const url = "/user/subInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setAccountList(jsonArr);
        }
      })
      .catch((error) => {});
  };

  const handlePageChange = (page) => {
    const url = "/kko/kakaoImgList";
    const data = {
      rowCount: rowCount,
      currentPage: page,
      type: type,
      search1: search1,
    };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);

          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);

          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <div className="container">
        <div className="Info-gray">
          <p className="title">알림톡 아이템 이미지 등록 가이드</p>

          <ul className="Info-wrap">
            <li>
              * 알림톡 발송 시 사용될 '알림톡 아이템 이미지'를 업로드 할 수
              있습니다.
            </li>
            <li>
              *{" "}
              <a
                href="https://kakaobusiness.gitbook.io/main/ad/bizmessage/notice-friend/content-guide/image"
                target="_blank"
                onClick={guideDownload}
                className="blue hover"
              >
                [이미지 알림톡 사용 가이드]
              </a>
              를 참고하여 주시기 바랍니다.
            </li>
            <li>
              * 허용 해상도 : 가로 크기 108px 이상, 가로:세로 비율이 1:1 비율
            </li>
            <li>* 지원 파일형식 및 크기 : png, jpg, jpeg / 최대 500KB</li>
            <li>
              * 이미지를 클릭하여 상세 이미지와 이미지 URL을
              확인하세요.(아이템리스트형 템플릿 등록시 필요)
            </li>
          </ul>
        </div>

        <div className="button-wrap">
          <Button
            buttonName="button-bgc-dark"
            buttonValue="이미지 등록"
            onChangePage={handlePopBtn}
          />
          {custType === "1" && custGrade === "2" ? (
            <span className="rfloat">
              <select
                name="userID"
                id="userID"
                onChange={(e) => {
                  setSearch1(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  계정 ID
                </option>
                <option value="" selected>
                  계정 전체
                </option>
                {accountList.map((info) => (
                  <option value={info.custSn}>{info.custId}</option>
                ))}
              </select>
            </span>
          ) : null}
          {/* 대표계정일 경우만 노출 end */}
        </div>

        <KakaoAlrimItemList
          data={tableRows}
          setDelList={setDelList}
          custGrade={custGrade}
          custType={custType}
        />

        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handlePageChange}
        />
      </div>
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="이미지 업로드">
          <LayerPopImgUpload2
            data={channel}
            handleReSearch={handleReSearch}
            type={type}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export default KakaoItemList;
