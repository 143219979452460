
import React,{useState, useEffect} from "react";
import { SendBox } from "./SendBox";
import { ReservationBox } from "./ReservationBox";


const MessageBox = (props) => {
    const [activeId, setActiveId] = useState(1);
    const [activeContent, setActiveContent] = useState();
    
    const tabMenu = [
        { id: 1, name: "보낸메세지함", content:<SendBox/> },
        { id: 2, name: "예약메세지함", content: <ReservationBox />},
        
    ]

    useEffect(() => {
        let viewType = sessionStorage.getItem("backCheck1");
        
        if(viewType === 'T') {
            setActiveId(1);
            setActiveContent(<SendBox/>);
        } else if(viewType === 'R') {
            setActiveId(2);
            setActiveContent(<ReservationBox />);
        } else {
            setActiveId(1);
            setActiveContent(<SendBox/>);
        }
    }, []);

    


    return (
        <div className="container">
            <ul className="tabmenu">
                {tabMenu.map((value) => (
                    <li className={activeId === value.id ? "active" : null}>
                        <a href="/" onClick={function (e) {
                            e.preventDefault();
                            setActiveId(value.id);
                            setActiveContent(value.content);
                        }}>{value.name}</a>
                    </li>
                ))}
        </ul>
            {activeContent}     
        </div>
    );
};

export default MessageBox;