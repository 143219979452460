import React from "react";

const getSliceByByte = (str, maxByte) => {
  // 허용된 바이트 수까지만 문자열 자르기

  let b,
    i,
    c = 0;
  for (b = i = 0; (c = str.charCodeAt(i)); ) {
    b += c >> 7 ? 2 : 1;

    if (b > maxByte) break;

    i++;
  }

  return str.substring(0, i);
};

export default getSliceByByte;
