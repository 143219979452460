import axios from "axios";
import { SEVER_DOMAIN } from "../utils/constant.js";

axios.defaults.withCredentials = true;

const UNAUTHORIZED = 401;
let setLoginCheck = 0;

const request = (method, url, data) => {
  if (sessionStorage.getItem("urlPath") != null) {
    if (
      window.location.pathname.indexOf(sessionStorage.getItem("urlPath")) === -1
    ) {
      sessionStorage.removeItem("backCheck");
    }
  }
  sessionStorage.setItem("urlPath", window.location.pathname);

  return axios({
    method,
    url: SEVER_DOMAIN + url,
    data,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tk")}` },
  })
    .then((result) => {
      setLoginCheck = 0;
      return result.data;
    })
    .catch((result) => {
      const { status } = result.response;
      if (setLoginCheck === 0) {
        setLoginCheck = 401;
        window.location.replace("/");
        sessionStorage.clear();
        return alert("로그인이 필요합니다.");
      } else if (setLoginCheck === 401) {
        return window.location.replace("/");
      } else {
        return alert(result.data);
      }
    });
};

const requestLogin = (method, url) => {
  return axios({
    method,
    url: SEVER_DOMAIN + url,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tk")}` },
  })
    .then((result) => {
      setLoginCheck = 0;
      return result.data;
    })
    .catch((result) => {
      const { status } = result.response;
      if (status === UNAUTHORIZED) {
        if (setLoginCheck === 0) {
          setLoginCheck = 401;
          window.location.replace("/");
          sessionStorage.clear();
          return alert("로그인이 필요합니다.");
        } else if (setLoginCheck === 401) {
          return window.location.replace("/");
        }
      } else {
        throw result.response;
      }
    });
};

const download = (url, items) => {
  axios({
    method: "POST",
    url: SEVER_DOMAIN + url,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
      "Content-Type": "application/json",
    },
    data: items,
  }).then((res) => {
    let blob = new Blob([res.data], { type: res.headers["content-type"] });
    //let fileName = getFileName(res.headers['content-disposition'])
    let fileName = "test.xlsx";
    fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)

    if (window.navigator.msSaveOrOpenBlob) {
      // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // not IE
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_self";
      if (fileName) link.download = fileName;
      link.click();
    }
  });
};

// POST 요청
export const postRequest = {
  fetch(url, items) {
    setLoginCheck = 0;
    return request("post", url, items);
  },
  downLoad(url, items) {
    return download(url, items);
  },
};

// GET 요청
export const getRequest = {
  fetch(url) {
    setLoginCheck = 0;
    return request("get", url);
  },
};

//로그인 요청
export const auth = {
  login(url) {
    return requestLogin("get", url);
  },
};

//set header token
export const setAuthInHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : null;
};

export async function getPostFetch(url, data) {
  return postRequest.fetch(url, data);
}

export async function getFetch(url) {
  return getRequest.fetch(url);
}
