import styled from "styled-components";

export const PrimaryColor = `#625af6`;

export const Container = styled.div`
  max-width: 1200px;
  min-height: calc(100vh - 200px);
  margin: 20px auto;
  overflow: visible;
`;
