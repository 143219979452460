import React from "react";

const getByte = (str) => {
  // 문자열의 바이트 수 체크
  return str
    .split("")
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
};

export default getByte;
