import React from 'react';
import { Button, PaginationDynamic } from 'component/Common';
import { KakaoProfileList } from 'component/Table';
import { getRequest, postRequest } from 'component/Axios';
import _ from 'lodash';

class List extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    tableRows: '',
    pages: [1],
    endPage: 1,
    leftPage: 1,
    rightPage: 1,
    currentPage: 1,
    rowCount: 10,
    fnlPage: 1,
    plusId: '',
    pfStatus: '',
    allCheck: '',
    custSn: '',
    accountList: [],
  };

  componentDidMount() {
    const page = 1;
    const url = '/kko/profileList';
    const data = { rowCount: this.state.rowCount, currentPage: page };
    postRequest
      .fetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          this.setState({ tableRows: jsonArr });
          const pageItem = JSON.parse(result.resultPage);
          this.setState({ leftPage: pageItem.leftPage });
          this.setState({ rightPage: pageItem.rightPage });
          this.setState({ endPage: pageItem.endPage });
          this.setState({ currentPage: pageItem.currentPage });
          this.setState({ fnlPage: pageItem.fnlPage });
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          this.setState({ pages: pages });
        } else {
          alert(result.resultMsg);
        }
      })
      .catch(error => {});
    this.handleSubList();
  }
  handleSubList() {
    const url = '/user/subInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          this.setState({ accountList: jsonArr });
        }
      })
      .catch(error => {});
  }

  render() {
    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }

    const deleteBtn = event => {
      event.preventDefault();
      let num = 0;
      let sn = [];
      const snNodeList = document.getElementsByName('checkDel');
      snNodeList.forEach(node => {
        if (node.checked) {
          num = num + 1;
          sn.push(node.value);
        }
      });

      if (num === 0) {
        alert('선택된 채널아이디가 없습니다.');
        return;
      }

      if (
        window.confirm(
          '선택된 채널아이디를 삭제 하시겠습니까?\n 삭제 건수 : ' + num
        )
      )
        updateProfile(sn, '1');
    };

    function updateProfile(sn, type) {
      const url = '/kko/profileMod';
      const data = { snList: sn, type: type };

      getPostFetch(url, data)
        .then(result => {
          if (result.resultCode === '0') {
            alert(result.resultMsg);
            handlePageChange(1);
          } else {
            alert(result.resultMsg);
          }
        })
        .catch(error => alert(error));
    }

    const releaseStopBtn = event => {
      event.preventDefault();
      let num = 0;
      let sn = [];
      const snNodeList = document.getElementsByName('checkDel');
      snNodeList.forEach(node => {
        if (node.checked) {
          num = num + 1;
          sn.push(node.value);
        }
      });

      if (num === 0) {
        alert('선택된 채널아이디가 없습니다.');
        return;
      }

      if (
        window.confirm(
          '선택된 채널아이디의 휴면을 해제 하시겠습니까?\n 휴면해제 건수 : ' +
            num
        )
      )
        updateProfile(sn, '2');
    };

    const handlePageChange = page => {
      this.setState({ currentPage: page });

      const url = '/kko/profileList';
      const data = {
        search1: this.state.plusId,
        search2: this.state.pfStatus,
        search3: this.state.custSn,
        rowCount: this.state.rowCount,
        currentPage: page,
      };

      getPostFetch(url, data)
        .then(result => {
          if (result.resultCode === '0') {
            const jsonArr = JSON.parse(result.resultData);
            this.setState({ tableRows: jsonArr });

            const pageItem = JSON.parse(result.resultPage);

            // rowCount 한페이지에 보여질 갯수
            // currentPage 현재 페이지
            // rightPage 오른쪽 화살표 페이지
            // leftPage 왼쪽 화살표 페이지
            // startPage 첫번째 페이지 숫자
            // endPage 보여질 페이지의 마지막 숫자
            // pageCount 화면에 보여질 페이지 갯수
            // fnlPage 마지막 페이지 숫자

            this.setState({ leftPage: pageItem.leftPage });
            this.setState({ rightPage: pageItem.rightPage });
            this.setState({ endPage: pageItem.endPage });
            this.setState({ currentPage: pageItem.currentPage });
            this.setState({ fnlPage: pageItem.fnlPage });

            const pages = _.range(pageItem.startPage, pageItem.endPage + 1);

            this.setState({ pages: pages });
          } else {
            alert(result.resultMsg);
          }
        })
        .catch(error => {});
    };
    return (
      <>
        <div className='kakao-contents'>
          <div className='searchbox'>
            <ul
              className={
                this.props.custType === '1' && this.props.custGrade === '2'
                  ? 'search-col4-group'
                  : 'search-col3-group'
              }
            >
              <li>
                <input
                  type='text'
                  name='channelId'
                  id='channelId'
                  defaultValue=''
                  placeholder='채널 아이디'
                  onChange={e => {
                    this.setState({ plusId: e.target.value });
                  }}
                />
              </li>
              <li>
                <select
                  name='channelStatus'
                  id='channelStatus'
                  onChange={e => {
                    this.setState({ pfStatus: e.target.value });
                  }}
                >
                  <option value='' disabled selected>
                    채널 상태
                  </option>
                  <option value='A'>정상</option>
                  <option value='S1'>휴면</option>
                  <option value='S'>차단</option>
                </select>
              </li>
              {this.props.custType === '1' && this.props.custGrade === '2' ? (
                <li>
                  <select
                    name='userID'
                    id='userID'
                    onChange={e => {
                      this.setState({ custSn: e.target.value });
                    }}
                  >
                    <option value='' selected>
                      계정 전체
                    </option>
                    {this.state.accountList.map(info => (
                      <option value={info.custSn}>{info.custId}</option>
                    ))}
                  </select>
                </li>
              ) : null}

              <li>
                <Button
                  buttonName='button-bgc-dark'
                  buttonValue='검색'
                  onChangePage={() => {
                    handlePageChange(1);
                  }}
                />
              </li>
            </ul>
          </div>
          {/* <div className="searchbox">
                        <span>채널(채널 아이디)</span>
                        <input type="text" name="" defaultValue="" />
                        
                        <span>채널 상태</span>
                        <select name="channel_id" id="">
                            <option value="">정상</option>
                            <option value="">휴면</option>
                        </select>
                        <Button buttonName="button-bgc-dark" buttonValue="검색"/>
                    </div> */}

          <div className='button-wrap'>
            <Button
              buttonName='button-bgc-gray'
              buttonValue='선택 삭제'
              onChangePage={deleteBtn}
            />
            <Button
              buttonName='button-border-gray'
              buttonValue='휴면 해제'
              onChangePage={releaseStopBtn}
            />
          </div>

          <KakaoProfileList
            data={this.state.tableRows}
            custGrade={this.props.custGrade}
            custType={this.props.custType}
          />
          <PaginationDynamic
            pages={this.state.pages}
            currentPage={this.state.currentPage}
            leftPage={this.state.leftPage}
            rightPage={this.state.rightPage}
            endPage={this.state.endPage}
            fnlPage={this.state.fnlPage}
            onPageChange={handlePageChange}
          />
        </div>
      </>
    );
  }
}

export default List;
