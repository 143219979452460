import React , { useEffect, useState } from "react";
import { SendTextMessage, PreviewMessage } from "./SendMessageTextMessage";
import { SendKakaoTalk } from "./SendKakaoTalk";
import { PreviewAlrimTalk } from "./SendKakaoAlrimTalk";
import { SendRCS, PreviewRCS } from "./SendRCS";
import Slider from "react-slick";
import { SendElectionMessage, PreviewElectionMessage } from "./SendElectionMessage";
import PreViewBGI from "assets/images/preview.png";

export const SendMessage = () => {
  const [isPreviewImg, setIsPreviewImg] = useState(false);
    const [msgType, setMsgType] = useState(<SendTextMessage setIsPreviewImg={setIsPreviewImg}/>);
    const [previewImg, setPreviewImg] = useState(<PreviewMessage isPreviewImg={isPreviewImg}/>);
    const [chekedDefault, setChekedDefault] = useState(1);
    const [previewType, setPreviewType] = useState('N');

    useEffect(()=> {
      setPreviewImg(<PreviewMessage isPreviewImg={isPreviewImg}/>);
    }, [isPreviewImg]);

    const MessageSendType = [
        { id: 1, value: "기업메시징", content : <SendTextMessage setIsPreviewImg={setIsPreviewImg}/>, img : <PreviewMessage isPreviewImg={isPreviewImg}/> },
        { id: 2, value: '카카오 메시지', content: <SendKakaoTalk setPreviewImg={setPreviewImg}/>, img: <PreviewAlrimTalk />},
        { id: 3, value : "RCS 메시지", content : <SendRCS previewType={setPreviewType}/>, img : <PreviewRCS/>}
      
    ]

    const style = {
        button:{
            position: 'relative',
            margin: '0 auto'
        },
        test : {
            marginTop:'-485px'
        }
    };
    return (
        <div className="container sendmessage">
            
            
          <div className="lfloat">
            <div>
              <p className="page-title">문자보내기</p>
            </div>
            <div className="write-left-inner">
              <div className="write-tit-con clearfix">
                <div className="tit-left select-tit">
                  <span className="required">전송유형</span>
                </div>
                <div className="tit-right">
                  <div className="gs-custom-select buttonWith">
                    <div className="Radiobox">
                      {MessageSendType.map((value) => (
                        <>
                          <input
                            type="radio"
                            name={`sendType${value}`}
                            id={value.id}
                            key={value.id}
                            checked={chekedDefault === value.id}
                            onClick={function (e) {
                              if(sessionStorage.getItem("alim") != 'Y' || value.id == '3') {
                                alert("준비중입니다.");
                                setMsgType(<SendTextMessage setIsPreviewImg={setIsPreviewImg}/>);
                                setChekedDefault(1);
                                setPreviewImg( <PreviewMessage isPreviewImg={isPreviewImg}/>);
                              } else {
                                setMsgType(value.content);
                                setChekedDefault(value.id);
                                setPreviewImg(value.img);
                              }
                            }}
                          />
                         
                          <label htmlFor={value.id}>{value.value}</label>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {msgType}
            </div>
          </div>
          
          <div className="rfloat">
              
          <div style={style.button}>
              <img src={PreViewBGI} alt="미리보기 이미지" />
              <div style={style.test}>
              </div>
              {previewImg}
            </div>
          </div>
          <p className="clear"></p>
          
        </div>
      );
    };


