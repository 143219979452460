// 전역 상태 관리
import React from "react";
import { atom } from "recoil";

const loginState = atom({
    key: "login",
    default: false,
});

const paymentState = atom({
    key: "before",
    default: true,
});

const headerState = atom({
    key: "header",
    default: 0,
});

const myHeaderState = atom({
    key: "myHeader",
    default: 0,
})

const subHeaderState = atom({
    key: "subHeader",
    default: 0,
})

const rootState = atom({
    key: "url",
    default: "",
});



export { loginState, paymentState, headerState, rootState, myHeaderState,subHeaderState};
