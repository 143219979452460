import React, { useState, useEffect } from 'react';
import { KakaoFriendList } from 'component/Table';
import { PopUpLayout } from 'component/popUp/PopUpLayout';
import { LayerPopImgUpload } from 'component/popUp/PopUp';
import { Button, PaginationDynamic } from 'component/Common';
import { postRequest, getRequest } from 'component/Axios';
import _, { set } from 'lodash';

export function KakaoFriend(props) {
  const [popup, setPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState('');
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [channel, setChannel] = useState('');
  const [type, setType] = useState(2);
  const [delList, setDelList] = useState([]);

  const [custType, setCustType] = useState('');
  const [custGrade, setCustGrade] = useState('');
  const [accountList, setAccountList] = useState([]);

  const [search1, setSearch1] = useState('');

  useEffect(() => {
    handlePageChange(1);
    // 카카오톡 채널 조회
    handleChannel();
    handleGetCustType(); //cust 정보
    handleSubList(); // 서브계정
  }, [search1]);

  const handlePopImg = nType => {
    setType(nType);
    setPopup(true);
  };

  const handleReSearch = () => {
    setPopup(false);
    handlePageChange(1);
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleChannel = () => {
    const url = '/kko/getChannel';
    getFetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setChannel(jsonArr);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {});
  };

  const handleGetCustType = () => {
    const url = '/user/custInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const json = JSON.parse(result.resultData);
          setCustType(json.custType);
          setCustGrade(json.custGrade);
        }
      })
      .catch((error) => {});
  };

  const handleSubList = () => {
    const url = '/user/subInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setAccountList(jsonArr);
        }
      })
      .catch((error) => {});
  };

  const handlePageChange = page => {
    const url = '/kko/kakaoImgList';
    const data = {
      rowCount: rowCount,
      currentPage: page,
      type: [2, 3],
      search1: search1,
    };

    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);

          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);

          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {});
  };

  const handleDelete = () => {
    if (delList.length === 0) {
      alert('선택된 이미지가 없습니다.');
      return;
    }

    if (window.confirm('선택된 이미지를 삭제 하시겠습니까?')) deleteImage();
  };

  function deleteImage() {
    const url = '/kko/imageDelete';
    const data = { imgList: delList };

    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          alert(result.resultMsg);
          handlePageChange(1);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  }

  return (
    <div>
      <div className='container'>
        <div className='Info-gray'>
          <p className='title'>친구톡 이미지 등록 가이드</p>

          <ul className='Info-wrap'>
            <li>* 친구 발송 시 사용될 이미지를 업로드 할 수 있습니다.</li>
            <li>* 권장 해상도 : 720px * 720px</li>
            <li>
              * 허용 해상도 : 가로 크기 500px 이상, 가로:세로 비율이 2:1 (가로가
              긴 타입)부터 3:4 (세로가 긴 타입) 비율까지 업로드 가능합니다.
            </li>
            <li>* 지원 파일형식 및 크기 : png, jpg, jpeg / 최대 500KB</li>
            <li>
              * 와이드형 이미지 허용 해상도 : 가로 크기 800px, 세로 크기 600px만
              업로드 가능합니다
            </li>
            <li>
              * 와이드형 이미지 지원 파일형식 및 크기 : png, jpg, jpeg / 최대
              2MB
            </li>
            <li>
              * 이미지를 클릭하여 상세 이미지와 이미지URL(친구톡 업로드 발송시
              필요)을 확인하세요.
            </li>
          </ul>
        </div>

        <div className='button-wrap'>
          <Button
            buttonName='button-bgc-gray'
            buttonValue='선택 삭제'
            onChangePage={handleDelete}
          />
          <Button
            buttonName='button-bgc-dark'
            buttonValue='이미지 업로드'
            onChangePage={e => {
              handlePopImg(2);
            }}
          />
          <Button
            buttonName='button-bgc-dark'
            buttonValue='와이드 이미지 업로드'
            onChangePage={e => {
              handlePopImg(3);
            }}
          />
          {/* 대표계정일 경우만 노출 */}

          {custType === '1' && custGrade === '2' ? (
            <span className='rfloat'>
              <select
                name='userID'
                id='userID'
                onChange={e => {
                  setSearch1(e.target.value);
                }}
              >
                <option value='' disabled selected>
                  계정 ID
                </option>
                <option value='' selected>
                  계정 전체
                </option>
                {accountList.map(info => (
                  <option value={info.custSn}>{info.custId}</option>
                ))}
              </select>
            </span>
          ) : null}
          {/* 대표계정일 경우만 노출 end */}
        </div>

        <KakaoFriendList
          data={tableRows}
          setDelList={setDelList}
          custGrade={custGrade}
          custType={custType}
        />

        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handlePageChange}
        />
      </div>

      {/* 여기 팝업을 밖으로  */}
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle='이미지 업로드'>
          <LayerPopImgUpload
            data={channel}
            type={type}
            handleReSearch={handleReSearch}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export default KakaoFriend;
