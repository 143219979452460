import React from "react";
import { Button } from "component/Common";
import { postRequest } from "component/Axios";

export class SendBoxView extends React.Component {
    state = { isActive: false , sendData : [], textLen: 0};

    componentDidMount (){
        const tabSeq = sessionStorage.getItem("sendView");
        let url = "/message/sendView";
        let data = { search1 : tabSeq }
    	postRequest.fetch(url,data).then(result => {
                if( result.resultCode === "0" ) {
                    const jsonArr = JSON.parse(result.resultData);
                    //this.setState({groupList : jsonArr})
                    //this.setState({totCnt : jsonArr[0].totCnt})
                    //this.setState({agCnt : jsonArr[0].totCnt})
                    this.setState({sendData : jsonArr[0]});
                    if(this.state.sendData.suc === '대체성공' || this.state.sendData.suc === '대체실패') {
                        this.setState({textLen : jsonArr[0].replaceText.length});
                    } else {
                        this.setState({textLen : jsonArr[0].text.length});
                    }
                    
                } else {
                    alert(result.resultMsg);
                }
            }
        )
        .catch(error => alert(error))
        
    }

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    handleReSend = (event) => {
        event.preventDefault();
    };

    render() {
        const isActive = this.state.isActive;

        return (
            <div className="container sendboxview">
                <p className="page-title">보낸메시지함 상세보기</p>
                <div className="button-wrap">
                    <Button
                        buttonName="button-border-gray"
                        buttonValue="뒤로가기"
                        onChangePage={function () {
                        sessionStorage.setItem("backCheck", 'T');
                        window.location.href = "/main/messagebox";
                    }}
                    />
                </div>
                <div className="sendboxviewInner">
                    <div className="msgboxContent msgcontext">
                        <div className="content">
                            {
                                this.state.sendData.suc === '대체성공' || this.state.sendData.suc === '대체실패' ?
                                this.state.sendData.replaceText
                                : this.state.sendData.text
                            }
                            
                            <div className="content_info">
                                <span className="rfloat gray999">{this.state.textLen}/2000</span>
                                <p className="clear"></p>
                            </div>
                        </div>
                    </div>
                    <ul className="msgboxContent msgSendInfo">
                        <li className="msgResult">
                            <div className="lfloat">전송결과</div>
                            <div className="rfloat blue">{this.state.sendData.suc}</div>
                        </li>
                        <li>
                            <div className="lfloat">발신번호</div>
                            <div className="rfloat">{this.state.sendData.callback}</div>
                        </li>
                        <li>
                            <div className="lfloat">수신번호</div>
                            <div className="rfloat">{this.state.sendData.dstaddr}</div>
                        </li>
                        <li>
                            <div className="lfloat">전송시간</div>
                            <div className="rfloat">{this.state.sendData.requestDt}</div>
                        </li>
                        <li>
                            <div className="lfloat">메세지유형</div>
                            <div className="rfloat">{this.state.sendData.typeNm}</div>
                        </li>
                        {/*<li>
                            <div className="lfloat">발송 요청 건수</div>
                            <div className="rfloat">10건</div>
                        </li>
                        <li>
                            <div className="lfloat">발송 요금</div>
                            <div className="rfloat">400원</div>
                        </li>
                        */}
                        <p className="clear"></p>
                    </ul>
                    <p className="clear"></p>
                </div>
            </div>
        );
    }
}