import React, { Component, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PasswordCheck from './PasswordCheck';
import RefundRequest from './RefundReqeust';
import { Button } from 'component/Common';
import { AccountCharge, SignOutCharge } from 'component/Table';
import { postRequest, getRequest } from 'component/Axios';
import { loginState } from '../../../../utils/state';
import { useRecoilState } from 'recoil';
export default function SignOutMember(props) {
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const navigate = useNavigate();
  const changeState = props.changeState;
  const signOutInfo = props.signOutInfo;
  const [signOutData, setSignOutData] = useState({ ...signOutInfo });
  const [refundGiveUpState, setREfundGiveUpState] = useState(false);
  const [codeRows, setCodeRows] = useState([]);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  const logout = () => {
    sessionStorage.clear();
    // 로그아웃 이력 저장
    const url = '/auth/logout';
    getFetch(url)
      .then(result => {
      })
      .catch(error => alert(error));
  };
  const handleSignOut = e => {
    e.preventDefault();
    /* 잔액이 남아있는 경우 & 체크박스 체크안했을 경우에만 노출  */
    if (refundGiveUpState) {
      if (Number(signOutInfo.amount) !== 0) {
        if (
          window.confirm('충전금이 남아 있습니다. 탈퇴를 진행하시겠습니까?')
        ) {
        } else {
          return;
        }
      }
    } else {
      if (window.confirm('탈퇴를 진행하시겠습니까?')) {
      } else {
        return;
      }
    }
    if (signOutData.reasonType === '') {
      alert('탈퇴사유를 선택하세요!');
      return;
    } else if (signOutData.reasonType === '4') {
      if (signOutData.remark === null || signOutData.remark === '') {
        alert('탈퇴사유를 입력해주세요!');
        return;
      }
    }
    const url = '/user/signOut';
    const data = signOutData;
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          logout();
          const jsonArr = JSON.parse(result.resultData);
          setIsLogin(false);
          navigate('/mypage/signOutCompleted', {
            state: { signoutSucInfo: jsonArr },
          });
        } else {
          const jsonArr = result;
          alert(jsonArr.resultMsg);
        }
      })
      .catch(error => alert(error));
    /* 잔액이 남아있는 경우 & 체크박스 체크안했을 경우에만 노출 end  */
    /* 잔액이 없는 경우 or 체크박스 체크 했을 경우에 로그아웃 및 재로그인 안되게 처리해주세요. */
  };
  const handleRefundGiveUpCheck = e => {
    if (e.target.checked) {
      setREfundGiveUpState(true);
    } else {
      setREfundGiveUpState(false);
    }
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleCommonCode = () => {
    const url = '/getCommonCode';
    const snList = [1031];
    const data = { snList: snList };
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch(error => alert(error));
  };
  const handleSetSignOutData = e => {
    const { name, value } = e.target;
    setSignOutData({ ...signOutData, [name]: value });
  };
  useEffect(() => {
    handleCommonCode();
  }, []);
  return (
    <div className='container'>
      <div className='Info-gray'>
        <p className='title'>탈퇴안내</p>
        <ul className='Info-wrap'>
          <li>
            * 계정 탈퇴를 신청하시면 해당 아이디는 탈퇴 처리되며,
            회원목록/그룹/주소록 등 저장된 모든 정보가 삭제됩니다.
          </li>
          <li>* 탈퇴 후에는 충전금 환불이 되지 않습니다.(선불고객)</li>
          <li>* 미납금액이 있을 경우 탈퇴처리가 불가합니다.(후불고객)</li>
          <li>
            * 탈퇴여부와 상관없이 새로운 아이디를 사용하면 기존의 사업자로
            재가입할 수 있습니다. 다만, 같은 아이디로는 재가입이 불가능합니다.
          </li>
        </ul>
      </div>
      <p className='page-subtitle'>
        <p className='title'>잔액정보</p>
        <span className='rfloat'>
          <div className='button-wrap'>
            <Button
              buttonName='button-bgc-blue'
              buttonValue='환불신청'
              onChangePage={changeState}
            />
          </div>
        </span>
      </p>
      <AccountCharge
        signOutInfo={signOutInfo}
        codeRows={codeRows}
        handleSetSignOutData={handleSetSignOutData}
      />
      <p className='mt20 mb20'>
        <label className='cursor'>
          <input
            type='checkbox'
            name=''
            defaultValue=''
            className='signoutCheck'
            onChange={e => handleRefundGiveUpCheck(e)}
          />
          별도의 환불절차 없이 충전금 전액을 포기하고 탈퇴 신청 하겠습니다.
        </label>
      </p>
      <p className='gray999'>
        탈퇴 하시는 사유가 기능상의 에러나 구현되지 않아 불편하신 경우
        전화번호를 남겨주시면 상담후 해결 해 드리겠습니다.
        <NavLink to='/customer/contactUs'>
          <Button
            buttonName='button-border-gray ssbtn ml10'
            buttonValue='상담하기'
          />
        </NavLink>
      </p>
      <div className='button-wrap center'>
        <Button
          buttonName='button-bgc-blue'
          buttonValue='탈퇴하기'
          /* onChangePage={function () {
              window.location.href = "";
            }} */
          onChangePage={handleSignOut}
        />
      </div>
    </div>
  );
}