import { postRequest, getRequest } from "component/Axios";
import React,{useState, useEffect} from "react";



export const RCSTemplate = (props) => {


    async function getFetch(url) {
        return getRequest.fetch(url);
    }
    
    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    return (
        <> 
        <div id="templateType">
            <div className="write-left-inner receive-area">
            </div>
            </div>
            <div className="write-tit-con clearfix" id="asdasd">
          </div>
            <p className="line"></p>
          
          

          
        </>
    )
};
