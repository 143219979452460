import React, {useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export function PopUpLayout(props) {
    const { onClose } = props;

    // 모달 오버레이에서 스크롤 방지
    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
        return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    return ( 
    <div  className="dim-layer" id="popup1">
        <div className="dimBg"></div>
        <div className={"pop-layer " + props.size}>
            <div className="pop-container">

                {/* content */}
                <div className="pop-header">
                    <span className="title">{props.poptitle}</span>
                        <a className="popup-close" onClick={() => {
                        onClose(false);
                    }} ><FontAwesomeIcon icon={faTimes} /></a>
                </div>

                <div className="pop-contns-box">
                    <div className="box-inner">
                        <div className="pop-box">

                            <div className="pop-con">
                                { props.children }
                            </div>
                            {/* //팝업내용 */}

                        </div>
                    </div>
                </div>
                {/* // content */}

            </div>
        </div>
    </div>
    )
 }