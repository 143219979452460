import React, { useCallback } from "react";
import styled from "styled-components";

function LoadReceiveNumberList({ data, snList, setSnList }) {
  // 전체 checked
  const handleAllCheck = useCallback(
    (checked) => {
      if (checked) {
        const newArr = [];
        data.map((item) => newArr.push({ RegId: item.RegId, sn: item.tsSn }));
        setSnList(newArr);
      } else {
        setSnList([]);
      }
    },
    [data]
  );

  // 개별 check
  const handleCheked = useCallback(
    (checked, tsRegId, tsSn) => {
      if (checked) {
        setSnList([...snList, { RegId: tsRegId, sn: tsSn }]);
      } else {
        // 체크 해제
        setSnList(snList.filter((el) => el.sn !== tsSn));
      }
    },
    [snList]
  );

  return (
    <>
      <ListTable>
        <colgroup>
          <col width="31px" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
        </colgroup>
        <thead>
          <tr>
            <Th>
              <input
                type="checkbox"
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  snList.length === 0
                    ? false
                    : snList.length === data.length
                    ? true
                    : false
                }
              />
            </Th>
            <Th>수신번호</Th>
            <Th>#&#123;이름&#125;</Th>
            <Th>#&#123;1&#125;</Th>
            <Th>#&#123;2&#125;</Th>
            <Th>#&#123;3&#125;</Th>
            <Th>#&#123;4&#125;</Th>
          </tr>
        </thead>

        {!data || data === undefined || data === [] || data.length === 0 ? (
          <tbody>
            <tr>
              <Td colSpan="8">불러 올 파일정보가 없습니다.</Td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data.map((row, index) => (
              <tr key={row.tsSn}>
                <Td>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      handleCheked(
                        e.currentTarget.checked,
                        row.tsRegId,
                        row.tsSn
                      );
                    }}
                    checked={
                      snList.some((item) => item.sn === row.tsSn) ? true : false
                    }
                  />
                </Td>
                <Td> {row.tsPhone} </Td>
                <Td> {row.tsNm} </Td>
                <Td> {row.tsEtc1} </Td>
                <Td> {row.tsEtc2} </Td>
                <Td> {row.tsEtc3} </Td>
                <Td> {row.tsEtc4} </Td>
              </tr>
            ))}
          </tbody>
        )}
      </ListTable>
    </>
  );
}

export default LoadReceiveNumberList;

const ListTable = styled.table`
  margin: 0;
  text-align: center;
  font-size: 15px;
  width: max-content !important;
  min-width: 100%;

  &:: tr:nth-child(2) td {
    border-top: 1px solid #ccc;
  }
`;

const Th = styled.th`
  background-color: #ececec;
  border-right: 1px solid rgb(247, 247, 247);
  color: #222;
  font-weight: 400;
  white-space: nowrap;
  padding: 6px 2px;
  width: 70px;

  &:last-child {
    border-right: 0;
  }
`;

const Td = styled.td`
  background-color: #ffffff;
  color: #777;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 6px 2px;
  width: 70px;
  max-width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
