import React, { useState, useEffect } from "react";
import { SendBoxTable } from "component/Table";
import { Button, PaginationDynamic } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'rsuite';
import { postRequest, getRequest } from "component/Axios";
import { addDays } from "date-fns"
import moment from "moment"
import _ from 'lodash';
import axios from 'axios' 
import { SEVER_DOMAIN } from 'utils/constant.js';
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  datepicker: {
    color: "#f5f5f5",
    
  },
}));

export const SendBox = () => {
  const classes = useStyles();
  const [callbackList, setCallbackList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search1, setSearch1] = useState('');
  const [search2, setSearch2] = useState('');
  const [search3, setSearch3] = useState('');
  const [search4, setSearch4] = useState('');
  const [search5, setSearch5] = useState('');
  const [snList, setSnList] = useState([]);
  const [value, onChange] = useState([new Date(), new Date()]);
  const [custType, setCustType] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
  };

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  }

  const DownloadSendHistory = (event) => {
    event.preventDefault();
    const cnt = snList.length;
    if ( snList.length === 0 ) {
        alert("선택된 내역이 없습니다.\n(다운로드 받을 전송내역을 검색결과에서 선택해 주세요.)");
        return;
    }

    if ( value.length === 0 ) {
        alert("발송일은 필수선택사항 입니다.");
        return;
    }


    let month0 = value[0].getMonth() + 1;
      if ( month0 < 10 )
        month0 = "0" + month0;

      let day0 = value[0].getDate();
      if ( day0 < 10 )
        day0 = "0" + day0;

      let month1 = value[1].getMonth() + 1;
      if ( month1 < 10 )
        month1 = "0" + month1;
      
      let day1 = value[1].getDate();
      if ( day1 < 10 )
        day1 = "0" + day1;  

      const startDay = value[0].getFullYear() + '-' + month0 + '-' + day0;
      const endDay = value[1].getFullYear() + '-' + month1 + '-' + day1;
      
    if( window.confirm("선택된 전송내역을 다운로드 받으시겠습니까? \n(선택 내역 : " +cnt+ ")") ) {
      //const url = "/message/sendListDownload";
      //const data = { strList : snList }
      let fileName = "전송내역.xlsx";
      handleLoadingOn();
          axios({
              method: 'POST',
              url: SEVER_DOMAIN + '/message/sendListDownload',
              responseType: 'blob',
              headers: {
                  Authorization :  `Bearer ${sessionStorage.getItem("tk")}`,
                  "Content-Type": "application/json"
              },   
              data: { strList : snList , dayList : value , search6 : startDay, search7 : endDay  }
          })
          .then(res =>{
              let blob = new Blob([res.data], { type: res.headers['content-type'] })
              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                  window.navigator.msSaveOrOpenBlob(blob, fileName)
              } else { // not IE
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.target = '_self'
                  if (fileName) link.download = fileName
                  link.click()
              }
          })
          .catch(error => alert("파일 다운로드를 실패하였습니다."))
            handleLoadingClose();
    }

  };

  // 2023.12.18 메시지함 검색된 리스트 전체 엑셀다운로드 - jmkil
  const DownloadSendHistoryAll = (event) => {
    event.preventDefault();

    let reqCustId = '';
    let reqSearch1 = '';
    let reqSearch2 = '';
    let reqSearch3 = '';
    let reqSearch4 = '';
    let reqSearch5 = '';
    let reqDate = [new Date(), new Date()];

    if(sessionStorage.getItem("backCheck") === 'T') {
      sessionStorage.removeItem("backCheck");

      if(sessionStorage.getItem("saveCustId") === "undefined" || sessionStorage.getItem("saveCustId") === null || sessionStorage.getItem("saveCustId") === "") {
        reqCustId = custId;
      } else {
        reqCustId = sessionStorage.getItem("saveCustId");
        setCustId(sessionStorage.getItem("saveCustId"));
      }

      if(sessionStorage.getItem("saveMsgType") === "undefined" || sessionStorage.getItem("saveMsgType") === null || sessionStorage.getItem("saveMsgType") === "") {
        reqSearch1 = search1;
      } else {
        document.getElementById("sendType").value = sessionStorage.getItem("saveMsgType");
        reqSearch1 = sessionStorage.getItem("saveMsgType");
        setSearch1(sessionStorage.getItem("saveMsgType"));
      }

      if(sessionStorage.getItem("saveCallBack") === "undefined" || sessionStorage.getItem("saveCallBack") === null|| sessionStorage.getItem("saveCallBack") === "") {
        reqSearch2 = search2;
      } else {
        document.getElementById("callback").value = sessionStorage.getItem("saveCallBack");
        reqSearch2 = sessionStorage.getItem("saveCallBack");
        setSearch2(sessionStorage.getItem("saveCallBack"));
      }

      if(sessionStorage.getItem("saveResultType") === "undefined" || sessionStorage.getItem("saveResultType") === null || sessionStorage.getItem("saveResultType") === "") {
        reqSearch3 = search3;
      } else {
        document.getElementById("resultType").value = sessionStorage.getItem("saveResultType");
        reqSearch3 = sessionStorage.getItem("resultType");
        setSearch3(sessionStorage.getItem("saveResultType"));
      }

      if(sessionStorage.getItem("saveText") === "undefined" || sessionStorage.getItem("saveText") === null || sessionStorage.getItem("saveText") === "") {
        reqSearch4 = search4;
      } else {
        document.getElementById("value5").value = sessionStorage.getItem("saveText");
        reqSearch4 = sessionStorage.getItem("saveText");
        setSearch4(sessionStorage.getItem("saveText"));
      }

      if(sessionStorage.getItem("saveDstaddr") === "undefined" || sessionStorage.getItem("saveDstaddr") === null || sessionStorage.getItem("saveDstaddr") === "") {
        reqSearch5 = search5;
      } else {
        document.getElementById("value6").value = sessionStorage.getItem("saveDstaddr");
        reqSearch5 = sessionStorage.getItem("saveDstaddr");
        setSearch5(sessionStorage.getItem("saveDstaddr"));
      }

      if(sessionStorage.getItem("dateStart") === "undefined" || sessionStorage.getItem("dateStart") === null || sessionStorage.getItem("dateStart") === "") {
        reqDate = value;
      } else {
        reqDate = [new Date(sessionStorage.getItem("dateStart")), new Date(sessionStorage.getItem("dateEnd"))];
        onChange([new Date(sessionStorage.getItem("dateStart")), new Date(sessionStorage.getItem("dateEnd"))])
      }
    } else {
      reqCustId = custId;
      reqSearch1 = search1;
      reqSearch2 = search2;
      reqSearch3 = search3;
      reqSearch4 = search4;
      reqSearch5 = search5;
      reqDate = value;
    }

    if ( value.length === 0 ) {
        alert("발송일은 필수선택사항 입니다.");
        return;
    }

    let month0 = value[0].getMonth() + 1;
      if ( month0 < 10 )
        month0 = "0" + month0;

      let day0 = value[0].getDate();
      if ( day0 < 10 )
        day0 = "0" + day0;

      let month1 = value[1].getMonth() + 1;
      if ( month1 < 10 )
        month1 = "0" + month1;
      
      let day1 = value[1].getDate();
      if ( day1 < 10 )
        day1 = "0" + day1;  

      const startDay = value[0].getFullYear() + '-' + month0 + '-' + day0;
      const endDay = value[1].getFullYear() + '-' + month1 + '-' + day1;

      const callBackurl = "/callback/getSelect/"
      const myInfoUrl = "/user/myInfo";
      const subInfoUrl = "/user/subInfo";
      getFetch(callBackurl)
        .then(result => {
          if( result.resultCode === "0" ) {
              const jsonArr = JSON.parse(result.resultData);
              setCallbackList(jsonArr);
          }
        })
      .catch(error => alert(error))
    
      getFetch(myInfoUrl)
      .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                setCustType(jsonArr.custType);

                if(jsonArr.custType === "1") {
                  getFetch(subInfoUrl)
                  .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        setCustIdList(jsonArr);
                    }
                  })
                    .catch(error => alert(error))
                  
                  if (window.confirm("검색된 전송내역을 다운로드 받으시겠습니까?")) {
            let fileName = "전송내역(전체).xlsx";
            handleLoadingOn();
          axios({
            method: 'POST',
            url: SEVER_DOMAIN + '/message/sendListDownloadAll',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
              "Content-Type": "application/json"
            },
            data: {
              rowCount: rowCount, currentPage: null, dayList: reqDate, search1: reqSearch1, search2: reqSearch2,
              search3: reqSearch3, search4: reqSearch4, search5: reqSearch5, search6: startDay, search7: endDay, search8: jsonArr.custType, search9: reqCustId
            }
          })
            .then(res => {
              let blob = new Blob([res.data], { type: res.headers['content-type'] })
              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                window.navigator.msSaveOrOpenBlob(blob, fileName)
              } else { // not IE
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.target = '_self'
                if (fileName) link.download = fileName
                link.click()
              }
            })
            .catch(error => alert("파일 다운로드를 실패하였습니다."))
            handleLoadingClose();
          }
                }
            }
          }
      )
      .catch(error => handleLoadingClose())
    
          
  };

  async function getPostFetch(url,data) {
      return postRequest.fetch(url,data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handlePageChange(1);
    }
  }


  const handlePageChange = page => {
    let reqPage = 1;
    let reqCustId = '';
    let reqSearch1 = '';
    let reqSearch2 = '';
    let reqSearch3 = '';
    let reqSearch4 = '';
    let reqSearch5 = '';
    let reqDate = [new Date(), new Date()];
    if(sessionStorage.getItem("backCheck") === 'T') {
      sessionStorage.removeItem("backCheck");
      reqPage = sessionStorage.getItem("pages");

      if(sessionStorage.getItem("saveCustId") === "undefined" || sessionStorage.getItem("saveCustId") === null || sessionStorage.getItem("saveCustId") === "") {
        reqCustId = custId;
      } else {
        reqCustId = sessionStorage.getItem("saveCustId");
        setCustId(sessionStorage.getItem("saveCustId"));
      }

      if(sessionStorage.getItem("saveMsgType") === "undefined" || sessionStorage.getItem("saveMsgType") === null || sessionStorage.getItem("saveMsgType") === "") {
        reqSearch1 = search1;
      } else {
        document.getElementById("sendType").value = sessionStorage.getItem("saveMsgType");
        reqSearch1 = sessionStorage.getItem("saveMsgType");
        setSearch1(sessionStorage.getItem("saveMsgType"));
      }

      if(sessionStorage.getItem("saveCallBack") === "undefined" || sessionStorage.getItem("saveCallBack") === null|| sessionStorage.getItem("saveCallBack") === "") {
        reqSearch2 = search2;
      } else {
        document.getElementById("callback").value = sessionStorage.getItem("saveCallBack");
        reqSearch2 = sessionStorage.getItem("saveCallBack");
        setSearch2(sessionStorage.getItem("saveCallBack"));
      }

      if(sessionStorage.getItem("saveResultType") === "undefined" || sessionStorage.getItem("saveResultType") === null || sessionStorage.getItem("saveResultType") === "") {
        reqSearch3 = search3;
      } else {
        document.getElementById("resultType").value = sessionStorage.getItem("saveResultType");
        reqSearch3 = sessionStorage.getItem("resultType");
        setSearch3(sessionStorage.getItem("saveResultType"));
      }

      if(sessionStorage.getItem("saveText") === "undefined" || sessionStorage.getItem("saveText") === null || sessionStorage.getItem("saveText") === "") {
        reqSearch4 = search4;
      } else {
        document.getElementById("value5").value = sessionStorage.getItem("saveText");
        reqSearch4 = sessionStorage.getItem("saveText");
        setSearch4(sessionStorage.getItem("saveText"));
      }

      if(sessionStorage.getItem("saveDstaddr") === "undefined" || sessionStorage.getItem("saveDstaddr") === null || sessionStorage.getItem("saveDstaddr") === "") {
        reqSearch5 = search5;
      } else {
        document.getElementById("value6").value = sessionStorage.getItem("saveDstaddr");
        reqSearch5 = sessionStorage.getItem("saveDstaddr");
        setSearch5(sessionStorage.getItem("saveDstaddr"));
      }

      if(sessionStorage.getItem("dateStart") === "undefined" || sessionStorage.getItem("dateStart") === null || sessionStorage.getItem("dateStart") === "") {
        reqDate = value;
      } else {
        reqDate = [new Date(sessionStorage.getItem("dateStart")), new Date(sessionStorage.getItem("dateEnd"))];
        onChange([new Date(sessionStorage.getItem("dateStart")), new Date(sessionStorage.getItem("dateEnd"))])
      }
    } else {
      reqPage = page;
      reqCustId = custId;
      reqSearch1 = search1;
      reqSearch2 = search2;
      reqSearch3 = search3;
      reqSearch4 = search4;
      reqSearch5 = search5;
      reqDate = value;
    }    
    
      if ( reqDate.length ===  0 ) {
          alert("발송일은 필수선택사항 입니다.");
          return;
      }

      let month0 = reqDate[0].getMonth() + 1;
      if ( month0 < 10 )
        month0 = "0" + month0;

      let day0 = reqDate[0].getDate();
      if ( day0 < 10 )
        day0 = "0" + day0;

      let month1 = reqDate[1].getMonth() + 1;
      if ( month1 < 10 )
        month1 = "0" + month1;
      
      let day1 = reqDate[1].getDate();
      if ( day1 < 10 )
        day1 = "0" + day1;  

      const startDay = reqDate[0].getFullYear() + '-' + month0 + '-' + day0;
      const endDay = reqDate[1].getFullYear() + '-' + month1 + '-' + day1;

      const callBackurl = "/callback/getSelect/"
      const myInfoUrl = "/user/myInfo";
      const subInfoUrl = "/user/subInfo";
      handleLoadingOn();
      getFetch(callBackurl)
        .then(result => {
          if( result.resultCode === "0" ) {
              const jsonArr = JSON.parse(result.resultData);
              setCallbackList(jsonArr);
          }
        })
        .catch(error => handleLoadingClose())

      getFetch(myInfoUrl)
      .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                setCustType(jsonArr.custType);

                if(jsonArr.custType === "1") {
                  getFetch(subInfoUrl)
                  .then(result => {
                    if( result.resultCode === "0" ) {
                        const jsonArr = JSON.parse(result.resultData);
                        setCustIdList(jsonArr);
                    }
                  })
                  .catch(error => handleLoadingClose())
                }

                const url = "/message/sendList";
                const data = { rowCount : rowCount, currentPage: reqPage, dayList : reqDate,  search1 : reqSearch1 , search2 : reqSearch2, 
                  search3 : reqSearch3, search4 : reqSearch4, search5 : reqSearch5, search6 : startDay, search7 : endDay, search8 : jsonArr.custType, search9 : reqCustId  }
                
                getPostFetch(url, data)
                .then(result => {
                        if( result.resultCode === "0" ) {
                            const jsonArr = JSON.parse(result.resultData);
                            const pageItem = JSON.parse(result.resultPage);
                            setLeftPage(pageItem.leftPage);
                            setRightPage(pageItem.rightPage);
                            setEndPage(pageItem.endPage);
                            setCurrentPage(pageItem.currentPage);
                            setFnlPage(pageItem.fnlPage);
                            const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                            setPages(pages);
                            setTableRows(jsonArr);
                        } else {
                            alert(result.resultMsg);
                        }
                        handleLoadingClose();
                    }
                )
                .catch(error => handleLoadingClose())
            }
          }
      )
      .catch(error => handleLoadingClose())

      sessionStorage.removeItem("saveCustId");
      sessionStorage.removeItem("saveMsgType");
      sessionStorage.removeItem("saveCallBack");
      sessionStorage.removeItem("saveResultType");
      sessionStorage.removeItem("saveText");
      sessionStorage.removeItem("saveDstaddr");
      sessionStorage.removeItem("dateStart");
      sessionStorage.removeItem("dateEnd");
      sessionStorage.removeItem("pages");
      sessionStorage.removeItem("backCheck");
  }
  
  const handleChange = num => {
      const regex = /^[0-9\b -]{0,13}$/;
      if (regex.test(num)) {
          setSearch5(num);
      }
  }

  const handleCustId = (e) => {
    
    setCustId(e.target.value);
  };
  
  return (
    <div className="container sendbox">
      {loading !=  1 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <p className="page-title">보낸메시지함</p>
      <div className="searchbox">
        <ul className="search-col4-group">
          <li>
            <DateRangePicker  appearance="default" placeholder="발송일"  id="requestDd" onKeyPress={handleEnterPress}
            onChange={onChange}
            value={value}
            dateFormat="yyyy-MM-dd"
            />
            <label for="value1">발송일</label>
          </li>
          <li>
            <select name="channel_id" id="sendType" value={search1} onChange={ e => setSearch1(e.target.value) } defaultValue="" onKeyPress={handleEnterPress}>
                <option hidden value="">서비스 유형</option>
                <option value="">전체</option>
                <option value="1">SMS</option>
                <option value="2">LMS</option>
                <option value="3">MMS</option>
                <option value="4">카카오 알림톡</option>
                <option value="5">카카오 친구톡 텍스트</option>
                <option value="6">카카오 친구톡 이미지</option>
                <option value="11">카카오 친구톡 와이드 이미지</option>
                <option value="7">RCS 템플릿</option>
                <option value="8">RCS SMS</option>
                <option value="9">RCS LMS</option>
                <option value="10">RCS MMS</option>
            </select>
          </li>
          <li>
            <select name="channel_id" id="callback" value={search2} onChange={ e => setSearch2(e.target.value) } defaultValue="" onKeyPress={handleEnterPress}>
                <option hidden value="">발신번호</option>
                <option value="">전체</option>
                {callbackList.map((row) => (
                  <option value={row.callback}>{row.callback}</option>   
                ))}
            </select>
          </li>
          <li>
            <select name="channel_id" id="resultType" value={search3} onChange={ e => setSearch3(e.target.value)} defaultValue="" onKeyPress={handleEnterPress}>
              <option hidden value="">결과 유형</option>
              <option value="">전체</option>
              <option value="1">성공</option>
              <option value="2">실패</option>
              <option value="3">잔여</option>
            </select>
          </li>
        </ul>
        <ul className="search-col4-group">
        { custType === '1' ? 
              <li>
                <select onChange={handleCustId} name="userID" id="userID" value={custId} defaultValue="" onKeyPress={handleEnterPress}>
                  <option hidden value="">사용자 계정</option>
                  <option value="">전체</option>
                  {
                  custIdList.map((row) => (<option value={row.custId}>{row.custId}</option>))
                  }
                </select>
              </li>
            : null }
          <li>
            <input type="text" id="value5" placeholder="메세지내용" onChange={ e => setSearch4(e.target.value)} onKeyPress={handleEnterPress}></input>
            <label for="value5">메세지내용</label>
          </li>
          <li>
            <input type="text" id="value6" placeholder="수신번호 (전체번호)" maxLength="13" 
            onChange={e => handleChange(e.target.value)} value={search5}  onKeyPress={handleEnterPress}></input>
            <label for="value6">수신번호</label>
          </li>
          <li>
            <Button buttonName="button-bgc-dark" buttonValue="검색" onChangePage={ e => {handlePageChange(1)}}/>
          </li>
          <li></li>
        </ul> 
      </div>

      <div className="button-wrap">
        <Button buttonName="buttonDown" buttonValue="전송내역 다운로드" onChangePage={DownloadSendHistory} /> 
        <Button buttonName="buttonDown" buttonValue="전송내역(전체) 다운로드" onChangePage={DownloadSendHistoryAll}/> 
      </div>

      <SendBoxTable data={tableRows} setSnList={setSnList}  custType={custType} 
      saveCustId={custId} saveMsgType={search1} saveCallBack={search2} saveResultType={search3} saveText={search4} saveDstaddr={search5}
      dateStart={moment(value[0]).format("YYYY-MM-DD")} dateEnd={moment(value[1]).format("YYYY-MM-DD")} pages={currentPage}
      />
      <PaginationDynamic  
                    pages={pages}
                    currentPage={currentPage}
                    leftPage={leftPage}
                    rightPage={rightPage}
                    endPage={endPage}
                    fnlPage={fnlPage}
                    onPageChange={handlePageChange}
                />
    </div>
  );
};

