import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faInfoCircle,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "component/Common";
import { ReceivingNumberList } from "component/Table";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "rsuite";
import TextField from "@material-ui/core/TextField";
import noimg from "assets/images/noimg.jpg";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import {
  LayerPopSendTestMsg, // 테스트전송
  LayerPopSendMsg, // 전송하기
  LayerPopAdvertisingGuide, // 광고문자 전송가이드
  LayerPopLoadMsg, // 문구 불러오기
  LayerPopAccountCreation, // 전용계좌 생성하기 (선불만 해당)
  LayerPopReceiveNumberError, // 수신번호 - 엑셀 대량전송 - 오류검사 - 오류건수
  LayerPopTestCardPay,
  LayerPopLoadFriendsTalkImg, // 이미지첨부 - 친구톡 이미지 불러오기
  LayerPopRechargeInfo,
} from "component/popUp/PopUp";
import ReceiveList from "../../../component/sendMessage/receiveList/ReceiveList";
import { postRequest, getRequest } from "component/Axios";
import { withThemeCreator } from "@material-ui/styles";
import { set } from "lodash";
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  textField: {
    width: 353,
  },
}));

export const SendKakaoFriendTalk = (props) => {
  const classes = useStyles();

  const [contractAllSelected, setContractAllSelected] = useState(false); // 광고성 정보 여부 - defaultchecked
  const [advertisingText, popupAdvertisingText] = useState(false); // 광고문자 전송가이드
  const [adName, setAdName] = useState(null); // 광고성 정보체크 - 업체명 또는 서비스명
  const [advertisement, setAdvertisement] = useState(null); // 광고성 정보체크 - 무료거부번호

  const [getalternative, setGetalternative] = useState(false); // 친구톡 발신 실패 시 대체문자 전송
  const [alternativeCheck, setAlternativeCheck] = useState(false); // 친구톡 발신 실패 시 대체문자 광고 여부;

  const [alternativeCharacter, setAlternativeCharacter] = useState(null); // 대체문자 입력란
  const [alternativeTotal, setAlternativeTotal] = useState(null); // 대체문자 입력 시 최하단 결과란 표시

  const [chekedImgDefault, setChekedImgDefault] =
    React.useState("uploadImage_1"); // 이미지 첨부 - 라디오 defaultchecked
  const [activeUploadImage, setActiveUploadImage] = React.useState(null); // 이미지 첨부

  const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
  const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창

  const [selectedContent, setSelectedContent] = useState(<MsgButtonType1 />); // 버튼 옵션

  const [chekedId, setChekedId] = React.useState("id3_1"); // 전송시간
  const [activeContent, setActiveContent] = React.useState(null); // 전송시간- 예약 datetype

  const [sendTestMsg, popupSendTestMsg] = useState(false); // 테스트 전송
  const [sendMsgConfirm, popupSendMsgConfirm] = useState(false); // 전송하기

  const [charge, setCharge] = useState(false); //전송예상결과에서 충전버튼 클릭 여부 (선불계정 only)

  /*********************************** 수신번호 관련 ***********************************/

  const [receiveCount, setReceiveCount] = useState(0);
  const [viewTableRows, setviewTableRows] = useState([]); // 수신 목록
  const [infoData, setInfoData] = useState([]);
  const [channelRows, setChannelRows] = useState([]); //채널명
  const [channelName, setChannelName] = useState("");
  const [channelId, setChannelId] = useState("");
  const [callbackList, setCallbackList] = useState([]);
  const [contentPhone, setContentPhone] = useState([]);
  const [phoneCount, setPhoneCount] = useState(0);
  const [fileRegIdList, setFileRegIdList] = useState([]);

  const [phone, setPhone] = useState(""); //
  const [rating, setRating] = useState("2");

  const [unitPrce, setUnitPrce] = useState(0);
  const [frdPrce, setFrdPrce] = useState(0);
  const [frdImgPrce, setFrdImgPrce] = useState(0);
  const [frdWideImgPrce, setFrdWideImgPrce] = useState(0);
  const [smsUnit, setSmsUnit] = useState(0);
  const [lmsUnit, setLmsUnit] = useState(0);
  const [mmsUnit, setMmsUnit] = useState(0);
  const [selectType, setSelectType] = useState("3");

  //발송제한금액
  const [daySend, setDaySend] = useState(0);
  const [monthSend, setMonthSend] = useState(0);

  //발송관련권한
  const [atFrd, setAtFrd] = useState("N");
  const [atFrdImg, setAtFrdImg] = useState("N");
  const [atFrdWideImg, setAtFrdWideImg] = useState("N");
  //친구톡 내용
  const [contents, setContents] = useState("");
  const [contentsHead, setContentsHead] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");
  const [adCheck, setAdCheck] = useState(false);
  const [contentLen, setContentLen] = useState(0);

  const [msgType, setMsgType] = useState("FRIEND_TALK");
  const [uploadFile, setUploadFile] = useState(null);

  const [friendFocus, setFriendFocus] = useState(0);

  //이미지 선택시 항목
  const [selectedImgData, setSelectedImgData] = useState(null);
  //메시지내용 메시지타입 및 글자수 제한
  const [showMsgType, setShowMsgType] = useState({
    type: "친구톡 텍스트",
    limit: "1000",
  });

  // 잔액
  const [balance, setBalance] = useState(0);
  const [rechargeInfo, popupRechargeInfo] = useState(false);
  const [sendType, setSendType] = useState(null); // 발송 타입 설정

  // 리액트 특정 돔 선택
  const friendAd = useRef(null); // 광고 체크박스
  const frienTextArea = useRef(null); // 친구톡 내용
  const receivingNumber = useRef(null); // 수신번호 입력란

  const setPreViewAdCheck = props.setPreViewAdCheck; //미리보기 광고여부 변경

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [listUploadType, setListUploadType] = useState("direct");

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    if (friendFocus !== 0) {
      textAreaPosition();
    }
  }, [friendFocus]);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  function handelDefaultSet() {
    for (let i = 1; i <= phoneCount; i++) {
      const addPhone = "addPhone_" + i;
      const addFile = "filePhone_" + i;
      if (document.getElementById(addPhone))
        document.getElementById(addPhone).remove();

      if (document.getElementById(addFile))
        document.getElementById(addFile).remove();
    }
    setContentPhone([]);
    getCountTotal();
    handleUserInfo();
    //handleContents('');

    // 수신목록 초기화
    setContentPhone([]);
    setFileRegIdList([]);
    setviewTableRows([]);
    setPhone("");

    // 대체문자 여부
    // 광고 여부
    // document.getElementById('advertising').checked = false;
    setAlternativeCharacter(null);
    setAlternativeTotal(null);
    setGetalternative(false);

    /* 발송시간 */
    setChekedId("id3_1");
    setActiveContent(null);

    setPhone("");

    // 친구톡 내용관련
    handleFriendContents("");
    setInputWebLinkList([]);
    setInputAppLinkList([]);
    setInputBotLinkList([]);
    setInputMessageLinkList([]);
    setPreViewAdCheck(false);

    //이미지 관련 초기화
    setActiveUploadImage(null);
    setFriendImgUrl("");
    setimgLink("");
    setSelectedImgData(null);

    handelbtnContents([]);
    document.getElementById("alternative").checked = false;

    //친구톡 타입관련
    let changeShowMsgType = { ...showMsgType };
    changeShowMsgType.type = "친구톡 텍스트";
    changeShowMsgType.limit = 1000;
    setShowMsgType(changeShowMsgType);
    setMsgType("FRIEND_TALK");
    setUnitPrce(frdPrce);
  }
  function handleBarenceInfo() {
    const url = "/user/userInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);

          sessionStorage.setItem("pay", jsonArr[0].balance);
          setBalance(jsonArr[0].balance);
        }
      })
      .catch((error) => console.log(error));
  }
  const payUrl = "/user/payInfo";

  getFetch(payUrl)
    .then((result) => {
      if (result.resultCode === "0") {
        const jsonArr = JSON.parse(result.resultData);
        setPayInfo(jsonArr.payType);
        setAccountNo(jsonArr.accountNo);
      }
    })
    .catch((error) => console.log(error));
  function handleUserInfo() {
    const url = "/user/userInfo";
    setLoading(true);
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);

          let prce = 0;
          let auth = "N";
          if (jsonArr[0].alimPrce) prce = jsonArr[0].alimPrce;

          if (jsonArr[0].frdPrce) prce = jsonArr[0].frdPrce;
          setFrdPrce(prce);
          setUnitPrce(prce);
          setRating(jsonArr[0].preRating);

          if (jsonArr[0].frdImgPrce) prce = jsonArr[0].frdImgPrce;
          setFrdImgPrce(prce);
          if (jsonArr[0].frdWideImgPrce) prce = jsonArr[0].frdWideImgPrce;
          setFrdWideImgPrce(prce);
          if (jsonArr[0].atFrd) {
            auth = jsonArr[0].atFrd;
            setAtFrd(auth);
          }
          setBalance(jsonArr[0].balance);

          if (jsonArr[0].atFrdImg) {
            auth = jsonArr[0].atFrdImg;
            setAtFrdImg(auth);
          }

          if (jsonArr[0].atFrdWideImg) {
            auth = jsonArr[0].atFrdWideImg;
            setAtFrdWideImg(auth);
          }
          if (jsonArr[0].frdPrce) prce = jsonArr[0].frdPrce;

          sessionStorage.setItem("pay", jsonArr[0].balance);

          let smsPrce = 0;
          if (jsonArr[0].smsPrce) smsPrce = jsonArr[0].smsPrce;
          setSmsUnit(smsPrce);

          let lmsPrce = 0;
          if (jsonArr[0].lmsPrce) lmsPrce = jsonArr[0].lmsPrce;
          setLmsUnit(lmsPrce);

          let mmsPrce = 0;
          if (jsonArr[0].mmsPrce) mmsPrce = jsonArr[0].mmsPrce;
          setMmsUnit(mmsPrce);

          if (jsonArr[0].daySend) setDaySend(jsonArr[0].daySend);

          if (jsonArr[0].monthSend) setMonthSend(jsonArr[0].monthSend);

          handleChannel();
          handleCallback();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const handleCallback = () => {
    const url = "/callback/getSelect/";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCallbackList(jsonArr);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  //채널명 가져오기
  const handleChannel = () => {
    const url = "/kko/getChannel";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChannelRows(jsonArr);
          console.log(jsonArr);
          setChannelName(jsonArr[0].plusId);
        }
      })
      .catch((error) => console.log(error));
  };
  // 수신번호 유효성검사

  const handleChange = (e) => {
    /*  const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    } */
    setPhone(e.target.value);
  };

  const AddReceiveNumber = (event) => {
    //수신번호 1만건 기준 이벤트 적용
    event.preventDefault();
    if (phone == "" || phone.indexOf("전화번호") > -1) {
      alert("등록할 수신번호를 입력해주세요.");
      return;
    }

    let tempTarget = phone.split("\n"); // 입력값들 줄바꿈기준으로 배열요소로 바꾸기
    if (receiveCount + tempTarget.length > 10000) {
      alert("일반전송의 1회 전송 가능한 최대건수는 1만건 입니다.");
      return;
    }

    let count = phoneCount;
    let receiveCnt = receiveCount;
    let addressNo = "";
    let newContentPhone = [...contentPhone];
    tempTarget.forEach((temp) => {
      if (temp != "" && temp.length > 0) {
        const target = addressNoCheck(temp);
        if (!target) {
          alert(
            "잘못된 형식의 번호는 수신번호로 등록할 수 없습니다.\n[" +
              temp +
              "]"
          );
        }

        //if (temp.substring(0, 1) != "0") temp = "0" + temp;
        if (target && newContentPhone.includes(target) === false) {
          newContentPhone.push(target);
          receiveCnt++;
          count++;
        }
      }
      setContentPhone(newContentPhone);
    });

    setPhoneCount(count);
    setReceiveCount(receiveCnt);
    setPhone("");
  };

  function addressNoCheck(addressNo) {
    //하이픈(-) 제거
    const target = addressNo.replace(/-/g, "");
    //휴대폰 번호 검증
    if (target.length < 10 || target.length > 13) {
      return false;
    }
    return target;
  }

  function getCountTotal() {
    let num = 0;
    for (let i = 1; i <= Number(phoneCount) + 10; i++) {
      const addFile = "filePhone_" + i;
      const addPhone = "addPhone_" + i;
      const fileCnt = "fileCnt_" + i;
      if (document.getElementById(addPhone)) {
        num = Number(num) + 1;
      }

      if (document.getElementById(addFile)) {
        const cnt = document.getElementById(fileCnt).value;
        num = Number(num) + Number(cnt);
      }
    }
    setReceiveCount(num);
  }

  /*********************************** 수신번호 관련 end***********************************/
  /*********************************** 이미지 첨부***********************************/
  const setFriendImgUrl = props.setFriendImgUrl; // 친구톡이미지 url 변경

  const [uploadImage, setUploadImage] = useState([
    { id: "uploadImage_1", value: "없음", content: null },
    {
      id: "uploadImage_2",
      value: "이미지",
      content: <ImageUpload channelName={channelName} />,
    },
  ]);

  const [imgLink, setimgLink] = useState("");

  useEffect(() => {
    handelActiveUploadImage(channelName);
  }, [chekedImgDefault, selectedImgData, imgLink]);
  useEffect(() => {
    setimgLink("");
  }, [selectedImgData]);
  const handelActiveUploadImage = (text) => {
    const textName = text;
    let type = [2, 3];
    if (chekedImgDefault === "uploadImage_2") {
      if (atFrdImg === "Y" && atFrdWideImg === "Y") {
        type = [2, 3];
      } else if (atFrdImg === "Y" && atFrdWideImg === "N") {
        type = [2];
      } else if (atFrdImg === "N" && atFrdWideImg === "Y") {
        type = [3];
      }
      setActiveUploadImage(
        <ImageUpload
          channelName={textName}
          type={type}
          selectedImgData={selectedImgData}
          setSelectedImgData={setSelectedImgData}
          imgLink={imgLink}
          setimgLink={setimgLink}
        />
      );
    } else {
      setActiveUploadImage(null);
      setFriendImgUrl("");
      setimgLink("");
      setSelectedImgData(null);
    }
  };
  useEffect(() => {
    let changeShowMsgType = { ...showMsgType };
    if (selectedImgData !== null) {
      setFriendImgUrl(selectedImgData.url);

      if (selectedImgData.type === 2) {
        changeShowMsgType.type = "친구톡 이미지";
        changeShowMsgType.limit = 400;
        let friendText = contents.substring(0, 400);
        handleFriendContents(friendText);
        setShowMsgType(changeShowMsgType);
        setMsgType("FRIEND_IMG");
        setUnitPrce(frdImgPrce);
      } else if (selectedImgData.type === 3) {
        changeShowMsgType.type = "친구톡 와이드";
        changeShowMsgType.limit = 76;
        setShowMsgType(changeShowMsgType);
        let friendText = contents.substring(0, 76);
        handleFriendContents(friendText);
        setMsgType("FRIEND_WIDE");
        setUnitPrce(frdWideImgPrce);
      }
    } else {
      changeShowMsgType.type = "친구톡 텍스트";
      changeShowMsgType.limit = 1000;
      setShowMsgType(changeShowMsgType);
      setMsgType("FRIEND_TALK");
      setUnitPrce(frdPrce);
    }
    if (selectedImgData !== null) {
    }
  }, [selectedImgData]);

  /*********************************** 이미지 첨부 end ***********************************/
  /*********************************** 메시지 내용 ***********************************/
  const handleFriendContents = (text) => {
    let friendTalkMax = 0;
    if (msgType === "FRIEND_TALK") {
      friendTalkMax = 1000;
      if (text.length > friendTalkMax) {
        alert("최대 1000 자까지만 입력이 가능합니다.");
        return;
      }
    } else if (msgType === "FRIEND_IMG") {
      friendTalkMax = 400;
      if (text.length > friendTalkMax) {
        alert("최대 400 자까지만 입력이 가능합니다.");
        return;
      }
    } else {
      friendTalkMax = 76;
      if (text.length > friendTalkMax) {
        alert("최대 76 자까지만 입력이 가능합니다.");
        return;
      }
    }

    setContents(text);
    if (contractAllSelected) {
      const msg = text + contentsFooter;
      props.handleContents(msg);
      setContentLen(text.length);
    } else {
      props.handleContents(text);
      setContentLen(text.length);
    }
  };
  function getAdvertisement(event) {
    //광고여부 체크

    if (event.target.checked) {
      setContractAllSelected(true);
      setPreViewAdCheck(true);
      // setAdvertisement(<Advertisement />);
      // setAdName(<AdName />);
    } else {
      setContractAllSelected(false);
      setPreViewAdCheck(false);
      setAdvertisement(null);
      setAdName(null);
    }
  }

  const AdvertisingGuide = (event) => {
    // 광고문자 전송가이드

    event.preventDefault();
    popupAdvertisingText(true);
  };

  const LoadMsg = (event) => {
    // 문구 불러오기

    event.preventDefault();
    popupLoadMsg(true);
  };

  const handleRetainMsgSet = (hText, subject, mText, fText, rMsgType, rAdvertYn) => {
    // if (rAdvertYn === 'Y') { // 광고성여부 주석처리
    //   setContents(mText);
    //   setContentsHead(hText);
    //   setContentsFooter(fText);
    //   setPreViewAdCheck(true);
    //   if (!contractAllSelected) {
    //     friendAd.current.click();
    //   }
    // } else {
    //   setContents(mText);
    //   setContentsHead('');
    //   setContentsFooter('');
    //   if (contractAllSelected) {
    //     friendAd.current.click();
    //   }
    //   setPreViewAdCheck(false);
    // }
    setContents(mText);
    handleContents(hText, mText, fText, rAdvertYn);
    popupLoadMsg(false);
  };

  const handleContents = (hText, mText, fText, rAdvertYn) => {
    const maxLen = 1000;
    const text_val = mText;
    let text_len = text_val.length;

    if (text_len > maxLen) {
      alert("최대 1000 자까지만 입력가능합니다.");
    } else {
      setContents(text_val);
      //setDetilInfo(totalByte);
      //setMsgByte(totalByte);

      if (rAdvertYn === "Y") {
        const msg = mText;
        props.handleContents(msg);
        setContentLen(msg.length);
      } else {
        props.handleContents(mText);
        setContentLen(mText.length);
      }
    }
  };

  const SaveMsg = (event) => {
    // 문구 저장하기
    event.preventDefault();

    let advertYn = "N";
    let footerText = "";
    let headerText = "";
    if (contractAllSelected) {
      advertYn = "Y";
    }

    if (!contents) {
      alert("저장할 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: headerText,
        mainText: contents,
        footerText: footerText,
        subject: "",
        msgType: msgType,
        advertYn: advertYn,
      };

      if (uploadFile) {
        url = "/message/retainMsgSaveFile";
        const formData = new FormData();
        formData.append("file", uploadFile);
        formData.append("headerText", headerText);
        formData.append("mainText", contents);
        formData.append("footerText", footerText);
        formData.append("subject", "");
        formData.append("msgType", msgType);
        formData.append("advertYn", advertYn);
        data = formData;
      }

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => console.log(error));
    }

    console.log("저장하기");
  };

  const showMergeSettings = () => {
    // 머지설정

    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };

  const handleContentsName = (text) => {
    const beforeTxt = contents.substring(
      0,
      frienTextArea.current.selectionStart
    );
    const afterTxt = contents.substring(
      frienTextArea.current.selectionEnd,
      contents.length
    );
    let selectPos = frienTextArea.current.selectionStart;

    console.log(frienTextArea);
    setFriendFocus(selectPos + text.length);
    handleFriendContents(beforeTxt + text + afterTxt);
  };

  const textAreaPosition = () => {
    frienTextArea.current.setSelectionRange(friendFocus, friendFocus);
    frienTextArea.current.focus();
  };

  /*********************************** 메시지 내용 end ***********************************/
  /*********************************** 버튼 노출 ***********************************/
  const friendButtonOption = [
    { id: "1", value: "웹링크", content: <BtnWebLink /> },
    { id: "2", value: "앱링크", content: <BtnAppLink /> },
    { id: "3", value: "봇키워드", content: <BtnBotKeyword /> },
    { id: "4", value: "메시지전달", content: <BtnDeliverMsg /> },
  ];
  const [selectedBtnType, setSelectedBtnType] = useState("1");

  // const [selectedBtnOption, setSelectedBtnOption] = useState(null);
  /* const [contentsCount, setContentsCount] = useState(1); */

  // const getSelectedBtnOption = (id) => {

  //   if (id === "1") {
  //     setSelectedBtnOption(<BtnWebLink />);
  //   }
  //   else if (id === "2") {
  //     setSelectedBtnOption(<BtnAppLink />);
  //   }
  //   else if (id === "3") {
  //     setSelectedBtnOption(<BtnBotKeyword />);
  //   }
  //   else if (id === "4") {
  //     setSelectedBtnOption(<BtnDeliverMsg />);
  // }

  // }

  const [inputWebLinkList, setInputWebLinkList] = useState([]);
  const [inputAppLinkList, setInputAppLinkList] = useState([]);
  const [inputBotLinkList, setInputBotLinkList] = useState([]);
  const [inputMessageLinkList, setInputMessageLinkList] = useState([]);

  const handelbtnContents = props.handelbtnContents;
  const btnText = props.btnText;
  const btnIdCnt = useRef(0);

  const handleBtnContentChange = (e, list, setList, index) => {
    const btnList = [...list];

    btnList[index][e.target.name] = e.target.value;
    setList(btnList);
  };
  const handelPrivewBtn = (
    e,
    type,
    webList,
    appList,
    bkList,
    mdList,
    index
  ) => {
    if (e.target.name === "name") {
      let webCnt = webList.length;
      let appCnt = appList.length;
      let bkCnt = bkList.length;
      let mdCnt = mdList.length;
      let btnCnt = 0;
      if (type === 1) {
        btnCnt = index;
      } else if (type === 2) {
        btnCnt = webCnt + index;
      } else if (type === 3) {
        btnCnt = webCnt + appCnt + index;
      } else if (type === 4) {
        btnCnt = webCnt + appCnt + bkCnt + index;
      }
      const privewBtnList = [...btnText];

      privewBtnList[btnCnt][e.target.name] = e.target.value;
      handelbtnContents(privewBtnList);
    }
  };

  const handleFriendBtnRemove = (setList, list, btnId, btnList, setBtnList) => {
    const btnLists = [...btnList];
    console.log(btnLists);
    console.log(btnId);
    setBtnList(btnLists.filter((btnInfo) => btnInfo.id !== btnId));
    setList(list.filter((btnInfo) => btnInfo.id !== btnId));
  };

  function addFriendTalkBtn(event) {
    // 친구톡 버튼
    event.preventDefault();
    let totalButtonCount =
      inputWebLinkList.length +
      inputAppLinkList.length +
      inputBotLinkList.length +
      inputMessageLinkList.length;
    if (chekedImgDefault === "uploadImage_3") {
      if (totalButtonCount >= 1)
        alert("와이드 이미지는 버튼 1개만 추가 할 수 있습니다.");
      else addButtonList(selectedBtnType);
    } else {
      if (totalButtonCount >= 5) alert("버튼 5개까지 추가 할 수 있습니다.");
      else addButtonList(selectedBtnType);
    }
  }
  const addButtonList = (selectedBtnType) => {
    if (selectedBtnType === "1") {
      let webBtnId = "btnId" + btnIdCnt.current;
      const webList = {
        id: webBtnId,
        type: "WL",
        name: "",
        btnLink1: "",
        btnLink2: "",
      };
      setInputWebLinkList(inputWebLinkList.concat(webList));
    } else if (selectedBtnType === "2") {
      let appBtnId = "btnId" + btnIdCnt.current;
      const appList = {
        id: appBtnId,
        type: "AL",
        name: "",
        btnLink1: "",
        btnLink2: "",
      };
      setInputAppLinkList(inputAppLinkList.concat(appList));
    } else if (selectedBtnType === "3") {
      let bkBtnId = "btnId" + btnIdCnt.current;
      const bkList = {
        id: bkBtnId,
        type: "BK",
        name: "",
      };
      setInputBotLinkList(inputBotLinkList.concat(bkList));
    } else if (selectedBtnType === "4") {
      let mdBtnId = "btnId" + btnIdCnt.current;
      const mdList = {
        id: mdBtnId,
        type: "MD",
        name: "",
      };

      setInputMessageLinkList(inputMessageLinkList.concat(mdList));
    }
    freviewBtnSet(selectedBtnType);

    btnIdCnt.current += 1;
  };

  function freviewBtnSet(selectedBtnType) {
    let btnList = [...btnText];
    let btnId = "btnId" + btnIdCnt.current;
    const previewBtn = {
      id: btnId,
      name: "",
    };
    let btnOrder = 0;
    let webBtnCnt = inputWebLinkList.length;
    let appBtnCnt = webBtnCnt + inputAppLinkList.length;
    let bkBtnCnt = appBtnCnt + inputBotLinkList.length;
    let mdBtnCnt = bkBtnCnt + inputMessageLinkList.length;
    if (selectedBtnType === "1") {
      btnOrder = webBtnCnt;
    } else if (selectedBtnType === "2") {
      btnOrder = appBtnCnt;
    } else if (selectedBtnType === "3") {
      btnOrder = bkBtnCnt;
    } else if (selectedBtnType === "4") {
      btnOrder = mdBtnCnt;
    }

    btnList.splice(btnOrder, 0, previewBtn);
    handelbtnContents(btnList);
  }

  /*********************************** 버튼 노출 end  ***********************************/
  /*********************************** 발신번호 관련 *************************************/

  const handleCertification = (event) => {
    event.preventDefault();
    console.log("발신번호 인증");
  };

  const closeInsufficientBalancePop = () => {
    popupRechargeInfo(false);
  };

  /*********************************** 발신번호 end *************************************/
  /*********************************** 대체문자 발송 ***********************************/
  function getCheckboxValue(event) {
    // 친구톡 발신 실패 시 대체문자 전송

    if (event.target.checked) {
      setAlternativeCharacter(
        <AlternativeCharacter setAlternativeCheck={setAlternativeCheck} />
      );
      setGetalternative(true);
      setAlternativeTotal(
        <AlternativeTotal alternativeType={"SMS"} smsUnit={smsUnit} />
      );
    } else {
      setGetalternative(false);
      setAlternativeCharacter(null);
      setAlternativeTotal(null);
    }
  }
  /*********************************** 대체문자 발송 end ***********************************/
  /*********************************** 전송시간 ***********************************/
  const sendTime = [
    { id: "id3_1", value: "즉시", content: null },
    { id: "id3_2", value: "예약", content: <Reservation /> },
  ];
  /*********************************** 전송시간 end ***********************************/
  /*********************************** 전송금액 설정 ***********************************/
  function setComma(num) {
    if (num > 999) {
      const cn = num
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return cn;
    } else {
      return num.toFixed(2);
    }
  }
  // 팝업 닫기
  const closeSendMsgPop = () => {
    popupSendMsgConfirm(false);
  };

  const closeSendMsgTestPop = () => {
    popupSendTestMsg(false);
  };
  /*********************************** 전송금액 설정end ********************************/

  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 (선불only) ***********************************/
  const handleRefresh = (e) => {
    e.preventDefault();
    handleBarenceInfo();
  };
  const showChargeAccount = () => {
    if (charge) {
      setCharge(false);
    } else {
      setCharge(true);
    }
  };

  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 end (선불only) ***********************************/
  /*********************************** 테스트전송 컨펌창 ***********************************/
  function getTestSendMsg(event) {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("T");
    if (balance < unitPrce * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendTestMsg(true);
    }
  }
  /*********************************** 테스트전송 컨펌창 end ***********************************/
  /*********************************** 전송하기 컨펌창 ***********************************/
  const sendMsgTest = (numPhone) => {
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    if (!numPhone) {
      alert("메시지를 발송할 휴대폰 번호가 존재하지 않습니다.");
      return;
    }
    sendMessage("Y", numPhone);
  };

  const sendMsg = () => 
  { 
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    sendMessage("N", "");
  };
  const sendMessage = (testYn, numPhone) => {
    let phoneList = [];
    let fileList = fileRegIdList;
    let cntNum = 0;

    if (testYn === "Y") {
      phoneList.push(numPhone);
      cntNum = 1;
      fileList = [] ; // jy 2023.01.18
    } else {
      phoneList = contentPhone;
      cntNum = receiveCount;

      const regText = /#\{.*?\}/g;

      let matchText = contents.match(regText);

      //console.log(matchText);
      const set = new Set(matchText);

      const uniqueArr = [...set];
      if (uniqueArr.length > 0 && phoneList.length > 0) {
        alert("머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다.");
        return;
      }
    }

    if (cntNum === 0) {
      alert("메시지를 발송할 수신번호가 존재하지 않습니다.");
      return;
    }

    const id = document.getElementById("channelId");
    const plusID = id.options[id.selectedIndex].text;
    const sender = document.getElementById("channelId").value;

    if (!sender) {
      alert("채널을 선택해 주세요.");
      return;
    }

    let mergeCheck = "N";
    let sendCnt = 1;
    let mergeText = "";
    if (testYn === "N") {
      // 머지확인
      const idMerge = ["merge_0", "merge_1", "merge_2", "merge_3", "merge_4"];
      const idName = [
        "mergeValue_0",
        "mergeValue_1",
        "mergeValue_2",
        "mergeValue_3",
        "mergeValue_4",
      ];
      const idChoice = [
        "mergeChoice_0",
        "mergeChoice_1",
        "mergeChoice_2",
        "mergeChoice_3",
        "mergeChoice_4",
      ];

      for (let i = 0; i < 5; i++) {
        if (document.getElementById(idMerge[i])) {
          const textNm = document.getElementById(idName[i]).value;
          const mergeVal = document.getElementById(idChoice[i]).value;
          if (!mergeVal) {
            alert("템플릿 에 있는 변수에 머지필드가 선택되어 있지 않습니다.");
            return;
          }
          if (!mergeText) mergeText = textNm + "|" + mergeVal;
          else mergeText = mergeText + "," + textNm + "|" + mergeVal;

          mergeCheck = "Y";
        }
      }
    }
    const callbackNum = document.getElementById("callbackList").value;

    if (callbackNum == 0) {
      alert("발신번호를 등록하세요.");
      return;
    }

    if (!callbackNum) {
      alert("발신번호를 선택해 주세요.");
      return;
    }

    let advertYn = "N";
    let adNumber = "";
    let replaceText = "";
    let headerText = "";
    let footerText = "";
    let mainText = "";
    // 대체문자 발송여부
    let failover = "0";

    console.log(getalternative);
    if (getalternative) {
      failover = "2";

      if (rating === "2" && receiveCount * smsUnit * 1.1 > balance) {
        alert(
          "대체발송을 위한 충전금이 부족합니다.\n 대체문자 발송여부를 해제해주세요."
        );
        return;
      }

      mainText = document.getElementById("replaceText").value;
      if (alternativeCheck) {
        advertYn = "Y";
        //080번호 체크

        adNumber = document.getElementById("adNumber").value;
        const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
        if (!adNumber) {
          alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
          return;
        } else if (!regexMobile.test(adNumber)) {
          alert("잘못된 형식의 080 번호 입니다. 입니다.");
          return;
        }
        headerText = "(광고)" + document.getElementById("adTitle").value + "\n";
        footerText = "\n무료거부 " + adNumber;
      }
      replaceText = headerText + mainText + footerText;
      const regText = /#\{.*?\}/g;

      let matchText = replaceText.match(regText);

      //console.log(matchText);
      const set = new Set(matchText);

      const uniqueArr = [...set];
      if (uniqueArr.length > 0 && phoneList.length > 0) {
        alert("머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다.");
        return;
      }
    }

    const text = contents;
    if (text === "") {
      alert("친구톡 메시지 내용을 입력해주세요");
      return;
    }

    let sendType = "1";
    let sendDayTime;
    if (testYn === "N") {
      sendCnt = receiveCount;
      if (chekedId === "id3_2") {
        sendDayTime = document.getElementById("datetime-local").value;
        if (!sendDayTime) {
          alert("예약시간을 선택해 주세요.");
          return;
        }
        sendType = "2";
      }
    }

    let btnArr = [
      ...inputWebLinkList,
      ...inputAppLinkList,
      ...inputBotLinkList,
      ...inputMessageLinkList,
    ];
    console.log(btnArr);
    if (btnArr.length !== 0) {
      for (let i = 0; i < btnArr.length; i++) {
        if (btnArr[i].name === "") {
          alert("버튼명을 입력해주세요");
          return;
        }

        if (btnArr[i].type === "WL") {
          if (btnArr[i].btnLink1 === "") {
            alert("Mobile 주소를 입력해주세요");
            return;
          }
        } else if (btnArr[i].type === "AL") {
          if (btnArr[i].btnLink1 === "") {
            alert("Android 앱링크를 입력해주세요");
            return;
          }
          if (btnArr[i].btnLink2 === "") {
            alert("IOS 앱링크를 입력해주세요");
            return;
          }
        }
      }
    }

    let btnInfo = JSON.stringify(btnArr);

    if (btnInfo === "[]") {
      btnInfo = "";
    }

    let imgInfo = "";
    if (selectedImgData !== null) {
      imgInfo = JSON.stringify({ image_url: selectedImgData.url, image_link : imgLink });
    }

    console.log(imgInfo);
    let friendType = "";
    switch (msgType) {
      case "FRIEND_TALK":
        friendType = "5";
        break;
      case "FRIEND_IMG":
        friendType = "6";
        break;
      case "FRIEND_WIDE":
        friendType = "7";
        break;
    }

    let url = "/message/friendCheck";
    let data = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: "",
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: friendType,
      advertYn: advertYn,
      totCnt: sendCnt,
      kakaoMethod: "p",
      replaceText: replaceText,
      senderKey: sender,
      failover: failover,
      plusId: plusID,
      mergeText: mergeText,
      btnInfo: btnInfo,
      imgInfo: imgInfo,
      daySend: daySend,
      monthSend: monthSend,
    };
    console.log("messageReady data", data);
    console.log("messageReady regIdList", data.regIdList);
    console.log("messageReady fileList", fileList);
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "1") {
          let msg = result.resultMsg;
          if (window.confirm(msg)) {
            const sn = result.resultData;
            fnlSendMsg(
              phoneList,
              fileList,
              callbackNum,
              text,
              sendType,
              sendDayTime,
              mergeCheck,
              mergeText,
              advertYn,
              replaceText,
              sender,
              failover,
              plusID,
              sn,
              testYn,
              "N",
              sendCnt,
              btnInfo,
              imgInfo,
              friendType
            );
          }
        } else if (result.resultCode === "0") {
          const sn = result.resultData;
          fnlSendMsg(
            phoneList,
            fileList,
            callbackNum,
            text,
            sendType,
            sendDayTime,
            mergeCheck,
            mergeText,
            advertYn,
            replaceText,
            sender,
            failover,
            plusID,
            sn,
            testYn,
            "N",
            sendCnt,
            btnInfo,
            imgInfo,
            friendType
          );
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  };
  const fnlSendMsg = (
    phoneList,
    fileList,
    callbackNum,
    text,
    sendType,
    sendDayTime,
    mergeCheck,
    mergeText,
    advertYn,
    replaceText,
    sender,
    failover,
    plusID,
    sn,
    testYn,
    duplicateYn,
    sendCnt,
    btnInfo,
    imgInfo,
    friendType
  ) => {
    let url = "/message/messageSendFriend";
    let data = {
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: "",
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: friendType,
      advertYn: advertYn,
      totCnt: sendCnt,
      kakaoMethod: "p",
      replaceText: replaceText,
      senderKey: sender,
      failover: failover,
      plusId: plusID,
      mergeText: mergeText,
      btnInfo: btnInfo,
      imgInfo: imgInfo,
      tmSn: sn,
    };

    console.log(data);
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          if (testYn === "Y") {
            popupSendTestMsg(false);
          } else {
            popupSendMsgConfirm(false);
            handelDefaultSet();
          }
        }
      })
      .catch((error) => console.log(error));
  };
  const SendMsgConfirm = (event) => {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("R");
    if (balance < unitPrce * receiveCount * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendMsgConfirm(true);
    }
  };
  /*********************************** 전송하기 컨펌창 end ***********************************/


  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <>
      <div>
        {
          loading == true && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />          
          )
        }
        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">채널명</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="channelId"
                  name="channelId"
                  data-fakeform-index="0"
                  className="wid100"
                  onChange={(e) => {
                    setChannelId(e.target.value);
                    setChannelName(e.target.selectedOptions[0].textContent);
                    handelActiveUploadImage(
                      e.target.selectedOptions[0].textContent
                    );
                  }}
                >
                  {channelRows.map((row) => (
                    <option value={row.senderKey} key={row.senderKey}>
                      {row.plusId}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <p className="line"></p>
        </div>

        {/*  수신번호 */}
        <ReceiveList
          phone={phone}
          setPhone={setPhone}
          phoneCount={phoneCount}
          setPhoneCount={setPhoneCount}
          contentPhone={contentPhone}
          setContentPhone={setContentPhone}
          receiveCount={receiveCount}
          setReceiveCount={setReceiveCount}
          getCountTotal={getCountTotal}
          setFileRegIdList={setFileRegIdList}
          setLoading={setLoading}
          viewTableRows={viewTableRows}
          setviewTableRows={setviewTableRows}
          listUploadType={listUploadType}
          setListUploadType={setListUploadType}
        />

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">이미지첨부</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {uploadImage.map((value) => (
                    <span key={value.id}>
                      <input
                        type="radio"
                        name="uploadImage"
                        id={value.id}
                        checked={chekedImgDefault === value.id}
                        onClick={function (e) {
                          setChekedImgDefault(value.id);
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {activeUploadImage}
          <p className="line"></p>
        </div>

        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">메시지 내용</span>
            </div>
          </div>
          <div id="msgArea" className="write-input-con change-effect-bg">
            <div className="write-input-down-con">
              <textarea
                name=""
                id="msgContents"
                className="gs-custom-textarea change-effect"
                placeholder=""
                maxlength="1000"
                value={contents}
                ref={frienTextArea}
                onChange={(e) => {
                  handleFriendContents(e.target.value);
                }}
              ></textarea>
              <div className="write-input-down-info">
                <span className="msgType">{showMsgType.type}</span>
                <span className="byte" id="msgByte">
                  {contentLen} / <em className="red">{showMsgType.limit} 자</em>
                </span>
                {/* 와이드이미지 선택시 76자 */}
              </div>
            </div>
          </div>
          <div className="write-number-down-btn mt20">
            <ul className="button-col3">
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 불러오기"
                  onChangePage={LoadMsg}
                />
              </li>
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 저장하기"
                  onChangePage={SaveMsg}
                />
              </li>
              {
                listUploadType != 'direct' && 
                <li>
                  <Button
                    buttonName={
                      mergeVariableState
                        ? "button-bgc-blue"
                        : "button-border-gray"
                    }
                    buttonValue="머지설정"
                    onChangePage={() => showMergeSettings()}
                  />
                  {mergeVariableState ? (
                    <MergeSettings handleContentsName={handleContentsName} />
                  ) : null}
                </li>
              }
            </ul>
          </div>
          <p className="line"></p>
        </div>

        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">버튼</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-input-btn-group">
                {/* 와이드형 = 미사용, 1개  / 텍스트, 이미지형 = 미사용, 1개~5개 */}
                <select
                  name="friendsBtnType"
                  id="friendsBtnType"
                  onChange={(e) => {
                    setSelectedBtnType(e.target.value);
                  }}
                >
                  {friendButtonOption.map((b) => (
                    <option value={b.id} key={b.id}>
                      {b.value}
                    </option>
                  ))}
                </select>
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="추가"
                  onChangePage={addFriendTalkBtn}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="write-left-inner Optionbutton-area mt10">
          {/* 추가된 버튼 나오는 곳 */}
          {inputWebLinkList.map((web, index) => (
            <BtnWebLink
              web={web}
              setList={setInputWebLinkList}
              btnList={btnText}
              setBtnList={handelbtnContents}
              webList={inputWebLinkList}
              appList={inputAppLinkList}
              bkList={inputBotLinkList}
              mdList={inputMessageLinkList}
              remove={handleFriendBtnRemove}
              contentChange={handleBtnContentChange}
              btnChange={handelPrivewBtn}
              index={index}
              key={index}
            />
          ))}
          {inputAppLinkList.map((app, index) => (
            <BtnAppLink
              app={app}
              setList={setInputAppLinkList}
              btnList={btnText}
              setBtnList={handelbtnContents}
              webList={inputWebLinkList}
              appList={inputAppLinkList}
              bkList={inputBotLinkList}
              mdList={inputMessageLinkList}
              remove={handleFriendBtnRemove}
              contentChange={handleBtnContentChange}
              btnChange={handelPrivewBtn}
              index={index}
              key={index}
            />
          ))}
          {inputBotLinkList.map((bk, index) => (
            <BtnBotKeyword
              bk={bk}
              setList={setInputBotLinkList}
              btnList={btnText}
              setBtnList={handelbtnContents}
              webList={inputWebLinkList}
              appList={inputAppLinkList}
              bkList={inputBotLinkList}
              mdList={inputMessageLinkList}
              remove={handleFriendBtnRemove}
              contentChange={handleBtnContentChange}
              btnChange={handelPrivewBtn}
              index={index}
              key={index}
            />
          ))}
          {inputMessageLinkList.map((md, index) => (
            <BtnDeliverMsg
              md={md}
              setList={setInputMessageLinkList}
              btnList={btnText}
              setBtnList={handelbtnContents}
              webList={inputWebLinkList}
              appList={inputAppLinkList}
              bkList={inputBotLinkList}
              mdList={inputMessageLinkList}
              remove={handleFriendBtnRemove}
              contentChange={handleBtnContentChange}
              btnChange={handelPrivewBtn}
              index={index}
              key={index}
            />
          ))}
          <p className="line"></p>
        </div>
        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">발신번호</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="callbackList"
                  name="phone"
                  data-fakeform-index="0"
                  className={receiveCount >= 10000 ? "" : "wid100"}
                >
                  {callbackList.length == 0 && <option value={"0"} selected hidden>발신번호를 등록하세요.</option>}
                  {callbackList.map((row) => (
                    <option value={row.callback} key={row.callback}>
                      {row.callback}
                      {row.cbType === "1" ? "(기본발신)" : null}
                    </option>
                  ))}
                </select>
                {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                  <Button
                    buttonName="button-bgc-dark"
                    buttonValue="발신번호 인증"
                    onChangePage={handleCertification}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
            <p className="list-item-state red mt5">
              ※ 1만건 이상 발송 시 발신번호 인증 후 메시지 발송이 가능합니다.
            </p>
          ) : null}
          <p className="line"></p>
        </div>
        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">대체문자 발송</span>
            </div>
            <div className="tit-right">
              <fieldset className="gs-custom-checkbox">
                <span className="checkbox-item">
                  <input
                    type="checkbox"
                    id="alternative"
                    name=""
                    onClick={getCheckboxValue}
                  />
                  <label
                    for="alternative"
                    className={getalternative ? "checked" : null}
                  >
                    친구톡 발송 실패 시 대체문자 전송하기
                  </label>
                </span>
              </fieldset>
            </div>
          </div>
          {alternativeCharacter}
          <p className="line"></p>
        </div>

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">전송시간</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {sendTime.map((value) => (
                    <span key={value.id}>
                      <input
                        type="radio"
                        name=""
                        id={value.id}
                        checked={chekedId === value.id}
                        onClick={function (e) {
                          setActiveContent(value.content);
                          setChekedId(value.id);
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </span>
                  ))}
                </div>
                {activeContent}
              </div>
            </div>
          </div>
          <p className="line"></p>
        </div>

        <div className="write-total-con clearfix" id="">
          <div className="write-total-left-con">
            <div className="write-total-top">
              <div className="inner clearfix">
                <div className="left">전송 금액</div>
                <div className="right">
                  <p className="price sendPrice">
                    {setComma(unitPrce * receiveCount * 1.1)} 원
                  </p>
                  <span className="txt">(부가세 포함)</span>
                </div>
              </div>
            </div>
            <div className="write-total-bottom-wrap">
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      메시지 타입
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">{showMsgType.type}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      건당 요금
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{unitPrce}</span>원
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {alternativeTotal} {/* 대체문자 전송 시 표시 */}
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{receiveCount}</span>건
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 선불계정일 때만 표시 */}
              {rating === "2" ? (
                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        내 충전금
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input">
                        <div className="input-con">
                          <a href="/" onClick={handleRefresh}>
                            <FontAwesomeIcon
                              icon={faRedoAlt}
                              className="gray999 mr10 cursor size8"
                            />
                          </a>
                          <span className="mr10">{setComma(balance)}</span>
                          원
                          {
                            payInfo == 2 ? <Button
                                              buttonName="cm-highlight-btn ml10"
                                              buttonValue="카드결제"
                                              onChangePage={handleTestCardPay}
                                            />
                                          : <Button
                                          buttonName="cm-highlight-btn ml10"
                                          buttonValue="충전"
                                          onChangePage={() => showChargeAccount()}
                                        />
                          }
                          <div id="payRender" name="payRender"></div>
                        </div>
                        {charge && (
                          <div className="input-con">
                            <MyAccountInfo setBalance={setBalance} infoData={infoData}/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* 선불계정일 때만 표시   */}
            </div>
          </div>
        </div>

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="button-wrap rfloat">
              <Button
                buttonName="button-border-blue"
                buttonValue="테스트전송"
                onChangePage={getTestSendMsg}
              />
              <Button
                buttonName="button-bgc-blue"
                buttonValue="전송하기"
                onChangePage={SendMsgConfirm}
              />
              {rechargeInfo && (
                <PopUpLayout
                  onClose={popupRechargeInfo}
                  poptitle="잔액 부족 안내"
                  size="small"
                >
                  <LayerPopRechargeInfo
                    payInfo={payInfo}
                    accountNo={accountNo}
                    sendType={sendType}
                    popupSendTestMsg={popupSendTestMsg}
                    popupSendMsgConfirm={popupSendMsgConfirm}
                    closeInsufficientBalancePop={closeInsufficientBalancePop}
                    balance={balance}
                    setBalance={setBalance}
                    nowBalance={unitPrce}
                    receiveCount={receiveCount}
                  />
                </PopUpLayout>
              )}
            </div>
          </div>
        </div>
        <p className="clear"></p>
      </div>
      {loadMsg && (
        <PopUpLayout
          onClose={popupLoadMsg}
          poptitle="문구 불러오기"
          size="medium"
        >
          <LayerPopLoadMsg
            selectType={selectType}
            handleRetainMsgSet={handleRetainMsgSet}
            setLoading = {setLoading}
          />
        </PopUpLayout>
      )}
      {advertisingText && (
        <PopUpLayout
          onClose={popupAdvertisingText}
          poptitle="광고문자 전송 가이드"
        >
          <LayerPopAdvertisingGuide />
        </PopUpLayout>
      )}
      {sendTestMsg && (
        <PopUpLayout onClose={popupSendTestMsg} poptitle="테스트전송">
          <LayerPopSendTestMsg
            sendMsgTest={sendMsgTest}
            closeSendMsgTestPop={closeSendMsgTestPop}
            unitPrce={unitPrce}
            msgType={showMsgType.type}
          />
        </PopUpLayout>
      )}
      {sendMsgConfirm && (
        <PopUpLayout onClose={popupSendMsgConfirm} poptitle="메시지 발송">
          <LayerPopSendMsg
            sendMsg={sendMsg}
            closeSendMsgPop={closeSendMsgPop}
            receiveCount={receiveCount}
            unitPrce={unitPrce}
            msgType={showMsgType.type}
            chekedId={chekedId}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout 
          onClose={setTestCardPay}
          poptitle="카드결제"
          size="small"
        >
          <LayerPopTestCardPay
             onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
    </>
  );
};

export const ErrorCheckResults = () => {
  // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

  const [errorList, popupErrorList] = useState(false);
  const OpenErrorList = (event) => {
    event.preventDefault();
    popupErrorList(true);
  };
  return (
    <>
      <div className="write-total-con mt10 clearfix" id="">
        <div className="write-total-left-con">
          <div className="write-total-bottom-wrap">
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">295</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">정상 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">293</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">오류 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <a href="/" className="red" onClick={OpenErrorList}>
                        {" "}
                        <span className="mr10 redline">2</span>건
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorList && (
        <PopUpLayout onClose={popupErrorList} poptitle="오류상세" size="medium">
          <LayerPopReceiveNumberError />
        </PopUpLayout>
      )}
    </>
  );
};

export function ImageUpload(props) {
  // 친구톡 이미지첨부
  const [friendsTalkImg, setFriendsTalkImg] = useState(false); // 친구톡 이미지관리 팝업
  const channelName = props.channelName;
  const type = props.type;
  const setSelectedImgData = props.setSelectedImgData;
  const selectedImgData = props.selectedImgData;
  const imgLink = props.imgLink;
  const setimgLink = props.setimgLink;

  const LoadFriendsTalkImg = () => {
    setFriendsTalkImg(true);
  };

  const popupClose = () => {
    setFriendsTalkImg(false);
  };
  const handleLinkChange = (e) => {
    setimgLink(e.target.value);
  };
  return (
    <>
      <div className="mt20 mb20">
        {/* 지원 규격 : 최대 500 KB / jpg, png 파일만 첨부 가능합니다. (720*720px
        권장)
        <br />
        가로 500px 미만 또는 가로:세로 비율이 2:1 미만 또는 3:4 초과시 첨부
        불가합니다. */}
        친구톡 이미지 관리에서 등록한 이미지만 사용할 수 있습니다.
      </div>

      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id=""
            name=""
            title="친구톡 이미지 URL"
            placeholder="친구톡이미지 URL"
            disabled={selectedImgData === null ? true : false}
            maxlength="200"
            value={imgLink}
            onChange={(e) => handleLinkChange(e)}
          />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="불러오기"
            onChangePage={LoadFriendsTalkImg}
          />
          {/* 
            불러오기 버튼 클릭 시 팝업이 오픈되고, 팝업내에서 이미지 select한 후 팝업창 닫히면 input에 선택된 이미지의 URL값 넣어주세요.
          */}
        </div>
      </div>
      {friendsTalkImg && (
        <PopUpLayout
          onClose={setFriendsTalkImg}
          poptitle="친구톡 이미지 불러오기"
          size="medium"
        >
          <LayerPopLoadFriendsTalkImg
            popupClose={popupClose}
            channelName={channelName}
            setSelectedImgData={setSelectedImgData}
            type={type}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function WideImageUpload() {
  // 친구톡 와이드이미지첨부

  const LoadingImg = (event) => {
    event.preventDefault();
    console.log("와이드이미지 불러오기");
  };
  return (
    <>
      <div className="mt20 mb20">
        와이드이미지 사용 시 메시지 내용 76자, 버튼 1개까지 입력할 수 있습니다.
        <br />
        지원 규격 : 최대 2 MB / jpg, png 파일만 첨부 가능합니다.
        <br />
        이미지 크기는 800*600px 사이즈로 제한됩니다.
      </div>

      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id=""
            name=""
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="파일등록"
            onChangePage={LoadingImg}
          />
        </div>
      </div>
    </>
  );
}

export function AdName() {
  // 메시지 내용 - 광고성 정보 체크 시 - 업체명 또는 서비스명 입력란
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02 gray999 size14">채널명</div>
    </div>
  );
}

export function Advertisement() {
  // 메시지 내용 - 광고성 정보 체크 시 - 채널차단
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id=""
        name=""
        title="무료거부번호"
        defaultValue="수신거부 : 홈 &rang; 채널차단"
        className="gray999 size14"
      />
    </div>
  );
}

export function AdNameAlternative(props) {
  const contentsHead = props.contentsHead;
  const handleContentsHeader = props.handleContentsHeader;
  // 대체메시지 내용 - 광고성 정보 체크 시 - 업체명 또는 서비스명 입력란
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02">
        <input
          type="text"
          id="adTitle"
          name=""
          title="제목"
          placeholder="업체명 또는 서비스명 입력"
          value={contentsHead}
          onChange={(e) => handleContentsHeader(e.target.value)}
        />
      </div>
    </div>
  );
}

export function AdvertisementAlternative(props) {
  // 대체메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
  const contentsFooter = props.contentsFooter;
  const handleContentsFooter = props.handleContentsFooter;
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id="adNumber"
        name=""
        title="무료거부번호"
        placeholder="080 무료거부번호 입력"
        value={contentsFooter}
        maxLength={11}
        onChange={(e) => handleContentsFooter(e.target.value)}
      />
    </div>
  );
}

export function BtnWebLink(props) {
  // 버튼옵션 - 웹링크
  const handleBtnContentChange = props.contentChange;
  const btnChange = props.btnChange;
  const index = props.index;
  const webBtnId = props.web.id;
  const name = props.web.name;
  const pc = props.web.btnLink2;
  const mobile = props.web.btnLink1;
  const remove = props.remove;
  const webList = props.webList;
  const appList = props.appList;
  const bkList = props.bkList;
  const mdList = props.mdList;
  const setList = props.setList;
  const btnList = props.btnList;
  const setBtnList = props.setBtnList;
  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    console.log("해당 옵션 삭제");
    remove(setList, webList, webBtnId, btnList, setBtnList);
  };

  return (
    <div className="check-list-item">
      <div className="input-inner textarea-inner clearfix" id="cropNumArea">
        <div className="title-con">
          <span>웹링크</span>
          <a href="/" onClick={handleBtnOptionDelete}>
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </div>
        <div className="input-con">
          <input
            type="text"
            name="name"
            id=""
            value={name}
            maxlength="14"
            placeholder="버튼명 (최대 14자)"
            onChange={(e) => {
              handleBtnContentChange(e, webList, setList, index);
              btnChange(e, 1, webList, appList, bkList, mdList, index);
            }}
          />
          <input
            type="text"
            name="btnLink1"
            id=""
            value={mobile}
            maxlength=""
            placeholder="(필수) Mobile (http://)"
            onChange={(e) => handleBtnContentChange(e, webList, setList, index)}
          />
          <input
            type="text"
            name="btnLink2"
            id=""
            maxlength=""
            value={pc}
            placeholder="PC (http://)"
            onChange={(e) => handleBtnContentChange(e, webList, setList, index)}
          />
        </div>
        <p className="clear"></p>
      </div>
    </div>
  );
}

export function BtnAppLink(props) {
  // 버튼옵션 - 앱링크

  const handleBtnContentChange = props.contentChange;
  const btnChange = props.btnChange;
  const index = props.index;
  const appBtnId = props.app.id;
  const name = props.app.name;
  const android = props.app.btnLink1;
  const ios = props.app.btnLink2;
  const remove = props.remove;
  const webList = props.webList;
  const appList = props.appList;
  const bkList = props.bkList;
  const mdList = props.mdList;
  const setList = props.setList;
  const btnList = props.btnList;
  const setBtnList = props.setBtnList;
  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    console.log("해당 옵션 삭제");
    remove(setList, appList, appBtnId, btnList, setBtnList);
  };
  return (
    <div className="check-list-item">
      <div className="input-inner textarea-inner clearfix" id="cropNumArea">
        <div className="title-con">
          <span>앱링크</span>
          <a href="/" onClick={handleBtnOptionDelete}>
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </div>
        <div className="input-con">
          <input
            type="text"
            name="name"
            id=""
            maxlength="14"
            value={name}
            placeholder="버튼명 (최대 14자)"
            onChange={(e) => {
              handleBtnContentChange(e, appList, setList, index);
              btnChange(e, 2, webList, appList, bkList, mdList, index);
            }}
          />
          <input
            type="text"
            name="btnLink1"
            id=""
            maxlength=""
            value={android}
            placeholder="(필수) Android (scheme://)"
            onChange={(e) => handleBtnContentChange(e, appList, setList, index)}
          />
          <input
            type="text"
            name="btnLink2"
            id=""
            maxlength=""
            value={ios}
            placeholder="(필수) iOS (scheme://)"
            onChange={(e) => handleBtnContentChange(e, appList, setList, index)}
          />
        </div>
        <p className="clear"></p>
      </div>
    </div>
  );
}

export function BtnBotKeyword(props) {
  // 버튼옵션 - 봇키워드

  const handleBtnContentChange = props.contentChange;
  const btnChange = props.btnChange;
  const index = props.index;
  const bkBtnId = props.bk.id;
  const name = props.bk.name;
  const remove = props.remove;
  const webList = props.webList;
  const appList = props.appList;
  const bkList = props.bkList;
  const mdList = props.mdList;
  const setList = props.setList;
  const btnList = props.btnList;
  const setBtnList = props.setBtnList;
  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    console.log("해당 옵션 삭제");
    remove(setList, bkList, bkBtnId, btnList, setBtnList);
  };
  return (
    <div className="check-list-item">
      <div className="input-inner clearfix" id="cropNumArea">
        <div className="title-con">
          <span>봇키워드</span>
          <a href="/" onClick={handleBtnOptionDelete}>
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </div>
        <div className="input-con">
          <input
            type="text"
            name="name"
            id=""
            maxlength="14"
            className="gs-custom-input fc-event"
            placeholder="버튼명 (최대 14자)"
            value={name}
            onChange={(e) => {
              handleBtnContentChange(e, bkList, setList, index);
              btnChange(e, 3, webList, appList, bkList, mdList, index);
            }}
          />
        </div>
        <p className="clear"></p>
      </div>
    </div>
  );
}

export function BtnDeliverMsg(props) {
  // 버튼옵션 - 메시지전달
  const handleBtnContentChange = props.contentChange;
  const btnChange = props.btnChange;
  const index = props.index;
  const mdBtnId = props.md.id;
  const name = props.md.name;
  const remove = props.remove;
  const webList = props.webList;
  const appList = props.appList;
  const bkList = props.bkList;
  const mdList = props.mdList;
  const setList = props.setList;
  const btnList = props.btnList;
  const setBtnList = props.setBtnList;
  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    console.log("해당 옵션 삭제");
    remove(setList, mdList, mdBtnId, btnList, setBtnList);
  };
  return (
    <div className="check-list-item">
      <div className="input-inner clearfix" id="cropNumArea">
        <div className="title-con">
          <span>메시지전달</span>
          <a href="/" onClick={handleBtnOptionDelete}>
            <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
          </a>
        </div>
        <div className="input-con">
          <input
            type="text"
            name="name"
            id=""
            maxlength="14"
            className="gs-custom-input fc-event"
            placeholder="버튼명 (최대 14자)"
            value={name}
            onChange={(e) => {
              handleBtnContentChange(e, mdList, setList, index);
              btnChange(e, 4, webList, appList, bkList, mdList, index);
            }}
          />
        </div>
        <p className="clear"></p>
      </div>
    </div>
  );
}

export function AlternativeCharacter(props) {
  // 대체문자 (발신번호 및 메시지 내용)

  /*********************************** 대체문자 메시지내용 ***********************************/
  const setAlternativeCheck = props.setAlternativeCheck;
  const [contractAllSelectedAlternative, setContractAllSelectedAlternative] =
    useState(false); // 대체문자 광고성 정보 여부 - defaultchecked

  const [adNameAlternative, setAdNameAlternative] = useState(null); // 대체문자 광고성 정보체크 - 업체명 또는 서비스명
  const [advertisementAlternative, setAdvertisementAlternative] =
    useState(null); // 대체문자 광고성 정보체크 - 무료거부번호
  const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
  const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창

  const [alternativechekedImgDefault, setAlternativeChekedImgDefault] =
    useState("alternativeImg1"); // 대체메시지 - 이미지 첨부 - 라디오 defaultchecked
  const [alternativeactiveUploadImage, setAlternativeActiveUploadImage] =
    useState(null); // 대체메시지 - 이미지 첨부
  const [selectType, setSelectType] = useState("1");

  const [contents, setContents] = useState("");
  const [contentsHead, setContentsHead] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");

  const [msgByte, setMsgByte] = useState(0);

  const alternativeAd = useRef(null); // 광고 체크박스
  const alternativeTextArea = useRef(null); // 대체메시지 내용

  const [msgType, setMsgType] = useState("SMS");

  const [alternativeFocus, setAlternativeFocus] = useState(0);
  /*********************************** 대체문자 메시지내용 ***********************************/

  useEffect(() => {
    // 대체메시지 광고문구 재 랜더링
    if (contractAllSelectedAlternative) {
      setAdvertisementAlternative(
        <AdvertisementAlternative
          contentsFooter={contentsFooter}
          handleContentsFooter={handleContentsFooter}
        />
      );
      setAdNameAlternative(
        <AdNameAlternative
          contentsHead={contentsHead}
          handleContentsHeader={handleContentsHeader}
        />
      );

      handleContentsFooter(contentsFooter);
      handleContentsHeader(contentsHead);
    }
  }, [contentsHead, contentsFooter]);

  useEffect(() => {
    if (alternativeFocus !== 0) {
      textAreaPosition();
    }
  }, [alternativeFocus]);

  useEffect(() => {
    handleContents(contents);
  }, [contractAllSelectedAlternative]);

  function getAdvertisementAlternative(event) {
    //광고여부 체크

    if (event.target.checked) {
      setAdvertisementAlternative(
        <AdvertisementAlternative
          contentsFooter={contentsFooter}
          handleContentsFooter={handleContentsFooter}
        />
      );
      setAdNameAlternative(
        <AdNameAlternative
          contentsHead={contentsHead}
          handleContentsHeader={handleContentsHeader}
        />
      );
      setContractAllSelectedAlternative(true);
      setAlternativeCheck(true);
    } else {
      setAdvertisementAlternative(null);
      setAdNameAlternative(null);
      setContentsHead("");
      setContentsFooter("");
      setContractAllSelectedAlternative(false);
      setAlternativeCheck(false);
    }
  }

  const LoadMsgAlternative = (event) => {
    // 문구 불러오기

    event.preventDefault();
    popupLoadMsg(true);
  };

  const handleRetainMsgSet = (hText, mText, fText, rMsgType, rAdvertYn) => {
    if (rAdvertYn === "Y") {
      setContentsHead(hText);
      handleContentsFooter(fText);
      if (!contractAllSelectedAlternative) {
        alternativeAd.current.click();
      }
    } else {
      if (contractAllSelectedAlternative) {
        alternativeAd.current.click();
      }
    }
    handleContents(mText);
    popupLoadMsg(false);
  };

  function getByte(str) {
    return str
      .split("")
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  }
  const handleContents = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    if (contractAllSelectedAlternative) {
      const add_len = contentsHead.length + contentsFooter.length;
      text_len = text_len + add_len;
    }

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    if (contractAllSelectedAlternative) {
      totalByte = getByte(text + contentsHead + contentsFooter) + 21;
    } else {
      totalByte = getByte(text);
    }

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContents(text_val);
      setMsgByte(totalByte);
    }
  };
  const handleContentsHeader = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    const add_len = contents.length + contentsFooter.length;
    text_len = text_len + add_len;

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    totalByte = getByte(text + contents + contentsFooter) + 21;

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContentsHead(text_val);
      setMsgByte(totalByte);
    }
  };

  const handleContentsFooter = (text) => {
    const maxByte = 90;
    const text_val = text;
    let text_len = text_val.length;

    const add_len = contents.length + contentsHead.length;

    text_len = text_len + add_len;

    let totalByte = 0;
    // for (let i = 0; i < text_len; i++) {
    //   const each_char = text_val.charAt(i);
    //   const uni_char = escape(each_char);
    //   if (uni_char.length > 4) totalByte += 2;
    //   else totalByte += 1;
    // }

    totalByte = getByte(contentsHead + contents + text) + 21;

    if (totalByte > maxByte) {
      alert("최대 90Byte까지만 입력가능합니다.");
    } else {
      setContentsFooter(text_val);
      setMsgByte(totalByte);
    }
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const SaveMsgAlternative = (event) => {
    // 문구 저장하기
    event.preventDefault();
    let advertYn = "N";
    let footerText = "";
    if (contractAllSelectedAlternative) {
      advertYn = "Y";
      //080번호 체크

      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!contentsFooter) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(contentsFooter)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }

    if (!contents) {
      alert("저장할 대체 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 대체 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: contentsHead,
        mainText: contents,
        footerText: contentsFooter,
        subject: "",
        msgType: "REP_" + msgType,
        advertYn: advertYn,
      };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => console.log(error));
    }
  };

  const showMergeSettingsAlternative = () => {
    // 머지설정

    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };

  const handleContentsName = (text) => {
    const beforeTxt = contents.substring(
      0,
      alternativeTextArea.current.selectionStart
    );
    const afterTxt = contents.substring(
      alternativeTextArea.current.selectionEnd,
      contents.length
    );
    let selectPos = alternativeTextArea.current.selectionStart;

    console.log(alternativeTextArea);
    setAlternativeFocus(selectPos + text.length);
    handleContents(beforeTxt + text + afterTxt);
  };

  const textAreaPosition = () => {
    alternativeTextArea.current.setSelectionRange(
      alternativeFocus,
      alternativeFocus
    );
    alternativeTextArea.current.focus();
  };
  /*********************************** 이미지 첨부***********************************/
  const alternativeuploadImage = [
    { id: "alternativeImg1", value: "없음", content: null },
    {
      id: "alternativeImg2",
      value: "이미지",
      content: <AlternativeImageUpload />,
    },
  ];
  /*********************************** 이미지 첨부 end ***********************************/

  let receiveCount = "19998"; //수신번호 총 건수

  const handleCertification = (event) => {
    event.preventDefault();
    console.log("발신번호 인증");
  };

  return (
    <>
      <p className="line"></p>

      <div className="write-left-inner" id="sendNumber">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">대체메시지</span>
          </div>
          <div className="tit-right">
            <fieldset className="gs-custom-checkbox">
              <span className="checkbox-item">
                <input
                  type="checkbox"
                  id="Alternative"
                  name=""
                  onClick={getAdvertisementAlternative}
                />
                <label
                  for="Alternative"
                  ref={alternativeAd}
                  className={contractAllSelectedAlternative ? "checked" : null}
                >
                  광고성 정보가 포함되어 있습니다
                </label>
              </span>
            </fieldset>
          </div>
        </div>
      </div>
      <div id="msgArea" className="write-input-con change-effect-bg">
        {/* 광고성 정보 체크 시에만 노출 */}
        {adNameAlternative}
        {/* 광고성 정보 체크 시에만 노출 */}
        <div className="write-input-down-con">
          <textarea
            name=""
            id="replaceText"
            className="gs-custom-textarea change-effect"
            placeholder="내용 입력
            - 90 byte 이내"
            maxlength="2000"
            value={contents}
            ref={alternativeTextArea}
            onChange={(e) => {
              handleContents(e.target.value);
            }}
          ></textarea>
          <div className="write-input-down-info">
            <span className="msgType">SMS</span>
            <span className="byte" id="msgByte">
              {msgByte} / <em className="red">90 Byte</em>
            </span>
          </div>
        </div>
        {/* 광고성 정보 체크 시에만 노출 */}
        {advertisementAlternative}
      </div>
      <div className="write-number-down-btn mt20">
        <ul className="clearfix button-col3">
          <li>
            <Button
              buttonName="button-border-gray"
              buttonValue="문구 불러오기"
              onChangePage={LoadMsgAlternative}
            />
          </li>
          <li>
            <Button
              buttonName="button-border-gray"
              buttonValue="문구 저장하기"
              onChangePage={SaveMsgAlternative}
            />
          </li>
          <li>
            <Button
              buttonName={
                mergeVariableState ? "button-bgc-blue" : "button-border-gray"
              }
              buttonValue="머지설정"
              onChangePage={() => showMergeSettingsAlternative()}
            />
            {mergeVariableState ? (
              <MergeSettings handleContentsName={handleContentsName} />
            ) : null}
          </li>
        </ul>
      </div>

      {loadMsg && (
        <PopUpLayout
          onClose={popupLoadMsg}
          poptitle="문구 불러오기"
          size="medium"
        >
          <LayerPopLoadMsg
            selectType={selectType}
            handleRetainMsgSet={handleRetainMsgSet}
          />
        </PopUpLayout>
      )}
    </>
  );
}

export function AlternativeImageUpload() {
  // 대체메시지 - 이미지첨부

  const LoadingImg = (event) => {
    event.preventDefault();
    console.log("이미지 불러오기");
  };
  return (
    <>
      <div className="mt20 mb20">
        가로기준 최대 640px입니다.
        <br />
        640보다 큰 이미지는 640으로 자동 조정되어 발송됩니다.
        <br />
        <br />
        * 크기조정시 가로X세로 비율이 약 1:2 이상인 경우 용량초과로
        <br />
        첨부되지 않을 수 있으니 확인 후 등록 바랍니다.
      </div>
      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id=""
            name=""
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="파일등록"
            onChangePage={LoadingImg}
          />
        </div>
      </div>
    </>
  );
}

export function MergeSettings(props) {
  const handleContentsName = props.handleContentsName;
  // 메시지 내용 - 머지설정
  const SetVariableName = (event) => {
    event.preventDefault();
    console.log("#{이름} 추가");
    handleContentsName("#{이름}");
  };
  const SetVariable1 = (event) => {
    event.preventDefault();
    console.log("#{1} 추가");
    handleContentsName("#{1}");
  };
  const SetVariable2 = (event) => {
    event.preventDefault();
    console.log("#{2} 추가");
    handleContentsName("#{2}");
  };
  const SetVariable3 = (event) => {
    event.preventDefault();
    console.log("#{3} 추가");
    handleContentsName("#{3}");
  };
  const SetVariable4 = (event) => {
    event.preventDefault();
    console.log("#{4} 추가");
    handleContentsName("#{4}");
  };
  return (
    <div className="variable-info-con" id="">
      <div>
        <table className="variable-info-tb" id="">
          <tbody>
            <tr>
              <td byte="10">
                <Button
                  buttonName=""
                  buttonValue="#{이름}"
                  onChangePage={SetVariableName}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{1}"
                  onChangePage={SetVariable1}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{2}"
                  onChangePage={SetVariable2}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{3}"
                  onChangePage={SetVariable3}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{4}"
                  onChangePage={SetVariable4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function Reservation() {
  // 전송시간 - 예약

  const classes = useStyles();
  return (
    <span>
      <TextField
        id="datetime-local"
        type="datetime-local"
        defaultValue={new Date()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </span>
  );
}

export function MyAccountInfo(props) {
  // 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)
  const [accountCreation, popupAccountCreation] = useState(false);
  const [testCardPay, setTestCardPay] = useState(false);
  const setBalance = props.setBalance;
  const [dataCheck, setDataCheck] = useState(null);
  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);

  const infoData = props.infoData;
  
  const handleAccountNumber = (event) => {
    event.preventDefault();
    popupAccountCreation(true);
  };
  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };
  const handleTestVbankPay = (event) => {
    event.preventDefault();
    alert("준비중 입니다.");
  };
  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  useEffect(() => {
    const url = "/user/payInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
          console.log("jsonArr" + jsonArr.payType);
        }
      })
      .catch((error) => console.log(error));
  }, [accountNo]);
  return (
    <div className="my-account-info">
      {/* 전용 계좌 생성 전 */}
      {payInfo === "1" && accountNo === undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              생성한 전용계좌가 없습니다.
            </fieldset>
          </div>
          <div className="right">
            <a href="/" onClick={handleAccountNumber} className="underline">
              내 전용계좌 생성하기
            </a>
          </div>
        </div>
      ) : null}
      {payInfo === "1" && accountNo != undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">하나은행</fieldset>
          </div>
          <div className="right">{accountNo}</div>
        </div>
      ) : null}
      {payInfo === "2" && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">
            <Button
              buttonName="cm-highlight-btn ml10"
              buttonValue="카드결제"
              onChangePage={handleTestCardPay}
            ></Button>
          </div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {payInfo === undefined || payInfo === null && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">결제 타입 미지정</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {infoData.custType != 1 && infoData.custType != 2 ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right red">계정 승인 후 충전 가능합니다.</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      
      {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
          <LayerPopAccountCreation
            popupAccountCreation={popupAccountCreation}
            setAccountNo={setAccountNo}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}>
          <LayerPopTestCardPay
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
      {/* 전용 계좌 생성 후 */}
      {/* <div className="clearfix center">
                국민은행 1301-1111-1234
            </div> */}
    </div>
  );
}

export function AlternativeTotal(props) {
  // 대체문자 선택 시 최하단 결과란에 반영
  const smsUnit = props.smsUnit;
  const alternativeType = props.alternativeType;
  return (
    <>
      <div className="write-total-bottom">
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">대체문자 타입</fieldset>
          </div>
          <div className="right">
            <div className="gs-custom-input-btn-group">
              <div className="input-con">{alternativeType}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="write-total-bottom">
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              대체문자 건당 요금
            </fieldset>
          </div>
          <div className="right">
            <div className="gs-custom-input-btn-group">
              <div className="input-con">
                <span className="mr10">{smsUnit}</span>원
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function MsgButtonType1() {
  const classes = useStyles();
  return (
    <div className={classes.btnbox}>
      {/* 웹링크 */}
      <ul>
        <li className={classes.left}>버튼명</li>
        <li className={classes.right}>
          <input
            type="text"
            value=""
            placeholder="버튼명을 입력해주세요(최대 14자)"
          />
        </li>
        <p className="clear"></p>
      </ul>
      <ul>
        <li className={classes.left}>
          Mobile<span className="required"></span>
        </li>
        <li className={classes.right}>
          <input type="text" value="" placeholder="http://" />
        </li>
        <p className="clear"></p>
      </ul>
      <ul>
        <li className={classes.left}>PC</li>
        <li className={classes.right}>
          <input type="text" value="" placeholder="http://" />
        </li>
        <p className="clear"></p>
      </ul>
    </div>
  );
}

export function MsgButtonType2() {
  const classes = useStyles();
  return (
    <div className={classes.btnbox}>
      {/* 앱링크 */}
      <ul>
        <li className={classes.left}>버튼명</li>
        <li className={classes.right}>
          <input
            type="text"
            value=""
            placeholder="버튼명을 입력해주세요(최대 14자)"
          />
        </li>
        <p className="clear"></p>
      </ul>
      <ul>
        <li className={classes.left}>
          Android<span className="required"></span>
        </li>
        <li className={classes.right}>
          <input type="text" value="" placeholder="scheme://" />
        </li>
        <p className="clear"></p>
      </ul>
      <ul>
        <li className={classes.left}>
          iOS<span className="required"></span>
        </li>
        <li className={classes.right}>
          <input type="text" value="" placeholder="scheme://" />
        </li>
        <p className="clear"></p>
      </ul>
    </div>
  );
}

export function MsgButtonType3() {
  const classes = useStyles();
  return (
    <div className={classes.btnbox}>
      {/* 봇키워드 */}
      <ul>
        <li className={classes.left}>버튼명</li>
        <li className={classes.right}>
          <input
            type="text"
            value=""
            placeholder="버튼명을 입력해주세요(최대 14자)"
          />
        </li>
        <p className="clear"></p>
      </ul>
    </div>
  );
}

export function MsgButtonType4() {
  const classes = useStyles();
  return (
    <div className={classes.btnbox}>
      {/* 메시지전달 */}
      <ul>
        <li className={classes.left}>버튼명</li>
        <li className={classes.right}>
          <input
            type="text"
            value=""
            placeholder="버튼명을 입력해주세요(최대 14자)"
          />
        </li>
        <p className="clear"></p>
      </ul>
    </div>
  );
}

export function MsgButtonType5() {
  const classes = useStyles();
  return (
    <div className={classes.btnbox}>
      {/* 메시지전달 */}
      <ul>
        <li className={classes.left}>버튼명</li>
        <li className={classes.right}>
          <input
            type="text"
            value=""
            placeholder="버튼명을 입력해주세요(최대 14자)"
          />
        </li>
        <p className="clear"></p>
      </ul>
    </div>
  );
}

export function FriendImages() {
  return (
    <div className="graybox">
      <ul>
        <li className="gray999">사진첨부 </li>
        <li>
          <input
            type="text"
            name=""
            defaultValue=""
            className="mr10"
            placeholder="파일 선택"
          />
          <span className="box-file-input">
            <label>
              <input type="file" name="" />
            </label>
          </span>
        </li>
      </ul>

      <p className="gray999 size14">
        이미지 사용 시 메시지 내용 400자까지 입력할 수 있습니다.
        <br />
        지원 규격 : 최대 500 KB / jpg, png 파일만 첨부 가능합니다. (720*720px
        권장)
        <br />
        가로 500px 미만 또는 가로:세로 비율이 2:1 미만 또는 3:4 초과시 첨부
        불가합니다.
      </p>
    </div>
  );
}

export function FriendWideImages() {
  return (
    <div className="graybox">
      <ul>
        <li className="gray999">사진첨부 </li>
        <li>
          <input
            type="text"
            name=""
            defaultValue=""
            className="mr10"
            placeholder="파일 선택"
          />
          <span className="box-file-input">
            <label>
              <input type="file" name="" />
            </label>
          </span>
        </li>
      </ul>
      <p className="gray999 size14">
        와이드이미지 사용 시 메시지 내용 76자, 버튼 1개까지 입력할 수 있습니다.
        <br />
        지원 규격 : 최대 2 MB / jpg, png 파일만 첨부 가능합니다.
        <br />
        이미지 크기는 800*600px 사이즈로 제한됩니다.
      </p>
    </div>
  );
}

export function PreviewFriendTalk(props) {
  const style = {
    head: {
      position: "absolute",
      top: "70px",
      left: "5%",
      width: "90%",
      height: "30px",
      lineHeight: "30px",
      color: "#505050",
      fontSize: "15px",
      textAlign: "center",
      borderBottom: "1px solid #9eb8cc",
    },
    thumb: {
      position: "absolute",
      left: "5%",
      top: "110px",
    },
    thumbImgwrap: {
      width: "30px",
      height: "30px",
      borderRadius: "5px",
      overflow: "hidden",
    },
    thumbImg: {
      width: "30px",
      height: "30px",
      display: "inline-block",
      borderRadius: "5px",
    },
    thumbName: {
      fontSize: "12px",
      color: "#505050",
      position: "relative",
      top: "-10px",
    },
    area: {
      position: "absolute",
      left: "8%",
      top: "150px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "85%",
      minHeight: "50px",
      maxHeight: "340px",
      color: "#4a4a4a",
      lineHeight: "22px",
      overflow: "hidden",
      fontSize: "15px",
    },
    imgwrap: {
      width: "100%",
      height: "auto",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      overflow: "hidden",
    },
    img: {
      width: "100%",
      height: "auto",
    },
    content: {
      padding: "15px",
    },
    buttonWrap: {
      width: "100%",
      marginTop: "10px",
    },
    button: {
      width: "100%",
      padding: "6px 0",
      textAlign: "center",
      backgroundColor: "#f5f4f6",
      color: "#222",
      marginBottom: "7px",
      borderRadius: "3px",
      fontSize: "14px",
    },
    textarea: {
      padding: 0,
      minHeight: "200px",
      backgroundColor: "white",
      resize: "none",
    },
  };
  const messageText = props.messageText;
  const btnTextList = props.btnText;
  const preViewAdCheck = props.preViewAdCheck;
  const friendImgUrl = props.friendImgUrl;
  console.log(friendImgUrl);
  return (
    <>
      <div style={style.head}>채널명</div>

      <div style={style.thumb}>
        <span style={style.thumbImgwrap}>
          <img src={noimg} alt="" style={style.thumbImg} />
          {/* 이미지/동영상 등록전 */}
        </span>
        <span style={style.thumbName}>
          {preViewAdCheck === true ? "(광고)" : null} 채널명
        </span>
      </div>

      <div style={style.area}>
        <div style={style.imgwrap}>
          {/* 이미지 or 동영상 추가할 경우 노출 */}
          {friendImgUrl !== "" ? (
            <img src={friendImgUrl} alt="" style={style.img} />
          ) : (
            <img src={noimg} alt="" style={style.img} />
          )}
          {/* 이미지/동영상 등록전 */}
          {/* <img src={} alt="" style={style.img}/> */}
          {/* 이미지/동영상 등록 후 */}
        </div>
        <div style={style.content}>
          <textarea
            style={style.textarea}
            value={messageText}
            readOnly
          ></textarea>
          {/* 버튼 사용할 경우 */}
          <ul style={style.buttonWrap}>
            {btnTextList.map((btn) => (
              <div style={style.button} key={btn.name}>
                {btn.name}
              </div>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
