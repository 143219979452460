// HTTP Request
export const ROOT_URL = "";

// message
export const ALERT_SUCCECE = `SUCCESS`;
export const ALERT_ERROR = `ERROR`;

export const LOGO_NAME = `Hisms`;

export const SEVER_DOMAIN = process.env.REACT_APP_SEVER_DOMAIN
