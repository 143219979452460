import React, { useState, useEffect } from 'react';
import { PrepayedContract, Price, PayLaterContract } from 'component/Table';
import { Button } from 'component/Common';
import { postRequest, getRequest } from 'component/Axios';
import Loader from "../../../component/Loader";

export function ContractInfo(props) {
  const [isPrepayed, setIsPrepayed] = useState(true);
  const [contractTable, setContractTable] = useState(<PrepayedContract />);
  const [infoData, setInfoData] = useState([]);
  const [accountInfoData, setAccountInfoData] = useState([]);
  const [isRequest, setIsRequest] = useState(false);
  const [loading, setLoading] = useState(0);

  const setUserInfo = () => {
    if (isPrepayed) {
      setIsPrepayed(false);
      setContractTable(<PayLaterContract />); // 후불
    } else {
      setIsPrepayed(true);
      setContractTable(<PrepayedContract />); // 선불
    }
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  useEffect(() => {
    handleAccountInfo();
    handleAccountReqInfo();
  }, [isRequest]);
  const handleAccountInfo = () => {
    const url = '/user/info';
    getFetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          const payRating = jsonArr[0].preRating;
          if (payRating === '1') {
            // 후불
            setIsPrepayed(true);
          } else {
            setIsPrepayed(false);
          }
        }
      })
      .catch(error => alert(error));
  };
  const handleAccountReqInfo = () => {
    const url = '/user/accountReqInfo';
    getFetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setAccountInfoData(jsonArr[0]);
        }
        setLoading(2)
      })
      .catch(error => {
        alert(error);
      });
  };
  return (
    <div className='container'>
      {loading !=  2 && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className='page-title'>계약정보</div>
      <p className='page-subtitle'>
        <b>계약정보</b>
      </p>
      {/*contractTable*/}
      {isPrepayed ? (
        <PayLaterContract data={infoData} />
      ) : (
        <PrepayedContract
          data={infoData}
          isRequest={setIsRequest}
          reqData={accountInfoData}
        />
      )}
      <p className='page-subtitle'>
        <b>계약단가</b>
      </p>
      <Price data={infoData} />
    </div>
  );
}
export default ContractInfo;