import React, { useState, useEffect } from "react";
import setComma from "../../../../component/common/SetComma";
import { PopUpLayout } from "../../../../component/popUp/PopUpLayout";
import RCSCallerList from "../../../../modal/rcsBrand/RCSCallerList";

export function RcsBrandListTable(props) {
  const [tableRows, setTableRows] = useState("");
  const [callerId, setCallerID] = useState(false);
  const [chatbotInfo, setChatbotInfo] = useState(false);

  const custGrade = props.custGrade;

  const popCallerIdList = (params, e) => {
    setChatbotInfo(params);
    setCallerID(true);
    e.preventDefault();
    // do someting...
  };

  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (jsonArr.length === 0) {
      rows.push(
        <tr>
          <td colSpan="7">조회된 정보가 없습니다.</td>
        </tr>
      );
    } else {
      for (const row of jsonArr) {
        rows.push(
          <tr>
            {/* 대표계정일 경우만 노출 */}
            {custGrade === "1" ? <td>{row.custId}</td> : null}
            {/* 대표계정일 경우만 노출 end */}
            <td>{row.brandNm}</td>
            <td>{row.brandId}</td>
            <td>{row.regDt}</td>
            <td>
              <a
                href="/"
                onClick={(e) => {
                  popCallerIdList(row.chatbotInfo, e);
                }}
              >
                {setComma(row.chatbotCnt)}건
              </a>
            </td>
            <td>
              <a href="/rcs/rcsTemplate">{setComma(row.templateCnt)}개</a>
            </td>
            <td>{row.brandStatus}</td>
            {/*신청완료 미신청*/}
          </tr>
        );
      }
    }
    setTableRows(rows);
  }, [props]);
  return (
    <>
      <table className="table_style2">
        <thead></thead>
        <tbody>
          <tr>
            {/* 대표계정일 경우만 노출 */}
            {custGrade === "1" ? <th>계정 ID</th> : null}
            {/* 대표계정일 경우만 노출 end */}
            <th>브랜드명</th>
            <th>브랜드ID</th>
            <th>등록일시</th>
            <th>발신번호</th>
            <th>템플릿</th>
            <th>운영권한신청상태</th>
          </tr>
          {tableRows}
        </tbody>
        <tfoot></tfoot>
      </table>
      {callerId && (
        <PopUpLayout
          onClose={setCallerID}
          poptitle="발신번호 목록"
          size="small"
        >
          <RCSCallerList chatbotInfo={chatbotInfo} />
        </PopUpLayout>
      )}
    </>
  );
}

export default RcsBrandListTable;
