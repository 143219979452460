import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function Direct({
  phone,
  setPhone,
  phoneCount,
  setPhoneCount,
  contentPhone,
  setContentPhone,
  receiveCount,
  setReceiveCount,
  handleDeleteNewUser,
}) {
  function addressNoCheck(addressNo) {
    // 숫자를 제외한 문자제거
    let target = addressNo.replace(/[^0-9]/g, "");
    // 0으로 시작하는 번호로 변경
    if (target.substring(0, 1) != "0") target = "0" + target;
    //휴대폰 번호 자릿수 검증
    if (target.length < 10 || target.length > 13) {
      return false;
    }
    return target;
  }

  const AddReceiveNumber = (event) => {
    event.preventDefault();
    if (phone == "" || phone.indexOf("전화번호") > -1) {
      alert("등록할 수신번호를 입력해주세요.");
      return;
    }

    let tempTarget = phone.split("\n"); // 입력값들 줄바꿈기준으로 배열요소로 바꾸기
    if (receiveCount + tempTarget.length > 1000) {
      alert("일반전송의 1회 전송 가능한 최대건수는 1천건 입니다.");
      return;
    }

    let count = phoneCount;
    let receiveCnt = receiveCount;
    let newContentPhone = [...contentPhone];
    const wrongNumList = [];
    tempTarget.forEach((temp) => {
      if (temp != "" && temp.length > 0) {
        const target = addressNoCheck(temp);
        if (!target) {
          wrongNumList.push(temp);
        }

        if (target && newContentPhone.includes(target) === false) {
          newContentPhone.push(target);
          receiveCnt++;
          count++;
        }
      }
    });
    if (wrongNumList.length > 0) {
      alert(
        "총 " +
          wrongNumList.length +
          "건을 수신번호로 등록할 수 없습니다.\n수신번호 입력 형식을 확인해주세요." +
          wrongNumList.map((list) => "\n[" + list + "]")
      );
    }
    setContentPhone(newContentPhone);
    setPhoneCount(count);
    setReceiveCount(receiveCnt);
    setPhone("");
  };
  return (
    <DirectWrap>
      <Left>
        <Field
          name="phone"
          className="gs-custom-textarea change-effect"
          placeholder="줄바꿈하여 수신번호 직접입력"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        ></Field>
        <AddListBtn onClick={AddReceiveNumber}>
          목록 추가&nbsp;&nbsp;&gt;
        </AddListBtn>
      </Left>
      <VerticalScrollWrap>
        <SendNumberInfo>
          <thead></thead>
          <tbody>
            <tr>
              <th>No</th>
              <th>수신목록</th>
            </tr>
            {contentPhone.length === 0 ? (
              <tr>
                <td colSpan={2}>
                  등록된 수신목록이 없습니다.
                  <br />
                  최대 1,000건까지 입력 가능 합니다.
                </td>
              </tr>
            ) : (
              <>
                {contentPhone.map((list, index) => (
                  <tr key={list}>
                    <td>{index + 1}</td>
                    <td>
                      {list}
                      <DeleteBtn onClick={() => handleDeleteNewUser(list)}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="dark"
                        />
                      </DeleteBtn>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
          <tfoot></tfoot>
        </SendNumberInfo>
      </VerticalScrollWrap>
    </DirectWrap>
  );
}
export default React.memo(Direct);

const DirectWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 20px 0;
`;

const Left = styled.div`
  width: 350px;
`;

const Field = styled.textarea`
  width: 100%;
  height: 300px;
`;

const AddListBtn = styled.button`
  background-color: #555;
  color: white;
  padding: 6px;
  width: 100%;
  height: 45px;
  border-radius: 0 0 5px 5px;
  font-size: 16px;
  font-weight: 500;
`;

const VerticalScrollWrap = styled.div`
  width: 240px;
  overflow-y: auto;
  border: 1px solid #ececec;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4c4d4e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(196, 196, 196);
    border-radius: 10px;
  }
`;

const SendNumberInfo = styled.table`
  max-width: 240px;
  width: 100%;
  height: 345px;
  margin: 0;
  display: block;
  border: 0;

  & tr {
    border-bottom: 1px solid #ececec;
  }

  & tr,
  th,
  td {
    height: 33.5px;
    box-sizing: border-box;
    text-align: center;
    border-right: 1px solid #ececec;
    color: #616161;
    align-items: center;
  }

  & tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 80px;
  }

  & tr th:nth-child(2),
  tr td:nth-child(2) {
    width: 180px;
    border-right: 0;
  }

  & tr td:nth-child(2) {
    display: flex;
    justify-content: space-evenly;
  }
`;

const DeleteBtn = styled.button`
  background-color: transparent;
  width: 12px;
  padding: 0;
  margin: 0;
`;
