import React, {useState} from "react";
import { RcsTemplateRegister } from "./RcsTemplateRegister";
import { RcsTemplateList } from "./RcsTemplateList";

export const RcsTemplate = () => {

    const [activeId, setActiveId] = useState(2);
    const [activeContent, setActiveContent] = useState(<RcsTemplateList/>)
    const templateList = [
        {id: 1, name: "템플릿 등록", content: <RcsTemplateRegister /> },
        {id: 2,  name: "템플릿 목록", content: <RcsTemplateList/>},
    ]
    return (
        <div className="container">
            <ul className="tabmenu">
            {templateList.map((value) => (
                <li className={activeId === value.id ? "active" : null}>
                <a href="/" onClick={function (e) {
                    e.preventDefault();
                    setActiveId(value.id);
                    setActiveContent(value.content);
                }}>
                    {value.name}
                </a>
                </li>
            ))}
            </ul>

            <div>{activeContent}</div>
        </div>
    );
}
    /* 
    const contentList = [
        { id : 1, name : "템플릿 등록", content : <RcsTemplateRegister /> },
        { id : 2, name : "템플릿 목록", content : <RcsTemplateList/> }
    ]
    const [activeContentId, setActiveContentsId] = useState(2);
    const [activeContent, setActiveContent] = useState(<RcsTemplateList />);
    
    const showActiveContents = (e, acticeId, activeContent) => {
        e.preventDefault();
        setActiveContentsId(acticeId);
        setActiveContent(activeContent);
    }
    return (
      <div className="container">
        <ul className="tabmenu">
            {contentList.map((value) => (
                <li className={value.id === activeContentId ? "active" : null}>
                    <a href="/" onClick={(e) => showActiveContents(e, value.id, value.content)}>{value.name}</a>
                </li>
            ))}
        </ul>
        <div className="rcs-contents">
            {activeContent}
        </div>
      </div>
    );
  }
 */
