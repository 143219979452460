import React, { useState, useEffect } from "react";
import { Button, PaginationDynamic } from "component/Common";
import ListTable from "./RcsTemplateList/ListTable";
import { postRequest, getRequest } from "component/Axios";
import Loader from "../../../component/Loader";
import _ from "lodash";

export const RcsTemplateList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [templateInfo, setTemplateInfo] = useState();
  const [cardType, setSelectCardType] = useState();
  const [status, setSelectStatus] = useState();
  const [formService, setSelectFormService] = useState();
  const [myCustId, setMyCustId] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();
  const [searchMode, setSearchMode] = useState();
  const [custGrade, setCustGrade] = useState();
  const [rowStyle, setRowStyle] = useState("search-col3-group");

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleDelete = (event) => {
    event.preventDefault();
  };

  const registerOne = (event) => {
    event.preventDefault();
  };
  const registerMany = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handlePageChange(1);
  }, []);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handlePageChange = (page) => {
    let reqPage = 1;
    let reqSearch2 = "";
    let reqSearch3 = "";
    let reqSearch4 = "";
    let reqSearch5 = "";
    let reqSearch6 = "";
    let reqSearch7 = "";

    if (sessionStorage.getItem("backCheck") === "R") {
      sessionStorage.removeItem("backCheck");
      reqPage = sessionStorage.getItem("pages");

      if (
        sessionStorage.getItem("saveSearchMode") === "undefined" ||
        sessionStorage.getItem("saveSearchMode") === null ||
        sessionStorage.getItem("saveSearchMode") === ""
      ) {
        reqSearch2 = searchMode;
      } else {
        reqSearch2 = sessionStorage.getItem("saveSearchMode");
        setSearchMode(sessionStorage.getItem("saveSearchMode"));
      }

      if (
        sessionStorage.getItem("saveCustId") === "undefined" ||
        sessionStorage.getItem("saveCustId") === null ||
        sessionStorage.getItem("saveCustId") === ""
      ) {
        reqSearch3 = custId;
      } else {
        reqSearch3 = sessionStorage.getItem("saveCustId");
        setCustId(sessionStorage.getItem("saveCustId"));
      }

      if (
        sessionStorage.getItem("saveTemplateInfo") === "undefined" ||
        sessionStorage.getItem("saveTemplateInfo") === null ||
        sessionStorage.getItem("saveTemplateInfo") === ""
      ) {
        reqSearch4 = templateInfo;
      } else {
        reqSearch4 = sessionStorage.getItem("saveTemplateInfo");
        setTemplateInfo(sessionStorage.getItem("saveTemplateInfo"));
      }

      if (
        sessionStorage.getItem("saveCardType") === "undefined" ||
        sessionStorage.getItem("saveCardType") === null ||
        sessionStorage.getItem("saveCardType") === ""
      ) {
        reqSearch5 = cardType;
      } else {
        reqSearch5 = sessionStorage.getItem("saveCardType");
        setSelectCardType(sessionStorage.getItem("saveCardType"));
      }

      if (
        sessionStorage.getItem("saveStatus") === "undefined" ||
        sessionStorage.getItem("saveStatus") === null ||
        sessionStorage.getItem("saveStatus") === ""
      ) {
        reqSearch6 = status;
      } else {
        reqSearch6 = sessionStorage.getItem("saveStatus");
        setSelectStatus(sessionStorage.getItem("saveStatus"));
      }
      if (
        sessionStorage.getItem("saveFormService") === "undefined" ||
        sessionStorage.getItem("saveFormService") === null ||
        sessionStorage.getItem("saveFormService") === ""
      ) {
        reqSearch7 = formService;
      } else {
        document.getElementById("formService").value =
          sessionStorage.getItem("saveFormService");
        reqSearch7 = sessionStorage.getItem("saveFormService");
        setSelectFormService(sessionStorage.getItem("saveFormService"));
      }
    } else {
      reqPage = page;
      reqSearch2 = searchMode;
      reqSearch3 = custId;
      reqSearch4 = templateInfo;
      reqSearch5 = cardType;
      reqSearch6 = status;
      reqSearch7 = formService;
    }

    getFetch("/user/myInfo")
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCustGrade(jsonArr.custType);
          setMyCustId(jsonArr.custId);
          if (jsonArr.custType === "1") {
            setRowStyle("search-col4-group");
            getFetch("/user/subInfo")
              .then((result) => {
                if (result.resultCode === "0") {
                  const jsonArr = JSON.parse(result.resultData);
                  setCustIdList(jsonArr);

                  const data = {
                    rowCount: rowCount,
                    currentPage: reqPage,
                    search2: reqSearch2,
                    search3: reqSearch3,
                    search4: reqSearch4,
                    search5: reqSearch5,
                    search6: reqSearch6,
                    search7: reqSearch7,
                  };
                  getPostFetch("/rcs/templateList", data)
                    .then((result) => {
                      if (result.resultCode === "0") {
                        const jsonArr = JSON.parse(result.resultData);
                        const pageItem = JSON.parse(result.resultPage);
                        setLeftPage(pageItem.leftPage);
                        setRightPage(pageItem.rightPage);
                        setEndPage(pageItem.endPage);
                        setCurrentPage(pageItem.currentPage);
                        setFnlPage(pageItem.fnlPage);
                        const pages = _.range(
                          pageItem.startPage,
                          pageItem.endPage + 1
                        );
                        setPages(pages);
                        setTableRows(jsonArr);
                      } else {
                        alert(result.resultMsg);
                      }
                    })
                    .catch((error) => console.log(error));
                }
              })
              .catch((error) => console.log(error));
          }
        }
      })
      .catch((error) => console.log(error));

    sessionStorage.removeItem("backCheck");
    sessionStorage.removeItem("saveSearchMode");
    sessionStorage.removeItem("saveCustId");
    sessionStorage.removeItem("saveTemplateInfo");
    sessionStorage.removeItem("saveCardType");
    sessionStorage.removeItem("saveStatus");
    sessionStorage.removeItem("saveFormService");
    sessionStorage.removeItem("pages");
  };

  const handleChange = (templateInfo) => {
    setTemplateInfo(templateInfo);
  };

  const handleSelectCardType = (e) => {
    setSelectCardType(e.target.value);
  };

  const handleSelectStatus = (e) => {
    setSelectStatus(e.target.value);
  };

  const handleSelectFormService = (e) => {
    setSelectFormService(e.target.value);
  };

  const handleCustId = (e) => {
    setCustId(e.target.value);
  };

  const handleSearchMode = (e) => {
    setSearchMode(e.target.value);
  };

  const [checkedInputs, setCheckedInputs] = useState([]);

  const handleTemplateApprovalRequest = async (event) => {
    // RCS 템플릿 승인요청
    event.preventDefault();

    if (checkedInputs.length === 0) {
      alert("선택된 템플릿이 없습니다.");
      return;
    }

    const url = "/rcs/approveRequest";
    const data = {
      snList: checkedInputs,
    };
    if (window.confirm("선택한 템플릿을 승인 요청하시겠습니까?")) {
      setIsLoading(true);
      await getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          setCheckedInputs([]);
          handlePageChange(1);
        })
        .catch((error) => console.log(error));
    }
    setIsLoading(false);
  };

  const handleTemplateCancleRequest = async (event) => {
    // RCS 템플릿 승인요청 취소
    event.preventDefault();

    if (checkedInputs.length === 0) {
      alert("선택된 템플릿이 없습니다.");
      return;
    }

    const url = "/rcs/cancleApproveRequest";
    const data = {
      snList: checkedInputs,
    };
    if (window.confirm("선택한 템플릿의 승인요청을 취소하시겠습니까?")) {
      setIsLoading(true);
      await getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          setCheckedInputs([]);
          handlePageChange(1);
        })
        .catch((error) => console.log(error));
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}

      <div className="rcsTemlist">
        <div className="searchbox">
          <ul className={rowStyle}>
            <li>
              <select
                name="cardType"
                id="cardType"
                onChange={handleSelectCardType}
              >
                <option value="" disabled selected>
                  속성
                </option>
                <option value="">전체</option>
                <option value="cell">cell</option>
                <option value="description">description</option>
              </select>
            </li>
            <li>
              <select name="status" id="stauts" onChange={handleSelectStatus}>
                <option value="" disabled selected>
                  상태
                </option>
                <option value="">전체</option>
                <option value="0">승인대기</option>
                <option value="1">승인</option>
                <option value="2">반려</option>
                <option value="3">검수완료</option>
                <option value="4">저장</option>
                <option value="5">검수시작</option>
                <option value="6">승인대기(수정)</option>
                <option value="7">반려(수정)</option>
                <option value="8">검수완료(수정)</option>
                <option value="9">검수시작(수정)</option>
                <option value="임시저장">임시저장</option>
              </select>
            </li>
            <li>
              <select
                name="formService"
                id="formService"
                onChange={handleSelectFormService}
              >
                <option value="" disabled selected>
                  유형
                </option>
                <option value="">전체</option>
                <option value="입금">입금</option>
                <option value="출금">출금</option>
                <option value="출고">출고</option>
                <option value="주문">주문</option>
                <option value="배송">배송</option>
                <option value="예약">예약</option>
                <option value="회원가입">회원가입</option>
                <option value="인증">인증</option>
              </select>
            </li>
            {custGrade === "1" ? (
              <li>
                <select
                  onChange={handleCustId}
                  name="userID"
                  id="userID"
                  value={custId}
                >
                  <option value="" selected>
                    계정 전체
                  </option>
                  {custIdList.map((row) => (
                    <option key={row.custId} value={row.custId}>
                      {row.custId}
                    </option>
                  ))}
                </select>
              </li>
            ) : null}
          </ul>
          {custGrade === "1" ? (
            <ul className="search-col4-group">
              {/* 대표계정이 아닐 경우(일반계정 / 서브계정), className="search-col3-group"으로 수정해주세요. */}
              <select
                onChange={handleSearchMode}
                name="searchMode"
                id="searchMode"
                value={searchMode}
              >
                <option value="" disabled selected>
                  검색어
                </option>
                <option value="1">템플릿명</option>
                <option value="2">템플릿 내용</option>
              </select>
              <li>
                <input
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  onChange={(e) => handleChange(e.target.value)}
                  value={templateInfo}
                />
              </li>
              <li>
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="검색"
                  onChangePage={(e) => {
                    handlePageChange(1);
                  }}
                />
              </li>
              <li></li>
            </ul>
          ) : (
            <ul className="search-col3-group">
              {/* 대표계정이 아닐 경우(일반계정 / 서브계정), className="search-col3-group"으로 수정해주세요. */}
              <select
                onChange={handleSearchMode}
                name="searchMode"
                id="searchMode"
                value={searchMode}
              >
                <option value="" disabled selected>
                  검색어
                </option>
                <option value="1">템플릿명</option>
                <option value="2">템플릿 내용</option>
              </select>
              <li>
                <input
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  onChange={(e) => handleChange(e.target.value)}
                  value={templateInfo}
                />
              </li>
              <li>
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="검색"
                  onChangePage={(e) => {
                    handlePageChange(1);
                  }}
                />
              </li>
              <li></li>
            </ul>
          )}
        </div>
        <div className="button-wrap">
          <Button
            buttonName="button-bgc-dark"
            buttonValue="선택항목 승인요청"
            onChangePage={handleTemplateApprovalRequest}
          />
          <Button
            buttonName="button-border-dark"
            buttonValue="선택항목 승인요청 취소"
            onChangePage={handleTemplateCancleRequest}
          />
        </div>
        <ListTable
          data={tableRows}
          currentPage={currentPage}
          rowCount={rowCount}
          myCustId={myCustId}
          custType={custGrade}
          saveSearchMode={searchMode}
          setCustId={custId}
          setTemplateInfo={templateInfo}
          setCardType={cardType}
          setStatus={status}
          setFormService={formService}
          pages={currentPage}
          setCheckedInputs={setCheckedInputs}
          checkedInputs={checkedInputs}
        />
        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
