import React, { useState } from "react";
import { Button } from "../../../../component/Common";
import { getPostFetch } from "../../../../component/Axios";

function LoadExcelFile({ setPopupClose, setRegId, setLoading }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = (e) => {
    if (!file) {
      alert("불러올 파일을 선택해주세요.");
      return;
    }

    if (window.confirm("파일을 불러오시겠습니까?")) {
      const url = "/message/fileUpload";
      const formData = new FormData();

      formData.append("file", file);

      const data = formData;
      setLoading(true);
      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            const regId = result.resultData;
            setRegId(regId);
            alert("엑셀 파일을 성공적으로 불러왔습니다.")
            setPopupClose();
          } else {
            alert(result.resultMsg);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>엑셀파일</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="excelFile"
                  id="excelFile"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="불러오기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}
export default React.memo(LoadExcelFile);
