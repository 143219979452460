import React, { Component, useState, useEffect } from "react";
import PaymentHistory from "./PaymentHistory";
import TaxBillHistory from "./TaxBillHistory";
import UsageHistory from "./UsageHistory";
import SubUsageHistory from "./SubUsageHistory";

export function PayLater(props) {
  const custType = props.custType;
  const userType = props.userType;
  const custGrade = props.custGrade;
  const [page, setPage] = useState("");

  useEffect(() => {
    if(custType == "1") {
      setPage("paymentHistory");
    } else {
      setPage("usageHistory");
    }
  }, [custType]);


  let content,
    usageActive,
    paymentActive,
    taxBillActive,
    subUsageActive = null;
  if (page === "usageHistory") {
    content = (
      <UsageHistory
        custType={custType}
        userType={userType}
        custGrade={custGrade}
        preRating={"1"}
      />
    );
    usageActive = "active";
  } else if (page === "paymentHistory") {
    content = (
      <PaymentHistory
        custType={custType}
        userType={userType}
        custGrade={custGrade}
      />
    );
    paymentActive = "active";
  } else if (page === "taxBillHistory") {
    content = <TaxBillHistory />;
    taxBillActive = "active";
  } else if (page === "subUsageHistory") {
    content = (
      <SubUsageHistory
        custType={custType}
        userType={userType}
        custGrade={custGrade}
      />
    );
    subUsageActive = "active";
  }
  return (
    <div>
      <ul className="tabmenu">
        {
          custType == 1 && (
            <li className={paymentActive}>
              <a
                href="/"
                onClick={function (e) {
                  e.preventDefault();
                  setPage("paymentHistory");
                }.bind(this)}
              >
                청구내역
              </a>
            </li>
          )
        }
        <li className={usageActive}>
          <a
            href="/"
            onClick={function (e) {
              e.preventDefault();
              setPage("usageHistory");
            }.bind(this)}
          >
            사용내역
          </a>
        </li>
        {
          custGrade == 2 && 
            <li className={subUsageActive}>
              <a
                href="/"
                onClick={function (e) {
                  e.preventDefault();
                  setPage("subUsageHistory");
                }.bind(this)}
              >
                서브계정 사용내역
              </a>
            </li>
        }
        
        {/* <li className={taxBillActive}>
            <a
              href="/"
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: "taxBillHistory",
                });
              }.bind(this)}
            >
              세금계산서 발행내역
            </a>
          </li> */}
      </ul>
      <div>{content}</div>
    </div>
  );
}
export default PayLater;
