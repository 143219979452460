import React from 'react';
import Upload from './Upload';
import List from './List';
import { postRequest, getRequest } from 'component/Axios';

class KakaoProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'list',
      custGrade: '',
      custType: '',
    };
  }
  componentDidMount() {
    this.handleGetCustType();
  }
  handleGetCustType() {
    const url = '/user/custInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const json = JSON.parse(result.resultData);
          this.setState({ custType: json.custType });
          this.setState({ custGrade: json.custGrade });
        }
      })
      .catch(error => {});
  }
  render() {
    let contents,
      uploadActive,
      listActive = null;

    if (this.state.page === 'upload') {
      contents = <Upload />;
      uploadActive = 'active';
    } else if (this.state.page === 'list') {
      contents = (
        <List custType={this.state.custType} custGrade={this.state.custGrade} />
      );
      listActive = 'active';
    }
    return (
      <div className='container'>
        <ul className='tabmenu'>
          <li className={uploadActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: 'upload',
                });
              }.bind(this)}
            >
              채널 등록
            </a>
          </li>

          <li className={listActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: 'list',
                });
              }.bind(this)}
            >
              채널 목록
            </a>
          </li>
        </ul>

        <div className='kakao-contents'>{contents}</div>
      </div>
    );
  }
}

export default KakaoProfile;
