import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import mockup1 from "assets/images/ser_mockup1.png";
import mockup2 from "assets/images/ser_mockup2.png";

import sec1_img from "assets/images/ser_sec1_img.png";

import sec2_icon1 from "assets/images/ser_sec2_icon1.png";
import sec2_icon2 from "assets/images/ser_sec2_icon2.png";
import sec2_icon3 from "assets/images/ser_sec2_icon3.png";
import sec2_icon4 from "assets/images/ser_sec2_icon4.png";

import sec3_icon1 from "assets/images/ser_sec3_icon1.png";
import sec3_icon2 from "assets/images/ser_sec3_icon2.png";
import sec3_icon3 from "assets/images/ser_sec3_icon3.png";
import sec3_img1 from "assets/images/ser_sec3_img1.png";
import sec3_img2 from "assets/images/ser_sec3_img2.png";
import sec3_img3 from "assets/images/ser_sec3_img3.png";

import sec4_icon1 from "assets/images/ser_sec4_icon1.png";
import sec4_icon2 from "assets/images/ser_sec4_icon2.png";
import sec4_icon3 from "assets/images/ser_sec4_icon3.png";
import sec4_icon4 from "assets/images/ser_sec4_icon4.png";
import sec4_img1 from "assets/images/ser_sec4_img1.png";
import sec4_img2 from "assets/images/ser_sec4_img2.png";
import sec4_img3 from "assets/images/ser_sec4_img3.png";

import sec5_icon1 from "assets/images/ser_sec5_icon1.png";
import sec5_img1 from "assets/images/ser_sec5_img1.png";
import sec5_img2 from "assets/images/ser_sec5_img2.png";

import sec6_img from "assets/images/ser_sec6_img.png";

export const AboutService = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    button: false,
  };

  let navigate = useNavigate();
  function handleContactUs() {
    navigate("/customer/contactUs");
  }
  return (
    <div className="service">
      <section className="section_1">
        <div className="inner">
          <div className="lfloat">
            <p className="sec1_title">쉽고, 빠르고, 간편한!</p>
            <div className="logo"></div>
            <p className="sec1_subtitle">고객과 소통하는 빠르고 편리한 채널!</p>
            <p>
              HiSMS에서 제공드리는 다양한 메시지서비스! 비용과 상황에 맞게
              이용하세요.
            </p>
          </div>

          <div className="rfloat">
            <img src={sec1_img} alt="쉽고,빠르고,간편한 HiSMS"></img>
          </div>
          <p className="clear"></p>
        </div>
      </section>
      <section className="section_2">
        <div className="triangle"></div>
        <div className="inner">
          <h3>서비스 특징</h3>
          <p className="sub_title">
            차별화된 시스템을 통해 최적의 메시지 서비스를 제공합니다.
          </p>
          <ul>
            <li>
              <div className="sec2_icon">
                <img src={sec2_icon1} alt="" />
              </div>
              <p className="sec2_con_title">다양한 편의성</p>
              <p className="sec2_con_desc">
                기존 메시지서비스(문자/카카오)뿐만 아니라 국내최초 RCS발송
                서비스까지 제공드립니다
              </p>
            </li>
            <li>
              <div className="sec2_icon">
                <img src={sec2_icon2} alt="" />
              </div>
              <p className="sec2_con_title">비용 효율성</p>
              <p className="sec2_con_desc">
                매년 늘어가는 메시지 비용을 절약할 수 있습니다. 최저단가의
                메시지 서비스를 만나보세요
              </p>
            </li>
            <li>
              <div className="sec2_icon">
                <img src={sec2_icon3} alt="" />
              </div>
              <p className="sec2_con_title">유연한 확장성</p>
              <p className="sec2_con_desc">
                고객 환경에 맞는 다양한 연동방식을 지원 드립니다(Web/Agent/API)
              </p>
            </li>
            <li>
              <div className="sec2_icon">
                <img src={sec2_icon4} alt="" />
              </div>
              <p className="sec2_con_title">시스템 안정성</p>
              <p className="sec2_con_desc">
                다년간의 운영 노하우와 한진그룹의 안정적인 시스템을 통해
                안심하고 사용하세요
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="section_3 sectionbox">
        <div className="inner">
          <h3>기업메시지</h3>
          <ul className="sectionboxul">
            <li>
              <img src={sec3_icon1} alt="" />
              <div>
                <span>SMS</span>
                <span>단문형</span>
              </div>
              <p>45자 이내, 단문 메시지</p>
            </li>
            <li>
              <img src={sec3_icon2} alt="" />
              <div>
                <span>LMS</span>
                <span>장문형</span>
              </div>
              <p>1000자 이내, 징문 메시지</p>
            </li>
            <li>
              <img src={sec3_icon3} alt="" />
              <div>
                <span>MMS</span>
                <span>이미지형</span>
              </div>
              <p>1000자 이내, 이미지 장문 메시지</p>
            </li>
          </ul>
          <div className="divbox">
            <div className="divboxImg">
              <ul className="rolling">
                <Slider {...settings}>
                  <div>
                    <img src={sec3_img1} alt="" />
                  </div>
                  <div>
                    <img src={sec3_img2} alt="" />
                  </div>
                  <div>
                    <img src={sec3_img3} alt="" />
                  </div>
                </Slider>
              </ul>
              <img src={mockup1} alt="" className="mockup" />
            </div>
            <div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>1</span>기업 메시지 서비스
                </p>
                <p className="divboxTextContent">
                  <p className="mb30">
                    이동통신 3사의 메시지 서비스로 휴대폰에 기본 탑재된
                    메시지함으로 메시지를 전송합니다.
                  </p>
                  <p>
                    일반폰, 스마트폰을 가리지 않고 메시지 전송이 가능하며,
                    합리적 가격과 편리한 전송환경, 높은 성공률 등의 장점을 갖고
                    있습니다.
                  </p>
                </p>
              </div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>2</span>주요특징
                </p>
                <p className="divboxTextContent">
                  <p>- 발신번호 사전등록 필요</p>
                  <p>- 등록된 발신번호로만 메시지 전송 가능 </p>
                  <p>
                    - 등록서류 : 재직증명서(휴대전화번호), <br />
                    <span className="ml10">
                      통신가입사실 증명원(유선번호, 대표번호)
                    </span>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section_4 sectionbox">
        <div className="inner">
          <h3>카카오메시지</h3>
          <ul className="sectionboxul">
            <li>
              <img src={sec4_icon1} alt="" />
              <div>
                <span>알림톡</span>
              </div>
              <p>
                템플릿메시지(정보성)
                <br />
                1000자 이내, 액션버튼
              </p>
            </li>
            <li>
              <img src={sec4_icon2} alt="" />
              <div>
                <span>친구톡 텍스트</span>
              </div>
              <p>1000자 이내, 액션버튼</p>
            </li>
            <li>
              <img src={sec4_icon3} alt="" />
              <div>
                <span>친구톡 이미지</span>
              </div>
              <p>
                400자 이내, 액션버튼
                <br />
                이미지 추가
              </p>
            </li>
            <li>
              <img src={sec4_icon4} alt="" />
              <div>
                <span>찬구톡 와이드</span>
              </div>
              <p>
                76자 이내, 액션버튼
                <br />
                이미지추가
              </p>
            </li>
          </ul>
          <div className="divbox">
            <div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>1</span>카카오톡 기반 문자 메시지 서비스
                </p>
                <p className="divboxTextContent">
                  <p className="mb30">
                    정보성 알림톡, 광고성 친구톡으로 구성되어 있으며, <br />
                    필요시 기업메시지와 연계 발송도 가능합니다.
                  </p>
                  <p>
                    기업 메시지 대비 저렴한 비용
                    <br /> 친숙한 카카오톡 플랫폼을 통한 전송 환경 제공
                  </p>
                </p>
              </div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>2</span>주요특징
                </p>
                <p className="divboxTextContent">
                  <p>- 알림톡 (정보성)</p>
                  <p className="mb30">
                    : 템플릿 사전등록 필요(카카오톡으로 신청 및 승인)
                  </p>
                  <p>- 친구톡 (광고성)</p>
                  <p className="mb30">
                    : 채널 추가를 완료한 수신 동의 고객에게만 전송 가능
                  </p>
                </p>
              </div>
            </div>
            <div className="divboxImg">
              <ul className="rolling">
                <Slider {...settings}>
                  <div>
                    <img src={sec4_img1} alt="" />
                  </div>
                  <div>
                    <img src={sec4_img2} alt="" />
                  </div>
                  <div>
                    <img src={sec4_img3} alt="" />
                  </div>
                </Slider>
              </ul>
              <img src={mockup1} alt="" className="mockup" />
            </div>
          </div>
        </div>
      </section>
      <section className="section_5 sectionbox">
        <div className="inner">
          <h3>RCS 메시지</h3>
          <ul className="sectionboxul">
            <li>
              <img src={sec5_icon1} alt="" />
              <div>
                <span>RCS 템플릿</span>
              </div>
              <p>
                템플릿메시지(정보성)
                <br />
                90자 이내, 액션버튼
              </p>
            </li>
            <li>
              <img src={sec3_icon1} alt="" />
              <div>
                <span>RCS SMS</span>
              </div>
              <p>100자 이내, 액션버튼</p>
            </li>
            <li>
              <img src={sec3_icon2} alt="" />
              <div>
                <span>RCS LMS</span>
              </div>
              <p>1300자 이내, 액션버튼</p>
            </li>
            <li>
              <img src={sec4_icon3} alt="" />
              <div>
                <span>RCS MMS</span>
              </div>
              <p>
                1300자 이내, 액션버튼
                <br />
                (Standalon/Carousel)
              </p>
            </li>
          </ul>
          <div className="divbox">
            <div className="divboxImg">
              <ul className="rolling">
                <Slider {...settings}>
                  <div>
                    <img src={sec5_img1} alt="" />
                  </div>
                  <div>
                    <img src={sec5_img2} alt="" />
                  </div>
                </Slider>
              </ul>
              <img src={mockup1} alt="" className="mockup" />
            </div>
            <div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>1</span>차세대 기업메시지 서비스
                </p>
                <p className="divboxTextContent">
                  <p className="mb30">
                    이동통신3사의 차세대 메시지 서비스로 신형 스마트폰에
                    <br />
                    기본 탑재된 메시지 함으로 향상된 RCS메시지를 전송합니다.
                  </p>
                  <p className="mb30">
                    RCS메시지는 다양한 컨텐츠, 버튼 적용 및 고객 피드백에 대한
                    <br />
                    확인이 가능합니다. (URL링크, 위치, 일정, 대화방, 전화연결
                    등)
                  </p>
                </p>
              </div>
              <div className="divboxText">
                <p className="divboxTextTitle">
                  <span>2</span>주요특징
                </p>
                <p className="divboxTextContent">
                  <p className="mt30">- 브랜드 등록 및 대행사 추가</p>
                  <p className="ml10">: RCS 고객사 브랜드 개설필요</p>
                  <p className="ml10">: 발송 대행사(한진정보통신) 추가 필요</p>
                  <p className="mt30">- 템플릿 사전등록(템플릿RCS 발송 시)</p>
                  <p className="ml10">
                    : 템플릿 RCS(정보성)는 발송 전, 문구 사전등록 필요
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section_6">
        <div className="inner">
          <h3>서비스 흐름</h3>
          <img src={sec6_img} alt="서비스 흐름"></img>
        </div>
      </section>
      <section className="section_7">
        <div className="inner">
          <h3>서비스 지원형태</h3>
          <ul>
            <li>
              <p className="sec7_con_title">Agent 설치시간 30분</p>
              <p className="sec7_con_desc">
                불필요한 설치 프로세스를 줄여 설치 시간을 단축할 수 있습니다.
              </p>
            </li>
            <li>
              <p className="sec7_con_title">메시지전송용 WEB제공</p>
              <p className="sec7_con_desc">
                복잡한 설치를 따로 할 필요없이, 메시지를 전송할 수 있는 WEB을
                제공해드립니다.
              </p>
            </li>
            <li>
              <p className="sec7_con_title">API제공</p>
              <p className="sec7_con_desc">
                메시지 Agent설치 없이 API연동을 통한 메시징 시스템구성
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="section_8">
        <div className="inner">
          <h3>선불요금안내</h3>
          <table className="feeTable">
            <thead></thead>
            <tbody>
              <tr>
                <td rowSpan="3" className="center">
                  기업메시지
                </td>
                <td>SMS</td>
                <td>
                  <span>9</span>원
                </td>
              </tr>
              <tr>
                <td>LMS</td>
                <td>
                  <span>28</span>원
                </td>
              </tr>
              <tr>
                <td>MMS</td>
                <td>
                  <span>60</span>원
                </td>
              </tr>
              <tr>
                <td rowSpan="4" className="center">
                  카카오메시지
                </td>
                <td>알림톡</td>
                <td>
                  <span>7</span>원
                </td>
              </tr>
              <tr>
                <td>친구톡 텍스트</td>
                <td>
                  <span>12</span>원
                </td>
              </tr>
              <tr>
                <td>친구톡 이미지</td>
                <td>
                  <span>20</span>원
                </td>
              </tr>
              <tr>
                <td>친구톡 와이드</td>
                <td>
                  <span>22</span>원
                </td>
              </tr>
              <tr>
                <td rowSpan="4" className="center">
                  RCS
                </td>
                <td>SMS템플릿</td>
                <td>
                  <span>7</span>원
                </td>
              </tr>
              <tr>
                <td>SMS</td>
                <td>
                  <span>8</span>원
                </td>
              </tr>
              <tr>
                <td>LMS</td>
                <td>
                  <span>25</span>원
                </td>
              </tr>
              <tr>
                <td>MMS</td>
                <td>
                  <span>70</span>원
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
          <div className="fee_info">
            <p>※ 부가세 별도입니다</p>
            <p className="bold">
              ※ 선불 요금제에 한해 적용됩니다(후불제 별도 협의)
            </p>
          </div>
          <button
            className="bbtn center button-bgc-dark"
            onClick={handleContactUs}
          >
            문의하기
          </button>
        </div>
      </section>
    </div>
  );
};
