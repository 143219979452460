import React, { useState } from "react";
import { Button} from "component/Common";
import { KakaoProfileUpload } from "component/Table";
import { postRequest } from "component/Axios";
import { useNavigate } from "react-router-dom";

class Upload extends React.Component {
    state = { plusId : '' , num : '', check : false , category : [] , category1 : '' , category2 : '' , category3 : '' , phone : '' }
        
    render() {
        async function getPostFetch(url,data) {
            return postRequest.fetch(url,data);
        }
        
        const setPlusId = id => {
            this.setState({plusId : id})
        }
        
        const setNum = num => {
            this.setState({num: num})
        }

        const setCheck = checked => {
            this.setState({check: checked})
        }

        const setCategory1 = val => {
            this.setState({category1: val})
        }

        const setCategory2 = val => {
            this.setState({category2: val})
        }

        const setCategory3 = val => {
            this.setState({category3: val})
        }

        const setPhone = val => {
            this.setState({phone: val})
        }

        const KakaoChannelCenter = (event) => {
            event.preventDefault();
            window.open("https://center-pf.kakao.com/","_blank");
        };

        
        const ConnectKakao = (event) => {
            event.preventDefault();
            
            if ( !this.state.plusId ) {
                alert("카카오톡 채널을 입력해주세요.");
                return;
            }

            if ( !this.state.category1 ) {
                alert("카테고리 대분류를 선택해 주세요.");
                return;
            }

            if ( !this.state.category2 ) {
                alert("카테고리 중분류를 선택해 주세요.");
                return;
            }

            if ( !this.state.category3 ) {
                alert("카테고리 소분류를 선택해 주세요.");
                return;
            }

            if ( !this.state.phone ) {
                alert("관리자 휴대폰 번호를 입력해 주세요.");
                return;
            }

            if ( !this.state.num ) {
                alert("인증번호를 입력해주세요.");
                return;
            }

            if ( !this.state.check ) {
                alert("인증번호를 요청해 주세요.");
                return;
            }

            const cate = this.state.category1 + this.state.category2 + this.state.category3

            if( window.confirm("카카오 채널을 추가 하시겠습니까?") ){
                const url  = "/kko/profileSave";
                const data = { plusId : this.state.plusId , num : this.state.num , pfCtCode : cate , phone : this.state.phone }
                
                getPostFetch(url, data)
                .then(result => {
                        alert(result.resultMsg)
                        if( result.resultCode === "0" ) {
                            this.props.navigation.navigate("/kakao/profile")
                        } 
                    }
                )
                .catch(error => alert(error))
                
            }
        };
        return (
            <>
                <div className="kakao-contents">
                    <div className="Info">
                        카톡에서 채널 검색 혹은 카카오톡 채널 관리자센터를 통해
                        채널 확인 후, @가 붙은 검색용 아이디 입력
                        <Button buttonName="button-border-dark rounded ssbtn" buttonValue="카카오톡 채널 관리자센터"  onChangePage={KakaoChannelCenter}/>

                    </div>
                    <KakaoProfileUpload setPlusId={setPlusId} setNum={setNum} setCheck={setCheck} 
                    setCategory1={setCategory1} setCategory2={setCategory2} setCategory3={setCategory3} setPhone={setPhone} />

                    <p className="center button-wrap">
                        <Button 
                            buttonName="button-bgc-blue bbtn" 
                            buttonValue="카카오 채널 추가" 
                            onChangePage={ConnectKakao}
                        />
                    </p>
                </div>
            </>
        );
    }
}

export default Upload;
