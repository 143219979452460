import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Button,PaginationDynamic } from "component/Common";
import { UseSendNumberInfo, UnUsedSendNumberInfo } from "component/Table";
import { makeStyles } from '@material-ui/core/styles';
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopAddSendNumber } from "component/popUp/PopUp";
import { postRequest } from "component/Axios";
import Loader from "../../../component/Loader";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    white: {
      color: '#ffffff',
      fontSize:20
    },
    searchbtn: {
        width: 45,
        height : 45,
        borderRadius : '3px',
        backgroundColor : '#313131',
        display: 'inline-block',
        textAlign : 'center',
        lineHeight : '45px',
        fontWeight : 300
    },
    box : {
        margin: '40px 0 20px 0'
    }
  }));

export const SendNumber = () => {
    const classes = useStyles();
    const [popup, setPopup] = useState(false);

    const [activeId, setActiveId] = useState(1);
    const [activeContent, setActiveContent] = useState(<UseSendNumberInfo />);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [tableRows, setTableRows] = useState([]);
    const [leftPage, setLeftPage] = useState(1);
    const [rightPage, setRightPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const [fnlPage, setFnlPage] = useState(1);
    const [pages, setPages] = useState([1]);
    const [search1, setSearch1] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleLoadingOn = () => {
        setLoading(true);
    }
  
    const handleLoadingClose = () => {
        setLoading(false);
    }

    const [saveButton, setSaveButton] = useState(<SaveButton handleLoadingOn={handleLoadingOn} 
                                                             handleLoadingClose={handleLoadingClose}/>);

    const tabMenu = [
        { id: 1, name: "사용가능 발신번호", content: <UseSendNumberInfo/>, button: <SaveButton   handleLoadingOn={handleLoadingOn} 
                                                                                               handleLoadingClose={handleLoadingClose}/>},
        { id: 2, name: "승인요청 및 사용불가 발신번호", content: <UnUsedSendNumberInfo />, button: null},
    ]
    
    useEffect(() => {
        setTableRows([]);
        handlePageChange(1);
    }, [activeId, popup]);

    const handleEnterPress = e => {
        if(e.key === 'Enter') {
          handlePageChange(1);
        }
      }    

    const handleSearch = (event) => {
        event.preventDefault();
        handlePageChange(1);
    };
    const handleAddNumber = (event) => {
        event.preventDefault();
        setPopup(true);
    };

    const popupClose = () => {
        setPopup(false);
    }

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }
    
    const handlePageChoice = page => {
        let num = page;
        if ( page === 0 ) 
            num = currentPage;

        handlePageChange(num);
    }



    const handlePageChange = page => {
        const url = "/callback/list";
        const data = { rowCount : rowCount, currentPage: page, search1 : search1 , search2 : activeId }
        handleLoadingOn();
        getPostFetch(url, data)
        .then(result => {
                if( result.resultCode === "0" ) {
                    const jsonArr = JSON.parse(result.resultData);
                    const pageItem = JSON.parse(result.resultPage);
                    setLeftPage(pageItem.leftPage);
                    setRightPage(pageItem.rightPage);
                    setEndPage(pageItem.endPage);
                    setCurrentPage(pageItem.currentPage);
                    setFnlPage(pageItem.fnlPage);
                    const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                    setPages(pages);
                    setTableRows(jsonArr);
                } else {
                    alert(result.resultMsg);
                }
                handleLoadingClose();
            }
        )
        .catch(error => handleLoadingClose())
    }
    
    return (
        <>
        {loading ==  true && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <div className="container">
            <p className="page-title">발신번호 관리</p>
            
            <ul className="tabmenu">
                {tabMenu.map((value) => (
                    <li className={activeId === value.id ? "active" : null}>
                        <a href="/" onClick={function (e) {
                            e.preventDefault();
                            setActiveId(value.id);
                            setActiveContent(value.content);
                            setSaveButton(value.button);
                        }}>{value.name}</a>
                    </li>
                ))}
         </ul>

            <div className={classes.box}>
                <div className="button-wrap lfloat">
                    <Button
                        buttonName="button-bgc-dark"
                        buttonValue="발신번호 신규등록"
                        onChangePage={handleAddNumber}
                    />
                    {saveButton}
                </div>
                <div className="sendNumber">
                    <div className="rfloat boardsearch">
                    <input type="text" placeholder="발신번호 검색" className="bolder_none" onChange={e => {setSearch1(e.target.value)}} onKeyPress={handleEnterPress}/>
                    <a  onClick={handleSearch}>
                    <FontAwesomeIcon icon={faSearch} />
                    </a>
                    </div>
                </div>
                
                <p className="clear"></p>
            </div>
            
            {activeId === 1 ? 
                <UseSendNumberInfo data={tableRows} handlePageChoice={handlePageChoice} /> : 
                <UnUsedSendNumberInfo data={tableRows} handlePageChoice={handlePageChoice} />
            }
            
            <PaginationDynamic  
                    pages={pages}
                    currentPage={currentPage}
                    leftPage={leftPage}
                    rightPage={rightPage}
                    endPage={endPage}
                    fnlPage={fnlPage}
                    onPageChange={handlePageChange}
                />
            {popup && (
                <PopUpLayout onClose={setPopup} poptitle="발신번호 신규 등록">
                    <LayerPopAddSendNumber popupClose={popupClose} handlePageChoice={handlePageChoice}/>
                </PopUpLayout>
            )}
        </div>
        </>
    );
};
export const SaveButton = (props) => {

    const handleLoadingOn = props.handleLoadingOn;
    const handleLoadingClose = props.handleLoadingClose;

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    } 

    const handleSave = (event) => {
        event.preventDefault();
        
        let num = 0;
        const snNodeList = document.getElementsByName("cbNm")
        const changeDate = [];
        snNodeList.forEach((node) => {
            let val = node.value;
            let oldId = "old_" + num;
            let oldVal = document.getElementById(oldId).value;
            let snId = "sn_" + num;
            let sn = document.getElementById(snId).value;
            let snTypeId = "snType_" + num;
            let type = document.getElementById(snTypeId).value;
            let checkId = "id" + num;
            let check = document.getElementById(checkId).checked;
            let checkNum = "1";
            if ( !check )
                checkNum = "2";
            num = num + 1;
            if ( val != oldVal || type != checkNum )
                changeDate.push({sn : sn , nm : val, type : checkNum});
        }) 

        if( window.confirm("변경사항을 저장하시겠습니까?") ){
            const url = "/callback/modify";
            const data = { cbList : changeDate };
            handleLoadingOn();
            getPostFetch(url, data)
            .then(result => {
                    alert(result.resultMsg);
                    handleLoadingClose();
                }
            )
            .catch(error => alert(error))
        }

    };
    return(
        <Button
            buttonName="button-border-gray"
            buttonValue="변경사항 저장"
            onChangePage={handleSave}
        />
    );
}