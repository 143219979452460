import React, { useState, useEffect } from "react";
import { Button } from "../../../../component/Common";
import { getPostFetch } from "../../../../component/Axios";
import AddressModalContent from "./addressModalContent/AddressModalContent";

function ImportAddress({ uploadAddrPopClose }) {
  const [postSnList, setPostSnList] = useState([]); // 선택 수신자 리스트 데이터
  const [activeId, setActiveId] = useState("1");

  const addressType = [
    { id: "1", name: "공유 주소록" },
    { id: "2", name: "개인 주소록" },
  ];

  // 선택 수신자 리스트 최종 반영
  const handleAdd = (event) => {
    event.preventDefault();
    if (postSnList.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    if (window.confirm("선택 수신자 리스트를 추가하시겠습니까?")) {
      const url = "/message/addrListSave";
      const data = { search1 : "2" , snList: postSnList };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            uploadAddrPopClose(result.resultData);
          }
        })
        .catch((error) => alert(error));
    }
  };

  return (
    <>
      <ul className="tabmenu2">
        {addressType.map((value) => (
          <li className={activeId === value.id && "active"} key={value.id}>
            <a
              href="/"
              onClick={function (e) {
                e.preventDefault();
                setActiveId(value.id);
              }}
            >
              {value.name}
            </a>
          </li>
        ))}
      </ul>
      <AddressModalContent setPostSnList={setPostSnList} activeId={activeId} uploadAddrPopClose={uploadAddrPopClose}/>

      <div className="popup-bottom-button">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="추가"
          onChangePage={handleAdd}
        />
      </div>
    </>
  );
}

export default ImportAddress;
