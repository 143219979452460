import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'component/Common';
import { useNavigate, useLocation } from 'react-router-dom';
import { postRequest, getRequest } from 'component/Axios';

export default function SignOutCompleted() {
  const location = useLocation();
  const navigate = useNavigate();
  const [signoutSucInfo, setSignoutSucInfo] = useState({
    custId: '',
    userNm: '',
    preRating: '',
    regDt: '',
  });
  useEffect(() => {
    if (location.state == null) {
      alert('잘못된 접근입니다.');
      navigate('/mypage/info/accountInfo');
    } else {
      setSignoutSucInfo(location.state.signoutSucInfo);
    }
  });

  return (
    <div className='container signout_complete'>
      <h2>탈퇴 신청완료</h2>
      <h4>
        안녕하세요. <span className='blue'>Hisms</span>입니다.
      </h4>
      <p>
        그 동안 Hisms를 이용해 주셔서 대단히 감사합니다. 회원탈퇴 신청이
        완료되었습니다.
        <br />
        요청하신 회원탈퇴 신청은 현재 처리 중에 있으며, 최대한 빠른 시일이내에
        탈퇴를 도와드리겠습니다.
        <br />
        고객님께서 신청하신 내역은 다음과 같습니다.
      </p>

      <table>
        <colgroup>
          <col width='20%' />
          <col width='' />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <th>아이디</th>
            <td>{signoutSucInfo.custId}</td>
          </tr>
          <tr>
            <th>신청 사용자</th>
            <td>{signoutSucInfo.userNm}</td>
          </tr>
          <tr>
            <th>서비스 이용 유형</th>
            <td>{signoutSucInfo.preRating === '2' ? '선불' : '후불'}</td>
          </tr>
          <tr>
            <th>신청일</th>
            <td>{signoutSucInfo.regDt}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <b>Hisms를 이용해 주셔서 감사합니다.</b>
      <NavLink to='/'>
        <Button buttonName='button-border-gray' buttonValue='홈으로' />
      </NavLink>
    </div>
  );
}
