import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../../../../component/Common";
import ImportAddressList from "./tableList/ImportAddressList";
import ReceiveAddressList from "./tableList/ReceiveAddressList";
import { getFetch, getPostFetch } from "../../../../../component/Axios";
import dotted from "../../../../../assets/images/base.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFolder,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import Loader from "component/Loader";
import { PaginationDynamic } from "component/Common";
export function AddressModalContent({ setPostSnList, activeId, uploadAddrPopClose }) {
  const [groupList, setGroupList] = useState([]); // 전체를 제외한 모든 그룹 목록
  const [totCnt, setTotCnt] = useState(0); // 주소록 총 갯수
  const [agCnt, setAgCnt] = useState(0); // 각 그룹의 주소록 갯수
  const [group, setGroup] = useState(""); // 각 그룹명
  const showGroupRef = useRef(false); // 2023-11-22 주소록 최초표시 예외처리(jm.kil)
  const [upSn, setUpSn] = useState(0); // 상위그룹 고유 일련번호
  const [groupNm, setGroupNm] = useState("");
  const [groupDepth, setGroupDepth] = useState(0);
  const [groupCnt, setGroupCnt] = useState(0);
  const [agSn, setAgSn] = useState(0); // 그룹의 일련번호
  const [search2, setSearch2] = useState("1"); // 검색조건 1: 이름, 2: 연락처
  const [search3, setSearch3] = useState(""); // 검색어 입력 내용
  const [snList, setSnList] = useState([]); // 선택 목록
  const [delList, setDelList] = useState([]); // 선택 수신자 리스트의 선택 목록
  const [selectCnt, setSelectCnt] = useState(0); // 선택 수신자 건수
  const [tableRows, setTableRows] = useState(""); // 선택된 그룹의 데이터
  const [rowData, setRowData] = useState([]); // 선택 수신자 리스트 데이터

  const [addrCurrentPage, setAddrCurrentPage] = useState(1);
  const [addrRowCount, setAddrRowCount] = useState(500);
  const [addrLeftPage, setAddrLeftPage] = useState(1);
  const [addrRightPage, setAddrRightPage] = useState(1);
  const [addrEndPage, setAddrEndPage] = useState(1);
  const [addrFnlPage, setAddrFnlPage] = useState(1);
  const [addrPages, setAddrPages] = useState([1]);

  const [loading, setLoading] = useState(false);

  const handleAddrPageChange = (page) => {
    let url = "/addr/addrListSend";
    const data = {
      search1: activeId,
      search2: search2,
      search3: search3,
      search4: agSn,
      search5: sessionStorage.getItem("custType"),
      rowCount : addrRowCount, 
      currentPage: page
    };

    if (activeId === "1") {
      // 공유 주소록 조회
      getFetch("/user/myInfo")
        .then((myInfo) => {
          if (myInfo.resultCode === "0") {
            const myInfoArr = JSON.parse(myInfo.resultData);
            let searchData = { ...data, search5: myInfoArr.custType };

            getAddrListView(url, searchData);
          } else {
            alert(myInfo.resultMsg);
          }
        })
        .catch((error) => alert(error));
    } else {
      // 개인 주소록 조회

      getAddrListView(url, data);
    }
  }

  const handleReceivePageChange = (page) => {

  }

  // 선택 수신자 리스트 변동되면 해당 값 post로 보내기 위한 key값만 추출
  useEffect(() => {
    let postList = rowData.map((row) => row.abSn);
    setPostSnList(postList);
  }, [rowData]);

  useEffect(() => {
    handleGroupSearch();
  }, [activeId]);

  // 주소록 리스트 조회 (공유주소록의 then 내부)
  const handleGroupSearch = () => {

    handleChoiceView(0);
    let url = "/addr/addrGroupList";

    if (activeId === "1") {
      // 공유 주소록 조회
      getFetch("/user/myInfo")
        .then((myInfo) => {
          if (myInfo.resultCode === "0") {
            const myInfoArr = JSON.parse(myInfo.resultData);
            let data = { search1: activeId, search2: myInfoArr.custType };
            getAddrGroupList(url, data);
          } else {
            alert(myInfo.resultMsg);
          }
        })
        .catch((error) => alert(error));
    } else {
      // 개인 주소록 조회
      let data = { search1: activeId };
      getAddrGroupList(url, data);
    }
    
  };

  function getAddrGroupList(url, data) {
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setGroupList(jsonArr);
          setTotCnt(jsonArr[0].totCnt);
          setAgCnt(jsonArr[0].totCnt);
          handelDefault();
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  }

  // 주소록 목록 기본 셋팅
  const handelDefault = () => {
    setAgSn(0);
    setGroup("전체");
    setUpSn(-1);
    setGroupNm("전체");
    setGroupDepth(1);
    setGroupCnt(0);
    setAgCnt(totCnt);
  };

  const handleGroup = (sn, nm, tot, upSn, grNm, depth, cnt) => {
    setAgSn(sn);
    setGroup(nm);
    setUpSn(upSn);
    setGroupNm(grNm);
    setGroupDepth(depth);
    setGroupCnt(cnt);
    if (sn === "0") setAgCnt(totCnt);
    else setAgCnt(tot);
    handleChoiceView(sn);
  };

  // 검색 조회
  const handleSearch = (event) => {
    event.preventDefault();
    handleChoiceView(agSn);
  };

  // 공유주소록의 then 내부 (중복)
  const handleChoiceView = (sn) => {
    let url = "/addr/addrListSend";
    const data = {
      search1: activeId,
      search2: search2,
      search3: search3,
      search4: sn,
      search5: sessionStorage.getItem("custType"),
      rowCount : addrRowCount, 
      currentPage: 1
    };

    if (activeId === "1") {
      // 공유 주소록 조회
      getFetch("/user/myInfo")
        .then((myInfo) => {
          if (myInfo.resultCode === "0") {
            const myInfoArr = JSON.parse(myInfo.resultData);
            let searchData = { ...data, search5: myInfoArr.custType };

            getAddrListView(url, searchData);
          } else {
            alert(myInfo.resultMsg);
          }
        })
        .catch((error) => alert(error));
    } else {
      // 개인 주소록 조회
      getAddrListView(url, data);
    }
  };

  function getAddrListView(url, data) {
    setLoading(true);
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {

          const pageItem = JSON.parse(result.resultPage);
          setAddrLeftPage(pageItem.leftPage);
          setAddrRightPage(pageItem.rightPage);
          setAddrEndPage(pageItem.endPage);
          setAddrCurrentPage(pageItem.currentPage);
          setAddrFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setAddrPages(pages);

          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
        } else {
          alert(result.resultMsg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

    // 선택 그룹 등록
    const handleAddGroupSelectList = (event) => {
      if (window.confirm("선택 그룹 : [" + group + "] / 건수 : [" + (agSn === 0 ? totCnt : agCnt) + "] 을 등록하시겠습니까?")) {
        const url = "/message/addrListSave";
        const data = { search1 : "1" , search2 : activeId, search3 : agSn, search4 : sessionStorage.getItem("custType") };
        setLoading(true);
        getPostFetch(url, data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              setLoading(false);
              uploadAddrPopClose(result.resultData);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
  
        
    };

  // 선택 항목 추가
  const handleAddSelectList = (event) => {
    event.preventDefault();
    if (snList.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    var arrSnList = [];
    var arrRowData = [];
    var dupCnt = 0;

    for(var i = 0; i < rowData.length; i++) {
      arrRowData.push(rowData[i].abSn);
    }

    for(var i = 0; i < snList.length; i++) {
      if(arrRowData.indexOf(snList[i].abSn) != -1) {
        dupCnt++;
      } else {
        arrSnList.push(snList[i]);
      }
    }

    if(rowData.length == 0) {
      arrSnList = snList;
    }

    if(dupCnt > 0) {
      alert("이미 등록된 데이터 ["+ dupCnt +"] 건은 삭제 후 추가되었습니다.")
    }
    
    const newSelectCnt = selectCnt + arrSnList.length;
    const newRowData = rowData.concat(arrSnList);
    setRowData(newRowData);
    setSelectCnt(newSelectCnt);
    
    setSnList([]);
  };

  // 선택한 목록 삭제
  const handelChoiceDelete = () => {
    let newArr = rowData;
    let cnt = selectCnt - delList.length;
    delList.forEach((item) => {
      newArr = newArr.filter((el) => el.abSn !== item);
    });
    setRowData(newArr);
    setSelectCnt(cnt);
    setDelList([]);
  };

  // 선택 수신자 리스트에서 단건 삭제
  const handleDeleteNewUser = (sn) => {
    let editList = rowData.filter((data) => data.abSn !== sn);
    setRowData(editList);
    setSelectCnt(selectCnt - 1);
  };

  return (
    <div className="address_area">
      {loading == true && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="left">
        <p className="list-title">주소록 리스트</p>
        <div className="filter">
          <div className="boardsearch">
            <select
              name="search_type"
              id=""
              className="select_type2"
              onChange={(e) => {
                setSearch2(e.target.value);
              }}
            >
              <option value="1">이름</option>
              <option value="2">연락처</option>
            </select>
            <input
              type="text"
              name=""
              defaultValue=""
              placeholder="검색어를 입력하세요"
              className="bolder_none"
              onChange={(e) => {
                setSearch3(e.target.value);
              }}
            />
            <a href="/" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
          <div style={{marginLeft: "26px"}}>
          <Button
            buttonName="button-bgc-blue"
            buttonValue="선택 항목 추가"
            onChangePage={handleAddSelectList}
          />
          <Button
            buttonName="button-bgc-blue"
            buttonValue="선택 그룹 등록"
            onChangePage={handleAddGroupSelectList}
          />
          </div>
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <div className="wrap_tree borderbox">
              <div className="dtree">
                <div className="treeBody">
                  <div className="tree_area">
                    <div className="dTreeNode">
                      <FontAwesomeIcon icon={faGlobeAsia} className="cursor" />
                      <a
                        id="sd0"
                        className="node"
                        onClick={(e) => {
                          handleGroup("0", "전체", "", "-1", "전체", 1, 0);
                        }}
                      >
                        전체({totCnt})
                      </a>
                    </div>
                    {groupList.map((row) => (
                      <div className={"clip depth" + row.depth} key={row.agNm}>
                        <div className="clip">
                          <div className="dTreeNode">
                            <img src={dotted} alt="점선" />
                            <FontAwesomeIcon
                              icon={faFolder}
                              className="cursor"
                            />
                            <a
                              id={row.agNm}
                              className="node"
                              onClick={(e) => {
                                handleGroup(
                                  row.agSn,
                                  row.agNm,
                                  row.agTotCnt,
                                  row.upperAgSn,
                                  row.groupNm,
                                  row.depth,
                                  row.groupCnt
                                );
                              }}
                            >
                              {row.agNm}({row.agTotCnt})
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table_overflow">
            {/* 2023-11-22 주소록 최초표시 예외처리(jm.kil) */}
            {/* <ImportAddressList data={tableRows} snList={snList} setSnList={setSnList} /> */}
           <ImportAddressList data={tableRows} snList={snList} setSnList={setSnList} showGroupRef={true} />
            
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <div className="rright">
        <p className="list-title">선택 수신자 리스트 (1,000 건까지 노출됩니다.)</p>
        <div className="filter">
          <Button
            buttonName="button-bgc-gray"
            buttonValue="선택 삭제"
            onChangePage={handelChoiceDelete}
          />
          <p className="ml10">
            전체 <span className="red bold">{selectCnt}</span>건
          </p>
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <ReceiveAddressList
              data={rowData}
              delList={delList}
              setDelList={setDelList}
              handleDeleteNewUser={handleDeleteNewUser}
            />
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <p className="clear"></p>
      <div style={{marginLeft: "-50px"}}>
        <PaginationDynamic
                pages={addrPages}
                currentPage={addrCurrentPage}
                leftPage={addrLeftPage}
                rightPage={addrRightPage}
                endPage={addrEndPage}
                fnlPage={addrFnlPage}
                onPageChange={handleAddrPageChange}
        />
      </div>
    </div>
  );
}

export default AddressModalContent;
