import React from 'react';
import { Button } from 'component/Common';
import {
  KakaoTemplateView,
  KakaoTemplateResult,
  FileUpload,
} from 'component/Table';
import { KakaoTemplateModify } from 'component/Table2';
import { postRequest } from 'component/Axios';
import { PopUpLayout } from 'component/popUp/PopUpLayout';
import { LayerPopCommentFileUpload } from 'component/popUp/PopUp';
import withRouter from '../../../utils/WithRouterSample';
class View extends React.Component {
  state = {
    status: '0',
    inspStatus: '',
    file: null,
    fileName: '',
    reqTpCode: '',
    reqPlusId: '',
    comment: '',
    popup: 'false',
  };
  render() {
    const useData = this.props;
    const setStatus = val => {
      this.setState({ status: val });
    };
    const setInspStatus = val => {
      this.setState({ inspStatus: val });
    };
    const setReqTpCode = val => {
      this.setState({ reqTpCode: val });
    };
    const setReqPlusId = val => {
      this.setState({ reqPlusId: val });
    };
    const setcomment = e => {
      this.setState({ comment: e.target.value });
    };
    const handleSetfile = file => {
      this.setState({ file: file });
    };
    const handleSetfileName = name => {
      this.setState({ fileName: name });
    };
    const EditContents = event => {
      event.preventDefault();
    };
    const Inspection = event => {
      event.preventDefault();
    };
    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }
    const handlePopupOn = event => {
      event.preventDefault();
      this.setState({ popup: true });
    };
    const handlePopupOff = str => {
      this.setState({ popup: str });
    };
    const handleTemplateCheck = () => {
      let stat = this.state.inspStatus;
      let tpCode = this.state.reqTpCode;
      let plusId = this.state.reqPlusId;
      let file = this.state.file;
      let comment = this.state.comment;
      let tpSn = document.getElementById('tpSn').value;
      if (stat === 'REQ') {
        let str = '심사요청 중인 템플릿은 검수요청이 불가능 합니다.';
        alert(str);
        return;
      }
      const formData = new FormData();
      formData.append('plusId', plusId);
      formData.append('tpSn', tpSn);
      formData.append('tpCode', tpCode);
      formData.append('file', file);
      formData.append('comment', comment);
      const url = '/kko/requestComment';
      const data = formData;
      getPostFetch(url, data)
        .then(result => {
          alert(result.resultMsg);
          if (result.resultCode === '0') {

            useData.navigate('/kakao/template');

          }
        })
        .catch(error => alert(error));
    };
    const handleTemplateSave = check => {
      const stat = this.state.inspStatus;
      let tpStat = this.state.status;
      if (stat === 'APR') {
        alert('승인된 템플릿은 수정 및 재검수요청이 불가능 합니다.');
        return;
      }
      let str = '템플릿을 수정하시겠습니까?';
      if (check === 1) {
        if (stat === 'REQ') {
          str = '심사요청 중인 템플릿은 수정이 불가능 합니다.';
          alert(str);
          return;
        } else if (stat === 'REG') {
          tpStat = '14';
        }
      } else if (check === 2) {
        str = '템플릿을 재검수 요청 하시겠습니까?';
        tpStat = '2';
        if (stat === 'REQ') {
          str = '심사요청 중인 템플릿은 검수요청이 불가능 합니다.';
          alert(str);
          return;
        } else if (stat === 'SAV') {
          str = '현재 검수요청이 안된 템플릿 입니다.';
          return;
        }
        tpStat = '13';
      }
      const channel = document.getElementById('plusId').value;
      if (!channel) {
        alert('채널은 필수선택사항 입니다.');
        return;
      }
      const templateCode = document.getElementById('tpCode').value;
      if (!templateCode) {
        alert('템플릿 코드는 필수입력사항 입니다.');
        document.getElementById('tpCode').focus();
        return;
      }
      const templateName = document.getElementById('tpNm').value;
      if (!templateName) {
        alert('템플릿 명은 필수입력사항 입니다.');
        document.getElementById('tpNm').focus();
        return;
      }
      const cate1 = document.getElementById('cate1').value;
      if (!cate1) {
        alert('카테고리 그룹은 필수선택사항 입니다.');
        return;
      }
      const cate2 = document.getElementById('cate2').value;
      if (!cate2) {
        alert('카테고리는 필수선택사항 입니다.');
        return;
      }
      const secCheck = document.getElementsByName('securityFlag')[0].checked;
      let flag = '2';
      if (secCheck) flag = '1';
      const tpText = document.getElementById('tpText').value;
      if (!tpText) {
        alert('템플릿 내용은 필수입력사항 입니다.');
        document.getElementById('tpText').focus();
        return;
      }
      const cnt = document.getElementsByName('hiddenVal').length;
      const btnList = [];
      for (let i = 0; i < cnt; i++) {
        const num = document.getElementsByName('hiddenVal')[i].value;
        const btnTypeNm = 'btnType_' + num; // 버튼타입
        const textNm = 'btnText_' + num; // 버튼명
        const linkNm1 = 'btnLink1_' + num; // 버튼링크 1
        const linkNm2 = 'btnLink2_' + num; // 버튼링크 2
        const btnType = document.getElementById(btnTypeNm).value;
        let btnNm = '';
        let link1 = '';
        let link2 = '';
        let pluginId = '';
        if (btnType) {
          btnNm = document.getElementById(textNm).value;
          if (!btnNm) {
            alert('버튼명은 필수입력사항 입니다.');
            document.getElementById(textNm).focus();
            return;
          }
          if (btnType === 'WL') {
            link1 = document.getElementById(linkNm1).value;
            if (!link1) {
              alert('버튼타입 웹링크의 Mobile 버튼링크는 필수입력사항 입니다.');
              document.getElementById(linkNm1).focus();
              return;
            }
            link2 = document.getElementById(linkNm2).value;
          } else if (btnType === 'AL') {
            link1 = document.getElementById(linkNm1).value;
            if (!link1) {
              alert(
                '버튼타입 앱링크의 Android 버튼링크는 필수입력사항 입니다.'
              );
              document.getElementById(linkNm1).focus();
              return;
            }
            link2 = document.getElementById(linkNm2).value;
            if (!link2) {
              alert('버튼타입 앱링크의 IOS 버튼링크는 필수입력사항 입니다.');
              document.getElementById(linkNm2).focus();
              return;
            }
          } else if (btnType === 'P1' || btnType === 'P2' || btnType === 'P3') {
            pluginId = document.getElementById(linkNm1).value;
            if (!pluginId) {
              alert(
                '플러그인 버튼의 플러그인ID 버튼링크는 필수입력사항 입니다.'
              );
              document.getElementById(linkNm1).focus();
              return;
            }
          }
          btnList.push({
            btnKind: '1',
            btnType: btnType,
            btnNm: btnNm,
            btnLink1: link1,
            btnLink2: link2,
            btnOrder: i + 1,
            btnPluginId: pluginId,
          });
        }
      }
      if (window.confirm(str)) {
        const url = '/kko/templateModify';
        const data = {
          tpType: '1',
          plusId: channel,
          tpCode: templateCode,
          tpNm: templateName,
          tpMsgType: '1',
          tpSecurityFlag: flag,
          tpText: tpText,
          tpCtCode: cate1 + cate2,
          btnList: btnList,
          tpSn: document.getElementById('tpSn').value,
          tpStatus: tpStat,
        };
        getPostFetch(url, data)
          .then(result => {
            alert(result.resultMsg);
            if (result.resultCode === '0') useData.navigate('/kakao/template');
          })
          .catch(error => alert(error));
      }
    };
    return (
      <>
        <div className='container kakao-contents'>
          <div className='button-wrap'>
            <Button
              buttonName='button-border-gray'
              buttonValue='목록으로'
              onChangePage={() => {
                useData.navigate('/kakao/template');
              }}
            />
            <Button
              buttonName='button-border-gray'
              buttonValue='수정하기'
              onChangePage={e => {
                handleTemplateSave(1);
              }}
            />
            <Button
              buttonName='button-border-gray'
              buttonValue='검수요청'
              onChangePage={e => {
                handleTemplateCheck();
              }}
            />
          </div>
          <p className='page-subtitle'>
            템플릿 상세정보{' '}
            <span className='blue pl15'>
              *승인된 템플릿은 수정/삭제가 불가합니다.
            </span>
          </p>
          <KakaoTemplateModify
            setStatus={setStatus}
            setInspStatus={setInspStatus}
            setReqTpCode={setReqTpCode}
            setReqPlusId={setReqPlusId}
          />
          <p className='page-subtitle'>검수결과</p>
          <KakaoTemplateResult />
          <p className='page-subtitle'>문의등록</p>
          <textarea
            name=''
            placeholder='문의내용'
            onChange={e => setcomment(e)}
          ></textarea>
          <div class='gs-custom-input-btn-group'>
            <input
              type='text'
              readOnly='readOnly'
              value={this.state.fileName}
              placeholder=''
            />
            <Button
              buttonName='button-bgc-gray'
              buttonValue='파일등록'
              onChangePage={e => handlePopupOn(e)}
            />
          </div>
        </div>
        {this.state.popup === true && (
          <PopUpLayout onClose={handlePopupOff} poptitle='파일 업로드'>
            <LayerPopCommentFileUpload
              setFile={handleSetfile}
              setPopupClose={handlePopupOff}
              setFileName={handleSetfileName}
            />
          </PopUpLayout>
        )}
      </>
    );
  }
}
export default withRouter(View);