import React, { useState, useEffect } from "react";
import { NoticeTable } from "component/Table";
import { PaginationDynamic } from "component/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { postRequest } from "component/Axios";
import _ from 'lodash';
import Loader from "../../../component/Loader";

export default function List() {

  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState('');
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [search1, setSearch1] = useState('1');
  const [search2, setSearch2] = useState('');
  const [loading, setLoading] = useState(0);

  useEffect(() => {
      handlePageChange(1);
  }, []);

  async function getPostFetch(url,data) {
      return postRequest.fetch(url,data);
  }

  const handleSearch = (event) => {
    event.preventDefault();
    handlePageChange(1);
  };

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  }

  const handlePageChange = page => {

    let reqPage = 1;
    let reqSearch1 = '';
    let reqSearch2 = '';

    if(sessionStorage.getItem("backCheck") === 'NOTICE') {
      sessionStorage.removeItem("backCheck");
      reqPage = sessionStorage.getItem("pages");

      if(sessionStorage.getItem("saveSearch1") === "undefined" || sessionStorage.getItem("saveSearch1") === null || sessionStorage.getItem("saveSearch1") === "") {
        reqSearch1 = search1;
      } else {
        reqSearch1 = sessionStorage.getItem("saveSearch1");
        setSearch1(sessionStorage.getItem("saveSearch1"));
      }

      if(sessionStorage.getItem("saveSearch2") === "undefined" || sessionStorage.getItem("saveSearch2") === null || sessionStorage.getItem("saveSearch2") === "") {
        reqSearch2 = search2;
      } else {
        reqSearch2 = sessionStorage.getItem("saveSearch2");
        setSearch2(sessionStorage.getItem("saveSearch2"));
      }
    } else {
      reqPage = page;
      reqSearch1 = search1;
      reqSearch2 = search2;
    }
    
      const url = "/board/noticeList";
      const data = { rowCount : rowCount, currentPage: reqPage, search1 : reqSearch1, search2 : reqSearch2 }
      handleLoadingOn();
      getPostFetch(url, data)
      .then(result => {
              if( result.resultCode === "0" ) {
                  const jsonArr = JSON.parse(result.resultData);
                  const pageItem = JSON.parse(result.resultPage);
                  setLeftPage(pageItem.leftPage);
                  setRightPage(pageItem.rightPage);
                  setEndPage(pageItem.endPage);
                  setCurrentPage(pageItem.currentPage);
                  setFnlPage(pageItem.fnlPage);
                  const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                  setPages(pages);
                  setTableRows(jsonArr);
              } else {
                  alert(result.resultMsg);
              }

              handleLoadingClose();
          }
      )
      .catch(error => handleLoadingClose())
      
      sessionStorage.removeItem("backCheck");
      sessionStorage.removeItem("pages");
      sessionStorage.removeItem("saveSearch1");
      sessionStorage.removeItem("saveSearch2");
  }
  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handlePageChange(1);
    }
  }

  

  return (
    <div className="container notice">
      {loading !=  1 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="page-title">
        공지사항
        <div className="rfloat boardsearch">
          <select name="searh1" id="searh1" className="select_type2" value={search1} onChange={ e => { setSearch1(e.target.value) } } >
            <option value="1">제목</option>
            <option value="2">내용</option>
          </select>
          <input type="text" name="search2" id="search2" placeholder="검색어를 입력하세요" className="bolder_none" value={search2} onChange={ e => { setSearch2(e.target.value) } } onKeyPress={handleEnterPress} />
          <a onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </a>
        </div>
      </div>
      

      <NoticeTable data={tableRows} currentPage={currentPage} 
                  saveSearch1={search1}
                  saveSearch2={search2}
                  pages={currentPage}
                  rowCount={rowCount} />
      <PaginationDynamic  
                    pages={pages}
                    currentPage={currentPage}
                    leftPage={leftPage}
                    rightPage={rightPage}
                    endPage={endPage}
                    fnlPage={fnlPage}
                    onPageChange={handlePageChange}
                />
    </div>
  );
}
