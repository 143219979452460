import React, { useState } from 'react';
import Upload from './Upload';
import List from './List';

const KakaoTemplate = props => {
  const [activeId, setActiveId] = useState(2);
  const [activeContent, setActiveContent] = useState(<List />);
  const templateList = [
    {
      id: 1,
      name: '템플릿 등록',
      content: <Upload setActiveContent={setActiveContent} />,
    },
    { id: 2, name: '템플릿 목록', content: <List /> },
  ];
  return (
    <div className='container'>
      <ul className='tabmenu'>
        {templateList.map(value => (
          <li key={value.id} className={activeId === value.id ? 'active' : null}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                setActiveId(value.id);
                setActiveContent(value.content);
              }}
            >
              {value.name}
            </a>
          </li>
        ))}
      </ul>

      <div>{activeContent}</div>
    </div>
  );
};

export default KakaoTemplate;
