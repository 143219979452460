import React from 'react';


export function setComma(num) {
  if (num > 999 || num < 999) {
    const cn = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return cn;
  } else {
    return num;
  }
}

export function setCommaToFixed(num) {
  if (num > 999) {
    return num.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num.toFixed(2);
  }
}
export default setComma;