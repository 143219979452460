import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterWrap = styled.div`
  position: relative;
  overflow: hidden;
  height: 80px;
  padding: 20px calc((100% - 1400px) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e4e9;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: normal;
  font-family: Roboto, sans-serif;
  background-color: #ffffff;
  color: #818181;

  @media screen and (max-width: 1400px) {
    min-width: 1200px;
    padding: 20px 0;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
`;

const FootLogo = styled.div`
  width: 80px;
  margin-right: 12px;
`;

const Biz = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ServiceInfo = styled.div`
  text-align: center;
  font-size: 13px;
`;

const Policy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ServiceLink = styled(Link)`
  display: block;
  color: #000;

  &:hover {
    color: #777;
  }
`;

const Copyright = styled.div`
  text-align: left;
`;

export const style = {
  FooterWrap,
  CompanyInfo,
  FootLogo,
  Biz,
  ServiceInfo,
  Policy,
  ServiceLink,
  Copyright,
};
