import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ReceiveAddressList({
  data,
  delList,
  setDelList,
  handleDeleteNewUser,
}) {
  // 전체 checked
  const onCheckedAll = useCallback(
    (checked) => {
      if (checked) {
        const newArr = [];
        data.map((item) => newArr.push(item.abSn));
        setDelList(newArr);
      } else {
        setDelList([]);
      }
    },
    [data]
  );

  // 개별 check
  const changeHandler = useCallback(
    (checked, sn) => {
      if (checked) {
        setDelList([...delList, sn]);
      } else {
        // 체크 해제
        setDelList(delList.filter((el) => el !== sn));
      }
    },
    [delList]
  );

  return (
    <table className="list-table receive">
      <colgroup>
        <col width="50px" />
        <col width="" />
        <col width="" />
        <col width="80px" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={(e) => onCheckedAll(e.target.checked)}
              checked={
                delList.length === 0
                  ? false
                  : delList.length === data.length
                  ? true
                  : false
              }
            />
          </th>
          <th>수신번호</th>
          <th>#&#123;이름&#125;</th>
          <th>삭제</th>
        </tr>
      </thead>
      {!data || data === undefined || data === [] || data.length === 0 ? (
        <tbody>
          <tr>
            <td colSpan="4">추가된 수신자 목록이 없습니다.</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data.map((row, i) => (
            i <= 1000 
            ? <tr id={row.abSn}>
            <td>
              <input
                type="checkbox"
                id={row.abSn}
                value={row.abSn}
                onChange={(e) => {
                  changeHandler(e.currentTarget.checked, row.abSn);
                }}
                checked={
                  delList.some((item) => item === row.abSn) ? true : false
                }
              />
            </td>
            <td> {row.abPhone} </td>
            <td>{row.abNm}</td>
            <td>
              <a
                onClick={(e) => {
                  handleDeleteNewUser(row.abSn);
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="gray999 ml10"
                />
              </a>
            </td>
          </tr> 
            :
            <></>
          ))}
        </tbody>
      )}
      <tfoot></tfoot>
    </table>
  );
}

export default ReceiveAddressList;
