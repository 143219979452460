import React, { useState, useEffect } from "react";
import { Button, PaginationDynamic } from "component/Common";
import { RcsBrandListTable } from "views/rcs/brand/list/RcsBrandListTable";
import { postRequest, getRequest } from "component/Axios";
import _ from "lodash";

export const RcsBrandList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [brandNm, setBrandNm] = useState();
  const [custGrade, setCustGrade] = useState();
  const [custIdList, setCustIdList] = useState([]);
  const [custId, setCustId] = useState();

  const style = {
    brandName: {
      color: "grey",
    },
  };

  useEffect(() => {
    handlePageChange();
  }, []);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handlePageChange = (page) => {
    const data = {
      rowCount: rowCount,
      currentPage: page,
      search4: brandNm,
      search5: custId,
    };
    getPostFetch("/rcs/brandList", data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);

          getFetch("/user/myInfo")
            .then((result) => {
              if (result.resultCode === "0") {
                const jsonArr = JSON.parse(result.resultData);
                setCustGrade(jsonArr.custType);
                getFetch("/user/subInfo")
                  .then((result) => {
                    if (result.resultCode === "0") {
                      const jsonArr = JSON.parse(result.resultData);
                      setCustIdList(jsonArr);
                    }
                  })
                  .catch((error) => console.log(error));
              }
            })
            .catch((error) => console.log(error));
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (num) => {
    setBrandNm(num);
  };

  const handleCustId = (e) => {
    setCustId(e.target.value);
  };

  const RCSBizCenter = (event) => {
    event.preventDefault();
    window.open("https://www.rcsbizcenter.com/login", "_blank");
  };
  return (
    <div className="container">
      <div className="Info-gray">
        <p className="title">RCS 브랜드 관리</p>

        <div className="Info mb20">
          RCS Biz Center에서 브랜드 생성 후 등록된 브랜드 ID 등록
          <p className="gray999 mt10">
            ※ 브랜드 생성 후 “한진정보통신” 대행사 권한 추가 필수 (RCS Biz
            Center의 브랜드정보를 반영하는데 최대 30분정도 소요될 수 있습니다.)
          </p>
          <Button
            buttonName="button-border-dark rounded"
            buttonValue="RCS Biz Center"
            onChangePage={RCSBizCenter}
          />
        </div>
      </div>

      <div className="rcs-contents">
        <div className="rcsTemlist">
          <div className="searchbox">
            <ul className="search-col3-group">
              <li>
                <input
                  type="text"
                  placeholder="브랜드명을 입력해주세요."
                  onChange={(e) => handleChange(e.target.value)}
                  value={brandNm}
                  style={style.brandName}
                ></input>
              </li>
              {custGrade === "1" ? (
                <li>
                  <select
                    onChange={handleCustId}
                    name="userID"
                    id="userID"
                    value={custId}
                  >
                    <option value="">계정 전체</option>
                    {custIdList.map((row) => (
                      <option value={row.custId}>{row.custId}</option>
                    ))}
                  </select>
                </li>
              ) : null}
              <li>
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="검색"
                  onChangePage={(e) => {
                    handlePageChange(1);
                  }}
                />
              </li>
            </ul>
          </div>

          <RcsBrandListTable
            data={tableRows}
            currentPage={currentPage}
            rowCount={rowCount}
            custGrade={custGrade}
          />
          <PaginationDynamic
            pages={pages}
            currentPage={currentPage}
            leftPage={leftPage}
            rightPage={rightPage}
            endPage={endPage}
            fnlPage={fnlPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
