import React from "react";
import { useRoutes } from "react-router-dom";
import { useRecoilState } from "recoil";
import routes from "./routes";
import { ROOT_URL } from "./utils/constant";
import { rootState } from "./utils/state";
import RouteChangeTracker from "./component/RouteChangeTracker";
//import Cookies from "universal-cookie";

const App = () => {

    RouteChangeTracker();
    
    const [url, setUrl] = useRecoilState(rootState);
    const routing = useRoutes(routes);

    const path = window.location.href
        .replace(window.location.origin + "/" + ROOT_URL + "/", "")
        .substr();
    const treePath = path.split("/");
    //const cookies = new Cookies();

    switch (treePath[0]) {
        // case "kakao":
        //     return (
        //         <>
        //             {setUrl("kakao")}
        //             {routing}
        //         </>
        //     );
        case "account":
            return (
                <>
                    {setUrl("account")}
                    {routing}
                </>
            );
        case "main":
            return (
                <>
                    {setUrl("main")}
                    {routing}
                </>
            );
        case "mypage":
            return (
                <>
                    {setUrl("mypage")}
                    {routing}
                </>
            );
        case "customer":
            return (
                <>
                    {setUrl("customer")}
                    {routing}
                </>
            );
        case "message":
            return (
                 <>
                    {setUrl("message")}
                    {routing}
                </>
            )
        default:
            return (
                <>
                    {setUrl("")}
                    {routing}
                </>
            );
    }
};

export default App;
