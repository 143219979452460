import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { faTimesCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// 바이트 구하기
function getByte(str) {
  return str
    .split("")
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0); // 계산식에 관한 설명은 위 블로그에 있습니다.
}

function byteCheck() {
  let str = "";
  let replaceCheck = "N";
  let totalByte = 0;

  if (document.getElementById("adTitle")) {
    str += document.getElementById("adTitle").value;
    replaceCheck = "Y";
  }
  if (document.getElementById("msgContents")) {
    str += document.getElementById("msgContents").value;
  }
  if (document.getElementById("adNumber")) {
    str += document.getElementById("adNumber").value;
    replaceCheck = "Y";
  }

  totalByte = getByte(str);
  if (replaceCheck === "Y") {
    totalByte += 21;
  }

  return totalByte;
}

export const OptionLinkURL = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;

  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  function handleBtnInfo1(e) {
    e.preventDefault();
    if (document.getElementById("btnInfo1" + cardId).value.length > 100) {
      alert("URL은 최대 100자까지 입력 가능합니다.");
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();

    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");

      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = URL연결 */}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              URL연결
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="30"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              maxlength="300"
              onChange={handleBtnInfo1}
              placeholder="예) URL 버튼 : https://www.bizrcs.com 형식으로 입력해 주세요.(최대 영문 100자, 한글 50자)"
            />
            <input
              type="hidden"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
          </div>
          <p className="clear"></p>
        </div>
      </div>
    </>
  );
};

export const OptionCall = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  const [inputValue, setInputValue] = useState("");

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }
  const handlePress = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 전화걸기 */}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              전화걸기
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              maxlength="23"
              placeholder="전화번호를 입력해주세요.(최대 20자)"
              onChange={handlePress}
              value={inputValue}
            />
            <input
              type="hidden"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
          </div>
          <p className="clear"></p>
        </div>
      </div>
    </>
  );
};
export const OptionCopy = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();

    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 복사하기 */}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              복사하기
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              maxlength="100"
              placeholder="내용을 입력해주세요.(최대 100자)"
            />
            <input
              type="hidden"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
          </div>
          <p className="clear"></p>
        </div>
      </div>
    </>
  );
};
export const OptionCoordinate = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();

    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 지도보여주기(좌표) */}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              지도보여주기(좌표)
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              maxlength="20"
              defaultValue=""
              placeholder="위도 (최대 20자)"
            />
            <input
              type="text"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
              maxlength="20"
              defaultValue=""
              placeholder="경도 (최대 20자)"
            />
            <input
              type="text"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
              maxlength="20"
              defaultValue=""
              placeholder="위치이름 (최대 20자)"
            />
            <input
              type="text"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
              defaultValue=""
              placeholder="https://www.google.com/maps/@37.5538314,126.9722358,17z 형식으로 입력해주세요(최대 영문 150자, 한글 50자)"
            />
          </div>
          <p className="clear"></p>
        </div>
      </div>
    </>
  );
};
export const OptionQueries = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();

    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 지도보여주기(쿼리) */}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              지도보여주기(쿼리)
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              defaultValue=""
              maxlength="20"
              placeholder="위치이름 (최대 20자)"
            />
            <input
              type="text"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
              defaultValue=""
              placeholder="https://www.google.com/maps/@37.5538314,126.9722358,17z 형식으로 입력해주세요(최대 영문 150자, 한글 50자)"
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
            <p className="clear"></p>
          </div>
        </div>
      </div>
    </>
  );
};
export const OptionPlace = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 현재위치공유 */}

      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              현재위치공유
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="hidden"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export const OptionCalendar = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;

  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();

    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  /* datetype 스타일 */
  const useStyles = makeStyles((theme) => ({
    textField: {
      width: "100%",
      marginTop: "10px",

      "& input": {
        width: "100%",
        backgroundColor: "#fdfdfd",
      },
    },
  }));

  const classes = useStyles();

  function ChangeCalendarDay(e) {
    e.preventDefault();

    // 날짜형식 변환
    let str = e.target.value + ":00"; // 2022-04-06T16:37:00
    let replaced_str = str.replace(/T/g, " "); // 2022-04-06 16:37:00

    if (document.getElementById(e.target.id + cardId)) {
      document.getElementById(e.target.id + cardId).value = str;
    }

    if (
      document.getElementById("btnInfo1" + cardId).value ===
      document.getElementById("btnInfo2" + cardId).value
    ) {
      alert("시작일과 종료일을 다르게 설정해주세요.");
    }
  }

  return (
    <>
      {/* select option = 캘린더등록 start*/}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              캘린더등록
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            {/* <TextField
                    //id="datetime-local"
                    id={"btnInfo1"+cardId}
                    name={"btnInfo1"+cardId} 
                    type="datetime-local"
                    label="시작일"
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={ChangeCalendarDay}
                />
                <TextField
                    //id="datetime-local"
                    id={"btnInfo2"+cardId}
                    name={"btnInfo2"+cardId} 
                    type="datetime-local"
                    label="종료일"
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={ChangeCalendarDay}
                /> */}
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              defaultValue=""
              maxlength="30"
              placeholder="시작일(YYYY-MM-DD hh:mm:ss)"
            />
            <input
              type="text"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
              defaultValue=""
              maxlength="30"
              placeholder="종료일(YYYY-MM-DD hh:mm:ss)"
            />
            <input
              type="text"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
              defaultValue=""
              maxlength="20"
              placeholder="제목을 입력해주세요 (최대 20자)"
              className="mt10"
            />
            <input
              type="text"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
              defaultValue=""
              maxlength="100"
              placeholder="내용을 입력해주세요. (최대 100자)"
            />
            <p className="clear"></p>
          </div>
        </div>
      </div>
    </>
  );
};
export const OptionSendMsg = (props) => {
  const setMsgType = props.setMsgType;
  const msgType = props.msgType;
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setLimitByte = props.setLimitByte;
  const infoData = props.infoData;
  const setNowBalance = props.setNowBalance;
  const [inputValue, setInputValue] = useState("");
  let previewBtnNm = props.previewBtnNm;
  let id = props.id;
  let cardId = "";

  if (props.cardId != undefined) {
    cardId = props.cardId;
  }
  function handleBtnName(e) {
    if (document.getElementById(previewBtnNm)) {
      document.getElementById(previewBtnNm).value = e.target.value;
    }
  }

  const handlePress = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  const handleBtnOptionDelete = (event) => {
    event.preventDefault();
    
    let subject = "";

    document.getElementById(previewBtnNm).remove();

    if (document.getElementById("subject")) {
      subject = document.getElementById("subject").value;
    }

    document.getElementById(id).remove();

    const btnCnt = document.getElementsByName("btnGrp").length;

    if (msgType === "MMS") {
      return;
    }
    if (byteCheck() <= 90 && btnCnt == 1 && subject === "") {
      setMsgType("SMS");
      setChekedRcsTypeValue("RCS SMS");
      setLimitByte("90");
      if (infoData.rcsSmsPrce === undefined) {
        setNowBalance(0);
      } else {
        setNowBalance(infoData.rcsSmsPrce);
      }
    }
  };

  return (
    <>
      {/* select option = 메시지전송 start*/}
      <div className="check-list-item" id={id} name="btnGrp">
        <div className="input-inner textarea-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span id={"btnType" + cardId} name={"btnType" + cardId}>
              메시지전송
            </span>
            <a href="/" onClick={handleBtnOptionDelete}>
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </div>
          <div className="input-con">
            <input
              type="text"
              name={"btnNm" + cardId}
              id={"btnNm" + cardId}
              maxlength="14"
              placeholder="버튼명"
              onChange={handleBtnName}
            />
            <input
              type="text"
              name={"btnInfo1" + cardId}
              id={"btnInfo1" + cardId}
              defaultValue=""
              maxlength="100"
              placeholder="전화번호를 입력해주세요 (최대 100자)"
              onChange={handlePress}
              value={inputValue}
            />
            <input
              type="text"
              name={"btnInfo2" + cardId}
              id={"btnInfo2" + cardId}
              defaultValue=""
              maxlength="100"
              placeholder="메시지 내용을 입력해주세요. (최대 100자)"
            />
            <input
              type="hidden"
              name={"btnInfo3" + cardId}
              id={"btnInfo3" + cardId}
            />
            <input
              type="hidden"
              name={"btnInfo4" + cardId}
              id={"btnInfo4" + cardId}
            />
            <p className="clear"></p>
          </div>
        </div>
      </div>
    </>
  );
};
