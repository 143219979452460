import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { loginState } from "utils/state";
import { Button, PaginationDynamic } from "component/Common";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSearch,
  faFolder,
  faGlobeAsia,
  faTimesCircle,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import dotted from "assets/images/base.png";
import profileImg from "assets/images/banner1.png"; //이미지 노출 확인용
import PreViewBGI from "assets/images/preview.png"; //RCS 미리보기 이미지
import Advertising_guide from "assets/images/Advertising_guide.png"; //광고문자 전송가이드
import {
  KakaoProfileUpload,
  AddManager,
  MasterAccountList, //대표계정 연동
  AccountUserList, //로그인 시 사용자 인증ㄴ
  JoinAccountList, //회원가입 시 동일한 사업자등록번호
  ConnectMaster, //회원가입 시 대표계정 연동(하위계정이 가입시 기준)
  DivideCharge, //요금조회 및 납부 - 충전금 배분하기
  CollectCharge, //요금조회 및 납부 - 충전금 회수하기
  PaymentTotal, //요금조회 및 납부 - 결제하기,
  ChargeCalculator, //요금조회 및 납부 - 충전금액 계산기
  SubAccountPrice, //내정보 - 계정목록 - 관리
  ContractTable, //요금조회 - 계약단가
  RequestForPayLaterTable, //계약정보 - 후불전환요청
  NewAccountService, // 내정보 - 계정목록(후불) - 신규계정 요청
  AddSendNumber, //일반관리 - 발신번호 관리 - 발신번호등록
  LoadReceiveNumberErrorList, //문자보내기 - 수신번호 - 오류검사
  AddGroup, //주소록 - 그룹추가(등록)
  EditGroup, //주소록 - 그룹수정
  MoveGroup, //주소록 - 그룹 주소록 이동
  CopyGroup, //주소록 - 그룹 주소록 복사
  EditAddressInfo, //주소록 - 주소록 상세정보 수정(개인)
  AddressList, //주소록 - 리스트
  DuplicateSearch, //주소록 - 중복번호, 이름 검색
  ImportAddressList, // 문자보내기 - 주소록 불러오기(테이블)
  ReceiveAddressList, // 문자보내기 - 주소록 불러오기 - (선택된 수신자 목록)
  LoadFriendsTalkImg,
  LoadRcsImg,
  TryAuthSendNumber,
  RequestAccountTable,
} from "component/Table";
import LoadReceiveNumberList from "../../modal/sendMessage/receiveList/LoadReceiveNumberList";
import DaumPostcode from "react-daum-postcode";
import { postRequest, getRequest } from "component/Axios";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import axios from "axios";
import { SEVER_DOMAIN } from "utils/constant.js";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import setComma, { setCommaToFixed } from "../common/SetComma";
import Loader from "component/Loader";
import { json } from "body-parser";

//카카오 템플릿 - 친구톡이미지 - 이미지 등록
export function LayerPopImgUpload(props) {
  const [tableRows, setTableRows] = useState("");
  const [type, setType] = useState("");
  const [channelId, setChannelId] = useState("");
  const [senderKey, setSenderKey] = useState("");
  const handleReSearch = props.handleReSearch;
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(<option value="">채널없음</option>);
    } else {
      setSenderKey(jsonArr[0].senderKey);
      setChannelId(jsonArr[0].plusId);
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(<option value={row.senderKey}>{row.plusId}</option>);
      }
    }
    setTableRows(rows);
    setType(props.type);
  }, [props.data]);
  const handleChannel = (e) => {
    setChannelId(e.target[e.target.selectedIndex].text);
    setSenderKey(e.target.value);
  };
  const [img, setImg] = useState(null);
  const handleChange = (e) => {
    setImg(e.target.files[0]);
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleUpload = () => {
    const formData = new FormData();
    if (!img) {
      alert("선택된 이미지가 없습니다.");
      return;
    }
    let id = channelId;
    if (!id) id = document.getElementById("channelId").text;
    let key = senderKey;
    if (!key) key = document.getElementById("channelId").value;

    if (channelId === "") {
      alert("선택된 채널이 없습니다.");
      return;
    }
    formData.append("file", img);
    formData.append("type", type);
    formData.append("title", "친구톡 이미지");
    formData.append("plusId", channelId);
    formData.append("senderKey", senderKey);
    const url = "/kko/kakaoImageUpload";
    const data = formData;
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          handleReSearch();
        }
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>카카오톡 채널</th>
            <td>
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="channelId"
                  name="channelId"
                  data-fakeform-index="0"
                  className="wid100"
                  onChange={handleChannel}
                >
                  {tableRows}
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>이미지</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="등록하기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}

//카카오 템플릿 - 아이템 이미지등록 - 이미지 등록
export function LayerPopImgUpload2(props) {
  const [tableRows, setTableRows] = useState("");
  const [type, setType] = useState("");
  const [channelId, setChannelId] = useState("");
  const [senderKey, setSenderKey] = useState("");
  const handleReSearch = props.handleReSearch;
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(<option value="">채널없음</option>);
    } else {
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(<option value={row.senderKey}>{row.plusId}</option>);
      }
    }
    setTableRows(rows);
    setType(props.type);
  }, [props.data]);

  const handleChannel = (e) => {
    setChannelId(e.target.value);
  };

  const [img, setImg] = useState(null);
  const handleChange = (e) => {
    setImg(e.target.files[0]);
  };

  const [imgTitle, setImgTitle] = useState("");
  const handleTitleChange = (e) => {
    setImgTitle(e.target.value);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleUpload = () => {
    const formData = new FormData();

    if (!img) {
      alert("선택된 이미지가 없습니다.");
      return;
    }

    if (!imgTitle) {
      alert("이미지명을 입력해 주세요.");
      return;
    }

    let id = channelId;

    if (!id) id = document.getElementById("channelId").value;

    let key = senderKey;

    if (!key) key = document.getElementById("channelId").value;

    if (key === "") {
      alert("선택된 채널이 없습니다.");
      return;
    }

    formData.append("file", img);
    formData.append("type", type);
    formData.append("title", imgTitle);
    formData.append("plusId", id);
    formData.append("senderKey", key);

    const url = "/kko/kakaoImageUpload";
    const data = formData;

    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          handleReSearch();
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>카카오톡 채널</th>
            <td>
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="channelId"
                  name="channelId"
                  data-fakeform-index="0"
                  className="wid100"
                  onChange={handleChannel}
                >
                  {tableRows}
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>이미지명</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="text"
                  name="imgTitle"
                  defaultValue=""
                  placeholder="30자 이내"
                  maxlength="30"
                  onChange={handleTitleChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>이미지</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="등록하기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}

//카카오 템플릿 - 친구톡이미지 - 확대보기
export function LayerPopImgZoom(props) {
  const style = {
    content: {
      width: "100%",
      padding: "15px",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
    },
  };
  const copy = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="mb20">
        <div className="gs-custom-input-btn-group bgc-none">
          <input type="text" name="" defaultValue="이미지 KEY" disabled />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="복사하기"
            onChangePage={copy}
          />
        </div>
        <div style={style.content}>202106/38627b87e331</div>
        <div className="gs-custom-input-btn-group bgc-none mt10">
          <input type="text" name="" defaultValue="이미지 URL" disabled />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="복사하기"
            onChangePage={copy}
          />
        </div>
        <div style={style.content}>
          https://mud-kage.kakao.com/dn{props.imgUrl}
        </div>
      </div>
      <div>
        <img src={props.imgUrl} alt="" />
      </div>
    </>
  );
}

//카카오 템플릿 - 친구톡이미지 - 확대보기
export function LayerPopImgSub(props) {
  const style = {
    content: {
      width: "100%",
      padding: "15px",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
    },
  };

  const inputUrl = useRef();

  return (
    <>
      <div className="mb20"> </div>
      <div>
        <img src={props.imgUrl} alt="" />
      </div>
    </>
  );
}

//카카오 템플릿 - 홈 - 카카오 채널ID등록
export function LayerPopAddChannel() {
  const navigate = useNavigate();
  const ConnectKakao = (event) => {
    event.preventDefault();
    //일치 확인되면
    navigate("/kakao/profile");
  };
  return (
    <>
      <a
        href="https://center-pf.kakao.com"
        target="_blank"
        className="underline blue"
      >
        카카오 채널 관리자센터 바로가기
      </a>
      <KakaoProfileUpload />
      <div className="gray999">
        <FontAwesomeIcon icon={faInfoCircle} className="mr5" />
        카카오 채널 관리자에 등록된 휴대폰 번호만 인증가능
      </div>
      <p className="center pt30">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="카카오 채널 추가"
          onChangePage={ConnectKakao}
        />
      </p>
    </>
  );
}

//카카오 템플릿 - 내정보 - 대표계정 연동
export function LayerPopMasterAccount(props) {
  const handleAccountInfo = props.handleAccountInfo;
  const popupClose = props.popupClose;
  const [bizno, setBizno] = useState("");
  const style = {
    searchbox: {
      margin: "10px 0",
    },
    textbox: {
      width: "calc(100% - 140px)",
    },
  };

  const [tableRows, setTableRows] = useState("");
  const [snList, setSnList] = useState([]);
  const [tot, setTot] = useState(0);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleSearch = (event) => {
    event.preventDefault();
    if (!bizno) {
      alert("사업자등록번호를 입력해 주세요.");
      return;
    }

    const url = "/user/getBizno";
    const data = { search1: bizno };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          setTot(jsonArr.length);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };
  const handleCancle = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (snList.length === 0) {
      alert("연동을 신청할 계정을 선택해 주세요.");
      return;
    } else if (snList.length > 1) {
      alert("연동 신청은 하나의 계정만 가능합니다.");
      return;
    }

    const custId = "cust_" + snList[0];

    const id = document.getElementById(custId).value;

    if (
      window.confirm(
        "[ ID : " + id + " ] 를 대표계정 으로 연동신청 하시겠습니까?"
      )
    ) {
      const url = "/user/highReq";
      const data = { custSn: snList[0] };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            //handlePageChange(1)
            handleAccountInfo();
            popupClose();
          }
        })
        .catch((error) => alert(error));
    }
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setBizno(e.target.value);
    }
  };

  return (
    <>
      <div className="check-list-item">
        <div className="input-inner clearfix" id="cropNumArea">
          <div>
            <fieldset className="address-fieldset">
              <div className="address-wrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  maxlength="20"
                  title="사업자등록번호"
                  className="gs-custom-input fc-event"
                  placeholder="사업자등록번호 '-'없이 숫자만 입력해주세요."
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={bizno}
                />
                <button
                  type="button"
                  className="cm-highlight-btn he30 fc-event"
                  id="corpNumCheckBtn"
                  onClick={handleSearch}
                >
                  검색
                </button>
              </div>
            </fieldset>
          </div>
          <p className="clear"></p>
        </div>
      </div>
      <div className="pop-stitle mt30">검색결과 ({setComma(tot)}건)</div>
      <MasterAccountList data={tableRows} setSnList={setSnList} />
      <div className="button-wrap center">
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="취소"
          onChangePage={handleCancle}
        />
        <Button
          buttonName="button-bgc-blue sbtn"
          buttonValue="확인"
          onChangePage={handleSubmit}
        />
      </div>
    </>
  );
}

//카카오 템플릿 - 내정보 - 담당자 추가
export function LayerPopAddManager(props) {
  const [userNm, setUserNm] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState("");

  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;
  const dataType = props.dataType;

  const style = {
    button: {
      width: "100%",
    },
  };
  const handleAccountInfo = props.handleAccountInfo;
  const popupClose = props.popupClose;
  const Input = () => {
    return <AddManager />;
  };
  const [inputList, setInputList] = useState([]);

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      onAddUser(e);
    }
  }

  const onAddBtnClick = (event) => {
    event.preventDefault();
    setInputList(inputList.concat(<AddManager key={inputList.length} handleEnterPress={handleEnterPress}/>));
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }


  const onAddUser = (event) => {
    event.preventDefault();
    if (!userNm) {
      alert("사용자 이름을 입력하세요.");
      return;
    }

    const regexEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!email) {
      alert("이메일은 필수 입력사항입니다.");
      return;
    } else if (!regexEmail.test(email)) {
      alert("잘못된 형식의 이메일입니다.");
      return;
    }

    if(!mobile) {
      alert("휴대폰 번호는 필수 입력사항입니다.")
      return;
    }

    if (window.confirm("사용자 정보를 등록하시겠습니까?")) {
      const url = "/user/userSave";
      const data = { userSn: 0, userNm: userNm, mobile: mobile, email: email, userType: dataType == "normal" ? "2" : "3"};
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            // 팝업 close
            popupClose();
            // 재조회
            handleAccountInfo();

            handleLoadingClose();
          }
          
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <>
        {
          dataType == "normal"
          ?
            <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
              등록된 사용자는 로그인 후 최초 1회 본인인증을 해야합니다.
            </p>
          :
            <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
              정산담당자의 이메일 주소로 청구내역서를 보내드립니다.
            </p>
        }
        
        <AddManager
          setChangeMobile={setMobile}
          setChangeEmail={setEmail}
          setChangeNm={setUserNm}
          setCheck={setCheck}
          type={1}
          handleEnterPress={handleEnterPress}
        />
        {inputList}
      </>

      {/*<Button buttonName="button-square-blue" buttonValue="+ 담당자 추가 등록" style={style.button} onChangePage={onAddBtnClick}/>*/}

      <div className="button-wrap center">
        <Button
          buttonName="button-bgc-blue bbtn"
          buttonValue="등록"
          onChangePage={onAddUser}
        />
      </div>
    </>
  );
}

//내정보 - 사용자정보 수정
export function LayerPopEditManager(props) {
  const popupClose = props.popupClose;
  const handleAccountInfo = props.handleAccountInfo;
  const dataType = props.dataType;
  const sn = props.sn;
  const [auth, setAuth] = useState(false);

  const [newMobile, setNewMobilee] = useState(props.mobile);
  const [newEmail, setNewEmail] = useState(props.email);
  const [newNm, setNewNm] = useState(props.userNm);

  const setCheck = (e) => {
    setAuth(e);
  };

  const setChangeMobile = (e) => {
    setNewMobilee(e);
  };

  const setChangeEmail = (e) => {
    setNewEmail(e);
  };

  const setChangeNm = (e) => {
    setNewNm(e);
  };

  const style = {
    button: {
      width: "100%",
    },
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const onEditUser = (event) => {
    event.preventDefault();

    const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const regexPhone =  /^(01[016789]{1})?[0-9]{4}?[0-9]{4}$/;

    if (!newNm) {
      alert("사용자 이름을 입력하세요.");
      return;
    }

    if (!newMobile) {
      alert("휴대폰번호를 입력하세요.");
      return;
    } else if (!regexPhone.test(newMobile)) {
      alert("잘못된 형식의 휴대폰번호 입니다.");
      return;
    }

    if (!newEmail) {
      alert("이메일은 필수입력사항 입니다.");
      return;
    } else if (!regexEmail.test(newEmail)) {
      alert("잘못된 형식의 이메일 입니다.");
      return;
    }

    if (window.confirm("사용자 정보를 수정 하시겠습니까?")) {
      const url = "/user/userModify";
      const data = {
        userSn: sn,
        userNm: newNm,
        mobile: newMobile,
        email: newEmail,
      };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            // 팝업 close
            popupClose();
            // 재조회
            handleAccountInfo();
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>   
    {
      dataType == "normal" 
      ?
        <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
          휴대폰번호 변경 시 아래 사용자는 미인증 상태로 변경되며<br></br>로그인 후 최초 1회 본인인증을 해야합니다.
        </p>
      :
        <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
          정산담당자의 이메일 주소로 청구내역서를 보내드립니다. 
        </p>
    }
      
      <AddManager
        mobile={props.mobile}
        email={props.email}
        userNm={props.userNm}
        setChangeMobile={setChangeMobile}
        setChangeEmail={setChangeEmail}
        setChangeNm={setChangeNm}
        setCheck={setCheck}
      />
      <div className="button-wrap center">
        <Button
          buttonName="button-bgc-blue bbtn"
          buttonValue="수정"
          onChangePage={onEditUser}
        />
      </div>
    </>
  );
}

//카카오 템플릿 - 내정보 - 신규계정 요청(후불)
export function LayerPopAddNewAccount(props) {
  const handlePopClose = props.onClose;
  const [requestInfo, setRequestInfo] = useState([
    {
      web: "",
      api: "",
      agent: "",
      arCnt: "",
      arRemark: "",
    },
    {
      web: "",
      api: "",
      agent: "",
      arCnt: "",
      arRemark: "",
    },
    {
      web: "",
      api: "",
      agent: "",
      arCnt: "",
      arRemark: "",
    },
  ]);

  const handelRequestInfoChange = (e, index) => {
    const defRequestInfo = [...requestInfo];
    defRequestInfo[index][e.target.name] = e.target.value;
    setRequestInfo(defRequestInfo);
  };
  const handleRequestInit = (index) => {
    let init = {
      web: "",
      api: "",
      agent: "",
      arCnt: "",
      arRemark: "",
    };
    const defRequestInfo = [...requestInfo];
    defRequestInfo[index] = init;
    setRequestInfo(defRequestInfo);
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const style = {
    searchbox: {
      margin: "20px 0",
    },
    textbox: {
      position: "relative",
      top: "3px",
    },
  };
  const handleNewAccount = (event) => {
    const regexNumber = /^\d/;
    let numberChek = false;
    let cntCheck = false;
    let remarkCheck = false;
    requestInfo.forEach((item, idex) => {
      if (item.arCnt !== "" && !regexNumber.test(item.arCnt)) {
        numberChek = true;
        return;
      }
      // if (idex === 0 && item.web === 'on' && item.arRemark === '') {
      //   remarkCheck = true;
      //   return;
      // } else if (idex === 1 && item.api === 'on' && item.arRemark === '') {
      //   remarkCheck = true;
      //   return;
      // } else if (idex === 2 && item.agent === 'on' && item.arRemark === '') {
      //   remarkCheck = true;
      //   return;
      // }
    });

    const handleLoadingOn = props.handleLoadingOn;
    const handleLoadingClose = props.handleLoadingClose;

    if (requestInfo[0].web === "on") {
      if (requestInfo[0].arCnt === "0" || requestInfo[0].arCnt === "") {
        cntCheck = true;
      }
    }
    if (requestInfo[1].api === "on") {
      if (requestInfo[1].arCnt === "0" || requestInfo[1].arCnt === "") {
        cntCheck = true;
      }
    }
    if (requestInfo[2].agent === "on") {
      if (requestInfo[2].arCnt === "0" || requestInfo[2].arCnt === "") {
        cntCheck = true;
      }
    }
    if (
      requestInfo[0].web === "" &&
      requestInfo[1].api === "" &&
      requestInfo[2].agent === ""
    ) {
      alert("최소 1개의 서비스를 선택해주세요");
      return;
    }
    if (numberChek) {
      alert("생성 요청 수량은 숫자만 입력이 가능합니다.");
      return;
    }
    if (cntCheck) {
      alert("생성 요청 수량은 1개 이상이어야 합니다.");
      return;
    }
    if (remarkCheck) {
      alert("비고를 입력해주세요.");
      return;
    }
    if (window.confirm("신규계정을 요청 하시겠습니까?")) {
      const url = "/user/requestNewAccount";
      const data = requestInfo;
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          handlePopClose(false);
          handleLoadingClose();
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <NewAccountService
        handelRequestInfoChange={handelRequestInfoChange}
        handleRequestInit={handleRequestInit}
      />
      <div className="button-wrap center">
        <Button
          buttonName="button-bgc-blue bbtn"
          buttonValue="신청하기"
          onChangePage={handleNewAccount}
        />
      </div>
    </>
  );
}

//로그인 - 사용자 인증
export function LayerPopLogin(props) {
  const style = {
    maintitle: {
      fontSize: "23px",
      color: "#333333",
      fontWeight: "600"
    },
    subtitle: {
      color: "#DC7979",
      lineHeight: 1.5,
      fontWeight: "500",
      fontSize: "13px",
      marginBottom: "-10px"
    },
    countWrap: {
      marginLeft: "28.6%",
      position: "relative",
    },
    countNumber: {
      width: "220px",
    },
    red: {
      color: "#ff5757",
      position: "absolute",
      right: "15px",
      top: "0%",
    },
    rightBtn: {
      position: "relative",
      marginTop: "-6px",
      padding: "0 25px",
      backgroundColor: "#625af6",
    },
  };
  
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const [tableRows] = useState(props.data);

  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  const time = useRef(180);
  const timerId = useRef(null);
  const [timeCheck, setTimeCheck] = useState("");
  const [sn, setSn] = useState("");
  const [num, setNum] = useState("");

  const [authPhoneBtnDisabled, setAuthPhoneBtnDisabled] = useState(false);
  const [authEmailBtnDisabled, setAuthEmailBtnDisabled] = useState(false);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleAuth = (type) => {
    let checkSn;

    clearInterval(timerId.current);
    setTimeCheck(false);
    time.current = 180;
    
    const snNodeList = document.getElementsByName("userCertification");
    snNodeList.forEach((node) => {
      if (node.checked) {
        setSn(node.value);
        checkSn = node.value;
      }
    });

    if (!checkSn) {
      alert("인증 할 사용자를 선택해 주세요.");
      return;
    }

    const url = "/auth/sendCode";
    const data = { sn: checkSn, type: type.target.value };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
      
      setTimeCheck(true);
        setMin(3);
        setSec(0);
        timerId.current = setInterval(() => {
          time.current -= 1;
          setMin(parseInt(time.current / 60));
          setSec(time.current % 60);
        }, 1000);
      alert("인증번호가 발송되었습니다.");

      if(type.target.value == 1) {
        setAuthPhoneBtnDisabled(true);
      } else if(type.target.value == 2) {
        setAuthEmailBtnDisabled(true);
      }
      
  };

  const handleSms = (event) => {
    event.preventDefault();

    let checkSn;

    clearInterval(timerId.current);
    setTimeCheck(false);
    time.current = 180;

    const snNodeList = document.getElementsByName("userCertification");
    snNodeList.forEach((node) => {
      if (node.checked) {
        setSn(node.value);
        checkSn = node.value;
      }
    });

    if (!checkSn) {
      alert("인증 할 사용자를 선택해 주세요.");
      return;
    }

    const url = "/auth/sendCode";
    const data = { sn: checkSn, type: "1" };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          setTimeCheck(true);
          setMin(3);
          setSec(0);
          timerId.current = setInterval(() => {
            time.current -= 1;
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
          }, 1000);
          
        } else {
          alert(result.resultMsg);
        }
        alert("인증번호가 발송되었습니다.");
      })
      .catch((error) => alert(error));
  };

  const handleEmail = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const login = (event) => {
    event.preventDefault();
    if (!timeCheck) {
      alert("인증번호를 요청하세요.");
      return;
    }

    const url = "/auth/login";
    const data = { sn: sn, num: num };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const ret = JSON.parse(result.resultData);
          //setCookie('tk', "", -1);
          //setCookie("tk", ret.jwt)
          sessionStorage.clear();
          sessionStorage.setItem("tk", ret.jwt);
          sessionStorage.setItem("nm", ret.nm);
          sessionStorage.setItem("rating", ret.rating);
          sessionStorage.setItem("pay", ret.pay);
          sessionStorage.setItem("web", ret.web);
          sessionStorage.setItem("api", ret.api);
          sessionStorage.setItem("agent", ret.agent);
          sessionStorage.setItem("sms", ret.sms);
          sessionStorage.setItem("lms", ret.lms);
          sessionStorage.setItem("mms", ret.mms);
          sessionStorage.setItem("alim", ret.alim);
          sessionStorage.setItem("frd", ret.frd);
          sessionStorage.setItem("frdImg", ret.frdImg);
          sessionStorage.setItem("frdWideImg", ret.frdWideImg);
          sessionStorage.setItem("rcsTmpl", ret.rcsTmpl);
          sessionStorage.setItem("rcsSms", ret.rcsSms);
          sessionStorage.setItem("rcsLms", ret.rcsLms);
          sessionStorage.setItem("rcsMms", ret.rcsMms);
          sessionStorage.setItem("mobile", ret.mobile);
          sessionStorage.setItem("custType", ret.custType);
          sessionStorage.setItem("custGrade", ret.custGrade);
          sessionStorage.setItem("id", ret.id);
          setIsLogin(true);

          navigate("/");
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    // 만약 타임 아웃이 발생했을 경우
    if (time.current <= 0) {
      alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
      clearInterval(timerId.current);
      setTimeCheck(false);
      time.current = 180;
    }
  }, [sec]);

  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      login(e);
    }
  }


  return (
    <div className="login_user_select" style={{marginBottom: "-20px"}}>
      <p style={style.maintitle}>
        계정 보호를 위해 <span  style={{color: "#625af6"}} >2단계 인증</span>을 진행합니다.
      </p>
      <p style={{fontWeight: "600", fontSize: "16px", color: "#555555"}}>본인을 선택하고 휴대폰 인증을 완료해 주세요.</p>
      <p style={style.subtitle}>
        - 미인증 사용자는 최초 1회 본인인증을 진행한 후 2단계 인증을 시도해 주세요.
      </p>
      
      <p style={style.subtitle}>
        - 사용자 등록 및 정보 변경은 대표사용자에게 문의해 주세요.
      </p>
      <AccountUserList data={tableRows} />
      <div className="button-wrap">
        <p className="lfloat">
          {/* <Button
            buttonName="button-bgc-gray"
            buttonValue="휴대폰 인증"
            onChangePage={(e) => {
              handleAuth(1);
            }}
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="이메일 인증"
            onChangePage={(e) => {
              handleAuth(2);
            }}
          />  */}
          <button name={"button-bgc-gray"} value={1} onClick={handleAuth} disabled={authPhoneBtnDisabled}>휴대폰 인증</button>
          {/* <button name={"button-bgc-gray"} value={2} onClick={handleAuth} disabled={authEmailBtnDisabled}>이메일 인증</button> */}
        </p>
        <p style={{marginTop: "0px"}}>
          <span style={style.countWrap} >
            <input
              type="text"
              name=""
              defaultValue=""
              style={style.countNumber}
              placeholder="인증번호"
              onChange={(e) => {
                setNum(e.target.value);
              }}
              onKeyPress={handleEnterPress}
            />
            <p style={style.red}>
              {min}:{sec < 10 ? `0${sec}` : sec}
            </p>
          </span>
          <NavLink to="/">
            <Button
              buttonName="button-bgc-dark"
              buttonValue="인증"
              onChangePage={login}
              style={{marginTop:"-5px"}}
            />
          </NavLink>
        </p>
      </div>
    </div>
  );
}

//회원가입 - 동일한 사업자등록번호로 진행
export function LayerPopJoin1(props) {
  const style = {
    subtitle: {
      color: "#666666",
      lineHeight: 1.5,
    },
  };

  const handleCancle = (event) => {
    event.preventDefault();
    setUseCust(false);
    props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setUseCust(true);
    props.onClose();
  };

  const [tableRows, setTableRows] = useState(props.data);
  const useCust = props.useCust;
  const setUseCust = props.setUseCust;

  return (
    <>
      <p style={style.subtitle}>
        동일한 사업자등록번호로 가입한 내역이 있습니다.
        <br />
        아래 계정의 서브계정으로 가입을 진행하실 경우 확인버튼을 클릭해주세요.
        <br/>
        <table className="table_style2 KakaoFriendList nowrap LoginStep">
          <thead>
            <tr>
              <th>선택</th>
              <th>대표 계정</th>
              <th>담당자</th>
              <th>연락처</th>
            </tr>
            {tableRows}
          </thead>
        </table>
      </p>
      <div className="popup-bottom-button">
        {/* <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />*/}
        <Button
          buttonName="confirm"
          buttonValue="확인"
          onChangePage={handleSubmit}
        />
      </div>
    </>
  );
}

//회원가입 - 대표계정 연동
export function LayerPopJoin2(props) {
  const style = {
    subtitle: {
      color: "#666666",
      lineHeight: 1.5,
    },
  };
  const handleCancle = (event) => {
    event.preventDefault();
    props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onClose();
  };
  return (
    <>
      <p style={style.subtitle}>
        대표계정을 연동하시면 가입하신 계정은
        <br /> 아래의 대표계정의 서브계정으로 가입됩니다.
        <br />
        연동할 사업자번호의 정보를 확인하여 맞으시면 확인을 눌러주세요.
      </p>

      <ConnectMaster />

      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="확인"
          onChangePage={handleSubmit}
        />
      </div>
    </>
  );
}

//문자보내기 - 테스트발송
export function LayerPopSendTestMsg(props) {
  const sendMsgTest = props.sendMsgTest;
  const closeSendMsgTestPop = props.closeSendMsgTestPop;
  const msgType = props.msgType;
  const unitPrce = props.unitPrce;
  const [testNum, setTestNum] = useState("");
  const style = {
    defaultColor: {
      color: "#4a4a4a",
      margin: "15px 0",
    },
  };

  const SendTestMsgConfirm = (event) => {
    event.preventDefault();
    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;
    if (!testNum) {
      alert("휴대폰번호를 입력하세요.");
      return;
    } else if (!regexMobile.test(testNum)) {
      alert("잘못된 형식의 휴대폰번호 입니다.");
      return;
    }

    sendMsgTest(testNum);
  };
  const handleChange = (num) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(num)) {
      setTestNum(num);
    }
  };
  return (
    <>
      <div className="check-list-item">
        <div className="input-inner clearfix" id="cropNumArea">
          <div className="title-con">
            <span>휴대폰번호</span>
          </div>
          <div className="input-con">
            <input
              type="text"
              id="testPhoneNumber"
              name="testPhoneNumber"
              maxlength="11"
              class="gs-custom-input"
              title="휴대폰번호"
              placeholder="'-'없이 숫자만 입력"
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={testNum}
            />
          </div>
          <p className="clear"></p>
        </div>
      </div>

      <div style={style.defaultColor}>
        * 테스트 전송 시에도 전송 요금이 차감됩니다.
      </div>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="전송하기"
          onChangePage={SendTestMsgConfirm}
        />
      </div>
    </>
  );
}

//문자보내기 - 메시지발송 컨펌
export function LayerPopSendMsg(props) {
  const sendMsg = props.sendMsg;
  const closeSendMsgPop = props.closeSendMsgPop;
  const receiveCount = props.receiveCount;
  const chekedId = props.chekedId;
  const msgType = props.msgType;
  const unitPrce = props.unitPrce;
  const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

  const style = {
    left: {
      float: "left",
      width: "50%",
      marginBottom: "8px",
    },
    right: {
      float: "right",
      width: "50%",
      textAlign: "right",
      marginBottom: "8px",
    },
    red: {
      color: "#ff5757",
    },
  };
  const handleCancle = (event) => {
    event.preventDefault();
    closeSendMsgPop();
  };
  const handleSendMsg =  async (event) => {
    event.preventDefault();
    sendMsg();
    await delay(1000);
    closeSendMsgPop();
  };
  return (
    <>
      <ul className="graybox">
        <li style={style.left}>메시지 타입</li>
        <li style={style.right}>
          <span className="red">{msgType}</span>
        </li>
        <li style={style.left}>발송 요청 건수</li>
        <li style={style.right}>
          <span className="red">{setComma(receiveCount)}</span>&nbsp;건
        </li>
        <li style={style.left}>메시지 발송 요금</li>
        <li style={style.right}>
          <span className="red">
            {setCommaToFixed(unitPrce * receiveCount * 1.1)}
          </span>
          &nbsp;원
        </li>
        <li style={style.left}>메시지 발송 시각</li>
        <li style={style.right}>
          <span className="bold">
            {chekedId == "id3_2"
              ? document
                  .getElementById("datetime-local")
                  .value.replace("T", " ")
              : "즉시"}
          </span>
        </li>
        <p className="clear"></p>
      </ul>
      {receiveCount >= 10000 ? (
          <>
            <p className="red" style={{marginTop: "7px", fontWeight: "bold", fontSize: "15px"}}>
              ※ 1만건 이상 발송은 관리자 승인이 필요합니다.
            </p>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px"}}>스팸, 스미싱을 방지하기 위해 1만건 이상 메시지는 관리자의 승인이 필요합니다.</span>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px",  color: "#625af6"}}>대량 발송을 하시면 담당자에게 실시간으로 자동 접수되며 영업일 기준 1일 이내 처리 완료됩니다.</span>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px"}}>승인 여부는 일반관리 - 전송결과에서 확인 가능합니다.</span>
            <br></br>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px"}}>더 빠른 처리를 원하시는 고객님은 고객센터 - 상담문의를 통해 문의 부탁드립니다.</span>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px"}}>영업시간(08:30 ~ 17:30) 외에 대량 발송을 원하시는 고객님은 예약 발송을 부탁드립니다.</span>
            <br></br>
            <span style={{marginLeft: "10px", fontWeight: "bold", fontSize: "14px"}}>담당자가 미리 확인한 후 승인할 수 있습니다.</span>
            <br></br>
            <br></br>
          </>
      ) : null}
      
      <p className="mt10" style={{fontWeight: "bold", fontSize: "15px"}}>
        메시지를 전송하기 전, 상기 내역을 확인하시기 바랍니다.
      </p>
      
      <div className="button-wrap center">
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="취소"
          onChangePage={handleCancle}
        />
        <Button
          buttonName="button-bgc-blue sbtn"
          buttonValue="전송"
          onChangePage={handleSendMsg}
        />
      </div>
    </>
  );
}

//문자보내기 - 메시지발송 시 잔액부족 안내
export function LayerPopRechargeInfo(props) {
  const sendMsg = props.sendMsg;
  const closeSendMsgPop = props.closeInsufficientBalancePop;
  const receiveCount = props.receiveCount;
  const nowBalance = props.nowBalance;
  const balance = props.balance;
  const setBalance = props.setBalance;
  const popupSendTestMsg = props.popupSendTestMsg;
  const popupSendMsgConfirm = props.popupSendMsgConfirm;
  const sendType = props.sendType;
  const payInfo = props.payInfo;
  const accountNo = props.accountNo;

  const style = {
    left: {
      float: "left",
      width: "50%",
      marginBottom: "8px",
    },
    right: {
      float: "right",
      width: "50%",
      textAlign: "right",
      marginBottom: "8px",
    },
    red: {
      color: "#ff5757",
    },
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleRefresh = (e) => {
    e.preventDefault();
    const userInfoUrl = "/user/userInfo";
    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setBalance(jsonArr[0].balance);
          sessionStorage.setItem("pay", jsonArr[0].balance);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCancle = (event) => {
    event.preventDefault();
    closeSendMsgPop();
  };

  const handleSendMsg = (event) => {
    event.preventDefault();
    if ((nowBalance * receiveCount * 1.1 - balance).toFixed(2) > 0) {
      alert("잔액이 부족합니다.");
      return;
    } else if ((nowBalance * receiveCount * 1.1 - balance).toFixed(2) <= 0) {
      closeSendMsgPop();
      if (sendType === "T") {
        popupSendTestMsg(true);
      } else if (sendType === "R") {
        popupSendMsgConfirm(true);
      }
    }
  };

  return (
    <>
      <ul className="graybox">
        <li style={style.left}>고객 잔액</li>
        <li style={style.right}>
          <a href="/" onClick={handleRefresh}>
            <FontAwesomeIcon
              icon={faRedoAlt}
              className="gray999 mr10 cursor size8"
            />
          </a>
          <span>{setComma(balance)}</span>원
        </li>
        <li style={style.left}>메시징 발송 요금</li>
        <li style={style.right}>
          <span>
            {sendType === "T"
              ? setCommaToFixed(nowBalance * 1.1)
              : setCommaToFixed((nowBalance * receiveCount) * 1.1)}
          </span>
          &nbsp;원
        </li>
        <li style={style.left}>부족 금액</li>
        <li style={style.right}>
          <span className="red">
            {sendType === "T"
              ? setCommaToFixed(nowBalance * 1.1 - balance)
              : setCommaToFixed(nowBalance * receiveCount * 1.1 - balance) 
            }
          </span>
          원
        </li>
        {payInfo === "1" && accountNo != undefined ? (
          <div>
            <li style={style.left}>내 전용계좌</li>
            <li style={style.right}>하나은행 {accountNo}</li>
            <li style={style.left}></li>
            <li style={style.right}>
              <span className="red">충전까지 최대 1분 소요됩니다.</span>
            </li>
          </div>
        ) : null}
        {payInfo === "1" && accountNo === undefined ? (
          <div>
            <li style={style.left}>내 전용계좌</li>
            <li style={style.right}>발급계좌 없음</li>
          </div>
        ) : null}
        <p className="clear"></p>
      </ul>
      <p className="mt10 mb10 size14 gray666">
        부족 금액을 충전한 후
        <FontAwesomeIcon icon={faRedoAlt} className="mr5" />
        버튼을 눌러 잔액을 확인해 주세요. <br />
        아래의 '재전송'버튼을 눌러서 메시지를 발송할 수 있습니다.
      </p>
      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="재전송"
          onChangePage={handleSendMsg}
        />
      </div>
    </>
  );
}

//요금조회 및 납부 - 충전금 배분/회수하기
export function LayerPopDevideCharge(props) {
  const custBalanceInfo = props.custBalanceInfo;
  const handlePageChange = props.handlePageChange;
  const handleGetBalance = props.handleGetBalance;
  
  const [subBalanceInfo, setSubBalanceInfo] = useState(custBalanceInfo);
  const [allSubAmount, setAllsubAmount] = useState(false);
  const handleSetTotalAmount = () => {
    let amount = 0;
    subBalanceInfo.map((info) => (amount += Number(info.amount)));
    return amount;
  };
  const setDistributionClosePop = props.setDistributionClosePop;
  const balance = props.balance;
  const style = {
    defaultColor: {
      color: "#4A4A4A",
      margin: "15px 0",
    },
    left: {
      float: "left",
      width: "50%",
      color: "#616161",
    },
    right: {
      float: "right",
      width: "50%",
      textAlign: "right",
      fontWeight: "500",
    },
    red: {
      color: "#FF5757",
    },
    text: {
      width: "90px",
      margin: "0 5px",
    },
    check: {
      position: "relative",
      top: "3px",
      marginRight: "5px",
    },
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handledivideBalance = () => {
    const url = "/user/divideBalance";
    const data = subBalanceInfo;
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          alert(result.resultMsg);
          handlePageChange(1);
        }
      })
      .catch((error) => alert(error));
  };
  const handleCancle = (event) => {
    setDistributionClosePop();
    event.preventDefault();
  };
  const handleSendMsg = (event) => {
    event.preventDefault();
    let totalAmount = handleSetTotalAmount();
    if (Number(balance) < totalAmount) {
      alert("분배가능한 충전금액이 부족합니다.");
      return;
    }
    if (totalAmount === 0) {
      alert("충전할 금액을 입력해주세요.");
      return;
    }
    handledivideBalance();
    handleGetBalance();
    setAllsubAmount(false);
    setDistributionClosePop();
    props.getAlertShown(); // 정상적으로 접수 알림 alert디자인
  };
  const handleSetSubAmount = (e, index) => {
    const subAmount = [...subBalanceInfo];
    const { name, value } = e.target;

    if(isNaN(value) === false){
      subAmount[index][name] = value;
    } else {
      return true;
    };

    subAmount[index][name] = value;
    setSubBalanceInfo(subAmount);
  };
  const handleChekedAllsubAmount = (e) => {
    if (e.target.checked) {
      setAllsubAmount(true);
    } else {
      setAllsubAmount(false);
    }
  };
  const handleSetSubAllAmount = (e) => {
    if (allSubAmount) {
      const subAmount = [...subBalanceInfo];
      const { name, value } = e.target;

      if(isNaN(value) === false){
        subAmount.map((info) => (info[name] = value));
      } else {
        return;
      };

      setSubBalanceInfo(subAmount);
    }
  };
  return (
    <>
      <ul className="graybox mb20">
        <li style={style.left}>배분 가능 잔액</li>
        <li style={style.right}>
          <span className="blue">{balance.toLocaleString("ko-KR")}</span>
        </li>
        <p className="clear"></p>
      </ul>
      <p style={style.defaultColor}>
        충전금액 배분할 서브계정에 금액을 입력해주세요.
      </p>
      <DivideCharge
        subBalanceInfo={subBalanceInfo}
        handleSetSubAmount={handleSetSubAmount}
      />
      <div style={style.defaultColor}>
        <label>
          <input
            type="checkbox"
            name=""
            style={style.check}
            onChange={(e) => handleChekedAllsubAmount(e)}
          />
          전체 계정에{" "}
          <input
            type="text"
            name="amount"
            style={style.text}
            onChange={(e) => handleSetSubAllAmount(e)}
            disabled={!allSubAmount}
          />
          원씩 일괄 배분
        </label>
      </div>
      <ul className="graybox mt20">
        <li style={style.left}>배분할 금액 합계</li>
        <li style={style.right}>
          <span className="red">
            {handleSetTotalAmount().toLocaleString("ko-KR")}
          </span>
        </li>
        <p className="clear"></p>
      </ul>
      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="확인"
          onChangePage={handleSendMsg}
        />
      </div>
    </>
  );
}

//요금조회 및 납부 - 충전금 회수하기
//요금조회 및 납부 - 충전금 회수하기
export function LayerPopCollectCharge(props) {
  const custBalanceInfo = props.custBalanceInfo;
  const handleGetBalance = props.handleGetBalance;
  const handlePageChange = props.handlePageChange;
  const [subBalanceInfo, setSubBalanceInfo] = useState(custBalanceInfo);
  const [allSubAmount, setAllSubAmount] = useState(false);
  const [isFullRecovery, setIsFullRecovery] = useState(false);
  const handleSetTotalAmount = () => {
    let amount = 0;
    subBalanceInfo.map((info) => (amount += Number(info.amount)));
    return amount;
  };
  const handleSetTotalBalance = () => {
    let totalBalance = 0;
    custBalanceInfo.map((info) => (totalBalance += Number(info.balance)));
    return totalBalance;
  };
  const style = {
    defaultColor: {
      color: "#4A4A4A",
      margin: "15px 0",
    },
    left: {
      float: "left",
      width: "50%",
      color: "#616161",
    },
    right: {
      float: "right",
      width: "50%",
      textAlign: "right",
      fontWeight: "500",
    },
    red: {
      color: "#FF5757",
    },
    text: {
      width: "90px",
      margin: "0 5px",
    },
    check: {
      position: "relative",
      top: "3px",
      marginRight: "5px",
    },
    marginRight15: {
      marginRight: "15px",
    },
  };
  const handleSetSubAmount = (e, index) => {
    
    const subAmount = [...subBalanceInfo];
    const { name, value } = e.target;

    if(isNaN(value) === false){
      subAmount[index][name] = value;
    } else {
      return;
    };

    setSubBalanceInfo(subAmount);
  };
  const handleChekedAllsubAmount = (e) => {
    if (e.target.checked) {
      setAllSubAmount(true);
    } else {
      setAllSubAmount(false);
    }
  };
  const handleSetSubAllAmount = (e) => {
    if (allSubAmount) {
      const subAmount = [...subBalanceInfo];
      const { name, value } = e.target;

      if(isNaN(value) === false){
        subAmount.map((info) => (info[name] = value));
      } else {
        return;
      };

      
      setSubBalanceInfo(subAmount);
    }
  };
  const handleFullRecovery = (e) => {
    if (e.target.checked) {
      const subAmount = [...subBalanceInfo];
      subAmount.map((info) => (info["amount"] = info.balance));
      setSubBalanceInfo(subAmount);
    }
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleCollectBalance = () => {
    const url = "/user/collectBalance";
    const data = subBalanceInfo;

    for(let i = 0; i < subBalanceInfo.length; i++) {
      if(subBalanceInfo[i].balance < subBalanceInfo[i].amount) {
        alert(subBalanceInfo[i].custId + "의 회수할 수 있는 잔액이 부족합니다.")
        return; 
      }
    }
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          alert(result.resultMsg);
          handlePageChange(1);
        }
      })
      .catch((error) => alert(error));
  };
  const handleCancle = (event) => {
    setCollectClosePop();
    event.preventDefault();
  };
  const setCollectClosePop = props.setCollectClosePop;
  const handleSendMsg = (event) => {
    event.preventDefault();
    let totalBalance = handleSetTotalBalance();
    let totalAmount = handleSetTotalAmount();
    if (totalBalance < totalAmount) {
      alert("회수가능한 충전금액이 부족합니다.");
      return;
    }
    if (totalAmount === 0) {
      alert("회수할 금액을 입력해주세요.");
      return;
    }
    handleCollectBalance();
    handleGetBalance();
    setCollectClosePop();
    setAllSubAmount(false);
    props.getAlertShown(); // 정상적으로 접수 알림 alert디자인
  };
  return (
    <>
      <ul className="graybox mb20">
        <li style={style.left}>회수 가능 잔액</li>
        <li style={style.right}>
          <span className="blue">
            {handleSetTotalBalance().toLocaleString("ko-KR")}
          </span>
        </li>
        <p className="clear"></p>
      </ul>
      <p style={style.defaultColor}>
        배분한 충전금을 회수할 서브계정에 회수금액을 입력해주세요.
      </p>
      <CollectCharge
        subBalanceInfo={subBalanceInfo}
        handleSetSubAmount={handleSetSubAmount}
      />
      <div style={style.defaultColor}>
        <label style={style.marginRight15}>
          <input
            type="checkbox"
            name=""
            style={style.check}
            onChange={(e) => handleChekedAllsubAmount(e)}
          />
          전체 계정에{" "}
          <input
            type="text"
            name="amount"
            defaultValue=""
            style={style.text}
            disabled={!allSubAmount}
            onChange={(e) => handleSetSubAllAmount(e)}
          />
          원씩 일괄 회수
        </label>
        <label>
          <input
            type="checkbox"
            name=""
            style={style.check}
            onChange={(e) => handleFullRecovery(e)}
          />
          일괄 전액 회수
        </label>
      </div>
      <ul className="graybox mt20">
        <li style={style.left}>회수할 금액 합계</li>
        <li style={style.right}>
          <span className="red">
            {handleSetTotalAmount().toLocaleString("ko-KR")}
          </span>
        </li>
        <p className="clear"></p>
      </ul>
      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="확인"
          onChangePage={handleSendMsg}
        />
      </div>
    </>
  );
}

export function LayerPopPaymentTotal(props) {
  const onClose = props.onClose;
  const cmYear = props.cmYear;
  const cmMonth = props.cmMonth;
  const cmCustId = props.cmCustId;
  const cmCustSn = props.cmCustSn;
  const custGrade = props.custGrade;
  const closeLoading = props.closeLoading;
  const onLoading = props.onLoading;
  const lastDay = new Date(cmYear, cmMonth, 0).getDate();
  const [usageList, setUsageList] = useState([]);
  const [monthClaim, setMonthClaim] = useState("");
  const [unaidClaim, setUnaidClaim] = useState([]);
  const [billResult, setBillResult] = useState({});
  const [cmRdcAmount, setCmRdcAmount] = useState({});
  const [minPayment, setMinPayment] = useState({});
  const [cmSn, setCmSn] = useState("");
  const msgTypeList = ["SMS", "LMS", "MMS", "알림톡", "친구톡", "친구톡 이미지", "친구톡 와이드 이미지"]
  const [usageMsgTypeList, setUsageMsgTypeList] = useState([]); 
  const [corpNm, setCorpNm] = useState("");
  const [cmStatus, setCmStatus] = useState();
  const [cmPayAmount, setCmPayAmount] = useState();
  const [cmTaxesStatus, setCmTaxesStatus] = useState("");
  
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  useEffect(() => {
  }, [props]);
  
  const handleFindCustMonthUSage = () => {
    const url = "/user/findCustMonthUSage";
    const data = { search1: cmYear, search2: cmMonth, search5: custGrade, search6: "1", userSn: cmCustSn };
    onLoading();
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          let totAmount = 0.0;
          let totVat = 0.0;
          let totResultAmount = 0.0;
          const jsonArr = JSON.parse(result.resultData);
          
          let custUsageList = jsonArr.custMonthUsageList;
          let custMonthClaim = jsonArr.custMonthClaim;
          if (custMonthClaim === undefined) {
            alert("청구 내역서가 존재하지 않습니다.");
            closeLoading();
            onClose(false);
            return;
          }
          custUsageList.map(
            (info) => (
              setUsageMsgTypeList(prevList => [...prevList, info.msgType]),
              (totAmount += Number(info.amount)),
              (totVat += Number(info.vat)),
              (totResultAmount += Number(info.totAmount))
            )
          );

          setMonthClaim(jsonArr.custMonthClaim);
          setCorpNm(jsonArr.custMonthClaim.corpNm);
          setCmStatus(jsonArr.custMonthClaim.cmStatus);
          setCmPayAmount(jsonArr.custMonthClaim.cmPayAmount);
          setCmSn(jsonArr.custMonthClaim.cmSn);
          setCmTaxesStatus(jsonArr.custMonthClaim.cmTaxesStatus);
          setUsageList(custUsageList);
          if (jsonArr.unaidClaim !== undefined) {
            setUnaidClaim(jsonArr.unaidClaim);

            let unpaidArr = jsonArr.unaidClaim;
            // unpaidArr.map(
            //   info => (
            //     (totAmount += info.cmAmount),
            //     (totVat += info.cmVat),
            //     (totResultAmount += info.cmTotAmount)
            //   )
            // );
          }
          totAmount -= Number(custMonthClaim.cmRdcAmount);
          totVat -= Number(custMonthClaim.cmRdcAmount) * 0.1;
          totResultAmount = totAmount + totVat;
          totAmount = Math.round((totAmount + Number.EPSILON) * 100) / 100;
          totVat = Math.round((totVat + Number.EPSILON) * 100) / 100;
          totResultAmount =
            Math.round((totResultAmount + Number.EPSILON) * 100) / 100;
          setBillResult({
            totAmount: totAmount,
            totVat: totVat,
            totResultAmount: totResultAmount,
          });
          if (custMonthClaim.cmRdcAmount !== undefined) {
            let rdcAmount =
              Math.round((custMonthClaim.cmRdcAmount + Number.EPSILON) * 100) /
              100;
            let rdcVat =
              Math.round(
                (custMonthClaim.cmRdcAmount * 0.1 + Number.EPSILON) * 100
              ) / 100;
            let rdcTot = rdcAmount + rdcVat;
            setCmRdcAmount({
              cmRdcAmount: rdcAmount,
              cmRdcVat: rdcVat,
              cmRdcTotal: rdcTot,
            });
          }
          closeLoading();
        }
      })
      .catch((error) => { closeLoading(); });
  };

  const handleRequestTaxes = () => {

    var date = cmYear + "년 " + cmMonth + "월";

    if (window.confirm(date + " 사용내역을 확인하였고,\n담당자에게 세금계산서 발행 요청을 하시겠습니까?")) {
      const url = "/pay/requestTaxes";
      const data = { search1: cmYear, 
                     search2: cmMonth, 
                     search3: setComma(billResult.totAmount), 
                     search4: setComma(billResult.totVat),
                     search5: setComma(billResult.totResultAmount),
                     search6: cmSn
                   };
      onLoading();
      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            alert("세금계산서 발행 요청을 완료하였습니다.");
            handleFindCustMonthUSage();
          } else {
            alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다.")
          }
          closeLoading();
        })
    }
  }

  // 미납금액의 공급가, 부가세, 청구금액 합계
  const totCmAmount = unaidClaim.reduce((acc, cur) => acc + cur.cmAmount, 0);
  const totCmVat = unaidClaim.reduce((acc, cur) => acc + cur.cmVat, 0);
  const totCmTotAmount = unaidClaim.reduce(
    (acc, cur) => acc + cur.cmTotAmount,
    0
  );

  const handlePayment = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    handleFindCustMonthUSage();
  }, []);
  return (
    <div className="statement">
      {cmCustSn && <div>계정 ID : {cmCustId}</div>}
        
      <div className="button-wrap" style={{marginTop: "0px"}}>
        <div style={{float: "left"}}>
          수신처 : {corpNm}
          <br></br>
          사용 기간 : {cmYear}년 {cmMonth}월 01일 ~ {cmMonth}월 {lastDay}일
        </div>
        <div style={{float: "right"}}>
          <Button buttonName="buttonDown" buttonValue="엑셀 다운로드"/> 
        </div>
        <br></br>
      </div>
      <table className="table_style1 textright" style={{margin: "0px"}}>
        <colgroup>
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
        </colgroup>
        <thead>
          <tr>
            <th colspan="5" className="center bold">
              청구 금액 (공급가 + 부가세)
            </th>
            <th className="bold">{setComma(billResult.totResultAmount)}원</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="center">구분</th>
            <th className="center">성공건수</th>
            <th className="center">단가</th>
            <th className="center">공급가액</th>
            <th className="center">부가세</th>
            <th className="center">청구금액</th>
          </tr>

          {
            msgTypeList.map((type) => (
              usageMsgTypeList.indexOf(type) != -1 ?
                usageList.map((info) => (
                  usageMsgTypeList.indexOf(info.msgType) != -1 && info.msgType == type && 
                    <tr>
                      <td className="center">{info.msgType}</td>
                      <td>{info.stTot != 0 ? setComma(info.stTot) : '-' }</td>
                      <td className="center">{info.prce != 0 ? info.prce : '-' }</td>
                      <td>{info.amount != 0 ? setComma(info.amount) : '-' }</td>
                      <td>{info.vat != 0 ? setComma(info.vat) : '-' }</td>
                      <td>{info.totAmount != 0 ? setComma(info.totAmount) : '-' }</td>
                    </tr>
                )) : <tr>
                <td className="center">{type}</td>
                <td>-</td>
                <td className="center">-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr> 
              
            ))
          }
          <tr>
            <td colSpan="3" className="center">
              요금 감액
            </td>
            <td>-</td>
            <td>-</td>
            <td>{cmRdcAmount.cmRdcAmount != 0 ? setComma(cmRdcAmount.cmRdcAmount) : '-' }</td>
          </tr>
          <tr>
            <td colSpan="3" className="center">
              080 사용료
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan="3" className="bold center">
              합계
            </th>
            <th>{setComma((billResult.totAmount))}</th>
            <th>{setComma((billResult.totVat))}</th>
            <th>
              {setComma(
                (billResult.totResultAmount)
              )}
            </th>
          </tr>
          
          <tr>
            <th colSpan="3" className="bold center">
              최소청구액
            </th>
            {monthClaim.cmMinAmount !== undefined ? (
              <>
                <th>{setComma(Number(monthClaim.cmMinAmount))}</th>
                <th>
                  {setComma(Number(monthClaim.cmMinAmount * 0.1))}
                </th>
                <th>
                  {setComma(Number(monthClaim.cmMinAmount * 1.1))}
                </th>
              </>
            ) : (
              <>
                <th>-</th>
                <th>-</th>
                <th>-</th>
              </>
            )}
          </tr> 
          <br></br>
          <tr>
            <th colSpan="3" className="bold center">
              납부 상태
            </th>
            <th colspan="3" className="bold center">{cmStatus}</th>
          </tr>
          
          <tr>
            <th colSpan="3" className="bold center">
              납부 금액
            </th>
            <th colspan="3" className="bold center">{cmPayAmount}</th>
          </tr>
        </tfoot>
      </table>
      <div className="mt10 mb10 bold" style={{fontSize: "13px", float: "left"}}>
        위 내역을 확인 바랍니다.
      </div>
      <div className="mt10 mb10" style={{fontSize: "12px", textAlign: "right", float: "right"}}>
        한진정보통신 주식회사
        <br></br>
        대표이사  변 봉 섭 (직인생략)
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div style={{float: "right"}} >
        {
          cmTaxesStatus == "1" && 
            <Button
              onChangePage={handleRequestTaxes}
              style={{height: "40px"}}
              buttonName="button-bgc-blue bbtn"
              buttonValue="세금계산서 발행 요청"
          />
        }
        {
          cmTaxesStatus == "2" && 
            <span style={{fontWeight: "bold", fontSize: "17px", color: "#625af6"}}>세금계산서 발행 요청이 접수되었습니다.</span>
        }
        {
          cmTaxesStatus == "3" && 
            <span style={{fontWeight: "bold", fontSize: "17px", color: "#625af6"}}>세금계산서 발행이 완료되었습니다.</span>
        }
      </div>
    </div>
  );
}

//요금조회 및 납부 - 충전금액 계산기
export function LayerPopCalculator(props) {
  const infoData = props.infoData;
  const smsPrce = infoData.smsPrce === undefined ? 0 : infoData.smsPrce;
  const lmsPrce = infoData.lmsPrce === undefined ? 0 : infoData.lmsPrce;
  const mmsPrce = infoData.mmsPrce === undefined ? 0 : infoData.mmsPrce;
  const alimPrce = infoData.alimPrce === undefined ? 0 : infoData.alimPrce;
  const frdImgPrce =
    infoData.frdImgPrce === undefined ? 0 : infoData.frdImgPrce;
  const frdPrce = infoData.frdPrce === undefined ? 0 : infoData.frdPrce;
  const frdWideImgPrce =
    infoData.frdWideImgPrce === undefined ? 0 : infoData.frdWideImgPrce;
  const rcsSmsPrce =
    infoData.rcsSmsPrce === undefined ? 0 : infoData.rcsSmsPrce;
  const rcsLmsPrce =
    infoData.rcsLmsPrce === undefined ? 0 : infoData.rcsLmsPrce;
  const rcsMmsPrce =
    infoData.rcsMmsPrce === undefined ? 0 : infoData.rcsMmsPrce;
  const rcsTmplPrce =
    infoData.rcsTmplPrce === undefined ? 0 : infoData.rcsTmplPrce;
  const balance = infoData.balance === undefined ? 0 : infoData.balance;
  const [totalPrce, setTotalPrce] = useState(0);
  const [vat, setVat] = useState(0.0);
  const [prce, setPrce] = useState(0.0);
  const [msgsCnt, setMsgsCnts] = useState({
    sms: "",
    lms: "",
    mms: "",
    alim: "",
    frd: "",
    frdImg: "",
    frdWideImg: "",
    rcsSms: "",
    rcsLms: "",
    rcsMms: "",
    rcsTp: "",
  });
  const [balanceIncludeCheck, setBalanceIncludeCheck] = useState(false);
  const handleMsgCnt = (e) => {
    const { name, value } = e.target;
    let numberRegx = "/[^0-9]/g";
    const replaceValue = value.replace(/[^0-9]/g, "");
    setMsgsCnts({ ...msgsCnt, [name]: replaceValue });
  };
  const style = {
    defaultColor: {
      color: "#4A4A4A",
      margin: "15px 0",
    },
    check: {
      position: "relative",
      top: "3px",
      margin: "0 5px 0 15px",
    },
    smallTitle: {
      fontWeight: "600",
      marginRight: "30px",
    },
  };
  
  const handleEnterPress = e => {
    if(e.key === 'Enter') {
      handleCalculator(e);
    }
  }

  const handleCalculator = (event) => {
    event.preventDefault();
    let totalSms = msgsCnt.sms * smsPrce;
    let totalLms = msgsCnt.lms * lmsPrce;
    let totalMms = msgsCnt.mms * mmsPrce;
    let totalAlim = msgsCnt.alim * alimPrce;
    let totalFrd = msgsCnt.frd * frdPrce;
    let totalFrdImg = msgsCnt.frdImg * frdImgPrce;
    let totalFrdWideImg = msgsCnt.frdWideImg * frdWideImgPrce;
    let totalRcsSms = msgsCnt.rcsSms * rcsSmsPrce;
    let totalRcsLms = msgsCnt.rcsLms * rcsLmsPrce;
    let totalRcsMms = msgsCnt.rcsMms * rcsMmsPrce;
    let totalRcsTp = msgsCnt.rcsTp * rcsTmplPrce;
    let vat = 0.0;
    let totalPrce = 0.0;
    let finalTotalPrice =
      totalSms +
      totalLms +
      totalMms +
      totalAlim +
      totalFrd +
      totalFrdImg +
      totalFrdWideImg +
      totalRcsSms +
      totalRcsLms +
      totalRcsMms +
      totalRcsTp;
    vat = finalTotalPrice * 0.1;
    totalPrce = vat + finalTotalPrice;
    if (balanceIncludeCheck) {
      let calcu = totalPrce - balance;
      if (Math.sign(calcu) === -1) {
        finalTotalPrice = 0;
        vat = 0;
        totalPrce = 0;
      } else {
        totalPrce = calcu;
        finalTotalPrice = calcu / 1.1;
        vat = totalPrce - finalTotalPrice;
      }
    }
    setVat(Math.round(vat * 100) / 100.0);
    setPrce(Math.round(finalTotalPrice * 100) / 100.0);
    setTotalPrce(Math.round(totalPrce * 100) / 100.0);
  };
  const handleBalanceCheck = (e) => {
    setBalanceIncludeCheck(e.target.checked);
  };
  return (
    <>
      <div style={style.defaultColor}>
        <span style={style.smallTitle}>고객 잔액</span>
        {balance.toLocaleString("ko-KR")}원
        <label>
          <input
            type="checkbox"
            name=""
            style={style.check}
            onChange={(e) => handleBalanceCheck(e)}
          />
          충전금 잔액 포함해서 계산하기
        </label>
      </div>
      <div style={style.defaultColor}>
        <span style={style.smallTitle}>발송 예정 건수</span>
      </div>
      <ChargeCalculator msgsCnt={msgsCnt} handleMsgCnt={handleMsgCnt} handleEnterPress={handleEnterPress}/>
      <div className="button-wrap center">
        <Button
          buttonName="button-bgc-blue bbtn"
          buttonValue="계산하기"
          onChangePage={handleCalculator}
        />
      </div>
      <p className="line"></p>
      <div>
        <div className="lfloat pt20">
          <span className="bold mr20">충전해야할 금액</span>
          <span className="gray999">
            {prce.toLocaleString("ko-KR")}원 + {vat.toLocaleString("ko-KR")}
            원(부가세 10%)
          </span>
        </div>
        <div className="rfloat size24 blue">
          총 {totalPrce.toLocaleString("ko-KR")}원
        </div>
        <p className="clear"></p>
      </div>
    </>
  );
}

//요금조회 및 납부 - 전용계좌 생성
export function LayerPopAccountCreation(props) {
  const popupAccountCreation = props.popupAccountCreation;
  const setAccountNo = props.setAccountNo;
  const [search3, setSearch3] = useState(null);

  const style = {
    defaultColor: {
      color: "#4a4a4a",
      margin: "15px 0",
    },
    select: {
      width: "68%",
      position: "relative",
      top: "2px",
    },
    smallTitle: {
      fontWeight: "600",
      marginRight: "30px",
    },
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleGet = (event) => {
    event.preventDefault();

    if (window.confirm("가상계좌를 발급하시겠습니까?")) {
      if (search3 != undefined) {
        const url = "/pay/getVirtualAccount";
        const data = { search3: search3 };

        getPostFetch(url, data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              const jsonArr = JSON.parse(result.resultData);
              setAccountNo(jsonArr.vaccount);
              popupAccountCreation(false);
            }
          })
          .catch((error) => alert(error));
      } else {
        alert("은행을 선택하세요.");
        return;
      }
    }
  };
  return (
    <>
      <div className="write-left-inner" id="sendNumber">
        <div className="write-tit-con clearfix">
          <div className="gs-custom-select h40-select gray-select buttonWith">
            <select
              id="callbackList"
              name="phone"
              data-fakeform-index="0"
              onChange={(e) => {
                setSearch3(e.target.value);
              }}
            >
              <option defaultValue="">
                전용계좌를 발급받으실 은행을 선택하세요.
              </option>
              <option defaultValue="하나은행">하나은행</option>
            </select>
            <Button
              buttonName="button-bgc-dark"
              buttonValue="발급받기"
              onChangePage={handleGet}
            />
          </div>
        </div>
      </div>
      <div style={style.defaultColor}>* 카드결제는 지원하지 않습니다.</div>
    </>
  );
}

//문자보내기 - 광고문자전송 가이드
export function LayerPopAdvertisingGuide() {
  const style = {
    graybox: {
      backgroundColor: "#f7f7f7",
      padding: "25px",
      margin: "20px 0",
      fontSize: "18px",
      width: "100%",
      "& li": {
        fontWeight: "300",
        marginBottom: "30px",
        fontSize: "16px",
      },
    },
    subTitle: {
      fontWeight: "600",
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.4",
    },
    conbox: {
      marginBottom: "30px",
    },
    bigTitleFirst: {
      fontSize: "24px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    bigTitle: {
      fontSize: "24px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    con: {
      fontWeight: "300",
      marginBottom: "30px",
      lineHeight: "1.5",
    },
    depth: {
      paddingLeft: "20px",
      fontSize: "15px",
      color: "#333",
      lineHeight: "1.5",
    },
  };
  return (
    <>
      <div style={style.bigTitleFirst}>
        <span>광고문자</span>를 전송하실 경우에는{" "}
        <span className="blue">반드시 아래 내용이 포함</span>되어야 합니다.
      </div>
      <div className="mt20 mb20">
        <img src={Advertising_guide} alt="광고문자 전송가이드" />
      </div>
      <div style={style.conbox}>
        <p style={style.bigTitle}>어떤 피해가 발생하나요?</p>
        <p style={style.subTitle}>
          광고 표기 미준수 시 수신자에게 전달되지 않고 발송실패됩니다.
          <br />
          가이드 미준수로 발송 실패 및 제한되는 피해가 발생하고 있으니 반드시
          해당 문구를 입력하신 후 보내시기 바랍니다.
        </p>
        <p style={style.con}>
          * 불법스팸 문자는 가이드 준수와 상관없이 발송제한 됩니다.
          <br />* 발송제한된 전화번호는 향후에도 사용하실 수 없습니다.
        </p>
      </div>

      <div style={style.conbox}>
        <p style={style.bigTitle}>광고문자란?</p>
        <p style={style.subTitle}>
          매장 또는 웹사이트 등 업체의 방문을 유도하는 모든 문자를 광고문자라고
          합니다.
        </p>
      </div>
    </>
  );
}

//문자보내기 - 주소록 불러오기
export function LayerPopImportAddress(props) {
  const uploadAddrPopClose = props.uploadAddrPopClose;
  //const modifyAddrPopClose = props.modifyAddrPopClose;
  const [activeId, setActiveId] = useState(1);
  const [activeContent, setActiveContent] = useState(<SharedAddressBook />);
  const addressType = [
    { id: 1, name: "공유 주소록", content: <SharedAddressBook /> },
    { id: 2, name: "개인 주소록", content: <PersonalAddressBook /> },
  ];

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleAdd = (event) => {
    event.preventDefault();
    const nodeList = document.getElementsByName("checkDel");
    if (nodeList.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    if (window.confirm("선택 수신자 리스트를 추가하시겠습니까?")) {
      let snList = [];
      nodeList.forEach((node) => {
        snList.push(node.value);
      });

      const url = "/message/addrListSave";
      const data = { search1 : "2" , snList: snList };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            uploadAddrPopClose(result.resultData, snList.length);
          }
        })
        .catch((error) => alert(error));
    }
  };
  const handleCancle = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <ul className="tabmenu2">
        {addressType.map((value) => (
          <li className={activeId === value.id ? "active" : null}>
            <a
              href="/"
              onClick={function (e) {
                e.preventDefault();
                setActiveId(value.id);
                setActiveContent(value.content);
              }}
            >
              {value.name}
            </a>
          </li>
        ))}
      </ul>
      {/*activeContent*/}
      {activeId === 1 ? <SharedAddressBook /> : <PersonalAddressBook />}

      <div className="popup-bottom-button">
        <Button
          buttonName="button-border-gray"
          buttonValue="취소"
          onChangePage={handleCancle}
        />
        <Button
          buttonName="button-bgc-blue"
          buttonValue="추가"
          onChangePage={handleAdd}
        />
      </div>
    </>
  );
}

export function SharedAddressBook(props) {
  //공유 주소록
  const handleSearch = (event) => {
    event.preventDefault();
    handleChoiceView(agSn);
  };
  const handleAddGroupList = (event) => {
    event.preventDefault();

    // 저장
  };

  const handleDeleteNewUser = (sn) => {
    const rowNum = "row_" + sn;
    document.getElementById(rowNum).remove();

    document.getElementsByName("checkAllDel")[0].checked = false;
    const nodeList = document.getElementsByName("checkDel");
    setSelectCnt(nodeList.length);
  };
  const [rowData, setRowData] = useState([]);
  const handleAddSelectList = (event) => {
    event.preventDefault();
    if (!snList) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    //const rowNm = "nm_" + row.abSn;
    //const rowPhone = "phone_" + row.abSn;
    const rows = [];
    for (const sn of snList) {
      const rowNm = "nm_" + sn;
      const abNm = document.getElementById(rowNm).value;
      const rowPhone = "phone_" + sn;
      const abPhone = document.getElementById(rowPhone).value;
      const rowNum = "row_" + sn;
      rows.push(
        <tr id={rowNum}>
          <td>
            <input type="checkbox" defaultValue="" name="checkDel" value={sn} />
          </td>
          <td> {abNm} </td>
          <td> {abPhone} </td>
          <td>
            <a
              onClick={(e) => {
                handleDeleteNewUser(sn);
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </td>
        </tr>
      );
    }
    setRowData(rows);

    document.getElementsByName("checkAllTp")[0].checked = false;
    const snNodeList = document.getElementsByName("checkTp");

    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });

    setSnList([]);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleGroupSearch();
  }, []);
  const [regId, setRegId] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [totCnt, setTotCnt] = useState(0);
  const [agCnt, setAgCnt] = useState(0);
  const [group, setGroup] = useState("");
  const [upSn, setUpSn] = useState(0);
  const [groupNm, setGroupNm] = useState("");
  const [groupDepth, setGroupDepth] = useState(0);
  const [groupCnt, setGroupCnt] = useState(0);
  const [agSn, setAgSn] = useState(0);
  const [tableRows, setTableRows] = useState("");
  const [search1, setSearch1] = useState("1");
  const [search2, setSearch2] = useState("1");
  const [search3, setSearch3] = useState("");
  const [snList, setSnList] = useState([]);
  const [rows, setRows] = useState([]);
  const [delList, setDelList] = useState([]);
  const [selectCnt, setSelectCnt] = useState(0);

  const handleGroupSearch = () => {
    getRequest
      .fetch("/user/myInfo")
      .then((myInfo) => {
        if (myInfo.resultCode === "0") {
          const myInfoArr = JSON.parse(myInfo.resultData);
          let url = "/addr/addrGroupList";
          let data = { search1: "1", search2: myInfoArr.custType };
          getPostFetch(url, data)
            .then((result) => {
              if (result.resultCode === "0") {
                const jsonArr = JSON.parse(result.resultData);
                setGroupList(jsonArr);
                setTotCnt(jsonArr[0].totCnt);
                setAgCnt(jsonArr[0].totCnt);
                handelDefault();
                handleChoiceView(0);
              } else {
                alert(result.resultMsg);
              }
            })
            .catch((error) => alert(error));
        } else {
          alert(myInfo.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };

  const handleGroup = (sn, nm, tot, upSn, grNm, depth, cnt) => {
    setAgSn(sn);
    setGroup(nm);
    setUpSn(upSn);
    setGroupNm(grNm);
    setGroupDepth(depth);
    setGroupCnt(cnt);
    if (sn === "0") setAgCnt(totCnt);
    else setAgCnt(tot);
    handleChoiceView(sn);
  };

  const handelDefault = () => {
    setAgSn(0);
    setGroup("전체");
    setUpSn(-1);
    setGroupNm("전체");
    setGroupDepth(1);
    setGroupCnt(0);
    setAgCnt(totCnt);
  };

  const handleChoiceView = (sn) => {
    getRequest
      .fetch("/user/myInfo")
      .then((myInfo) => {
        if (myInfo.resultCode === "0") {
          const myInfoArr = JSON.parse(myInfo.resultData);

          const url = "/addr/addrListSend";
          const data = {
            search1: search1,
            search2: search2,
            search3: search3,
            search4: sn,
            search5: sessionStorage.getItem("custType")
          };

          getPostFetch(url, data)
            .then((result) => {
              if (result.resultCode === "0") {
                const jsonArr = JSON.parse(result.resultData);
                setTableRows(jsonArr);
              } else {
                alert(result.resultMsg);
              }
            })
            .catch((error) => alert(error));
        } else {
          alert(myInfo.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };

  const handelChoiceDelete = () => {
    const snNodeList = document.getElementsByName("checkDel");
    let cnt = 0;
    snNodeList.forEach((node) => {
      if (node.checked) {
        const sn = node.value;
        const rowNum = "row_" + sn;
        node.checked = false;
        setTimeout(function () {
          document.getElementById(rowNum).remove();
        }, 5);
      } else {
        cnt = cnt + 1;
      }
    });

    document.getElementsByName("checkAllDel")[0].checked = false;
    setSelectCnt(cnt);
  };

  return (
    <div className="address_area">
      <div className="left">
        <p className="list-title">주소록 리스트</p>
        <div className="filter">
          <div className="boardsearch">
            <select
              name="search_type"
              id=""
              className="select_type2"
              onChange={(e) => {
                setSearch2(e.target.value);
              }}
            >
              <option value="1">이름</option>
              <option value="2">연락처</option>
            </select>
            <input
              type="text"
              name=""
              defaultValue=""
              placeholder="검색어를 입력하세요"
              className="bolder_none"
              onChange={(e) => {
                setSearch3(e.target.value);
              }}
            />
            <a href="/" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
          <Button
            buttonName="button-bgc-blue"
            buttonValue="선택 항목 추가"
            onChangePage={handleAddSelectList}
          />
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <div className="wrap_tree borderbox">
              <div className="dtree">
                <div className="treeBody">
                  <div className="tree_area">
                    <div className="dTreeNode">
                      <FontAwesomeIcon icon={faGlobeAsia} className="cursor" />
                      <a
                        id="sd0"
                        className="node"
                        onClick={(e) => {
                          handleGroup("0", "전체", "", "-1", "전체", 1, 0);
                        }}
                      >
                        전체({totCnt})
                      </a>
                    </div>
                    {groupList.map((row) => (
                      <div className={"clip depth" + row.depth}>
                        <div className="clip">
                          <div className="dTreeNode">
                            <img src={dotted} alt="점선" />
                            <FontAwesomeIcon
                              icon={faFolder}
                              className="cursor"
                            />
                            <a
                              id={row.agNm}
                              className="node"
                              onClick={(e) => {
                                handleGroup(
                                  row.agSn,
                                  row.agNm,
                                  row.agTotCnt,
                                  row.upperAgSn,
                                  row.groupNm,
                                  row.depth,
                                  row.groupCnt
                                );
                              }}
                            >
                              {row.agNm}({row.agTotCnt})
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table_overflow">
            <ImportAddressList data={tableRows} setSnList={setSnList} />
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <div className="rright">
        <p className="list-title">선택 수신자 리스트</p>
        <div className="filter">
          <Button
            buttonName="button-bgc-gray"
            buttonValue="선택 삭제"
            onChangePage={handelChoiceDelete}
          />
          <p className="ml10">
            전체 <span className="red bold">{selectCnt}</span>건
          </p>
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <ReceiveAddressList
              data={rowData}
              setDelList={setDelList}
              handleDeleteNewUser={handleDeleteNewUser}
              setSelectCnt={setSelectCnt}
            />
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <p className="clear"></p>
    </div>
  );
}

export function PersonalAddressBook() {
  //개인 주소록

  const [regId, setRegId] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [totCnt, setTotCnt] = useState(0);
  const [agCnt, setAgCnt] = useState(0);
  const [group, setGroup] = useState("");
  const [upSn, setUpSn] = useState(0);
  const [groupNm, setGroupNm] = useState("");
  const [groupDepth, setGroupDepth] = useState(0);
  const [groupCnt, setGroupCnt] = useState(0);
  const [agSn, setAgSn] = useState(0);
  const [tableRows, setTableRows] = useState("");
  const [search1, setSearch1] = useState("2");
  const [search2, setSearch2] = useState("1");
  const [search3, setSearch3] = useState("");
  const [snList, setSnList] = useState([]);
  const [rows, setRows] = useState([]);
  const [delList, setDelList] = useState([]);
  const [selectCnt, setSelectCnt] = useState(0);
  const handleDeleteNewUser = (sn) => {
    const rowNum = "row_" + sn;
    document.getElementById(rowNum).remove();

    document.getElementsByName("checkAllDel")[0].checked = false;
    const nodeList = document.getElementsByName("checkDel");
    setSelectCnt(nodeList.length);
  };
  const [rowData, setRowData] = useState([]);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleGroupSearch();
  }, []);

  const handleGroupSearch = () => {
    let url = "/addr/addrGroupList";
    let data = { search1: "2" };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setGroupList(jsonArr);
          setTotCnt(jsonArr[0].totCnt);
          setAgCnt(jsonArr[0].totCnt);
          handelDefault();
          handleChoiceView(0);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };

  const handelDefault = () => {
    setAgSn(0);
    setGroup("전체");
    setUpSn(-1);
    setGroupNm("전체");
    setGroupDepth(1);
    setGroupCnt(0);
    setAgCnt(totCnt);
  };

  const handleChoiceView = (sn) => {

    const url = "/addr/addrListSend";
    const data = {
      search1: search1,
      search2: search2,
      search3: search3,
      search4: sn,
      search5: sessionStorage.getItem("custType")
    };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };

  const handleGroup = (sn, nm, tot, upSn, grNm, depth, cnt) => {
    setAgSn(sn);
    setGroup(nm);
    setUpSn(upSn);
    setGroupNm(grNm);
    setGroupDepth(depth);
    setGroupCnt(cnt);
    if (sn === "0") setAgCnt(totCnt);
    else setAgCnt(tot);
    handleChoiceView(sn);
  };

  const handelChoiceDelete = () => {
    const snNodeList = document.getElementsByName("checkDel");
    let cnt = 0;
    snNodeList.forEach((node) => {
      if (node.checked) {
        const sn = node.value;
        const rowNum = "row_" + sn;
        node.checked = false;
        setTimeout(function () {
          document.getElementById(rowNum).remove();
        }, 5);
      } else {
        cnt = cnt + 1;
      }
    });

    document.getElementsByName("checkAllDel")[0].checked = false;
    setSelectCnt(cnt);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    handleChoiceView(agSn);
  };
  const handleAddGroupList = (event) => {
    event.preventDefault();
  };
  const handleAddSelectList = (event) => {
    event.preventDefault();
    if (!snList) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    //const rowNm = "nm_" + row.abSn;
    //const rowPhone = "phone_" + row.abSn;
    const rows = [];
    for (const sn of snList) {
      const rowNm = "nm_" + sn;
      const abNm = document.getElementById(rowNm).value;
      const rowPhone = "phone_" + sn;
      const abPhone = document.getElementById(rowPhone).value;
      const rowNum = "row_" + sn;
      rows.push(
        <tr id={rowNum}>
          <td>
            <input type="checkbox" defaultValue="" name="checkDel" value={sn} />
          </td>
          <td> {abNm} </td>
          <td> {abPhone} </td>
          <td>
            <a
              onClick={(e) => {
                handleDeleteNewUser(sn);
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} className="gray999 ml10" />
            </a>
          </td>
        </tr>
      );
    }
    setRowData(rows);

    document.getElementsByName("checkAllTp")[0].checked = false;
    const snNodeList = document.getElementsByName("checkTp");

    snNodeList.forEach((node) => {
      if (node.checked) node.checked = false;
    });

    setSnList([]);
  };
  return (
    <div className="address_area">
      <div className="left">
        <p className="list-title">주소록 리스트</p>
        <div className="filter">
          {/* <select name="search_type" id="" className="select_type2">
                        <option defaultValue="">10개</option>
                        <option defaultValue="">500개</option>
                        <option defaultValue="">1000개</option>
                    </select> */}
          <div className="boardsearch">
            <select
              name="search_type"
              id=""
              className="select_type2"
              onChange={(e) => {
                setSearch2(e.target.value);
              }}
            >
              <option value="1">이름</option>
              <option value="2">연락처</option>
            </select>
            <input
              type="text"
              name=""
              defaultValue=""
              placeholder="검색어를 입력하세요"
              className="bolder_none"
              onChange={(e) => {
                setSearch3(e.target.value);
              }}
            />
            <a href="/" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
          {/*<Button buttonName="button-border-blue ml10 mr10" buttonValue="선택 그룹 추가" onChangePage={handleAddGroupList} />*/}
          <Button
            buttonName="button-bgc-blue"
            buttonValue="선택 항목 추가"
            onChangePage={handleAddSelectList}
          />
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <div className="wrap_tree borderbox">
              <div className="dtree">
                <div className="treeBody">
                  <div className="tree_area">
                    <div className="dTreeNode">
                      <FontAwesomeIcon icon={faGlobeAsia} className="cursor" />
                      <a
                        id="sd0"
                        className="node"
                        onClick={(e) => {
                          handleGroup("0", "전체", "", "-1", "전체", 1, 0);
                        }}
                      >
                        전체({totCnt})
                      </a>
                    </div>
                    {groupList.map((row) => (
                      <div className={"clip depth" + row.depth}>
                        <div className="clip">
                          <div className="dTreeNode">
                            <img src={dotted} alt="점선" />
                            <FontAwesomeIcon
                              icon={faFolder}
                              className="cursor"
                            />
                            <a
                              id={row.agNm}
                              className="node"
                              onClick={(e) => {
                                handleGroup(
                                  row.agSn,
                                  row.agNm,
                                  row.agTotCnt,
                                  row.upperAgSn,
                                  row.groupNm,
                                  row.depth,
                                  row.groupCnt
                                );
                              }}
                            >
                              {row.agNm}({row.agTotCnt})
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table_overflow">
            <ImportAddressList data={tableRows} setSnList={setSnList} />
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <div className="rright">
        <p className="list-title">선택 수신자 리스트</p>
        <div className="filter">
          <Button
            buttonName="button-bgc-gray"
            buttonValue="선택 삭제"
            onChangePage={handelChoiceDelete}
          />
          <p className="ml10">
            전체 <span className="red bold">{selectCnt}</span>건
          </p>
        </div>
        <div className="area_body">
          <div className="table_overflow">
            <ReceiveAddressList
              data={rowData}
              setDelList={setDelList}
              handleDeleteNewUser={handleDeleteNewUser}
              setSelectCnt={setSelectCnt}
            />
          </div>
        </div>
        <p className="clear"></p>
      </div>
      <p className="clear"></p>
    </div>
  );
}

//문자보내기 - 저장문구 불러오기
export function LayerPopLoadMsg(props) {
  const selectType = props.selectType;
  const handleRetainMsgSet = props.handleRetainMsgSet;
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(3);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);

  const setLoading = props.setLoading;

  useEffect(() => {
    handleSearch();
  }, []);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleSearch = () => {
    handlePageChange(1);
  };

  const handlePageChange = (page) => {
    let url = "/message/retainMsgList";
    const data = {
      search1: selectType,
      search2: searchText,
      rowCount: rowCount,
      currentPage: page,
    };

    setLoading(true);
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows([]);
          if(jsonArr.length == 0) {
            alert("저장된 문구가 존재하지않습니다.");
          }

          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);

          const snNodeList = document.getElementsByName("checkDel");
          snNodeList.forEach((node) => {
            if (node.checked) node.checked = false;
          });
        } else {
          alert(result.resultMsg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSelectAll = (event) => {
    event.preventDefault();
    handleChoiceChecked(true);
  };

  const handleChoiceChecked = (chk) => {
    const snNodeList = document.getElementsByName("checkDel");
    snNodeList.forEach((node) => {
      if (chk) {
        if (!node.checked) node.checked = true;
      } else {
        if (node.checked) node.checked = false;
      }
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    handleDeleteEvent("1");
  };

  const handleEdit = (sn) => {
    const title = document.getElementById(sn).value;
    const mainText = document.getElementById("mainText" + sn).value;

    if (window.confirm("수정 하시겠습니까?")) {
      let url = "/message/retainMsgModify";
      const data = { search1: sn, search2: title, search3: mainText };
      setLoading(true);
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          handleSearch(pages);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleDeleteEvent = (type) => {
    let sn = [];
    let num = 0;
    const snNodeList = document.getElementsByName("checkDel");
    snNodeList.forEach((node) => {
      if (type === "1") {
        if (node.checked) {
          sn.push(node.value);
          num = num + 1;
        }
      } else {
        sn.push(node.value);
      }
    });

    if (sn.length === 0) {
      alert("선택된 문구가 없습니다.");
      return;
    }

    // 삭제
    if (
      window.confirm("선택된 문구를 삭제 하시겠습니까?\n 삭제 건수 : " + num)
    ) {
      let url = "/message/retainMsgDelete";
      const data = { snList: sn };
      setLoading(true);
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleSearch(1);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="gs-custom-input-btn-group bgc-none loadMsg">
        <input
          type="text"
          name=""
          defaultValue=""
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          value={searchText}
        />
        <Button
          buttonName="button-bgc-dark"
          buttonValue="검색"
          onChangePage={handleSearch}
        />
        <Button
          buttonName="button-bgc-gray"
          buttonValue="전체선택"
          onChangePage={handleSelectAll}
        />
        <Button
          buttonName="button-bgc-red"
          buttonValue="선택삭제"
          onChangePage={handleDelete}
        />
      </div>
      <ul className="loadMsgList col3-group">
        {tableRows.map((value) => (
          <li>
            <div className="msgTitle">
              <input type="checkbox" name="checkDel" value={value.mgSn}></input>
              <input type="text" id={value.mgSn} defaultValue={value.subject||''} key={value.mgSn}/>
            </div>
            <textarea className="msgBody preWrap" id={"mainText" + value.mgSn}>
              {value.advertYn === "Y" &&
              value.msgType !== "5" &&
              value.msgType !== "6" &&
              value.msgType !== "7"
                ? "(광고)" +
                  value.headerText +
                  "\n" +
                  value.mainText +
                  "\n무료거부 " +
                  value.footerText
                : value.mainText}
            </textarea>
            <div className="col2-group">
              <Button
                buttonName="button-bgc-dark"
                buttonValue="적용"
                onChangePage={(e) =>
                  handleRetainMsgSet(
                    value.headerText,
                    value.subject,
                    value.mainText,
                    value.footerText,
                    value.msgType,
                    value.advertYn
                  )
                }
              />
              <Button
                buttonName="button-bgc-gray"
                buttonValue="수정"
                onChangePage={(e) => handleEdit(value.mgSn)}
              />
            </div>
          </li>
        ))}
      </ul>
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export function LayerPopLoadRcsMms(props) {
  const channelName = props.channelName;
  const type = props.type;
  const setSelectedImgData = props.setSelectedImgData; //이미지 선택시 상태변경메소드
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const popupClose = props.popupClose;

  useEffect(() => {
    handlePageChange(1);
  }, []);
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handlePageChange = (page) => {
    const url = "/rcs/rcsImgList";
    const data = {
      rowCount: rowCount,
      currentPage: page,
      type: type,
      search1: channelName,
    };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <LoadRcsImg
        popupClose={popupClose}
        tableRows={tableRows}
        setSelectedImgData={setSelectedImgData}
      />
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export function LayerPopLoadFriendsTalkImg(props) {
  const channelName = props.channelName;
  const type = props.type;
  const setSelectedImgData = props.setSelectedImgData; //이미지 선택시 상태변경메소드
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const popupClose = props.popupClose;
  useEffect(() => {
    handlePageChange(1);
  }, []);
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handlePageChange = (page) => {
    const url = "/kko/kakaoImgList";
    const data = {
      rowCount: rowCount,
      currentPage: page,
      type: type,
      search3: channelName,
    };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <LoadFriendsTalkImg
        popupClose={popupClose}
        tableRows={tableRows}
        setSelectedImgData={setSelectedImgData}
      />
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

//내정보 - 계정정보 - 서브계정 - 관리
export function LayerPopSubAccountContract(props) {
  const custSn = props.data;
  const popupClose = props.popupClose;
  const mainData = props.main;
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;

  const [subInfoData, setSubInfoData] = useState([]);
  useEffect(() => {
    handleSearchPrce();
  }, []);
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleSearchPrce = () => {
    const url = "/user/subIdDetailPrce";
    const data = { custSn, custSn };
    handleLoadingOn();
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setSubInfoData(jsonArr[0]);
        }
        handleLoadingClose();
      })
      .catch((error) => alert(error));
  };
  const handleCancle = (event) => {
    event.preventDefault();
    popupClose();
  };
  const handleSave = (event) => {
    event.preventDefault();
    // 단가 비교
    const mSms = mainData.smsPrce;
    const sSms = document.getElementById("smsPrce").value;
    if (mSms > sSms) {
      alert("SMS 단가는 [ " + mSms + " ] 보다 낮은 금액은 지정할 수 없습니다.");
      return;
    }
    const mLms = mainData.lmsPrce;
    const sLms = document.getElementById("lmsPrce").value;
    if (mLms > sLms) {
      alert("LMS 단가는 [ " + mLms + " ] 보다 낮은 금액은 지정할 수 없습니다.");
      return;
    }
    const mMms = mainData.mmsPrce;
    const sMms = document.getElementById("mmsPrce").value;
    if (mMms > sMms) {
      alert("MMS 단가는 [ " + mMms + " ] 보다 낮은 금액은 지정할 수 없습니다.");
      return;
    }
    const mAlim = mainData.alimPrce;
    const sAlim = document.getElementById("alimPrce").value;
    if (mAlim > sAlim) {
      alert(
        "알림톡 단가는 [ " + mAlim + " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mFrd = mainData.frdPrce;
    const sFrd = document.getElementById("frdPrce").value;
    if (mFrd > sFrd) {
      alert(
        "친구톡 단가는 [ " + mFrd + " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mFrdImg = mainData.frdImgPrce;
    const sFrdImg = document.getElementById("frdImgPrce").value;
    if (mFrdImg > sFrdImg) {
      alert(
        "친구톡 이미지 단가는 [ " +
          mFrdImg +
          " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mFrdWideImg = mainData.frdWideImgPrce;
    const sFrdWideImg = document.getElementById("frdWideImgPrce").value;
    if (mFrdWideImg > sFrdWideImg) {
      alert(
        "친구톡 와이드 이미지 단가는 [ " +
          mFrdWideImg +
          " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mRcsSms = mainData.rcsSmsPrce;
    const sRcsSms = document.getElementById("rcsSmsPrce").value;
    if (mRcsSms > sRcsSms) {
      alert("RCS-SMS [ " + mRcsSms + " ] 보다 낮은 금액은 지정할 수 없습니다.");
      return;
    }
    const mRcsLms = mainData.rcsLmsPrce;
    const sRcsLms = document.getElementById("rcsLmsPrce").value;
    if (mRcsLms > sRcsLms) {
      alert(
        "RCS-LMS 단가는 [ " +
          mRcsLms +
          " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mRcsMms = mainData.rcsMmsPrce;
    const sRcsMms = document.getElementById("rcsMmsPrce").value;
    if (mRcsMms > sRcsMms) {
      alert(
        "RCS-MMS 단가는 [ " +
          mRcsMms +
          " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    const mRcsTmpl = mainData.rcsTmplPrce;
    const sRcsTmpl = document.getElementById("rcsTmplPrce").value;
    if (mRcsTmpl > sRcsTmpl) {
      alert(
        "RCS-MMS 단가는 [ " +
          mRcsTmpl +
          " ] 보다 낮은 금액은 지정할 수 없습니다."
      );
      return;
    }
    // popupClose
    if (window.confirm("단가를 변경 하시겠습니까?")) {
      const url = "/user/subPrceModify";
      const data = {
        custSn,
        custSn,
        smsPrce: sSms,
        lmsPrce: sLms,
        mmsPrce: sMms,
        alimPrce: sAlim,
        frdPrce: sFrd,
        frdImgPrce: sFrdImg,
        frdWideImgPrce: sFrdWideImg,
        rcsSmsPrce: sRcsSms,
        rcsLmsPrce: sRcsLms,
        rcsMmsPrce: sRcsMms,
        rcsTmplPrce: sRcsTmpl,
      };
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            popupClose();
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      {/* <div>
              <p>내 계약 단가</p>
              <ContractTable />
          </div> */}
      <div>
        <p style={{fontWeight: "bold"}}>서브 계정 : {subInfoData.custId}</p>
        <SubAccountPrice data={subInfoData} main={mainData} />
      </div>
      <div className="button-wrap center">
        <Button
          buttonName="button-border-gray"
          buttonValue="취소"
          onChangePage={handleCancle}
        />
        {mainData.custGrade === "2" ? (
          <Button
            buttonName="button-bgc-blue"
            buttonValue="저장"
            onChangePage={handleSave}
          />
        ) : null}
      </div>
    </>
  );
}

//마이페이지 - 계약정보 - 후불계정생성 요청
export function LayerPopRequestForPayLater(props) {
  const handlePopClose = props.onClose;
  const handleIsRequest = props.isRequest;
  const [accountData, setAccountData] = useState({
    arNm: "",
    arPhone: "",
    arEmail: "",
    arSdKind: "",
    arRemark: "",
  });
  const handleContentChange = (e) => {
    setAccountData({
      ...accountData,
      [e.target.name]: e.target.value,
    });
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleRequestAccount = (event) => {
    const regexPhone = /^(0(\d{1,2}))(\d{3,4})(\d{4})$/;
    const regexEmail =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (accountData.arNm == "") {
      alert("담당자명을 입력해주세요.");
      return;
    } else if (accountData.arPhone == "") {
      alert("연락처를 입력해주세요.");
      return;
    } else if (!regexPhone.test(accountData.arPhone)) {
      alert("잘못된 형식의 번호 입니다.");
      return;
    } else if (accountData.arEmail == "") {
      alert("이메일을 입력해주세요.");
      return;
    } else if (!regexEmail.test(accountData.arEmail)) {
      alert("잘못된 형식의 이메일 입니다.");
      return;
    } else if (accountData.arRemark == "") {
      alert("연락 가능 시간대를 입력해주세요");
      return;
    }
    if (window.confirm("후불계정을 요청 하시겠습니까?")) {
      const url = "/user/requestAccount";
      const data = accountData;
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          handleIsRequest(true);
          handlePopClose(false);
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div className="graybox center">
        요청하신 내용을 Hisms 담당자가 확인 후 연락드리겠습니다.
      </div>
      <RequestForPayLaterTable
        handleContentChange={handleContentChange}
        setAccountData={setAccountData}
        accountData={accountData}
      />
      <div className="button-wrap center">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="요청완료"
          onChangePage={handleRequestAccount}
        />
      </div>
    </>
  );
}

//RCS - 템플릿등록
export function LayerPopRCSTemplatePreview(props) {
  const style = {
    area: {
      position: "absolute",
      left: "15%",
      top: "80px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "70%",
      minHeight: "50px",
      maxHeight: "480px",
      padding: "15px",
      color: "#4a4a4a",
      overflow: "hidden",
      fontSize: "15px",
      lineHeight: "1.4",
    },
    buttonWrap: {
      width: "100%",
      marginTop: "10px",
    },
    button: {
      width: "100%",
      padding: "8px 0",
      textAlign: "center",
      backgroundColor: "#f5f4f6",
      color: "#222",
      marginBottom: "10px",
      borderRadius: "3px",
      fontSize: "14px",
    },
    freeRefusal: {
      textDecoration: "underline",
      color: "#625af6",
      marginLeft: "5px",
    },
  };

  const [tableRows, setTableRows] = useState("");
  const [btnRows, setBtnRows] = useState("");

  useEffect(() => {
    const jsonArr = props.body;
    const rows = [];
    if (jsonArr.length === 0) {
    } else {
      for (const row of jsonArr) {
        rows.push(
          <div>
            <div dangerouslySetInnerHTML={{ __html: row }}></div>
          </div>
        );
      }
    }
    setTableRows(rows);

    const btnArray = props.btn;
    const btnRows = [];
    if (jsonArr.length === 0) {
    } else {
      for (const row of btnArray) {
        btnRows.push(
          <ul style={style.buttonWrap}>
            <div style={style.button}>{row.btnName}</div>
          </ul>
        );
      }
    }
    setBtnRows(btnRows);
  }, [props.body, props.btn]);

  return (
    <div className="pop_preview">
      <div className="relative">
        <div style={style.area}>
          <p style={style.content}>
            {tableRows}
            {btnRows}
          </p>
        </div>
      </div>
    </div>
  );
}

//일반관리 - 발신번호 관리 - 발신번호등록
export function LayerPopAddSendNumber(props) {
  const style = {
    button: {
      width: "100%",
    },
  };
  const popupClose = props.popupClose;
  const handlePageChoice = props.handlePageChoice;
  const onEditUser = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <AddSendNumber
        size="small"
        authType={"R"}
        popupClose={popupClose}
        handlePageChoice={handlePageChoice}
      />
    </>
  );
}

//일반관리 - 발신번호 관리 - 재인증
export function LayerPopTryAuthSendNumber(props) {
  const style = {
    button: {
      width: "100%",
    },
  };

  const popupClose = props.popupClose;
  const handlePageChoice = props.handlePageChoice;
  const number = props.number;
  const cbKind = props.cbKind;
  const onEditUser = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <TryAuthSendNumber
        size="small"
        authType={"T"}
        popupClose={popupClose}
        handlePageChoice={handlePageChoice}
        number={number}
        cbKind={cbKind}
      />
    </>
  );
}

//문자보내기 - 수신번호 - 엑셀 대량전송
export function LayerPopReceiveNumber(props) {
  const uploadPopClose = props.uploadPopClose;
  const modifyPopClose = props.modifyPopClose;
  const handleDeleteNewFile = props.handleDeleteNewFile;
  const selectIdx = props.selectIdx;
  const uploadType = props.uploadType;
  const handleRegId = props.handleRegId;
  useEffect(() => {
    const data = props.data;
    if (data && data.length > 0) {
      setUploadId(data);
      setOpenType("1");
    }
  }, []);

  const loadFile = (event) => {
    event.preventDefault();
    setPopup(true);
  };
  const downloadSample = (event) => {
    event.preventDefault();
    let fileName = "문자전송_Sample.xlsx";
    axios({
      method: "POST",
      url: SEVER_DOMAIN + "/file/download/",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
        "Content-Type": "application/json",
      },
      data: {
        sn: 3,
      },
    }).then((res) => {
      let blob = new Blob([res.data], { type: res.headers["content-type"] });

      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // not IE
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_self";
        if (fileName) link.download = fileName;
        link.click();
      }
    });
  };

  const selectListDelete = (event) => {
    event.preventDefault();
    if (snList.length === 0) {
      alert("삭제할 항목이 선택되지 않았습니다.");
      return;
    }

    if (
      window.confirm(
        "선택항목을 삭제 하시겠습니까? [ 선택항목 수 : " + snList.length + " ]"
      )
    ) {
      const url = "/message/tempDelete";
      const data = { search1: uploadId, search2: "2", snList: snList };

      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            handleSearch();
            setDeleteCnt(snList.length);

            const snNodeList = document.getElementsByName("checkDel");
            if (openType === "1") {
              if (snNodeList.length === 0) {
                handleDeleteNewFile(selectIdx);
                handlePopClose();
              } else {
                modifyPopClose(
                  uploadId,
                  snNodeList.length,
                  snList.length,
                  uploadType
                );
              }
            }
          }
        })
        .catch((error) => alert(error));
    }
  };
  const AllDelete = (event) => {
    event.preventDefault();

    if (window.confirm("업로드한 데이터를 전부 삭제 하시겠습니까?")) {
      const url = "/message/tempDelete";
      const data = { search1: uploadId, search2: "1" };

      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            setTableRows([]);
            setDeleteCnt(uploadCnt);
            setUploadCnt(0);
            if (openType === "1") {
              handleDeleteNewFile(selectIdx); //modifyPopClose(uploadId, 0 , snNodeList.length);
              handlePopClose();
            }
          }
        })
        .catch((error) => alert(error));
    }
  };
  const handlePopClose = props.handlePopClose;
  const handleCancle = (event) => {
    event.preventDefault();
    handlePopClose();
  };
  const handleAdd = (event) => {
    event.preventDefault();

    if (uploadCnt === 0) {
      alert("추가할 데이터가 없습니다.");
      return;
    }
    let msg = "추가";
    if (openType === "1") msg = "변경";

    if (window.confirm("업로드한 데이터를 " + msg + " 하시겠습니까?")) {
      if (openType === "1")
        modifyPopClose(uploadId, uploadCnt, deleteCnt, uploadType);
      else uploadPopClose(uploadId, uploadCnt, uploadType);
    }
  };

  const [deleteCnt, setDeleteCnt] = useState(0);
  const [uploadFile, setUploadFile] = useState(null);
  const [popup, setPopup] = useState(false);
  const [uploadId, setUploadId] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [snList, setSnList] = useState([]);
  const [uploadCnt, setUploadCnt] = useState(0);
  const [openType, setOpenType] = useState(0);

  const setFile = (uploadFile) => {
    setUploadFile(uploadFile);
  };
  const setPopupClose = () => {
    setPopup(false);
  };

  const setRegId = (regId) => {
    setUploadId(regId);
    handleRegId(regId);
  };

  const setDelList = (delList) => {
    setSnList(delList);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleSearch();
  }, [uploadId]);

  const handleSearch = () => {
    const url = "/message/tempList";
    const data = { search1: uploadId };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          setUploadCnt(jsonArr.length);
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <div className="receive-number-wrap">
      {openType === 0 && (
        <div>
          불러오기 가능한 파일 형식은 xls, xlsx 입니다. (최대 10만건)
          <br />
          셀서식의 표시형식은 텍스트로 이용해 주세요.
        </div>
      )}
      <div className="button-wrap">
        {openType === 0 ? (
          <Button
            buttonName="button-bgc-dark"
            buttonValue="파일 불러오기"
            onChangePage={loadFile}
          />
        ) : null}
        <Button
          buttonName="button-border-dark"
          buttonValue="샘플파일 다운로드"
          onChangePage={downloadSample}
        />
      </div>
      <div className="receive-number-list">
        <LoadReceiveNumberList
          data={tableRows}
          snList={snList}
          setSnList={setSnList}
        />
      </div>
      <div className="button-wrap clearfix">
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="삭제"
          onChangePage={selectListDelete}
        />
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="전체삭제"
          onChangePage={AllDelete}
        />
      </div>
      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="추가"
          onChangePage={handleAdd}
        />
      </div>

      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="파일 업로드">
          <LayerPopMessageFileUpload
            setRegId={setRegId}
            setPopupClose={setPopupClose}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

//문자보내기 - 수신번호 - 오류상세
export function LayerPopReceiveNumberError() {
  const loadFile = (event) => {
    event.preventDefault();
  };
  const downloadSample = (event) => {
    event.preventDefault();
  };
  const selectListDelete = (event) => {
    event.preventDefault();
  };
  const AllDelete = (event) => {
    event.preventDefault();
  };
  const handleCancle = (event) => {
    event.preventDefault();
  };
  const handleEdit = (event) => {
    event.preventDefault();
  };
  return (
    <div className="receive-number-wrap">
      <div className="mb10">목록을 삭제하시면 변경된 내용이 반영됩니다.</div>
      <div className="receive-number-list">
        <LoadReceiveNumberErrorList />
      </div>
      <div className="button-wrap clearfix">
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="삭제"
          onChangePage={selectListDelete}
        />
        <Button
          buttonName="button-border-gray sbtn"
          buttonValue="전체삭제"
          onChangePage={AllDelete}
        />
      </div>
      <div className="popup-bottom-button">
        <Button buttonName="" buttonValue="취소" onChangePage={handleCancle} />
        <Button
          buttonName="confirm"
          buttonValue="수정"
          onChangePage={handleEdit}
        />
      </div>
    </div>
  );
}

//주소록 - 그룹추가(등록)
export function LayerPopAddGroup(props) {
  const popupAddGroup = props.popupAddGroup;
  const handleReload = props.handleReload;
  const groupNm = props.groupNm;
  const groupSn = props.groupSn;
  const agType = props.agType;
  const [groupCheck, setGroupCheck] = useState(false);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleGroupCheck = (check) => {
    setGroupCheck(check);
  };

  const handleCancle = (event) => {
    event.preventDefault();
    popupAddGroup(false);
  };
  const handleSave = (event) => {
    event.preventDefault();
    if (!groupCheck) {
      alert("그룹명 중복체크를 하세요.");
      return;
    }

    const addrGroupNm = document.getElementById("addGroupName").value;
    if (
      window.confirm(
        "[ 그룹명 : " +
          addrGroupNm +
          " ( 상위그룹명 : " +
          groupNm +
          " ) ] 을 등록하시겠습니까? "
      )
    ) {
      const url = "/addr/addrGroupSave";
      const data = { agSn: groupSn, agType: agType, agNm: addrGroupNm };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleReload(0);
            popupAddGroup(false);
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div>
        <p className="red">※ 새로운 그룹을 등록할 수 있습니다.</p>
        <AddGroup
          groupNm={groupNm}
          groupSn={groupSn}
          agType={agType}
          handleGroupCheck={handleGroupCheck}
        />
        <div className="popup-bottom-button">
          <Button
            buttonName="confirm"
            buttonValue="저장"
            onChangePage={handleSave}
          />
          <Button
            buttonName=""
            buttonValue="닫기"
            onChangePage={handleCancle}
          />
        </div>
      </div>
    </>
  );
}

//주소록 - 그룹수정
export function LayerPopEditGroup(props) {
  const handleEditGroupNm = props.handleEditGroupNm;
  const popupEditGroup = props.popupEditGroup;
  const handleReload = props.handleReload;
  const groupNm = props.groupNm;
  const groupSn = props.groupSn;
  const groupUpSn = props.groupUpSn;
  const [groupCheck, setGroupCheck] = useState(false);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleGroupCheck = (check) => {
    setGroupCheck(check);
  };
  const handleCancle = (event) => {
    event.preventDefault();
    popupEditGroup(false);
  };
  const handleSave = (event) => {
    event.preventDefault();
    if (!groupCheck) {
      alert("그룹명 중복체크를 하세요.");
      return;
    }

    const editGroupName = document.getElementById("editGroupName").value;
    if (
      window.confirm(
        "[ 그룹명 : " + editGroupName + " ] 으로 수정하시겠습니까? "
      )
    ) {
      const url = "/addr/addrGroupModify";
      const data = { agSn: groupSn, agType: "1", agNm: editGroupName };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleEditGroupNm(editGroupName);
            handleReload(0);
            popupEditGroup(false);
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div>
        <p className="red">※ 기존에 등록한 그룹의 이름을 변경할 수 있습니다.</p>
        <EditGroup
          groupNm={groupNm}
          groupSn={groupSn}
          groupUpSn={groupUpSn}
          handleGroupCheck={handleGroupCheck}
        />
        <div className="popup-bottom-button">
          <Button
            buttonName="confirm"
            buttonValue="저장"
            onChangePage={handleSave}
          />
          <Button
            buttonName=""
            buttonValue="닫기"
            onChangePage={handleCancle}
          />
        </div>
      </div>
    </>
  );
}

//주소록 - 그룹 주소록 이동
export function LayerPopMoveGroup(props) {
  const groupPathNm = props.groupPathNm;
  const groupNm = props.groupNm;
  const groupSn = props.groupSn;
  const groupUpSn = props.groupUpSn;
  const groupDepth = props.groupDepth;
  const groupCnt = props.groupCnt;
  const popupMoveGroup = props.popupMoveGroup;
  const handleReload = props.handleReload;
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;
  const [groupList, setGroupList] = useState([]);
  const addrType = props.addrType;

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleSearchGroup();
  }, []);

  const handleSearchGroup = () => {
    const url = "/addr/groupPathList";
    const data = { agSn: groupSn, agType: addrType, upperAgSn: groupUpSn };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setGroupList(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCancle = (event) => {
    event.preventDefault();
    popupMoveGroup(false);
  };
  const handleMove = (event) => {
    event.preventDefault();
    const agSn = document.getElementById("groupListMove").value;

    if (window.confirm("주소록을 이동 하시겠습니까? ")) {
      const url = "/addr/groupMove";
      const data = {
        agSn: groupSn,
        agType: "1",
        upperAgSn: agSn,
        agNm: groupNm,
      };

      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleReload(0);
            popupMoveGroup(false);
            handleLoadingClose();
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div>
        <p className="red">※ 주소록을 다른 그룹으로 이동시킬 수 있습니다.</p>
        <MoveGroup
          groupList={groupList}
          groupDepth={groupDepth}
          groupPathNm={groupPathNm}
          groupCnt={groupCnt}
        />
        <div className="popup-bottom-button">
          <Button
            buttonName="confirm"
            buttonValue="저장"
            onChangePage={handleMove}
          />
          <Button
            buttonName=""
            buttonValue="닫기"
            onChangePage={handleCancle}
          />
        </div>
      </div>
    </>
  );
}

//주소록 - 그룹 주소록 복사
export function LayerPopCopyGroup(props) {
  const groupPathNm = props.groupPathNm;
  const groupNm = props.groupNm;
  const groupSn = props.groupSn;
  const groupUpSn = props.groupUpSn;
  const groupDepth = props.groupDepth;
  const groupCnt = props.groupCnt;
  const popupCopyGroup = props.popupCopyGroup;
  const handleReload = props.handleReload;
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;
  const agType = props.agType;
  const [groupList, setGroupList] = useState([]);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleSearchGroup();
  }, []);

  const handleSearchGroup = () => {
    const url = "/addr/groupPathList";
    const data = { agSn: groupSn, agType: agType, upperAgSn: groupUpSn };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setGroupList(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleCancle = (event) => {
    event.preventDefault();
    popupCopyGroup(false);
  };
  const handleCopy = (event) => {
    event.preventDefault();
    const agSn = document.getElementById("groupListCopy").value;

    if (window.confirm("주소록을 복사 하시겠습니까? ")) {
      const url = "/addr/groupCopy";
      const data = {
        agSn: groupSn,
        agType: "1",
        upperAgSn: agSn,
        agNm: groupNm,
      };
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleReload(0);
            popupCopyGroup(false);
            handleLoadingClose();
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div>
        <p className="red">※ 주소록을 다른 그룹으로 복사할 수 있습니다.</p>
        <CopyGroup
          groupList={groupList}
          groupDepth={groupDepth}
          groupPathNm={groupPathNm}
          groupCnt={groupCnt}
        />
        <div className="popup-bottom-button">
          <Button
            buttonName="confirm"
            buttonValue="저장"
            onChangePage={handleCopy}
          />
          <Button
            buttonName=""
            buttonValue="닫기"
            onChangePage={handleCancle}
          />
        </div>
      </div>
    </>
  );
}

//주소록 - 중복번호/이름 검색
export function LayerPopDuplicateSearch(props) {
  const popupPopDuplicate = props.popupPopDuplicate;
  const handleReload = props.handleReload;
  const groupSn = props.groupSn;
  const custGrade = props.custGrade;
  const userType = props.userType;
  const addrType = props.addrType;
  const agType = props.agType;
  const test = props.test;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [totalCount, setTotalCount] = useState(0);
  const [snList, setSnList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose =props.handleLoadingClose;


  const handleCancle = (event) => {
    event.preventDefault();
    popupPopDuplicate(false);
  };
  const handleDelete = (event) => {
    event.preventDefault();

    const num = snList.length;

    if (num === 0) {
      alert("선택된 연락처가 없습니다.");
      return;
    }

    if (
      window.confirm("선택된 연락처를 삭제 하시겠습니까?\n 삭제 건수 : " + num)
    ) {
      const url = "/addr/addrDel";
      const data = { snList: snList };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleDuplicateSearch();
            handleReload(-num);
          }
        })
        .catch((error) => alert(error));
    }
  };
  const setDelList = (val) => {
    setSnList(val);
  };

  const [chekedDefault, setChekedDefault] = React.useState(1);
  const filter = [
    { id: 1, value: "이름" },
    { id: 2, value: "연락처" },
  ];

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  useEffect(() => {
    handleDuplicateSearch(1);
  }, [chekedDefault]);

  const handleDuplicateSearch = (page) => {
    const url = "/addr/groupDuplicateList";
    const data = {
      agSn: groupSn,
      agType: agType,
      type: chekedDefault,
      addrType: addrType,
      rowCount: rowCount,
      currentPage: page,
      custGrade: sessionStorage.getItem("custType")
    };
    handleLoadingOn();
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          const pageItem = JSON.parse(result.resultPage);
          setTotalCount(pageItem.totalCount);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);
          handleLoadingClose()
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <>
      <div>
        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">검색 조건</div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {filter.map((value) => (
                    <>
                      <input
                        type="radio"
                        name="filter"
                        id={value.id}
                        checked={chekedDefault === value.id}
                        onClick={function (e) {
                          setChekedDefault(value.id);
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </>
                  ))}
                </div>
                {custGrade === "1" && userType === "1" ? (
                  <Button
                    buttonName="confirm"
                    buttonValue="선택삭제"
                    onChangePage={handleDelete}
                  />
                  ) : null}
                  {addrType === "P" ? (
                  <Button
                    buttonName="confirm"
                    buttonValue="선택삭제"
                    onChangePage={handleDelete}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <DuplicateSearch
          data={tableRows}
          setDelList={setDelList}
          handleDuplicateSearch={handleDuplicateSearch}
          handleReload={handleReload}
          custGrade={custGrade}
          userType={userType}
          addrType={addrType}
          totalCount={totalCount}
        />  
        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handleDuplicateSearch}
        />
      </div>
    </>
  );
}

//주소록 - 주소록 상세정보 수정(개인)
export function LayerPopEditAddressInfo(props) {
  const rowData = props.rowData;
  const popupClose = props.popupClose;
  const handleCurrent = props.handleCurrent;
  const handleCancle = (event) => {
    event.preventDefault();
    popupClose();
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleUpdate = (event) => {
    event.preventDefault();
    const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/;

    const nm = document.getElementById("manualNm").value;
    if (!nm) {
      alert("이름은 필수입력사항 입니다.");
      return;
    }

    const phone = document.getElementById("manualPhone").value;
    if (!phone) {
      alert("휴대폰번호는 필수입력사항 입니다.");
      return;
    } else if (!regexMobile.test(phone)) {
      alert("잘못된 형식의 휴대폰번호 입니다.");
      return;
    }

    const etc1 = document.getElementById("manualEtc1").value;
    const etc2 = document.getElementById("manualEtc2").value;
    const etc3 = document.getElementById("manualEtc3").value;
    const etc4 = document.getElementById("manualEtc4").value;

    if (window.confirm("입력된 정보를 수정하시겠습니까?")) {
      const url = "/addr/addrModify";
      const data = {
        abSn: rowData.sn,
        abNm: nm,
        abPhone: phone,
        abEtc1: etc1,
        abEtc2: etc2,
        abEtc3: etc3,
        abEtc4: etc4,
      };

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleCurrent();
            popupClose();
          }
        })
        .catch((error) => alert(error));
    }
  };
  return (
    <>
      <div>
        <p className="red">※ 상세정보를 수정할 수 있습니다.</p>
        <EditAddressInfo rowData={rowData} />
        <div className="popup-bottom-button">
          <Button
            buttonName="confirm"
            buttonValue="수정"
            onChangePage={handleUpdate}
          />
          <Button
            buttonName=""
            buttonValue="취소"
            onChangePage={handleCancle}
          />
        </div>
      </div>
    </>
  );
}

//발신번호 관리 - 첨부문서 확인
export function LayerPopCheckAttachments() {
  const style = {
    content: {
      width: "100%",
      padding: "15px",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
    },
  };
  const handleDownload = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="mb20">
        <div className="gs-custom-input-btn-group bgc-none">
          <input type="text" name="" defaultValue="재직증명서" disabled />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="다운로드"
            onChangePage={handleDownload}
          />
        </div>
        <div style={style.content}>재직증명서.pdf</div>
        <div className="gs-custom-input-btn-group bgc-none mt10">
          <input type="text" name="" defaultValue="가입사실증명원" disabled />
          <Button
            buttonName="button-bgc-dark"
            buttonValue="다운로드"
            onChangePage={handleDownload}
          />
        </div>
        <div style={style.content}>가입사실증명원.pdf</div>
      </div>
    </>
  );
}

// 우편번호 검색
export function LayerPopPostCode(props) {
  const address = props.address;
  const setAddress = props.setAddress;
  const setPopupPostCode = props.setPopupPostCode;

  const handlePostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") extraAddress += data.bname;

      if (data.buildingName !== "")
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;

      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setAddress([data.zonecode, fullAddress]);

    props.onClose();
  };

  const style = {
    area: {
      position: "relative",
      left: "15%",
      top: "10px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "100%",
      minHeight: "50px",
      maxHeight: "200px",
      padding: "7px",
      color: "#4a4a4a",
      fontSize: "10px",
      lineHeight: "1.4",
      display: "block",
    },
  };

  return (
    <div className="pop_preview">
      <div className="postCodeForm">
        <DaumPostcode style={style} onComplete={handlePostCode} />
      </div>
    </div>
  );
}

export function LayerPopFileUpload(props) {
  const setPopupClose = props.setPopupClose;
  const setFile = props.setFile;
  const setFileName = props.setFileName;
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setPopupClose();
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>엑셀파일</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}

export function LayerPopExcelUpload(props) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const setPopupClose = props.setPopupClose;
  const handleReload = props.handleReload;
  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;
  const sn = props.sn;
  const nm = props.nm;

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleUpload = (e) => {
    if (!file) {
      alert("업로드할 엑셀파일을 선택해주세요.");
      return;
    }

    if (
      window.confirm(
        "[ 그룹명 : " + nm + " ] 에 엑셀 데이터를 업로드 하시겠습니까?"
      )
    ) {
      const url = "/addr/addrUpload";
      const formData = new FormData();

      formData.append("file", file);
      formData.append("agSn", sn);

      const data = formData;
      handleLoadingOn();
      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
          if (result.resultCode === "0") {
            handleReload(result.resultData);
            setPopupClose();
          }
          handleLoadingClose();
        })
        .catch((error) => handleLoadingClose());
    }
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>엑셀파일</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="excelFile"
                  id="excelFile"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="등록하기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}

export function LayerPopMessageFileUpload(props) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const setPopupClose = props.setPopupClose;
  const setRegId = props.setRegId;

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleUpload = (e) => {
    if (!file) {
      alert("불러올 파일을 선택해주세요.");
      return;
    }

    if (window.confirm("파일을 불러오시겠습니까?")) {
      const url = "/message/fileUpload";
      const formData = new FormData();

      formData.append("file", file);

      const data = formData;

      getPostFetch(url, data)
        .then((result) => {
          if (result.resultCode === "0") {
            const regId = result.resultData;
            setRegId(regId);
            setPopupClose();
          } else {
            alert(result.resultMsg);
          }
        })
        .catch((error) => alert(error));
    }
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>엑셀파일</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="excelFile"
                  id="excelFile"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="불러오기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}

export function LayerPopMessageImgUpload(props) {
  const setImgFile = props.setImgFile;
  const setImageUrl = props.setImageUrl;
  const setImageFileName = props.setImageFileName;
  const closeImagePop = props.closeImagePop;

  const [img, setImg] = useState(null);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    setImgFile(imageFile);
    setImageUrl(URL.createObjectURL(imageFile));
    setImageFileName(imageFile.name);
    closeImagePop();
  };

  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>이미지</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}

export function LayerPopTestCardPay(props) {

  const style = {
    subTitle: {
      fontWeight: "500",
      fontSize: "16px",
    },
    subTitle2: {
      fontWeight: "600",
      fontSize: "22px",
      color: "#625af6",
    },
    bigTitleFirst: {
      fontSize: "26px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    bigTitle: {
      fontSize: "26px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    smallTitleFirst: {
      fontSize: "14px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    smallTitleNormal: {
      fontSize: "13px",
      color: "#444",
      fontWeight: "500",
      marginTop: "3px",
    },
    smallTitle: {
      fontSize: "14px",
      color: "#625af6",
      fontWeight: "600"
    }
  };


  const [money, setMoney] = useState(null);
  const [payInfo, setPayInfo] = useState(null);
  const [payUse, setPayUse] = useState("N");
  const [reset, setReset] = useState(null);

  const testCardPay = props.testCardPay;
  const setDataCheck = props.setDataCheck;
  const handlePopClose = props.onClose;

  const handleCancle = (event) => {
    event.preventDefault();
    handlePopClose();
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleChange = (e) => {
    setMoney(e);
    setPayUse("N");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://stdpay.inicis.com/stdjs/INIStdPay.js";
    script.async = true;
    script.charset = "UTF-8";
    document.body.appendChild(script);
  }, [payUse]);

  const handleChargeBtn = (e) => {
    if (money === null) {
      alert("충전 금액을 선택하세요.");
      return;
    }

    if (money < 1) {
      alert("1원 이상을 입력하세요.");
      return;
    }

      let data1 = "";
      setPayUse("Y");
      getFetch("/pay/Card/" + money + "/req")
        .then((result) => {
          data1 = result;
          for (const key in data1) {
            setPayInfo(data1);
            setReset(false);
            testCardPay(false);
            setPayUse("N");
            ReactDOM.render(
              <PayForm name="payForm" formData={data1} />,
              document.getElementById("payRender")
            );
            setDataCheck(data1);
          }
        })

        .catch(function (error) {
          alert(error);
        });
    
  };
  return (
    <>
    <p style={style.subTitle2}>카드결제를 진행합니다.</p>
      <p style={style.subTitle}>충전할 금액을 선택하고 다음 버튼을 클릭해주세요.</p>
      <br></br>
      <div style={style.smallTitleNormal}>
        <select style={{width:"200px", textAlign: "left", fontSize: "15px", backgroundColor:""}}  onChange={(e) => {handleChange(e.target.value);}}>
          <option defaultValue="" hidden>선택하세요.</option>
          {/* <option style={style.smallTitleNormal} value="10000">10,000</option>
          <option style={style.smallTitleNormal} value="30000">30,000</option>
          <option style={style.smallTitleNormal} value="50000">50,000</option>
          <option style={style.smallTitleNormal} value="100000">100,000</option>
          <option style={style.smallTitleNormal} value="300000">300,000</option>
          <option style={style.smallTitleNormal} value="500000">500,000</option>
          <option style={style.smallTitleNormal} value="1000000">1,000,000</option> */}
          <option style={style.smallTitleNormal} value="10000">10,000원</option>
          <option style={style.smallTitleNormal} value="30000">30,000원</option>
          <option style={style.smallTitleNormal} value="50000">50,000원</option>
          <option style={style.smallTitleNormal} value="100000">100,000원</option>
          <option style={style.smallTitleNormal} value="300000">300,000원</option>
          <option style={style.smallTitleNormal} value="500000">500,000원</option>
          <option style={style.smallTitleNormal} value="1000000">1,000,000원</option>
          <option style={style.smallTitleNormal} value="3000000">3,000,000원</option>
          <option style={style.smallTitleNormal} value="5000000">5,000,000원</option>
        </select>
      </div>
      <br></br>
      <div style={{float: "right"}}>
      <Button style={{width: "300px", height: "40px", borderRadius: "5px", backgroundColor: "#555",}} 
              buttonName="cm-highlight-btn"
              buttonValue="닫기"
              onChangePage={handleCancle}
      ></Button>
      <Button style={{width: "300px", height: "40px", borderRadius: "5px", marginLeft: "10px", backgroundColor: "#625af6",}} 
              buttonName="cm-highlight-btn"
              buttonValue="다음"
              onChangePage={handleChargeBtn}
      ></Button>
      </div>
    </>
  );
}

export const PayForm = (props) => {
  const formData = props.formData;

  return (
    <div id="payForm" name="payForm">
      <form id="send_pay_form" method="POST">
        {Object.keys(formData).map((item, index) => (
          <input
            type="hidden"
            name={item}
            value={formData[item] ? formData[item] : ""}
            key={index}
          />
        ))}
      </form>
    </div>
  );
};

export function LayerPopRcsImgUpload(props) {
  const [tableRows, setTableRows] = useState("");
  const [type, setType] = useState("");
  const [channelId, setChannelId] = useState("");
  const [senderKey, setSenderKey] = useState("");
  const handleReSearch = props.handleReSearch;
  useEffect(() => {
    const jsonArr = props.data;
    const rows = [];
    if (!jsonArr || jsonArr.length === 0) {
      rows.push(<option value="">채널없음</option>);
    } else {
      setSenderKey(jsonArr[0].senderKey);
      setChannelId(jsonArr[0].plusId);
      for (const row of jsonArr) {
        const stat = row.pfStatus;
        rows.push(<option value={row.senderKey}>{row.plusId}</option>);
      }
    }
    setTableRows(rows);
    setType(props.type);
  }, [props.data]);
  const handleChannel = (e) => {
    setChannelId(e.target[e.target.selectedIndex].text);
    setSenderKey(e.target.value);
  };
  const [img, setImg] = useState([]);
  const handleChange = (e) => {
    let as = e.target.files;
    let array = [];

    for (let i = 0; i < e.target.files.length; i++) {
      array.push(e.target.files[i]);
      setImg(array);
    }
  };
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleUpload = () => {
    const formData = new FormData();
    if (!img) {
      alert("선택된 이미지가 없습니다.");
      return;
    }

    for (let i = 0; i < img.length; i++) {
      formData.append("file", img[i]);
    }

    const url = "/rcs/rcsImageUpload";
    const data = formData;
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg);
        if (result.resultCode === "0") {
          handleReSearch();
        }
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>이미지</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      <div className="button-wrap center mb0">
        <Button
          buttonName="button-bgc-blue"
          buttonValue="등록하기"
          onChangePage={handleUpload}
        />
      </div>
    </>
  );
}

export function LayerPopRequestAccount(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [loading, setLoading] = useState(0);

  const handleLoadingOn = props.handleLoadingOn;
  const handleLoadingClose = props.handleLoadingClose;

  const popupClose = props.popupClose;
  useEffect(() => {
    handlePageChange(1);
  }, []);
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handlePageChange = (page) => {
    const url = "/user/requestAccountList";
    const data = {
      rowCount: rowCount,
      currentPage: page,
    };
    handleLoadingOn();
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
        handleLoadingClose();
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <RequestAccountTable popupClose={popupClose} tableRows={tableRows} />
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export function LayerPopCommentFileUpload(props) {
  const setPopupClose = props.setPopupClose;
  const setFile = props.setFile;
  const setFileName = props.setFileName;
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setPopupClose();
  };
  return (
    <>
      <table className="table_style1">
        <thead></thead>
        <tbody>
          <tr>
            <th>파일</th>
            <td>
              <div class="gs-custom-input-btn-group">
                <input
                  type="file"
                  name="fileImg"
                  id="fileImg"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}

export function LayerPopNewUserGuide() {

  const style = {
    graybox: {
      backgroundColor: "#f7f7f7",
      padding: "25px",
      margin: "20px 0",
      fontSize: "18px",
      width: "100%",
      "& li": {
        fontWeight: "300",
        marginBottom: "30px",
        fontSize: "16px",
      },
    },
    subTitle: {
      fontWeight: "600",
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.4",
    },
    conbox: {
      marginBottom: "30px",
    },
    bigTitleFirst: {
      fontSize: "26px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    bigTitle: {
      fontSize: "26px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    smallTitleFirst: {
      fontSize: "14px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    smallTitleNormal: {
      fontSize: "13px",
      color: "#444",
      fontWeight: "600",
      marginTop: "3px",
    },
    smallTitle: {
      fontSize: "14px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    con: {
      fontWeight: "300",
      marginBottom: "30px",
      lineHeight: "1.5",
    },
    depth: {
      paddingLeft: "20px",
      fontSize: "15px",
      color: "#333",
      lineHeight: "1.5",
    },
  };

  return (
    <>
      <div style={style.bigTitleFirst}>
        <span>회원가입을 진심으로 환영합니다!</span>
      </div>
      <span style={style.smallTitleFirst}>안녕하세요</span> <span style={style.smallTitle}>HiSMS</span><span style={style.smallTitleFirst}>입니다.</span>
      <br></br>
      <span style={style.smallTitleFirst}>{sessionStorage.getItem("id")}님 회원가입을 축하드리며 서비스를 이용하심에 불편함이 없도록 최선을 다하겠습니다!</span>
      <br></br>
      <span style={style.smallTitleFirst}>서브 계정으로 가입을 원하신다면 <span style={style.smallTitle}>[마이페이지 - 계정정보 - 대표계정연동]</span> 을 이용해 주세요.</span>
      <br></br>
      <br></br>
      <br></br>
      <p style={style.smallTitleNormal}>정상적인 서비스 이용을 위해 담당자가 회원님의 계정을 검토하고 있습니다.</p>
      <p style={style.smallTitleNormal}>영업일 기준 1일 이내 담당자가 회원님의 계정을 승인하고</p>
      <p style={style.smallTitleNormal}>가입시 작성해주신 대표 담당자 이메일 주소로 승인 완료 메일을 보내드리겠습니다.</p>
      <p style={style.smallTitleNormal}>승인 과정 중 담당자가 회원님의 연락처로 연락을 할 수 있습니다.</p>
      <br></br>
      <p style={style.smallTitleNormal}>궁금하신 사항은 고객센터의 상담문의를 이용해주세요.</p>
      <br></br>
      <br></br>
      <br></br>
      <span style={style.smallTitleNormal}>감사합니다.</span>
      <br></br>
      <span style={style.smallTitle}>HiSMS 드림</span>
      <br></br>
      <br></br>
    </>
  );
}

export function LayerBatchSend() {

  const style = {
    graybox: {
      backgroundColor: "#f7f7f7",
      padding: "25px",
      margin: "20px 0",
      fontSize: "18px",
      width: "100%",
      "& li": {
        fontWeight: "300",
        marginBottom: "30px",
        fontSize: "16px",
      },
    },
    subTitle: {
      fontWeight: "600",
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.4",
    },
    conbox: {
      marginBottom: "30px",
    },
    bigTitleFirst: {
      fontSize: "26px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    bigTitle: {
      fontSize: "26px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    smallTitleFirst: {
      fontSize: "14px",
      color: "#222",
      fontWeight: "600",
      marginBottom: "30px",
    },
    smallTitleNormal: {
      fontSize: "13px",
      color: "#444",
      fontWeight: "600",
      marginTop: "3px",
    },
    smallTitle: {
      fontSize: "14px",
      color: "#625af6",
      fontWeight: "600",
      marginBottom: "30px",
      marginTop: "50px",
    },
    con: {
      fontWeight: "300",
      marginBottom: "30px",
      lineHeight: "1.5",
    },
    depth: {
      paddingLeft: "20px",
      fontSize: "15px",
      color: "#333",
      lineHeight: "1.5",
    },
  };

  return (
    <>
      <span style={{fontWeight: "bold", fontSize: "18px", color: "#625af6"}}>대량 발송 승인 요청은 어떻게 하나요?</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>안녕하세요 HiSMS 입니다.</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>스팸, 스미싱을 방지하기 위해 1만건 이상 메시지는 관리자의 승인이 필요합니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px",  color: "#625af6"}}>대량 발송을 하시면 담당자에게 실시간으로 자동 접수되며 영업일 기준 1일 이내 처리 완료됩니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>승인 여부는 일반관리 - 전송결과에서 확인 가능합니다.</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>더 빠른 처리를 원하시는 고객님은 고객센터 - 상담문의를 통해 문의 부탁드립니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>영업시간 외(08:30 ~ 17:30)에 대량 발송을 원하시는 고객님은 예약 발송을 부탁드립니다.</span>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>담당자가 미리 확인한 후 승인할 수 있습니다.</span>
      <br></br>
      <br></br>
      <span style={{fontWeight: "bold", fontSize: "14px"}}>감사합니다.</span>
    </>
  );
}