import React from 'react';
       
class Manual extends React.Component{
    state = { isActive: false };

  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  render(){
    const isActive = this.state.isActive;
    return (
        <section className={`information-text ${isActive ? "on" : ""}`}>
            <h1 className="information-head">
                <button type="button" aria-expanded="false" aria-controls="informationPanel" clasNames="btn-viewmore" onClick={this.handleToggle}>템플릿 등록 가이드<i className="icon arrow"></i></button>
            </h1>
            <div role="region" aria-labelledby="informationHead" aria-hidden="true" className="information-body">
                <strong className="mb10">템플릿 등록</strong>
                <div className="mb20">
                * 서술(desctiption)과 스타일(cell) 템플릿은 관리자 승인 후 사용이 가능합니다.<br/>
                * 저장 상태에서는 수정/삭제가 가능합니다.<br/>
                * 승인요청 후에 취소가 가능(검수시작 전)하며, 승인 완료 후에 수정(승인필요)이 가능합니다.<br/>
                </div>
                <strong className="mb10">서술(description) 템플릿 등록</strong>
                <div className="mb20">
                * 문자메시지처럼 문장 형태의 텍스트를 입력할 수 있습니다.<br/>
                * 최대 90자까지(공백포함) 입력이 가능합니다.<br/>
                </div>
                <strong className="mb10">템플릿 등록</strong>
                <div className="mb20">
                * 최대 10행까지 입력이 가능합니다.<br/>
                * 1행에 1개 또는 2개의 Cell을 선택적으로 지정하실 수 있습니다.<br/>
                * 라인 체크박스 클릭 시 해당 행 하단에 라인을 삽입하실 수 있습니다.<br/>
                </div>
            </div>
        </section>
    );
  }
}

export default Manual;