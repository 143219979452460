import React from "react";
import { Button } from "component/Common";
import { postRequest } from "component/Axios";

export class ReservationBoxView extends React.Component {
    state = { isActive: false, reservData : [], textLen: 0 , typeNm : ''};

    componentDidMount (){
        const sn = sessionStorage.getItem("reservView");
        const kind = sessionStorage.getItem("reservKind");
        const type = sessionStorage.getItem("reservType");
        
        this.setState({typeNm : sessionStorage.getItem("reservTypeNm") });
        let url = "/message/reservView";
        let data = { search1 : sn , search2 : kind , search3 : type }
    	postRequest.fetch(url,data).then(result => {
                if( result.resultCode === "0" ) {
                    const jsonArr = JSON.parse(result.resultData);
                    if ( jsonArr.length === 0 ) {
                        window.location.replace("/main/messagebox");
                    }
                    this.setState({reservData : jsonArr[0]});
                    this.setState({textLen : jsonArr[0].text.length});
                } else {
                    alert(result.resultMsg);
                }
            }
        )
        .catch(error => alert(error))
        
    }

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    render() {
        const isActive = this.state.isActive;

        return (
            <div className="container sendboxview">
                <p className="page-title">예약메시지함 상세보기</p>
                <div className="button-wrap">
                    <Button
                        buttonName="button-border-gray"
                        buttonValue="뒤로가기"
                        onChangePage={function () {
                        window.location.href = "/main/messagebox";
                    }}
                    />
                </div>
                <div className="sendboxviewInner">
                    <div className="msgboxContent msgcontext">
                        <div className="content">
                            {this.state.reservData.text}
                            <div className="content_info">
                                <span className="rfloat gray999">{this.state.textLen}/2000</span>
                                <p className="clear"></p>
                            </div>
                        </div>
                    </div>
                    <ul className="msgboxContent msgSendInfo">
                        <li>
                            <div className="lfloat">발신번호</div>
                            <div className="rfloat">{this.state.reservData.callback}</div>
                        </li>
                        <li>
                            <div className="lfloat">수신번호</div>
                            <div className="rfloat">{this.state.reservData.dstaddr}</div>
                        </li>
                        <li>
                            <div className="lfloat">예약시간</div>
                            <div className="rfloat">{this.state.reservData.requestTime}</div>
                        </li>
                        <li>
                            <div className="lfloat">메세지유형</div>
                            <div className="rfloat">{this.state.typeNm}</div>
                        </li>
                        <li>
                            <div className="lfloat">발송 요청 건수</div>
                            <div className="rfloat">{this.state.reservData.totCnt}건</div>
                        </li>
                        <li>
                            <div className="lfloat">발송 요금</div>
                            <div className="rfloat">{this.state.reservData.amount}원</div>
                        </li>
                        <p className="clear"></p>
                    </ul>
                    <p className="clear"></p>
                </div>
            </div>
        );
    }
}