import React from "react";

const contents = (
  <ul className="text-sub">
    <pre>
      * 입력란은 총 10개까지 생성가능합니다. 11건 이상의 템플릿 등록 시에는 파일
      업로드 기능을 이용해 주세요. (최대 100개)
      <br />
      * 템플릿 등록은 한번에 10개까지 등록 가능하며, 템플릿 등록 갯수 제한은
      없습니다.
      <br />
      * 채널 그룹 등록 시, 그룹간 동일한 템플릿 코드와 템플릿명을 중복해서
      등록할 수 없습니다.
      <br />
      * 채널과 채널그룹에 동일한 템플릿코드가 존재할 경우 채널에 등록된 템플릿이
      우선 발송됩니다.
      <br />
      * 템플릿 동시 등록은 가능하나, 수정 및 검수 요청은 템플릿 별로 가능합니다.
      <br />
      * 채널(발송할 카카오톡 채널)을 선택해 주세요. 템플릿별 하나의 카카오톡
      채널만 선택 가능합니다.
      <br />
      * 템플릿명은 메시지 내용에 포함되지 않습니다.
      <br />* 템플릿내용은 한/영구분없이 URL 포함 1,000자 입력 가능합니다.
      <span className="import1 importbg bold">
        부가정보, 광고성메세지, 템플릿내용
      </span>
      을 모두 합하여 1,000자 입력 가능한 점 감안 부탁드립니다.
      <br />
      변수에 들어갈 내용의 최대 길이를 감안하여 작성해 주세요.
      <br />
      * 템플릿코드는 최대 30자, 템플릿명은 최대 30자, 버튼명은 최대 14자,
      버튼링크는 최대 ​500자까지 가능합니다.
      <br />
      * 보안템플릿설정은 OTP 등 보안이 필요한 메시지인 경우 체크해 주세요. (OTP
      메시지 등 민감 메시지가 아닌 경우, 검수 과정에서 노출 상태로 변경됩니다.)
      <br />* 이미지 템플릿은 이미지 알림톡 사용 가이드를 참고하여 주시기
      바랍니다.
      <a
        href="https://kakaobusiness.gitbook.io/main/ad/bizmessage/notice-friend/content-guide/image"
        target="_blank"
        className="import2 bold"
      >
        이미지 알림톡 사용 가이드 확인하기
      </a>
      <br />* 변수를
      <span className="import1 importbg bold">#&#123;변수&#125;</span> 형태로
      넣어주세요. 특수문자(#&#123;&#125;&#125;)가 다를 경우 변수로 사용할 수
      없습니다.
      <br />
      (예 : <span className="import1 importbg bold">#&#123;고객명&#125;</span>
      님의 택배가 금일
      <span className="import1 importbg bold">(#&#123;배달시간&#125;)</span>에
      배달될 예정입니다.)
      <br />
      * 하나의 채널에 동일한 템플릿코드와 템플릿명을 중복해서 등록할 수
      없습니다.
      <br />
      * '채널추가' 버튼 사용시 버튼명은 사용 불가합니다. 고정 버튼명인 '채널
      추가'만 사용 가능합니다.
      <br />*
      <a
        href="https://kakaobusiness.gitbook.io/main/ad/bizmessage/notice-friend/audit"
        target="_blank"
        className="import2 bold"
      >
        템플릿 검수 가이드 문서 확인하기 (필독)
      </a>
      <br />* <span className="import1 importbg bold">'엑셀대량입력'</span>시
      템플릿 카테고리 목록을 다운로드 받으신 후 템플릿 카테고리 기입에 참고
      하시기 바랍니다.
      <br />* <span className="import1 importbg bold">'엑셀대량입력'</span> 시
      이미지 템플릿 등록은 이용이 불가합니다.
      <br />
      <span className="import1 importbg">
        *** 알림톡 템플릿 카테고리란? ***
      </span>
      <br />
      - 알림톡 템플릿 카테고리는 카테고리 그룹과 카테고리로 구성됩니다. ( ex:
      회원 &gt; 회원가입) - 알림톡 템플릿 카테고리를 입력한 템플릿을 우선하여
      심사 진행됩니다.
      <br />
      - 알림톡 템플릿 카테고리를 입력하지 않고 등록요청을 하거나 '기타'를 선택한
      경우, 카카오에서 내용 분석 혹은 '심사'를 통해 임의적으로 카테고리를
      부여하게 됩니다.
      <br />
    </pre>
  </ul>
);

class Manual extends React.Component {
  state = { isActive: false };

  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const isActive = this.state.isActive;
    return (
      <section className={`information-text ${isActive ? "on" : ""}`}>
        <h1 className="information-head">
          <button
            type="button"
            aria-expanded="false"
            aria-controls="informationPanel"
            clasNames="btn-viewmore"
            onClick={this.handleToggle}
          >
            템플릿 등록 가이드<i className="icon arrow"></i>
          </button>
        </h1>
        <div
          role="region"
          aria-labelledby="informationHead"
          aria-hidden="true"
          className="information-body"
        >
          {contents}
        </div>
      </section>
    );
  }
}

export default Manual;
