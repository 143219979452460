import React, { useState, useEffect, useRef } from "react";
import { Button } from "component/Common";
import { postRequest } from "component/Axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../component/Loader";

export const FindPwd1 = () => {
    const [defaultValue, setdefaultValue] = useState("phone");
    const [timeCheck, setTimeCheck] = useState('');
    const [custId, setCustId] = useState('');
    const [name, setName] = useState('');
    const [subVal, setSubVal] = useState('');
    const nameInput = useRef();
    const custIdInput = useRef();
    const [authNum, setAuthNum] = useState('');
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    const handleSearch = (event) => {
        let type;
        let mobile;
        let email;

        if ( !custId ) {
            alert("아이디는 필수 입력사항입니다.");
            return;
        }

        if ( !name ) {
            alert("이름은 필수 입력사항입니다.");
            return;
        }

        if ( defaultValue === "phone" ) {
            type = "1"
            mobile = subVal;
            const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/
            if ( !subVal ) {
                alert("휴대폰번호는 필수 입력사항입니다.");
                return;
            } else if (!regexMobile.test(subVal)) {
                alert("잘못된 형식의 휴대폰번호입니다.");
                return;
            }
        } else {
            type = "2"
            email = subVal;
            const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
            if ( !subVal ) {
                alert("이메일은 필수 입력사항입니다.");
                return;
            } else if (!regexEmail.test(subVal)) {
                alert("이메일 형식을 다시 확인하세요.");
                return;
            }
        }
        
        if ( !authNum ){
            alert("인증번호를 입력하세요.");
            return;
        }
        
        if ( !timeCheck ) {
            alert("인증번호를 요청하세요.");
            return;
        }
        
        const url = "/auth/checkAuthNum";
        const data = { formType : "2", id: custId, name : name, mobile: mobile, email: email, type: type, num: authNum }

        getPostFetch(url, data)
        .then(result => {
                if( result.resultCode === "0" ) {
                    sessionStorage.setItem("find_id", custId)
                    navigate("/account/findpwd2");
                } else {
                    alert(result.resultMsg)
                }
            }
        )
        .catch(error => alert(error))

    };

    const handleChange = (event) => {
        setdefaultValue(event.target.defaultValue);
    };

    const handleEnterPress = e => {
        if(e.key === 'Enter') {
            handleSearch();
        }
      }
    
    return (
        <div className="account-wrap login">
            <div className="account-container">
                <p className="page-stitle">
                비밀번호 찾기
                </p>
                <div className="account-contents">
                

                <p className="Radiobox">
                    <input type="radio" name="find" id="phone" defaultValue="phone" onChange={handleChange} checked={defaultValue === "phone"} />
                    <label for="phone">대표담당자 휴대폰번호</label>
                    <input type="radio" name="find" id="email" defaultValue="email" onChange={handleChange} checked={defaultValue === "email"} />
                    <label for="email">대표담당자 이메일</label>
                </p>
                <div className="check-list-item">
                    <div className="input-inner clearfix" id="cropNumArea">
                        <div className="title-con">아이디</div>
                        <div className="input-con">
                            <input type="text" name="" id="" maxlength="20" title="ID" className="gs-custom-input fc-event" placeholder="아이디" 
                            ref={custIdInput} onChange={e => {setCustId(e.target.value)}} onKeyPress={handleEnterPress}/>
                        </div>
                        <p className="clear"></p>
                    </div>
                    <p className="list-item-state"><span className="red"></span></p>
                </div>    
                <div className="check-list-item">
                    <div className="input-inner clearfix" id="cropNumArea">
                        <div className="title-con">이름</div>
                        <div className="input-con">
                            <input type="text" name="" id="" maxlength="20" title="이름" className="gs-custom-input fc-event" placeholder="이름" 
                            ref={nameInput} onChange={e => {setName(e.target.value)}} onKeyPress={handleEnterPress}/>
                        </div>
                        <p className="clear"></p>
                    </div>
                    <p className="list-item-state"><span className="red"></span></p>
                </div>
                    {defaultValue === "phone" ? (
                        <Phone setTimeCheck={setTimeCheck} name={name} custId={custId} setSubVal={setSubVal} setAuthNum={setAuthNum} handleEnterPress={handleEnterPress}/>
                    ) : (
                        <Email setTimeCheck={setTimeCheck} name={name} custId={custId} setSubVal={setSubVal} setAuthNum={setAuthNum} handleEnterPress={handleEnterPress}/>
                    )}
                    <Button
                        buttonName="button-bgc-dark bbtn mt30"
                        buttonValue="비밀번호 찾기"
                        onChangePage={handleSearch} 
                    />
                </div>
            </div>
        </div>
    );
};

export const Phone = (props) => {
    const [min, setMin] = useState(3);
    const [sec, setSec] = useState(0);
    const time = useRef(180);
    const timerId = useRef(null);
    const setTimeCheck = props.setTimeCheck;
    const [mobile, setMobile] = useState('');
    const mobileInput = useRef();
    const name = props.name;
    const custId = props.custId;
    const setSubVal = props.setSubVal;
    const setAuthNum = props.setAuthNum;
    const [loading, setLoading] = useState(1);
    const handleEnterPress = props.handleEnterPress;

    const handleLoadingOn = () => {
        setLoading(0)
    }
    
    const handleLoadingClose = () => {
        setLoading(1)
    }

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    const handlePhone = (event) => {
        let phone = event.target.value;
        phone = phone.replace(/[^0-9.]/g, '')
        setMobile(phone);
        setSubVal(phone);
    }

    useEffect(() => {
        if (time.current <= 0) {
            alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
            clearInterval(timerId.current);
            setTimeCheck(false);
            time.current = 180;
        }
    }, [sec]);

    const handleNum = (event) => {
        setAuthNum(event.target.value);
    }

    const handleCertification = (event) => {
        event.preventDefault();

        const regexMobile = /^01(?:0|1|0)(?:\d{3}|\d{4})\d{4}$/

        clearInterval(timerId.current);
        setTimeCheck(false);
        time.current = 180;

        if ( !custId ) {
            alert("아이디는 필수입력사항 입니다.");
            return;
        }

        if ( !name ) {
            alert("이름은 필수입력사항 입니다.");
            return;
        }

        if ( !mobile ) {
            alert("휴대폰번호는 필수입력사항 입니다.");
            mobileInput.current.focus();
            return;
        } else if (!regexMobile.test(mobile)) {
            alert("잘못된 형식의 휴대폰번호 입니다.");
            mobileInput.current.focus();
            return;
        }
        
        const url = "/auth/authNum";
        const data = { formType : "2", id: custId, name : name, mobile: mobile, type: "1" }
        handleLoadingOn();
        getPostFetch(url, data)
        .then(result => {
            handleLoadingClose();
                if( result.resultCode === "0" ) {
                    setTimeCheck(true);
                    setMin(3);
                    setSec(0);
                    timerId.current = setInterval(() => {
                        time.current -= 1;
                        setMin(parseInt(time.current / 60));
                        setSec(time.current % 60);
                    }, 1000);
                    alert("인증번호가 발송되었습니다.");
                } else {
                    alert(result.resultMsg)
                }
            }
        )
        .catch(error => alert(error))

    };
    return (
        <>
        <div className="check-list-item">
        {loading !=  1 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
            <div className="input-inner clearfix" id="cropNumArea">
                <div className="title-con">휴대폰번호</div>
                <div className="input-con">
                    <fieldset className="address-fieldset">
                        <div className="address-wrapper">
                            <input type="text" name="" id="" maxlength="20" title="휴대폰번호" className="gs-custom-input fc-event" placeholder="'-'없이 숫자만 입력" 
                            onChange={handlePhone} value={mobile} ref={mobileInput} onKeyPress={handleEnterPress}/>
                            <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleCertification}>인증</button>
                        </div>
                    </fieldset>
                </div>
                <p className="clear"></p>
            </div>
            <p className="list-item-state"><span className="red"></span></p>
        </div>
        <div class="check-list-item" id="numChkTot2">
            <div class="input-inner clearfix" id="numChkBox2">
                <div class="title-con">
                    인증번호
                </div>
                <div class="input-con overseas">
                    <input type="text" id="phoneNumChk" name="phoneNumChk" title="인증번호" class="gs-custom-input fc-event" placeholder="인증번호" maxlength="6" 
                    onChange={handleNum} onKeyPress={handleEnterPress}/>
                    <div class="counter" id="phoneNumTime" name="phoneNumTime">
                        {min}:{sec < 10 ? `0${sec}` : sec}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export const Email = (props) => {
    const [min, setMin] = useState(3);
    const [sec, setSec] = useState(0);
    const time = useRef(180);
    const timerId = useRef(null);
    const setTimeCheck = props.setTimeCheck;
    const [email, setEmail] = useState('');
    const emailInput = useRef();
    const name = props.name;
    const custId = props.custId;
    const setSubVal = props.setSubVal;
    const setAuthNum = props.setAuthNum;
    const [loading, setLoading] = useState(1);
    const handleEnterPress = props.handleEnterPress;

    const handleLoadingOn = () => {
        setLoading(0)
    }
    
    const handleLoadingClose = () => {
        setLoading(1)
    }

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    const handleNum = (event) => {
        setAuthNum(event.target.value);
    }

    useEffect(() => {
        if (time.current <= 0) {
            alert("인증번호 입력시간을 초과하였습니다.\n인증번호를 재요청 하십시요.");
            clearInterval(timerId.current);
            setTimeCheck(false);
            time.current = 180;
        }
    }, [sec]);

    const handleEmail = (event) => {
        event.preventDefault();
        
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        clearInterval(timerId.current);
        setTimeCheck(false);
        time.current = 180;

        if ( !custId ) {
            alert("아이디는 필수입력사항 입니다.");
            return;
        }

        if ( !name ) {
            alert("이름은 필수입력사항 입니다.");
            return;
        }

        if ( !email ) {
            alert("이메일주소는 필수입력사항 입니다.");
            emailInput.current.focus();
            return;
        } else if (!regexEmail.test(email)) {
            alert("잘못된 형식의 이메일주소 입니다.");
            emailInput.current.focus();
            return;
        }
        
        const url = "/auth/authNum";
        const data = { formType : "2", id: custId, name : name, email: email, type: "2" }
        handleLoadingOn();
        getPostFetch(url, data)
        .then(result => {
            handleLoadingClose();
                if( result.resultCode === "0" ) {
                    setTimeCheck(true);
                    setMin(3);
                    setSec(0);
                    timerId.current = setInterval(() => {
                        time.current -= 1;
                        setMin(parseInt(time.current / 60));
                        setSec(time.current % 60);
                    }, 1000);
                    alert("인증번호가 발송되었습니다.");
                } else {
                    alert(result.resultMsg)
                }
            }
        )
        .catch(error => alert(error))

    };
    return (
        <>
        <div className="check-list-item">
        {loading !=  1 && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
            <div className="input-inner clearfix" id="cropNumArea">
                <div className="title-con">이메일</div>
                <div className="input-con">
                    <fieldset className="address-fieldset">
                        <div className="address-wrapper">
                            <input type="text" name="" id="" maxlength="30" title="이메일주소" className="gs-custom-input fc-event" placeholder="이메일" 
                            onChange={ e => {setEmail(e.target.value); setSubVal(e.target.value);} } ref={emailInput} onKeyPress={handleEnterPress}/>
                            <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleEmail}>인증</button>
                        </div>
                    </fieldset>
                </div>
                <p className="clear"></p>
            </div>
            <p className="list-item-state"><span className="red"></span></p>
        </div>
        <div class="check-list-item" id="numChkTot2">
            <div class="input-inner clearfix" id="numChkBox2">
                <div class="title-con">
                    인증번호
                </div>
                <div class="input-con overseas">
                    <input type="text" id="phoneNumChk" name="phoneNumChk" title="인증번호" class="gs-custom-input fc-event" placeholder="인증번호" maxlength="6"
                    onChange={handleNum} onKeyPress={handleEnterPress}/>
                    <div class="counter" id="phoneNumTime" name="phoneNumTime">
                        {min}:{sec < 10 ? `0${sec}` : sec}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
