import React from "react";
import { makeStyles } from "@material-ui/core";
import { Header } from "./Header";
import { Footer } from "./Footer";
import "../../assets/css/font.css";
import "../../assets/css/form.css";
import "../../assets/css/layout.css";
import "../../assets/css/reset.css";
import { Outlet } from "react-router-dom";

export const desktopLayoutStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapperOpen: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  wrapperClose: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

export const desktopLayoutTopbar = makeStyles({
  root: {},
  toolbar: {
    height: 64,
  },
  drawerOpen: {
    width: "83.5%",
  },
  drawerClose: {
    width: "100vm",
  },
  icon: {
    marginRight: "10px",
  },
  buttonGrp: {
    marginLeft: "auto",
  },
});

export const DesktopLayout = () => {
  const classes = desktopLayoutStyles();

  return (
    <>
      <Header />
      <div>
        <div>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export const KakaoLayout = () => {
  const classes = desktopLayoutStyles();

  return (
    <div className="kakao-wrap">
      <Header />
      <div className="kakao-container">
        <Outlet />
      </div>
    </div>
  );
};

export const KakaoHomeLayout = () => {
  return (
    <div className="kakao-wrap">
      <Header />
      <div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export const AccountLayout = () => {
  return (
    <>
      <Header />
      <div className="account-wrap">
        <div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
