import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { PrimaryColor } from "../../styles/GlobalStyle";
import ArrowBtn from "../../assets/images/arrow_down2.png";

const MenuBGC = `#f2f6fd`;

/* BeforeLogin */
const BeforeLoginWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MemberActBtn = styled(Link)`
  display: block;
  height: 34px;
  padding: 0 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #444;
  letter-spacing: -0.03em;
  position: relative;
  border: 1px solid rgba(2, 20, 63, 0.15);
  border-radius: 5px;
  box-sizing: border-box;

  &:last-child {
    margin-left: 8px;
  }
`;

/* Header wrap */

const HeaderWrap = styled.div`
  height: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e3e4e9;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px calc((100% - 1400px) / 2);
  min-width: 1200px;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Logo = styled(Link)`
  position: relative;
  z-index: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  color: #999;
  width: 130px;

  & img {
    display: block;
    height: 40px;
  }
`;

const NavBar = styled.ul`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const NavMenu = styled.li`
  display: block;
  position: relative;
  padding: 16px;
  font-size: 15px;
  margin-top: 10px;
  &:hover > div {
    display: block;
  }
`;

const Menu = styled(Link)`
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  color: ${({ isActive }) => (isActive ? PrimaryColor : "#222")};
`;

const SubMenuWrap = styled.div`
  display: none;
  position: absolute;
  top: 40px;
  left: -21px;
  min-width: 150px;
  padding: 26px 20px 20px;
  box-shadow: 0 0 8px 2px rgb(3 9 35 / 15%);
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 10;
  cursor: default;
  overflow: hidden;
  width: max-content;
`;

const SubMenu = styled(Link)`
  display: block;
  min-width: 125px;
  padding: 8px 15px 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? PrimaryColor : "#222")};
  box-sizing: border-box;
  letter-spacing: -0.3px;
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  background-color: ${({ isActive }) => (isActive ? MenuBGC : "#fff")};

  &:hover {
    background-color: ${MenuBGC};
  }
`;

/* AfterLogin */
const AterLogin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
`;

const ChargeState = styled.div`
  & svg {
    margin-right: 4px;
  }
`;

const MyMenu = styled.div`
  position: relative;
`;

const MypageBtn = styled(Link)`
  color: #444;
  margin: 0 16px;
  position: relative;
  padding-right: 20px;
`;

const Arrow = styled.span`
  display: block;
  font-size: 0;
  line-height: 0;
  background: url(${ArrowBtn}) no-repeat center center;
  background-size: 9px 5px;
  text-indent: -9999px;
  vertical-align: top;
  width: 18px;
  height: 14px;
  position: absolute;
  top: 3px;
  right: 0;
  transform: ${({ isClicked }) => (isClicked ? "rotate(180deg)" : "rotate(0)")};
`;

const MyPageSub = styled.div`
  display: ${({ isClicked }) => (isClicked ? "block" : "none")};
  position: absolute;
  top: 20px;
  left: -30px;
  min-width: 200px;
  padding: 26px 20px 20px;
  border: 1px solid #e3e4e9;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 10;
  cursor: default;
`;

const Mymenu = styled.div`
  position: relative;
  overflow: hidden;
`;

const MymenuList = styled(Link)`
  display: block;
  min-width: 95px;
  padding: 8px 15px 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? PrimaryColor : "#222")};
  background-color: ${({ isActive }) => (isActive ? MenuBGC : "#fff")};
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  box-sizing: border-box;
  letter-spacing: -0.3px;
  &:hover {
    background-color: ${MenuBGC};
  }
`;

const LogOutBtn = styled(Link)`
  color: #999;
`;

export const style = {
  HeaderWrap,
  HeaderLeft,
  Logo,
  NavBar,
  NavMenu,
  Menu,
  SubMenuWrap,
  SubMenu,
  BeforeLoginWrap,
  MemberActBtn,
  AterLogin,
  ChargeState,
  MyMenu,
  MypageBtn,
  Arrow,
  MyPageSub,
  Mymenu,
  MymenuList,
  LogOutBtn,
};
