import React, { Component, useEffect, useState } from "react";
import PayInAdvance from "./PayInAdvance";
import PayLater from "./PayLater";
import { ContractTable } from "component/Table";
import { Button, Pagination } from "component/Common";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { postRequest, getRequest } from "component/Axios";
import {
  LayerPopPaymentTotal,
  LayerPopCalculator,
  LayerPopAccountCreation,
  LayerPopTestCardPay
} from "component/popUp/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import useSetComma from "../../../component/common/SetComma";
import Loader from "../../../component/Loader";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.handleGetBalance = this.handleGetBalance.bind(this);
    this.handleSetBalance = this.handleSetBalance.bind(this);
    this.state = {
      userPayment: "before",
      isActive: false,
      infoData: [],
      custType: "",
      userType: "",
      custGrade: "",
      balance: 0,
      textLen: 0,
      loading: 0
    };
  }
  handleGetBalance() {
    const url = "/user/userInfo";
    this.setState({ loading: 0 });
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          sessionStorage.setItem("pay", jsonArr[0].balance);
          this.setState({ balance: jsonArr[0].balance });
          this.setState({ loading: 2 });
        }
      })
      .catch((error) => this.setState({ loading: 2 }));
  }

  handleGetCustType = () => {
    const url = "/user/custInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const json = JSON.parse(result.resultData);
          this.setState({ custType: json.custType });
          this.setState({ userType: json.userType });
          this.setState({ custGrade: json.custGrade });
          this.setState({ loading: 2 });
        }
      })
      .catch((error) => this.setState({ loading: 2 }));
  };

  handleSetBalance = (balance) => {
    this.setState({ balance: balance })
  }

  componentDidMount() {
    const url = "/user/userInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          const payRating = jsonArr[0].preRating;

          this.setState({ infoData: jsonArr[0] });
          this.setState({ balance: jsonArr[0].balance });

          if (payRating === "1") {
            // 후불
            this.setState({ userPayment: "after" });
          } else {
            this.setState({ userPayment: "before" });
          }
        }
      })
      .catch((error) => this.setState({ loading: 2 }));
    this.handleGetCustType();
    this.handleGetBalance();
    /*
    let url = "/addr/addrGroupList";
    let data = { search1 : "1" }
  postRequest.fetch(url,data).then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                this.setState({groupList : jsonArr})
                this.setState({totCnt : jsonArr[0].totCnt})
                this.setState({agCnt : jsonArr[0].totCnt})
            } else {
                alert(result.resultMsg);
            }
        }
    )
    .catch(error => alert(error))
    
    url = "/addr/addrList";
    data = { search1 : this.state.search1, search2 : this.state.search2, search3 : this.state.search3, 
            search4 : this.state.agSn, rowCount : this.state.rowCount, currentPage: this.state.currentPage }
  postRequest.fetch(url,data).then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                this.setState({tableRows : jsonArr})
                const pageItem = JSON.parse(result.resultPage);
                this.setState({leftPage : pageItem.leftPage})
                this.setState({rightPage : pageItem.rightPage})
                this.setState({endPage : pageItem.endPage})
                this.setState({currentPage : pageItem.currentPage})
                this.setState({fnlPage: pageItem.fnlPage})
                const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
                this.setState({pages : pages})
            } else {
                alert(result.resultMsg);
            }
        }
    )
    .catch(error => alert(error))
    */
  }

  render() {
    const { infoData } = this.state;
    const { balance } = this.state;
    const { custType } = this.state;
    const { userType } = this.state;
    const { custGrade } = this.state;
    const { userPayment } = this.state;


    let box = null;
    let content = null;

    if (this.state.userPayment === "before") {
      content = (
        <PayInAdvance
          custType={custType}
          userType={userType}
          custGrade={custGrade}
          balance={balance}
          handleGetBalance={this.handleGetBalance}
          handleSetBalance={this.handleSetBalance}
        />
      );
      box = (
        <PayInAdvanceBox
          userType={userType}
          custType={custType}
          infoData={infoData}
          balance={balance}
          handleGetBalance={this.handleGetBalance}
          handleSetBalance={this.handleSetBalance}
        />
      );
    } else if (this.state.userPayment === "after") {
      content = (
        <PayLater
          custType={custType}
          userType={userType}
          custGrade={custGrade}
        />
      );
      box = <PayLaterBox custType={custType} userType={userType} custGrade={custGrade} />;
    }

    return (
      <div className="container contract">
        {this.state.loading !=  2 && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <div className="page-title">요금조회 및 납부</div>
        <p style={{fontSize: "15px", fontWeight: "500", color: "#625af6"}}>
          사용내역은 발송 성공 건수만 보여지며, 집계 기준은 발송 요청 연월이 아닌 발송 성공 결과를 받은 연월입니다.
        </p>
        <p style={{fontSize: "14px", fontWeight: "500", color: ""}}>
          * 발송 후 최대 72시간 내 성공/실패 결과를 받을 수 있습니다.
        </p>
        <br></br>
        {
           (custType == 1 && userPayment == "after") && (
            <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
              후불 고객의 경우 전월에 발송 요청을 하였고, 발송 성공 결과를 당월에 받게 되었을 때 해당 사용분은 익월에 청구됩니다.
            </p>
           )
        }
        {
          userPayment == "before" && (
            <p style={{fontSize: "15px", fontWeight: "600", color: "#625af6"}}>
              선불 고객의 경우 발송 실패 결과를 받았을 때 건별로 환불 처리됩니다.
            </p>
           )
        }
        
        <br></br>
        {/* 아래 버튼은 선불, 후불을 표기하기 위한 버튼으로 백엔드 연동시 삭제 */}
        {/* <button className ="dark" onClick={function () {
          if (this.state.userPayment === "before") {
            this.setState({
              userPayment : "after"
           })  
          }
          else if (this.state.userPayment === "after") {
            this.setState({
              userPayment: "before"
            })
          }
          
        }.bind(this)}>{this.state.userPayment === "before" ? "선불" : "후불"}</button> */}

        {/* <div className="right button-wrap">
          <select name="" id="">
            <option value="">충전금액</option>
            <option value="">직접입력</option>
            <option value="">5만원 + 5천원</option>
            <option value="">10만원 + 1만원</option>
            <option value="">20만원 + 2만원</option>
            <option value="">30만원 + 3만원</option>
            <option value="">50만원 + 5만원</option>
            <option value="">80만원 + 8만원</option>
            <option value="">100만원 + 10만원</option>
          </select>
          <Button buttonName="buttonDown" buttonValue="견적서 다운로드" onChangePage={DownloadEstimate}/>
        </div> */}

        <div className="payinfo">
          <div className="lfloat">
            <p className="page-subtitle">계약단가</p>
            <ContractTable infoData={infoData} />
          </div>

          <div className="rfloat">
            <p className="page-subtitle">요금이용정보</p>
            {box}
          </div>
          <p className="clear"></p>
        </div>

        {/* 대표계정의 대표 사용자는 서브계정의 (충전금배분, 충전금회수)가 가능하다 cf. 배분받은 충전금 먼저 차감*/}
        {/* 각 계정의 사용자는 각 계정의 (사용내역, 충전내역,납부내역) 을 볼 수 있다. 추가로 대표계정의 대표사용자는 서브계정의 것도 볼 수 있다 */}
        <div>{content}</div>
        {/*END*/}
        {/* <Pagination /> */}
      </div>
    );
  }
}

export function PayInAdvanceBox(props) {
  const [calculator, popupCalculator] = useState(false);
  const [createAccount, setCreateAccount] = useState(true); //전용계좌 생성여부
  const [accountCreation, popupAccountCreation] = useState(false);

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [search3, setSearch3] = useState("하나은행");

  const infoData = props.infoData;
  const userType = props.userType;
  const handleGetBalance = props.handleGetBalance;
  const balance = props.balance;
  const custType = props.custType;
  const handleSetBalance = props.handleSetBalance;
  
  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  useEffect(() => {
    const url = "/user/payInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => alert(error));
  }, []);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleAccountNumber = (event) => {
    event.preventDefault();
    if(custType == 5) {
      alert("승인 거부된 계정입니다.");
      return;
    }
    if(custType != 1 && custType != 2) {
      alert("계정 승인 후 이용 가능합니다.");
      return;
    }
    if (userType !== "1") {
      alert("일반 사용자는 전용계좌 생성을 할 수 없습니다.\n대표 사용자에게 문의하세요.");
      return;
    }
    //popupAccountCreation(true);

    event.preventDefault();

    async function getPostFetch(url, data) {
      return postRequest.fetch(url, data);
    }

    if (window.confirm("가상계좌를 발급하시겠습니까?")) {
      if (search3 != undefined) {
        const url = "/pay/getVirtualAccount";
        const data = { search3: search3 };

        getPostFetch(url, data)
          .then((result) => {
            alert(result.resultMsg);
            if (result.resultCode === "0") {
              const jsonArr = JSON.parse(result.resultData);
              setAccountNo(jsonArr.vaccount);
              popupAccountCreation(false);
            }
          })
          .catch((error) => alert(error));
      }
    }
  };
  const handleCalculator = (event) => {
    event.preventDefault();
    popupCalculator(true);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    handleGetBalance();
  };

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <>
      <div className="borderbox">
        <div className="right button-wrap">
          <Button
            buttonValue="충전금액 계산기"
            buttonName="button-border-blue"
            onChangePage={handleCalculator}
          />
        </div>

        <ul className="payment">
          <li>결제조건</li>
          <li>선불</li>
          <li>충전잔액</li>
          <li>
            <a href="/" onClick={handleRefresh}>
              <FontAwesomeIcon
                icon={faRedoAlt}
                className="gray999 mr10 cursor size8"
              />
            </a>
            <span>{balance.toLocaleString("ko-KR")}</span>원
          </li>
              
          {
            custType == 1 && (
              <>
                <li>
                  {payInfo === "1" && (
                      <span>내 전용계좌</span>
                  )}
                  {payInfo === "2" && (
                      <span>카드결제</span>
                  )}
                </li>
                
                <li>
                  {/* 전용 계좌 생성 전 */}
                  {payInfo === "1" && accountNo === undefined && (
                    <a href="/" onClick={handleAccountNumber} className="underline">
                      내 전용계좌 생성하기
                    </a>
                  )}
                  {payInfo === "1" && accountNo != undefined && (
                    <span> 하나은행 {accountNo}</span>
                  )}
                  {payInfo === "2" && (
                    <span>
                      <Button buttonName="cm-highlight-btn ml10"
                              buttonValue="카드결제"
                              onChangePage={handleTestCardPay}
                      />
                      <div id="payRender" name="payRender"></div>
                    </span>
                  )}
                </li>
                {payInfo === "1" && (
                  <>
                    <li>ㅤ</li>
                    <li>
                      <span className="red">
                        충전까지 최대 1분 소요됩니다.
                      </span>
                    </li>
                  </>
                )}
              </>
            )
          }
          {
            (custType == 3 || custType == 4) && (
              <>
              <li>결제방식</li>
              <li className="red">승인 후 이용가능합니다.</li>
              </>
              )
          }
          {/* <li>세금계산서</li> */}
          {/* <li> */}
          {/* 사업자정보 등록 전 */}
          {/* <BeforeBusinessInfo /> */}
          {/* 사업자정보 등록 후 */}
          {/* 123-45-78910 */}
          {/* </li> */}
        </ul>
      </div>
      {calculator && (
        <PopUpLayout
          onClose={popupCalculator}
          poptitle="발송건별 충전금액 계산기"
        >
          <LayerPopCalculator infoData={infoData} />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}
                     poptitle="카드결제"
                     size="small"
        >
          <LayerPopTestCardPay
            onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={handleSetBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
      {/* {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle='전용계좌 생성'>
          <LayerPopAccountCreation
            setAccountNo={setAccountNo}
            popupAccountCreation={popupAccountCreation}
          />
        </PopUpLayout>
      )} */}
    </>
  );
}

export function PayLaterBox(props) {
  const [paymentTotal, popupPaymentTotal] = useState(false);
  const [calculator, popupCalculator] = useState(false);
  const [createAccount, setCreateAccount] = useState(true); //전용계좌 생성여부
  const [accountCreation, popupAccountCreation] = useState(false);
  const [cmYear, setCmYear] = useState();
  const [cmMonth, setCmMonth] = useState();
  const [nowMonth, setNowMonth] = useState();
  const [expectedPay, setExpectedPay] = useState();

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const custType = props.custType;
  const userType = props.userType;
  const custGrade = props.custGrade;
  const handleSetBalance = props.handleSetBalance;

  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  const [loading, setLoading] = useState(2);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  const closeLoading = () => {
    setLoading(2);
  }

  const onLoading = () => {
    setLoading(0);
  }

  useEffect(() => {
    const url = "/user/payInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => alert(error));
  }, []);
  const handleFindCustMonthUSage = (year, month) => {
    const url = "/user/findCustMonthUSage";
    const data = { search1: year, search2: month, userSn: "", search6:  sessionStorage.getItem("custGrade") };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          let totAmount = 0.0;
          let totVat = 0.0;
          let totResultAmount = 0.0;
          const jsonArr = JSON.parse(result.resultData);
          let custMonthClaim = jsonArr.custMonthClaim;

          jsonArr.custMonthUsageList.map((info) => (totResultAmount += Number(info.totAmount)));

          setExpectedPay(totResultAmount);

          // setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleAccountNumber = (event) => {
    event.preventDefault();
    if (userType !== "1") {
      alert("일반 사용자는 전용계좌 생성을 할 수 없습니다.");
      return;
    }
    popupAccountCreation(true);
  };

  const handlePay = (event) => {
    let date = new Date();
    date.setMonth(date.getMonth());
    const year = date.getFullYear(); // 년
    setCmYear(year);
    let month = date.getMonth(); // 월
    month = ("0" + month).slice(-2);
    setCmMonth(month);
    
    event.preventDefault();
    popupPaymentTotal(true);
  };
  const handleDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth());
    const year = date.getFullYear(); // 년
    setCmYear(year);
    let month = date.getMonth() + 1; // 월
    month = ("0" + month).slice(-2);
    setCmMonth(month);

    let nowDate = new Date();
    let nowMonth = nowDate.getMonth() + 1; // 월
    setNowMonth(("0" + nowMonth ).slice(-2))

    handleFindCustMonthUSage(year, month);
  };
  useEffect(() => {
    handleDate();
  }, []);

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <>
      {loading !=  2 && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="borderbox">
        {/* 각 계정의 사용자는 계정의 요금결제하기를 할 수 있다
         */}
         {
            custType == 1 && (
              <div className="right button-wrap">
                <Button
                  buttonValue={nowMonth + "월 청구내역서"}
                  buttonName="button-bgc-blue bbtn"
                  onChangePage={handlePay}
                />
              </div>
            )
         }
        
        {/* 각 계정의 사용자는 계정의 요금결제하기를 할 수 있다  END*/}

        <ul className="payment">
          <li>계약 조건</li>
          <li>후불</li>
          {
            custType == 1 && (
              <>
                <li><span>결제 방식</span></li>
                <li>
                  {/* 전용 계좌 생성 전 */}
                  {payInfo === "1" && accountNo === undefined && (
                    <a href="/" onClick={handleAccountNumber} className="underline">
                      내 전용계좌 생성하기
                    </a>
                  )}
                  {payInfo === "1" && accountNo != undefined && (
                    <span> 하나은행 {accountNo}</span>
                  )}
                  {payInfo === "2" && (
                    <span>
                      <Button buttonName="cm-highlight-btn ml10"
                              buttonValue="카드결제"
                              onChangePage={handleTestCardPay}
                      />
                      <div id="payRender" name="payRender"></div>
                    </span>
                  )}
                </li>
              </>
            )
          }
          {
            custType == 1 
            ? <li>익월 청구 예정 금액</li>
            : <li>당월 사용 금액</li>
          }
         <li><span>{expectedPay > 0 ? expectedPay.toLocaleString("ko-KR") : 0}</span> 원</li>
            

          {/* <li>세금계산서</li> */}
          {/* <li> */}
          {/* 사업자정보 등록 전 */}
          {/* <BeforeBusinessInfo /> */}
          {/* 사업자정보 등록 후 */}
          {/* 123-45-78910 */}
          {/* </li> */}
        </ul>
      </div>
      {paymentTotal && (
        <PopUpLayout onClose={popupPaymentTotal} poptitle="청구 내역서 확인">
          <LayerPopPaymentTotal
            onClose={popupPaymentTotal}
            cmYear={cmYear}
            cmMonth={cmMonth}
            custGrade={custGrade}
            closeLoading={closeLoading}
            onLoading={onLoading}
          />
        </PopUpLayout>
      )}
      {calculator && (
        <PopUpLayout
          onClose={popupCalculator}
          poptitle="발송건별 금액충전 계산기"
        >
          <LayerPopCalculator />
        </PopUpLayout>
      )}
      {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
          <LayerPopAccountCreation
            setAccountNo={setAccountNo}
            popupAccountCreation={popupAccountCreation}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}
                     poptitle="카드결제"
                     size="small"
        >
          <LayerPopTestCardPay
            onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={handleSetBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
    </>
  );
}

//사업자 정보(세금계산서) 등록 전
export function BeforeBusinessInfo() {
  const handleBusinessInfo = (event) => {
    event.preventDefault();
  };
  return (
    <a href="/" onClick={handleBusinessInfo} className="underline">
      사업자정보 등록하기
    </a>
  );
}

export default Payment;
