import React, { useState, useEffect } from "react";
import RcsImgList from "./list/RcsImgList";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopRcsImgUpload } from "component/popUp/PopUp";
import { Button, PaginationDynamic } from "component/Common";
import { postRequest, getRequest } from "component/Axios";
import _, { set } from "lodash";

export function RcsImg(props) {
  const [popup, setPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);
  const [tableRows, setTableRows] = useState("");
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [channel, setChannel] = useState("");
  const [type, setType] = useState(2);
  const [delList, setDelList] = useState([]);

  const [custType, setCustType] = useState("");
  const [custGrade, setCustGrade] = useState("");
  const [accountList, setAccountList] = useState([]);

  const [search1, setSearch1] = useState("");

  useEffect(() => {
    handlePageChange(1);
    // 카카오톡 채널 조회
    handleGetCustType(); //cust 정보
    handleSubList(); // 서브계정
  }, [search1]);

  const handlePopImg = (nType) => {
    setType(nType);
    setPopup(true);
  };

  const handleReSearch = () => {
    setPopup(false);
    handlePageChange(1);
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  const handleGetCustType = () => {
    const url = "/user/custInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const json = JSON.parse(result.resultData);
          setCustType(json.custType);
          setCustGrade(json.custGrade);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSubList = () => {
    const url = "/user/subInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setAccountList(jsonArr);
        }
      })
      .catch((error) => console.log(error));
  };

  const handlePageChange = (page) => {
    const url = "/rcs/rcsImgListPage";
    const data = {
      rowCount: rowCount,
      currentPage: page,
      search1: search1,
    };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);

          setTableRows(jsonArr);
          const pageItem = JSON.parse(result.resultPage);

          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleDelete = () => {
    if (delList.length === 0) {
      alert("선택된 이미지가 없습니다.");
      return;
    }

    if (window.confirm("선택된 이미지를 삭제 하시겠습니까?")) deleteImage();
  };

  function deleteImage() {
    const url = "/kko/imageDelete";
    const data = { imgList: delList };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          alert(result.resultMsg);
          handlePageChange(1);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <div>
      <div className="container">
        <div className="Info-gray">
          <p className="title">RCS 이미지 등록 가이드</p>

          <ul className="Info-wrap">
            <li>* 최대 권장 해상도 : 1024px * 1024px</li>
            <li>* 등록한 이미지는 등록일시로부터 7일간 사용 가능합니다.</li>
          </ul>
        </div>

        <div className="button-wrap">
          <Button
            buttonName="button-bgc-dark"
            buttonValue="이미지 업로드"
            onChangePage={(e) => {
              handlePopImg(2);
            }}
          />
          {/* 대표계정일 경우만 노출 */}

          {custType === "1" && custGrade === "2" ? (
            <span className="rfloat">
              <select
                name="userID"
                id="userID"
                onChange={(e) => {
                  setSearch1(e.target.value);
                }}
              >
                \
                <option value="" selected>
                  계정 전체
                </option>
                {accountList.map((info) => (
                  <option value={info.custSn}>{info.custId}</option>
                ))}
              </select>
            </span>
          ) : null}
          {/* 대표계정일 경우만 노출 end */}
        </div>

        <RcsImgList
          data={tableRows}
          setDelList={setDelList}
          custGrade={custGrade}
          custType={custType}
        />

        <PaginationDynamic
          pages={pages}
          currentPage={currentPage}
          leftPage={leftPage}
          rightPage={rightPage}
          endPage={endPage}
          fnlPage={fnlPage}
          onPageChange={handlePageChange}
        />
      </div>

      {/* 여기 팝업을 밖으로  */}
      {popup && (
        <PopUpLayout onClose={setPopup} poptitle="이미지 업로드">
          <LayerPopRcsImgUpload
            data={channel}
            type={type}
            handleReSearch={handleReSearch}
          />
        </PopUpLayout>
      )}
    </div>
  );
}

export default RcsImg;
