import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
//import { Button } from "component/Common";
import { useRecoilState } from "recoil";
import {
  loginState,
  headerState,
  paymentState,
  myHeaderState,
  subHeaderState,
} from "../../utils/state";
import { LOGO_NAME } from "../../utils/constant";
import LogoImg from "assets/images/Logo.png";
import { setCookie, getCookie } from "component/Cookies";
import { getRequest } from "../../component/Axios";
import { useNavigate } from "react-router-dom";
import { style } from "./Header.style";
import { PopUpLayout } from "../../component/popUp/PopUpLayout";
import { LayerPopNewUserGuide } from "../../component/popUp/PopUp";
import Loader from "../../component/Loader";
import { ROOT_URL } from "utils/constant";
export function BeforeLogin() {
  //const [isLogin, setIsLogin] = useRecoilState(loginState);
  //const [header, setHeader] = useRecoilState(headerState);

  return (
    <BeforeLoginWrap>
      <MemberActBtn to={`/account/login`}>로그인</MemberActBtn>
      <MemberActBtn to={`/account/joinStep1`}>회원가입</MemberActBtn>
      {/* <MemberActBtn
        to={`#`}
        onClick={function (e) {
          e.preventDefault();
          alert("가입 문의는 고객센터 메뉴에서 상담문의를 통해 남겨주세요");
        }}
      >
        회원가입
      </MemberActBtn> */}
    </BeforeLoginWrap>
  );
}

export function AfterLogin() {
  // Default 선불 (회원가입한 회원은 모두 선불회원,admin에서 승인하여 발급한 계정만 후불 )
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const [isClickedMyHeader, setIsClickedMyHeader] = useState(false);
  const [activeMyHeader, setActiveMyHeader] = useRecoilState(myHeaderState);
  const [activeHeader, setActiveHeader] = useRecoilState(headerState);
  const [activeSubHeader, setActiveSubHeader] = useRecoilState(subHeaderState);
  const [popup, setPopup] = useState(true);
  const [loading, setLoading] = useState(false);

  useRecoilState(paymentState);
  const [pay, setPay] = useState("");
  const [rating, setRating] = useState("");
  useEffect(() => {
    setPay(sessionStorage.getItem("pay"));
    setRating(sessionStorage.getItem("rating"));
  }, []);
  useEffect(() => {
    setPay(sessionStorage.getItem("pay"));
  }, [sessionStorage.getItem("pay")]);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    setIsLogin(false);
    sessionStorage.clear();
    // 로그아웃 이력 저장
    const url = "/auth/logout";
    getFetch(url)
      .then((result) => {
      })
      .catch((error) => alert(error));
    alert("로그아웃 되었습니다.");
    //navigate("")
    window.location.replace("/");
  };
  const myPageHeader = [
    { id: 1, link: "/mypage/info/accountInfo", name: "계정정보" },
    { id: 2, link: "/mypage/info/contractInfo", name: "계약정보" },
    { id: 3, link: "/mypage/payment", name: "요금조회 및 납부" },
    { id: 4, link: "/mypage/reference", name: "SW자료실" },
  ];
  function setComma(num) {
    if (num > 999) {
      const cn = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return cn;
    } else {
      return num;
    }
  }

  const setPopupClose = () => {
    setPopup(false);
  };

  // 마이페이지 메뉴 외부 클릭 감지
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsClickedMyHeader(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleRefresh = (e) => {
    setLoading(true);
    e.preventDefault();
    const userInfoUrl = "/user/userInfo";
    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          sessionStorage.setItem("pay", jsonArr[0].balance);
          setPay(jsonArr[0].balance);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      {
        loading == true && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />          
        )
      }
      <AterLogin>
        {
          (sessionStorage.getItem("custType") == 3 || sessionStorage.getItem("custType") == 4) && popup == true && (
            <PopUpLayout onClose={setPopup} poptitle='알림'>
              <LayerPopNewUserGuide
                setPopupClose={setPopupClose}
              />
            </PopUpLayout>)
        }
        {rating === "2" && (
          <ChargeState>
            <a href="/" onClick={handleRefresh}>
              <FontAwesomeIcon icon={faRedoAlt} className="gray999 mr10 cursor size12"/>
              </a>
              충전잔액 : {setComma(pay)}원
            
          </ChargeState>
        )}
        <MyMenu ref={wrapperRef}>
          <MypageBtn
            to={`/`}
            onClick={function (e) {
              e.preventDefault();
              if (isClickedMyHeader) {
                setIsClickedMyHeader(false);
                setActiveHeader(0);
              } else {
                setIsClickedMyHeader(true);
              }
            }}
          >
            마이페이지
            <Arrow isClicked={isClickedMyHeader}></Arrow>
          </MypageBtn>
          <MyPageSub isClicked={isClickedMyHeader}>
            <Mymenu>
              {myPageHeader.map((value) => (
                <MymenuList
                  key={value.id}
                  to={value.link}
                  onClick={function (e) {
                    setActiveMyHeader(value.id);
                    setActiveHeader(0);
                    setActiveSubHeader(0);
                    setIsClickedMyHeader(false);
                  }}
                  isActive={activeMyHeader === value.id}
                >
                  {value.name}
                </MymenuList>
              ))}
            </Mymenu>
          </MyPageSub>
        </MyMenu>
        <MyMenu>
          <LogOutBtn to={`/`} onClick={logout}>
            로그아웃
          </LogOutBtn>
        </MyMenu>
      </AterLogin>
    </>
  );
}
// default header
export const Header = (props) => {
  const LogoName = props.logoname;
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const [activeMyHeader, setActiveMyHeader] = useRecoilState(myHeaderState);
  const [activeMainHeader, setActiveMainHeader] = useRecoilState(headerState);
  const [activeSubHeader, setActiveSubHeader] = useRecoilState(subHeaderState);

  useEffect(() => {
    if (sessionStorage.getItem("tk")) setIsLogin(true);
    else setIsLogin(false);
  }, [isLogin]);

  const header = [
    { id: 1, link: "/service", name: "서비스소개", sub: null },
    {
      id: 2,
      link: "/sendMessage/message",
      name: "문자보내기",
      sub: null,
    },
    {
      id: 3,
      link: "/main/address",
      name: "일반관리",
      sub: [
        { subId: 31, link: "/main/address", subName: "공유주소록" },
        { subId: 32, link: "/main/personalAddress", subName: "개인주소록" },
        { subId: 33, link: "/main/sendnumber", subName: "발신번호" },
        { subId: 34, link: "/main/messagebox", subName: "메세지함" },
        { subId: 35, link: "//main/sendresult", subName: "전송결과" },
      ],
    },
    {
      id: 4,
      link: "/kakao/profile",
      name: "카카오서비스관리",
      sub: [
        { subId: 41, link: "/kakao/profile", subName: "채널" },
        { subId: 42, link: "/kakao/template", subName: "템플릿" },
        { subId: 43, link: "/kakao/friends", subName: "친구톡 이미지" },
        {
          subId: 44,
          link: "/kakao/topimg",
          subName: "알림톡 상단 이미지 관리",
        },
        {
          subId: 45,
          link: "/kakao/itemlist",
          subName: "알림톡 아이템 이미지 관리",
        },
      ],
    },
    {
      id: 5,
      link: "/rcs/rcsBrand",
      name: "RCS관리",
      sub: [
        { subId: 51, link: "/rcs/rcsBrand", subName: "브랜드" },
        { subId: 52, link: "/rcs/rcsTemplate", subName: "템플릿" },
        { subId: 53, link: "/rcs/rcsImg", subName: "이미지 관리" },
      ],
    },
    {
      id: 6,
      link: "/customer/notice",
      name: "고객센터",
      sub: [
        { subId: 61, link: "/customer/notice", subName: "공지사항" },
        { subId: 62, link: "/customer/faq", subName: "FAQ" },
        { subId: 63, link: "/customer/contactUs", subName: "상담문의" },
        { subId: 64, link: "/customer/api", subName: "API연동" },
      ],
    },
  ];

  function ClickedMenu(e, id) {
    setActiveMyHeader(0);
    setActiveMainHeader(id);
    setActiveSubHeader(0);
  }

  return (
    <HeaderWrap>
      <HeaderLeft>
        <Logo to={`/`}>
          <img src={LogoImg} alt={LOGO_NAME} />
        </Logo>
        <NavBar>
          {header.map((menu) => (
            <NavMenu key={menu.id}>
              <Menu
                to={menu.id == 5 ? window.location : menu.link}
                onClick={function (e) {
                  if(menu.id == 5) {
                    alert("준비중입니다.");
                  }
                  setActiveMyHeader(0);
                  setActiveMainHeader(menu.id);
                  setActiveSubHeader(0);
                }}
                isActive={menu.id === activeMainHeader}
              >
                {menu.name}
              </Menu>
              {menu.sub !== null && (
                <SubMenuWrap>
                  {menu.sub.map((submenu) => (
                    <SubMenu
                      key={submenu.subId}
                      to={menu.id == 5 ? window.location : submenu.link}
                      onClick={function (e) {
                        if(menu.id == 5) {
                          alert("준비중입니다.");
                        }
                        setActiveMainHeader(menu.id);
                        setActiveSubHeader(submenu.subId);
                      }}
                      isActive={submenu.subId === activeSubHeader}
                    >
                      {submenu.subName}
                    </SubMenu>
                  ))}
                </SubMenuWrap>
              )}
            </NavMenu>
          ))}
        </NavBar>
      </HeaderLeft>
      {!isLogin ? <BeforeLogin /> : <AfterLogin />}
    </HeaderWrap>
  );
};

const {
  HeaderWrap,
  HeaderLeft,
  Logo,
  NavBar,
  NavMenu,
  Menu,
  SubMenuWrap,
  SubMenu,
  BeforeLoginWrap,
  MemberActBtn,
  AterLogin,
  ChargeState,
  MyMenu,
  MypageBtn,
  Arrow,
  MyPageSub,
  Mymenu,
  MymenuList,
  LogOutBtn,
} = style;
