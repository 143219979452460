import React, { Component, useState, useEffect } from "react";
import { Button } from "component/Common";
import { PaymentTable } from "component/Table";
import { makeStyles } from "@material-ui/core/styles";
import { PaginationDynamic } from "component/Common";
import { DateRangePicker } from "rsuite";
import { postRequest, getRequest } from "component/Axios";
import _ from "lodash";
import Loader from "../../../component/Loader";

export function PaymentHistory(props) {
  const custType = props.custType; // 대표ID, 서브ID 구분
  const userType = props.userType; // 대표 사용자, 일반 사용자
  const custGrade = props.custGrade; // 일반 계정, 리셀러 계정 구분
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [unpaid, setUnpaid] = useState(0);
  const [dateValue, setDateValue] = useState("");
  const [cmStatus, setCmStatus] = useState("");
  const [cmPayUse, setPayUse] = useState("");
  const [codeRows, setCodeRows] = useState([]);
  const [searchAccountId, setSearchAccountId] = useState(""); // 선택한 계정 ID
  const [custRows, setCustRows] = useState([]);
  const [loading, setLoading] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    handlePageChange(1);
  };

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleCommonCode = () => {
    const url = "/getCommonCode";
    const snList = [1032, 1033];
    const data = { snList: snList };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };

  const handleSubList = () => {
    const url = "/user/subInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCustRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handlePageChange = (page) => {
    const url = "/user/findClaimList";
    const data = {
      search1: dateValue,
      search2: cmStatus,
      search3: cmPayUse,
      search4: searchAccountId,
      rowCount: rowCount,
      currentPage: page,
      userType: 1,
    };
    setLoading(0)
    getPostFetch(url, data)
      .then((result) => { 
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          let totUnpaid = 0.0;

          if(jsonArr.length > 0) {
            totUnpaid = jsonArr[jsonArr.length-1].cmTotAmount;

            jsonArr.pop();
          }


          setUnpaid(totUnpaid);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);
        } else {
          alert(result.resultMsg);
        }
        setLoading(2)
      })
      .catch((error) => alert(error));
      
  };
  useEffect(() => {
    handlePageChange(currentPage);
    handleCommonCode();
    handleSubList();
  }, []);

  return (
    <div className="charge">
      {loading !=  2 && (
        <Loader type="spin" color="black" message="요청을 처리중입니다" />
      )}
      <div className="searchbox mt20 mb20">
        <ul className="search-col5-group">
          <li>
            <input
              type="month"
              id="start"
              name="start"
              min="2021-01"
              onChange={(e) => setDateValue(e.target.value)}
            ></input>
          </li>
          <li>
            <select
              name=""
              id=""
              defaultValue=""
              value={cmStatus}
              onChange={(e) => setCmStatus(e.target.value)}
            >
              <option hidden value="">납부상태</option>
              <option value="">전체</option>
              {codeRows.map((row) =>
                row.cgSn === 1033 ? (
                  <option value={row.cdId} key={row.cdId}>
                    {row.cdNm}
                  </option>
                ) : null
              )}
            </select>
          </li>

          <li>
            <select
              id=""
              name=""
              data-fakeform-index="0"
              defaultValue=""
              value={cmPayUse}
              onChange={(e) => setPayUse(e.target.value)}
            >
              <option hidden value="">납부방법</option>
              <option value="">전체</option>
              {codeRows.map((row) =>
                row.cgSn === 1032 ? (
                  <option value={row.cdId} key={row.cdId}>
                    {row.cdNm}
                  </option>
                ) : null
              )}
            </select>
          </li>
          {custType === "1" && custGrade === "2" && (
            <li>
              <select
                name=""
                id=""
                onChange={(e) => setSearchAccountId(e.target.value)}
                value={searchAccountId}
              >
                <option hidden value="">계정 ID</option>
                <option value="">전체</option>

                {custRows.map((row) => (
                  <option value={row.custSn} key={row.custSn}>
                    {row.custId}
                  </option>
                ))}
              </select>
            </li>
          )}
          <li>
            <Button
              buttonName="button-bgc-dark"
              buttonValue="검색"
              onChangePage={handleSearch}
            />
          </li>
        </ul>
      </div>

      <div>
        <PaymentTable tableRows={tableRows} unpaid={unpaid} />
      </div>
      <PaginationDynamic
        pages={pages}
        currentPage={currentPage}
        leftPage={leftPage}
        rightPage={rightPage}
        endPage={endPage}
        fnlPage={fnlPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default PaymentHistory;