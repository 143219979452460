import React,{ useState, useEffect } from "react";
import Manual from './Manual';
import { RcsTemplateRegisterTable } from 'component/Table';
import { postRequest, getRequest } from "component/Axios";
import _ from 'lodash';
import axios from 'axios'; 

export const RcsTemplateRegister = () => {

  const [content, setContent] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    handlePageChange();
  }, []);

  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handlePageChange = page => {
    getFetch("/rcs/brandId/getSelect")
    .then(result => {
      if( result.resultCode === "0" ) {
          let data = [{"updateSn":0,"btnData":"[]","templateDescription":"[{\"text1\":\"\"}]","custSn":0,"regSn":0,"templateCardType":"RCSCardType1","userSn":0}];
          setContent(<RcsTemplateRegisterTable brandIdList={JSON.parse(result.resultData)} data={data}/>);
      }
    })
    .catch(error => console.log(error))
  }
    return (
      <div>
          <Manual />
          {content}
      </div>
    );
}
