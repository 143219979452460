import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Tooltip = ({
  text,
  horiz,
  vertical,
  children,
  maxWidth = 320,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  return (
    <TooltipIco>
      {children ? (
        children
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <path
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm.83 12.541h-1.9v-.708c0-1.156.625-2.24 1.672-2.9.867-.546 1.337-.939 1.337-1.447 0-1.014-1-1.561-1.94-1.561-1.035 0-1.942.73-1.942 1.56h-1.9c0-1.875 1.758-3.46 3.841-3.46 2.154 0 3.842 1.52 3.842 3.46 0 1.655-1.393 2.533-2.225 3.056-.236.15-.784.573-.784 1.292v.708Zm-.889 3.631a1.244 1.244 0 1 1 0-2.487 1.244 1.244 0 0 1 0 2.487Z"
          ></path>
        </svg>
      )}
      {show && (
        <ToolTipContainer horiz={horiz} vertical={vertical} maxWidth={maxWidth}>
          <dispatchEvent {...rest}>{text}</dispatchEvent>
        </ToolTipContainer>
      )}
    </TooltipIco>
  );
};

const TooltipIco = styled.div`
  color: rgba(0, 0, 0, 0.44);
  cursor: pointer;
  position: relative;
  padding: 2px;
  padding-bottom: 0px;
  display: inline-block;

  & svg {
    flex: 0 0 auto;
    margin: 0px;
    color: inherit;
    transition: color 150ms cubic-bezier(0.3, 0, 0, 1) 0ms;
  }
`;

const ToolTipContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  cursor: pointer;
  user-select: none;
  background-color: rgb(61 61 61);
  color: rgb(231 231 231);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px,
    rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
  transition-duration: 150ms;
  transition-property: background-color, box-shadow;
  padding: 8px 12px;
  overflow: hidden;
  border-radius: 8px;
  margin: 0px;
  font: inherit;
  max-width: ${(props) => props.maxWidth}px;
  width: max-content;
  position: absolute;
  ${(props) =>
    props.vertical === "middle"
      ? "top : 50%; transform:translateY(-50%);"
      : props.vertical === "top"
      ? "bottom:21px;"
      : "top:21px;"}
  ${(props) =>
    props.horiz === "left"
      ? "right:21px;"
      : props.horiz === "right"
      ? "left:21px;"
      : "left:50%; transform:translateX(-50%);"}
`;

export default Tooltip;

export const TootipText = ({ children }) => {
  return <TooltipTitle>{children}</TooltipTitle>;
};

const TooltipTitle = styled.span`
  position: relative;
  top: -3px;
`;

export const TooltipHover = ({ children, text, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="tooltip-container">
      <div
        className={show ? "tooltip-box-center visible" : "tooltip-box-center"}
      >
        {text}
        <span className="tooltip-arrow-center" />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};
