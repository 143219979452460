import React, { useEffect } from "react";
import { Container } from "../../styles/GlobalStyle";

export const AntiSpam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="Terms">
      <div className="service_tit">
        <h2>스팸방지정책</h2>
      </div>
      <div class="servicecont">
        <div class="service_cont">
          <br />
          <h4>Hisms 스팸방지정책</h4>

          <pre>
            한진정보통신 주식회사(이하 “회사”)는 다수 이용자의 편리한 서비스
            이용과 스팸, 불법스팸으로 인한 폐해를 방지하기 위해 스팸방지정책을
            수립하여 운영하고 있습니다. 아래와 같이 불법스팸 메시지를 발송한
            이용자에 대해서는 이용정지 및 해지, 형사고발 등의 조치를 취할 수
            있습니다.
          </pre>

          <pre>
            <strong>제1조 [개인정보의 처리 목적]</strong>
            {`① 스팸이란 정보통신망을 통해 수신자의 명시적인 사전 동의없이 일방적으로 전송되는 영리목적의 광고성 정보를 말합니다.
                    ② 불법스팸이란「정보통신망 이용촉진 및 정보보호 등에 관한 법률」(이하“정보통신망법”)을 위반하여 전송 또는 게시되는 영리목적의 광고성 정보를 말합니다. 불법스팸은 형사처벌 및 과태료 부과의 대상이 됩니다.`}
          </pre>

          <pre>
            <strong>제2조 [이용고객의 의무]</strong>
            {`① "고객”은 "서비스" 계약에 필요한 개인신상정보 등을 "회사"에 허위로 제공하여서는 안되며, 정보변경 시 지체 없이 "회사"에 통보하여 갱신하여야 합니다.
                    ② "고객"은 "회사"의 "서비스" 제공 목적 외의 용도로 "서비스"를 이용하여서는 안되며, 제3자에게 임의로 해당 "서비스"를 제공할 수 없습니다.
                    ③ 이용고객은 정보통신망이용촉진및정보보호등에관한법률의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 합니다.
                    ④ 이용고객은 스팸 또는 불법스팸을 전송함으로써 발생하는 모든 민형사상의 책임을 부담합니다.`}
          </pre>

          <pre>
            <strong>제3조 [이용의 정지]</strong>
            {`① 회사는 이용고객이 다음 중 하나에 해당하는 경우 지체없이 서비스 이용을 정지할 수 있습니다.`}
            <div className="pl10">
              {`1) 과학기술정보통신부 또는 한국인터넷진흥원이 불법스팸 전송사실을 확인하여 이용정지를 요청하는 경우
                        2) 대량으로 스팸을 전송하여 시스템 장애를 야기했거나 야기할 우려가 있는 경우
                        3) 이용자가 전송하는 광고의 수신자가 스팸으로 신고하는 경우
                        4) 이용자에게 제공하는 서비스가 불법스팸 전송에 이용되고 있는 경우`}
            </div>
            {`② 회사는 이용정지의 사유가 해소되고 재발생할 우려가 없다고 판단될 경우 서비스 이용을 가능하게 할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제4조 [계약의 해지]</strong>
            {`① 회사는 이용고객이 다음 중 하나에 해당하는 경우 지체 없이 계약을 해지할 수 있습니다.`}
            <div className="pl10">
              {`1) 타인의 명의로 계약하였거나 계약 시 제출한 자료 및 정보가 허위 또는 누락되었음이 확인된 경우
                        2) 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우
                        3) 방송통신위원회 또는 한국인터넷진흥원이 불법스팸 전송사실을 확인하여 계약해지를 요청하는 경우
                        4) 제5조 제1항의 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우`}
            </div>
            {`② 회사는 제1항의 규정에 의하여 서비스 계약을 해지하는 경우, 그 사실을 이용고객에게 통지합니다. 다만 미리 통지하는 것이 곤란한 경우에는 계약해지 후 통지할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제5조 [회사의 의무]</strong>
            {`① 회사는 서비스 제공과 관련하여 알고 있는 이용자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
                    ② 회사는 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.
                    ③ 회사는 스팸을 방지하기 위하여 스팸으로 인지되는 문자에 대해 필터링 또는 차단을 할 수 있습니다.
                    ④ 회사는 이용고객이 불법스팸을 전송한 사실을 확인한 경우, 한국인터넷진흥원 불법스팸대응센터의 요청에 따라 관련 자료를 제공할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제6조 [사전 수신동의 및 철회]</strong>
            {`① "고객"은 광고 전송 시 광고 전송 이전에 향후 전송될 광고의 내용 및 전송매체(방법)에 대해 정확히 고지하고 이에 대한 수신자의 동의를 받아야 합니다.
                    ② 하나의 "고객"이 여러 종류의 서비스를 제공할 경우, 해당 "고객"이 전송하는 모든 광고수신에 대해 포괄적으로 동의 받는 것은 안되며, 유형별 서비스를 모두 고지하고 개별적 동의를 받아야 합니다.
                    ③ 영리목적으로 광고성 정보를 전송하는 자는 수신자가 수신거부나 수신동의의 철회를 할 때 발생하는 전화요금 등 금전적 비용을 수신자가 부담하지 아니하도록 대통령령으로 정하는 바에 따라 필요한 조치를 하여야 합니다.`}
            <div className="pl10">
              {`1) "고객"은 매회 광고전송시 수신자가 언제든지 손쉽게 무료로 수신동의철회 또는 수신거부를 할 수 있도록 방법을 제시하여야 합니다.
                        2) 수신자가 전달하는 수신동의철회 또는 수신거부의사는 해당 광고에만 적용되는 것이 아니라 그 전송자가 보내는 모든 광고에 다 적용되어야 합니다.`}
            </div>
          </pre>

          <pre>
            <strong>제7조 [광고전송 허용시간]</strong>
            "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 제
            50조에 의거 광고성 정보 전송 시 유의사항에 대해 안내 드립니다.
            <br />
            {`① 일반적인 광고전송에 대한 동의를 받았을 경우 광고전송이 허용되는 시간은 오전 8시 이후 ~ 오후 9시 이전입니다.
                    ② 이 시간 이후의 야간시간대(오후 9시 ~ 다음날 오전 8시)에 광고를 전송하고자 하는 자는 수신자로부터 별도의 사전 동의를 받아야 합니다.
                    ③ 전화 광고의 야간시간대 전송금지 기준은 수신자에게 도달하는 시간을 기준으로 하기 때문에 광고를 전송하려는 “고객”은 전송시간을 추정하여 오후 9시부터 다음날 오전 8시까지 수신자의 이동전화나 유선전화로 광고가 전송되지 않도록 발신시간을 조정하여야 합니다.`}
          </pre>

          <pre>
            <strong>제8조 [광고 전송 시 표시의무 준수]</strong>
            {`① 메일은 제목에 (광고) 문구 표기, 문자는 본문에 (광고) 문구 표기
                    ② 정보전송의 유형 및 주요 내용
                    ③ 전송자의 명칭 및 연락처
                    ④ 전자우편주소를 수집한 출처(전자우편으로 전송하는 경우에 한한다.)
                    ⑤ 수신거부의 의사표시를 쉽게 할 수 있는 조치 및 방법에 관한 사항`}
            <div className="pl10">
              {`1) 광고성 메일을 전송하는 “고객”은 제목 앞에 "(광고)"를 표시해야 하며, 본문의 주요 내용을 제목으로 명시하고 또한 본문 안에는 전송자의 명칭/연락처 및 주소를 기재하고 한글과 영문의 수신거부 방법, 이메일 수집 출처 등을 명시해야 합니다.
                        2) 광고성 정보를 전송하는 “고객”은 전항에 따라 문자는 본문 내, 메일은 제목 내 “(광고)” 문구를 표시해야 하며, 표시 형식은 “(광고)” 형식으로만 가능합니다. 따라서 “광고”, “[광고]” 등의 방식으로 표기하는 경우는 광고를 표기하지 않은 것으로 간주합니다.
                        3) 광고 수신에 동의를 받은 메일 전송자의 경우, 동법 시행령에 의거하여 "(광고)" 표시를 하지 않거나 "(동의)"문구로 대신할 수 있는데, 전혀 표시를 않는 경우 광고 표기 의무를 준수하지 않는 스팸과 구별되지 않아 불이익을 보는 사례가 많기 때문에 "(광고)"표기하는 것을 권장하며. 이 때, 본문에는 동의를 얻은 시기 및 내용을 구체적으로 명시해야 합니다.
                        4) 수신자가 업체명 만으로 식별할 수 없는 이벤트 등에 의한 가입 등의 경우 그에 대해 법적 또는 계약상 관리책임을 지고 있는 이통사ㆍ통신과금서비스 제공 사업자(PG사) 등을 병행하여 표기해야 합니다.
                        5) 광고성 문자 전송 시 전송자의 연락처는 광고본문 또는 회신번호에 기재할 수 있으나, 연락이 되지 않거나 허위 연락처인 경우 표기하지 않은 것으로 간주합니다. 다만, 전송자 연락처 및 무료 수신동의철회 방법을 하나의 전화번호 등으로 통일하여도 제 기능을 수행할 수 있다면 단일한 전화번호 하나만을 본문 끝부분에 표시할 수 있습니다.
                        6) 광고성 문자 전송 시 수신동의철회 번호(080 등)는 수신자 부담 전화번호이어야 하며 광고본문 하단 또는 회신번호에 기재할 수 있으나 동 번호로 수신동의철회를 할 수 없을 경우, 수신동의철회 방법을 표기하지 않은 것으로 간주합니다.
                        7) 광고성 문자 전송을 받은 수신자가 수신동의철회 번호로 연락한 경우 즉시 수신거부 조치하거나 단말기의 특정 번호를 선택하는 것만으로 수신동의철회를 가능하게 하는 등 매우 간편한 수신동의 철회방법을 제공해야 합니다.`}
            </div>
          </pre>

          <pre>
            <strong>제9조 [기존거래관계 기간제한]</strong>
            회사는 재화 및 용역의 거래관계를 통하여 수신자로부터 직접 연락처를
            수집한 경우에는 수신자의 사전 동의 없이 회사가 취급하는 재화 및
            용역에 대한 영리 목적의 광고성 정보를 전송할 수 있습니다. 다만, 이
            경우의 기존거래관계는 광고수신일자로부터 최근 6개월 이내에
            거래관계가 있는 경우에만 인정하며, 정보통신서비스제공자의 경우에는
            적용되지 아니합니다.
          </pre>

          <pre>
            <strong>제10조 [이용고객 책임에 대한 동의 및 약관 공지]</strong>
            이용고객은 서비스 이용에 관하여 본인의 관리 소홀 또는 시스템 취약점
            등 회사의 귀책사유 없는 사정에 의한 해킹 또는 제3자의 불법 침해로
            인해 발생되는 다량의 메시지 발송, 서비스 장애, 서비스 중단, 불법
            스팸 발송, 발송요금 등의 피해에 대해서 회사가 어떠한 책임도 부담하지
            않음에 동의합니다. 가입하신 이용고객은 모두 회사에서 운영하는
            웹사이트인 HISMS 약관에 동의한 것입니다.
            <p className="he10"></p>
            {`- 공고 일자 : 2021년 9월 1일
                    - 시행 일자 : 2021년 9월 1일.`}
          </pre>
        </div>
      </div>
    </Container>
  );
};
