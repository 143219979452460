import React, { Component, useState, useEffect } from "react";
import { UsageTable } from "component/Table";
import { Button } from "component/Common";
import { DatePicker, DateRangePicker } from "rsuite";
import { postRequest, getRequest } from "component/Axios";
import _ from "lodash";
import axios from "axios";
import { PaginationDynamic } from "component/Common";
import { SEVER_DOMAIN } from "utils/constant.js";
import Loader from "../../../component/Loader";
import { json } from "body-parser";

export function UsageHistory(props) {
  const custType = props.custType; // 대표ID, 서브ID 구분
  const userType = props.userType; // 대표 사용자, 일반 사용자
  const custGrade = props.custGrade; // 일반 계정, 리셀러 계정 구분
  const preRating = props.preRating;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState([]);
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [codeRows, setCodeRows] = useState([]);
  const [usageSum, setUsageSum] = useState({
    webCntSum: 0,
    agentCntSum: 0,
    apiCntSum: 0,
    amountSum: 0,
    vatSum: 0,
    resultAmountSum: 0,
  });
  const [custBalanceInfo, setCustBalanceInfo] = useState([]);

  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");

  const [custRows, setCustRows] = useState([]);
  const [loading, setLoading] = useState("");

  var today = new Date();
  var year = today.getFullYear();
  var month = ('0' + (today.getMonth() + 1)).slice(-2);
  var date = year + '-' + month;
  const [dateValue, setDateValue] = useState(date);

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  

  const handleCommonCode = () => {
    const url = "/getCommonCode";
    const snList = [1018];
    const data = { snList: snList };

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handleCustBalanceInfo = () => {
    const url = "/getFindAccount";
    const data = {};

    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          let jsonArr = JSON.parse(result.resultData);
          jsonArr.map((info) => (info.amount = 0));
          setCustRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handleSubList = () => {
    const url = "/user/subInfo";
    getRequest
      .fetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCustRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handlePageChange = (page) => {
    const url = "/user/findUsagePageList";
    const data = {
      search1: dateValue,
      search2: search2,
      search3: search3,
      search5: custGrade,
      search6: "1",
      search7 : preRating,
      rowCount: rowCount,
      currentPage: page,
      userType: 1,
    };
    setLoading(0)
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          let webCntSum = 0;
          let agentCntSum = 0;
          let apiCntSum = 0;
          let amountSum = 0.0;
          let vatSum = 0.0;
          let resultAmountSum = 0.0;

          // if(jsonArr.length > 0) {
          //   webCntSum = jsonArr[jsonArr.length-1].webTot
          //   agentCntSum = jsonArr[jsonArr.length-1].agentTot
          //   apiCntSum = jsonArr[jsonArr.length-1].apiTot          
          //   amountSum = jsonArr[jsonArr.length-1].amount
          //   vatSum = jsonArr[jsonArr.length-1].vat
          //   resultAmountSum = jsonArr[jsonArr.length-1].totAmount

          //   jsonArr.pop();
          // }
          if(jsonArr != undefined) {
            jsonArr.map(
              (info) => (
                (webCntSum += info.webTot),
                (agentCntSum += info.agentTot),
                (apiCntSum += info.apiTot),
                (amountSum += info.amount),
                (vatSum += info.vat),
                (resultAmountSum += info.totAmount)
              )
            );
          }
          amountSum = (Math.round(amountSum * 100) / 100.0).toLocaleString(
            "ko-KR"
          );
          vatSum = (Math.round(vatSum * 100) / 100.0).toLocaleString("ko-KR");
          resultAmountSum = (
            Math.round(resultAmountSum * 100) / 100.0
          ).toLocaleString("ko-KR");

          setUsageSum({
            webCntSum: webCntSum,
            agentCntSum: agentCntSum,
            apiCntSum: apiCntSum,
            amountSum: amountSum,
            vatSum: vatSum,
            resultAmountSum: resultAmountSum,
          });
          setTableRows(jsonArr);
        } else {
          alert(result.resultMsg);
        }
        setLoading(2)
      })
      .catch((error) => setLoading(2));
  };

  useEffect(() => {
    handlePageChange(currentPage);
    handleCommonCode();
    handleSubList();
  }, [userType]);
  const handleSearch = (event) => {
    event.preventDefault();
    handlePageChange(1);
  };

  const DownloadStatistics = (event) => {
    event.preventDefault();
    handleDownTemplate();
  };

  const handleDownTemplate = () => {
    let str = "사용내역을 다운로드 하시겠습니까?";

    if (window.confirm(str)) {
      if (tableRows.length < 1) {
        alert("조회된 사용내역이 없습니다.");
        return;
      }
      setLoading(0)
      axios({
        method: "POST",
        url: SEVER_DOMAIN + "/user/usageListDownload/",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tk")}`,
          "Content-Type": "application/json",
        },
        data: {
          search1: dateValue,
          search2: search2,
          search3: search3,
          search5: custGrade,
          search6: "1",
          search7 : preRating,
          userType : 1,  
          rowCount: rowCount,
          currentPage: currentPage,
        },
      }).then((res) => {
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let fileName = "사용내역.xlsx";
        if (window.navigator.msSaveOrOpenBlob) {
          // IE 10+
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // not IE
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.target = "_self";
          if (fileName) link.download = fileName;
          link.click();
        }
        setLoading(2)
      })
      .catch((error) => setLoading(2));
    }
  };
  const DatePickerInstance = (props) => (
    <DatePicker format="yyyy-MM" ranges={[]} style={{ width: 200 }} />
  );
  return (
    <div>
      <div className="searchbox">
        {loading !=  2 && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <ul className="search-col4-group">
          <li>
            <input
              type="month"
              id="start"
              name="start"
              min="2021-01"
              value={dateValue}
              onChange={(e) => setDateValue(e.target.value)}
            ></input>
          </li>
          {custType === "1" && custGrade === "2" ? (
            <li>
              <select
                name=""
                id=""
                onChange={(e) => setSearch2(e.target.value)}
                value={search2}
              >
                <option hidden value="">사용자 계정</option>
                <option defaultValue value="">전체</option>

                {custRows.map((row) => (
                  <option value={row.custSn}>{row.custId}</option>
                ))}
              </select>
            </li>
          ) : null}
          <li>
            <select
              id=""
              name=""
              data-fakeform-index="0"
              onChange={(e) => setSearch3(e.target.value)}
              value={search3}
            >
              <option hidden value="">서비스 유형</option>
              <option value="">
                전체
              </option>
              <option value="S">SMS</option>
              <option value="L">LMS</option>
              <option value="M">MMS</option>
              <option value="A">알림톡</option>
              <option value="F">친구톡</option>
              <option value="f">친구톡 이미지</option>
              <option value="W">친구톡 와이드 이미지</option>
              <option value="8">RCS 템플릿</option>
              <option value="9">RCS SMS</option>
              <option value="10">RCS LMS</option>
              <option value="11">RCS MMS</option>
              
            </select>
          </li>
          <li>
            <Button
              buttonName="button-bgc-dark"
              buttonValue="검색"
              onChangePage={handleSearch}
            />
          </li>
        </ul>
      </div>
      <div className="button-wrap">
        <div style={{float:"left"}}>
          <p style={{fontSize: "14px", fontWeight: "500", color: "#625af6"}}>
            {
              custGrade == 2 && preRating != 2 && (
              <span>
                * 조회된 금액은 리셀러와 서브 계정의 총 발송 성공 건수를 리셀러의 발송 단가로 적용한 금액입니다. (실제 청구 금액 정보) 
              </span>
              )
            }
            
          </p>
        </div>
        <div style={{float:"right"}}>
          <Button
            buttonName="buttonDown"
            buttonValue="사용내역 다운로드"
            onChangePage={DownloadStatistics}
          />
        </div>
        <br></br>
      </div>
      <div>
        <UsageTable tableRows={tableRows} usageSum={usageSum} />
      </div>
    </div>
  );
}
export default UsageHistory;
