import React, { useEffect } from "react";
import { Container } from "../../styles/GlobalStyle";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="Terms">
      <div className="service_tit">
        <h2>개인정보처리방침</h2>
      </div>
      <div class="servicecont">
        <div class="service_cont">
          <br />
          <h4>Hisms 개인정보처리방침</h4>
          <pre>
            한진정보통신 주식회사(이하 ‘회사’라 한다)는 정보통신망이용촉진 및
            정보보호 등에 관한 법률(이하 정보통신망법), 개인정보 보호법에 따라
            정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
            처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
            수립.공개합니다.개인정보처리방침 목차는 관계법령에서 요구하는 필수
            사항과 회사가 이용자 개인정보보호를 위해 기재한 사항을 포함합니다.
          </pre>

          <pre>
            <strong>제1조 [개인정보의 처리 목적]</strong>
            {`① 회원관리
                        회원제 서비스 제공, 개인식별, 발신번호 확인, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인, 서비스 이용이 정지된 회원에 대한 이용 기록을 수집하고 회사는 회원이 공개적으로 등록한 정보 이외에 회원 개인의 신상에 관한 정보를 제3자에게 제공하지는 아니합니다.
                    ② 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                        컨텐츠 제공, 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심
                    ③ 상담처리
                        요청인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락.통지, 처리결과 통보의 목적으로 개인정보를 처리합니다.`}
          </pre>

          <pre>
            <strong>제2조 [개인정보의 처리 및 보유 기간]</strong>
            회사는 법령에 따른 개인정보 보유.이용기간 또는 정보주체로부터
            개인정보를 수집 시 또는 아래의 이유로 명시한 기간 동안
            처리.보유합니다.
            <br />
            {`① 회원 관리 목적
                    - 탈퇴 회원에 대해 부정 이용 방지 및 분쟁 조정, 발신번호 확인 및 불법 이용자에 관한 수사 협조 등의 사유로 탈퇴시로부터 1년 보존
                    - 보존 항목 : 아이디, 이름, 휴대전화번호, 이메일, 상호, 대표자명, 사업자등록번호, 사업자등록증, 가입일, 탈퇴일, 탈퇴사유, 재직증명서 (이름, 연락처, 소속/직책), 위임장 (이름, 연락처, 주소)
                    ② 관련법령에 의한 정보 보유 사유
                        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                    `}
            <table class="table02">
              <thead>
                <tr>
                  <th scope="col">보존 근거</th>
                  <th scope="col">보존 내용</th>
                  <th scope="col">보존 기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="3">전자상거래 등에서 소비자보호에 관한 법률</td>
                  <td>대금 결제 및 재화 등의 공급에 관한 기록</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>계약 또는 청약철회 등에 관한 기록</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자 불만 또는 분쟁 처리에 관한 기록</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td rowSpan="2">통신비밀보호법</td>
                  <td>웹사이트 방문에 관한 기록</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>통신사실확인자료</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>전기통신사업법</td>
                  <td>발신번호 등록 기록</td>
                  <td>1년</td>
                </tr>
              </tbody>
            </table>
            {`③ 장기 미이용 회원 개인정보분리보관(휴면회원)
                    - 대상 : 1년 이상 로그인이 필요한 모든 서비스의 사용 이력이 없는 회원
                    - 정보처리 : 별도 테이블에 분리 보관
                    - 통보 및 고지 : 분리 보관 30일 전 해당 사실, 분리 보관 예정 일자 등 전자우편으로 별도 통지
                    - 관련법령 : 정보통신망법 제29조 및 동법 시행령 제16조`}
          </pre>

          <pre>
            <strong>제3조 [개인정보의 제3자 제공]</strong>
            회사는 회원의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위
            내에서만 처리하며, 회원의 사전 동의 없이 본 개인정보처리방침의
            범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다. 다만, 회원의
            동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에
            해당하는 경우에만 개인정보를 제3자에게 제공합니다.
          </pre>

          <pre>
            <strong>제4조 [개인정보처리의 위탁]</strong>
            회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
            처리업무를 위탁하고 있습니다.
            <br />
            <table class="table02">
              <thead>
                <tr>
                  <th scope="col">수탁업체</th>
                  <th scope="col">위탁업무내용</th>
                  <th scope="col">개인정보 보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>나이스평가정보(주)</td>
                  <td>
                    휴대폰 본인 인증(서비스 이용을 위한 본인확인 업무 처리)
                  </td>
                  <td>위탁 계약 종료 시</td>
                </tr>
                <tr>
                  <td>넷매니아㈜</td>
                  <td>센드빌 세금계산서 발행</td>
                  <td>위탁 계약 종료 시</td>
                </tr>
                <tr>
                  <td>TBD</td>
                  <td>결제 서비스 제공</td>
                  <td>위탁 계약 종료 시</td>
                </tr>
              </tbody>
            </table>
          </pre>

          <pre>
            <strong>제5조 [정보주체의 권리·의무 및 그 행사방법]</strong>
            {`① 정보주체는 회사에 대해 언제든지 개인정보 열람.정정.삭제.처리정지 요구 등의 권리를 행사할 수 있습니다.
                    ② 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
                    ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                    ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                    ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정.삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`}
          </pre>

          <pre>
            <strong>제6조 [처리하는 개인정보의 항목]</strong>
            회사는 다음의 개인정보 항목을 처리하고 있습니다.
            <br />
            {`① 개인회원
                    - 필수항목:아이디, 비밀번호, 이름, 휴대전화번호, 이메일
                    - 선택항목:회원구분(개인/기업), 선호채널(서비스종류), 메시지사용목적, 단발성 전송 여부, 광고성 메시지 전송 여부, 이미지 메시지 사용 여부, 방문경로, 서면양식(이용통신증명원 등) 내 이름, 주소, 생년월일 (주민등록번호 뒷자리 마스킹 필수, 불법신고 등 필요시에 한하여 수집)
                    ② 기업회원
                    - 필수항목:아이디, 비밀번호, 담당자 이름, 담당자 휴대전화번호, 담당자 이메일, 상호, 대표자명, 업종, 업태, 종목, 사업자등록증(사업자등록번호), 사업장 주소, 재직증명서 (이름, 연락처, 소속/직책)
                    - 선택항목:담당자 전화번호, 회원구분(개인/기업), 선호채널(서비스종류), 메시지사용목적, 단발성 전송 여부, 광고성 메시지 전송 여부, 이미지 메시지 사용 여부, 방문경로, 서면양식(이용통신증명원/위임장/재직증명서/사실확인서 등) 내 이름, 주소, 생년월일 (주민등록번호 뒷자리 마스킹 필수, 불법신고 등 필요시에 한하여 수집)
                    `}
            <p className="he10"></p>
            유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수
            있습니다.
            <div>
              <span className="import2_bg">{`- 신용카드 결제시 : 카드사명, 카드번호`}</span>
            </div>
            <div>
              <span className="import2_bg">{`- 무통장입금 결제시 : 은행명, 입금자명, 휴대폰번호, 사업자등록번호`}</span>
            </div>
            <div>
              <span className="import2_bg">{`- 현금영수증 발급시 : 국세청에 등록된 휴대폰번호 또는 주민등록번호`}</span>
            </div>
            <div>
              <span className="import2_bg">{`- 전자세금계산서 신청시 : 주민등록번호 (사업자가 아닌 이용자에 한정)`}</span>
            </div>
            <p className="he10"></p>
            {`인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
                    - IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등`}
          </pre>

          <pre>
            <strong>제7조 [개인정보의 파기]</strong>
            {`① 회사는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 해당 개인정보를 복구∙재생할 수 없도록 파기합니다.
                    ② 개인정보 파기의 절차 및 방법은 다음과 같습니다.`}
            <div className="pl10">
              {`- 파기절차
                    회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관계 법령에 의한 정보보호 사유에 따라 일정 기간 저장 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다. 단, 상법 등 관련법령의 규정에 의하여 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
                    - 파기방법
                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`}
            </div>
          </pre>

          <pre>
            <strong>제8조 [개인정보의 안전성 확보 조치]</strong>
            회사는 개인정보의 안정성 확보를 위하여 다음과 같은 조치를 취하고
            있습니다.
            <pre className="graybox">
              1) 기술적 대책
              <div className="pl10">
                {`가.개인정보 암호화이용자의 비밀번호, 휴대폰 번호 등과 같은 중요 정보는 암호화되어 저장 및 관리되고 있습니다.
                        나.해킹 등에 대비한 대책회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
                        다.개인정보에 대한 접근제한개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근을 통제하고, 침입차단시스템과 침입방지시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                        `}
              </div>
              2) 관리적 대책
              {`운영자는 이용자의 개인정보를 안전하게 처리하기 위한 내부관리계획을 마련하여 소속 직원이 이를 숙지하고 준수하도록 하고 있습니다.
                    회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보처리방침의 준수를 항상 강조하고 있습니다.
                    서비스 이용신청의 승락 또는 서비스 제공을 위하여 이용자의 은행결제계좌, 신용카드번호 등 대금결제에 관한 정보를 수집하거나 이용자에게 제공하는 경우 해당 이용자가 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.

                    운영자는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 이용자의 개인정보를 보호하기 위해서 자신의 ID와 비밀번호를 철저히 관리하고 책임을 져야 합니다.`}
            </pre>
          </pre>

          <pre>
            <strong>
              제9조 [개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항]
            </strong>
            {`회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                    ① 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게최적화된 정보 제공을 위해 사용됩니다.
                    ② 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
                    ③ 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제10조 [개인정보 보호책임자에 관한 사항]</strong>
            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보 보호책임자를 지정하고 있습니다.
            <pre className="graybox">
              {`1.개인정보 보호책임자
                    이름 : ㅇㅇㅇ전화 : 메일 : ㅇㅇㅇㅇ@회사.co.kr
                    2.개인정보 보호 담당부서
                    부서 : 이름 : 전화 : 메일 : ㅇㅇㅇㅇ@회사.co.kr
                    3.기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                    - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)- 경찰청 사이버안전국(cyberbureau.police.go.kr / 국번없이 182)`}
            </pre>
          </pre>

          <pre>
            <strong>제11조 [개인정보 처리 방침의 변경에 관한 사항]</strong>이
            개인정보 처리 방침은 2021. 00. 00 부터 적용됩니다. 현
            개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
            7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만,
            개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한
            변경이 있을 경우에는 최소 30일 전에 고지합니다.
            <p className="he10"></p>
            {`- 공고 일자 : 2021년 9월 1일.
                    - 시행 일자 : 2021년 9월 1일.`}
          </pre>
        </div>
      </div>
    </Container>
  );
};
