
import React,{useState} from "react";
import { SendBox } from "./SendBox";
import { SendBoxView } from "./SendBoxView";
import SendProfileStatistic from "./SendProfileStatistic";
import SendStatistic from "./SendStatistic";
import SendTemplateStatistic from "./SendTemplateStatistic";


const SendResult = () => {
    const [activeId, setActiveId] = useState(1);
    const [activeContent, setActiveContent] = useState(<SendStatistic />);
    
    const tabMenu = [
        { id: 1, name: "전송통계", content: <SendStatistic />},
        { id: 2, name: "채널별 통계", content:<SendProfileStatistic />},
        { id: 3, name : "템플릿별 통계", content: <SendTemplateStatistic /> },
 
    ]
    return (
        <div className="container">
            
            <ul className="tabmenu">
                {tabMenu.map((value) => (
                    <li className={activeId === value.id ? "active" : null}>
                        <a href="/" onClick={function (e) {
                            e.preventDefault();
                            setActiveId(value.id);
                            setActiveContent(value.content);
                        }}>{value.name}</a>
                    </li>
                ))}
            </ul>
            {activeContent}                  
                
        </div>
    );
};

export default SendResult;