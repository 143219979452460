import React, { useState, useEffect, useCallback } from "react";
import { Button } from "component/Common";
import { Agreement } from "views/terms/Agreement";
import { Privacy } from "views/terms/Privacy";
import { AntiSpam } from "views/terms/AntiSpam";
import { useNavigate } from "react-router-dom";

export const JoinStep1 = () => {
  const navigate = useNavigate();
  const [checkedInputs, setCheckedInputs] = useState([]);
  const [checkList, setCheckList] = useState([
    {
      id: 1,
      title: "서비스 이용약관",
      isEssential: true,
      checked: false,
      isVisible: false,
      label: "checkAgree",
      content: <Agreement />,
    },
    {
      id: 2,
      title: "개인정보 수집 및 이용",
      isEssential: true,
      checked: false,
      isVisible: false,
      label: "checkPrivacy",
      content: <Privacy />,
    },
    {
      id: 3,
      title: "스팸방지정책",
      isEssential: true,
      checked: false,
      isVisible: false,
      label: "checkSpam",
      content: <AntiSpam />,
    },
    {
      id: 4,
      title: "광고성 정보 수신",
      isEssential: false,
      checked: false,
      isVisible: false,
      label: "checkSelectAll",
      content: <Agreement />,
    },
  ]);

  // 필수 약관의 id값만 추출
  const essentialList = checkList
    .filter((ele) => ele.isEssential)
    .map((ele) => ele.id);

  // 전체 checked
  const onCheckedAll = useCallback(
    (checked) => {
      if (checked === false) {
        const id = [];
        checkList.forEach((v) => id.push(v.id));
        setCheckedInputs(id);
      } else {
        setCheckedInputs([]);
      }
      // checkList의 checked 상태값 업데이트
      setCheckList(
        checkList.map((value) =>
          value.checked === false
            ? { ...value, checked: true }
            : { ...value, checked: false }
        )
      );
    },
    [checkList]
  );

  // 개별 check
  const changeHandler = useCallback(
    (checked, id) => {
      if (checked === false) {
        setCheckedInputs([...checkedInputs, id]);
      } else {
        // 체크 해제
        setCheckedInputs(checkedInputs.filter((el) => el !== id));
      }
      // checkList의 checked 상태값 업데이트
      setCheckList(
        checkList.map((value) =>
          value.id === id && value.checked === false
            ? { ...value, checked: true }
            : { ...value, checked: false }
        )
      );
    },
    [checkedInputs]
  );

  // 보기 닫기 버튼 컨트롤
  const controlOpenClose = (id) => {
    setCheckList(
      checkList.map((checkList) =>
        checkList.id === id
          ? { ...checkList, isVisible: !checkList.isVisible }
          : checkList
      )
    );
  };

  // 필수약관 선택 여부에 따른 회원가입 진행 프로세스
  const Continue = (event) => {
    event.preventDefault();
    if (essentialList.every((i) => checkedInputs.includes(i)) === true) {
      sessionStorage.setItem("step1", true);
      navigate("/account/joinStep2");
    } else {
      alert("필수 동의 약관을 확인 후 체크해주세요.");
      return;
    }
  };

  return (
    <div className="terms-container">
      <div className="account-contents join-contents">
        <article className="sub-join02-check-list total-add-css">
          {checkList.map((value) => (
            <div className="check-list-item">
              <dl className="check-inner">
                <dt className="title-con">
                  <div className="">
                    <span
                      className="arrow"
                      onClick={() => {
                        controlOpenClose(value.id);
                      }}
                    >
                      {value.isVisible ? "닫기" : "보기"}
                    </span>
                    <strong className="check-title">
                      <fieldset className="gs-custom-checkbox">
                        <span className="checkbox-item">
                          <input
                            type="checkbox"
                            id={value.id}
                            checked={
                              checkedInputs.includes(value.id) ? true : false
                            }
                          />
                          <label
                            for={value.label}
                            onClick={(e) => {
                              changeHandler(e.currentTarget.checked, value.id);
                            }}
                            checked={
                              checkedInputs.includes(value.id) ? true : false
                            }
                            className={
                              checkedInputs.includes(value.id)
                                ? "checked"
                                : null
                            }
                          >
                            <em
                              className={
                                value.isEssential ? "essential" : "choice"
                              }
                            >
                              {value.isEssential ? "(필수)" : "(선택)"}
                            </em>
                            {value.title}
                          </label>
                        </span>
                      </fieldset>
                    </strong>
                  </div>
                </dt>
                <dd className={value.isVisible ? "text-con add" : "text-con"}>
                  <div className="custom-scrollbar-wrapper">
                    <div className="scroll-object-box mCustomScrollbar _mCS_2 mCS_no_scrollbar">
                      <div
                        id="mCSB_2"
                        className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside"
                        tabindex="0"
                      >
                        <div
                          id="mCSB_2_container"
                          className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                          dir="ltr"
                        >
                          <div className="service-txt-scroll">
                            {value.content}
                          </div>
                        </div>
                        <div
                          id="mCSB_2_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_2_scrollbar mCS-dark mCSB_scrollTools_vertical"
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_2_dragger_vertical"
                              className="mCSB_dragger"
                            >
                              <div className="mCSB_dragger_bar"></div>
                            </div>
                            <div className="mCSB_draggerRail"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          ))}
          <div className="top-total-agreement">
            <fieldset className="gs-custom-checkbox">
              <span className="checkbox-item">
                <input
                  type="checkbox"
                  id="checkAll"
                  name="checkAll"
                  checked={
                    checkedInputs.length === 0
                      ? false
                      : checkedInputs.length === checkList.length
                      ? true
                      : false
                  }
                />
                <label
                  for="checkAll"
                  onClick={(e) => onCheckedAll(e.target.checked)}
                  checked={
                    checkedInputs.length === 0
                      ? false
                      : checkedInputs.length === checkList.length
                      ? true
                      : false
                  }
                >
                  필수 및 선택 약관 전체 동의합니다.
                </label>
              </span>
            </fieldset>
          </div>
        </article>
        <Button
          buttonName="button-bgc-blue mt20 wid100"
          buttonValue="회원가입 진행"
          onChangePage={Continue}
        />
      </div>
    </div>
  );
};
