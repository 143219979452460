import React,{useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "component/Common";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { PopUpLayout } from "component/popUp/PopUpLayout";
import {
    LayerPopSendTestMsg, // 테스트전송
    LayerPopSendMsg, // 전송하기
    LayerPopAdvertisingGuide, // 광고문자 전송가이드
    LayerPopLoadMsg, // 문구 불러오기
    LayerPopAccountCreation, // 전용계좌 생성하기 (선불만 해당)
    LayerPopReceiveNumber, // 수신번호 - 엑셀 대량전송
    LayerPopReceiveNumberError, // 수신번호 - 엑셀 대량전송 - 오류검사 - 오류건수
    LayerPopImportAddress, // 수신번호 - 주소록 불러오기
} from "component/popUp/PopUp";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
    textField: {
      width: 353,
    }
}));

export const SendElectionMessage = () => {
    const [importAddress, popupImportAddress] = useState(false); // 수신번호 - 주소록 불러오기
    const [receiveNumberFile, popupReceiveNumberFile] = useState(false); // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

    const [chekedImgDefault, setChekedImgDefault] = React.useState("uploadImage_1"); // 이미지 첨부 - 라디오 defaultchecked
    const [activeUploadImage, setActiveUploadImage] =React.useState(null); // 이미지 첨부
    
    const [contractAllSelected, setContractAllSelected] = useState(false); // 광고성 정보 여부 - defaultchecked
    const [advertisingText, popupAdvertisingText] = useState(false); // 광고문자 전송가이드
    const [adName, setAdName] = useState(null); // 광고성 정보체크 - 업체명 또는 서비스명
    const [advertisement, setAdvertisement] = useState(null); // 광고성 정보체크 - 무료거부번호

    const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
    const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창
    
    const [chekedId, setChekedId] = React.useState("id3_1"); // 전송시간
    const [activeContent, setActiveContent] =React.useState(null); // 전송시간- 예약 datetype
  
    const [sendTestMsg, popupSendTestMsg] = useState(false); // 테스트 전송
    const [sendMsgConfirm, popupSendMsgConfirm] = useState(false); // 전송하기
  
    const [charge, setCharge] = useState(false); //전송예상결과에서 충전버튼 클릭 여부 (선불계정 only)

  /*********************************** 수신번호 관련 ***********************************/

  let receiveCount = '19998'; //수신번호 총 건수

  const AddReceiveNumber = (event) => { 
      //수신번호 1만건 기준 이벤트 적용

      event.preventDefault();

      if (receiveCount >= 10000) {
      }
      else {
      }
  };

  const OpenAddressBook = (event) => {
      // 주소록 불러오기

    event.preventDefault();
    popupImportAddress(true);
  };

  const OpenReceiveNumberFile = (event) => {
      // 엑셀 대량전송

      event.preventDefault();
      popupReceiveNumberFile(true);
  };

  const handleDeleteNewUser = (event) => {
      // 수신번호 카드의 X버튼

      event.preventDefault();
  };

  const RecipientAllDelete = (event) => {
      // 수신번호 전체삭제

    event.preventDefault();
  }
/*********************************** 수신번호 관련 end***********************************/
/*********************************** 발신번호 인증 ***********************************/
    const handleCertification = (event) => {
        // 수신번호가 1만건 이상일 때 발신번호 인증
        
        event.preventDefault();
    };
/*********************************** 발신번호 인증 end ***********************************/
/*********************************** 이미지 첨부***********************************/
    const uploadImage = [
        { id: "uploadImage_1", value: "없음", content : null },
        { id: "uploadImage_2", value : "이미지", content : <ImageUpload/>}
    ]
/*********************************** 이미지 첨부 end ***********************************/
/*********************************** 메시지 내용 ***********************************/
    function getAdvertisement(event)  {
        //광고여부 체크

        if(event.target.checked)  {
            setAdvertisement(<Advertisement />);
            setAdName(<AdName />);
        }else {
            setAdvertisement(null);
            setAdName(null);
        }
    }

    const AdvertisingGuide = (event) => {
        // 광고문자 전송가이드

        event.preventDefault();
        popupAdvertisingText(true);
    };

    const LoadMsg = (event) => {
        // 문구 불러오기

        event.preventDefault();
        popupLoadMsg(true);
    };

    const SaveMsg = (event) => {
        // 문구 저장하기

        event.preventDefault();
    };

    const showMergeSettings= () => {
        // 머지설정

        if (mergeVariableState) {
            setMergeVariableState(false);
        }
        else {
            setMergeVariableState(true);
        }
    }
/*********************************** 메시지 내용 end ***********************************/
/*********************************** 전송시간 ***********************************/
    const sendTime = [
        { id: "id3_1", value: "즉시", content : null },
        { id: "id3_2", value : "예약", content : <Reservation/>}
    ]
/*********************************** 전송시간 end ***********************************/
/*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 (선불only) ***********************************/
    const showChargeAccount= () => {
        if (charge) {
            setCharge(false);
        }
        else {
            setCharge(true);
        }
    }
/*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 end (선불only) ***********************************/
/*********************************** 테스트전송 컨펌창 ***********************************/
    function getTestSendMsg(event) {
        event.preventDefault();
        popupSendTestMsg(true);
    }
/*********************************** 테스트전송 컨펌창 end ***********************************/
/*********************************** 전송하기 컨펌창 ***********************************/
    const SendMsgConfirm = (event) => {
        event.preventDefault();
        popupSendMsgConfirm(true);
    };
/*********************************** 전송하기 컨펌창 end ***********************************/

    return (
            <>
                <div >
                    <div className="write-left-inner receive-area">
                        <div className="write-tit-con clearfix">
                            <div className="bluebox mt20 mb30 clearfix">
                                <div className="text-left size14 mb10">현재 발송 가능한 건수</div>
                                <div className="text-right">SMS 10건 / LMS 10건 / MMS 10건</div>
                            </div>
                            <p className="line"></p>
                        </div>
                    </div>
                    <div className="write-left-inner receive-area">
                        <div className="write-tit-con clearfix">
                            <div className="tit-left select-tit"><span className="required">수신번호</span></div>
                            <div className="tit-right">
                                <div className="gs-custom-input-btn-group">
                                    <input type="text" id="" name="phone" title="수신번호" placeholder="수신번호 (숫자만 입력)" />
                                    <Button buttonName="button-bgc-dark" buttonValue="추가" onChangePage={AddReceiveNumber}/>
                                </div>
                            </div>
                        </div>
                        <div className="write-number-down-con">
                            <div className="custom-scrollbar-wrapper">
                                <div className="scroll-object-box-outside recipientInfo mCustomScrollbar _mCS_1 mCS_no_scrollbar" ><div id="mCSB_1" className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_outside" tabindex="0"><div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" dir="ltr">
                                    <div className="scroll-number-inner" id="recipientInfo">
                                    <ul className="chip_wrap">
                                        <li>
                                            010-2100-0000
                                            <a href="/" onClick={handleDeleteNewUser}>
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    className="gray999 ml10"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={OpenReceiveNumberFile} className="blue">파일 불러오기 (294건)</a>
                                            <a href="/" onClick={handleDeleteNewUser}>
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    className="gray999 ml10"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                </div></div><div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical"><div className="mCSB_draggerContainer"><div id="mCSB_1_dragger_vertical" className="mCSB_dragger"><div className="mCSB_dragger_bar"></div></div><div className="mCSB_draggerRail"></div></div></div></div>
                            </div>
                            <div className="write-number-down-info">
                                <span className="case" id="recipientCnt">총 295 건</span>
                                <a href="/" onClick={RecipientAllDelete} className="blue underline ml10">전체삭제</a>
                            </div>
                        </div>
                        <div className="write-number-down-btn write-layer-bottom-con">
                            <ul className="clearfix button-col2">
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="주소록 불러오기" onChangePage={OpenAddressBook}/>
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="엑셀전송 및 변수설정" onChangePage={OpenReceiveNumberFile}/>
                                </li>
                            </ul>
                        </div>
                        <p className="line"></p>
                        {receiveNumberFile && (
                            <PopUpLayout onClose={popupReceiveNumberFile} poptitle="엑셀전송 및 변수설정" size="medium">
                                <LayerPopReceiveNumber />
                            </PopUpLayout>
                        )}
                    </div>
                    <div className="write-left-inner" id="sendNumber">
                        <div className="write-tit-con clearfix">
                            <div className="tit-left select-tit"><span className="required">발신번호</span></div>
                            <div className="tit-right">
                                <div className="gs-custom-select h40-select gray-select buttonWith">
                                    <select id="callbackList" name="phone" data-fakeform-index="0" className={receiveCount >= 10000 ? "" : "wid100"}>
                                        <option value="">02-1234-5678(기본발신)</option>
                                    </select>
                                    {/* {
                                        receiveCount >= 10000 //수신번호 1만건 이상인지 확인
                                        ? <Button buttonName="button-bgc-dark" buttonValue="발신번호 인증" onChangePage={handleCertification}/>
                                        : null
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* {
                            receiveCount >= 10000 //수신번호 1만건 이상인지 확인
                            ? <p className="list-item-state red mt5">※ 1만건 이상 발송 시 발신번호 인증 후 메시지 발송이 가능합니다.</p>
                            : null
                        } */}
                        <p className="line"></p>
                    </div>
                    <div className="write-left-inner">
                        <div className="write-tit-con clearfix">
                            <div className="tit-left select-tit"><span className="required">이미지첨부</span></div>
                            <div className="tit-right">
                                <div className="gs-custom-select buttonWith">
                                    <div className="Radiobox">
                                        {uploadImage.map((value) => (
                                        <>
                                            <input type="radio" name="uploadImage" id={value.id} checked={chekedImgDefault === value.id} onClick={function (e) {
                                            setActiveUploadImage(value.content);
                                            setChekedImgDefault(value.id);
                                        }} />
                                        <label htmlFor={value.id}>{value.value}</label>
                                        </>   
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {activeUploadImage}
                        <p className="line"></p>
                    </div>
                    <div className="write-left-inner receive-area">
                        <div className="write-tit-con clearfix">
                            <div className="tit-left select-tit"><span className="required">메시지 내용</span></div>
                            <div className="tit-right">
                                <fieldset className="gs-custom-checkbox">
                                    <span className="checkbox-item">
                                        <input type="checkbox" id="advertising" name="" onClick={getAdvertisement} />
                                        <label for="advertising" className={contractAllSelected ? "checked" : null}>광고성 정보가 포함되어 있습니다</label>        
                                    </span>
                                </fieldset>
                                <Button buttonName="button-bgc-dark" buttonValue="광고문자 전송가이드(필독)" onChangePage={AdvertisingGuide}/>
                            </div>
                        </div>
                        <div id="msgArea" className="write-input-con change-effect-bg">
                            {/* 광고성 정보 체크 시에만 노출 */}
                            {adName}
                            {/* 광고성 정보 체크 시에만 노출 */}
                            <div className="write-input-down-con">
                                <textarea name="" id="msgContents" className="gs-custom-textarea change-effect" placeholder="내용 입력
                                - 90 byte 초과 시 LMS 자동전환
                                - 파일첨부 시 MMS 자동전환" maxlength="2000">
                                    
                                </textarea>
                                <div className="write-input-down-info">
                                    <span className="msgType">SMS</span>
                                    <span className="byte" id="msgByte">47 / <em className="red">90 Byte</em></span>
                                </div>
                            </div>
                            {/* 광고성 정보 체크 시에만 노출 */}
                            {advertisement}
                        </div>
                        <div className="write-number-down-btn mt20">
                            <ul className="clearfix button-col3">
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="문구 불러오기" onChangePage={LoadMsg}/>
                                </li>
                                <li>
                                    <Button buttonName="button-border-gray" buttonValue="문구 저장하기" onChangePage={SaveMsg}/>
                                </li>
                                <li>
                                    <Button buttonName={mergeVariableState?  "button-bgc-blue" : "button-border-gray"} buttonValue="머지설정" onChangePage={()=>showMergeSettings()}/>
                                   {mergeVariableState?  <MergeSettings/> : null}
                                </li>
                            </ul>
                        </div>
                        <p className="line"></p>
                    </div>
                    
                    <div className="write-left-inner">
                        <div className="write-tit-con clearfix">
                            <div className="tit-left select-tit"><span className="required">전송시간</span></div>
                            <div className="tit-right">
                                <div className="gs-custom-select buttonWith">
                                    <div className="Radiobox">
                                        {sendTime.map((value) => (
                                        <>
                                            <input type="radio" name="RCSCardType" id={value.id} checked={chekedId === value.id} onClick={function (e) {
                                            setActiveContent(value.content);
                                            setChekedId(value.id);
                                        }} />
                                        <label htmlFor={value.id}>{value.value}</label>
                                        </>   
                                        ))}
                                    </div>
                                    {activeContent}
                                </div>
                            </div>
                        </div>
                        <p className="line"></p>
                    </div>
                    <div className="write-total-con clearfix" id="">
						<div className="write-total-left-con">
							<div className="write-total-top">
								<div className="inner clearfix">
									<div className="left">전송 금액</div>
									<div className="right">
										<p className="price sendPrice">4,720 원</p><span className="txt">(부가세 포함)</span>
									</div>
								</div>
							</div>
                            <div className="write-total-bottom-wrap">
                                <div className="write-total-bottom">
                                    <div className="clearfix">
                                        <div className="left">
                                            <fieldset className="gs-custom-checkbox">
                                                메시지 타입
                                            </fieldset>
                                        </div>
                                        <div className="right">
                                            <div className="gs-custom-input-btn-group">
                                                <div className="input-con">
                                                    SMS
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="write-total-bottom">
                                    <div className="clearfix">
                                        <div className="left">
                                            <fieldset className="gs-custom-checkbox">
                                                건당 요금
                                            </fieldset>
                                        </div>
                                        <div className="right">
                                            <div className="gs-custom-input-btn-group">
                                                <div className="input-con">
                                                    <span className="mr10">16</span>원
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="write-total-bottom">
                                    <div className="clearfix">
                                        <div className="left">
                                            <fieldset className="gs-custom-checkbox">
                                                총 건수
                                            </fieldset>
                                        </div>
                                        <div className="right">
                                            <div className="gs-custom-input-btn-group">
                                                <div className="input-con">
                                                    <span className="mr10">295</span>건
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 선불계정일 때만 표시 */}
                                <div className="write-total-bottom"> 
                                    <div className="clearfix">
                                        <div className="left">
                                            <fieldset className="gs-custom-checkbox">
                                                내 충전금
                                            </fieldset>
                                        </div>
                                        <div className="right">
                                            <div className="gs-custom-input-btn-group">
                                                <div className="input-con">
                                                    <span className="mr10">0</span>원
                                                    <Button buttonName="cm-highlight-btn" buttonValue="충전" onChangePage={()=>showChargeAccount()}/>
                                                    {charge?  <MyAccountInfo/> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 선불계정일 때만 표시 */}
                            </div>
						</div>
					</div>
                    <div className="write-left-inner">
                        <div className="write-tit-con clearfix">
                            <div className="button-wrap rfloat">
                                <Button buttonName="button-border-blue" buttonValue="테스트전송"  onChangePage={getTestSendMsg} /> 
                                <Button buttonName="button-bgc-blue" buttonValue="전송하기"  onChangePage={SendMsgConfirm} /> 
                            </div>
                        </div>
                    </div>
                    <p className="clear"></p>
            </div>
            {advertisingText && (
                <PopUpLayout onClose={popupAdvertisingText} poptitle="광고문자 전송 가이드">
                    <LayerPopAdvertisingGuide />
                </PopUpLayout>
            )}
            {importAddress && (
                <PopUpLayout onClose={popupImportAddress} poptitle="주소록 불러오기" size="large" overflow="clip">
                    <LayerPopImportAddress />
                </PopUpLayout>
            )}
            {loadMsg && (
                <PopUpLayout onClose={popupLoadMsg} poptitle="문구 불러오기">
                    <LayerPopLoadMsg />
                </PopUpLayout>
            )}
            {sendTestMsg && (
                <PopUpLayout onClose={popupSendTestMsg} poptitle="테스트전송">
                    <LayerPopSendTestMsg />
                </PopUpLayout>
            )}
            {sendMsgConfirm && (
                <PopUpLayout onClose={popupSendMsgConfirm} poptitle="메시지 발송">
                    <LayerPopSendMsg />
                </PopUpLayout>
            )}
            </>
                
            
    );
};

export const ErrorCheckResults = () => {
    // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

    const [errorList, popupErrorList] = useState(false);
    const OpenErrorList = (event) => {
        event.preventDefault();
        popupErrorList(true);
    };
    return(
        <>
        <div className="write-total-con mt10 clearfix" id="">
            <div className="write-total-left-con">
                <div className="write-total-bottom-wrap">
                    <div className="write-total-bottom">
                        <div className="clearfix">
                            <div className="left">
                                <fieldset className="gs-custom-checkbox">
                                    총 건수
                                </fieldset>
                            </div>
                            <div className="right">
                                <div className="gs-custom-input-btn-group">
                                    <div className="input-con">
                                        <span className="mr10">295</span>건
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="write-total-bottom">
                        <div className="clearfix">
                            <div className="left">
                                <fieldset className="gs-custom-checkbox">
                                    정상 건수
                                </fieldset>
                            </div>
                            <div className="right">
                                <div className="gs-custom-input-btn-group">
                                    <div className="input-con">
                                        <span className="mr10">293</span>건
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="write-total-bottom">
                        <div className="clearfix">
                            <div className="left">
                                <fieldset className="gs-custom-checkbox">
                                    오류 건수
                                </fieldset>
                            </div>
                            <div className="right">
                                <div className="gs-custom-input-btn-group">
                                    <div className="input-con">
                                        <a href="/" className="red" onClick={OpenErrorList}> <span className="mr10 redline">2</span>건</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {errorList && (
            <PopUpLayout onClose={popupErrorList} poptitle="오류상세" size="medium">
                <LayerPopReceiveNumberError />
            </PopUpLayout>
        )}
        </>
    );
}

export function ImageUpload(){
    // 이미지첨부

    const LoadingImg = (event) => {
        event.preventDefault();
    };
    return(
        <>
            <div className="mt20 mb20">
                가로기준 최대 640px입니다.<br/>
                640보다 큰 이미지는 640으로 자동 조정되어 발송됩니다.<br/>
                <br/>
                * 크기조정시 가로X세로 비율이 약 1:2 이상인 경우 용량초과로<br/>
                첨부되지 않을 수 있으니 확인 후 등록 바랍니다.
            </div>
            <div className="input-con">
                <div className="gs-custom-input-btn-group">
                    <input type="text" id="" name="" title="첨부이미지" placeholder="등록된 이미지가 없습니다." disabled/>
                    <Button buttonName="button-bgc-gray" buttonValue="파일등록" onChangePage={LoadingImg}/>
                </div>
            </div>
            
        </>
    );
}

export function AdName(){
    // 메시지 내용 - 광고성 정보 체크 시 - 업체명 또는 서비스명 입력란
    return(
        <div className="write-input-top-con clearfix adMsgInfo">
            <div className="tit-con tit01">(광고)</div>
            <div className="tit-con tit02">											
                <input type="text" id="" name="" title="제목" placeholder="업체명 또는 서비스명 입력" />
            </div>
        </div>
    );
}

export function Advertisement(){
    // 메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
    return(
        <div className="write-input-top-con advertisement">
            <input type="text" id="" name="" title="무료거부번호" placeholder="080 무료거부번호 입력" />
        </div>
    );
}

export function MergeSettings(){
    // 메시지 내용 - 머지설정
    const SetVariableName = (event) => {
        event.preventDefault();
    };
    const SetVariable1 = (event) => {
        event.preventDefault();
    };
    const SetVariable2 = (event) => {
        event.preventDefault();
    };
    const SetVariable3 = (event) => {
        event.preventDefault();
    };
    const SetVariable4 = (event) => {
        event.preventDefault();
    };
    return(
        <div className="variable-info-con" id="">
            <div>
                <table className="variable-info-tb" id="">
                    <tbody>
                        <tr>
                            <td byte="10"><Button buttonName="" buttonValue="#{이름}" onChangePage={SetVariableName} /></td>
                            <td byte="20"><Button buttonName="" buttonValue="#{1}" onChangePage={SetVariable1} /></td>
                            <td byte="20"><Button buttonName="" buttonValue="#{2}" onChangePage={SetVariable2} /></td>
                            <td byte="20"><Button buttonName="" buttonValue="#{3}" onChangePage={SetVariable3} /></td>
                            <td byte="20"><Button buttonName="" buttonValue="#{4}" onChangePage={SetVariable4} /></td>
                        </tr>
                </tbody></table>
            </div>
        </div>
    );
}

export function Reservation(){
    // 전송시간 - 예약

    const classes = useStyles();
    return(
        <span>
            <TextField
                id="datetime-local"
                type="datetime-local"
                defaultValue= {new Date()}
                className={classes.textField}
                InputLabelProps={{
                shrink: true,
                }}
            />
        </span>
    );
}

export function MyAccountInfo(){
    // 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)

    const [accountCreation, popupAccountCreation] = useState(false);

    const handleAccountNumber = (event) => {
        event.preventDefault();
        popupAccountCreation(true);
    };

    return(
        <div className="my-account-info">
            {/* 전용 계좌 생성 전 */}
            <div className="clearfix">
                <div className="left">
                    <fieldset className="gs-custom-checkbox">
                        생성한 전용계좌가 없습니다.
                    </fieldset>
                </div>
                <div className="right">
                    <a href="/" onClick={handleAccountNumber} className="underline">내 전용계좌 생성하기</a>
                </div>
            </div>
            {accountCreation && (
                <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
                    <LayerPopAccountCreation />
                </PopUpLayout>
            )}
            {/* 전용 계좌 생성 후 */}
            {/* <div className="clearfix center">
                국민은행 1301-1111-1234
            </div> */}
        </div>
    );
}

export function PreviewElectionMessage() {
    // 미리보기 이미지

    const style = {
        area :{
            position :'absolute',
            left: '7%',
            top: '80px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            width: '76%',
            minHeight: '70px',
            padding: '15px',
            color: '#4a4a4a',
            lineHeight: '22px',
            fontSize:'15px'
        },
        freeRefusal : {
            textDecoration: 'underline',
            color:'#625af6',
            marginLeft:'5px'
        }
    };
    return (
        <>
        <div style={style.area}>
            (광고) Hisms를 이용해주시는 회원님들을 위한 여름맞이 적립금 이벤트 실시! <br/><br/>
            15일간 누적 100만원 이상 충전 시 5% 적립 혜택을 놓치지 마세요~!<br/><br/>
            <p>무료거부<span style={style.freeRefusal}>08012345678</span></p>
        </div>
        </>
    )
}
