import React, { Component } from 'react';
import ChargeHistory from './ChargeHistory';
import TaxBillHistory from './TaxBillHistory';
import UsageHistory from './UsageHistory';

class PayInAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'chargeHistory',
    };
  }
  render() {
    let content = (
      <UsageHistory
        custType={this.props.custType}
        userType={this.props.userType}
        custGrade={this.props.custGrade}
      />
    );
    let usageActive;
    let chargeActive,
      taxBillActive = null;
    if (this.state.page === 'chargeHistory') {
      content = (
        <ChargeHistory
          custType={this.props.custType}
          userType={this.props.userType}
          custGrade={this.props.custGrade}
          balance={this.props.balance}
          handleGetBalance={this.props.handleGetBalance}
        />
      );
      usageActive = null;
      chargeActive = 'active';
      taxBillActive = null;
    } else if (this.state.page === 'usageHistory') {
      content = (
        <UsageHistory
          custType={this.props.custType}
          userType={this.props.userType}
          custGrade={this.props.custGrade}
          preRating={"2"}
        />
      );
      usageActive = 'active';
      chargeActive = null;
      taxBillActive = null;
    } else if (this.state.page === 'taxBillHistory') {
      content = <TaxBillHistory />;
      usageActive = null;
      chargeActive = null;
      taxBillActive = 'active';
    }
    return (
      <div>
        <ul className='tabmenu'>
          <li className={chargeActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: 'chargeHistory',
                });
              }.bind(this)}
            >
              충전내역
            </a>
          </li>
          <li className={usageActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: 'usageHistory',
                });
              }.bind(this)}
            >
              사용내역
            </a>
          </li>

          

          {/* <li className={taxBillActive}>
            <a
              href="/"
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: "taxBillHistory",
                });
              }.bind(this)}
            >
              세금계산서 발행내역
            </a>
          </li> */}
        </ul>
        <div>{content}</div>
      </div>
    );
  }
}
export default PayInAdvance;
