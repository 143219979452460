import React from "react";
import NotFoundImg from "assets/images/notFound.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    const contactUs = (event) => {
        event.preventDefault();
        navigate("/customer/contactUs");
    }
    const goHome = (event) => {
        event.preventDefault();
        navigate("/");
    }
    return (
        <div className="notFoundPage">
            <div className="lfloat">
                <img src={NotFoundImg} alt="Not Found" />
            </div>
            <div className="rfloat">
                <div>
                    <h2>Error 404</h2>
                    <h5>페이지를 찾을 수 없습니다.</h5>
                    <p>궁금한 점이 있으시면 언제든 고객센터를 통해 문의해 주시기 바랍니다.&nbsp;<a href="/" onClick={contactUs} className="underline blue">상담문의</a></p>
                    <button onClick={goHome}>홈으로 돌아가기</button>
                </div>
            </div>
            <p className="clear"></p>
        </div>
    );
}
export default NotFound;