import React from 'react';
import SignOutMember from './SignOutMember';
import RefundRequest from './RefundReqeust';
import { postRequest, getRequest } from 'component/Axios';
import { withRouter } from 'react-router-dom';

class SignOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'signOutMember',
      infoData: [],
      signOutInfo: {
        amount: 0,
        comm: 0,
        refundAmount: 0,
        reasonType: '',
        remark: null,
      },
    };
  }

  handleGetCustType = () => {
    const url = '/user/custInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const json = JSON.parse(result.resultData);
          if (json.userType !== '1') {
            alert('잘못된 접근입니다.');
          }
        }
      })
      .catch(error => alert(error));
  };

  componentDidMount() {
    const url = '/user/userInfo';
    getRequest
      .fetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          const payRating = jsonArr[0].preRating;

          this.setState({ infoData: jsonArr[0] });
          let amount = 0;
          let comm = 0;
          let refundAmount = 0;
          amount = jsonArr[0].balance;
          comm = Math.floor(amount * 0.1 * 100) / 100.0;
          refundAmount = amount - comm;
          this.setState({
            signOutInfo: {
              amount: amount,
              comm: comm,
              refundAmount: refundAmount,
              reasonType: '',
              remark: null,
              fnclCmpy: '',
              accountNo: '',
              owner: '',
              refundRemark: '',
            },
          });

          if (payRating === '1') {
            // 후불
            this.setState({ userPayment: 'after' });
          } else {
            this.setState({ userPayment: 'before' });
          }
        }
      })
      .catch(error => alert(error));
    this.handleGetCustType();
  }

  changeState = () => {
    this.setState({ page: 'refundRequest' });
  };

  render() {
    let contents = null;
    let signOutActive = 'active';
    let refundActive = null;
    if (this.state.page === 'signOutMember') {
      signOutActive = 'active';
      refundActive = null;
      contents = (
        <SignOutMember
          changeState={this.changeState}
          signOutInfo={this.state.signOutInfo}
        />
      );
    } else if (this.state.page === 'refundRequest') {
      signOutActive = null;
      refundActive = 'active';
      contents = <RefundRequest signOutInfo={this.state.signOutInfo} />;
    }

    return (
      <div className='container'>
        <ul className='tabmenu'>
          <li className={signOutActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();

                this.setState({
                  page: 'signOutMember',
                });
              }.bind(this)}
            >
              탈퇴신청
            </a>
          </li>
          <li className={refundActive}>
            <a
              href='/'
              onClick={function (e) {
                e.preventDefault();
                this.setState({
                  page: 'refundRequest',
                });
              }.bind(this)}
            >
              환불신청
            </a>
          </li>
        </ul>

        {contents}
      </div>
    );
  }
}

export default SignOut;
