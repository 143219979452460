import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "component/Common";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import {
  LayerPopSendTestMsg, // 테스트전송
  LayerPopSendMsg, // 전송하기
  LayerPopAdvertisingGuide, // 광고문자 전송가이드
  LayerPopLoadMsg, // 문구 불러오기
  LayerPopAccountCreation, // 전용계좌 생성하기 (선불만 해당)
  LayerPopReceiveNumber, // 수신번호 - 엑셀 대량전송
  LayerPopReceiveNumberError, // 수신번호 - 엑셀 대량전송 - 오류검사 - 오류건수
  LayerPopImportAddress, // 수신번호 - 주소록 불러오기
  LayerPopMessageImgUpload,
  LayerPopTestCardPay,
  LayerPopRechargeInfo, // 전송 시 잔액부족 안내
  LayerBatchSend
} from "component/popUp/PopUp";
import { postRequest, getRequest } from "component/Axios";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import defaultImg from "assets/images/default_image.png";
import ReceiveList from "../../../component/sendMessage/receiveList/ReceiveList";
import Loader from "../../../component/Loader";

/* datetype 스타일 */
const useStyles = makeStyles((theme) => ({
  textField: {
    width: 353,
  },
}));

export const SendTextMessage = (props) => {
  const [importAddress, popupImportAddress] = useState(false); // 수신번호 - 주소록 불러오기
  const [receiveNumberFile, popupReceiveNumberFile] = useState(false); // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

  const [chekedImgDefault, setChekedImgDefault] =
    React.useState("uploadImage_1"); // 이미지 첨부 - 라디오 defaultchecked
  const [activeUploadImage, setActiveUploadImage] = React.useState(null); // 이미지 첨부

  const [contractAllSelected, setContractAllSelected] = useState(false); // 광고성 정보 여부 - defaultchecked
  const [advertisingText, popupAdvertisingText] = useState(false); // 광고문자 전송가이드
  const [batchSend, popupBatchSend] = useState(false); // 광고문자 전송가이드
  const [adName, setAdName] = useState(null); // 광고성 정보체크 - 업체명 또는 서비스명
  const [advertisement, setAdvertisement] = useState(null); // 광고성 정보체크 - 무료거부번호

  const [loadMsg, popupLoadMsg] = useState(false); // 문구 불러오기
  const [mergeVariableState, setMergeVariableState] = useState(false); // 머지설정 변수 창

  const [chekedId, setChekedId] = React.useState("id3_1"); // 전송시간
  const [activeContent, setActiveContent] = React.useState(null); // 전송시간- 예약 datetype

  const [sendTestMsg, popupSendTestMsg] = useState(false); // 테스트 전송
  const [sendMsgConfirm, popupSendMsgConfirm] = useState(false); // 전송하기

  const [charge, setCharge] = useState(false); //전송예상결과에서 충전버튼 클릭 여부 (선불계정 only)
  /*********************************** 수신번호 관련 ***********************************/
  const navigate = useNavigate();
  const [receiveCount, setReceiveCount] = useState(0);
  const [viewTableRows, setviewTableRows] = useState([]); // 수신 목록
  const [fileRegIdList, setFileRegIdList] = useState([]);

  const [phone, setPhone] = useState("");
  const [phoneCount, setPhoneCount] = useState(0);
  const [contentPhone, setContentPhone] = useState([]);
  const [totCount, setTotCount] = useState(0);
  const [callbackList, setCallbackList] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [selectRegId, setSelectRegId] = useState("");
  const [smsUnit, setSmsUnit] = useState(0);
  const [lmsUnit, setLmsUnit] = useState(0);
  const [mmsUnit, setMmsUnit] = useState(0);
  const [contents, setContents] = useState("");
  const [msgType, setMsgType] = useState("SMS");
  const [msgByte, setMsgByte] = useState(0);
  const [limitByte, setLimitByte] = useState(90);
  const [uploadFile, setUploadFile] = useState(null);
  const [adCheck, setAdCheck] = useState(false);
  const [subject, setSubject] = useState("");
  const [contentsHead, setContentsHead] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");
  const [unitPrce, setUnitPrce] = useState(0);
  const [balance, setBalance] = useState(0);
  const [url, setUrl] = useState(null);
  const [popupImgUpload, setPopupImgUpload] = useState(false);
  const [choiceTime, setChoiceTime] = useState(null);
  const [selectIdx, setSelectIdx] = useState(0);
  const [rating, setRating] = useState("2");
  const [uploadType, setUploadType] = useState("1");
  const [selectType, setSelectType] = useState("1");
  const [rechargeInfo, popupRechargeInfo] = useState(false);
  const [sendType, setSendType] = useState(null);
  const repAd = useRef(null); // 광고 체크박스
  const [retainCheck, setRetainCheck] = useState(false);

  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [listUploadType, setListUploadType] = useState("direct");

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const handlePopClose = () => {
    setSelectRegId("");
    popupReceiveNumberFile(false);
  };

  useEffect(() => {
    handleCallback();
  }, []);

  useEffect(() => {
    setRetainCheck(false);
    handleContents(contents);
  }, [uploadFile, adCheck, retainCheck]);

  const handleCallback = () => {
    setLoading(true);
    const url = "/callback/getSelect/";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCallbackList(jsonArr);
          handleUserInfo();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleUserInfo() {
    const url = "/user/userInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setDefaultUnit(jsonArr[0]);

          let uPrce = "0";
          if (jsonArr[0].smsPrce) uPrce = jsonArr[0].smsPrce;

          setUnitPrce(uPrce);
          setBalance(jsonArr[0].balance);
          setRating(jsonArr[0].preRating);

          sessionStorage.setItem("pay", jsonArr[0].balance);
          setLoading(false);
        }
      })
      .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));

    const payUrl = "/user/payInfo";

    getFetch(payUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
  }

  const closeSendMsgPop = () => {
    popupSendMsgConfirm(false);
  };

  const closeSendMsgTestPop = () => {
    popupSendTestMsg(false);
  };

  const openImagePop = () => {
    setPopupImgUpload(true);
  };

  const closeImagePop = () => {
    setPopupImgUpload(false);
  };

  const setImgFile = (file) => {
    setUploadFile(file);
  };

  const setImageUrl = (url) => {
    setUrl(url);
    props.setIsPreviewImg(true);
    document.getElementById("imagePreviewSrc").src = url;
    //ReactDOM.render(msg, elm01)
  };

  const setImageFileName = (name) => {
    document.getElementById("imgFileName").value = name;
  };

  const handleContentsName = (text) => {
    const txtArea = document.getElementById("msgContents");
    const txtValue = txtArea.value;
    let selectPos = txtArea.selectionStart;
    const beforeTxt = txtValue.substring(0, selectPos);
    const afterTxt = txtValue.substring(txtArea.selectionEnd, txtValue.length);
    const addTxt = text;
    handleContents(beforeTxt + addTxt + afterTxt);
    selectPos = selectPos + addTxt.length;
    txtArea.selectionStart = selectPos;
    txtArea.selectionEnd = selectPos;
    txtArea.focus();
  };

  const setDefaultUnit = (userData) => {
    const monthLimit = userData.monthLimit;
    const monthSend = userData.monthSend;
    const monthUse = monthLimit - monthSend;
    const dayLimit = userData.dayLimit;
    const daySend = userData.daySend;
    const dayUse = dayLimit - daySend;
    const preRating = userData.preRating;
    let balance = userData.balance;
    // let usePrce = dayUse;
    // if ( dayUse > monthUse )
    //     usePrce = monthUse;

    // const preRating = userData.preRating;

    // if ( preRating === "2" ) {
    //     const balance = userData.balance;
    //     if ( usePrce > balance )
    //         usePrce = balance;
    // }

    // let smsCnt = 0;
    // if ( userData.smsPrce && usePrce && userData.smsPrce != 0 )
    //     smsCnt = Math.floor(usePrce / userData.smsPrce);

    // let lmsCnt = 0;
    // if ( userData.lmsPrce && usePrce && userData.lmsPrce != 0 )
    //     lmsCnt = Math.floor(usePrce / userData.lmsPrce);

    // let mmsCnt = 0;
    // if ( userData.mmsPrce && usePrce && userData.mmsPrce != 0 )
    //     mmsCnt = Math.floor(usePrce / userData.mmsPrce);

    let replaceBalnce = 0;
    let smsCnt = 0;
    let lmsCnt = 0;
    let mmsCnt = 0;

    let smsPrce = userData.smsPrce;
    let lmsPrce = userData.lmsPrce;
    let mmsPrce = userData.mmsPrce;

    if (preRating === "1") {
      if (monthLimit === 0 && dayLimit === 0) {
        smsCnt = "∞";
        lmsCnt = "∞";
        mmsCnt = "∞";
      } else if (monthLimit != 0 && dayLimit === 0) {
        smsCnt = Math.floor((monthLimit - monthSend) / (smsPrce * 1.1));
        lmsCnt = Math.floor((monthLimit - monthSend) / (lmsPrce * 1.1));
        mmsCnt = Math.floor((monthLimit - monthSend) / (mmsPrce * 1.1));
      } else if (monthLimit === 0 && dayLimit != 0) {
        smsCnt = Math.floor((dayLimit - daySend) / (smsPrce * 1.1));
        lmsCnt = Math.floor((dayLimit - daySend) / (lmsPrce * 1.1));
        mmsCnt = Math.floor((dayLimit - daySend) / (mmsPrce * 1.1));
      } else {
        if (monthLimit - monthSend >= dayLimit - daySend) {
          smsCnt = Math.floor((dayLimit - daySend) / (smsPrce * 1.1));
          lmsCnt = Math.floor((dayLimit - daySend) / (lmsPrce * 1.1));
          mmsCnt = Math.floor((dayLimit - daySend) / (mmsPrce * 1.1));
        } else {
          smsCnt = Math.floor((monthLimit - monthSend) / (smsPrce * 1.1));
          lmsCnt = Math.floor((monthLimit - monthSend) / (lmsPrce * 1.1));
          mmsCnt = Math.floor((monthLimit - monthSend) / (mmsPrce * 1.1));
        }
      }
    }

    if (preRating === "2") {
      if (monthLimit != 0 || dayLimit != 0) {
        if (monthLimit === 0 && dayLimit != 0) {
          if (balance <= dayLimit) {
            replaceBalnce = balance;
          } else {
            replaceBalnce = dayLimit;
          }
        } else if (monthLimit != 0 && dayLimit === 0) {
          if (balance <= monthLimit) {
            replaceBalnce = balance;
          } else {
            replaceBalnce = monthLimit;
          }
        } else {
          if (balance <= monthLimit && balance <= dayLimit) {
            replaceBalnce = balance;
          } else {
            if (monthLimit <= dayLimit) {
              replaceBalnce = monthLimit;
            } else {
              replaceBalnce = dayLimit;
            }
          }
        }
      }

      if (monthLimit === 0 && dayLimit === 0) {
        smsCnt = Math.floor(balance / (smsPrce * 1.1));
        lmsCnt = Math.floor(balance / (lmsPrce * 1.1));
        mmsCnt = Math.floor(balance / (mmsPrce * 1.1));
      } else if (monthLimit != 0 && dayLimit === 0) {
        smsCnt = Math.floor((replaceBalnce - monthSend) / (smsPrce * 1.1));
        lmsCnt = Math.floor((replaceBalnce - monthSend) / (lmsPrce * 1.1));
        mmsCnt = Math.floor((replaceBalnce - monthSend) / (mmsPrce * 1.1));
      } else if (monthLimit === 0 && dayLimit != 0) {
        smsCnt = Math.floor((replaceBalnce - daySend) / (smsPrce * 1.1));
        lmsCnt = Math.floor((replaceBalnce - daySend) / (lmsPrce * 1.1));
        mmsCnt = Math.floor((replaceBalnce - daySend) / (mmsPrce * 1.1));
      } else {
        if (monthLimit - monthSend >= dayLimit - daySend) {
          if (balance <= monthLimit - monthSend) {
            smsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (smsPrce * 1.1)
            );
            lmsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (lmsPrce * 1.1)
            );
            mmsCnt = Math.floor(
              (balance - (dayLimit - daySend)) / (mmsPrce * 1.1)
            );
          } else {
            smsCnt = Math.floor((dayLimit - daySend) / (smsPrce * 1.1));
            lmsCnt = Math.floor((dayLimit - daySend) / (lmsPrce * 1.1));
            mmsCnt = Math.floor((dayLimit - daySend) / (mmsPrce * 1.1));
          }
        } else {
          if (balance <= monthLimit - monthSend) {
            smsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (smsPrce * 1.1)
            );
            lmsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (lmsPrce * 1.1)
            );
            mmsCnt = Math.floor(
              (balance - (monthLimit - monthSend)) / (mmsPrce * 1.1)
            );
          } else {
            smsCnt = Math.floor((monthLimit - monthSend) / (smsPrce * 1.1));
            lmsCnt = Math.floor((monthLimit - monthSend) / (lmsPrce * 1.1));
            mmsCnt = Math.floor((monthLimit - monthSend) / (mmsPrce * 1.1));
          }
        }
      }
    }

    if (smsCnt < 1) {
      smsCnt = 0;
    }
    if (lmsCnt < 1) {
      lmsCnt = 0;
    }
    if (mmsCnt < 1) {
      mmsCnt = 0;
    }

    if (smsCnt === Infinity) {
      smsCnt = "∞";
    }
    if (lmsCnt === Infinity) {
      lmsCnt = "∞";
    }
    if (mmsCnt === Infinity) {
      mmsCnt = "∞";
    }

    if (smsCnt != "∞" && isNaN(smsCnt)) {
      smsCnt = 0;
    }

    if (lmsCnt != "∞" && isNaN(lmsCnt)) {
      lmsCnt = 0;
    }

    if (mmsCnt != "∞" && isNaN(mmsCnt)) {
      mmsCnt = 0;
    }

    smsCnt = userData.smsPrce === undefined ? 0 : smsCnt;
    lmsCnt = userData.lmsPrce === undefined ? 0 : lmsCnt;
    mmsCnt = userData.mmsPrce === undefined ? 0 : mmsCnt;

    setSmsUnit(smsCnt);
    setLmsUnit(lmsCnt);
    setMmsUnit(mmsCnt);
  };

  function setComma(num) {
    if (num > 999) {
      const cn = num
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return cn;
    } else {
      return num.toFixed(2);
    }
  }

  function getCountTotal() {
    let num = 0;
    for (let i = 1; i <= Number(phoneCount) + 10; i++) {
      const addFile = "filePhone_" + i;
      const addPhone = "addPhone_" + i;
      const fileCnt = "fileCnt_" + i;
      if (document.getElementById(addPhone)) {
        num = Number(num) + 1;
      }

      if (document.getElementById(addFile)) {
        const cnt = document.getElementById(fileCnt).value;
        num = Number(num) + Number(cnt);
      }
    }
    setReceiveCount(num);
  }

  function handelDefaultSet() {
    for (let i = 1; i <= phoneCount; i++) {
      const addPhone = "addPhone_" + i;
      const addFile = "filePhone_" + i;
      if (document.getElementById(addPhone))
        document.getElementById(addPhone).remove();

      if (document.getElementById(addFile))
        document.getElementById(addFile).remove();
    }

    setSubject("");

    /* 이미지 */
    setChekedImgDefault("uploadImage_1");
    setUploadFile(null);
    if (document.getElementById("imagePreviewSrc"))
      document.getElementById("imagePreviewSrc").src = "";

    if (document.getElementById("imgFileName"))
      document.getElementById("imgFileName").value = "";

    handleContents(contents);

    /* 광고 */
    document.getElementById("advertising").checked = false;
    setContractAllSelected(false);
    setAdvertisement(null);
    setAdName(null);
    setAdCheck(false);

    /* 발송시간 */
    setChekedId("id3_1");
    setActiveContent(null);

    getCountTotal();
    handleUserInfo();
    handleContents("");

    setPhone("");

    /* 수신번호 목록 */
    setContentPhone([]);
    setFileRegIdList([]);
    setviewTableRows([]);
    
  }

  const handleContents = (text) => {
    const maxByte = 2000;

    const text_val = contentsHead + text + contentsFooter;

    let totalByte = 0;
    totalByte += getByte(text_val);

    if (adCheck) {
      totalByte = totalByte + 21;
    }

    if (totalByte > maxByte) {
      alert("최대 2000Byte까지만 입력가능합니다.");
    } else {
      setContents(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      if (adCheck) {
        const msg =
          "(광고)" +
          contentsHead +
          "\n" +
          text +
          "\n" +
          "무료거부 " +
          contentsFooter;
        document.getElementById("msgContentsPreview").value = msg;
      } else {
        document.getElementById("msgContentsPreview").value = text;
      }
    }
  };

  function handleSubject(e) {
    e.preventDefault();

    const text = e.target.value;
    const maxByte = 120;

    if (getByte(text) > maxByte) {
      alert("최대 120Byte까지만 입력가능합니다.");
    } else {
      setSubject(text);
    }
  }

  function handleContentsHeader(text) {
    const maxByte = 2000;
    const text_val = text + contents + contentsFooter;

    let totalByte = 0;
    totalByte += getByte(text_val);
    totalByte = totalByte + 21;

    if (totalByte > maxByte) {
      alert("최대 2000Byte까지만 입력가능합니다.");
      document.getElementById("adTitle").value = contentsHead;
    } else {
      setContentsHead(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      const msg =
        "(광고)" + text + "\n" + contents + "\n" + "무료거부 " + contentsFooter;
      document.getElementById("msgContentsPreview").value = msg;
    }
  }

  function handleContentsFooter(text) {
    const maxByte = 2000;
    const text_val = contentsHead + contents + text;

    let totalByte = 0;
    totalByte += getByte(text_val);
    totalByte = totalByte + 21;

    if (totalByte > maxByte) {
      alert("최대 2000Byte까지만 입력가능합니다.");
      document.getElementById("adNumber").value = contentsFooter;
    } else {
      setContentsFooter(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      const msg =
        "(광고)" + contentsHead + "\n" + contents + "\n" + "무료거부 " + text;
      document.getElementById("msgContentsPreview").value = msg;
    }
  }

  function setDetilInfo(totalByte) {
    if (uploadFile) {
      setMsgType("MMS");
      setLimitByte("2000");
      let uPrce = "0";
      if (infoData.mmsPrce) uPrce = infoData.mmsPrce;
      setUnitPrce(uPrce);
    } else if (totalByte > 90) {
      setMsgType("LMS");
      setLimitByte("2000");
      let uPrce = "0";
      if (infoData.lmsPrce) uPrce = infoData.lmsPrce;
      setUnitPrce(uPrce);
    } else {
      setMsgType("SMS");
      setLimitByte("90");
      let uPrce = "0";
      if (infoData.smsPrce) uPrce = infoData.smsPrce;
      setUnitPrce(uPrce);
    }
  }
  /*********************************** 수신번호 관련 end***********************************/
  /*********************************** 발신번호 인증 ***********************************/
  const handleCertification = (event) => {
    // 수신번호가 1만건 이상일 때 발신번호 인증

    event.preventDefault();
  };
  /*********************************** 발신번호 인증 end ***********************************/
  /*********************************** 이미지 첨부***********************************/
  const uploadImage = [
    { id: "uploadImage_1", value: "없음", content: null },
    { id: "uploadImage_2", value: "이미지", content: <ImageUpload /> },
  ];
  /*********************************** 이미지 첨부 end ***********************************/
  /*********************************** 메시지 내용 ***********************************/
  function getAdvertisement(event) {
    //광고여부 체크

    if (event.target.checked) {
      setAdvertisement(<Advertisement />);
      setAdName(<AdName />);
      setAdCheck(true);
    } else {
      setAdvertisement(null);
      setAdName(null);
      setAdCheck(false);
    }
  }

  const AdvertisingGuide = (event) => {
    // 광고문자 전송가이드

    event.preventDefault();
    popupAdvertisingText(true);
  };

  const BatchSendGuide = (event) => {
    event.preventDefault();
    popupBatchSend(true);
  };

  const LoadMsg = (event) => {
    // 문구 불러오기
    event.preventDefault();
    popupLoadMsg(true);
  };

  const handleRetainMsgSet = (
    hText,
    subject,
    mText,
    fText,
    rMsgType,
    rAdvertYn
  ) => {
    if (rAdvertYn === "Y") {
      if (!adCheck) {
        repAd.current.click();
      }
      setAdCheck(true);
      document.getElementById("advertising").checked = true;
      setContractAllSelected(true);

      setContentsHead(hText);
      setSubject(subject);
      setContents(mText);
      setContentsFooter(fText);

      handleContentsHeader(hText);
      handleContents(mText);
      handleContentsFooter(fText);
    } else {
      if (adCheck) {
        repAd.current.click();
      }
      setAdCheck(false);
      document.getElementById("advertising").checked = false;
      setContractAllSelected(false);

      setContentsHead("");
      setContentsFooter("");
      setSubject(subject);
      setContents(mText);

      handleContents(mText);
    }
    setRetainCheck(true);
    popupLoadMsg(false);
  };

  const saveMsg = (event) => {
    // 문구 저장하기
    event.preventDefault();

    let advertYn = "N";
    let footerText = "";
    if (adCheck) {
      advertYn = "Y";
      //080번호 체크
      footerText = document.getElementById("adNumber").value;
      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!footerText) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(footerText)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }

    if (!contents) {
      alert("저장할 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: contentsHead,
        mainText: contents,
        footerText: footerText,
        subject: subject,
        msgType: msgType,
        advertYn: advertYn,
      };

      if (uploadFile) {
        url = "/message/retainMsgSaveFile";
        const formData = new FormData();
        formData.append("file", uploadFile);
        formData.append("headerText", contentsHead);
        formData.append("mainText", contents);
        formData.append("footerText", footerText);
        formData.append("subject", subject);
        formData.append("msgType", msgType);
        formData.append("advertYn", advertYn);
        data = formData;
      }

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
    }
  };

  const showMergeSettings = () => {
    // 머지설정

    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };
  /*********************************** 메시지 내용 end ***********************************/
  /*********************************** 전송시간 ***********************************/
  const sendTime = [
    { id: "id3_1", value: "즉시", content: null },
    { id: "id3_2", value: "예약", content: <Reservation /> },
  ];
  /*********************************** 전송시간 end ***********************************/
  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 (선불only) ***********************************/
  const showChargeAccount = () => {
    if (charge) {
      setCharge(false);
    } else {
      setCharge(true);
    }
  };

  const closeInsufficientBalancePop = () => {
    popupRechargeInfo(false);
  };

  const handleRefresh = (e) => {
    setLoading(true);
    e.preventDefault();
    const userInfoUrl = "/user/userInfo";
    getFetch(userInfoUrl)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setDefaultUnit(jsonArr[0]);
          setBalance(jsonArr[0].balance);
          setRating(jsonArr[0].preRating);
          sessionStorage.setItem("pay", jsonArr[0].balance);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  /*********************************** 최하단 전송상태 요약창 - 충전버튼 클릭 end (선불only) ***********************************/
  /*********************************** 테스트전송 컨펌창 ***********************************/
  function getTestSendMsg(event) {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("T");
    if (balance < unitPrce * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendTestMsg(true);
    }
  }
  /*********************************** 테스트전송 컨펌창 end ***********************************/
  /*********************************** 전송하기 컨펌창 ***********************************/
  const SendMsgConfirm = (event) => {
    event.preventDefault();
    if(infoData.custType != "1" && infoData.custType != "2") {
      alert("승인된 계정만 발송할 수 있습니다.");
      return;
    }
    setSendType("R");
    if (balance < unitPrce * receiveCount * 1.1 && rating === "2") {
      popupRechargeInfo(true);
    } else {
      popupSendMsgConfirm(true);
    }
  };
  /*********************************** 전송하기 컨펌창 end ***********************************/

  const sendMsg = () => {
    sendMessage("N", "");
  };

  const sendMsgTest = (numPhone) => {
    if (!numPhone) {
      alert("메시지를 발송할 휴대폰 번호가 존재하지 않습니다.");
      return;
    }
    sendMessage("Y", numPhone);
  };

  const sendMessage = (testYn, numPhone) => {
    let phoneList = [];
    let fileList = fileRegIdList;
    let cntNum = 0;
    if (testYn === "Y") {
      phoneList.push(numPhone);
      cntNum = 1;
      fileList = [] ; // jy 2023.01.10

    } else {
       phoneList = contentPhone; 
       cntNum = receiveCount;     
    }

    if (cntNum === 0) {
      alert("메시지를 발송할 수신번호가 존재하지 않습니다.");
      return;
    }

    const callbackNum = document.getElementById("callbackList").value;

    if (callbackNum == 0) {
      alert("발신번호를 등록하세요.");
      return;
    }

    if (!callbackNum) {
      alert("발신번호를 선택해 주세요.");
      return;
    }

    let advertYn = "N";
    let adNumber = "";
    if (adCheck) {
      advertYn = "Y";
      //080번호 체크

      adNumber = document.getElementById("adNumber").value;
      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!adNumber) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(adNumber)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }
    const text = document.getElementById("msgContentsPreview").value;

    if (!text) {
      alert("발송할 메시지를 작성해 주세요.");
      return;
    }

    let mergeCheck = "N";
    let sendType = "1";
    let sendDayTime;
    let sendCnt = 1;

    if (testYn === "N") {
      sendCnt = receiveCount;

      let flag = text.includes("#{이름}");
      if (flag) mergeCheck = "Y";

      flag = text.includes("#{1}");
      if (flag) mergeCheck = "Y";

      flag = text.includes("#{2}");
      if (flag) mergeCheck = "Y";

      flag = text.includes("#{3}");
      if (flag) mergeCheck = "Y";

      flag = text.includes("#{4}");
      if (flag) mergeCheck = "Y";
    }

    if (mergeCheck === "Y") {
      if (phoneList.length > 0) {
        alert("머지 항목 존재시 개별 수신번호는 테스트 발송만 할 수 있습니다.");
        return;
      }
    }

    if (chekedId === "id3_2") {
      sendDayTime =
        document.getElementById("datetime-local").value.replace("T", " ") +
        ":00";
      if (document.getElementById("datetime-local").value === "") {
        alert("예약시간을 선택해 주세요.");
        return;
      }
      sendType = "2";
    }

    let url = "/message/messageReady";
    let data = {
      file: "",
      phoneList: phoneList,
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: subject,
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: msgType,
      advertYn: advertYn,
      totCnt: sendCnt,
    };
    setLoading(true);
    getPostFetch(url, data)
      .then((result) => {
        let msg = result.resultMsg;
        if (result.resultCode === "1") {
          if (window.confirm(msg)) {
            const jsonArr = JSON.parse(result.resultData);

            const sn = jsonArr.tmSn;
            const customRegIdList = jsonArr.regIdList;
            setLoading(true);
            fnlSendMsg(
              phoneList,
              fileList,
              callbackNum,
              text,
              sendType,
              subject,
              sendDayTime,
              mergeCheck,
              advertYn,
              sn,
              testYn,
              "Y",
              sendCnt,
              customRegIdList
            );
          }
        } else if (result.resultCode === "0") {
          if (window.confirm(msg)) {
            const jsonArr = JSON.parse(result.resultData);

            const sn = jsonArr.tmSn;
            const customRegIdList = jsonArr.regIdList;
            setLoading(true);
            fnlSendMsg(
              phoneList,
              fileList,
              callbackNum,
              text,
              sendType,
              subject,
              sendDayTime,
              mergeCheck,
              advertYn,
              sn,
              testYn,
              "N",
              sendCnt,
              customRegIdList
            );
          }
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fnlSendMsg = (
    phoneList,
    fileList,
    callbackNum,
    text,
    sendType,
    subject,
    sendDayTime,
    mergeCheck,
    advertYn,
    sn,
    testYn,
    duplicateYn,
    sendCnt,
    customRegIdList
  ) => {

    let url = "/message/messageSendSms";
    let data = {
      file: "",
      phoneList: phoneList,   
      regIdList: fileList,
      callback: callbackNum,
      dstaddr: "",
      text: text,
      sendType: sendType,
      subject: msgType == "SMS" ? ""  : subject,
      requestDt: sendDayTime,
      mergeYn: mergeCheck,
      msgType: msgType,
      advertYn: advertYn,
      totCnt: sendCnt,
      tmSn: sn,
      duplicateYn: duplicateYn,
      customRegIdList: customRegIdList, 
    };
    if (uploadFile) {
      url = "/message/messageSendMms";
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("phoneList", phoneList);
      formData.append("regIdList", fileList);
      formData.append("callback", callbackNum);
      formData.append("dstaddr", "");
      formData.append("text", text);
      formData.append("sendType", sendType);
      formData.append("subject", subject);
      formData.append("requestDt", sendDayTime);
      formData.append("mergeYn", mergeCheck);
      formData.append("msgType", msgType);
      formData.append("tmSn", sn);
      formData.append("advertYn", advertYn);
      formData.append("totCnt", receiveCount);
      formData.append("duplicateYn", duplicateYn);
      formData.append("customRegIdList", customRegIdList); 

      data = formData;
    }

    setLoading(true);
    getPostFetch(url, data)
      .then((result) => {
        alert(result.resultMsg, result);
        setLoading(false);
        if (result.resultCode === "0") {
          //navigate("/sendMessage/message");
          // 초기화
          if (testYn === "Y") {
            popupSendTestMsg(false);
          } else {
            popupSendMsgConfirm(false);
            handelDefaultSet();
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [testCardPay, setTestCardPay] = useState(false);
  const [dataCheck, setDataCheck] = useState(null);

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <>
      <div>
        {
          loading == true && (
            <Loader type="spin" color="black" message="요청을 처리중입니다" />          
          )
        }
        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="bluebox mt20 mb30 clearfix">
              <div className="text-left size14 mb10">현재 발송 가능한 건수</div>
              <div className="text-right">
                SMS {smsUnit}건 / LMS {lmsUnit}건 / MMS {mmsUnit}건
              </div>
            </div>
            <p className="line"></p>
          </div>
        </div>

        {/*  수신번호 */}
        <ReceiveList
          phone={phone}
          setPhone={setPhone}
          phoneCount={phoneCount}
          setPhoneCount={setPhoneCount}
          contentPhone={contentPhone}
          setContentPhone={setContentPhone}
          receiveCount={receiveCount}
          setReceiveCount={setReceiveCount}
          getCountTotal={getCountTotal}
          setFileRegIdList={setFileRegIdList}
          viewTableRows={viewTableRows}
          setviewTableRows={setviewTableRows}
          fileRegIdList={fileRegIdList}
          setLoading={setLoading}
          listUploadType={listUploadType}
          setListUploadType={setListUploadType}
        />

        <div className="write-left-inner" id="sendNumber">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">발신번호</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select h40-select gray-select buttonWith">
                <select
                  id="callbackList"
                  name="phone"
                  data-fakeform-index="0"
                  //lassName={receiveCount >= 10000 ? "" : "wid100"}
                  className={"wid100"}
                >
                  {callbackList.length == 0 && <option value={"0"} selected hidden>발신번호를 등록하세요.</option>}
                  {callbackList.map((row) => (
                    <option key={row.callback} value={row.callback}>
                      {row.callback}
                      {row.cbType === "1" ? "(기본발신)" : null}
                    </option>
                  ))}
                </select>
                {/* {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                  <Button
                    buttonName="button-bgc-dark"
                    buttonValue="발신번호 인증"
                    onChangePage={handleCertification}
                  />
                ) : null} */}
              </div>
            </div>
          </div>
          
          <p className="line"></p>
        </div>
        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">이미지첨부</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {uploadImage.map((value) => (
                    <>
                      <input
                        type="radio"
                        name="uploadImage"
                        key={value.id}
                        id={value.id}
                        checked={chekedImgDefault === value.id}
                        onClick={function (e) {
                          setActiveUploadImage(value.content);
                          setChekedImgDefault(value.id);
                          setSubject("");
                          if (value.id === "uploadImage_1") {
                            setUploadFile(null);
                            document.getElementById("imagePreviewSrc").src = "";
                            document.getElementById("imgFileName").value = "";
                            handleContents(contents);
                            props.setIsPreviewImg(false);
                          } else if (value.id === "uploadImage_2") {
                            props.setIsPreviewImg(true);
                          }
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/*activeUploadImage*/}
          {chekedImgDefault === "uploadImage_2" ? (
            <ImageUpload
              openImagePop={openImagePop}
              closeImagePop={closeImagePop}
            />
          ) : null}
          <p className="line"></p>
        </div>
        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">메시지 내용</span>
            </div>

            <div className="tit-right">
              <fieldset className="gs-custom-checkbox">
                <span className="checkbox-item">
                  <input
                    type="checkbox"
                    id="advertising"
                    name=""
                    onClick={getAdvertisement}
                  />
                  <label
                    htmlFor="advertising"
                    ref={repAd}
                    className={contractAllSelected ? "checked" : null}
                  >
                    광고성 정보가 포함되어 있습니다
                  </label>
                </span>
              </fieldset>
              <Button
                buttonName="button-bgc-dark"
                buttonValue="광고문자 전송가이드(필독)"
                onChangePage={AdvertisingGuide}
              />
            </div>
          </div>
          {msgType !== "SMS" && (
            <input
              type="text"
              name="subject"
              title="제목"
              placeholder="제목 (최대 120byte)"
              onChange={(e) => handleSubject(e)}
              value={subject}
              className="wid100 mt10"
            />
          )}
          <div id="msgArea" className="write-input-con change-effect-bg">
            {/* 광고성 정보 체크 시에만 노출 */}
            {/*adName*/}
            {adCheck ? (
              <AdName
                handleContentsHeader={handleContentsHeader}
                contentsHead={contentsHead}
              />
            ) : null}
            {/* 광고성 정보 체크 시에만 노출 */}
            <div className="write-input-down-con">
              <textarea
                name=""
                id="msgContents"
                className="gs-custom-textarea change-effect"
                placeholder="내용 입력
                                - 90 byte 초과 시 LMS 자동전환
                                - 파일첨부 시 MMS 자동전환"
                maxLength="2000"
                onChange={(e) => {
                  handleContents(e.target.value);
                }}
                value={contents}
              ></textarea>
              <div className="write-input-down-info">
                <span className="msgType">{msgType}</span>
                <span className="byte" id="msgByte">
                  {msgByte} / <em className="red">{limitByte} Byte</em>
                </span>
              </div>
            </div>
            {/* 광고성 정보 체크 시에만 노출 */}
            {/*advertisement*/}
            {adCheck ? (
              <Advertisement
                handleContentsFooter={handleContentsFooter}
                contentsFooter={contentsFooter}
              />
            ) : null}
          </div>
          <div className="write-number-down-btn mt20">
            <ul className="button-col3">
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 불러오기"
                  onChangePage={LoadMsg}
                />
              </li>
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 저장하기"
                  onChangePage={saveMsg}
                />
              </li>
              {
                listUploadType != 'direct' && 
                <li>
                  <Button
                    buttonName={
                      mergeVariableState
                        ? "button-bgc-blue"
                        : "button-border-gray"
                    }
                    buttonValue="머지설정"
                    onChangePage={() => showMergeSettings()}
                  />
                  {mergeVariableState ? (
                    <MergeSettings handleContentsName={handleContentsName} />
                  ) : null}
                </li>
              }
              
            </ul>
          </div>
          <p className="line"></p>
        </div>

        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">전송시간</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-select buttonWith">
                <div className="Radiobox">
                  {sendTime.map((value) => (
                    <>
                      <input
                        type="radio"
                        name="RCSCardType"
                        key={value.id}
                        id={value.id}
                        checked={chekedId === value.id}
                        onClick={function (e) {
                          setActiveContent(value.content);
                          setChekedId(value.id);
                        }}
                      />
                      <label htmlFor={value.id}>{value.value}</label>
                    </>
                  ))}
                </div>
                {activeContent}
              </div>
            </div>
          </div>
          <p className="line"></p>
        </div>
        <div className="write-total-con clearfix" id="">
          <div className="write-total-left-con">
            <div className="write-total-top">
              <div className="inner clearfix">
                <div className="left">전송 금액</div>
                <div className="right">
                  <p className="price sendPrice">
                    {setComma(unitPrce * receiveCount * 1.1)} 원
                  </p>
                  <span className="txt">(부가세 포함)</span>
                </div>
              </div>
            </div>
            <div className="write-total-bottom-wrap">
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      메시지 타입
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">{msgType}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">
                      건당 요금
                    </fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{unitPrce}</span>원
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="write-total-bottom">
                <div className="clearfix">
                  <div className="left">
                    <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                  </div>
                  <div className="right">
                    <div className="gs-custom-input-btn-group">
                      <div className="input-con">
                        <span className="mr10">{receiveCount}</span>건
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 선불계정일 때만 표시 */}

              {rating === "2" ? (
                <div className="write-total-bottom">
                  <div className="clearfix">
                    <div className="left">
                      <fieldset className="gs-custom-checkbox">
                        내 충전금
                      </fieldset>
                    </div>
                    <div className="right">
                      <div className="gs-custom-input">
                        <div className="input-con">
                          <a href="/" onClick={handleRefresh}>
                            <FontAwesomeIcon
                              icon={faRedoAlt}
                              className="gray999 mr10 cursor size8"
                            />
                          </a>
                          <span className="mr10">{setComma(balance)}</span>원
                          {
                            payInfo == 2 ? <Button
                                              buttonName="cm-highlight-btn ml10"
                                              buttonValue="카드결제"
                                              onChangePage={handleTestCardPay}
                                            />
                                          : <Button
                                          buttonName="cm-highlight-btn ml10"
                                          buttonValue="충전"
                                          onChangePage={() => showChargeAccount()}
                                        />
                          }
                          <div id="payRender" name="payRender"></div>
                        </div>
                        {charge && (
                          <div className="input-con">
                            <MyAccountInfo setBalance={setBalance} infoData={infoData}/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* 선불계정일 때만 표시 */}
            </div>
          </div>
        </div>
        
        <div className="write-left-inner">
          <div className="write-tit-con clearfix">
            <div className="button-wrap rfloat">
              <div style={{float: "left", marginLeft:"0px"}}>
                {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                  <p className="red" style={{marginTop: "7px", fontWeight: "bold"}}>
                    ※ 1만건 이상 발송은 관리자 승인이 필요합니다.
                  </p>
                ) : null}
              </div>
              <div style={{float: "right"}}>
                {receiveCount >= 10000 ? ( //수신번호 1만건 이상인지 확인
                  <Button
                    buttonName="button-bgc-dark"
                    buttonValue="발송 승인 안내"
                    onChangePage={BatchSendGuide} 
                />
                ) : null}
                <Button
                  buttonName="button-border-blue"
                  buttonValue="테스트전송"
                  onChangePage={getTestSendMsg}
                />
                <Button
                  buttonName="button-bgc-blue"
                  buttonValue="전송하기"
                  onChangePage={SendMsgConfirm}
                />
              </div>
            </div>
          </div>
        </div>
        <p className="clear"></p>
      </div>
      {advertisingText && (
        <PopUpLayout
          onClose={popupAdvertisingText}
          poptitle="광고문자 전송 가이드"
        >
          <LayerPopAdvertisingGuide />
        </PopUpLayout>
      )}
      {batchSend && (
        <PopUpLayout
          onClose={popupBatchSend}
          poptitle="대량발송 승인 안내"
        >
          <LayerBatchSend />
        </PopUpLayout>
      )}

      {loadMsg && (
        <PopUpLayout
          onClose={popupLoadMsg}
          poptitle="문구 불러오기"
          size="medium"
        >
          <LayerPopLoadMsg
            selectType={selectType}
            handleRetainMsgSet={handleRetainMsgSet}
            setLoading = {setLoading}
          />
        </PopUpLayout>
      )}
      {sendTestMsg && (
        <PopUpLayout onClose={popupSendTestMsg} poptitle="테스트전송">
          <LayerPopSendTestMsg
            sendMsgTest={sendMsgTest}
            closeSendMsgTestPop={closeSendMsgTestPop}
            unitPrce={unitPrce}
            msgType={msgType}
          />
        </PopUpLayout>
      )}
      {sendMsgConfirm && (
        <PopUpLayout onClose={popupSendMsgConfirm} poptitle="메시지 발송">
          <LayerPopSendMsg
            sendMsg={sendMsg}
            closeSendMsgPop={closeSendMsgPop}
            receiveCount={receiveCount}
            unitPrce={unitPrce}
            msgType={msgType}
            chekedId={chekedId}
          />
        </PopUpLayout>
      )}
      {popupImgUpload && (
        <PopUpLayout onClose={setPopupImgUpload} poptitle="이미지 업로드">
          <LayerPopMessageImgUpload
            setImgFile={setImgFile}
            setImageUrl={setImageUrl}
            setImageFileName={setImageFileName}
            closeImagePop={closeImagePop}
          />
        </PopUpLayout>
      )}
      {rechargeInfo && (
        <PopUpLayout
          onClose={popupRechargeInfo}
          poptitle="잔액 부족 안내"
          size="small"
        >
          <LayerPopRechargeInfo
            payInfo={payInfo}
            accountNo={accountNo}
            sendType={sendType}
            popupSendTestMsg={popupSendTestMsg}
            popupSendMsgConfirm={popupSendMsgConfirm}
            closeInsufficientBalancePop={closeInsufficientBalancePop}
            balance={balance}
            setBalance={setBalance}
            nowBalance={unitPrce}
            receiveCount={receiveCount}
          />
        </PopUpLayout>
      )}
      {testCardPay && (
        <PopUpLayout 
          onClose={setTestCardPay}
          poptitle="카드결제"
          size="small"
        >
          <LayerPopTestCardPay
             onClose={setTestCardPay}
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
    </>
  );
};

export const ErrorCheckResults = () => {
  // 수신번호 - 엑셀불러오기버튼 클릭 시 팝업

  const [errorList, popupErrorList] = useState(false);
  const OpenErrorList = (event) => {
    event.preventDefault();
    popupErrorList(true);
  };
  return (
    <>
      <div className="write-total-con mt10 clearfix" id="">
        <div className="write-total-left-con">
          <div className="write-total-bottom-wrap">
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">총 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">295</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">정상 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <span className="mr10">293</span>건
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="write-total-bottom">
              <div className="clearfix">
                <div className="left">
                  <fieldset className="gs-custom-checkbox">오류 건수</fieldset>
                </div>
                <div className="right">
                  <div className="gs-custom-input-btn-group">
                    <div className="input-con">
                      <a href="/" className="red" onClick={OpenErrorList}>
                        {" "}
                        <span className="mr10 redline">2</span>건
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorList && (
        <PopUpLayout onClose={popupErrorList} poptitle="오류상세" size="medium">
          <LayerPopReceiveNumberError />
        </PopUpLayout>
      )}
    </>
  );
};

export function ImageUpload(props) {
  // 이미지첨부
  const closeImagePop = props.closeImagePop;
  const openImagePop = props.openImagePop;

  const LoadingImg = (event) => {
    event.preventDefault();
    openImagePop();
  };
  return (
    <>
      <div className="mt20 mb20">
        가로기준 최대 640px입니다.
        <br />
        640보다 큰 이미지는 640으로 자동 조정되어 발송됩니다.
        <br />
        <br />
        * 크기조정시 가로X세로 비율이 약 1:2 이상인 경우 용량초과로
        <br />
        첨부되지 않을 수 있으니 확인 후 등록 바랍니다.
      </div>
      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id="imgFileName"
            name="imgFileName"
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
          <Button
            buttonName="button-bgc-gray"
            buttonValue="파일등록"
            onChangePage={LoadingImg}
          />
        </div>
      </div>
    </>
  );
}

export function AdName(props) {
  const handleContentsHeader = props.handleContentsHeader;
  const contentsHead = props.contentsHead;

  // 메시지 내용 - 광고성 정보 체크 시 - 업체명 또는 서비스명 입력란
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02">
        <input
          type="text"
          id="adTitle"
          name="adTitle"
          title="제목"
          className="gs-custom-input"
          placeholder="업체명 또는 서비스명 입력"
          onChange={(e) => {
            handleContentsHeader(e.target.value);
          }}
          defaultValue={contentsHead}
        />
      </div>
    </div>
  );
}

export function Advertisement(props) {
  const handleContentsFooter = props.handleContentsFooter;
  const contentsFooter = props.contentsFooter;
  // 메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id="adNumber"
        name="adNumber"
        maxLength="11"
        title="무료거부번호"
        placeholder="080 무료거부번호 입력"
        onChange={(e) => {
          handleContentsFooter(e.target.value);
        }}
        defaultValue={contentsFooter}
      />
    </div>
  );
}

export function MergeSettings(props) {
  const handleContentsName = props.handleContentsName;
  // 메시지 내용 - 머지설정
  const SetVariableName = (event) => {
    event.preventDefault();
    handleContentsName("#{이름}");
  };
  const SetVariable1 = (event) => {
    event.preventDefault();
    handleContentsName("#{1}");
  };
  const SetVariable2 = (event) => {
    event.preventDefault();
    handleContentsName("#{2}");
  };
  const SetVariable3 = (event) => {
    event.preventDefault();
    handleContentsName("#{3}");
  };
  const SetVariable4 = (event) => {
    event.preventDefault();
    handleContentsName("#{4}");
  };
  return (
    <div className="variable-info-con" id="">
      <div>
        <table className="variable-info-tb" id="">
          <tbody>
            <tr>
              <td byte="10">
                <Button
                  buttonName=""
                  buttonValue="#{이름}"
                  onChangePage={SetVariableName}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{1}"
                  onChangePage={SetVariable1}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{2}"
                  onChangePage={SetVariable2}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{3}"
                  onChangePage={SetVariable3}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{4}"
                  onChangePage={SetVariable4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function Reservation() {
  // 전송시간 - 예약

  const classes = useStyles();
  return (
    <span>
      <TextField
        id="datetime-local"
        type="datetime-local"
        defaultValue={new Date()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </span>
  );
}

// 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)
export function MyAccountInfo(props) {
  // 최하단 결과창 - 충전버튼 클릭 시 (선불계정 only)

  const [accountCreation, popupAccountCreation] = useState(false);
  const [testCardPay, setTestCardPay] = useState(false);

  const setBalance = props.setBalance;
  const [dataCheck, setDataCheck] = useState(null);
  const [payInfo, setPayInfo] = useState(null);
  const [accountNo, setAccountNo] = useState(null);

  const infoData = props.infoData;
  const handleAccountNumber = (event) => {
    event.preventDefault();
    popupAccountCreation(true);
  };

  const handleTestCardPay = (event) => {
    event.preventDefault();
    setTestCardPay(true);
  };

  const handleTestVbankPay = (event) => {
    event.preventDefault();
    alert("준비중 입니다.");
  };

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  useEffect(() => {
    const url = "/user/payInfo";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setPayInfo(jsonArr.payType);
          setAccountNo(jsonArr.accountNo);
        }
      })
      .catch((error) => alert("시스템 오류가 발생하였습니다.\n관리자에게 문의바랍니다."));
  }, [accountNo]);

  useEffect(() => {
    if (document.getElementById("payForm")) {
      window.INIStdPay.pay("send_pay_form");
    }
  }, [dataCheck]);

  return (
    <div className="my-account-info">
      {/* 전용 계좌 생성 전 */}
      {payInfo === "1" && accountNo === undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <fieldset className="gs-custom-checkbox">
              생성한 전용계좌가 없습니다.
            </fieldset>
          </div>
          <div className="right">
            <a href="/" onClick={handleAccountNumber} className="underline">
              내 전용계좌 생성하기
            </a>
          </div>
        </div>
      ) : null}
      {payInfo === "1" && accountNo != undefined && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">
            <div className="gs-custom-checkbox">하나은행</div>
          </div>
          <div className="right">{accountNo}</div>
          <div className="left">ㅤ</div>
          <div className="red">충전까지 최대 1분 소요됩니다.</div>
        </div>
      ) : null}
      {payInfo === "2" && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right">
            <Button
              buttonName="cm-highlight-btn ml10"
              buttonValue="카드결제"
              onChangePage={handleTestCardPay}
            ></Button>
          </div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {payInfo === undefined || payInfo === null && (infoData.custType == 1 || infoData.custType == 2) ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right red">결제 타입 미지정</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}
      {infoData.custType != 1 && infoData.custType != 2 ? (
        <div className="clearfix">
          <div className="left">ㅤ</div>
          <div className="right red">계정 승인 후 충전 가능합니다.</div>
          <div id="payRender" name="payRender"></div>
        </div>
      ) : null}

      {accountCreation && (
        <PopUpLayout onClose={popupAccountCreation} poptitle="전용계좌 생성">
          <LayerPopAccountCreation
            popupAccountCreation={popupAccountCreation}
            setAccountNo={setAccountNo}
          />
        </PopUpLayout>
      )}

      
      {testCardPay && (
        <PopUpLayout onClose={setTestCardPay}>
          <LayerPopTestCardPay
            testCardPay={setTestCardPay}
            setBalance={setBalance}
            setDataCheck={setDataCheck}
          />
        </PopUpLayout>
      )}
      {/* 전용 계좌 생성 후 */}
      {/* <div className="clearfix center">
                국민은행 1301-1111-1234
            </div> */}
    </div>
  );
}

// 바이트 구하기
function getByte(str) {
  return str
    .split("")
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
}

export function PreviewMessage({ isPreviewImg }) {
  // 미리보기 이미지

  const style = {
    wrap: {
      position: "absolute",
      left: "7%",
      top: "80px",
      width: "86%",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      minHeight: "200px",
      maxHeight: "360px",
      height: "auto",
      padding: "15px",
    },
    areaImg: {
      borderRadius: "10px",
      overflow: "hidden",
      marginBottom: "10px",
    },
    textArea: {
      backgroundColor: "#ffffff",
      color: "#4a4a4a",
      lineHeight: "22px",
      fontSize: "15px",
      height: "auto",
      padding: 0,
      overflow: "hidden",
    },
    freeRefusal: {
      textDecoration: "underline",
      color: "#625af6",
      marginLeft: "5px",
    },
  };
  return (
    <div style={style.wrap}>
      {isPreviewImg && (
        <div id="imagePreview" style={style.areaImg}>
          <img src={defaultImg} id="imagePreviewSrc" />
        </div>
      )}
      <textarea
        style={style.textArea}
        name="msgContentsPreview"
        id="msgContentsPreview"
        className="gs-custom-textarea change-effect"
        readOnly
      ></textarea>
    </div>
  );
}
