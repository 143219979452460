import React, { useState } from "react";

import sec1_img1 from "assets/images/sec1_img.png";

import sec2_icon1 from "assets/images/sec2_icon1.png";
import sec2_icon2 from "assets/images/sec2_icon2.png";
import sec2_icon3 from "assets/images/sec2_icon3.png";
import sec2_icon4 from "assets/images/sec2_icon4.png";
import sec2_img from "assets/images/sec2_img.png";

import sec3_img1 from "assets/images/sec3_img1.png";
import sec3_img2 from "assets/images/sec3_img2.png";
import sec3_img3 from "assets/images/sec3_img3.png";

import sec3_icon1 from "assets/images/sec3_icon1.png";
import sec3_icon2 from "assets/images/sec3_icon2.png";
import sec3_icon3 from "assets/images/sec3_icon3.png";
import sec3_icon1_on from "assets/images/sec3_icon1_on.png";
import sec3_icon2_on from "assets/images/sec3_icon2_on.png";
import sec3_icon3_on from "assets/images/sec3_icon3_on.png";

import sec4_icon1 from "assets/images/sec4_icon1.png";
import sec4_icon2 from "assets/images/sec4_icon2.png";
import sec4_icon3 from "assets/images/sec4_icon3.png";
import sec4_icon4 from "assets/images/sec4_icon4.png";
import sec4_icon5 from "assets/images/sec4_icon5.png";

import sec5_img1 from "assets/images/sec5_img1.png";
import sec5_img2 from "assets/images/sec5_img2.png";
import sec5_img3 from "assets/images/sec5_img3.png";
import sec5_img4 from "assets/images/sec5_img4.png";
import { Button } from "component/Common.js";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function Main() {
  const [activeContentId, setActiveContentId] = useState(1);
  const [contents, setContents] = useState(Content1);
  const navigate = useNavigate();
  const setActiveContent = (contentId) => {
    setActiveContentId(contentId);
    if (contentId === 1) {
      setContents(Content1);
    } else if (contentId === 2) {
      setContents(Content2);
    } else if (contentId === 3) {
      setContents(Content3);
    }
  };
  const contentList = [
    {
      id: 1,
      title: "채널의 다양화",
      icon: sec3_icon1,
      activeIcon: sec3_icon1_on,
    },
    {
      id: 2,
      title: "제약없는 대고객 서비스",
      icon: sec3_icon2,
      activeIcon: sec3_icon2_on,
    },
    {
      id: 3,
      title: "컨텐츠 다변화",
      icon: sec3_icon3,
      activeIcon: sec3_icon3_on,
    },
  ];
  return (
    <div className="main">
      <section className="section_1">
        {/* <div className="inner">
                    <ul>
                        <li><span className="small">한진정보통신이 제공하는</span><br/>메시징 통합 서비스 플랫폼</li>
                        <li><img src={sec1_img1} alt=""/></li>
                    </ul>
                    <p className="clear"/>
                </div> */}
      </section>
      <section className="section_2">
        <div className="inner">
          <h4></h4><br></br>
          <h5>메시징&마케팅 솔루션 통합 서비스</h5>
          <p>
          SMS, LMS, 알림톡, 친구톡, RCS 및 타겟마케팅 등 <br></br>
          기업활동에 필요한 메시징&마케팅서비스를 통합 제공합니다.
          </p>
          <ul>
            <li>
              <div>
                <img src={sec2_icon1} alt="" />
              </div>
            </li>
            <li>
              <div>
                <img src={sec2_icon2} alt="" />
              </div>
            </li>
            <li>
              <div>
                <img src={sec2_icon3} alt="" />
              </div>
            </li>
            <li>
              <div>
                <img src={sec2_icon4} alt="" />
              </div>
            </li>
          </ul>
          <p className="clear"></p>
          <div>
            <img src={sec2_img} alt="" className="section2Image" />
          </div>
        </div>
      </section>

      <section className="section_3">
        <div className="inner">
          <h4>소개</h4>
          <ul>
            {contentList.map((val) => (
              <li
                className={val.id === activeContentId ? "active" : null}
                key={val.id}
                onClick={function (e) {
                  setActiveContent(val.id);
                }}
              >
                <span>
                  {" "}
                  <img
                    src={val.id === activeContentId ? val.activeIcon : val.icon}
                    alt=""
                  ></img>
                  {val.title}
                </span>
              </li>
            ))}
          </ul>
          <p className="clear"></p>

          {contents}
        </div>
      </section>

      <section className="section_4">
        <div className="inner">
          <h4>알아보기</h4>
          <p>다년간의 사업 노하우를 통한 안정적인 서비스 제공</p>
          <ul>
            <li>
              <img src={sec4_icon1} alt=""></img>
              <p>
                고객에게 맞는
                <br /> 메시지 형태 제공
              </p>
            </li>
            <li>
              <img src={sec4_icon2} alt=""></img>
              <p>편리한 UI구성</p>
            </li>
            <li>
              <img src={sec4_icon3} alt=""></img>
              <p>저렴한 비용</p>
            </li>
            <li>
              <img src={sec4_icon4} alt=""></img>
              <p>24/365 안정적인 서비스</p>
            </li>
            <li>
              <img src={sec4_icon5} alt=""></img>
              <p>
                다양한 확장성
                <br />
                (Web/Agent/API)
              </p>
            </li>
          </ul>
          <p className="clear" />
        </div>
      </section>
      <section className="section_5">
        <div className="inner">
          <h4>활용하기</h4>
          <p>기업메시지의 모든 것, Hi SMS에서 경험해 보세요</p>
          <ul>
            <li>
              <div>
                <img src={sec5_img1} alt="" />
              </div>
              <p>LMS</p>
            </li>
            <li>
              <div>
                <img src={sec5_img2} alt="" />
              </div>
              <p>알림톡</p>
            </li>
            <li>
              <div>
                <img src={sec5_img3} alt="" />
              </div>
              <p>친구톡</p>
            </li>
            <li>
              <div>
                <img src={sec5_img4} alt="" />
              </div>
              <p>RCS-Carousel</p>
            </li>
          </ul>
          <p className="clear" />
        </div>
      </section>
      {/* <section className="section_6">
                <h5>지금 Hi SMS를 시작해보세요!</h5>
                    
                <Button buttonName="button-bgc-violet" buttonValue="회원가입하기" onChangePage={function () {
                        navigate("/account/joinStep1");
                }}/>
            </section> */}
      <p className="clear"></p>
    </div>
  );
}

export function Content1() {
  return (
    <div className="content">
      <p>"메시징서비스와 마케팅서비스의 조화"</p>
      <p>
        고객사의 재구매 창출 및 고객 알림을 위한 메시징서비스 부터 <br/>
        신규 고객 창출을 위한 타겟 마케팅 서비스까지, HiSMS는 진화된 메시징&마케팅 서비스로 앞서 나갑니다.
      </p>
      <div>
        <img src={sec3_img1} alt="" />
      </div>
    </div>
  );
}
export function Content2() {
  return (
    <div className="content">
      <p>“국내 최저가의 강력한 메시징&마케팅 서비스＂</p>
      <p>한진정보통신이 직접 개발/서비스하는 HiSMS는 고객사를 위해, 가장 효율적이고 경제적인 솔루션을 제시합니다.</p>
      <div>
        <img src={sec3_img2} alt="" />
      </div>
    </div>
  );
}

export function Content3() {
  return (
    <div className="content">
      <p>“다양한 컨텐츠 공급을 통한 고객 만족 실현”</p>
      <p>
      현존하는 모든 타입의 메시징서비스 부터, 타겟마케팅 서비스, 지속적인 제공컨텐츠 확대를 통해 고객 만족을 추구합니다.
      </p>
      <div>
        <img src={sec3_img3} alt="" />
      </div>
    </div>
  );
}

export default Main;
