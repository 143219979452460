import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { postRequest, getRequest } from "component/Axios";
export function KakaoTemplateModify(props) {
  const [channelList, setChannelList] = useState([]);
  const [template, setTemplate] = useState([]);
  const [category, setCategory] = useState([]);
  const [textLen, setTextLen] = useState(0);
  const [btnNum, setBtnNum] = useState(1);
  const [buttonTypeList, setButtonTypeList] = useState([]);
  const [codeRows, setCodeRows] = useState([]);
  const [cate1, setCate1] = useState("");
  const [cate2, setCate2] = useState("");
  const [securityCheck, setSecurityCheck] = useState("");
  const setStatus = props.setStatus;
  const setInspStatus = props.setInspStatus;
  const setReqTpCode = props.setReqTpCode;
  const setReqPlusId = props.setReqPlusId;
  useEffect(() => {
    handleCommonCode();
  }, []);
  useEffect(() => {
    handleChannel();
    handleCategory();
    handleTemplate();
  }, [codeRows]);
  useEffect(() => {
    setBtnCount();
  }, [btnNum]);
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const handleCommonCode = () => {
    const url = "/getCommonCode";
    const snList = [1024, 1025];
    const data = { snList: snList };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCodeRows(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handleChannel = () => {
    const url = "/kko/getChannel";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setChannelList(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handleCategory = () => {
    const url = "/kko/getCategory/2";
    getFetch(url)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setCategory(jsonArr);
        }
      })
      .catch((error) => alert(error));
  };
  const handleSecurityCheck = (e) => {
    if (e.target.checked) {
      setSecurityCheck(true);
    } else {
      setSecurityCheck(false);
    }
  };
  function handleTemplate() {
    const url = "/kko/templateDetailView";
    const data = { search1: sessionStorage.getItem("find_temp"), search2: "2" };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          setTemplate(jsonArr[0]);
          setCate1(jsonArr[0].tpCtCode.substring(0, 3));
          setCate2(jsonArr[0].tpCtCode.substring(3, 6));
          setTextLen(jsonArr[0].tpText.length);
          setStatus(jsonArr[0].tpStatus);
          setInspStatus(jsonArr[0].tpInspStatus);
          const count = jsonArr[0].countBtn;
          if (count > 0) handleTemplateBtn();
          if (jsonArr[0].tpSecurityFlag === "1") {
            setSecurityCheck(true);
          } else {
            setSecurityCheck(false);
          }
          setReqTpCode(jsonArr[0].tpCode);
          setReqPlusId(jsonArr[0].plusId);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  }
  function handleTemplateBtn() {
    const url = "/kko/templateBtn";
    const data = { search1: sessionStorage.getItem("find_temp") };
    getPostFetch(url, data)
      .then((result) => {
        if (result.resultCode === "0") {
          const jsonArr = JSON.parse(result.resultData);
          AddBtnMulit(jsonArr);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch((error) => alert(error));
  }
  const handleCateChange = (code) => {
    setCate1(code);
  };
  const handleChangeText = (text) => {
    const len = text.length;
    setTextLen(len);
    if (len > 1000) alert("템플릿 내용 1000자를 초과하지 못합니다.");
  };
  function AddBtnMulit(jsonArr) {
    const row = [];
    const num = jsonArr.length;
    for (let i = 1; i <= num; i++) {
      const btnRow = "row_" + i;
      const btnTypeNm = "btnType_" + i;
      const textNm = "btnText_" + i;
      const linkText = "linkText_" + i;
      const linkText2 = "linkText2_" + i;
      const btnCnt = "btnCnt_" + i;
      const btnSn = "btnSn_" + i;
      row.push(
        <tr name="btnTypeGrp" id={btnRow}>
          <input type="hidden" name="hiddenVal" value={i} />
          <input type="hidden" name={btnSn} id={btnSn} />
          <td name={btnCnt} id={btnCnt}></td>
          <td>
            <select
              name={btnTypeNm}
              id={btnTypeNm}
              onChange={(e) => handelChangeBtnType1(i, e.target.value)}
            >
              <option value="">선택</option>
              {codeRows.map((row) =>
                row.cgSn === 1024 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
          </td>
          <td>
            <input type="text" name={textNm} id={textNm} />
          </td>
          <td name={linkText} id={linkText}>
            <div name={linkText2} id={linkText2}></div>
          </td>
          <td>
            <button
              className="delete"
              onClick={(e) => {
                handleDelete(i);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="dark" />
            </button>
          </td>
        </tr>
      );
    }
    setBtnNum(btnNum + num);
    setButtonTypeList(buttonTypeList.concat(row));
    // setTimeout(() => setBtnRowData(jsonArr), 1000);
    setBtnRowData(jsonArr);
  }
  function handelChangeBtnType1(num, val) {
    const linkNm1 = "btnLink1_" + num;
    const linkNm2 = "btnLink2_" + num;
    if (val === "WL") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "Mobile", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement(
          "input",
          { type: "text", placeholder: "PC", id: linkNm2 },
          null
        );
    } else if (val === "AL") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "Android", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement(
          "input",
          { type: "text", placeholder: "IOS", id: linkNm2 },
          null
        );
    } else if (val === "P1" || val === "P2" || val === "P3") {
      var rElmLi1 = React.createElement(
          "input",
          { type: "text", placeholder: "플러그인ID", id: linkNm1 },
          null
        ),
        rElmLi2 = React.createElement("li", { id: "li3" }, null);
    } else {
      var rElmLi1 = React.createElement("li", { id: "li3" }, null),
        rElmLi2 = React.createElement("li", { id: "li3" }, null);
    }
    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;
    var reactElementUl = React.createElement(
      "div",
      { id: linkText2, name: linkText2 },
      rElmLi1,
      rElmLi2
    );
    ReactDOM.render(reactElementUl, document.getElementsByName(linkText)[0]);
  }
  function setBtnRowData(jsonArr) {
    let num = 1;
    for (const row of jsonArr) {
      const btnTypeNm = "btnType_" + num;
      const textNm = "btnText_" + num;
      const linkNm1 = "btnLink1_" + num;
      const linkNm2 = "btnLink2_" + num;
      const btnSn = "btnSn_" + num;
      document.getElementById(btnTypeNm).value = row.btnType;
      handelChangeBtnType1(num, row.btnType);
      document.getElementById(textNm).value = row.btnNm;
      if (row.btnLink1) document.getElementById(linkNm1).value = row.btnLink1;
      if (row.btnLink2) document.getElementById(linkNm2).value = row.btnLink2;
      document.getElementById(btnSn).value = row.btnSn;
      num = num + 1;
    }
    setBtnCount();
  }
  function setBtnCount() {
    let count = 1;
    for (let i = 1; i <= btnNum + 10; i++) {
      const btnRow = "row_" + i;
      const btnCnt = "btnCnt_" + i;
      if (document.getElementById(btnRow)) {
        document.getElementsByName(btnCnt)[0].innerText = count;
        count = count + 1;
      }
    }
  }
  const handleDelete = (num) => {
    const btnRow = "row_" + num;
    document.getElementById(btnRow).remove();
    setBtnCount();
  };
  //버튼타입 '추가'버튼 클릭
  const AddBtnType = () => {
    const cnt = document.getElementsByName("btnTypeGrp").length;
    if (cnt > 4) {
      alert("버튼은 최대 5개까지 가능합니다.");
      return;
    }
    const num = btnNum;
    const btnRow = "row_" + num;
    const btnTypeNm = "btnType_" + num;
    const textNm = "btnText_" + num;
    const linkText = "linkText_" + num;
    const linkText2 = "linkText2_" + num;
    const btnCnt = "btnCnt_" + num;
    const btnSn = "btnSn_" + num;
    const count = btnNum + 1;
    setBtnNum(count);
    setButtonTypeList(
      buttonTypeList.concat(
        <tr name="btnTypeGrp" id={btnRow}>
          <input type="hidden" name="hiddenVal" value={num} />
          <input type="hidden" name={btnSn} id={btnSn} />
          <td name={btnCnt} id={btnCnt}></td>
          <td>
            <select
              name={btnTypeNm}
              id={btnTypeNm}
              onChange={(e) => handelChangeBtnType1(num, e.target.value)}
            >
              <option value="">선택</option>
              {codeRows.map((row) =>
                row.cgSn === 1024 ? (
                  <option value={row.cdId}>{row.cdNm}</option>
                ) : null
              )}
            </select>
          </td>
          <td>
            <input type="text" name={textNm} id={textNm} />
          </td>
          <td name={linkText} id={linkText}>
            <div name={linkText2} id={linkText2}></div>
          </td>
          <td>
            <button
              className="delete"
              onClick={(e) => {
                handleDelete(num);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="dark" />
            </button>
          </td>
        </tr>
      )
    );
  };

  return (
    <table className="table_style1 KakaoTemplateUpload1">
      <thead></thead>
      <tbody>
        <input type="hidden" name="tpSn" id="tpSn" value={template.tpSn} />
        <tr>
          <th>채널</th>
          <td>
            <select id="plusId" name="plusId">
              {channelList.map((row) => (
                <option
                  value={row.plusId}
                  selected={template.plusId === row.plusId ? "selected" : null}
                >
                  {row.plusId}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <th>템플릿 코드</th>
          <td>
            <input
              type="text"
              name="tpCode"
              id="tpCode"
              defaultValue={template.tpCode}
            />
          </td>
        </tr>
        <tr>
          <th>템플릿 명</th>
          <td>
            <input
              type="text"
              name="tpNm"
              id="tpNm"
              defaultValue={template.tpNm}
            />
          </td>
        </tr>
        <tr>
          <th>카테고리</th>
          <td>
            <select
              name="cate1"
              id="cate1"
              className="halfSelect"
              onChange={(e) => {
                handleCateChange(e.target.value);
              }}
            >
              <option value="">카테고리 그룹</option>
              {category.map((row) =>
                row.ctType === "1" ? (
                  <option
                    value={row.ctCd}
                    selected={cate1 === row.ctCd ? "selected" : null}
                  >
                    {row.ctNm}
                  </option>
                ) : null
              )}
            </select>
            <span></span>
            <select name="cate2" id="cate2" className="halfSelect">
              <option value="">카테고리</option>
              {category.map((row) =>
                row.ctType === "2" && row.ctParentCd === cate1 ? (
                  <option
                    value={row.ctCd}
                    selected={cate2 === row.ctCd ? "selected" : null}
                  >
                    {row.ctNm}
                  </option>
                ) : null
              )}
            </select>
          </td>
        </tr>
        <tr>
          <th>등록일</th>
          <td>{template.regDt}</td>
        </tr>
        <tr>
          <th>검수 요청일</th>
          <td>{template.insReqDt}</td>
        </tr>
        <tr>
          <th>검수일</th>
          <td>{template.insRepDt}</td>
        </tr>
        <tr>
          <th>검수상태/템플릿상태</th>
          <td>
            <span
              className={
                template.tpInspStatusNm === "승인"
                  ? "stateB"
                  : template.tpInspStatusNm === "반려"
                  ? "stateR"
                  : template.tpInspStatusNm === "등록"
                  ? "stateG"
                  : "stateY"
              }
            >
              {template.tpInspStatusNm}
            </span>
            <span
              className={
                template.tpStatusNm === "정상"
                  ? "stateB"
                  : template.tpStatusNm === "대기(발송전)"
                  ? "stateG"
                  : template.tpStatusNm === "휴면삭제" ||
                    template.tpStatusNm === "중지"
                  ? "stateR"
                  : "stateY"
              }
            >
              {template.tpStatusNm}
            </span>
          </td>
        </tr>
        <tr>
          <th>보안템플릿 설정</th>
          <td>
            <input
              type="checkbox"
              name="securityFlag"
              id="id4"
              className="check"
              checked={securityCheck === true ? "checked" : null}
              onClick={(e) => handleSecurityCheck(e)}
            />
            <label htmlFor="id4">
              보안 템플릿 체크 시, 메인 디바이스 모바일 외 모든 서브
              디바이스에서는 메시지 내용이 노출되지 않습니다.
            </label>
          </td>
        </tr>
        <tr>
          <th>템플릿 내용</th>
          <td className="table_position">
            <textarea
              name="tpText"
              id="tpText"
              onChange={(e) => {
                handleChangeText(e.target.value);
              }}
              defaultValue={template.tpText}
            ></textarea>
            <p className="gray999 size14">
              {textLen} /1000 <br />
            </p>
          </td>
        </tr>
        <tr>
          <th>
            <button className="button_add" onClick={AddBtnType}>
              + 추가
            </button>
          </th>
          <td>
            <table className="table_style2 inner_table">
              <tbody>
                <tr>
                  <th>No</th>
                  <th>버튼타입</th>
                  <th>버튼명</th>
                  <th>버튼링크</th>
                  <th>삭제</th>
                </tr>
                {buttonTypeList}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
