import React, { useState, useEffect } from 'react';
import { SendKakaoAlrimTalk, PreviewAlrimTalk } from './SendKakaoAlrimTalk';
import { SendKakaoFriendTalk, PreviewFriendTalk } from './SendKakaoFriendTalk';
import PreViewBGI from 'assets/images/preview.png';
import { getRequest } from 'component/Axios';

/* datetype 스타일 */
export const SendKakaoTalk = props => {
  const [sendMethod, setSendMethod] = useState(<SendKakaoAlrimTalk />);
  const [Preview, setPreview] = useState(<PreviewAlrimTalk />);
  const [sendMethodCheck, setSendMethodCheck] = useState(true);
  const [infoData, setInfoData] = useState([]);
  const [alimUnit, setAlimUnit] = useState(0);
  const [frdUnit, setFrdUnit] = useState(0);
  const [frdImgUnit, setFrdImgUnit] = useState(0);
  const [frdWideImgUnit, setFrdWideImgUnit] = useState(0);
  const [messageText, setMessageText] = useState(''); //미리보기내용
  const [btnText, setBtnText] = useState([]); //미리보기버튼
  const [preViewAdCheck, setPreViewAdCheck] = useState(false);
  const [friendImgUrl, setFriendImgUrl] = useState('');

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    handelPreviewImg();
  }, [messageText, btnText, preViewAdCheck, friendImgUrl]);

  function handleUserInfo() {
    const url = '/user/userInfo';
    getFetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setDefaultUnit(jsonArr[0]);
        }
      })
      .catch(error => console.log(error));
  }

  const handleSendMethod = check => {
    if (check) {
      props.setPreviewImg(<PreviewAlrimTalk />);
      setMessageText('');
    } else {
      props.setPreviewImg(
        <PreviewFriendTalk
          messageText={messageText}
          btnText={btnText}
          preViewAdCheck={preViewAdCheck}
          friendImgUrl={friendImgUrl}
        />
      );
    }
    setSendMethodCheck(check);
  };
  const handelPreviewImg = () => {
    if (sendMethodCheck) {
      props.setPreviewImg(<PreviewAlrimTalk />);
    } else {
      props.setPreviewImg(
        <PreviewFriendTalk
          messageText={messageText}
          btnText={btnText}
          preViewAdCheck={preViewAdCheck}
          friendImgUrl={friendImgUrl}
        />
      );
    }
  };
  const setDefaultUnit = userData => {
    console.log(userData);
    const monthLimit = userData.monthLimit;
    const monthSend = userData.monthSend;
    const monthUse = monthLimit - monthSend;
    const dayLimit = userData.dayLimit;
    const daySend = userData.daySend;
    const dayUse = dayLimit - daySend;
    let usePrce = dayUse;
    if (dayUse > monthUse) usePrce = monthUse;
    const preRating = userData.preRating;
    let alimCnt = 0;
    let frdCnt = 0;
    let frdImgCnt = 0;
    let frdWideImgCnt = 0;
    let alimPrce = userData.alimPrce === undefined ? 0.0 : userData.alimPrce;
    let frdPrce = userData.frdPrce === undefined ? 0.0 : userData.frdPrce;
    let frdImgPrce =
      userData.frdImgPrce === undefined ? 0.0 : userData.frdImgPrce;
    let frdWideImgPrce =
      userData.frdWideImgPrce === undefined ? 0.0 : userData.frdWideImgPrce;
    if (preRating === '1') {
      if (monthLimit === 0 && dayLimit === 0) {
        alimCnt = '∞';
        frdCnt = '∞';
        frdImgCnt = '∞';
        frdWideImgCnt = '∞';
      } else if (monthLimit === 0) {
        alimCnt = Math.floor((dayLimit - daySend) / (alimPrce * 1.1));
        frdCnt = Math.floor((dayLimit - daySend) / (frdPrce * 1.1));
        frdImgCnt = Math.floor((dayLimit - daySend) / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(
          (dayLimit - daySend) / (frdWideImgPrce * 1.1)
        );
      } else if (dayLimit === 0) {
        alimCnt = Math.floor((monthLimit - monthSend) / (alimPrce * 1.1));
        frdCnt = Math.floor((monthLimit - monthSend) / (frdPrce * 1.1));
        frdImgCnt = Math.floor((monthLimit - monthSend) / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(
          (monthLimit - monthSend) / (frdWideImgPrce * 1.1)
        );
      } else {
        let replaceLimit =
          monthLimit - monthSend > dayLimit - daySend
            ? dayLimit - daySend
            : monthLimit - monthSend;
        console.log(replaceLimit);
        alimCnt = Math.floor((replaceLimit - daySend) / (alimPrce * 1.1));
        frdCnt = Math.floor((replaceLimit - daySend) / (frdPrce * 1.1));
        frdImgCnt = Math.floor((replaceLimit - daySend) / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(
          (replaceLimit - daySend) / (frdWideImgPrce * 1.1)
        );
      }
      if (monthLimit !== 0 && monthSend > monthLimit) {
        alimCnt = 0;
        frdCnt = 0;
        frdImgCnt = 0;
        frdWideImgCnt = 0;
      }
      if (dayLimit !== 0 && daySend > dayLimit) {
        alimCnt = 0;
        frdCnt = 0;
        frdImgCnt = 0;
        frdWideImgCnt = 0;
      }
    } else {
      let balance = userData.balance === undefined ? 0 : userData.balance;
      let replaceBalnce = 0;
      if (monthLimit === 0 && dayLimit === 0) {
        alimCnt = Math.floor(balance / (alimPrce * 1.1));
        frdCnt = Math.floor(balance / (frdPrce * 1.1));
        frdImgCnt = Math.floor(balance / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(balance / (frdWideImgPrce * 1.1));
      } else if (monthLimit === 0) {
        replaceBalnce =
          dayLimit - daySend < balance ? dayLimit - daySend : balance;
        console.log(replaceBalnce);
        alimCnt = Math.floor(replaceBalnce / (alimPrce * 1.1));
        frdCnt = Math.floor(replaceBalnce / (frdPrce * 1.1));
        frdImgCnt = Math.floor(replaceBalnce / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(replaceBalnce / (frdWideImgPrce * 1.1));
      } else if (dayLimit === 0) {
        replaceBalnce =
          monthLimit - monthSend < replaceBalnce
            ? monthLimit - monthSend
            : balance;
        alimCnt = Math.floor(replaceBalnce / (alimPrce * 1.1));
        frdCnt = Math.floor(replaceBalnce / (frdPrce * 1.1));
        frdImgCnt = Math.floor(replaceBalnce / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(replaceBalnce / (frdWideImgPrce * 1.1));
      } else {
        let replaceLimit =
          monthLimit - monthSend > dayLimit - daySend
            ? dayLimit - daySend
            : monthLimit - monthSend;
        replaceBalnce = replaceLimit > balance ? balance : replaceLimit;
        alimCnt = Math.floor(replaceBalnce / (alimPrce * 1.1));
        frdCnt = Math.floor(replaceBalnce / (frdPrce * 1.1));
        frdImgCnt = Math.floor(replaceBalnce / (frdImgPrce * 1.1));
        frdWideImgCnt = Math.floor(replaceBalnce / (frdWideImgPrce * 1.1));
      }
    }
    alimCnt = userData.alimPrce === undefined ? 0 : alimCnt;
    frdCnt = userData.frdPrce === undefined ? 0 : frdCnt;
    frdImgCnt = userData.frdImgPrce === undefined ? 0 : frdImgCnt;
    frdWideImgCnt = userData.frdWideImgPrce === undefined ? 0 : frdWideImgCnt;
    if (alimCnt === Infinity) {
      alimCnt = '∞';
    }
    if (frdCnt === Infinity) {
      frdCnt = '∞';
    }
    if (frdImgCnt === Infinity) {
      frdImgCnt = '∞';
    }
    if (frdWideImgCnt === Infinity) {
      frdWideImgCnt = '∞';
    }
    setAlimUnit(alimCnt);
    setFrdUnit(frdCnt);
    setFrdImgUnit(frdImgCnt);
    setFrdWideImgUnit(frdWideImgCnt);
  };
  const handleContents = text => {
    setMessageText(text);
  };
 

  return (
    <div>
      <div className='write-left-inner receive-area'>
        <div className='write-tit-con clearfix'>
          <div className='bluebox mt20 mb30 clearfix'>
            <div className='text-left size14 mb10'>현재 발송 가능한 건수</div>
            <div className='text-right'>
              알림톡 {alimUnit}건 / 친구톡-텍스트 {frdUnit}건 / 친구톡-이미지{' '}
              {frdImgUnit}건 / 친구톡-와이드 {frdWideImgUnit}건
            </div>
          </div>
          <p className='line'></p>
        </div>
      </div>
      <div className='write-left-inner'>
        <div className='write-tit-con clearfix'>
          <div className='tit-left select-tit'>
            <span className='required'>카카오 메시지</span>
          </div>
          <div className='tit-right'>
            <div className='gs-custom-select buttonWith'>
              <div className='Radiobox'>
                <input
                  type='radio'
                  name='radio'
                  id='id2'
                  defaultValue='type2'
                  defaultChecked
                  onClick={() => {
                    handleSendMethod(true);
                  }}
                />
                <label htmlFor='id2'>알림톡</label>
                <input
                  type='radio'
                  name='radio'
                  id='id3'
                  defaultValue='type3'
                  onClick={() => {
                    handleSendMethod(false);
                  }}
                />
                <label htmlFor='id3'>친구톡</label>
              </div>
            </div>
          </div>
        </div>
        <p className='line'></p>
      </div>
      {/*sendMethod*/}
      {sendMethodCheck ? (
        <SendKakaoAlrimTalk infoData={infoData} />
      ) : (
        <SendKakaoFriendTalk
          infoData={infoData}
          messageText={messageText}
          handleContents={handleContents}
          handelbtnContents={setBtnText}
          btnText={btnText}
          setPreViewAdCheck={setPreViewAdCheck}
          preViewImg
          setFriendImgUrl={setFriendImgUrl}
        />
      )}
    </div>
  );
};
