import React, { useState, useEffect } from 'react';
import { Button, Pagination, PaginationDynamic } from 'component/Common';
import {
  Myinfo,
  Userinfo,
  SubAccountPrepaid,
  SubAccountPostpaid,
} from 'component/Table';
import { NavLink } from 'react-router-dom';
import { PopUpLayout } from 'component/popUp/PopUpLayout';
import {
  LayerPopAddManager,
  LayerPopAddNewAccount,
  LayerPopRequestAccount,
} from 'component/popUp/PopUp';
import { postRequest, getRequest } from 'component/Axios';
import _ from 'lodash';
import Loader from "../../../component/Loader";

export function CompanyInfo(props) {
  useEffect(() => {
    handleAccountInfo();
  }, []);
  const [custType, setCustType] = useState('2');
  const [userType, setUserType] = useState('2');
  const [custGrade, setCustGrade] = useState('1');
  const [infoData, setInfoData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [addManager, popupAddManager] = useState(false);
  const [addBilling, popupAddBilling] = useState(false);
  const [isPrepayed, setIsPrepayed] = useState(true);
  const [userAccountList, setUserAccountList] = useState(
    <AccountListPrepaid />
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [tableRows, setTableRows] = useState('');
  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fnlPage, setFnlPage] = useState(1);
  const [pages, setPages] = useState([1]);
  const [subTot, setSubTot] = useState(0);
  const [addNewAccount, popupAddNewAccount] = useState(false);
  const [popupAccountList, setPopupAccountList] = useState(false);
  const [authCheck, setAuthCheck] = useState('N');
  const [loading, setLoading] = useState(0);
  const [billingUser, setBillingUser] = useState([]);

  useEffect(() => {
    if (authCheck != 'N') {
      handleAccountInfo();
      setAuthCheck('N');
    }
  }, [authCheck]);
  const AddNewAccount = event => {
    if (userType !== '1') {
      alert('대표 사용자만 신규계정 요청이 가능합니다.');
      return;
    }
    event.preventDefault();
    popupAddNewAccount(true);
  };
  const RequestAccount = event => {
    if (userType !== '1') {
      alert('대표 사용자만 목록 확인이 가능합니다.');
      return;
    }
    event.preventDefault();
    setPopupAccountList(true);
  };
  const AddManager = event => {
    event.preventDefault();
    popupAddManager(true);
  };
  const popupClose = () => {
    popupAddManager(false);
  };

  const AddBilling = event => {
    event.preventDefault();
    popupAddBilling(true);
  };
  const popupCloseBilling = () => {
    popupAddBilling(false);
  };

  const handleLoadingOn = () => {
    setLoading(0)
  }

  const handleLoadingClose = () => {
    setLoading(1)
  }

  const handleAccountInfo = () => {
    const url = '/user/info';
    handleLoadingOn();

    getFetch('/user/billing')
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setBillingUser(jsonArr);
        }
        handleLoadingClose();
      })
      .catch(error => handleLoadingClose());

    getFetch(url)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          setInfoData(jsonArr[0]);
          setUserData(jsonArr);
          if (jsonArr[0].preRating === '1')
            // 후불
            setIsPrepayed(false);
          else setIsPrepayed(true);
          const cType = jsonArr[0].custType;
          const uType = jsonArr[0].userType;
          setCustType(cType);
          setUserType(uType);
          setCustGrade(jsonArr[0].custGrade);
          // 대표ID 이면서 대표사용자 이면 서브 아이디 조회
          if (cType === '1' && uType === '1') {
            handlePageChange(1);
          }
        }
        handleLoadingClose();
      })
      .catch(error => handleLoadingClose());
  };
  async function getFetch(url) {
    return getRequest.fetch(url);
  }
  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }
  const [tab, setTab] = useState([]);
  const handleTab = () => { };
  const handlePageChange = page => {
    const url = '/user/subInfo';
    const data = { rowCount: rowCount, currentPage: page, search1: custGrade };
    getPostFetch(url, data)
      .then(result => {
        if (result.resultCode === '0') {
          const jsonArr = JSON.parse(result.resultData);
          const pageItem = JSON.parse(result.resultPage);
          setLeftPage(pageItem.leftPage);
          setRightPage(pageItem.rightPage);
          setEndPage(pageItem.endPage);
          setCurrentPage(pageItem.currentPage);
          setFnlPage(pageItem.fnlPage);
          const pages = _.range(pageItem.startPage, pageItem.endPage + 1);
          setPages(pages);
          setTableRows(jsonArr);
          setSubTot(pageItem.totalCount);
        } else {
          alert(result.resultMsg);
        }
      })
      .catch(error => alert(error));
  };
  const setUserInfo = () => {
    if (isPrepayed) {
      // 후불
      setIsPrepayed(false);
      setUserAccountList(<AccountListPostpaid data={userData} />);
    } else {
      setIsPrepayed(true); // 선불
      setUserAccountList(<AccountListPrepaid data={userData} />);
    }
  };
  return (
    <>
      <div className='container'>
        {loading != 1 && (
          <Loader type="spin" color="black" message="요청을 처리중입니다" />
        )}
        <div className='page-title'>계정정보</div>
        <p className='page-subtitle'>
          <b>내 정보</b>
          <span className='rfloat'>
            <div className='button-wrap'>
              {infoData.userType === '1' ? (
                <NavLink to='/mypage/info/editInfo'>
                  <Button
                    buttonName='button-border-gray'
                    buttonValue='내정보수정'
                  />
                </NavLink>
              ) : null}
              {/* 선불계정의 대표사용자일 때만 노출 & 주소창에 경로쳐서 들어가는 것도 후불사용자 및 대표사용자가 아닐 경우는 막아주세요.*/}
              {userType === '1' ? (
                <NavLink to='/mypage/passwordCheck'>
                  <Button
                    buttonName='button-border-gray'
                    buttonValue='탈퇴하기'
                  />
                </NavLink>
              ) : null}
              {/* 선불계정의 대표사용자일 때만 노출 end */}
            </div>
          </span>
        </p>
        <Myinfo data={infoData} handleAccountInfo={handleAccountInfo} />
        <p className='page-subtitle'>
          <b>사용자 정보</b>
          <span className='rfloat'>
            <div className='button-wrap'>
              {infoData.userType === '1' && (
                <Button
                  buttonName='button-border-gray'
                  buttonValue='일반사용자 등록'
                  onChangePage={AddManager}
                />
              )}
            </div>
          </span>
        </p>
        <Userinfo
          data={userData}
          handleAccountInfo={handleAccountInfo}
          setAuthCheck={setAuthCheck}
          userType={infoData.userType}
          dataType={"normal"}
        />

        {userType === '1' && isPrepayed == false ? (
          <>
            <p className='page-subtitle'>
              <b>정산담당자 정보</b>
              <span className='rfloat'>
                <div className='button-wrap'>
                  {infoData.userType === '1' && (
                    <Button
                      buttonName='button-border-gray'
                      buttonValue='정산담당자 등록'
                      onChangePage={AddBilling}
                    />
                  )}
                </div>
              </span>
            </p>
            <Userinfo
              data={billingUser}
              handleAccountInfo={handleAccountInfo}
              setAuthCheck={setAuthCheck}
              userType={infoData.userType}
              dataType={"billing"}     
            />
          </>
          ) : <></>
        }

        {/* 선불계정일 경우
                  <AccountListPrepaid/> */}
        {/* 후불계정일 경우 */}
        {/* <AccountListPostpaid/> {userAccountList}*/}
        {/*custType === "1" && userType === "1" ? ( isPrepayed ? <AccountListPostpaid data={tableRows}/> : <AccountListPrepaid data={tableRows}/> ) : null*/}
        {custType === '1' && userType === '1' && custGrade === '2' ? (
          <>
            <p className='page-subtitle'>
              <b>계정목록</b>
              <span className='blue pl10'>총 {subTot}개</span>
              {isPrepayed === false ? (
                <span className='rfloat'>
                  <div className='button-wrap'>
                    <Button
                      buttonName='button-border-blue'
                      buttonValue='계정요청 목록'
                      onChangePage={RequestAccount}
                    />
                    <Button
                      buttonName='button-border-blue'
                      buttonValue='신규계정 요청'
                      onChangePage={AddNewAccount}
                    />
                  </div>
                </span>
              ) : null}
            </p>
            <SubAccountPrepaid
              data={tableRows}
              handlePageChange={handlePageChange}
              main={infoData}
              handleLoadingOn={handleLoadingOn}
              handleLoadingClose={handleLoadingClose}
            />
            <PaginationDynamic
              pages={pages}
              currentPage={currentPage}
              leftPage={leftPage}
              rightPage={rightPage}
              endPage={endPage}
              fnlPage={fnlPage}
              onPageChange={handlePageChange}
            />
            {addNewAccount && (
              <PopUpLayout
                poptitle='신규계정 요청'
                onClose={popupAddNewAccount}
              >
                <LayerPopAddNewAccount onClose={popupAddNewAccount}
                  handleLoadingOn={handleLoadingOn}
                  handleLoadingClose={handleLoadingClose} />
              </PopUpLayout>
            )}
            {popupAccountList && (
              <PopUpLayout
                poptitle='계정요청 목록'
                onClose={setPopupAccountList}
              >
                <LayerPopRequestAccount onClose={setPopupAccountList}
                  handleLoadingOn={handleLoadingOn}
                  handleLoadingClose={handleLoadingClose} />
              </PopUpLayout>
            )}
          </>
        ) : null}
      </div>
      {addManager && (
        <PopUpLayout onClose={popupAddManager} poptitle='일반사용자 등록'>
          <LayerPopAddManager popupClose={popupClose}
            handleAccountInfo={handleAccountInfo}
            handleLoadingOn={handleLoadingOn}
            handleLoadingClose={handleLoadingClose}
            dataType={"normal"}
          />
        </PopUpLayout>
      )}
      {addBilling && (
        <PopUpLayout onClose={popupAddBilling} poptitle='정산담당자 등록'>
          <LayerPopAddManager popupClose={popupCloseBilling}
            handleAccountInfo={handleAccountInfo}
            handleLoadingOn={handleLoadingOn}
            handleLoadingClose={handleLoadingClose}
            dataType={"billing"} 
          />
        </PopUpLayout>
      )}
    </>
  );
}
export default CompanyInfo;
export function AccountListPrepaid() {
  const [addsubAccount, popupAddsubAccount] = useState(false);
  const AddsubAccount = event => {
    event.preventDefault();
    popupAddsubAccount(true);
  };
  return (
    //선불계정일 경우 계정 목록
    <>
      <p className='page-subtitle'>
        <b>계정목록</b>
        <span className='blue pl10'>총 4개</span>
      </p>
      <SubAccountPrepaid />
      <Pagination />
    </>
  );
}
export function AccountListPostpaid(props) {
  const [addNewAccount, popupAddNewAccount] = useState(false);
  const AddNewAccount = event => {
    event.preventDefault();
    popupAddNewAccount(true);
  };
  return (
    //후불계정일 경우 계정 목록
    <>
      <p className='page-subtitle'>
        <b>계정목록</b>
        <span className='blue pl10'>총 4개</span>
        <span className='rfloat'>
          <div className='button-wrap'>
            <Button
              buttonName='button-border-blue'
              buttonValue='신규계정 요청'
              onChangePage={AddNewAccount}
            />
          </div>
        </span>
      </p>
      <SubAccountPostpaid data={props.data} />
      <Pagination />
      {addNewAccount && (
        <PopUpLayout onClose={popupAddNewAccount} poptitle='신규계정 요청'>
          <LayerPopAddNewAccount />
        </PopUpLayout>
      )}
    </>
  );
}