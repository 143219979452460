import React, { useState, useEffect } from "react";
import { Button } from "component/Common";
import Slider from "react-slick";
import { postRequest, getRequest } from "component/Axios";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import ReactDOM from "react-dom";
import noimg from "assets/images/noimg.jpg";
import {
  LayerPopAdvertisingGuide, // 광고문자 전송가이드
  LayerPopLoadMsg, // 문구 불러오기
  LayerPopLoadRcsMms,
} from "component/popUp/PopUp";
import {
  OptionLinkURL,
  OptionCall,
  OptionCopy,
  OptionCoordinate,
  OptionQueries,
  OptionPlace,
  OptionCalendar,
  OptionSendMsg,
} from "./SendRCSButtonComponent"; // 버튼 정보

export const RCSCarousel = (props) => {
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setNowBalance = props.setNowBalance;
  const cardCount = props.cardCount;
  const [chekedImgDefault, setChekedImgDefault] = React.useState("mediaSize_1");
  const [activeUploadImage, setActiveUploadImage] = React.useState(null);
  // RCS Carousel 유형
  const uploadImage = [
    { id: "mediaSize_1", value: "SMALL", content: null },
    { id: "mediaSize_2", value: "MEDIUM", content: <ImageUpload /> },
  ];

  // 카드 유형
  const cardList = [
    { id: "110", value: "3장" },
    { id: "111", value: "4장" },
    { id: "112", value: "5장" },
    { id: "113", value: "6장" },
  ];

  useEffect(() => {}, []);

  // 디폴트값
  const [chekedCardId, setChekedCardId] = useState("110");
  const [activeCardType, setActiveCardType] = useState(
    <>
      <CarouselCard
        id={1}
        setChekedImgDefault={setChekedImgDefault}
        setChekedImgDefault2={chekedImgDefault}
        setChekedRcsTypeValue={setChekedRcsTypeValue}
      />
      <CarouselCard
        id={2}
        setChekedImgDefault={setChekedImgDefault}
        setChekedImgDefault2={chekedImgDefault}
        setChekedRcsTypeValue={setChekedRcsTypeValue}
      />
      <CarouselCard
        id={3}
        setChekedImgDefault={setChekedImgDefault}
        setChekedImgDefault2={chekedImgDefault}
        setChekedRcsTypeValue={setChekedRcsTypeValue}
      />
    </>
  );

  // 광고문자 관련
  const [advertisingText, popupAdvertisingText] = useState(false);
  const AdvertisingGuide = (event) => {
    event.preventDefault();
    popupAdvertisingText(true);
  };

  useEffect(() => {
    if (chekedCardId === "110") {
      setActiveCardType(
        <>
          <CarouselCard
            id={1}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={2}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={3}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
        </>
      );
      cardCount("3");
    } else if (chekedCardId === "111") {
      setActiveCardType(
        <>
          <CarouselCard
            id={1}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={2}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={3}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={4}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
        </>
      );
      cardCount("4");
    } else if (chekedCardId === "112") {
      setActiveCardType(
        <>
          <CarouselCard
            id={1}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={2}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={3}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={4}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={5}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
        </>
      );
      cardCount("5");
    } else if (chekedCardId === "113") {
      setActiveCardType(
        <>
          <CarouselCard
            id={1}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={2}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={3}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={4}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={5}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
          <CarouselCard
            id={6}
            setChekedImgDefault={setChekedImgDefault}
            setChekedImgDefault2={chekedImgDefault}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
          />
        </>
      );
      cardCount("6");
    }
  }, [chekedImgDefault, chekedCardId]);

  return (
    <>
      <div className="write-left-inner">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">미디어 크기</span>
          </div>
          <div className="tit-right">
            <div className="gs-custom-select buttonWith">
              <div className="Radiobox">
                {uploadImage.map((value) => (
                  <>
                    <input
                      type="radio"
                      name="uploadImage"
                      id={value.id}
                      checked={chekedImgDefault === value.id}
                      onClick={function (e) {
                        setActiveUploadImage(value.content);
                        setChekedImgDefault(value.id);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <p className="list-item-state mt5 size14">
          ※ SMALL 카드의 권장 크기 240X224, MEDIUM 카드의 권장 크기 464X336
          <br />
          크기가 다를 경우 핸드폰에서 왜곡되어 보이거나 이미지가 잘릴 수
          있습니다.
        </p>
        <p className="line"></p>
      </div>
      <div className="write-left-inner">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">유형</span>
          </div>
          <div className="tit-right">
            <div className="gs-custom-select buttonWith">
              <div className="Radiobox">
                {cardList.map((value) => (
                  <>
                    <input
                      type="radio"
                      name="radio11"
                      id={value.id}
                      checked={chekedCardId === value.id}
                      onClick={function (e) {
                        setChekedCardId(value.id);
                        setActiveCardType(value.content);
                      }}
                    />
                    <label htmlFor={value.id}>{value.value}</label>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <p className="list-item-state mt5 size14">
          ※ 이미지의 총 사이즈는 1Mbyte이하 입니다.
        </p>
        <p className="line"></p>
      </div>

      <div className="write-left-inner receive-area">
        <div className="write-tit-con clearfix">
          <div className="tit-left select-tit">
            <span className="required">카드 정보</span>
          </div>
          <div className="tit-right">
            <fieldset className="gs-custom-checkbox"></fieldset>
            <Button
              buttonName="button-bgc-dark"
              buttonValue="광고문자 전송가이드(필독)"
              onChangePage={AdvertisingGuide}
            />
          </div>
        </div>
        {activeCardType}
        <p className="line"></p>
      </div>
      {advertisingText && (
        <PopUpLayout
          onClose={popupAdvertisingText}
          poptitle="광고문자 전송 가이드"
        >
          <LayerPopAdvertisingGuide />
        </PopUpLayout>
      )}
    </>
  );
};

export const CarouselCard = (props) => {
  // 광고성 관련
  const [contractAllSelected, setContractAllSelected] = useState(false);
  const [adName, setAdName] = useState(null);
  const [adCheck, setAdCheck] = useState(false);
  const [advertisement, setAdvertisement] = useState(null);

  // 광고문자 관련
  const [advertisingText, popupAdvertisingText] = useState(false);
  const [infoData, setInfoData] = useState([]);

  // 본문 내용 관련
  const [subject, setSubject] = useState("");
  const [contents, setContents] = useState("");
  const [contentsFooter, setContentsFooter] = useState("");
  const [contentsHead, setContentsHead] = useState("");
  const [msgType, setMsgType] = useState("MMS");
  const [msgByte, setMsgByte] = useState(0);
  const [limitByte, setLimitByte] = useState(1300);
  const [unitPrce, setUnitPrce] = useState(0);

  // 문구 불러오기 관련
  const [loadMsg, popupLoadMsg] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  // 머지 관련
  const [mergeVariableState, setMergeVariableState] = useState(false);
  const [selectType, setSelectType] = useState("1");
  const [friendsTalkImg, setFriendsTalkImg] = useState(false); // 친구톡 이미지관리 팝업

  // 현재 메시지 타입
  const setChekedRcsTypeValue = props.setChekedRcsTypeValue;
  const setNowBalance = props.setNowBalance;

  const setChekedImgDefault = props.setChekedImgDefault2;

  const [selectedImgData, setSelectedImgData] = useState(null);

  // 버튼 관련
  const RcsSMSButtonOption = [
    { id: "1", value: "URL연결", content: <OptionLinkURL /> },
    { id: "2", value: "전화걸기", content: <OptionCall /> },
    { id: "3", value: "복사하기", content: <OptionCopy /> },
    { id: "4", value: "지도보여주기(좌표)", content: <OptionCoordinate /> },
    { id: "5", value: "지도보여주기(쿼리)", content: <OptionQueries /> },
    { id: "6", value: "현재위치공유", content: <OptionPlace /> },
    { id: "7", value: "캘린더등록", content: <OptionCalendar /> },
    { id: "8", value: "메시지전송", content: <OptionSendMsg /> },
  ];
  const [selectedBtnType, setSelectedBtnType] = useState("1");
  const [inputBtnList, setInputBtnList] = useState([]);
  const [activeButtonType, setActiveButtonType] = useState(null);

  const cardId = props.id;

  async function getPostFetch(url, data) {
    return postRequest.fetch(url, data);
  }

  async function getFetch(url) {
    return getRequest.fetch(url);
  }

  const LoadFriendsTalkImg = () => {
    setFriendsTalkImg(true);
  };

  useEffect(() => {
    handleContents(contents);
  }, [uploadFile, adCheck, loadMsg, setChekedImgDefault]);

  const getAdvertisement = (event) => {
    //광고여부 체크
    if (event.target.checked) {
      setAdvertisement(<Advertisement cardId={cardId} />);
      setAdName(<AdName cardId={cardId} />);
      setAdCheck(true);
    } else {
      setAdCheck(false);
      setContentsHead("");
      setContentsFooter("");
    }
  };

  // 문구 불러오기
  const LoadMsg = (event) => {
    event.preventDefault();
    popupLoadMsg(true);
  };

  // 문구 저장하기
  const SaveMsg = (event) => {
    event.preventDefault();

    let advertYn = "N";
    let footerText = "";
    if (adCheck) {
      advertYn = "Y";
      //080번호 체크
      footerText = document.getElementById("adNumber").value;
      const regexMobile = /^080(?:\d{3}|\d{4})\d{4}$/;
      if (!footerText) {
        alert("광고 메시지의 080 무료거부번호는 필수입력사항 입니다.");
        return;
      } else if (!regexMobile.test(footerText)) {
        alert("잘못된 형식의 080 번호 입니다. 입니다.");
        return;
      }
    }

    if (!contents) {
      alert("저장할 메시지를 작성해 주세요.");
      return;
    }

    if (window.confirm("작성된 메시지를 저장 하시겠습니까?")) {
      let url = "/message/retainMsgSave";
      let data = {
        headerText: contentsHead,
        mainText: contents,
        footerText: footerText,
        subject: subject,
        msgType: msgType,
        advertYn: advertYn,
      };

      if (uploadFile) {
        url = "/message/retainMsgSaveFile";
        const formData = new FormData();
        formData.append("file", uploadFile);
        formData.append("headerText", contentsHead);
        formData.append("mainText", contents);
        formData.append("footerText", footerText);
        formData.append("subject", subject);
        formData.append("msgType", msgType);
        formData.append("advertYn", advertYn);
        data = formData;
      }

      getPostFetch(url, data)
        .then((result) => {
          alert(result.resultMsg);
        })
        .catch((error) => alert(error));
    }
  };

  // 머지설정
  const showMergeSettings = () => {
    if (mergeVariableState) {
      setMergeVariableState(false);
    } else {
      setMergeVariableState(true);
    }
  };

  // 본문 길이에 따른 단가 조정
  function setDetilInfo(totalByte) {
    const btnCnt = document.getElementsByName("btnGrp").length;

    setMsgType("MMS");
    if (setChekedRcsTypeValue != undefined) {
      setChekedRcsTypeValue("RCS MMS");
    }

    let uPrce = "0";
    if (infoData.rcsMmsPrce) uPrce = infoData.rcsMmsPrce;
    setUnitPrce(uPrce);
  }

  const handleSubject = (text) => {
    if (text.length > 30) {
      alert("제목은 최대 30자까지만 입력가능합니다.");
      return;
    }
    document.getElementById("previewSubject" + cardId).innerHTML = text;
  };

  function handleContentsHeader(text) {
    let maxByte = 0;
    if (setChekedImgDefault == "mediaSize_1") {
      maxByte = 30;
      setLimitByte(30);
    } else if (setChekedImgDefault == "mediaSize_2") {
      maxByte = 60;
      setLimitByte(60);
    }

    const text_val = text + contents + contentsFooter;

    let totalByte = 0;
    totalByte += text_val.length;

    totalByte = totalByte + 11;

    if (totalByte > maxByte) {
      alert("최대 " + maxByte + "자까지만 입력가능합니다.");
      document.getElementById("adTitle" + cardId).value = contentsHead;
    } else {
      setContentsHead(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      const msg = "(광고) " + text;
      document.getElementById("previewHeader" + cardId).innerHTML = msg;
      if (document.getElementById("adTitle" + cardId)) {
        document.getElementById("adTitle" + cardId).value = text;
      }
    }
  }

  const handleContents = (text) => {
    let maxByte = 0;
    if (setChekedImgDefault == "mediaSize_1") {
      maxByte = 30;
      setLimitByte(30);
    } else if (setChekedImgDefault == "mediaSize_2") {
      maxByte = 60;
      setLimitByte(60);
    }

    const text_val = text + contentsHead + contentsFooter;

    let totalByte = 0;
    totalByte += text_val.length;

    if (adCheck) {
      totalByte = totalByte + 11;
    }

    if (totalByte > maxByte) {
      alert("최대 " + maxByte + "자까지만 입력가능합니다.");
    } else {
      setContents(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      if (adCheck) {
        document.getElementById("previewHeader" + cardId).innerHTML =
          "(광고) " + contentsHead;
        document.getElementById("previewBody" + cardId).innerHTML = text;
        document.getElementById("previewFooter" + cardId).innerHTML =
          "무료거부 " + contentsFooter;
      } else {
        if (document.getElementById("previewHeader" + cardId)) {
          document.getElementById("previewHeader" + cardId).innerHTML = "";
        }
        if (document.getElementById("previewBody" + cardId)) {
          document.getElementById("previewBody" + cardId).innerHTML = text;
        }
        if (document.getElementById("previewFooter" + cardId)) {
          document.getElementById("previewFooter" + cardId).innerHTML = "";
        }
      }
    }
  };

  function handleContentsFooter(text) {
    let maxByte = 0;
    if (setChekedImgDefault == "mediaSize_1") {
      maxByte = 30;
      setLimitByte(30);
    } else if (setChekedImgDefault == "mediaSize_2") {
      maxByte = 60;
      setLimitByte(60);
    }

    const text_val = text + contents + contentsHead;

    let totalByte = 0;
    totalByte += text_val.length;
    totalByte = totalByte + 11;

    if (totalByte > maxByte) {
      alert("최대 " + maxByte + "자까지만 입력가능합니다.");
      document.getElementById("adNumber" + cardId).value = contentsFooter;
    } else {
      setContentsFooter(text);
      setDetilInfo(totalByte);
      setMsgByte(totalByte);

      document.getElementById("previewFooter" + cardId).innerHTML =
        "무료거부 " + text;
      if (document.getElementById("adNumber")) {
        document.getElementById("adNumber").value = text;
      }
    }
  }

  const handleContentsName = (text) => {
    const txtArea = document.getElementById("msgContents" + cardId);
    const txtValue = txtArea.value;
    let selectPos = txtArea.selectionStart;
    const beforeTxt = txtValue.substring(0, selectPos);
    const afterTxt = txtValue.substring(txtArea.selectionEnd, txtValue.length);
    const addTxt = text;
    handleContents(beforeTxt + addTxt + afterTxt);
    selectPos = selectPos + addTxt.length;
    txtArea.selectionStart = selectPos;
    txtArea.selectionEnd = selectPos;
    txtArea.focus();
  };

  const handleRetainMsgSet = (
    hText,
    subject,
    mText,
    fText,
    rMsgType,
    rAdvertYn
  ) => {
    if (hText === undefined) {
      hText = "";
    }
    if (subject === undefined) {
      subject = "";
    }
    if (mText === undefined) {
      mText = "";
    }
    if (fText === undefined) {
      fText = "";
    }
    let checkLength = hText + mText + fText;

    if (rAdvertYn === "Y") {
      if (checkLength.length + 11 > limitByte) {
        alert(limitByte + "자 보다 긴 문구를 불러올 수 없습니다.");
        return;
      }
    } else {
      if (checkLength.length > limitByte) {
        alert(limitByte + "자 보다 긴 문구를 불러올 수 없습니다.");
        return;
      }
    }

    if (rAdvertYn === "Y") {
      setContentsHead(hText);
      setContentsFooter(fText);

      setAdCheck(true);
      document.getElementById("advertising" + cardId).checked = true;
      handleContentsHeader(hText);
      handleContentsFooter(fText);

      setContents(mText);
    } else {
      document.getElementById("advertising" + cardId).checked = false;
      setContractAllSelected(false);
      setAdCheck(false);
    }
    handleContents(mText);
    setSubject(subject);
    popupLoadMsg(false);
  };

  const addButtonList = () => {
    const style = {
      button: {
        width: "100%",
        padding: "8px 0",
        textAlign: "center",
        backgroundColor: "#f5f4f6",
        color: "#222",
        marginBottom: "10px",
        borderRadius: "3px",
        fontSize: "14px",
      },
    };
    let btnDivId = "button" + cardId + "_";
    let previewBtnNm = "rcsTextButtonNm" + cardId + "_";

    let btnCnt = 0;

    // if ( btnCnt > 1 ) {
    //     alert("RCS MMS의 허용 가능한 버튼 수는 최대 2개입니다.");
    //     return;
    // }

    for (let i = 0; i < 2; i++) {
      if (document.getElementById(btnDivId + i)) {
        btnCnt += 1;
      }
    }

    if (btnCnt === 2) {
      alert("RCS Carousel은 카드별 허용 가능한 버튼 수가 최대 2개입니다.");
      return;
    }
    for (let i = 0; i < 2; i++) {
      if (!document.getElementById(btnDivId + i)) {
        btnDivId += i;
        previewBtnNm += i;
        break;
      }
    }

    if (selectedBtnType === "1") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionLinkURL
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "2") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionCall
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "3") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionCopy
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "4") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionCoordinate
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "5") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionQueries
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "6") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionPlace
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "7") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionCalendar
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    } else if (selectedBtnType === "8") {
      setInputBtnList(
        inputBtnList.concat(
          <OptionSendMsg
            cardId={cardId}
            msgType={msgType}
            setMsgType={setMsgType}
            setChekedRcsTypeValue={setChekedRcsTypeValue}
            setLimitByte={setLimitByte}
            infoData={infoData}
            setNowBalance={setNowBalance}
            previewBtnNm={previewBtnNm}
            id={btnDivId}
          />
        )
      );
    }

    var newDiv = document.createElement("input");

    newDiv.style.width = "90%";
    newDiv.style.height = "20px";
    newDiv.style.textAlign = "center";
    newDiv.style.background = "#f5f4f6";
    newDiv.style.color = "#222";
    newDiv.style.border = "none";
    newDiv.style.borderRadius = "3px";
    newDiv.style.fontSize = "14px";
    newDiv.style.marginRight = "20px";
    newDiv.style.marginBottom = "-10px";
    newDiv.id = previewBtnNm;

    var currentDiv = document.getElementById("previewBtnList" + cardId);
    currentDiv.appendChild(newDiv, currentDiv);
    document.getElementById(previewBtnNm).readOnly = true;
  };

  const popupClose = () => {
    setFriendsTalkImg(false);
  };

  useEffect(() => {
    if (selectedImgData != null) {
      document.getElementById("file" + cardId).value = selectedImgData.id;
      document.getElementById("rcsMmsImg" + cardId).src = selectedImgData.url;
    }
  }, [selectedImgData]);

  return (
    <>
      <div className="rcsCarouselCard">
        <div className="write-left-inner receive-area">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">사진첨부</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-input-btn-group">
                <input
                  type="text"
                  id={"file" + cardId}
                  name={"file" + cardId}
                  defaultValue=""
                  placeholder=""
                  readOnly
                />
                <Button
                  buttonName="button-bgc-gray"
                  buttonValue="파일등록"
                  onChangePage={LoadFriendsTalkImg}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="write-left-inner receive-area mt10">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">제목</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-input-btn-group">
                <input
                  type="text"
                  id={"subject" + cardId}
                  name={"subject" + cardId}
                  title="제목"
                  placeholder="메시지 제목을 입력해주세요.(최대 30자)"
                  onChange={(e) => {
                    handleSubject(e.target.value);
                  }}
                  value={subject}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="write-left-inner receive-area mt10">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">메시지 내용</span>
            </div>
            <div className="tit-right">
              <fieldset className="gs-custom-checkbox">
                <span className="checkbox-item">
                  <input
                    type="checkbox"
                    id={"advertising" + cardId}
                    name=""
                    onClick={getAdvertisement}
                  />
                  <label
                    for={"advertising" + cardId}
                    className={contractAllSelected ? "checked" : null}
                  >
                    광고성 정보가 포함되어 있습니다
                  </label>
                </span>
              </fieldset>
            </div>
          </div>
          <div id="msgArea" className="write-input-con change-effect-bg">
            {/* 광고성 정보 체크 시에만 노출 */}
            {/* {adName} */}
            {adCheck ? (
              <AdName
                cardId={cardId}
                handleContentsHeader={handleContentsHeader}
                contentsHead={contentsHead}
              />
            ) : null}
            {/* 광고성 정보 체크 시에만 노출 */}
            <div className="write-input-down-con">
              <textarea
                name=""
                id={"msgContents" + cardId}
                className="gs-custom-textarea change-effect"
                placeholder="내용 입력"
                maxlength="1300"
                onChange={(e) => {
                  handleContents(e.target.value);
                }}
                value={contents}
              ></textarea>
              <div className="write-input-down-info">
                <span className="msgType">{msgType}</span>
                <span className="byte" id="msgByte">
                  {msgByte} / <em className="red">{limitByte} 자</em>
                </span>
              </div>
            </div>
            {/* 광고성 정보 체크 시에만 노출 */}
            {/* {advertisement} */}
            {adCheck ? (
              <Advertisement
                cardId={cardId}
                handleContentsFooter={handleContentsFooter}
                contentsFooter={contentsFooter}
              />
            ) : null}
          </div>
          <div className="write-number-down-btn mt20">
            <ul className="button-col3">
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 불러오기"
                  onChangePage={LoadMsg}
                />
              </li>
              <li>
                <Button
                  buttonName="button-border-gray"
                  buttonValue="문구 저장하기"
                  onChangePage={SaveMsg}
                />
              </li>
              <li>
                <Button
                  buttonName={
                    mergeVariableState
                      ? "button-bgc-blue"
                      : "button-border-gray"
                  }
                  buttonValue="머지설정"
                  onChangePage={() => showMergeSettings()}
                />
                {mergeVariableState ? (
                  <MergeSettings handleContentsName={handleContentsName} />
                ) : null}
              </li>
            </ul>
          </div>
        </div>
        {loadMsg && (
          <PopUpLayout
            onClose={popupLoadMsg}
            poptitle="문구 불러오기"
            size="medium"
          >
            <LayerPopLoadMsg
              selectType={selectType}
              handleRetainMsgSet={handleRetainMsgSet}
            />
          </PopUpLayout>
        )}
        {advertisingText && (
          <PopUpLayout
            onClose={popupAdvertisingText}
            poptitle="광고문자 전송 가이드"
          >
            <LayerPopAdvertisingGuide />
          </PopUpLayout>
        )}
        {friendsTalkImg && (
          <PopUpLayout
            onClose={setFriendsTalkImg}
            poptitle="RCS 이미지 불러오기"
            size="medium"
          >
            <LayerPopLoadRcsMms
              popupClose={popupClose}
              setSelectedImgData={setSelectedImgData}
            />
          </PopUpLayout>
        )}
        <div className="write-left-inner receive-area mt10">
          <div className="write-tit-con clearfix">
            <div className="tit-left select-tit">
              <span className="required">버튼</span>
            </div>
            <div className="tit-right">
              <div className="gs-custom-input-btn-group">
                <select
                  name="friendsBtnType"
                  id="friendsBtnType"
                  onChange={(e) => {
                    setSelectedBtnType(e.target.value);
                  }}
                >
                  {RcsSMSButtonOption.map((b) => (
                    <option value={b.id}>{b.value}</option>
                  ))}
                </select>
                <Button
                  buttonName="button-bgc-dark"
                  buttonValue="추가"
                  onChangePage={addButtonList}
                />
              </div>
            </div>
          </div>
          {inputBtnList}
        </div>
      </div>

      <div className="usebtn">
        {activeButtonType}
        <p className="line"></p>
      </div>
    </>
  );
};

export function ImageUpload() {
  const LoadingImg = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="mt20 mb20">
        지원 규격 : 최대 500 KB / jpg, png 파일만 첨부 가능합니다. (720*720px
        권장)
        <br />
        가로 500px 미만 또는 가로:세로 비율이 2:1 미만 또는 3:4 초과시 첨부
        불가합니다.
      </div>
      <div className="input-con">
        <div className="gs-custom-input-btn-group">
          <input
            type="text"
            id=""
            name=""
            title="첨부이미지"
            placeholder="등록된 이미지가 없습니다."
            disabled
          />
        </div>
      </div>
    </>
  );
}

export function MergeSettings(props) {
  const handleContentsName = props.handleContentsName;

  // 메시지 내용 - 머지설정
  const SetVariableName = (event) => {
    event.preventDefault();
    handleContentsName("#{이름}");
  };
  const SetVariable1 = (event) => {
    event.preventDefault();
    handleContentsName("#{1}");
  };
  const SetVariable2 = (event) => {
    event.preventDefault();
    handleContentsName("#{2}");
  };
  const SetVariable3 = (event) => {
    event.preventDefault();
    handleContentsName("#{3}");
  };
  const SetVariable4 = (event) => {
    event.preventDefault();
    handleContentsName("#{4}");
  };
  return (
    <div className="variable-info-con" id="">
      <div>
        <table className="variable-info-tb" id="">
          <tbody>
            <tr>
              <td byte="10">
                <Button
                  buttonName=""
                  buttonValue="#{이름}"
                  onChangePage={SetVariableName}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{1}"
                  onChangePage={SetVariable1}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{2}"
                  onChangePage={SetVariable2}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{3}"
                  onChangePage={SetVariable3}
                />
              </td>
              <td byte="20">
                <Button
                  buttonName=""
                  buttonValue="#{4}"
                  onChangePage={SetVariable4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function Advertisement(props) {
  const handleContentsFooter = props.handleContentsFooter;
  const contentsFooter = props.contentsFooter;
  const cardId = props.cardId;

  // 메시지 내용 - 광고성 정보 체크 시 - 무료거부번호 입력란
  return (
    <div className="write-input-top-con advertisement">
      <input
        type="text"
        id={"adNumber" + cardId}
        name={"adNumber" + cardId}
        maxlength="11"
        title="무료거부번호"
        placeholder="080 무료거부번호 입력"
        onChange={(e) => {
          handleContentsFooter(e.target.value);
        }}
        defaultValue={contentsFooter}
      />
    </div>
  );
}

export function AdName(props) {
  const handleContentsHeader = props.handleContentsHeader;
  const contentsHead = props.contentsHead;
  const cardId = props.cardId;
  // 메시지 내용 - 광고성 정보 체크 시 - 채널명
  return (
    <div className="write-input-top-con clearfix adMsgInfo">
      <div className="tit-con tit01">(광고)</div>
      <div className="tit-con tit02">
        <input
          type="text"
          id={"adTitle" + cardId}
          name={"adTitle" + cardId}
          title="제목"
          className="gs-custom-input"
          placeholder="채널명 입력"
          onChange={(e) => {
            handleContentsHeader(e.target.value);
          }}
          defaultValue={contentsHead}
        />
      </div>
    </div>
  );
}
