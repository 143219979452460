import React, { useEffect } from "react";
import { Container } from "../../styles/GlobalStyle";

export const Agreement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="Terms">
      <div className="service_tit">
        <h2>이용약관</h2>
      </div>
      <div class="servicecont">
        <div class="service_cont">
          <br />
          <h4>Hisms Biz메시징 서비스 이용약관</h4>
          <h5>제 1 장 총칙</h5>

          <pre>
            <strong>제1조 (목적)</strong>이 약관은 전기통신사업법 및 동법
            시행령에 의하여 한진정보통신(주)(이하 "회사"라 합니다)이 제공하는
            HISMS서비스(이하 "서비스"라 합니다)를 이용함에 있어 이용 조건 및
            절차에 관한 제반 사항을 규정하는 것을 목적으로 합니다.
          </pre>

          <pre>
            <strong>제2조 (약관의 효력 및 개정)</strong>
            {`① 이 약관은 회사가 서비스 화면을 통해 그 내용을 게시하고 이용자가 이에 동의를 함으로써 효력을 발생합니다.
                    ② 회사는 관련법을 위배하지 않는 범위에서 약관을 규정할 수 있으며, 필요시 약관을 개정할 수 있습니다.
                    ③ 회사가 약관을 개정할 경우에는 서비스 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지하며, 제1항과 같은 방법으로 효력이 발생합니다.
                    ④ 동조 제3항의 공지에도 불구하고 회원이 개정약관에 동의하지 않는다는 이의 표시를 회사에 별도로 하지 않은 경우에는 동조 제1항에 동의한 것으로 간주되며 개정약관이 적용됩니다.`}
          </pre>

          <pre>
            <strong>제3조 (약관 외 준칙)</strong>이 약관에서 정하지 않은 사항과
            이 약관의 해석에 관하여는 전기통신기본법, 전기통신사업법,
            정보통신망이용촉진및정보보호등에관한법률 및 기타 관계법령 또는
            상관례에 따릅니다.
          </pre>

          <pre>
            <strong>제4조 (용어의 정의)</strong>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />
            {`① 이용자 : 회사가 제공하는 서비스를 이용하는 회원 및 비회원
                    ② 선불회원 : 회사에 개인정보를 제공하여 Web상에서 이용자 아이디(ID)를 부여 받은 회원으로, 회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자
                    ③ 후불회원 : 회사에 개인정보를 제공하여 이용계약을 체결하고 이용자 아이디(ID)를 부여 받은 회원으로, 회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자
                    ④ 대표아이디(ID) : 회원이 정한 조합형이며, 서브아이디를 관리 할 수 있는 아이디 입니다.
                    ⑤ 서브아이디(ID) : 대표아이디가 별도로 생성한 아이디로 문자를 보낼 수 있는 아이디 입니다.
                    ⑥ 비밀번호 : 회원이 부여 받은 그룹 및 서브아이디와 일치되는 회원임을 확인하고 회원의 비밀보호를 위해 정한 문자 또는 숫자의 조합
                    ⑦ 충전 : 전송할 수 있는 일정 문자량을 확보하거나 일정 상품을 구매하기 위해 특정지불수단을 이용하여 현금을 회사에 지불하거나 회사가 지정한 여타의 방법으로 구매 또는 획득하는 행위
                    ⑧ 해지 : 회사 또는 회원이 이용계약을 해약하는 것`}
          </pre>

          <h5>제 2 장 회원가입과 정보변경 및 회원 탈퇴</h5>

          <pre>
            <strong>제5조 (회원가입)</strong>
            {`① 대표아이디 이용자는 회사가 정한 가입 양식에 따라 회원정보를 정확하게 기입하고, 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청하며 회사는 이를 회사가 정한 실명확인절차를 거쳐 회원가입을 승낙하는 것을 원칙으로 합니다.
                    ② 서브아이디는 회사가 정한 가입 양식에 따라 대표아이디가 회원가입 신청을 하며 서브아이디에 대한 모든 법적 책임은 대표아이디에 있습니다.
                    ③ 14세 미만의 아동은 특별히 법률이 정하는 바에 따라 회원정보를 회사에 제공하기 전에 회사가 정한 가입 양식 외에 별도로 회사가 정한 법정대리인의 동의절차를 완료해야 합니다.
                    ④ 회사는 전항과 같이 회원 가입을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
                        가. 가입 신청자가 이전에 회원 자격을 상실한 적이 있는 경우 (단, 회원자격 상실 후 1년이 경과하고 회사의 회원 재가입 승낙을 얻은 경우에는 예외)
                        나. 등록 내용에 허위, 기재누락, 오기가 있는 경우 및 비실명 가입
                        다. 기타 회원으로 등록하는 것이 회사의 서비스에 현저히 지장이 있다고 판단되는 경우`}
          </pre>

          <pre>
            <strong>제6조 (회원정보의 변경)</strong>
            회원은 이용 신청 시 기재한 회원정보가 변경되었을 경우 즉시
            온라인으로 직접 수정해야 하며, 관리자에게 수정을 요구할 수 있습니다.
            이때 변경하지 않은 정보로 인해 발생되는 서비스 관련 모든 문제는
            회원에게 책임이 있습니다.
          </pre>

          <pre>
            <strong>제7조 (회원 탈퇴 및 자격 상실, 서비스 이용제한 등)</strong>
            {`① 회원은 탈퇴를 희망하는 경우 탈퇴를 요청할 수 있으며 회사는 정한 양식에 의하여 회원 탈퇴를 즉시 처리합니다.
                    ② 회원이 탈퇴 후 재가입은 30일 이내에는 불가합니다.
                    ③ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한하거나 정지시킬 수 있습니다.
                        가. 가입 신청 시에 허위 내용을 등록한 경우 및 회사가 정한 실명확인을 거치지 않은 비실명 가입
                        나. 다른 사람의 서비스 이용을 방해하거나, 타인의 명예를 훼손하거나 그 정보를 도용하는 등 전자거래 및 정보통신서비스 이용질서를 위협하는 경우
                        다. 제공되는 정보를 변경하는 등 회사의 업무를 방해한 경우
                        라. 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하는 경우 및 회사가 정한 이용조건에 위배되는 경우
                        마. 만 14세 미만의 아동이 법정대리인의 동의를 받지 아니하고 회원에 가입한 경우
                        바. 제17조(회원의 의무)를 위반한 경우
                    ④ 회사가 회원자격을 상실(정지)시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원자격 말소 사유에 따라 회원등록 말소 전에 회원에게 소명할 기회를 부여할 수 있습니다.`}
          </pre>

          <h5>제 3 장 서비스 제공 및 이용</h5>

          <pre>
            <strong>제8조(서비스의 제공 및 변경)</strong>
            {`① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                        가. SMS, LMS, MMS 전송 서비스
                        나. 알림톡, 친구톡 전송 서비스
                        다. RCS 메시지 서비스
                    ② 회사는 서비스를 변경할 경우 제2조에서 정한 방법으로 서비스 초기화면에 개정된 약관 및 적용일자를 공지하고, 본 조 1항에 정한 서비스를 변경하여 이용자에게 제공할 수 있습니다.
                    ③ 회사는 서비스의 원활한 수행을 저해하는 제3자와 이용자간 혹은 이용자간 불법 스팸 등의 전송행위를 방지하기 위하여 여러 가지 기술적, 제도적 정책을 시행할 수 있으며, 이로 인하여 이용자는 회사가 제공하는 서비스 이용에 제한을 받을 수 있습니다.`}
          </pre>

          <pre>
            <strong>
              제9조 (서비스 이용시간, 중단 및 제공이 불가능한 경우의 처리방안)
            </strong>
            {`① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
                        가. 천재지변, 시스템장애 및 점검, 서비스 양도 및 폐지(휴지)등
                        나. 서비스용 설비의 보수 등 공사로 인한 부득이 한 경우
                        다. 전기통신사업법에 규정된 기간통신 사업자가 전기통신 서비스를 중지했을 경우
                    ② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
                    ③ 회사는 천재지변, 휴지, 불가항력 사유발생 등 서비스제공이 불가능에 해당하는 경우, 손해배상의 의무를 지지 않습니다.
                    ④ 회사는 정기점검, 서비스양도, 서비스종료(폐업) 등 '서비스제공 중지' 등천재지변 등을 제외한 서비스 불가능에 해당하는 경우, 이를 반드시 최소 30일 전에 "이용자"에게 공지해야 하며, 중지 사유에 따라 손해배상의 의무를 집니다. 다만, "회사"가 사전에 통지할 수 없는 오류(장애)등의 경우 사후에 통지할 수 있습니다.`}
          </pre>

          <pre>
            <strong>
              제10조 (이용자의 개인정보, ID 및 비밀번호에 대한 의무)
            </strong>
            {`① 회원은 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 정확한 정보(이하 "등록정보"라 한다)를 제공하여야 합니다. 또한 등록정보에 변경이 발생할 경우 즉시 갱신하여야 하며, 제공하는 정보의 내용이 허위(차명, 비실명 등)인 것으로 판명되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할 경우 회사는 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 책임을 지지 않습니다.
                    ② 회사는 비실명 사용에 따른 병폐를 해소하기 위해 실명확인 절차를 시행하고 있으며, 회원은 반드시 회사에서 정한 실명확인 절차를 거쳐야 합니다.
                    ③ 회원이 서비스 사용을 위한 등록절차를 완료하면 아이디와 비밀 번호를 받게 됩니다.
                    ④ 회원은 정보 관리를 위하여 회원의 승인 없이 비밀번호, 아이디가 사용되는 문제가 발생할 경우 회사에 즉시 신고 하여야 하며, 접속 종료시 확실히 로그아웃을 하여야 합니다.
                    ⑤ 회원은 불법으로 타인의 개인정보를 획득, 게시, 전송 해서는 아니되며 위반시 이에 대한 모든 책임은 회원에게 있습니다.
                    ⑥ 회사가 관계법령 및 "개인정보취급방침"에 의해서 그 책임을 지는 경우를 제외하고, 자신의 ID와 비밀번호에 관한 관리책임은 각 회원에게 있습니다.
                    ⑦ 대표아이디는 서브아이디를 회사의 승인 없이 생성 및 사용 그리고 중지를 할 수 있으므로 서브아이디에 의해 발생하는 모든 민.형사 책임은 대표아이디 회원에게 있습니다.`}
          </pre>

          <pre>
            <strong>제11조 (광고 게재)</strong>
            {`① 회사는 서비스의 운용과 관련하여 회원의 휴대단말기로 광고를 전송할 수 있습니다.
                    ② 회사는 회사의 서비스화면, 홈페이지, 이메일 등을 통해 광고를 게재할 수 있습니다.
                    ③ 회사는 회사의 서비스화면, 홈페이지, 이메일 등을 통해 광고를 게재할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제12조 (지식 재산권의 보호)</strong>
            {`① 회사가 작성한 저작물에 대한 저작권 및 기타 제반 지식재산권은 모두 회사에 귀속하며 서비스 이용 목적외의 복제, 전송, 배포 등의 일체의 행위는 금지됩니다.
                    ② 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 이용할 수 없습니다.
                    ③ 회사가 제공하는 모든 콘텐츠 및 서비스에 대해서는 회원이 단순 링크의 방법이나 기타 전자적인 방법을 통하여 본 사이트 외에서 이용하는 것은 상업적 목적 여부를 불문하고 금지됩니다.`}
          </pre>

          <pre>
            <strong>제13조 (이용자 불만형태별 처리절차 및 처리기간)</strong>
            {`① 이용자의 불만사항 접수 및 처리는 다음과 같이 시행합니다.
                    ② 불만사항 접수는 일반전화, 이메일에 의한 방법으로 접수하며, 민원접수 처리는 운영자가 내용을 직접 인지한 시간으로부터 24시간 이내에 처리하는 것을 원칙으로 합니다.`}

            <table className="table02">
              <thead>
                <tr>
                  <th scope="col">형태</th>
                  <th scope="col">유형</th>
                  <th scope="col">원인</th>
                  <th scope="col">처리 절차</th>
                  <th scope="col">처리 기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="3">서비스관련</td>
                  <td>통신장애</td>
                  <td>회사의 귀책사유</td>
                  <td>대고객 사과 및 품질개선</td>
                  <td>즉시</td>
                </tr>
                <tr>
                  <td rowSpan="2">시스템장애</td>
                  <td>회사의 귀책사유</td>
                  <td>대고객 사과 및 품질개선</td>
                  <td>즉시</td>
                </tr>
                <tr>
                  <td>고객사(회원)의 귀책사유</td>
                  <td>대고객 설명</td>
                  <td>24시간 이내</td>
                </tr>
                <tr>
                  <td rowSpan="3">서비스관련</td>
                  <td rowSpan="3">청구요금이의</td>
                  <td rowSpan="2">회사의 귀책사유</td>
                  <td>과금전 : 비과금 요청</td>
                  <td rowSpan="2">즉시</td>
                </tr>
                <tr>
                  <td>과금후 : 환불</td>
                </tr>
                <tr>
                  <td>고객사(회원)의 귀책사유</td>
                  <td>대고객 설명</td>
                  <td>24시간 이내</td>
                </tr>
              </tbody>
            </table>
          </pre>

          <pre>
            <strong>
              제14조 (거짓으로 표시된 전화번호를 송신한 자의 해당 회선에 대한
              전기통신역무 제공의 중지를 위한 처리방안)
            </strong>
            미래창조과학부(거짓으로 표시된 전화번호로 인한 이용자 피해 예방에
            등에 관한)고시 제 4조, 제5조, 제7조에 따라 거짓으로 표시된
            전화번호를 송신한 이용자 또는 고객사의 회선제공을 중지 할 수
            있습니다.
          </pre>

          <h5>제 4 장 이용요금 충전 및 사용</h5>

          <pre>
            <strong>제15조 (이용요금 운영)</strong>
            회사는 회원이 유료서비스를 이용할 수 있도록 디지털 화폐 형태의
            이용요금(단위:원)을 운영합니다.
            <p className="he10"></p>
            {`① 충전
                        가. 이용요금은 실시간계좌이체, 무통장입금(가상계좌), 기타 회사가 정한 결제수단으로 구매(충전)할 수 있습니다.
                        나. 회원이 타인의 결제수단을 이용하여 이용요금을 충전할 경우, 회사는 이에 대한 책임을 지지 않습니다.`}
            <p className="he10"></p>
            {`② 이용 및 차감
                        가. 충전된 이용요금은 서비스상의 각종 유료서비스를 이용하거나 디지털 콘텐츠를 구입하는 지불수단으로 이용됩니다.
                        나. 충전된 이용요금은 회사가 제공하는 유료서비스를 이용하는 시점에서 자동으로 차감 되며, 구매한 유료서비스 또는 콘텐츠가 서비스상의 중대한 하자에 의하여 손상, 훼손, 삭제되었거나 불량했을 경우 재충전 혹은 해당 콘텐츠 복원으로 보상 받을 수 있습니다.
                        다. 회사는 회원이 일정기간 회사의 서비스를 이용하지 않을 경우 이를 휴면회원으로 구분하여 서비스와 무관하게 별도 관리하며, 다음의 구분과 같이 휴면회원 상태의 기간이 경과되면 회사는 해당 회원의 충전된 이용요금 잔액을 회계상의 잡이익 계정으로 처리합니다.
                            1) 충전잔액이 일만(10,000)원 미만이면서 1년 이상 서비스를 이용하지 않는 회원
                            2) 충전잔액이 일만(10,000)원 이상 오만(50,000)원 미만이면서 2년 이상 서비스를 이용하지 않는 회원
                            3) 충전잔액이 오만(50,000)원 이상이면서 3년 이상 서비스를 이용하지 않는 회원`}
            <p className="he10"></p>
            {`③ 증여 : 충전된 이용요금은 회사가 별도로 지정하지 않는 한 타인에게 증여할 수 없습니다.`}
            <p className="he10"></p>
            {`④ 환불
                        가. 이용요금 환불은 서비스의 회원탈퇴를 전제로 회사의 고객센터를 통하여 환불 신청이유를 회사에 통지하고 회원 본인이 직접 환불신청을 하여야 합니다.
                        나. 환불 신청 및 처리는 다음의 환불규정에 따릅니다.
                            1) 환불 신청시 환불액은 이용요금 잔액에서 재정경제부에서 고시한 “인터넷 이용관련 소비자 피해보상”에서 규정한 위약금(결제 금액의 10%) 및 PG수수료, 송금비용을 제외한 금액을 환불합니다. 단, 위약금, PG수수료, 송금비용의 합계는 최소 1,000원으로 산정하여 이용요금 잔액이 1,000원 미만일 경우는 환불되지 않습니다.
                            2) 회원은 회사의 고객센터에 관련 서류(회원 : 신분증 사본, 회원명의 통장 사본, 법인 : 사업자등록증 사본, 사업자 대표 명의 또는 사업자명 명의 통장 사본)이 제출되어야 합니다.
                            3) 환불금은 회원명의 또는 사업자명의 통장으로 영업일수 기준 7일 이내에 환불됩니다.
                            4) 기 발행된 세금계산서는 폐기 처리되고, 매출 신고가 된 경우는 환불이 불가합니다.
                        다. 다음의 사유로 환불 신청시는 환불되지 않는 것을 원칙으로 합니다.
                            1) 회원의 부당행위나 불법행위 등의 귀책사유로 서비스 이용정지, 회원 탈퇴되거나 탈퇴하려는 경우
                            2) 회사의 서비스 운영 정책에 반하는 스팸 문자 메시지 전송을 목적으로 가입한 경우
                            예) KISA(한국인터넷진흥원)가 고지한 3대 스팸(성인, 060, 불특정다수에게 보내는 메시지 등)
                        라. 이용요금 충전 관련 결제(카드결제, 실시간계좌이체 등) 오류로 인한 결제금액은 결제 취소가 가능합니다.`}
            <p className="he10"></p>
            {`⑤ 이용요금 충전 후 이용한 서비스가 정상적으로 이루어지지 못한 경우, 차감된 이용요금은 회사의 사실확인 후 24시간 이내에 재충전 처리됩니다.`}
            <p className="he10"></p>
            {`⑥ 회사는 관계법령의 변경 등 합리적인 사유 발생 및 회사 정책상 변경이 필요한 경우, 이용요금 운영정책을 변경하여 운영할 수 있습니다.`}
            <p className="he10"></p>
            {`⑦ 후불제
                        가. 회사는 회원과 계약을 통해 후불로 이용금액을 청구할 수 있습니다.
                        나. 후불계약의 경우는 2개월분 예상 이용금액만큼을 보증금액으로 하고 보증기간을 서비스이용기간으로 하는 이행보증보험증권을 서비스 이용 전에 제출하여야 합니다.
                        다. 회사는 후불계약을 한 회원에게 매달 초 전월 이용요금을 청구내역서와 세금계산서를 온라인 또는 우편으로 발송하며, 후불 회원은 이용월의 익월 지정일까지 회사의 지정계좌에 현금 입금을 해야 합니다.
                        라. 회사는 후불 회원이 연체를 하면, 해당 아이디를 정지시킬 수 있으며, 이행보증보험증권 등을 통해 채권을 회수 할 수 있습니다.
                        마. 후불제 회원은 회사에 제출한 이행보증보험증권의 보증기간 및 보증금액 범위 내에서 회사가 정한 기준에 따라 서비스를 이용할 수 있습니다.`}
          </pre>

          <h5>제 5 장 개인정보보호</h5>

          <pre>
            <strong>제16조(회사의 보안관리)</strong>
            회사는 이용자의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
            않도록 안전성 확보에 필요한 조치를 강구합니다. 단, 회원이 게시판이나
            email, 채팅 등 온라인 상에서 자발적으로 개인정보를 제공하는 경우
            이에 대한 책임은 회원에게 있습니다.
          </pre>

          <pre>
            <strong>제17조 (회사의 회원정보 사용에 대한 동의)</strong>
            {`① 회사는 서비스를 이용하는 이용자를 대상으로 해당 서비스의 양적, 질적 향상을 위하여 이용자의 개인 식별이 가능한 개인정보를 이용자의 동의를 받아 이를 수집하여 회사의 각종 서비스 등에 이용할 수 있습니다.
                    ② 회사가 수집하는 개인정보는 서비스의 제공에 필요한 범위 내에서 최소한으로 합니다.
                    ③ 회사의 개인정보보호와 관련된 방침은 웹사이트에 게시된 개인정보취급방침에 따릅니다.
                    ④ 이용자가 제공한 개인정보는 이용자의 동의 없이 제3자에게 누설하거나 제공하지 않습니다.
                      단, 회사는 회원에게 서비스를 제공할 목적으로 계약을 체결한 콘텐츠 사업자 혹은 비즈니스 사업자등에게 이용자의 개인정보(제한된 범위의 개인정보로서 이름, 주소 등)를 제공할 수 있습니다.
                    ⑤ 회원은 회사에 제공한 개인정보의 수집과 이용에 대한 동의를 언제든지 철회할 수 있습니다.`}
          </pre>

          <h5>
            제 6 장 발신번호 등록, 관리, 부정가입 방지 등에 대한 각 당사자의
            의무 및 기타 이용자 고지사항
          </h5>

          <pre>
            <strong>제18조 (회사의 의무)</strong>
            {`① 회사는 발신번호의 변작방지를 위해 번호인증을 통한 발신번호 사전등록서비스를 제공, 운영한다.
                    ② 회사는 이용고객의 회원가입 시 타인의 명의를 도용한 부정가입을 방지하기 위해 본인인증서비스 사업자가 제공하는 인증방법 또는 대면인증을 통해 본인인증서비스를 제공, 운영한다.
                    ③ 회사는 고객사(회원)가 발신번호 사전등록 및 본인인증절차를 거친 후 서비스를 제공하여야 한다.
                    ④ 회사는 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않는 것을 원칙으로 합니다. 단, 아래 사항에 해당하는 경우는 예외입니다.
                        가. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우
                        나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우
                        다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
                    ⑤ 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 자료를 작성하여 이를 사용할 수 있습니다.`}
          </pre>

          <pre>
            <strong>제19조 (회원(고객사)의 의무)</strong>
            {`① 회원은 발신번호의 변작방지를 위해 번호인증을 통해 발신번호 사전등록 후 서비스를 이용하여야 한다.
                    ② 회원은 이용고객의 회원가입 시 부정가입 방지를 위해 본인인증서비스사업자가 제공하는 인증방법으로 본인인증을 거친 후 서비스를 이용하여야 한다.
                    ③ 회원은 이 약관에서 규정하는 모든 사항과 서비스 이용안내 및 주의사항 등을 준수하여야 합니다.
                    ④ 회원은 회원등록 양식에서 요구되는 내용들에 대해 진실되고 정확하며 현재의 사실과 일치되는 완전한 정보를 제공하고 유지합니다.
                    ⑤ 회원은 자신의 ID와 비밀번호를 관리할 의무를 가지며, 그에 대한 회원의 관리 소홀로 인하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
                    ⑥ 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안되며, ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
                    ⑦ 회원은 사전 승낙 없이 상업적인 목적을 위하여 서비스를 이용할 수 없습니다.
                    ⑧ 이용자는 서비스를 이용함으로써 얻은 정보를 회사의 승낙 없이 복제, 전송, 출판, 배포, 방송 및 기타 방법에 의하여 상업적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.
                    ⑨ 회원은 별도로 게재된 스팸방지정책 규정을 반드시 준수하여야 합니다. 이를 위반하는 경우 이에 대한 모든 책임은 회원이 부담합니다.`}
          </pre>

          <h5>제 7 장 기타</h5>

          <pre>
            <strong>제20조 (분쟁해결 및 기타사항)</strong>
            회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하여 적절하고
            빠른 조치를 취합니다. 단, 조치가 곤란한 경우에는 이용자에게 그
            사유와 처리일정을 통보합니다.
          </pre>

          <pre>
            <strong>제21조 (손해배상책임)</strong>
            {`① 회사는 서비스의 이용과 관련하여 회사의 고의 또는 중대한 과실이 없이 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다.
                    ② 이용자가 본 약관상의 의무를 위반하거나 이행하지 아니하여 회사 또는 제3자에게 손해를 입힌 경우는 그 손해를 배상하여야 합니다.`}
          </pre>

          <pre>
            <strong>제22조 (면책조항)</strong>
            {`① 회사는 서비스 이용 중 회사의 귀책사유가 없이 발생한 회원의 손해에 대해서는 책임을 지지 않습니다.
                    ② 회사는 약관에 명시된 사유로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공의 불이행에 관하여는 책임이 면제됩니다.
                    ③ 회사는 회사의 연결 사이트에 대해 보증책임을 지지 않으며, 연결 사이트와 이용자간에 이루어진 어떠한 거래에 대해서도 책임을 지지 않습니다.
                    ④ 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대해 그 책임이 면제됩니다.
                    ⑤ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 관하여 책임이 면제됩니다.
                    ⑥ 해당 서비스나 디지털 콘텐츠가 회원의 특정한 목적이나 필요를 충족시킨다는 보장이 없습니다. 따라서 서비스 이용에 있어 품질, 성능, 정확도 또는 노력 면에서 회원의 불만족에 대해서는 회사는 책임을 지지 않습니다.`}
          </pre>

          <pre>
            <strong>제23조 (분쟁의 처리)</strong>
            회사에 귀책사유가 명백히 인정되는 경우, 회원은 회사를 상대로 소송
            등을 제기하기 전에 우선 회사에 이를 알려 회사가 별도로 운영하는
            고객센터 등을 통해 원만히 분쟁을 해결할 수 있습니다.
          </pre>

          <pre>
            <strong>제24조 (관할 법원)</strong>
            회사와 이용자간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속적
            관할법원으로 합니다.
          </pre>

          <pre>
            {`공고일자: 2021년 9월 1일
                    시행일자: 2021년 9월 1일.끝.`}
          </pre>
        </div>
      </div>
    </Container>
  );
};
