import React, { useState, useEffect } from "react";
import { Button } from "component/Common";
import { PopUpLayout } from "component/popUp/PopUpLayout";
import { LayerPopJoin1, LayerPopJoin2, LayerPopPostCode } from "component/popUp/PopUp";
import { makeStyles } from '@material-ui/core/styles';
import { postRequest, getRequest } from "component/Axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../component/Loader";

const useStyles = makeStyles((theme) => ({
    password: {
        width: '100px'
    }
}));


export const EditMyInfo = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [popupJoin1, setPopupJoin1] = useState(false);
    const [popupJoin2, setPopupJoin2] = useState(false);
    const [addsubAccount, popupAddsubAccount] = useState(false);
    const [infoData, setInfoData] = useState([]);
    const [bizNo1, setBizNo1] = useState('');
    const [bizNo2, setBizNo2] = useState('');
    const [bizNo3, setBizNo3] = useState('');
    const [userNm, setUserNm] = useState('');
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        handleAccountInfo();
    }, []);

    const handleLoadingOn = () => {
        setLoading(0)
      }
    
      const handleLoadingClose = () => {
        setLoading(1)
      } 

    const handleAccountInfo = () => {
        const url = "/user/info"
        handleLoadingOn();
        getFetch(url)
        .then(result => {
            if( result.resultCode === "0" ) {
                const jsonArr = JSON.parse(result.resultData);
                setInfoData(jsonArr[0]);
                setCorpNm(jsonArr[0].corpNm);
                setCeoNm(jsonArr[0].ceoNm);
                setBizCndtnNm(jsonArr[0].bizCndtnNm);
                setBizIndstNm(jsonArr[0].bizIndstNm);
                setZip(jsonArr[0].zip);
                setAddr(jsonArr[0].addr);
                setDtlAddr(jsonArr[0].dtlAddr);
                setMobile(jsonArr[0].mobile);
                const bizno = jsonArr[0].bizNo;
                setBizNo1(bizno.substring(0,3));
                setBizNo2(bizno.substring(3,5));
                setBizNo3(bizno.substring(5,10));
                setUserNm(jsonArr[0].userNm);
                setUserNm(jsonArr[0].userNm);
                setEmail(jsonArr[0].email);
                handleLoadingClose();
            }
        })
        .catch(error => handleLoadingClose())
    }

    const intialValues = { password: "",  confirmPassword: "", oldPassword: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [popupPostCode, setPopupPostCode] = useState(false);
    const [address, setAddress] = useState("");
    const [tableRows, setTableRows] = useState('');
    const [checkBizno, setCheckBizno] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [repCustSn, setRepCustSn] = useState(0);
    const [biznoData, setBiznoData] = useState('');
    const [useCust, setUseCust] = useState(false);
    const [zip, setZip] = useState('');
    const [addr, setAddr] = useState('');
    const [dtlAddr, setDtlAddr] = useState('');
    const [corpNm, setCorpNm] = useState('');
    const [ceoNm, setCeoNm] = useState('');
    const [bizCndtnNm, setBizCndtnNm] = useState('');
    const [bizIndstNm, setBizIndstNm] = useState('');
    const [bizMsg, setBizMsg] = useState('');
    const [custStatus, setCustStatus] = useState('3');
    const [emailMsg, setEmailMsg] = useState('');
    const [checkMobile, setCheckMobile] = useState(true);
    const [certMsg, setCertMsg] = useState('');
    const [confirmMsg, setConfirmMsg] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const closePostCode = () => {
        setPopupPostCode(false)
    }

    const closeBizno = () => {
        setPopupJoin1(false);
    }

    const closeCustInfo = () => {
        setPopupJoin2(false);
    }

    async function getPostFetch(url,data) {
        return postRequest.fetch(url,data);
    }

    async function getFetch(url) {
        return getRequest.fetch(url);
    }
    
    const submitForm = () => {

        if (Object.keys(formErrors).length === 0 && isSubmitting ) {
            
            if( window.confirm("수정 하시겠습니까?") ){
                
                const url = "/user/custModify";
                const data = { custPwd: formValues.password, corpNm : corpNm, ceoNm : ceoNm, oldCustPwd : formValues.oldPassword,
                    bizCndtnNm : bizCndtnNm, bizIndstNm : bizIndstNm, zip : zip, addr : addr, dtlAddr : dtlAddr }
                handleLoadingOn();
                getPostFetch(url, data)
                .then(result => {
                        alert(result.resultMsg);
                        handleLoadingClose();
                        if( result.resultCode === "0" ) {
                            navigate("/mypage/info/accountInfo");
                        } 
                    }
                )
                .catch(error => handleLoadingClose())
            }
        }
    };

    useEffect(()=>{ 
        setZip(address[0]);
        setAddr(address[1]);
        setDtlAddr("");
    }, [address]);
    
    useEffect(()=>{ 
        setFormErrors(validate(formValues));
    }, [formValues,isSubmitting]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors(validate(formValues));
    };

    function handleSetValue (name, value){
        setFormValues({ ...formValues, [name]: value });
        setFormErrors(validate(formValues));
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    };
    
    const validate = (values) => {
        let errors = {};
        
        // 영문,특수문자,숫자 조합으로 6자리 이상
        let regexPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/
        
        if (!values.oldPassword && isSubmitting) { 
            errors.oldPassword = "기존 비밀번호는 필수입력사항 입니다.";
        } 

        if ( values.password ) { 
            if (!regexPwd.test(values.password) )
                errors.password = "비밀번호는 6자리이상, 영문, 숫자, 특수문자를 조합하여야 합니다.";
            else
                setPasswordErrMsg("안전한 비밀번호입니다");
        }

        if ( values.password ) { 
            if (values.password !== values.confirmPassword ) {
                errors.confirmPassword = "수정 비밀번호가 일치하지 않습니다.";
            } else {
                setConfirmMsg("비밀번호가 일치합니다.");
            }
        }

        return errors;
    };
    
    
    const handleAddress = (event) => {
        event.preventDefault();
        setPopupPostCode(true);
    };

    const handleCertification = (event) => {
        event.preventDefault();
        setCertMsg("본인인증이 완료되었습니다.");
    };

    const handleEmail = (event) => {
        event.preventDefault();
        setEmailMsg("메일주소가 인증되었습니다.");

    };

    const ConnectAccount = (event) => {
        event.preventDefault();
        setPopupJoin2(true);
    };
    const handleReset = (event) => {
        event.preventDefault();
    };
    const handleDeleteUser = (event) => {
        event.preventDefault();
    };
    const handleAddUser = (event) => {
        event.preventDefault();
    };
    
    const handleJoin = (event) => {
        event.preventDefault();
    };

    const handleMobileChange = (e) => {
        const regex = /^[0-9\b -]{0,13}$/;
        if (regex.test(e.target.value)) {
            setMobile(e.target.value);
            setCheckMobile(false);
            setEmailMsg("");
        }
    }

    return (
        <> 
            <p className="page-stitle">
                수정할 <b>회원 정보를 입력</b>해주세요.
            </p>
            {loading !=  1 && (
                <Loader type="spin" color="black" message="요청을 처리중입니다" />
            )}
            <form onSubmit={handleSubmit} noValidate >
            <div className="account-container join-container">
                <p className="stitle">
                    <div>
                        기업정보
                    </div>
                </p>
                <p className="clear"></p>

                <div className="account-contents">
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con">사업자등록번호</div>
                            <div className="input-con clearfix">
                                <ul className="input-col3-group clearfix">
                                    <li><input type="text" name="bizno1" id="bizno1" maxlength="3" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                            value={bizNo1} />
                                    </li>
                                    <li><input type="text" name="bizno2" id="bizno2" maxlength="2" title="" className="gs-custom-input w-auto fc-event" placeholder="" 
                                            value={bizNo2} />
                                    </li>
                                    <li><input type="text" name="bizno3" id="bizno3" maxlength="5" title="" className="gs-custom-input w-auto fc-event" placeholder=""
                                            value={bizNo3}  />
                                    </li>
                                    <p className="clear"></p>
                                </ul>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>업체명</span></div>
                            <div className="input-con">
                                <input type="text" name="corpNm" id="corpNm" maxlength="100" title="업체명" className="gs-custom-input fc-event" placeholder="업체명" value={corpNm} 
                                onChange={ e => {setCorpNm(e.target.value)}} />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>대표자명</span></div>
                            <div className="input-con">
                                <input type="text" name="ceoNm" id="ceoNm" maxlength="10" title="대표자명" className="gs-custom-input fc-event" placeholder="대표자명" value={ceoNm}
                                onChange={ e => {setCeoNm(e.target.value)}} />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>업태</span></div>
                            <div className="input-con">
                                <input type="text" name="bizCndtnNm" id="bizCndtnNm" maxlength="100" title="업태" className="gs-custom-input fc-event" placeholder="업태" value={bizCndtnNm}
                                onChange={ e => {setBizCndtnNm(e.target.value)}} />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>종목</span></div>
                            <div className="input-con">
                                <input type="text" name="bizIndstNm" id="bizIndstNm" maxlength="100" title="종목" className="gs-custom-input fc-event" placeholder="종목" value={bizIndstNm} 
                                onChange={ e => {setBizIndstNm(e.target.value)}} />
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span>우편번호</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" className="gs-custom-input" name="zip" id="zip" readonly="" value={zip} />
                                        <button type="button" className="cm-highlight-btn he30 fc-event" id="corpNumCheckBtn" onClick={handleAddress} >주소 검색</button>
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item mt10">
                        <div className="input-inner clearfix" id="corpAddrArea">
                            <div className="title-con"><span>주소</span></div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" className="gs-custom-input" name="addr" id="addr" readonly="" value={addr} />
                                    </div>
                                </fieldset>
                            </div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        <input type="text" className="gs-custom-input" name="dtlAddr" maxlength="100" id="dtlAddr" value={dtlAddr} 
                                        onChange={ e => {setDtlAddr(e.target.value)}}  />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <p className="clear"></p>
            </div>
            <div className="account-container join-container">
                <p className="stitle">
                    <div>
                        회원정보
                        <span className="rfloat required">필수 입력사항</span>
                    </div>
                </p>
                <p className="clear"></p>

                <div className="account-contents">
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con">아이디</div>
                            <div className="input-con">
                                <fieldset className="address-fieldset">
                                    <div className="address-wrapper">
                                        {infoData.custId}
                                    </div>
                                </fieldset>
                            </div>
                            <p className="clear"></p>
                        </div>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con"><span className="required">기존 비밀번호</span></div>
                            <div className="input-con">
                                <input type="password" name="oldPassword" id="oldPassword" maxlength="20" title="비밀번호" className="gs-custom-input fc-event" 
                                    value={formValues.oldPassword} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.oldPassword && (<span className="red">{formErrors.oldPassword}</span>)}</p>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con">수정 비밀번호</div>
                            <div className="input-con">
                                <input type="password" name="password" id="password" maxlength="20" title="비밀번호" className="gs-custom-input fc-event" placeholder="비밀번호 6자리이상, 영문, 숫자, 특수문자 조합" 
                                value={formValues.password} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.password && (<span className="red">{formErrors.password}</span>)}<span className="blue">{passwordErrMsg}</span></p>
                    </div>
                    <div className="check-list-item">
                        <div className="input-inner clearfix" id="cropNumArea">
                            <div className="title-con">수정 비밀번호 확인</div>
                            <div className="input-con">
                                <input type="password" name="confirmPassword" id="confirmPassword" maxlength="20" title="비밀번호 확인" className="gs-custom-input fc-event" placeholder="수정 비밀번호 확인" 
                                value={formValues.confirmPassword} onChange={handleChange} />
                            </div>
                            <p className="clear"></p>
                        </div>
                        <p className="list-item-state">{formErrors.confirmPassword && (<span className="red">{formErrors.confirmPassword}</span>)}<span className="blue">{confirmMsg}</span></p>
                    </div>
                </div>
                <p className="clear"></p>
                
            </div>
            <div className="button-wrap center">
                <p className="size15 gray333"></p>
                <Button buttonName="button-bgc-blue mt20 bbtn" buttonValue="수정완료" onChangePage={submitForm} />
            </div>
            </form>
        {popupPostCode && (
            <PopUpLayout onClose={setPopupPostCode} poptitle="우편번호 조회">
                <LayerPopPostCode address={address} setAddress={setAddress} onClose={closePostCode} />
            </PopUpLayout>
        )}
        </>
    );
};